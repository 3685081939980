import icClock from "../../../../assets/images/ic-clock-backward.svg";
import {formatDateMoment} from "../../../../utils/utils";
import {Link} from "react-router-dom";

export const ContractHistory = ({historyContractList, contractIdParams, historyIdParams}) => {
    return (
        <div className={"border-r-8 border-neutral-10 flex-1 h-full"}>
            <div className={"p-6 flex flex-row items-center gap-3"}>
                <img src={icClock} alt={'ic-file-lock'}/>
                <p className={"text-neutral-80 font-bold text-md"}>History</p>
            </div>
            <div className={"max-h-[calc(100%_-_308px)] overflow-scroll"}>
            {
                historyContractList.map((item, index) => (
                    <Link to={`?contractId=${contractIdParams}&contractHistoryId=${item.id}`} key={index}>
                        <div className={"px-6 pb-2 cursor-pointer"} key={index}>
                            <div className={"flex flex-row items-center gap-5"}>
                                <div
                                    className={`${Number(historyIdParams) === item.id ? 'bg-primary-50' : 'bg-neutral-30'} w-5 h-5 rounded-full flex flex-col justify-center items-center`}>
                                    <div className={"bg-white w-3 h-3 rounded-full"}/>
                                </div>
                                <p className={"text-neutral-80 font-bold capitalize"}>{item.event}</p>
                            </div>
                            <div
                                className={`text-neutral-70 pb-5 ${index !== (historyContractList.length - 1) ? (Number(historyIdParams) === item.id ? 'border-l-2 border-primary-50 ml-2 mt-1 pl-8' : 'border-l-2 border-neutral-30 ml-2 mt-1 pl-8') : 'pl-10'}`}>
                                <p className={"font-bold pt-2 w-[85%] truncate"}>No. {item.number} </p>
                                <p className={"font-semibold pt-3"}>Modified by</p>
                                <p className={"font-semibold"}>{item.actor}</p>
                                <p className={"pt-3"}>{formatDateMoment(item.timestamp, 'DD MMM YYYY HH:mm:ss')}</p>
                            </div>
                        </div>
                    </Link>
                ))
            }
            </div>
        </div>
    )
}
