import {PayrollDetail} from "../../../components/payroll/payroll-detail/PayrollDetail";
import {useLocation, useSearchParams} from "react-router-dom";
import {
    constructOptionsSelect,
    downloadFile,
    getAllQueryParams,
    setCurrency,
    setQueryParams
} from "../../../utils/utils";
import {INITIAL_DATA_QUERY_DETAIL, TABLE_PAYROLL_DETAIL} from "../../../configs/payroll/configTablePayrollList";
import {useEffect, useState} from "react";
import {useFetch} from "../../../services/useFetch";
import {API_PAYROLL_LIST} from "../../../services/apiPayrollList";
import moment from "moment";

const INIT_COLUMN_EARNING = {
    name: 'Earning',
    key: 'earning',
    classNameHeader: "!text-center",
}

const INIT_COLUMN_DEDUCTION = {
    name: 'Deduction',
    key: 'deduction',
    classNameHeader: "!text-center",
}

const INIT_SUB_COLUMN = {
    name: null,
    key: null,
    isShowTooltip: true,
    classNameHeader: "!text-center max-w-0 !min-w-[150px]",
    classNameTooltip: "font-medium truncate",
    className: '!text-center',
    render: ({value}) => <div>{setCurrency(value)}</div>
}

export const PayrollDetailContainer = () => {
    const location = useLocation()
    const [searchParams, setSearchParams] = useSearchParams()
    const payrollId = searchParams.get('payrollId')
    const query = searchParams.size > 0 ? getAllQueryParams(searchParams) : INITIAL_DATA_QUERY_DETAIL
    const [modalExport, setModalExport] = useState(false)
    const [optionsFilter, setOptionsFilter] = useState([])
    const [isShowToast, setIsShowToast] = useState(false)
    const [toastMessage, setToastMessage] = useState('')
    const {data: payrollDetail, fetching: getPayrollDetail, isLoading} = useFetch({...API_PAYROLL_LIST.PAYROLL_DETAIL, initPathVariable: payrollId}, INITIAL_DATA_QUERY_DETAIL)
    const {fetching: exportPayrollDetail} = useFetch({...API_PAYROLL_LIST.EXPORT_PAYROLL_DETAIL, initPathVariable: payrollId})
    const {fetching: departmentList} = useFetch(API_PAYROLL_LIST.DEPARTMENT_LIST)

    useEffect(() => {
        const {payrollId, ...queryParams} = query
        getPayrollDetail({...queryParams})
    }, [location?.search])

    useEffect(() => {
        departmentList()
            .then(response => {
                const list = constructOptionsSelect([{
                    name: 'Semua Department',
                    id: null
                }, ...response?.data], 'name', 'id')
                setOptionsFilter(list)
            })
    }, []);

    const onChangeQuery = (queryParams) => {
        const params = setQueryParams({...query, ...queryParams})
        setSearchParams(params, {replace: true})
    }

    const clearToast = () => {
        setIsShowToast(false)
        setToastMessage('')
    }

    const onExport = () => {
        const combineObj = {...INITIAL_DATA_QUERY_DETAIL, ...query}
        const {payrollId, ...payload} = combineObj
        exportPayrollDetail(payload, '', {}, 'blob')
            .then(response => {
                downloadFile(null, response, `payroll-${payrollId}-Export-${moment().format('DD-MM-YYYY')}.xlsx`)
                setToastMessage('Payroll Exported')
                setIsShowToast(true)
                setModalExport(false)
            })
    }

    const getColumnTable = () => {
        const column = [...TABLE_PAYROLL_DETAIL.column]
        const subColumn = {earning: [], deduction: []}
        payrollDetail?.data?.result?.column_map_earning?.map(item => subColumn?.earning.push({...INIT_SUB_COLUMN, ...item}))
        payrollDetail?.data?.result?.column_map_deduction?.map(item => subColumn?.deduction.push({...INIT_SUB_COLUMN, ...item}))

        if (subColumn?.deduction?.length) {
            const deductionObj = {...INIT_COLUMN_DEDUCTION, subColumn: subColumn?.deduction}
            column.splice(10, 0, deductionObj)
        }

        if (subColumn?.earning?.length) {
            const earningObj = {...INIT_COLUMN_EARNING, subColumn: subColumn?.earning}
            column.splice(10, 0, earningObj)
        }

        return column
    }

    const props = {
        dataList: payrollDetail?.data,
        columnTable: getColumnTable(),
        isLoading,
        query,
        optionsFilter,
        modalExport,
        setModalExport,
        isShowToast,
        toastMessage,
        onExport,
        clearToast,
        onChangeQuery,
    }

    return (
        <PayrollDetail {...props}/>
    )
}
