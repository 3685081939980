import {useDispatch, useSelector} from "react-redux";
import {setContractList, setModal, setSelectedRows, setToast, setTotalData} from "../../../reducers/contractReducer";
import {useEffect, useState} from "react";
import {downloadFile, getAllQueryParams, getValueFromArray, setQueryParams} from "../../../utils/utils";
import moment from "moment";
import {useFetch} from "../../../services/useFetch";
import {API_CONTRACT} from "../../../services/apiContract";
import {useLocation, useSearchParams} from "react-router-dom";
import {ContractList} from "../../../components/management-contract/contract-employee/ContractList";
import {Alert} from "../../../components/commons/alert";

const FILTER_PARAMS = ['department_ids', 'division_ids', 'subdivision_ids', 'type', 'start_date', 'end_date']

export const ContractEmployeeContainer = () => {
    const dispatch = useDispatch()
    const location = useLocation()
    const [searchParams, setSearchParams] = useSearchParams();
    const {
        toast,
        modal,
        totalData,
        selectedRows
    } = useSelector(state => state.contractReducer)
    const {fetching: exportApi} = useFetch(API_CONTRACT.EXPORT)
    const {data: contractListResponse, fetching: getContractListApi, isLoading} = useFetch(API_CONTRACT.CONTRACT_LIST)
    const [queryFilter, setQueryFilter] = useState()
    const defaultQuery = {
        page: 1,
        limit: 20,
        order_by: "created_at",
        order_type: "asc",
        status: 'active'
    }
    const query = searchParams.size > 0 ? getAllQueryParams(searchParams) : defaultQuery
    const tabList = [
        {id: 'active', name: 'active'},
        {id: 'draft', name: 'draft'},
        {id: 'pending', name: 'pending'},
        {id: 'completed', name: 'completed'},
    ]
    const status = searchParams.get('status') || 'active'
    const [visibility, setVisibility] = useState({
        drawer: false,
        preview: false
    })
    const [documentPreview, setDocumentPreview] = useState('')
    const [employeeDetail, setEmployeeDetail] = useState({})
    const {fetching: previewContractDetailApi} = useFetch(API_CONTRACT.PREVIEW_DETAIL)
    const {fetching: deleteContractApi} = useFetch(API_CONTRACT.DELETE_CONTRACT)

    useEffect(() => {
        getContractList()
    }, [location.search])

    const clearToast = () => {
        dispatch(setToast({isShowToast: false, message: ''}))
    }

    const getContractList = async () => {
        const newQuery = {...query}
        const newQueryFilter = {}
        Object.entries(newQuery).forEach(([key, item]) => {
            if (FILTER_PARAMS.includes(key)) {
                const valueOfQuery = FILTER_PARAMS.slice(0, 4).includes(key) ? item.split(',') : item
                newQuery[key] = valueOfQuery
                newQueryFilter[key] = valueOfQuery
            }
        })
        setQueryFilter(newQueryFilter)
        if (newQuery?.type) {
            let typeContract = []
            newQuery.type.forEach((i) => {
                if (Number(i) === 1) {
                    typeContract.push('PKWTT')
                } else {
                    typeContract.push('PKWT')
                }
            })
            newQuery.type = typeContract
        }
        let response = await getContractListApi(newQuery)
        dispatch(setContractList(response.data))
        dispatch(setTotalData(response?.data?.total_result))
    }

    const onChangeTab = (value) => {
        onChangeQuery({page: 1, status: value.name})
    }

    const modalVisibility = (value) => {
        dispatch(setModal({...modal, ...value}))
    }

    const onExportHandler = () => {
        const newQuery = {...query}
        const newQueryFilter = {}
        Object.entries(newQuery).forEach(([key, item]) => {
            if (FILTER_PARAMS.includes(key)) {
                const valueOfQuery = FILTER_PARAMS.slice(0, 4).includes(key) ? item.split(',') : item
                newQuery[key] = valueOfQuery
                newQueryFilter[key] = valueOfQuery
            }
        })
        setQueryFilter(newQueryFilter)
        if (newQuery?.type) {
            let typeContract = []
            newQuery.type.forEach((i, index) => {
                if (i === 1) {
                    typeContract.push('PKWTT')
                } else {
                    typeContract.push('PKWT')
                }
            })
            newQuery.type = typeContract
        }
        exportApi({...newQuery, contract_ids: getValueFromArray(selectedRows)}, '', {}, 'blob')
            .then(res => {
                downloadFile(null, res, `Contract-Employee-List-Export-${moment().format('DD-MM-YYYY')}.xlsx`)
                modalVisibility({'export': false})
                dispatch(setToast({isShowToast: true, message: 'Contract Exported !!'}))
            })
    }

    const onChangeQuery = (queryParams) => {
        const params = setQueryParams({...query, ...queryParams})
        setSearchParams(params, {replace: true})
    }

    const onPreviewHandler = (empDetail) => {
        previewContractDetailApi({}, empDetail.id)
            .then(response => {
                updateVisibility('drawer', true)
                setEmployeeDetail(response.data)
            })
    }

    const onSelectItem = (e) => {
        dispatch(setSelectedRows(e))
    }

    const updateVisibility = (key, value) => {
        setVisibility({...visibility, [key]: value})
    }

    const onClickButtonDelete = () => {
        if (selectedRows.length === 0) {
            return Alert({
                icon: 'warning',
                title: 'Silakan pilih employee terlebih dahulu.',
                autoClose: true,
            })
        }
        modalVisibility({delete: true})
    }

    const onDeleteContract = () => {
        const ids = selectedRows?.map(item => item?.id)
        deleteContractApi({contract_ids: ids})
            .then(() => {
                dispatch(setToast({isShowToast: true, message: 'Contract Deleted !!'}))
                modalVisibility({delete: false})
                getContractList()
            })
    }

    const props = {
        tabList,
        status,
        onChangeTab,
        query,
        onChangeQuery,
        queryFilter,
        toast,
        modal,
        modalVisibility,
        isLoading,
        selectedRows,
        totalData,
        onExportHandler,
        contractListResponse,
        onPreviewHandler,
        isOpen: visibility.drawer,
        modalPreview: visibility.preview,
        updateVisibility,
        employeeDetail,
        documentPreview,
        setDocumentPreview,
        onChangePage: (page) => onChangeQuery({page}),
        onSelectItem,
        onClickSort: (sortQuery) => onChangeQuery({ ...sortQuery, page: 1 }),
        onClickButtonDelete,
        onDeleteContract,
        clearToast
    }

    return (
        <ContractList {...props} />
    )
}
