import { Alert } from "components/commons/alert"
import { Overtime } from "components/overtime/Overtime"
import { TABLE_REQUEST_OVERTIME } from "configs/overtime/configTableOvertime"
import { useEffect, useState } from "react"
import { useLocation, useSearchParams } from "react-router-dom"
import {downloadFile, getAllQueryParams, setQueryParams} from "utils/utils"
import moment from "moment";
import {useFetch} from "../../services/useFetch";
import {API_OVERTIME} from "../../services/apiOvertime";

const OPTION_BULK = {
    APPROVE: 'approve',
    REJECT: 'reject',
}

const TAB_LIST = [
    { id: 'submitted', name: 'submitted' },
    { id: 'approved', name: 'approved' },
    { id: 'rejected', name: 'rejected' },
    { id: 'cancelled', name: 'cancelled' },
]

const PREVIEW_TAB = [
    { id: 'information', name: 'informasi' },
    { id: 'history', name: 'history' },
]

export const OvertimeContainer = () => {
    const location = useLocation()
    const [searchParams, setSearchParams] = useSearchParams()
    const status = searchParams.get('status')
    const defaultQuery = {
        page: 1,
        limit: 20,
        search: '',
        order_by: "requested_at",
        order_type: "desc",
        status: TAB_LIST[0].id,
        start_date: moment().startOf('month').format('YYYY-MM-DD'),
        end_date: moment().endOf('month').format('YYYY-MM-DD')
    }
    const query = searchParams.size > 0 ? getAllQueryParams(searchParams) : defaultQuery
    const [selectedEmployeeList, setSelectedEmployeeList] = useState([])
    const [selectedBulkOption, setSelectedBulkOption] = useState()
    const [activePreviewTab, setActivePreviewTab] = useState(PREVIEW_TAB[0].id)
    const [selectedPreviewFile, setSelectedPreviewFile] = useState()
    const [rejectReason, setRejectReason] = useState(null)
    const [toastMessage, setToastMessage] = useState('')
    const [modal, setModal] = useState({
        limit: false,
        export: false,
        toast: false,
        preview: false,
    })
    const { data: overtimeList, fetching: getOvertimeList, isLoading } = useFetch(API_OVERTIME.OVERTIME_LIST, defaultQuery)
    const { data: previewOvertime, fetching: getPreviewOvertime} = useFetch(API_OVERTIME.PREVIEW_OVERTIME)
    const {fetching: exportOvertime} = useFetch(API_OVERTIME.EXPORT_OVERTIME_LIST)
    const {fetching: approveOvertime} = useFetch(API_OVERTIME.APPROVE_OVERTIME)
    const {fetching: rejectOvertime} = useFetch(API_OVERTIME.REJECT_OVERTIME)

    useEffect(() => {
        fetchOvertimeList({...query})
    }, [location.search])

    const onChangeTab = (status) => {
        const params = { ...defaultQuery, status: status.id }
        setSearchParams(params, { replace: true })
    }

    const onChangeQuery = (queryParams) => {
        const params = setQueryParams({ ...query, ...queryParams })
        setSearchParams(params, { replace: true })
    }

    const fetchOvertimeList = (item = query) => {
        getOvertimeList(item)
    }

    const onExport = () => {
        const dataFilter = query?.start_date ? query : {...defaultQuery, ...query}
        const payload = {...dataFilter, overtime_request_ids: selectedEmployeeList.map(item => item.id)}
        exportOvertime(payload, '', {}, 'blob')
            .then(response => {
                downloadFile(null, response, `Overtime-Export-${moment().format('DD-MM-YYYY')}.xlsx`)
                setToastMessage("Anda berhasil export data overtime")
                setModal({...modal, export: false, toast: true})
            })
    }

    const onBulkUpdate = (option) => {
        if (selectedEmployeeList.length === 0 && !modal?.preview) {
            return Alert({
                icon: 'warning',
                title: 'Silakan pilih employee terlebih dahulu.',
                autoClose: true,
            })
        }
        setSelectedBulkOption(option)
        setModal({ ...modal, bulk_update: true })
    }

    const onPreview = (item) => {
        getPreviewOvertime({}, item?.id)
        setActivePreviewTab(PREVIEW_TAB[0].id)
        setModal({ ...modal, preview: true })
    }

    const getPromiseAPI = (type, bodyRequest) => {
        if (type === OPTION_BULK?.APPROVE) return approveOvertime(bodyRequest)
        return rejectOvertime(bodyRequest)
    }

    const errorMessage = (data = []) => {
        let message = ''
        data?.forEach(item => {
            message += `\nSomething happen in ID ${item?.overtime_request_id}:\nStatus: ${item?.success ? 'Success' : 'Failed'}\nMessage: ${item?.message}\n`
        })

        return message
    }

    const onUpdateStatusRequest = (status, id = null) => {
        const bodyRequest = {
            overtime_request_ids: id ? [id] : selectedEmployeeList.map(item => item.id)
        }
        if (status === OPTION_BULK?.REJECT) bodyRequest['reject_reason'] = rejectReason

        getPromiseAPI(status, bodyRequest)
            .then(() => {
                setToastMessage(`Overtime ${status === OPTION_BULK?.APPROVE ? 'Approved !!' : 'Rejected !!'}`)
                setModal({...modal, bulk_update: false, preview: false, toast: true})
                fetchOvertimeList()
            }).catch(err => {
            if (err?.code === '400-L003') {
                Alert({
                    icon: 'error',
                    title: err?.message,
                    message: errorMessage(err?.data),
                })
            }
            setModal({ ...modal, bulk_update: false, preview: false})
        })
    }

    const onPreviewFile = (item) => {
        setSelectedPreviewFile(item)
        setModal({...modal, previewFile: true})
    }

    const props = {
        tabList: TAB_LIST,
        dataEmployee: overtimeList?.data,
        dataPreview: previewOvertime?.data,
        status: status || TAB_LIST[0].id,
        optionBulk: OPTION_BULK,
        configTable: TABLE_REQUEST_OVERTIME,
        previewTab: PREVIEW_TAB,
        activePreviewTab,
        setActivePreviewTab,
        selectedEmployeeList,
        selectedBulkOption,
        isLoading,
        query,
        modal,
        toastMessage,
        rejectReason,
        setRejectReason,
        onChangeTab,
        onChangeQuery,
        onSelectItem: (employeeList) => setSelectedEmployeeList([...employeeList]),
        onExport,
        onBulkUpdate,
        onPreview,
        onSetModal: (e) => setModal({ ...modal, ...e }),
        onUpdateStatusRequest,
        selectedPreviewFile,
        onPreviewFile
    }

    return (
        <Overtime {...props} />
    )
}
