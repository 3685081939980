import { Alert } from "components/commons/alert"
import { PersonalDocument } from "components/employee/employee-add/PersonalDocument"
import { ACTION_TYPE } from "constants/constant"
import { DEFAULT_FORM_PERSONAL_DOCUMENT } from "constants/defaultFormEmployee"
import { useValidation } from "hooks/useValidation"
import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setPersonalDocument } from "reducers/addEmployeeReducer"
import { capitalize } from "utils/utils"

export const PersonalDocumentContainer = ({ optionsPersonalDocumentType }) => {
    const dispatch = useDispatch()
    const dataEmployee = useSelector((state) => state.addEmployeeReducer)
    const [showModal, setShowModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [dataPersonalDocument, setDataPersonalDocument] = useState(DEFAULT_FORM_PERSONAL_DOCUMENT)
    const [file, setFile] = useState(null)
    const [selectedIndex, setSelectedIndex] = useState()
    const [actionType, setActionType] = useState(ACTION_TYPE.CREATE)
    const { isFormValid } = useValidation({ form: { ...dataPersonalDocument, currentFile: file || dataPersonalDocument?.url } })

    const onChangeForm = (e) => {
        const { name, value } = e
        setDataPersonalDocument({ ...dataPersonalDocument, [name]: value })
    }

    const onCloseModal = () => {
        setShowModal(false)
        setDataPersonalDocument(DEFAULT_FORM_PERSONAL_DOCUMENT)
        setActionType(ACTION_TYPE.CREATE)
        setFile(null)
    }

    const onSubmit = () => {
        const newData = [...dataEmployee.personal_documents]
        const newDataPersonalDocument = {
            ...dataPersonalDocument,
            type: dataPersonalDocument?.type?.value,
            ...(file && { url: file })
        }
        switch (actionType) {
            case ACTION_TYPE.CREATE:
                newData.push(newDataPersonalDocument)
                break;
            case ACTION_TYPE.EDIT:
                newData[selectedIndex] = newDataPersonalDocument
                break;
            default:
        }
        dispatch(setPersonalDocument(newData))
        onCloseModal()
    }

    const handleEdit = (item) => {
        if (item?.url?.name) {
            setFile(item?.url)
        } else {
            setFile(null)
        }

        setDataPersonalDocument({
            ...item,
            type: {
                label: capitalize(item.type),
                value: item.type,
            }
        })
        setActionType(ACTION_TYPE.EDIT)
        setShowModal(true)
    }

    const handleRemove = (index) => {
        const newData = [...dataEmployee.personal_documents]
        newData.splice(index, 1)
        dispatch(setPersonalDocument(newData))
        setShowDeleteModal(false)
    }

    const onActionRow = ({ index, item, actionType }) => {
        setSelectedIndex(index)
        switch (actionType) {
            case ACTION_TYPE.EDIT:
                return handleEdit(item);
            case ACTION_TYPE.DELETE:
                return setShowDeleteModal(true)
            default:
        }
    }

    const onUploadFile = (e) => {
        const file = e.target.files[0]
        if (!file) return

        const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.pdf)$/i;
        if (!allowedExtensions.exec(file?.name)) {
            e.target.value = null
            return Alert({
                icon: 'error',
                title: 'Failed',
                message: 'Format file tidak sesuai',
            })
        }

        if (file?.size > 21000000) {
            e.target.value = null
            return Alert({
                icon: 'error',
                title: 'Failed',
                message: 'File terlalu besar (Maksimal 20 MB)',
            })
        }

        const reader = new FileReader()
        reader.onload = () => {
            e.target.value = null
            setFile(file)
        }
        reader.readAsDataURL(file)
    }

    const props = {
        optionsPersonalDocumentType,
        dataPersonalDocument,
        isFormValid: isFormValid(),
        isEdit: actionType === ACTION_TYPE.EDIT,
        form: dataEmployee.personal_documents,
        showModal,
        onShowModal: () => setShowModal(true),
        onCloseModal,
        onChangeForm,
        onSubmit,
        onActionRow,
        showDeleteModal,
        onCloseDeleteModal: () => setShowDeleteModal(false),
        onDelete: () => handleRemove(selectedIndex),
        onUploadFile,
        file,
    }

    return (
        <PersonalDocument {...props} />
    )
}
