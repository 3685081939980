import {HRISAnnouncementForm} from "../../../components/general-settings/hris-announcement-form/HRISAnnouncementForm";
import {useDispatch} from "react-redux";
import {useEffect, useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import {setAnnouncementToast} from "../../../reducers/formHRISAnnouncementReducer";
import {useFetch} from "../../../services/useFetch";
import {API_HRIS_ANNOUNCEMENT} from "../../../services/apiHrisAnnouncement";
import {constructOptionsSelect, formatDateMoment, uploadFileToS3} from "../../../utils/utils";
import {Alert} from "../../../components/commons/alert";
import moment from "moment";

export const INITIAL_STATE = {
    title: null,
    description: null,
    company_ids: [],
    attachments: [],
    published_date: null,
    published_time: null
}

const getFieldName = {
    title: 'Judul pengumuman',
    description: 'Uraian pengumuman',
    published_date: 'Tanggal publish',
    published_time: 'Waktu publish',
    company_ids: 'Perusahaan'
}

export const HRISAnnouncementFormContainer = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const [errorForm, setErrorForm] = useState({})
    const [companiesList, setCompaniesList] = useState([])
    const announcementId = searchParams.get('announcementId')
    const [announcementDetail, setAnnouncementDetail] = useState(INITIAL_STATE)
    const [files, setFiles] = useState([])
    const [isLoadingUpload, setIsLoadingUpload] = useState(false)
    const {fetching: getCompanies} = useFetch(API_HRIS_ANNOUNCEMENT.COMPANIES_LIST)
    const {fetching: createAnnouncement, isLoading: isCreating} = useFetch(API_HRIS_ANNOUNCEMENT.CREATE_ANNOUNCEMENT)
    const {fetching: getUrlUploadAttachments} = useFetch(API_HRIS_ANNOUNCEMENT.URL_ATTACHMENTS)
    const {fetching: getDetailAnnouncement} = useFetch(API_HRIS_ANNOUNCEMENT.DETAIL_ANNOUNCEMENT)
    const {fetching: updateAnnouncement, isLoading: isUpdating} = useFetch(API_HRIS_ANNOUNCEMENT.UPDATE_ANNOUNCEMENT)

    const onChangeValue = (item, name) => {
        const newData = name ? {[name]: item} : {[item?.name]: item?.value}
        setAnnouncementDetail({...announcementDetail, ...newData})
    }

    const onUploadFile = (e, index) => {
        const file = e.target?.files[0]
        if (!file) return

        const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.pdf)$/i;
        if (!allowedExtensions.exec(file?.name)) {
            e.target.value = null
            return Alert({
                icon: 'error',
                title: 'Failed',
                message: 'Format file tidak sesuai',
            })
        }

        if (file?.size > 21000000) {
            e.target.value = null
            return Alert({
                icon: 'error',
                title: 'Failed',
                message: 'File terlalu besar (Maksimal 20 MB)',
            })
        }

        const reader = new FileReader()
        reader.onload = () => {
            e.target.value = null
            const fileName = file
            const copyFiles = [...files]
            if (index + 1) {
                copyFiles[index] = fileName
            } else {
                copyFiles.push(fileName)
            }
            setFiles(copyFiles)
        }
        reader.readAsDataURL(file)
    }

    const onDeleteFile = (index) => {
        const copyFiles = [...files]
        copyFiles.splice(index, 1)
        setFiles(copyFiles)
    }

    const validateForm = () => {
        return new Promise((resolve, reject) => {
            const errors = {}
            Object.keys(announcementDetail).forEach(key => {
                const selected_publish = moment(`${announcementDetail?.published_date} ${announcementDetail?.published_time}`)
                const duration = moment.duration(selected_publish.diff(moment()))
                if (key !== 'attachments' && (!announcementDetail[key] || !announcementDetail[key].length)) {
                    errors[key] = `${getFieldName[key]} tidak boleh kosong`
                } else if (key === 'published_time' && (Math.floor(duration.asMinutes()) < 10)) {
                    errors[key] = `${getFieldName[key]} tidak boleh kurang dari 10 menit`
                }
            })
            setErrorForm(errors)
            if (!Object.keys(errors).length) resolve()
        })
    }

    const onSubmitForm = () => {
        validateForm()
            .then(() => {
                setIsLoadingUpload(true)
                const uploadedFiles = []
                const uploadFiles = []
                const filesName = []

                files.map(item => {
                    if (item?.name) {
                        filesName.push(item?.name?.replace(/\s/g, ''))
                        uploadFiles.push(item)
                    } else {
                        uploadedFiles.push(item)
                    }
                })

                if (!filesName.length) return onSave(uploadedFiles)
                setUploadAttachments(filesName, uploadFiles, uploadedFiles)
            })
    }

    const setUploadAttachments = async (filesName, uploadFiles, uploadedFiles) => {
        try {
            const s3Urls = await getUrlUploadAttachments({files: filesName})
            const apiS3list = s3Urls?.data?.map(async (item, index) => {
                await uploadFileToS3(item, uploadFiles[index])
                uploadedFiles.push(`${item.url}${item.fields.key}`)
            })
            await Promise.all(apiS3list)
            onSave(uploadedFiles)
        } catch (err) {
            setIsLoadingUpload(false)
            console.log('upload failed')
        }
    }

    const onSave = (urls) => {
        const combineObj = {
            ...announcementDetail,
            published_at: moment(`${announcementDetail?.published_date} ${announcementDetail?.published_time}`).utc().format('YYYY-MM-DD HH:mm'),
            company_ids: announcementDetail?.company_ids?.map(item => item?.value),
            attachments: urls
        }
        const {published_date, published_time, ...payload} = combineObj

        if (!announcementId) {
            createAnnouncement(payload)
                .then(() => {
                    navigate('/general-settings/hris-announcement')
                    dispatch(setAnnouncementToast({message: 'Announcement Created', isShowToast: true}))
                })
        } else {
            updateAnnouncement(payload, announcementId)
                .then(() => {
                    navigate('/general-settings/hris-announcement')
                    dispatch(setAnnouncementToast({message: 'Announcement Updated', isShowToast: true}))
                })
        }
    }

    useEffect(() => {
        getCompanies({})
            .then(res => setCompaniesList(constructOptionsSelect(res?.data, 'name', 'id')))

        if (announcementId) {
            getDetailAnnouncement({}, announcementId)
                .then(response => {
                    const combineObj = {
                        ...response?.data,
                        company_ids: constructOptionsSelect(response?.data?.companies, 'name', 'id'),
                        published_date: formatDateMoment(response?.data?.published_at, 'YYYY-MM-DD'),
                        published_time: formatDateMoment(response?.data?.published_at, 'HH:mm')
                    }
                    const {id, published_at, companies, attachments, ...dataRest} = combineObj
                    setAnnouncementDetail({...INITIAL_STATE, ...dataRest})
                    setFiles(response?.data?.attachments?.map(item => item?.url))
                })
        }
    }, [])

    const props = {
        announcementId,
        announcementDetail,
        files,
        companiesList,
        onUploadFile,
        onDeleteFile,
        onChangeValue,
        isLoadingUpload,
        isCreating,
        isUpdating,
        onSubmitForm,
        errorForm
    }

    return (
        <HRISAnnouncementForm {...props}/>
    )
}
