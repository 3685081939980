import clsx from "clsx";
import Button from "../button";
import icCalendar02 from "../../../assets/images/ic-calendar-02.svg";
import icChevDownGrey from "../../../assets/images/ic-chev-down-grey.svg";
import {useEffect, useState} from "react";
import './monthpicker.scss';
import icLeft from "../../../assets/images/ic-left.svg";
import icRight from "../../../assets/images/ic-right.svg";
import moment from "moment";
import {useClickOutside} from "../../../hooks/useClickOutside";
import icCloseRoundFill from "../../../assets/images/ic-close-round-fill.svg";

const MONTH = [
    {label: 'Januari', key: 'jan', value: 0},
    {label: 'Februari', key: 'feb', value: 1},
    {label: 'Maret', key: 'mar', value: 2},
    {label: 'April', key: 'apr', value: 3},
    {label: 'Mei', key: 'mei', value: 4},
    {label: 'Juni', key: 'jun', value: 5},
    {label: 'Juli', key: 'jul', value: 6},
    {label: 'Agustus', key: 'agu', value: 7},
    {label: 'September', key: 'sep', value: 8},
    {label: 'Oktober', key: 'okt', value: 9},
    {label: 'November', key: 'nov', value: 10},
    {label: 'Desember', key: 'des', value: 11}
]

const MonthPicker = (
    {
        className,
        name = 'avo-month-picker',
        format,
        placeholder = 'Select Month',
        value,
        onChange,
        minYear,
        maxYear,
    }) => {
    const defaultYear = moment().get('year')
    const END_YEAR = 1971
    const [modal, setModal] = useState(false)
    const [selectedValue, setSelectedValue] = useState({})

    const onClickButtonModal = (value) => {
        setModal(value)
    }

    const getMonth = (value) => {
        return MONTH[moment(value).get('month')]
    }

    const getYear = (value) => {
        return moment(value).get('year')
    }

    const isDisabledArrowButton = (direction) => {
        if (direction === 'right') return getYear(selectedValue) === (maxYear || defaultYear)
        return getYear(selectedValue) === (minYear || END_YEAR)
    }

    const onClickArrow = (type) => {
        if (type === 'next') {
            setSelectedValue(moment(selectedValue).add(1, 'year'))
        } else {
            setSelectedValue(moment(selectedValue).subtract(1, 'year'))
        }
    }

    const onClickMonth = (item) => {
        if (onChange) onChange(moment(selectedValue).set('month', item).format(format))
        onClickButtonModal(false)
    }

    const onClearSelected = (e) => {
        if (e.stopPropagation) e.stopPropagation()
        if (onChange) onChange(null)
        setSelectedValue({})
        onClickButtonModal(false)
    }

    useEffect(() => {
        if (value) setSelectedValue(moment(value))
    }, [value])

    useClickOutside({
        callback: () => onClickButtonModal(false),
        elementID: `month-picker-${name}`
    })

    return (
        <div id={`month-picker-${name}`} className={clsx('avo__monthpicker', className)}>
            <div className='button-modal'>
                <Button
                    className='button-value'
                    type='outline'
                    title={value ?
                        <>
                            <p>{`${getMonth(value)?.label} ${getYear(value)}`}</p>
                            <img src={icCloseRoundFill} alt="ic-close-round-fill" onClick={onClearSelected}/>
                        </> :
                        placeholder
                    }
                    icon={icCalendar02}
                    iconAfter={icChevDownGrey}
                    onClick={() => onClickButtonModal(!modal)}
                />
                <div className={clsx('modal', modal ? 'show' : 'hide')}>
                    <div className='header-wrapper'>
                        <Button
                            type='text'
                            icon={icLeft}
                            disabled={isDisabledArrowButton('left')}
                            onClick={() => onClickArrow('previous')}
                        />
                        <p className='selected-year'>{getYear(selectedValue)}</p>
                        <Button
                            type='text'
                            icon={icRight}
                            disabled={isDisabledArrowButton('right')}
                            onClick={() => onClickArrow('next')}
                        />
                    </div>
                    <div className='wrapper-month'>
                        {MONTH.map((item, index) => (
                            <div
                                key={index}
                                className={clsx('item', item?.key === getMonth(value)?.key && 'selected-month')}
                                onClick={() => onClickMonth(item?.value)}
                            >
                                {item?.key}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MonthPicker
