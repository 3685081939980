import { Alert } from "components/commons/alert";
import { AccountActivation } from "components/management-account/account-activation/AccountActivation";
import { TABLE_ACCOUNT_ACTIVATION } from "configs/management-account/configTableAccountActivation";
import { ACTION_TYPE, STATUS_EMPLOYEE } from "constants/constant";
import moment from "moment";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { API_ACTIVATION } from "services/apiActivation";
import { useFetch } from "services/useFetch";
import { capitalize, downloadFile, getAllQueryParams, setQueryParams } from "utils/utils";
import {useDispatch, useSelector} from "react-redux";
import {setToast} from "../../../reducers/accountReducer";

export const SCOPE = {
    ACTIVE: 'active',
    FORMER: 'former',
}

const SETTING = {
    STATUS: 'Status',
    ROLE: 'Role',
}

const TAB_LIST = [
    { id: SCOPE.ACTIVE, name: 'Active Employee', key: SCOPE.ACTIVE },
    { id: SCOPE.FORMER, name: 'Former Employee', key: SCOPE.FORMER },
]

const FILTER_PARAMS = ['department_ids', 'division_ids', 'subdivision_ids', 'status', 'start_date', 'end_date']

const OPTION_STATUS = [
    { label: capitalize(STATUS_EMPLOYEE.REGISTERED), value: STATUS_EMPLOYEE.REGISTERED },
    { label: capitalize(STATUS_EMPLOYEE.ACTIVE), value: STATUS_EMPLOYEE.ACTIVE },
    { label: capitalize(STATUS_EMPLOYEE.SUSPEND), value: STATUS_EMPLOYEE.SUSPEND },
    { label: capitalize(STATUS_EMPLOYEE.INACTIVE), value: STATUS_EMPLOYEE.INACTIVE },
    { label: capitalize(STATUS_EMPLOYEE.BLACKLIST), value: STATUS_EMPLOYEE.BLACKLIST },
    { label: capitalize(STATUS_EMPLOYEE.CONTRACT_REJECTED.replace('-', ' ')), value: STATUS_EMPLOYEE.CONTRACT_REJECTED },
]

export const AccountActivationContainer = () => {
    const dispatch = useDispatch()
    const location = useLocation()
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams();
    const scope = searchParams.get('scope')
    const defaultQuery = {
        page: 1,
        limit: 20,
        order_by: "created_at",
        order_type: "desc",
        scope: SCOPE.ACTIVE,
    }
    const query = searchParams.size > 0 ? getAllQueryParams(searchParams) : defaultQuery
    const [selectedEmployeeList, setSelectedEmployeeList] = useState([])
    const [selectedEmployee, setSelectedEmployee] = useState()
    const [selectedStatus, setSelectedStatus] = useState()
    const [queryFilter, setQueryFilter] = useState()
    const [modal, setModal] = useState({
        limit: false,
        export: false,
        filter: false,
        status: false
    })
    const {toast} = useSelector(state => state.accountReducer)
    const { data: dataEmployee, fetching: getEmployeeList, isLoading } = useFetch(API_ACTIVATION.LIST_EMPLOYEE_ACTIVATION)
    const { fetching: updateStatusEmployee, isLoading: isLoadingUpdateStatus } = useFetch(API_ACTIVATION.UPDATE_STATUS_EMPLOYEE)
    const { fetching: exportAccountActivation } = useFetch(API_ACTIVATION.EXPORT_EMPLOYEE_ACTIVATION)

    useEffect(() => {
        getEmployeeListData()
    }, [location.search])

    const getEmployeeListData = () => {
        const newQuery = { ...query }
        const newQueryFilter = {}
        Object.entries(newQuery).forEach(([key, item]) => {
            if (FILTER_PARAMS.includes(key)) {
                const valueOfQuery = FILTER_PARAMS.slice(0, 4).includes(key) ? item.split(',') : item
                newQuery[key] = valueOfQuery
                newQueryFilter[key] = valueOfQuery
            }
        })
        setQueryFilter(newQueryFilter)
        getEmployeeList(newQuery)
    }

    const clearToast = () => {
        dispatch(setToast({isShowToast: false, message: ''}))
    }

    const onChangeTab = (scope) => {
        const params = { ...defaultQuery, scope: scope.key }
        setSearchParams(params, { replace: true })
    }

    const onChangeQuery = (queryParams) => {
        const params = setQueryParams({ ...query, ...queryParams })
        setSearchParams(params, { replace: true })
    }

    const onExport = (e) => {
        const params = {
            ...query,
            ...queryFilter,
            employee_ids: selectedEmployeeList.map((item) => item.id)
        }

        exportAccountActivation(params, '', {}, 'blob')
            .then(res => {
                downloadFile(null, res, `Account-Activation-${capitalize(scope || TAB_LIST[0].key)}-Employee-Export-${moment().format('DD-MM-YYYY')}.xlsx`)
                setModal({ ...modal, export: false})
                dispatch(setToast({isShowToast: true, message: `Account ${capitalize(scope || TAB_LIST[0].key)} Employee Exported !!`}))
            })
    }

    const onSelectSetting = (setting) => {
        if (selectedEmployeeList.length === 0) {
            return Alert({
                icon: 'warning',
                title: 'Silakan pilih employee terlebih dahulu.',
                autoClose: true,
            })
        }
        if (setting === SETTING.ROLE) {
            const employeeId = selectedEmployeeList.map((item) => item.id).join(',')
            return navigate(`/management-account/account-activation/update-role?employeeId=${employeeId}`, { state: { selectedEmployeeList: selectedEmployeeList } })
        }
        setModal({ ...modal, status: true })
    }

    const onActionRow = ({ item, actionType }) => {
        switch (actionType) {
            case ACTION_TYPE.UPDATE_STATUS:
                setSelectedEmployee({ ...item })
                setModal({ ...modal, status: true })
                return
            case ACTION_TYPE.UPDATE_ROLE:
                return navigate(`/management-account/account-activation/update-role?employeeId=${item.id}`)
            default:
        }
    }

    const onCloseModalStatus = () => {
        setModal({ ...modal, status: false })
        setSelectedEmployee()
        setSelectedStatus()
    }

    const onUpdateStatus = () => {
        const params = {
            status: selectedStatus?.value,
            employee_ids: selectedEmployee ? [selectedEmployee.id] : selectedEmployeeList.map((item) => item.id)
        }

        updateStatusEmployee(params).then(() => {
            getEmployeeListData()
            onCloseModalStatus()
            Alert({
                icon: 'success',
                title: 'Success',
                message: 'Status Karyawan Berhasil Diperbarui!',
            })
        }).catch((error) => {
            if (error.code === '400-C005') {
                Alert({
                    icon: 'error',
                    title: 'Oops!',
                    message: 'Contract processing is not completed yet',
                })
            }
        })
    }

    const props = {
        tabList: TAB_LIST,
        optionStatus: OPTION_STATUS,
        dataEmployee: dataEmployee?.data,
        isLoading,
        query,
        scope: scope || TAB_LIST[0].key,
        setting: SETTING,
        configTable: TABLE_ACCOUNT_ACTIVATION,
        selectedEmployee,
        selectedEmployeeList,
        selectedStatus,
        setSelectedStatus,
        toast,
        clearToast,
        onChangeTab,
        onChangeQuery,
        onSelectItem: (employeeList) => setSelectedEmployeeList([...employeeList]),
        onExport,
        onSelectSetting,
        modal,
        onSetModal: (e) => setModal({ ...modal, ...e }),
        onCloseModalStatus,
        onActionRow,
        onUpdateStatus,
        isLoadingUpdateStatus,
        queryFilter,
    }

    return (
        <AccountActivation {...props} />
    )
}
