import icPlus01 from "assets/images/ic-plus-01.svg"
import Button from "components/commons/button"
import DeleteModal from "components/commons/delete-modal"
import Table from "components/commons/table"
import {CreateJobTitle} from "components/employee/create-modal/CreateJobTitle"

export const JobTitleTab = ({
        configTable,
        optionsCompany,
        optionsJobTitle,
        dataJobTitle,
        isFormValid,
        isEdit,
        job_title_employees,
        showModal,
        onShowModal,
        onCloseModal,
        onChangeForm,
        onSubmit,
        onActionRow,
        showDeleteModal,
        onCloseDeleteModal,
        onDelete,
        isLoadingSubmit,
        isLoadingDelete,
    }) => {

    return (
        <>
            <div className={"flex items-center justify-between"}>
                <p className={"text-neutral-80 font-semibold text-md"}>{'Informasi Job Title'}</p>
                <Button title={'Data Job Title'} icon={icPlus01} onClick={onShowModal}/>
            </div>
            <Table
                className="my-6"
                classNameHeader="!bg-primary-10 !text-md !text-primary-50"
                classNameRow="!py-6"
                name={configTable.name}
                columns={configTable.column}
                data={job_title_employees}
                onClickItem={onActionRow}
            />

            <CreateJobTitle
                isFormValid={isFormValid}
                isEdit={isEdit}
                show={showModal}
                onHide={onCloseModal}
                optionsCompany={optionsCompany}
                optionsJobTitle={optionsJobTitle}
                dataJobTitle={dataJobTitle}
                onChangeForm={onChangeForm}
                onSubmit={onSubmit}
                isLoadingSubmit={isLoadingSubmit}
            />

            <DeleteModal
                text={"Apakah anda yakin ingin menghapus data job title?"}
                show={showDeleteModal}
                onHide={onCloseDeleteModal}
                onDelete={onDelete}
                isLoading={isLoadingDelete}
            />
        </>
    )
}
