import Datepicker from "../../commons/datepicker";
import moment from "moment/moment";
import FormModal from "../../commons/form-modal";
import Timepicker from "../../commons/timepicker";
import {setCurrency} from "../../../utils/utils";
import ErrorMessage from "../../commons/error-message";

export const PayrollScheduleForm = (
    {
        title,
        show,
        onHide,
        payrollScheduleDetail = {},
        onChangeValue,
        isCreating,
        errorForm,
        onSubmitForm
    }) => {

    return (
        <FormModal
            title={title}
            show={show}
            width={'max-w-[639px]'}
            onHide={onHide}
            isLoading={isCreating}
            isDisabled={!payrollScheduleDetail?.date || !payrollScheduleDetail?.time}
            onSubmitForm={onSubmitForm}
        >
            <div className={"flex flex-col space-y-6 px-6 py-8"}>
                <div className={"flex items-center text-neutral-70"}>
                    <div className="w-1/3">Total Employee</div>
                    <div className={"font-medium text-lg text-neutral-80"}>{payrollScheduleDetail?.total_employee} Karyawan</div>
                </div>
                <div className={"flex items-center text-neutral-70"}>
                    <div className="w-1/3">Total Payroll</div>
                    <div className={"font-medium text-lg text-neutral-80"}>{setCurrency(payrollScheduleDetail?.total_payroll)}</div>
                </div>
                <div className={"flex items-center text-neutral-70"}>
                    <div className={"w-1/3 whitespace-pre-wrap"}>{`Pilih Tanggal \nPengiriman Payroll`}</div>
                    <Datepicker
                        className="flex-1"
                        value={payrollScheduleDetail?.date}
                        format='YYYY-MM-DD'
                        placeholder="Pilih Tanggal"
                        minDate={moment()}
                        onChange={(e) => onChangeValue(e, 'date')}
                    />
                </div>
                <div className={"flex text-neutral-70"}>
                    <div className={"w-1/3 whitespace-pre-wrap"}>{`Pilih Waktu \nPengiriman Payroll`}</div>
                    <div className="flex-1">
                        <Timepicker
                            className="flex-1"
                            name="time"
                            placeholder="Pilih Waktu"
                            value={payrollScheduleDetail?.time}
                            onChange={(e) => onChangeValue(e)}
                            isOnlyHour
                        />
                        <ErrorMessage className="mt-2" message={errorForm?.time}/>
                    </div>
                </div>
            </div>
        </FormModal>
    )
}
