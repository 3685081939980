import {ViewData} from "../../../components/employee/employee-import/ViewData";
import {Alert} from "../../../components/commons/alert";

export const ViewDataContainer = ({validatedData, onChangeStep}) => {
    const setToPreviousStep = () => {
        onChangeStep(1)
    }

    const onSubmitImport = () => {
        onChangeStep(4)
    }

    const errorMessage = (data = []) => {
        let message = ''
        data?.forEach(item => {
            message += `\nTerjadi error pada baris ${item?.row} excel\n`
            Object.keys(item?.errors)?.forEach(key => message += `- ${key}: ${item?.errors[key]}\n`)
        })

        return message
    }

    const showErrorDetail = () => {
        Alert({
            icon: 'error',
            title: 'Error Validate',
            message: errorMessage(validatedData?.error_detail),
        })
    }

    const props = {
        isValidated: !validatedData?.statistics?.total_invalid,
        dataImport: validatedData,
        setToPreviousStep,
        showErrorDetail,
        onSubmitImport,
    }

    return (
        <ViewData {...props}/>
    )
}
