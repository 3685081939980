import icFileUp from "assets/images/ic-file-up-02.svg"
import icSetting from "assets/images/ic-setting-2-primary.svg"
import icUserDefault from "assets/images/ic-user-profile.svg"
import clsx from "clsx"
import { ExportModal } from "components/commons/ExportModal"
import Badge from "components/commons/badge/Badge"
import Button from "components/commons/button"
import FormModal from "components/commons/form-modal"
import Search from "components/commons/search"
import Select from "components/commons/select"
import Table from "components/commons/table"
import Toast from "components/commons/toast"
import ViewList from "components/commons/view-list"
import { AccountActivationFilterContainer } from "containers/management-account/account-activation/AccountActivationFilterContainer"
import { useClickOutside } from "hooks/useClickOutside"
import { useState } from "react"
import {InlineTabs} from "../../commons/InlineTabs";


export const AccountActivation = (
    {
        tabList,
        optionStatus,
        dataEmployee,
        isLoading,
        query,
        scope,
        setting,
        configTable,
        selectedEmployee,
        selectedEmployeeList,
        selectedStatus,
        setSelectedStatus,
        toast,
        clearToast,
        onChangeTab,
        onChangeQuery,
        onSelectItem,
        onExport,
        onSelectSetting,
        modal,
        onSetModal,
        onCloseModalStatus,
        onActionRow,
        onUpdateStatus,
        isLoadingUpdateStatus,
        queryFilter,
    }) => {
    const elementID = 'dropdownSetting'
    const [showSetting, setShowSetting] = useState(false)
    useClickOutside({ callback: () => showSetting && setShowSetting(false), elementID })

    const onSelect = (e) => {
        setShowSetting(false)
        onSelectSetting(e)
    }

    return (
        <>
            <InlineTabs tabList={tabList} activeTab={scope} onClickTab={onChangeTab} />
            <div className={'flex flex-row justify-between gap-3 px-6 py-4'}>
                <div className={'flex flex-row gap-3'}>
                    <Search value={query?.search} onSearch={(search) => onChangeQuery({ page: 1, search })} />
                    <AccountActivationFilterContainer
                        show={modal.filter}
                        onShow={() => onSetModal({ filter: true })}
                        onClose={() => onSetModal({ filter: false })}
                        queryFilter={queryFilter}
                        onSubmit={(queryFilter) => onChangeQuery({ ...queryFilter, page: 1 })}
                        scope={query?.scope}
                    />
                    <ViewList
                        show={modal?.limit}
                        onShow={() => onSetModal({ limit: true })}
                        onClose={() => onSetModal({ limit: false })}
                        value={query?.limit}
                        onSelectSize={(limit) => onChangeQuery({ page: 1, limit })}
                    />
                    <Button title="Export" icon={icFileUp} type="outline" onClick={() => onSetModal({ export: true })} />
                </div>
                <div className="relative">
                    <Button title="Setting" type="outline" icon={icSetting} onClick={() => setShowSetting(!showSetting)} />
                    <div
                        id={elementID}
                        className={clsx(
                            "absolute bg-white right-0 mt-1 p-1.5 border border-neutral-20 rounded-lg shadow-sm whitespace-nowrap transition-all duration-200 w-[180px] z-10",
                            showSetting ? "opacity-100 visible" : "opacity-0 collapse"
                        )}
                    >
                        {Object.entries(setting).map(([key, value]) => (
                            <div key={key} className="hover:bg-primary-10 px-4 py-2.5 rounded-lg cursor-pointer transition-all duration-200" onClick={() => onSelect(value)}>{`Update ${value}`}</div>
                        ))}
                    </div>
                </div>
            </div>
            <Table
                className="my-3"
                name={configTable.name}
                data={dataEmployee?.result}
                columns={configTable.column}
                size={dataEmployee?.limit}
                totalPage={dataEmployee?.total_page}
                totalResult={dataEmployee?.total_result}
                currentPage={query.page}
                onChangePage={(page) => onChangeQuery({ page })}
                onSelectItem={onSelectItem}
                onClickItem={onActionRow}
                onClickSort={(sortQuery) => onChangeQuery({ ...sortQuery, page: 1 })}
                loading={isLoading}
            />

            <ExportModal
                title={'Apakah anda yakin ingin melakukan export data?'}
                description={`Total data ${scope} employee: ${selectedEmployeeList?.length || dataEmployee?.total_result}`}
                show={modal?.export}
                onExportHandler={onExport}
                onCancelHandler={() => onSetModal({ export: false })}
            />

            <Toast
                show={toast?.isShowToast}
                message={toast?.message}
                onClose={() => clearToast()}
            />

            <FormModal
                show={modal?.status}
                onHide={onCloseModalStatus}
                title='Update Status'
                onSubmitForm={onUpdateStatus}
                isDisabled={!selectedStatus}
                isLoading={isLoadingUpdateStatus}
            >
                <div className={"px-8 py-6 mb-2 space-y-6"}>
                    <div className="bg-neutral-10 rounded-lg p-4">
                        <div className="flex items-center justify-between">
                            {selectedEmployee || selectedEmployeeList?.length === 1 ?
                                <>
                                    <div className="flex items-center gap-4">
                                        <img className="w-7 h-7 border border-white bg-white rounded-full -ml-1.5" src={selectedEmployee?.profile_picture || selectedEmployeeList?.[0]?.profile_picture || icUserDefault} alt={selectedEmployee?.full_name || selectedEmployeeList?.[0]?.full_name} />
                                        <div className="flex flex-col gap-1">
                                            <span className="font-semibold">{selectedEmployee?.full_name || selectedEmployeeList?.[0]?.full_name}</span>
                                            <span className="font-medium text-neutral-70">{'Role'}<span className="text-neutral-80 ml-2">{selectedEmployee?.roles || selectedEmployeeList?.[0]?.roles || '-'}</span></span>
                                        </div>
                                    </div>
                                    <div className="flex items-center gap-2">
                                        <span className="font-medium text-neutral-70">{'Status'}</span>
                                        <Badge type={selectedEmployee?.employee_status || selectedEmployeeList?.[0]?.employee_status} title={selectedEmployee?.employee_status || selectedEmployeeList?.[0]?.employee_status} />
                                    </div>
                                </>
                                :
                                <div className="flex items-center gap-4">
                                    <div className="flex ml-1.5">
                                        {selectedEmployeeList?.map((item, index) => (
                                            <img key={index} className="w-7 h-7 border border-white bg-white rounded-full -ml-1.5" src={item?.profile_picture || icUserDefault} alt={item?.full_name} />
                                        ))}
                                    </div>
                                    <span className="font-medium"> {`Total User Selected: ${selectedEmployeeList?.length}`} </span>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="flex items-center gap-3">
                        <div className="font-medium w-1/3 text-neutral-70 text-md">{'Pilih Status'}</div>
                        <Select
                            name='status'
                            className='flex-1'
                            placeholder={'Pilih status'}
                            options={optionStatus}
                            value={selectedStatus}
                            onChange={({ value }) => setSelectedStatus(value)}
                        />
                    </div>
                </div>
            </FormModal>
        </>
    )
}
