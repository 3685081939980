import {combineReducers, configureStore} from '@reduxjs/toolkit'
import addEmployeeReducer from "./addEmployeeReducer"
import employeeDetailReducer from "./employeeDetailReducer";
import formWorkTimeReducer from "./formWorkTimeReducer";
import matrixApprovalReducer from "./matrixApprovalReducer";
import organizationStructureReducer from "./organizationStructureReducer";
import contractReducer from "./contractReducer";
import employeeListReducer from "./employeeListReducer";
import companiesReducer from "./companiesReducer";
import attendanceReducer from "./attendanceReducer";
import formLeaveSettingReducer from "./formLeaveSettingReducer";
import formHRISAnnouncementReducer from "./formHRISAnnouncementReducer";
import payrollReducer from "./payrollReducer";
import accountReducer from "./accountReducer";

const rootReducer = combineReducers({
    addEmployeeReducer,
    employeeDetailReducer,
    formWorkTimeReducer,
    matrixApprovalReducer,
    organizationStructureReducer,
    contractReducer,
    employeeListReducer,
    companiesReducer,
    attendanceReducer,
    formLeaveSettingReducer,
    formHRISAnnouncementReducer,
    payrollReducer,
    accountReducer
})

const store = configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware => getDefaultMiddleware({serializableCheck: false})
})

export default store
