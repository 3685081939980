import { useSelector } from 'react-redux'

export const Home = () => {
    const { activatedCompany } = useSelector(state => state.companiesReducer)

    return (
        <div className='px-6 py-[10%]'>
            <div className='flex flex-col items-center pb-4'>
                <img src={activatedCompany?.logo} className='w-1/5 max-w-[200px]' alt={activatedCompany?.name} />
                <h1 className='font-bold text-4xl text-primary-50 mt-4'>{activatedCompany?.name}</h1>
            </div>
        </div>
    )
}
