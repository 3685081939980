import Input from "../../commons/input";
import FormModal from "../../commons/form-modal";
import Select from "../../commons/select";
import Switch from "../../commons/switch";

export const SettingComponentForm = (props) => {
    const {
        onSubmitForm,
        show,
        onHide,
        componentTypeOptions,
        formValue,
        onChangeFormValue,
        componentCategoryOptions,
        isFormValid
    } = props

    return (
        <FormModal
            title={`${formValue?.id ? 'Edit' : 'Create'} Komponen Payroll`}
            onSubmitForm={onSubmitForm}
            show={show}
            onHide={onHide}
            isDisabled={formValue?.id ? false : !isFormValid()}
        >
            <div className={"flex flex-col px-6 py-8 gap-[20px]"}>
                <div className="flex flex-row items-center">
                    <div className="w-1/3 text-neutral-70 required">Nama Komponen</div>
                    <Input className={'flex-1'} name="title" placeholder={`Inputkan nama komponen`}
                           value={formValue.title} onChange={(e) => onChangeFormValue(e)}/>
                </div>
                <div className="flex flex-row items-center">
                    <div className="w-1/3 text-neutral-70 required capitalize">Tipe Komponen</div>
                    <Select name="type"
                            className='flex-1'
                            placeholder={`Pilih tipe komponen`}
                            options={componentTypeOptions}
                            value={formValue.type}
                            onChange={(e) => onChangeFormValue(e)}
                            optionsClassName='max-h-[350px]'
                    />
                </div>
                <div className="flex flex-row items-center">
                    <div className="w-1/3 text-neutral-70 capitalize">Kategori Komponen</div>
                    <Select name="category"
                            className='flex-1'
                            placeholder={`Pilih kategori komponen`}
                            options={componentCategoryOptions}
                            value={formValue.category}
                            onChange={onChangeFormValue}
                            optionsClassName='max-h-[350px]'
                    />
                </div>
                <div className="flex flex-row">
                    <div className="w-1/3 text-neutral-70 required capitalize">Status</div>
                    <div className={"gap-5 flex flex-row items-center"}>
                        <Switch name={'is_enabled'} checked={!!formValue.is_enabled}
                                onChange={(e) => onChangeFormValue({name: e.name, value: e.checked})}/>
                        {!!formValue.is_enabled ? <p className={"font-bold text-primary-50"}>Active</p> : <p className={"font-bold text-gray-500"}>Inactive</p>}
                    </div>
                </div>
            </div>
        </FormModal>
    )
}
