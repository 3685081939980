import { METHOD } from "./method";

export const API_REQUEST_ATTENDANCE = {
    REQUEST_ATTENDANCE_LIST: {
        api: '/v1/hr-adm/attendance-request/list',
        method: METHOD.POST
    },
    EXPORT_REQUEST_ATTENDANCE_LIST: {
        api: '/v1/hr-adm/attendance-request/export',
        method: METHOD.POST
    },
    DETAIL_REQUEST_ATTENDANCE: {
        api: '/v1/hr-adm/attendance-request',
        method: METHOD.GET
    },
    APPROVE_REQUEST_ATTENDANCE: {
        api: '/v1/hr-adm/attendance-request/approve',
        method: METHOD.POST
    },
    REJECT_REQUEST_ATTENDANCE: {
        api: '/v1/hr-adm/attendance-request/reject',
        method: METHOD.POST
    },
    SCHEDULE_CATEGORY_LIST: {
        api: '/v1/hr-adm/attendance-request/schedule-category',
        method: METHOD.GET
    },
    LIST_JOB_UNIT: {
        api: '/v1/hr-adm/attendance-request/job-unit',
        method: METHOD.POST
    },
}
