import {useEffect, useState} from "react";
import {ImportData} from "../../../../components/payroll/create-payroll/import-data-payroll/ImportData";
import {useFetch} from "../../../../services/useFetch";
import {API_CREATE_PAYROLL} from "../../../../services/apiCreatePayroll";

export const ImportDataContainer = ({params, payrollId, payload, onChangeStep, setProgressing, onHide}) => {
    const [percentage, setPercentage] = useState(0)
    const [isComplete, setIsComplete] = useState(false)
    const API = params === 'percentage-incentive' ? API_CREATE_PAYROLL.IMPORT_INCENTIVE : API_CREATE_PAYROLL.IMPORT_COMPONENT
    const {fetching: importData, isLoading} = useFetch({...API, initPathVariable: payrollId})

    useEffect(() => {
        if (percentage === 10) {
            importData(payload, null, {'Content-Type': 'multipart/form-data'})
                .then().catch(() => {
                setProgressing(false)
                onChangeStep(1)
            })
        }
    }, [percentage])

    useEffect(() => {
        let timeout = null
        if (percentage <= 80) {
            timeout = setTimeout(() => {
                setPercentage(percentage + 10)
            }, 1000)
        } else if (!isLoading) {
            timeout = setTimeout(() => {
                if (percentage === 100) {
                    setIsComplete(true)
                    setProgressing(false)
                } else {
                    setPercentage(percentage + 10)
                }
            }, 1000)
        }

        return () => {
            clearTimeout(timeout)
        }
    }, [percentage, isLoading])

    const props = {
        params,
        percentage,
        isComplete,
        onHide
    }

    return (
        <ImportData {...props}/>
    )
}
