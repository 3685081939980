import { AttendanceDetail } from "components/attendance/attendance-list/AttendanceDetail";
import { useSearchParams } from "react-router-dom";
import { API_ATTENDANCE } from "services/apiAttendance";
import { useFetch } from "services/useFetch";

export const AttendanceDetailContainer = () => {
  const [searchParams] = useSearchParams()
  const attendanceId = searchParams.get('id')
  const { data: dataAttendanceDetail } = useFetch({ ...API_ATTENDANCE.ATTENDANCE_DETAIL, initPathVariable: attendanceId }, {}, { isFetching: true })

  return (
    <AttendanceDetail dataAttendanceDetail={dataAttendanceDetail?.data} />
  )
}
