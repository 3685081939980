import icEditPrimary from "assets/images/ic-edit-04-primary.svg"
import icTrashRed from "assets/images/ic-trash-01-red.svg"
import Button from "components/commons/button"
import { ACTION_TYPE } from "constants/constant"
import {setCurrency} from "../../utils/utils";

export const TABLE_OVERTIME_LOCATION = {
    name: 'Lokasi Lembur',
    column: [
        {
            name: "Lokasi",
            key: "name",
            className: "font-medium !text-neutral-80",
        },
        {
            name: "Action",
            key: "action",
            classNameHeader: "!text-center w-[150px]",
            render: ({ item, onClickItem }) => (
                <div className="flex justify-center gap-3 mx-auto">
                    <Button type="outline" icon={icEditPrimary} onClick={() => onClickItem({ item, actionType: ACTION_TYPE.EDIT })} />
                    <Button type="danger" icon={icTrashRed} onClick={() => onClickItem({ item, actionType: ACTION_TYPE.DELETE })} />
                </div>
            )
        }
    ]
}

export const TABLE_BENEFITS_REPRESENTATIVE = {
    name: 'Tunjangan Representative',
    column: [
        {
            name: "Tipe Jam Kerja",
            key: "schedule_category",
            className: "font-medium !text-neutral-80",
            render: ({ value }) => <div>{value?.name}</div>
        },
        {
            name: "Grade",
            key: "grade",
            className: "font-medium !text-neutral-80",
            render: ({ value }) => <div>{value?.name}</div>
        },
        {
            name: "Non DKI Jakarta",
            key: "non_jakarta_based",
            classNameHeader: "!text-center",
            className: "text-center",
            render: ({ value }) => setCurrency(value)
        },
        {
            name: "DKI Jakarta",
            key: "jakarta_based",
            classNameHeader: "!text-center",
            className: "text-center",
            render: ({ value }) => setCurrency(value)
        },
        {
            name: "Action",
            key: "action",
            classNameHeader: "!text-center w-[150px]",
            render: ({ item, onClickItem }) => (
                <div className="flex justify-center gap-3 mx-auto">
                    <Button type="outline" icon={icEditPrimary} onClick={() => onClickItem({ item, actionType: ACTION_TYPE.EDIT })} />
                    <Button type="danger" icon={icTrashRed} onClick={() => onClickItem({ item, actionType: ACTION_TYPE.DELETE })} />
                </div>
            )
        }
    ]
}

export const TABLE_ADDITIONAL_INCENTIVE = {
    name: 'Additional Incentive',
    column: [
        {
            name: "Grade",
            key: "grade",
            className: "font-medium !text-neutral-80",
            render: ({ value }) => <div>{value?.name}</div>
        },
        {
            name: "Incentive Minimum",
            key: "min_incentive",
            classNameHeader: "!text-center",
            className: "text-center",
            render: ({ value }) => setCurrency(value)
        },
        {
            name: "Incentive Average",
            key: "avg_incentive",
            classNameHeader: "!text-center",
            className: "text-center",
            render: ({ value }) => setCurrency(value)
        },
        {
            name: "Incentive Maximum",
            key: "max_incentive",
            classNameHeader: "!text-center",
            className: "text-center",
            render: ({ value }) => setCurrency(value)
        },
        {
            name: "Action",
            key: "action",
            classNameHeader: "!text-center w-[150px]",
            render: ({ item, onClickItem }) => (
                <div className="flex justify-center gap-3 mx-auto">
                    <Button type="outline" icon={icEditPrimary} onClick={() => onClickItem({ item, actionType: ACTION_TYPE.EDIT })} />
                    <Button type="danger" icon={icTrashRed} onClick={() => onClickItem({ item, actionType: ACTION_TYPE.DELETE })} />
                </div>
            )
        }
    ]
}

export const TABLE_GOVERNMENT_OVERTIME = {
    name: 'Perhitungan Lembur Pemerintah',
    column: [
        {
            name: "Tipe Jam Kerja",
            key: "schedule",
            className: "font-medium !text-neutral-80",
            render: ({value}) => <div>{value?.name}</div>
        },
        {
            name: "Durasi Lembur",
            key: "duration",
            render: ({value}) => <div>{value ? `${value} Jam` : '-'}</div>
        },
        {
            name: "Benefit Hari Kerja",
            key: "benefit_on_working_day",
            render: ({value}) => <div>{value ? `${value} Kali gaji perjam` : '-'}</div>
        },
        {
            name: "Benefit Hari Libur",
            key: "benefit_on_holiday",
            render: ({value}) => <div>{value ? `${value} Kali gaji perjam` : '-'}</div>
        },
        {
            name: "Action",
            key: "action",
            classNameHeader: "!text-center w-[150px]",
            render: ({ item, onClickItem }) => (
                <div className="flex justify-center gap-3 mx-auto">
                    <Button type="outline" icon={icEditPrimary} onClick={() => onClickItem({ item, actionType: ACTION_TYPE.EDIT })} />
                    <Button type="danger" icon={icTrashRed} onClick={() => onClickItem({ item, actionType: ACTION_TYPE.DELETE })} />
                </div>
            )
        }
    ]
}

export const TABLE_OVERTIME_SETTING = {
    name: 'Pengaturan Lembur',
    column: [
        {
            name: "Tipe Jam Kerja",
            key: "schedule",
            className: "font-medium !text-neutral-80",
            render: ({value}) => <div>{value?.name}</div>
        },
        {
            name: "Waktu Lembur",
            key: "is_work_day",
            render: ({value}) => <div>{value ? 'Hari Kerja' : 'Hari Libur'}</div>
        },
        {
            name: "Grade",
            key: "grades",
            render: ({value}) => <div>{value?.map(item => item?.name).join(', ')}</div>
        },
        {
            name: "Durasi Minimum",
            key: "min_duration",
            classNameHeader: '!text-center',
            className: 'text-center',
            render: ({value}) => <div>{value || 1}</div>
        },
        {
            name: "Durasi Maksimum",
            key: "max_duration",
            classNameHeader: '!text-center',
            className: 'text-center',
        },
        {
            name: "Lokasi Lembur",
            key: "location",
            render: ({value}) => <div>{value?.name}</div>
        },
        {
            name: "Benefit",
            key: "benefit",
            render: ({value}) => <div>{value?.value}</div>
        },
        {
            name: "Action",
            key: "action",
            classNameHeader: "!text-center w-[150px]",
            render: ({ item, onClickItem }) => (
                <div className="flex justify-center gap-3 mx-auto">
                    <Button type="outline" icon={icEditPrimary} onClick={() => onClickItem({ item, actionType: ACTION_TYPE.EDIT })} />
                    <Button type="danger" icon={icTrashRed} onClick={() => onClickItem({ item, actionType: ACTION_TYPE.DELETE })} />
                </div>
            )
        }
    ]
}
