import { regexEmail } from "utils/utils";

export const addEmployeeValidation = {
    full_name: {
        required: "Nama  wajib diisi",
        minimum: {
            value: 3,
            message: "Nama minimal 3 karakter"
        },
    },
    username: {
        required: "Username wajib diisi",
        minimum: {
            value: 3,
            message: "Username minimal 3 karakter"
        },
    },
    id_card_number: {
        required: "NIK wajib diisi",
        minimum: {
            value: 16,
            message: "NIK harus 16 digit"
        },
    },
    family_id_card_number: {
        minimum: {
            value: 16,
            message: "KK harus 16 digit"
        },
    },
    phone_number: {
        required: "No. Hp wajib diisi",
        minimum: {
            value: 9,
            message: "No. Hp minimal 9 digit"
        },
    },
    personal_email: {
        required: "Email wajib diisi",
        format: {
            pattern: regexEmail,
            message: "Format email tidak sesuai"
        },
    },
    office_email: {
        format: {
            pattern: regexEmail,
            message: "Format email tidak sesuai"
        },
    },
    job_title_employees: {
        required: "Job title wajib diisi",
    },

    bank_name: {
        required: "Bank wajib diisi",
    },
    bank_account_number: {
        required: "Nomor rekening wajib diisi",
    },
    bank_account_name: {
        required: "Nama pemilik rekening wajib diisi",
    }

}

export const addFamilyValidation = {
    phone_number: {
        minimum: {
            value: 9,
            message: "No. Hp minimal 9 digit"
        },
    },
}
