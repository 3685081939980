import icJobTitle from "assets/images/ic-glasses-01.svg"
import Button from "components/commons/button"
import DeleteModal from "components/commons/delete-modal"
import ErrorMessage from "components/commons/error-message"
import Table from "components/commons/table"
import { TABLE_JOB_TITLE } from "configs/employee/configTableAddEmployee"
import { useExpandable } from "hooks/useExpandable"
import { CreateJobTitle } from "../create-modal/CreateJobTitle"
import { FormHeader } from "./FormHeader"

export const JobTitle = ({
    optionsCompany,
    optionsJobTitle,
    dataJobTitle,
    errorForm,
    isFormValid,
    isEdit,
    form,
    showModal,
    onShowModal,
    onCloseModal,
    onChangeForm,
    onSubmit,
    onActionRow,
    showDeleteModal,
    onCloseDeleteModal,
    onDelete,
}) => {
    const { isExpanded, elementRef, toggleExpand } = useExpandable({ verticalPadding: 24 })

    return (
        <div className="bg-white border-y border-neutral-20">
            <FormHeader title="Job Title" icon={icJobTitle} expanded={isExpanded} onClick={toggleExpand} errorMessage={errorForm?.job_title_employees} />
            <div ref={elementRef} className="expandable px-8">
                <Table
                    className="mb-5"
                    classNameHeader="!bg-primary-10 !text-md !text-primary-50"
                    classNameRow="!py-6"
                    name={TABLE_JOB_TITLE.name}
                    columns={TABLE_JOB_TITLE.column}
                    data={form}
                    onClickItem={onActionRow}
                />
                <ErrorMessage className="mb-4" message={errorForm?.job_title_employees} />
                <Button type="outline" title="+ Tambah Job Title" onClick={onShowModal} />
            </div>

            <CreateJobTitle
                isFormValid={isFormValid}
                isEdit={isEdit}
                show={showModal}
                onHide={onCloseModal}
                optionsCompany={optionsCompany}
                optionsJobTitle={optionsJobTitle}
                dataJobTitle={dataJobTitle}
                onChangeForm={onChangeForm}
                onSubmit={onSubmit}
            />

            <DeleteModal
                text={"Apakah anda yakin ingin menghapus data job title?"}
                show={showDeleteModal}
                onHide={onCloseDeleteModal}
                onDelete={onDelete}
            />
        </div>
    )
}
