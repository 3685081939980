import { JobTitle } from "components/employee/employee-add/JobTitle"
import { ACTION_TYPE } from "constants/constant"
import { DEFAULT_FORM_JOB_TITLE } from "constants/defaultFormEmployee"
import { useValidation } from "hooks/useValidation"
import { useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setJobTitle } from "reducers/addEmployeeReducer"
import { API_EMPLOYEE } from "services/apiEmployee"
import { useFetch } from "services/useFetch"

export const JobTitleContainer = ({ errorForm }) => {
    const dispatch = useDispatch()
    const dataEmployee = useSelector((state) => state.addEmployeeReducer)
    const [showModal, setShowModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [dataJobTitle, setDataJobTitle] = useState(DEFAULT_FORM_JOB_TITLE)
    const [selectedIndex, setSelectedIndex] = useState()
    const [actionType, setActionType] = useState(ACTION_TYPE.CREATE)
    const { data: dataListCompany } = useFetch(API_EMPLOYEE.LIST_COMPANY, {}, { isFetching: true })
    const { data: dataListJobTitle, fetching: getListJobTitle } = useFetch(API_EMPLOYEE.LIST_JOB_TITLE)
    const { isFormValid } = useValidation({ form: dataJobTitle })

    const optionsCompany = useMemo(() => {
        if (dataListCompany?.data?.length === 0) return []
        return dataListCompany?.data?.map((company) => ({
            label: company.name,
            value: company.id
        }))
    }, [dataListCompany?.data])

    const optionsJobTitle = useMemo(() => {
        if (dataListJobTitle?.data?.length === 0) return []
        return dataListJobTitle?.data?.map((job) => ({
            label: job.title,
            value: job.id
        }))
    }, [dataListJobTitle?.data])

    const onChangeForm = (e) => {
        const { name, value } = e
        if (name === "company") {
            getListJobTitle({ companyId: value.value })
            setDataJobTitle({
                job_title: null,
                [name]: value
            })
            return
        }
        setDataJobTitle({ ...dataJobTitle, [name]: value })
    }

    const onCloseModal = () => {
        setShowModal(false)
        setDataJobTitle(DEFAULT_FORM_JOB_TITLE)
        setActionType(ACTION_TYPE.CREATE)
    }

    const onSubmit = () => {
        const newJobTitle = [...dataEmployee.job_title_employees]
        const newDataJobTitle = {
            company_id: dataJobTitle.company.value,
            company_name: dataJobTitle.company.label,
            job_title_id: dataJobTitle.job_title.value,
            job_title_name: dataJobTitle.job_title.label,
        }
        switch (actionType) {
            case ACTION_TYPE.CREATE:
                newJobTitle.push(newDataJobTitle)
                break;
            case ACTION_TYPE.EDIT:
                newJobTitle[selectedIndex] = newDataJobTitle
                break;
            default:
        }
        dispatch(setJobTitle(newJobTitle))
        onCloseModal()
    }

    const handleEdit = (item) => {
        getListJobTitle({ companyId: item.company_id })
        setDataJobTitle({
            company: {
                label: item.company_name,
                value: item.company_id,
            },
            job_title: {
                label: item.job_title_name,
                value: item.job_title_id,
            }
        })
        setActionType(ACTION_TYPE.EDIT)
        setShowModal(true)
    }

    const handleRemove = (index) => {
        const newJobTitle = [...dataEmployee.job_title_employees]
        newJobTitle.splice(index, 1)
        dispatch(setJobTitle(newJobTitle))
        setShowDeleteModal(false)
    }

    const onActionRow = ({ index, item, actionType }) => {
        setSelectedIndex(index)
        switch (actionType) {
            case ACTION_TYPE.EDIT:
                return handleEdit(item);
            case ACTION_TYPE.DELETE:
                return setShowDeleteModal(true)
            default:
        }
    }

    const props = {
        optionsCompany,
        optionsJobTitle,
        dataJobTitle,
        errorForm,
        isFormValid: isFormValid(),
        isEdit: actionType === ACTION_TYPE.EDIT,
        form: dataEmployee.job_title_employees,
        showModal,
        onShowModal: () => setShowModal(true),
        onCloseModal,
        onChangeForm,
        onSubmit,
        onActionRow,
        showDeleteModal,
        onCloseDeleteModal: () => setShowDeleteModal(false),
        onDelete: () => handleRemove(selectedIndex)
    }

    return (
        <JobTitle {...props} />
    )
}