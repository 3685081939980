import Button from "../../components/commons/button";
import icEdit from "../../assets/images/ic-edit-04.svg";

export const TABLE_PNC_APPROVER_SETTING = {
    name: 'PnC Approver',
    column: [
        {
            name: 'Category',
            key: "category_name",
            className: 'font-semibold capitalize'
        },
        {
            name: "Employee Name",
            key: "employee_name",
            className: "capitalize"
        },
        {
            name: "Action",
            key: "action",
            render: ({item, onClickItem}) => (
                <div className="flex gap-3 mx-auto">
                    <Button icon={icEdit} type="outline" onClick={() => onClickItem(item)}/>
                </div>
            )
        }
    ]
}

export const INITIAL_DATA_FILTER_PNC_APPROVER = {
    page: 1,
    limit: 20
}
