import {WorkTime} from "../../../components/work-settings/work-time/WorkTime";
import {ACTION_TYPE} from "../../../constants/constant";
import {useDispatch, useSelector} from "react-redux";
import {
    resetInitialWorkTime,
    setActionType,
    setWorkTimeDetail,
    setWorkTimeToast
} from "../../../reducers/formWorkTimeReducer";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {INITIAL_DATA_FILTER} from "../../../configs/work-settings/configTableWorkTime";
import {useFetch} from "../../../services/useFetch";
import {API_WORK_TIME} from "../../../services/apiWorkTime";
import {getAllQueryParams, setQueryParams} from "../../../utils/utils";

export const WorkTimeContainer = () => {
    const location = useLocation()
    const {data: workTimeList, fetching: getWorkTimeList, isLoading} = useFetch(API_WORK_TIME.WORK_TIME_LIST)
    const {fetching: deleteWorkTime, isLoading: isDeleting} = useFetch(API_WORK_TIME.DELETE_WORK_TIME)
    const {toast} = useSelector(state => state.formWorkTimeReducer)
    const [deleteModal, setDeleteModal] = useState(false)
    const [deleteId, setDeleteId] = useState(null)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams()
    const query = searchParams.size > 0 ? getAllQueryParams(searchParams) : INITIAL_DATA_FILTER

    useEffect(() => {
        fetchWorkTimeList({...query})
    }, [location?.search])

    const onChangeQuery = (queryParams) => {
        const params = setQueryParams({...query, ...queryParams})
        setSearchParams(params, {replace: true})
    }

    const clearToast = () => {
        dispatch(setWorkTimeToast({isShowToast: false}))
    }

    const setHideDeleteModal = () => {
        setDeleteModal(false)
    }

    const fetchWorkTimeList = (item = query) => {
        getWorkTimeList(item)
    }

    const onClickItemHandler = ({id, item, actionType}) => {
        switch (actionType) {
            case ACTION_TYPE.CREATE:
                dispatch(resetInitialWorkTime())
                navigate('/work-settings/work-time/create')
                break
            case ACTION_TYPE.EDIT:
                dispatch(setActionType(ACTION_TYPE.EDIT))
                dispatch(setWorkTimeDetail(item))
                navigate('/work-settings/work-time/update')
                break
            default:
                setDeleteId(id)
                setDeleteModal(true)
        }
    }

    const onDelete = () => {
        deleteWorkTime({}, deleteId)
            .then(() => {
                dispatch(setWorkTimeToast({message: 'Work Time Deleted', isShowToast: true}))
                setDeleteModal(false)
                if (workTimeList?.data?.result?.length === 1 && Number(query?.page) !== 1) {
                    onChangeQuery({page: 1})
                } else {
                    fetchWorkTimeList()
                }
            })
    }

    const props = {
        dataList: workTimeList?.data,
        isShowToast: toast?.isShowToast,
        toastMessage: toast?.message,
        deleteModal,
        isLoading,
        query,
        isDeleting,
        clearToast,
        onChangeQuery,
        setHideDeleteModal,
        onClickItemHandler,
        onDelete
    }

    return (
        <WorkTime {...props}/>
    )
}
