import clsx from "clsx";
import {useEffect, useRef, useState} from "react";
import Input from "../input";
import icCalendar02 from "../../../assets/images/ic-calendar-02.svg";
import icLeft from "../../../assets/images/ic-left.svg";
import icRight from "../../../assets/images/ic-right.svg";
import './datepicker.scss'
import moment from "moment";
import Select from "../select";
import {formatDateMoment} from "../../../utils/utils";
import Button from "../button";

const Datepicker = ({name='avo-datepicker', className, value, onChange, format, placeholder = 'Pilih tanggal', minDate = null, maxDate = null, disabled, isClearable}) => {
    const [show, setShow] = useState(false)
    const datepickerRef = useRef(null)
    const optionsRef = useRef(null)
    const [position, setPosition] = useState('below')
    const months = [
        {label: 'January', value: 1},
        {label: 'Februari', value: 2},
        {label: 'Maret', value: 3},
        {label: 'April', value: 4},
        {label: 'Mei', value: 5},
        {label: 'Juni', value: 6},
        {label: 'Juli', value: 7},
        {label: 'Agustus', value: 8},
        {label: 'September', value: 9},
        {label: 'Oktober', value: 10},
        {label: 'November', value: 11},
        {label: 'Desember', value: 12}
    ]

    const dateMoment = moment()
    const [y, setY] = useState(dateMoment)
    const [dateArrayY, setDateArrayY] = useState([])
    const [dateBeforeArrayY, setDateBeforeArrayY] = useState([])
    const [dateAfterArrayY, setDateAfterArrayY] = useState([])
    const [selectedMonth, setSelectedMonth] = useState()
    const [selectedYear, setSelectedYear] = useState()
    const [selectedDate, setSelectedDate] = useState(moment())
    const END_YEAR = 1970
    const START_YEAR = moment(maxDate || moment()).get('year')
    const years = getYearsList(END_YEAR)

    useEffect(() => {
        const clickListener = (e) => {
            const flyoutElement = document.getElementById(`datepicker-${name}`)
            let targetElement = e.target

            do {
                if (targetElement === flyoutElement) return  // This is a click inside. Do nothing, just return.
                targetElement = targetElement.parentNode
            } while (targetElement);
            setShow(false)
        }

        if (show) {
            document.addEventListener("click", clickListener, true)
            calculatePosition()
        }

        return () => document.removeEventListener("click", clickListener, true)
    }, [show])

    useEffect(() => {
        const currentDate = value || moment()
        if (currentDate) {
            setY(currentDate)
            setSelectedDate(currentDate)
            const yearY = moment(currentDate).get('year')
            const monthY = moment(currentDate).get('month')
            setSelectedMonth(months[monthY])
            setSelectedYear({label: yearY, value: yearY})
            constructDateArrayY(yearY, monthY)
        }
    }, [value])

    useEffect(() => {
        if (selectedMonth?.value && selectedYear?.value) constructDateArrayY(selectedYear.value, (selectedMonth.value - 1))
    }, [selectedMonth?.value, selectedYear?.value])

    const calculatePosition = () => {
        const { bottom } = datepickerRef.current.getBoundingClientRect()
        const windowHeight = window.innerHeight
        const optionsHeight = optionsRef.current.offsetHeight
        const spaceBelow = windowHeight - bottom

        if (spaceBelow >= optionsHeight) {
            setPosition('below')
        } else {
            setPosition('above')
        }
    }

    const constructDateArrayY = (yearY, monthY) => {
        let arrayDateY = []
        let monthBeforeArrayY = []
        let monthAfterArrayY = []
        let dateEndY = 40 - (new Date(yearY, monthY, 40).getDate())
        let dateStartY = new Date(yearY, monthY).getDay()

        //generate array date before selected month
        let dateBeforeEnd = 40 - (new Date(constructYearNew(yearY, monthY - 1), constructMonth(monthY - 1), 40).getDate())
        for (let i = dateBeforeEnd - (dateStartY - 1); i <= dateBeforeEnd; i++) {
            let c = moment(String(`${constructYearNew(yearY, monthY - 1)}-${constructMonth(monthY)}-${i}`)).startOf('days')
            monthBeforeArrayY.push(c)
        }

        //generate array date selected month
        for (let i = 1; i <= dateEndY; i++) {
            let c = moment(String(`${yearY}-${monthY + 1}-${i}`))
            arrayDateY.push(c)
        }

        //generate array date after selected month
        let totalDate = monthBeforeArrayY.length + arrayDateY.length
        let dateLeft = 42 - totalDate
        for (let i = 1; i <= dateLeft; i++) {
            let c = moment(String(`${constructYear(yearY, monthY + 1, 'plus')}-${constructMonth(monthY + 2)}-${i}`))
            monthAfterArrayY.push(c)
        }

        setDateArrayY(arrayDateY)
        setDateBeforeArrayY(monthBeforeArrayY)
        setDateAfterArrayY(monthAfterArrayY)
    }

    function getYearsList(endYear) {
        const years = [];
        for (let year = START_YEAR; year >= endYear; year--) {
            years.push({
                label: year,
                value: year,
            })
        }
        return years;
    }

    const constructMonth = (m) => {
        if (m === 13) {
            return 1
        } else if (m === 0) {
            return 12
        } else {
            return m
        }
    }

    const constructYearNew = (y, m) => {
        if (m === 13) {
            return y + 1
        } else if (m === -1) {
            return y - 1
        } else {
            return y
        }
    }

    const constructYear = (y, m, type) => {
        if (type === 'minus') {
            if (m === 13) {
                return y - 1
            } else {
                return y
            }
        } else {
            if (m === 12) {
                return y + 1
            } else {
                return y
            }
        }
    }

    const dateButtonHandler = (item, slug) => {
        switch (slug) {
            case 'before':
                arrowLeftHandler()
                break;
            case 'main':
                onChange(item.format(format))
                setSelectedDate(item)
                setShow(false)
                break;
            case 'after':
                arrowRightHandler()
                break;
        }
    }

    const isDisabled = (item) => {
        return moment(item).isBefore(minDate, 'day') || moment(item).isAfter(maxDate, 'day')
    }

    const isDisabledArrowButton = (slug) => {
        if (slug === 'left') return selectedMonth?.value === 1 && selectedYear?.value === END_YEAR
        return selectedMonth?.value === 12 && selectedYear?.value === START_YEAR
    }

    const renderButtonItem = (item, index, slug) => {
        if (slug === 'main') {
            return (
                <button key={index} onClick={() => dateButtonHandler(item, slug)}
                        disabled={isDisabled(item)}
                        className={`flex flex-row justify-center items-center h-10 p-[10px] disabled:text-gray-400 disabled:bg-gray-100 disabled:rounded-none disabled:cursor-not-allowed hover:text-white hover:bg-dark-sage-two hover:rounded-full ${moment(item).isBefore(minDate, 'day') && 'cursor-not-allowed'} ${moment(item).isSame(dateMoment, 'day') && 'border border-dark-sage-two rounded-full'} ${moment(item).isSame(selectedDate, 'day') && 'bg-primary-30 text-white rounded-full'}`}>
                    <p className={"text-xs font-Poppins-Medium"}>{moment(item).date()}</p>
                </button>
            )
        } else {
            return (
                <button key={index}
                        disabled={isDisabled(item)}
                        onClick={() => dateButtonHandler(item, slug)}
                        className={`row justify-center items-center h-10  disabled:text-gray-400 disabled:bg-gray-100 disabled:rounded-none disabled:cursor-not-allowed hover:text-white hover:bg-dark-sage-two hover:rounded-full text-gray-300`}>
                    <p className={"text-xs font-Poppins-Medium"}>{moment(item).date()}</p>
                </button>
            )
        }
    }

    const arrowLeftHandler = () => {
        if (selectedMonth.value === 1) {
            const currentYearIndex = years.findIndex(year => year.value === selectedYear.value)
            setSelectedMonth(months[11])
            setSelectedYear(years[currentYearIndex + 1])
        } else {
            setSelectedMonth(months[selectedMonth.value - 2])
        }
        const month = moment(y).subtract(1, 'months')
        setY(month)
    }

    const arrowRightHandler = () => {
        if (selectedMonth.value === 12) {
            const currentYearIndex = years.findIndex(year => year.value === selectedYear.value)
            setSelectedYear(years[currentYearIndex - 1])
            setSelectedMonth(months[0])
        } else {
            setSelectedMonth(months[selectedMonth.value])
        }
        const month = moment(y).add(1, 'months')
        setY(month)
    }

    const onClearValue = () => {
        onChange(null)
        setShow(false)
    }

    return (
        <div ref={datepickerRef} id={`datepicker-${name}`} className={clsx("avo__datepicker", className)}>
            <Input name="date" placeholder={placeholder} value={value && formatDateMoment(value, 'DD-MMM-YYYY')}
                   readOnly={true} iconAfter={icCalendar02} disabled={disabled}
                   className={clsx("cursor-pointer", value && 'date-value', className)} onClick={() => setShow(!show)}
                   onClickClear={isClearable && onClearValue}/>
            <div
                ref={optionsRef}
                className={clsx('dialog_wrapper', position, show ? 'opacity-100 scale-100 visible' : 'opacity-0 scale-95 collapse')}
            >
                <div>
                    <div className={"flex items-center justify-between mb-4"}>
                        <Button type="text" icon={icLeft} disabled={isDisabledArrowButton('left')} onClick={arrowLeftHandler}/>
                        <Select name={'select'}
                                className='w-[116px] rounded'
                                optionsClassName='max-h-[300px]'
                                options={months}
                                value={selectedMonth}
                                onChange={(e) => setSelectedMonth(e.value)}
                        />
                        <Select name={'select'}
                                className='w-[82px] rounded'
                                optionsClassName='max-h-[300px]'
                                options={years}
                                value={selectedYear}
                                onChange={(e) => setSelectedYear(e.value)}
                        />
                        <Button type="text" icon={icRight} disabled={isDisabledArrowButton('right')} onClick={arrowRightHandler}/>
                    </div>
                    <div className={"grid grid-cols-7"}>
                        {
                            ['Min', 'Sen', 'Sel', 'Rab', 'Kam', 'Jum', 'Sab'].map((item, index) => (
                                <div key={index}
                                     className={"flex flex-row justify-center items-center w-[42px] h-[35px]"}>
                                    <p>{item}</p>
                                </div>
                            ))
                        }
                    </div>
                    <div className={"grid grid-cols-7 text-brownish-grey"}>
                        {dateBeforeArrayY.map((item, index) => renderButtonItem(item, index, 'before'))}
                        {dateArrayY.map((item, index) => renderButtonItem(item, index, 'main'))}
                        {dateAfterArrayY.map((item, index) => renderButtonItem(item, index, 'after'))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Datepicker
