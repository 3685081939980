import FormModal from "components/commons/form-modal";
import Select from "components/commons/select";

export const CreateJobTitle = ({
    show,
    onHide,
    optionsCompany,
    optionsJobTitle,
    dataJobTitle,
    onChangeForm,
    onSubmit,
    isFormValid,
    isEdit,
    isLoadingSubmit,
}) => {
    return (
        <FormModal
            show={show}
            onHide={onHide}
            title={`${isEdit ? 'Edit' : 'Tambah'} Job Title`}
            onSubmitForm={onSubmit}
            isLoading={isLoadingSubmit}
            isDisabled={!isFormValid}
        >
            <div className={"px-8 py-6 mb-2 space-y-6"}>
                <div className="flex items-center gap-3">
                    <div className="font-medium w-1/3 text-neutral-70 text-md">{'Perusahaan'}</div>
                    <Select name="company"
                        className='flex-1'
                        placeholder="Pilih perusahaan"
                        optionsClassName='max-h-[300px]'
                        options={optionsCompany}
                        value={dataJobTitle?.company}
                        onChange={onChangeForm}
                    />
                </div>
                <div className="flex items-center gap-3">
                    <div className="font-medium w-1/3 text-neutral-70 text-md">{'Job Title'}</div>
                    <Select name="job_title"
                        className='flex-1'
                        placeholder="Pilih job title"
                        optionsClassName='max-h-[300px]'
                        options={optionsJobTitle}
                        value={dataJobTitle?.job_title}
                        onChange={onChangeForm}
                        searchable
                    />
                </div>
            </div>
        </FormModal>
    )
}
