import {InlineTabs} from "../../commons/InlineTabs";
import Search from "../../commons/search";
import icFilter from "../../../assets/images/ic-filter-new.svg";
import DateRangePicker from "../../commons/date-range-picker";
import moment from "moment/moment";
import ViewList from "../../commons/view-list";
import Button from "../../commons/button";
import icFileUp from "../../../assets/images/ic-file-up-02.svg";
import Table from "../../commons/table";
import icAddSquare from "../../../assets/images/id-add-square.svg";
import {ACTION_TYPE} from "../../../constants/constant";
import Drawer from "../../commons/drawer";
import icEditPrimary from "../../../assets/images/ic-edit-04-primary.svg";
import {downloadFile, formatDateMoment} from "../../../utils/utils";
import Badge from "../../commons/badge";
import icTrashRed from "../../../assets/images/ic-trash-01-red.svg";
import {ExportModal} from "../../commons/ExportModal";
import Toast from "../../commons/toast";
import DeleteModal from "../../commons/delete-modal";
import icEye from "../../../assets/images/ic-eye-open.svg";
import {ModalDocument} from "../../commons/modal-document/ModalDocument";
import icDownloadPrimary from "../../../assets/images/ic-download-primary.svg";

export const HRISAnnouncement = (
    {
        tabList,
        configTable,
        dataList,
        dataPreview,
        status,
        isLoading,
        isDeleting,
        modal,
        query,
        toast,
        selectedAnnouncementList,
        navigate,
        documentPreview,
        onPreviewDocument,
        clearToast,
        onExport,
        onDelete,
        onChangeTab,
        onChangeQuery,
        onClickItemHandler,
        onSelectItem,
        onSetModal
    }) => {

    const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.pdf)$/i;

    return (
        <>
            <InlineTabs tabList={tabList} activeTab={status} onClickTab={onChangeTab}/>
            <div className={"flex flex-row justify-between gap-3 px-6 py-4"}>
                <div className={"flex flex-row gap-3"}>
                    <Search value={query?.search} onSearch={(search) => onChangeQuery({page: 1, search})}/>
                    <div className={"flex items-center gap-2"}>
                        <img src={icFilter} alt="ic-filter"/>
                        <p className="text-neutral-70">Filter by date:</p>
                        <DateRangePicker
                            value={query?.startDate ? [query?.startDate, query?.endDate] : []}
                            onChange={(value) => onChangeQuery({page: 1, startDate: value[0], endDate: value[1]})}
                            format='YYYY-MM-DD'
                            maxDate={`${moment().get('year')}-12-31`}
                        />
                    </div>
                    <ViewList
                        show={modal?.limit}
                        onShow={() => onSetModal({limit: true})}
                        onClose={() => onSetModal({limit: false})}
                        value={query?.limit}
                        onSelectSize={(limit) => onChangeQuery({page: 1, limit})}
                    />
                    <Button title="Export" icon={icFileUp} type="outline" disabled={!dataList?.total_result}
                            onClick={() => onSetModal({export: true})}/>
                </div>
                <Button title="Create Announcement" icon={icAddSquare}
                        onClick={() => onClickItemHandler({actionType: ACTION_TYPE.CREATE})}/>
            </div>
            <Table
                className="my-3"
                name={configTable.name}
                data={dataList?.result}
                columns={configTable.column}
                size={query?.limit}
                totalPage={dataList?.total_page}
                totalResult={dataList?.total_result}
                currentPage={query?.page}
                onChangePage={(page) => onChangeQuery({page})}
                onSelectItem={onSelectItem}
                onClickItem={(item) => onClickItemHandler(item)}
                onClickSort={(sortQuery) => onChangeQuery({...sortQuery, page: 1})}
                loading={isLoading}
            />
            <Drawer visibility={modal?.preview} onHide={() => onSetModal({preview: false})} title="Preview HRIS Announcement">
                <div className={"space-y-5 px-6 pb-16"}>
                    <div className={"flex items-center justify-between"}>
                        <div className={"font-bold text-md text-neutral-80 capitalize"}>{dataPreview?.title || '-'}</div>
                        <Button className={status === tabList[0].id ? 'block' : 'hidden'} type="outline"
                                icon={icEditPrimary} title="edit" onClick={() => navigate(`/general-settings/hris-announcement/update?announcementId=${dataPreview?.id}`)}/>
                    </div>
                    <div className={"flex flex-col space-y-5 inner-html"}>
                        <div className="text-neutral-80 font-medium">Deskripsi</div>
                        <div dangerouslySetInnerHTML={{__html: dataPreview?.description}}/>
                    </div>
                    <div className="flex">
                        <div className={"w-32 text-neutral-70 pr-2"}>File Pendukung</div>
                        <div className={"flex flex-col"}>
                            {
                                dataPreview?.attachments?.length ?
                                    <div className="flex flex-col space-y-2">
                                        {
                                            dataPreview?.attachments?.map((item, index) =>
                                                <Button
                                                    key={index}
                                                    title={dataPreview?.attachments?.length > 1 ? `File Attachment ${index + 1}` : 'File Attachment'}
                                                    onClick={() => allowedExtensions.exec(item) ? onPreviewDocument(item) : downloadFile(item, null, null, '_blank')}
                                                    icon={allowedExtensions.exec(item) ? icEye : icDownloadPrimary}
                                                    type="outline"
                                                />
                                            )
                                        }
                                    </div>
                                    :
                                    '-'
                            }
                        </div>
                    </div>
                    <div className="space-y-5">
                        <div className="flex">
                            <div className={"w-32 text-neutral-70 pr-2"}>Waktu Publish</div>
                            <div
                                className={"flex-1 font-medium text-neutral-80"}>{formatDateMoment(dataPreview?.published_at, 'D MMMM YYYY HH:mm')}</div>
                        </div>
                    </div>
                    <div className="space-y-5">
                        <div className="flex">
                            <div className={"w-32 text-neutral-70 pr-2"}>Sent to</div>
                            <div
                                className={"flex-1 font-medium text-neutral-80"}>{dataPreview?.company_name || '-'}</div>
                        </div>
                    </div>
                    <div className="space-y-5">
                        <div className="flex">
                            <div className={"w-32 text-neutral-70 pr-2"}>Created by</div>
                            <div
                                className={"flex-1 font-medium text-neutral-80"}>{dataPreview?.announcer_name || '-'}</div>
                        </div>
                    </div>
                    <div className={"flex items-center"}>
                        <div className={"w-32 text-neutral-70 pr-2"}>Status</div>
                        <Badge title={dataPreview?.status} type={dataPreview?.status}/>
                    </div>
                </div>
                <div className={"flex w-full justify-end fixed bottom-0 bg-white border-t pr-6 py-6"}>
                    <Button className={status === tabList[0].id ? 'block' : 'hidden'} type="danger" icon={icTrashRed}
                            title="Delete Announcement"
                            onClick={() => onClickItemHandler({id: dataPreview?.id, actionType: ACTION_TYPE.DELETE})}/>
                </div>
            </Drawer>
            <DeleteModal
                show={modal?.delete}
                onHide={() => onSetModal({delete: false})}
                onDelete={onDelete}
                isLoading={isDeleting}
                text="Apakah anda yakin ingin menghapus data announcement?"
            />
            <ExportModal
                title="Apakah anda yakin ingin melakukan export data?"
                description={`Total data list announcement ${status}: ${selectedAnnouncementList?.length || dataList?.total_result || 0}`}
                show={modal?.export}
                onExportHandler={onExport}
                onCancelHandler={() => onSetModal({export: false})}
            />
            <Toast
                show={toast?.isShowToast}
                message={toast?.message}
                onClose={() => clearToast()}
            />
            <ModalDocument onHide={() => onSetModal({preview_document: false})} show={modal?.preview_document}
                           src={documentPreview}/>
        </>
    )
}
