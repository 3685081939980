import clsx from "clsx";
import './switch.scss'

const Switch = ({className, classNameInput, classNameToggleSwitch, size, typeOn = "primary", typeOff = "secondary", name, disabled, checked, onChange}) => {
    const handleOnChange = (e) => {
        const { name, checked } = e.target
        if (onChange) onChange ({ name, checked })
    }

    return (
        <label className={clsx("avo__switch", size, className)}>
            <input
                className={clsx("input-switch", typeOn, classNameInput)}
                name={name}
                type="checkbox"
                checked={checked}
                disabled={disabled}
                onChange={handleOnChange}
            />
            <span className={clsx("toggle-switch", typeOff, classNameToggleSwitch)}></span>
        </label>
    )
}

export default Switch
