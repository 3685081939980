import clsx from "clsx"
import { useEffect, useRef, useState } from "react"
import icChevDown from "./ic-chev-down.svg"
import './menu.scss'
import Tooltip from "../tooltip";

export const Menu = ({className, contentHeader, items, onClick, onHide, selectedKey, contentFooter}) => {
    const [activeMenu, setActiveMenu] = useState('')

    return (
        <div className={clsx("avo__menu", className)}>
            {contentHeader}
            <div className="menu-wrapper">
                {items.map((item, index) => (
                    <MenuItem
                        key={index}
                        item={item}
                        value={null}
                        parentKey={item.key}
                        onClick={onClick}
                        onHide={onHide}
                        selectedKey={selectedKey}
                        onExpand={() => setActiveMenu(item.key)}
                        activeMenu={activeMenu}
                    />
                ))}
            </div>
            {contentFooter}
            <p className={"text-xs text-neutral-60 py-4 text-center"}>AVO 2023 All Right Reserved</p>
        </div>
    )
}

export default Menu

const MenuItem = ({
                      item, level = 0,
                      parentKey,
                      onHide,
                      maxHeight = () => {
                      },
                      onClick,
                      selectedKey,
                      onExpand,
                      activeMenu,
                  }) => {
    const childRef = useRef()
    const labelRef = useRef()
    const [childHeight, setChildHeight] = useState(0)
    const hasChild = !!item?.children
    const lastSelectedKey = selectedKey.split('/').pop()
    const selectedParentKey = selectedKey.split('/').slice(0, -1).join('/')
    const isSelected = lastSelectedKey === item.key || selectedParentKey === parentKey
    const isExpand = activeMenu === item.key
    const [isShowTooltip, setIsShowTooltip] = useState(false)

    useEffect(() => {
        if (hasChild || level === 0) {
            const firstParentKey = selectedKey.split('/').splice(0, 1).join()
            if (firstParentKey === item.key) onExpand(item.key)
        }
    }, [hasChild])

    useEffect(() => {
        maxHeight(childHeight)
    }, [isExpand])

    useEffect(() => {
        if (childRef?.current?.scrollHeight) {
            setChildHeight(childRef?.current?.scrollHeight)
            maxHeight(childRef?.current?.scrollHeight)
        }
    }, [childRef?.current?.scrollHeight])

    useEffect(() => {
        setIsShowTooltip(labelRef.current?.scrollHeight > labelRef.current?.clientHeight)
    }, [])

    const handleOnClick = (e) => {
        e.stopPropagation()
        if (hasChild) return onExpand(item.key)
        if (level === 0) onExpand(item.key)
        onClick(parentKey)
        window.scrollTo(0, 0)
        if (onHide) {
            onHide()
        }
    }

    return (
        <>
            <Tooltip
                content={
                    <div className={clsx("menu-item", isSelected && 'active', isExpand && "is-expand")} onClick={(e) => handleOnClick(e)}>
                        {item?.icon &&
                            <img className={clsx("menu-item-icon", isExpand && "active")} src={item.icon} alt={item.label}/>}
                        <div className={clsx("menu-item-label", !hasChild && level > 0 && "no-child")} ref={labelRef}>{item?.label}</div>
                        {hasChild && <img src={icChevDown} alt={item.label}/>}
                    </div>
                }
                text={item?.label}
                position={'right'}
                isShow={isShowTooltip}
            />
            {hasChild &&
                <div ref={childRef} className={clsx("expandable", isExpand && "show-tooltip")} style={{ maxHeight: isExpand ? childHeight : 0 }}>
                    {item?.children.map((childItem, childIndex) => (
                        <MenuItem
                            key={childIndex}
                            item={childItem}
                            maxHeight={(value) => setChildHeight(childHeight + value)}
                            level={level + 1}
                            parentKey={`${parentKey}/${childItem.key}`}
                            onClick={onClick}
                            onHide={onHide}
                            selectedKey={selectedKey}
                        />
                    ))}
                </div>
            }
        </>
    )
}


