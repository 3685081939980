import Input from "../../commons/input";
import FormModal from "../../commons/form-modal";
import ErrorMessage from "../../commons/error-message";
import clsx from "clsx";

export const GradeSettingForm = (props) => {
    const {
        isShow = false,
        onHideForm,
        onSubmitForm,
        onChangeValue,
        formData,
        allowancesColumn,
        errorForm
    } = props

    return (
        <FormModal
            show={isShow}
            title="Grade Setting"
            width="max-w-[985px]"
            onHide={onHideForm}
            onSubmitForm={onSubmitForm}
        >
            <div className={"px-6 py-8"}>
                <div className={"flex flex-row w-1/2"}>
                    <div className={"w-1/3 text-neutral-70 mt-2 required"}>Grade</div>
                    <div>
                        <Input
                            className="flex-1"
                            name="name"
                            required={true}
                            placeholder="Isi grade"
                            value={formData?.name}
                            onChange={(e) => onChangeValue(e)}
                        />
                        <ErrorMessage className="mt-2" message={errorForm['name']}/>
                    </div>
                </div>
                <div className="py-4">
                    <div className={"font-bold text-sm text-neutral-80"}>Salary</div>
                    <div className={"grid grid-cols-2 w-full gap-3 py-2"}>
                        <div className="flex">
                            <div className={"w-1/3 text-neutral-70 mt-2 required"}>Min Basic Salary</div>
                            <div>
                                <Input
                                    name="min_basic_salary"
                                    className="flex-1"
                                    addonBefore="Rp"
                                    type="currency"
                                    value={formData?.min_basic_salary}
                                    onChange={(e) => onChangeValue(e)}
                                />
                                <ErrorMessage className="mt-2" message={errorForm['min_basic_salary']}/>
                            </div>
                        </div>
                        <div className="flex">
                            <div className={"w-1/3 text-neutral-70 mt-2 required"}>Max Basic Salary</div>
                            <div>
                                <Input
                                    name="max_basic_salary"
                                    className="flex-1"
                                    addonBefore="Rp"
                                    value={formData?.max_basic_salary}
                                    type="currency"
                                    onChange={(e) => onChangeValue(e)}
                                />
                                <ErrorMessage className="mt-2" message={errorForm['max_basic_salary']}/>
                            </div>
                        </div>
                    </div>
                </div>
                {/*allowances*/}
                <div className={clsx('py-4', allowancesColumn?.length ? 'block' : 'hidden')}>
                    <div className={"font-bold text-sm text-neutral-80"}>Allowances</div>
                    <div className={"grid grid-cols-2 w-full gap-5 py-2"}>
                        {
                            allowancesColumn?.map((item, index) =>
                                <div key={index} className={"flex items-center"}>
                                    <div className={"w-1/3 text-neutral-70"}>{item?.name}</div>
                                    <div>
                                        <Input
                                            name={item?.key}
                                            className="flex-1"
                                            addonBefore="Rp"
                                            type="currency"
                                            value={formData?.allowances[index]?.value || 0}
                                            onChange={(e) => onChangeValue(e, item?.key, index)}
                                        />
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        </FormModal>
    )
}
