import Button from "../../commons/button";
import icFileUp from "assets/images/ic-file-up-02.svg";
import icAddSquare from "assets/images/id-add-square.svg";
import Table from "../../commons/table";
import {TABLE_GRADE_SETTING} from "configs/organization-structure/configTableGrade";
import {ExportModal} from "../../commons/ExportModal";
import {GradeSettingForm} from "./GradeSettingForm";
import Search from "../../commons/search";
import DeleteModal from "../../commons/delete-modal";
import icSettings from "../../../assets/images/ic-add-personal-incentive.svg";
import icFileDown from "../../../assets/images/ic-file-down-02.svg";
import {ImportGradeContainer} from "../../../containers/organization-structure-management/grade-setting/import-grade/ImportGradeContainer";
import Toast from "../../commons/toast";

export const GradeSetting = (props) => {
    const {
        gradeData,
        columnTable,
        modal,
        isLoading,
        toastMessage,
        isShowToast,
        clearToast,
        addGradeHandler,
        gradeDetail,
        onChangeValueHandler,
        onSubmitHandler,
        onExportHandler,
        onSelectRowHandler,
        selectedRowIds,
        onClickItemHandler,
        onDelete,
        onSetModal,
        errorForm,
        onChangeQuery,
        onClickSettingTypeAllowance,
        query,
        allowancesColumn,
        fetchGradeList,
        setShowToast
    } = props

    return (
        <div className={'flex flex-col'}>
            <div className={'flex flex-row justify-between gap-3 px-6 py-4'}>
                <div className={'flex flex-row gap-3'}>
                    <Search value={query?.search} onSearch={(search) => onChangeQuery({ page: 1, search })}/>
                    <Button title="Export" icon={icFileUp} type="outline"
                            onClick={() => onSetModal({export: true})}/>
                </div>
                <div className={"flex gap-x-3"}>
                    <Button title="Import" icon={icFileDown} type="outline" onClick={() => onSetModal({import: true})}/>
                    <Button title="Setting Type Allowance" type="outline" icon={icSettings} onClick={onClickSettingTypeAllowance}/>
                    <Button title="Add Grade" icon={icAddSquare} onClick={addGradeHandler}/>
                </div>
            </div>
            <Table
                className="my-3"
                data={gradeData?.result?.data}
                columns={columnTable}
                onSelectItem={onSelectRowHandler}
                totalResult={gradeData?.total_result}
                totalPage={gradeData?.total_page}
                currentPage={query?.page}
                name={TABLE_GRADE_SETTING.name}
                onClickItem={onClickItemHandler}
                onChangePage={(page) => onChangeQuery({ page })}
                loading={isLoading}
            />
            <ExportModal
                title={'Apakah anda yakin ingin export data grade?'}
                description={`Total data grade export: ${selectedRowIds?.length ? selectedRowIds?.length : gradeData?.total_result}`}
                show={modal?.export}
                onExportHandler={onExportHandler}
                onCancelHandler={() => onSetModal({export: false})}
            />
            {
                modal?.form &&
                <GradeSettingForm
                    isShow={modal?.form}
                    formData={gradeDetail}
                    allowancesColumn={allowancesColumn}
                    onChangeValue={onChangeValueHandler}
                    onSubmitForm={onSubmitHandler}
                    onHideForm={() => onSetModal({form: false})}
                    errorForm={errorForm}
                />
            }
            <DeleteModal
                show={modal?.delete}
                onHide={() => onSetModal({delete: false})}
                onDelete={onDelete}
                text={`Apakah anda yakin ingin menghapus data ini?`}
            />
            {
                modal?.import &&
                <ImportGradeContainer
                    show={modal?.import}
                    onHide={() => onSetModal({import: false})}
                    fetchList={fetchGradeList}
                    setShowToast={setShowToast}
                />
            }
            <Toast
                show={isShowToast}
                message={toastMessage}
                onClose={() => clearToast()}
            />
        </div>
    )
}
