import icWorkExperience from "assets/images/ic-luggage-03.svg"
import Button from "components/commons/button"
import DeleteModal from "components/commons/delete-modal"
import Table from "components/commons/table"
import { TABLE_WORK_EXPERIENCE } from "configs/employee/configTableAddEmployee"
import { useExpandable } from "hooks/useExpandable"
import { CreateWorkExperience } from "../create-modal/CreateWorkExperience"
import { FormHeader } from "./FormHeader"

export const WorkExperience = ({
    dataWorkExperience,
    isFormValid,
    isEdit,
    form,
    showModal,
    onShowModal,
    onCloseModal,
    onChangeForm,
    onSubmit,
    onActionRow,
    showDeleteModal,
    onCloseDeleteModal,
    onDelete,
}) => {
    const { isExpanded, elementRef, toggleExpand } = useExpandable({ verticalPadding: 24 })

    return (
        <div className="bg-white border-y border-neutral-20">
            <FormHeader title="Pengalaman Kerja" optional icon={icWorkExperience} expanded={isExpanded} onClick={toggleExpand} />
            <div ref={elementRef} className="expandable px-8">
                <Table
                    className="mb-5"
                    classNameHeader="!bg-primary-10 !text-md !text-primary-50"
                    classNameRow="!py-6"
                    name={TABLE_WORK_EXPERIENCE.name}
                    columns={TABLE_WORK_EXPERIENCE.column}
                    data={form}
                    onClickItem={onActionRow}
                />
                <Button type="outline" title="+ Tambah Pengalaman Kerja" onClick={onShowModal} />
            </div>

            <CreateWorkExperience
                isFormValid={isFormValid}
                isEdit={isEdit}
                show={showModal}
                onHide={onCloseModal}
                dataWorkExperience={dataWorkExperience}
                onChangeForm={onChangeForm}
                onSubmit={onSubmit}
            />

            <DeleteModal
                text={"Apakah anda yakin ingin menghapus data pengalaman kerja?"}
                show={showDeleteModal}
                onHide={onCloseDeleteModal}
                onDelete={onDelete}
            />
        </div>
    )
}