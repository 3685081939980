import Datepicker from "components/commons/datepicker";
import FormModal from "components/commons/form-modal";
import Input from "components/commons/input";
import moment from "moment";

export const CreateWorkExperience = ({
    show,
    onHide,
    dataWorkExperience,
    onChangeForm,
    onSubmit,
    isFormValid,
    isEdit,
    isLoadingSubmit,
}) => {
    return (
        <FormModal
            show={show}
            onHide={onHide}
            title={`${isEdit ? 'Edit' : 'Tambah'} Data Pengalaman Kerja`}
            onSubmitForm={onSubmit}
            isLoading={isLoadingSubmit}
            isDisabled={!isFormValid}
        >
            <div className={"px-8 py-6 mb-2 space-y-6"}>
                <div className="flex items-center gap-3">
                    <div className="font-medium w-1/3 text-neutral-70">{'Nama Perusahaan'}</div>
                    <Input
                        className='flex-1'
                        name="company_name"
                        placeholder={"Isi nama perusahaan"}
                        value={dataWorkExperience?.company_name}
                        onChange={onChangeForm}
                    />
                </div>
                <div className="flex items-center gap-3">
                    <div className="font-medium w-1/3 text-neutral-70">{'Jabatan'}</div>
                    <Input
                        className='flex-1'
                        name="position"
                        placeholder={"Isi posisi pekerjaan"}
                        maxLength={100}
                        value={dataWorkExperience?.position}
                        onChange={onChangeForm}
                    />
                </div>
                <div className="flex gap-3">
                    <div className="font-medium w-1/3 text-neutral-70 mt-2">{'Jobdesc'}</div>
                    <Input
                        className='flex-1'
                        name="job_description"
                        type="text-area"
                        row={3}
                        maxLength={100}
                        showCount
                        placeholder={"Tulis jobdesc pekerjaan"}
                        value={dataWorkExperience?.job_description}
                        onChange={onChangeForm}
                    />
                </div>
                <div className="flex items-center gap-3">
                    <div className="font-medium w-1/3 text-neutral-70">{'Tgl Masuk'}</div>
                    <Datepicker
                        name='work_exp_start_date'
                        className="flex-1"
                        placeholder="Pilih tanggal masuk"
                        value={dataWorkExperience?.start_date}
                        format='YYYY-MM-DD'
                        maxDate={moment()}
                        onChange={(e) => onChangeForm({ name: 'start_date', value: e })}
                    />
                </div>
                <div className="flex items-center gap-3">
                    <div className="font-medium w-1/3 text-neutral-70">{'Tgl Keluar'}</div>
                    <Datepicker
                        name='work_exp_end_date'
                        className="flex-1"
                        placeholder="Pilih tanggal keluar"
                        value={dataWorkExperience?.end_date}
                        format='YYYY-MM-DD'
                        maxDate={moment()}
                        onChange={(e) => onChangeForm({ name: 'end_date', value: e })}
                    />
                </div>
            </div>
        </FormModal>
    )
}
