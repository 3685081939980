import FormModal from "../../../commons/form-modal";
import Input from "../../../commons/input";
import {DOCUMENT_REQUIREMENT_CONST} from "../../../../constants/constant-management-contract";
import icPlus from "../../../../assets/images/ic-plus-01-primary.svg";
import icProfilePicture from "../../../../assets/images/ic-user-profile.svg"
import {defineContractStatus} from "../../../../utils/utils";

export const UploadDocumentModal = ({show, onHide, onSubmitForm, contractDetail, employeeDetail, onUploadFile, documentUpload, formValid}) => {
    let contractStatus = defineContractStatus(contractDetail.status, contractDetail.addendum_number, contractDetail.is_renew_contract)
    return (
        <FormModal title={'Upload Document'} onHide={onHide} onSubmitForm={() => onSubmitForm(contractStatus)} show={show} isDisabled={formValid(contractStatus)}>
            <div className={"p-8 space-y-8"}>
                <div className={"flex p-4 space-x-4 bg-neutral-10 rounded-md"}>
                    <img src={employeeDetail?.profile_picture ?? icProfilePicture} alt={'profile-picture'}
                         className={"rounded-full w-12 h-12 border"}/>
                    <div className={"flex flex-col space-y-2"}>
                        <p className={"font-bold text-neutral-80"}>{employeeDetail.name}</p>
                        <div className={"flex items-center space-x-2 font-medium"}>
                            <p className={"text-neutral-70"}>No. Kontrak</p>
                            <p className={"text-neutral-80"}>{contractDetail.number}</p>
                        </div>
                        {
                            contractDetail?.addendum_number &&
                            <div className={"flex items-center space-x-2 font-medium"}>
                                <p className={"text-neutral-70"}>No. Addendum</p>
                                <p className={"text-neutral-80"}>{contractDetail.addendum_number}</p>
                            </div>
                        }
                    </div>
                </div>
                {
                    DOCUMENT_REQUIREMENT_CONST[contractStatus].map((item, index) => {
                        switch (item.type) {
                            case 'description':
                                return (
                                    <div className={"flex"} key={index}>
                                        <p className={`w-1/3 ${item.required && 'required'}`}>{`Notes ${contractDetail?.is_renew_contract ? 'Perubahan' : 'Addendum'}`}</p>
                                        <Input type="text-area" row={5} showCount maxLength={300} className={"w-2/3"}
                                               placeholder={`Notes ${contractDetail?.is_renew_contract ? 'Perubahan' : 'Addendum'}`} onChange={onUploadFile} name={item.key}/>
                                    </div>
                                )
                            default:
                                return (
                                    <div className={"flex items-center"} key={index}>
                                        <p className={`w-1/3 ${item.required && 'required'}`}>{item.label}</p>
                                        {
                                            !!documentUpload[item.key] ?
                                                <div className={"w-2/3 flex justify-between items-center"}>
                                                    <p className={"font-semibold capitalize truncate w-36"}>{documentUpload[item.key].name}</p>
                                                    <div>
                                                        <input className={'hidden'} accept="application/pdf" id={item.key}
                                                               type={'file'} onChange={(e) => onUploadFile(e, item.key)}/>
                                                        <label className="avo__button outline cursor-pointer" type="button" htmlFor={item.key}>
                                                            <div className="btn-content">
                                                                <img src={icPlus} alt='upload-ulang'/>
                                                                <span>{'Upload Ulang'}</span>
                                                            </div>
                                                        </label>
                                                    </div>
                                                </div>
                                                :
                                                <div >
                                                    <input className={'hidden'} accept="application/pdf" id={item.key}
                                                           type={'file'} onChange={(e) => onUploadFile(e, item.key)}/>
                                                    <label className="avo__button outline cursor-pointer"
                                                           type="button" htmlFor={item.key}>
                                                        <div className="btn-content">
                                                            <img src={icPlus} alt='ubah'/>
                                                            <span>{'Upload'}</span>
                                                        </div>
                                                    </label>
                                                </div>
                                        }
                                    </div>
                                )
                        }
                    })
                }

            </div>
        </FormModal>
    )
}
