import {GradeSetting} from "components/organization-structure-management/grade-setting/GradeSetting";
import {useFetch} from "services/useFetch";
import {API_GRADE} from "services/apiGrade";
import {useEffect, useState} from "react";
import {downloadFile, getAllQueryParams, setCurrency, setQueryParams} from "utils/utils";
import moment from "moment";
import {useValidation} from "../../../hooks/useValidation";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {TABLE_GRADE_SETTING} from "../../../configs/organization-structure/configTableGrade";

const INIT_COLUMN = {
    name: "Allowances",
    key: "allowances",
    classNameHeader: "!text-center",
}

const INIT_SUB_COLUMN = {
    name: null,
    key: null,
    isShowTooltip: true,
    classNameHeader: "!text-center max-w-0",
    classNameTooltip: "font-medium truncate",
    className: '!text-center',
    render: ({value}) => <div>{setCurrency(value)}</div>
}

const INITIAL_STATE = {
    name: null,
    min_basic_salary: null,
    max_basic_salary: null,
    allowances: []
}

const gradeValidationScheme = {
    name: {
        required: "Nama tidak boleh kosong",
    },
    min_basic_salary: {
        required: "Minimum salary tidak boleh kosong",
    },
    max_basic_salary: {
        required: "Maximum salary tidak boleh kosong",
    }
}

const defaultQuery = {
    page: 1,
    search: '',
    limit: 10,
}
export const GradeSettingContainer = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams();
    const query = searchParams.size > 0 ? getAllQueryParams(searchParams) : defaultQuery
    const {data: gradeData, fetching: getGradeListApi, isLoading} = useFetch(API_GRADE.LIST_GRADE)
    const {fetching: createGradeApi} = useFetch(API_GRADE.CREATE_GRADE)
    const {fetching: exportGradeApi} = useFetch(API_GRADE.EXPORT_GRADE)
    const {fetching: deleteGradeApi} = useFetch(API_GRADE.DELETE_GRADE)
    const {fetching: updateGradeApi} = useFetch(API_GRADE.UPDATE_GRADE)
    const [modal, setShowModal] = useState({
        export: false,
        form: false,
        delete: false,
        import: false,
    })
    const [gradeDetail, setGradeDetail] = useState({...INITIAL_STATE})
    const [allowancesColumn, setAllowancesColumn] = useState([])
    const [selectedRowIds, setSelectedRowIds] = useState([])
    const [selectedItem, setSelectedItem] = useState()
    const [isShowToast, setIsShowToast] = useState(false)
    const [toastMessage, setToastMessage] = useState('')
    const {clearErrorForm, errorForm, validateForm} = useValidation({form: gradeDetail, schema: gradeValidationScheme})

    useEffect(() => {
        fetchGradeList()
    }, [location?.search])

    const clearToast = () => {
        setIsShowToast(false)
        setToastMessage('')
    }

    const onSetModal = (item) => {
        setShowModal({...modal, ...item})
    }

    const setShowToast = (message, itemModal) => {
        onSetModal(itemModal)
        setIsShowToast(true)
        setToastMessage(message)
    }

    const fetchGradeList = () => {
        getGradeListApi({...query})
            .then(response => setAllowancesColumn(response?.data?.result?.column_map || []))
    }

    const getAllowancesState = (allowancesData = {}) => {
        return allowancesColumn?.map(item => { return {...item, value: allowancesData[item?.key] || 0} })
    }

    const addGradeHandler = () => {
        clearErrorForm()
        onSetModal({form: true})
        setGradeDetail({...INITIAL_STATE, allowances: getAllowancesState()})
    }

    const onChangeValueHandler = (value, slug, index) => {
        let itemDetailCopied = {...gradeDetail}
        if (slug) {
            itemDetailCopied.allowances[index] = {...itemDetailCopied.allowances[index], value: Number(value.value)}
        } else {
            itemDetailCopied[value.name] = value?.name === 'name' ? value.value : Number(value.value)
        }
        setGradeDetail(itemDetailCopied)
    }

    const onSubmitHandler = () => {
        validateForm(gradeDetail)
            .then(() => {
                if (gradeDetail?.id) {
                    updateGrade()
                } else {
                    createGrade()
                }
            })
    }

    const createGrade = () => {
        createGradeApi(gradeDetail)
            .then(() => {
                fetchGradeList()
                setShowToast('Grade Created !!', {form: false})
            })
            .catch(err => console.log("err"))
    }

    const updateGrade = () => {
        updateGradeApi(gradeDetail, gradeDetail?.id)
            .then(() => {
                fetchGradeList()
                setShowToast('Grade Updated !!', {form: false})
            })
            .catch(err => console.log("err"))
    }

    const onExportHandler = () => {
        let body = {
            search: query?.search,
            grade_ids: selectedRowIds
        }

        exportGradeApi(body, '', {}, 'blob')
            .then(res => {
                downloadFile(null, res, `Grade-List-Export-${moment().format('DD-MM-YYYY')}.xlsx`)
                setShowToast('Grade Exported !!', {export: false})
            })
    }

    const onSelectRowHandler = (item) => {
        let ids = item?.map(i => i?.id)
        setSelectedRowIds(ids)
    }

    const onClickItemHandler = (value) => {
        const {item, actionType} = value
        if (actionType === 'DELETE') {
            onSetModal({delete: true})
            setSelectedItem(item)
        }

        if (actionType === 'EDIT') {
            clearErrorForm()
            setGradeDetail({...item, allowances: getAllowancesState(item)})
            onSetModal({form: true})
        }

    }

    const onDeleteHandler = () => {
        deleteGradeApi({}, selectedItem?.id)
            .then(() => {
                setShowToast('Grade Deleted !!', {delete: false})
                if (gradeData?.data?.result?.data?.length === 1 && Number(query?.page) !== 1) {
                    onChangeQuery({page: 1})
                } else {
                    fetchGradeList()
                }
            })
            .catch(err => console.log("err :", err))
    }

    const onChangeQuery = (queryParams) => {
        const params = setQueryParams({...query, ...queryParams})
        setSearchParams(params, {replace: true})
    }

    const onClickSettingTypeAllowance = () => {
        navigate('/management-structure-organization/grade-settings/setting-type-allowance')
    }

    const getColumnTable = () => {
        const column = [...TABLE_GRADE_SETTING.column]
        const subColumnResult = []
        allowancesColumn?.map(item => subColumnResult.push({...INIT_SUB_COLUMN, ...item}))

        if (subColumnResult?.length) {
            const combineObj = {...INIT_COLUMN, subColumn: subColumnResult}
            column.splice(2, 0, combineObj)
        }

        return column
    }

    const props = {
        gradeData: gradeData?.data,
        columnTable: getColumnTable(),
        gradeDetail,
        modal,
        isLoading,
        toastMessage,
        isShowToast,
        clearToast,
        addGradeHandler,
        onChangeValueHandler,
        onSubmitHandler,
        onExportHandler,
        onSelectRowHandler,
        selectedRowIds,
        onClickItemHandler,
        onDelete: onDeleteHandler,
        onSetModal,
        errorForm,
        onChangeQuery,
        onClickSettingTypeAllowance,
        query,
        allowancesColumn,
        fetchGradeList,
        setShowToast
    }

    return (
        <GradeSetting {...props}/>
    )
}
