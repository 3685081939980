import {METHOD} from "./method";

export const API_CONTRACT = {
    CONTRACT_LIST: {
        api: '/v1/hr-adm/contract/list',
        method: METHOD.POST
    },
    EMPLOYEE_LIST: {
        api: '/v1/hr-adm/contract/employees',
        method: METHOD.GET
    },
    EMPLOYEE_DETAIL: {
        api: '/v1/hr-adm/contract/employee',
        method: METHOD.GET
    },
    CONSTANT: {
        api: '/v1/hr-adm/contract/constant',
        method: METHOD.GET
    },
    LIST_COMPANY: {
        api: '/v1/hr-adm/contract/companies',
        method: METHOD.GET
    },
    LIST_JOB_TITLE: {
        api: '/v1/hr-adm/contract/job-titles',
        method: METHOD.GET
    },
    LIST_GRADE: {
        api: '/v1/hr-adm/contract/grades',
        method: METHOD.GET
    },
    ALLOWANCE: {
        api: '/v1/hr-adm/contract/grade-allowances',
        method: METHOD.GET
    },
    CREATE_CONTRACT: {
        api: '/v1/hr-adm/contract',
        method: METHOD.POST
    },
    PREVIEW_DETAIL: {
        api: '/v1/hr-adm/contract/preview',
        method: METHOD.GET
    },
    EXPORT: {
        api: '/v1/hr-adm/contract/list/export',
        method: METHOD.POST
    },
    DETAIL_CONTRACT_HISTORY: {
        api: '/v1/hr-adm/contract/history',
        method: METHOD.GET
    },
    DETAIL_CONTRACT: {
        api: '/v1/hr-adm/contract',
        method: METHOD.GET
    },
    DETAIL_CONTRACT_INFORMATION: {
        api: '/v1/hr-adm/contract/information',
        method: METHOD.GET
    },
    GET_URL_CONTRACT_DOCUMENT: {
        api: '/v1/hr-adm/contract/storage-url/document',
        method: METHOD.POST
    },
    UPLOAD_CONTRACT_DOCUMENT: {
        api: '/v1/hr-adm/contract/upload',
        method: METHOD.POST
    },
    UPLOAD_ADDENDUM_CONTRACT_DOCUMENT: {
        api: '/v1/hr-adm/contract/addendum/upload',
        method: METHOD.POST
    },
    UPLOAD_RENEW_CONTRACT_DOCUMENT: {
        api: '/v1/hr-adm/contract/renew/upload',
        method: METHOD.POST
    },
    EDIT_CONTRACT: {
        api: '/v1/hr-adm/contract',
        method: METHOD.PUT
    },
    ADDENDUM_CONTRACT: {
        api: '/v1/hr-adm/contract/addendum',
        method: METHOD.POST
    },
    RENEW_CONTRACT: {
        api: '/v1/hr-adm/contract/renew',
        method: METHOD.POST
    },
    COMPLETE_CONTRACT: {
        api: '/v1/hr-adm/contract/complete',
        method: METHOD.POST
    },
    DELETE_CONTRACT: {
        api: '/v1/hr-adm/contract/delete',
        method: METHOD.POST
    }
}
