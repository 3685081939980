import {GenerateComponent} from "../../../components/payroll/create-payroll/GenerateComponent";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {useFetch} from "../../../services/useFetch";
import {API_CREATE_PAYROLL} from "../../../services/apiCreatePayroll";
import {STEPS_CREATE_PAYROLL} from "../../../constants/constant";
import {getPathUrl} from "../../../utils/utils";

const INIT_PROGRESS_DETAIL = {
    completed_count: 0,
    failed_count: 0,
    total_count: 0,
    ongoing_count: 0,
    is_process_done: false
}

export const GenerateComponentContainer = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const [percentage, setPercentage] = useState(0)
    const [searchParams] = useSearchParams()
    const payrollId = searchParams.get('payrollId')
    const monthName = getPathUrl(location, '-', -2).join('-')
    const [progressDetail, setProgressDetail] = useState(INIT_PROGRESS_DETAIL)
    const [isCalculate, setIsCalculate] = useState(false)
    const [isRefresh, setIsRefresh] = useState(false)
    const {fetching: checkProgress} = useFetch({...API_CREATE_PAYROLL.CHECK_PROGRESS, initPathVariable: payrollId})
    const {fetching: calculate, isLoading} = useFetch({...API_CREATE_PAYROLL.CALCULATE, initPathVariable: payrollId})
    const {fetching: updateLastStep, isUpdatingStep} = useFetch({...API_CREATE_PAYROLL.UPDATE_LAST_STEP, initPathVariable: payrollId})

    const getPercentage = (dataCount) => {
        return Math.round(((dataCount?.completed_count + dataCount?.failed_count) / dataCount?.total_count) * 100)
    }

    const setRefresh = (dataOngoingCount, isFirstLoad) => {
        if (dataOngoingCount && isFirstLoad) {
            setIsRefresh(true)
        } else {
            setIsRefresh(false)
        }
    }

    const checkCompletedHandler = (dataCount) => {
        if (dataCount?.is_process_done) {
            setTimeout(() => {
                setIsCalculate(false)
                setProgressDetail({...dataCount})
            }, 1000)
        } else {
            setProgressDetail({...dataCount})
        }
    }

    const checkProgressHandler = async (isFirstLoad) => {
        try {
            const currentProgress = await checkProgress()
            const percentage = getPercentage(currentProgress?.data)
            setPercentage(percentage)
            setRefresh(currentProgress?.data?.ongoing_count, isFirstLoad)
            checkCompletedHandler(currentProgress?.data)
        } catch (error) {
            if (error?.code === 'ERR_NETWORK' && isCalculate) setIsRefresh(true)
            setIsCalculate(false)
        }
    }

    useEffect(() => {
        checkProgressHandler(true)
            .then()
    }, [])

    useEffect(() => {
        let timeout = null
        if (isCalculate && !progressDetail?.is_process_done) {
            timeout = setTimeout(async () => {
                await checkProgressHandler()
            }, 3000)
        }

        return () => {
            clearTimeout(timeout)
        }
    }, [isCalculate, progressDetail])

    const getWordingCalculateButton = () => {
        if (isRefresh) {
            return 'Lanjutkan Perhitungan Komponen'
        } else if (!progressDetail.completed_count && !progressDetail?.failed_count) {
            return 'Hitung Seluruh Komponen'
        } else {
            return 'Hitung Ulang Sisa Komponen'
        }
    }

    const onCalculate = () => {
        if (!isRefresh) {
            calculate()
                .then(() => setIsCalculate(true))
        } else {
            setIsCalculate(true)
        }
    }

    const onSubmit = () => {
        updateLastStep({last_step: 4})
            .then(() => navigate(`/payroll/create-payroll/${STEPS_CREATE_PAYROLL[3]}-${monthName}?payrollId=${payrollId}`))
    }

    const props = {
        progressDetail,
        percentage,
        isCalculate,
        isUpdatingStep,
        isLoading,
        isRefresh,
        getWordingCalculateButton,
        onCalculate,
        onSubmit
    }

    return (
        <GenerateComponent {...props}/>
    )
}
