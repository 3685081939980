import clsx from "clsx";
import './timepicker.scss';
import {useEffect, useRef, useState} from "react";
import icClock from './ic-clock.svg';
import icXGrey from "../search/ic-x-grey.svg";

const Timepicker = ({className, name, placeholder = 'hh:mm', value, disabled, onChange, isOnlyHour}) => {
    const inputRef = useRef()
    const regexValidValue = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/
    const [isOnFocus, setIsOnFocus] = useState(false)
    const [sValue, setValue] = useState("")

    const handleOnChange = (e) => {
        const {name, value} = e.target
        setValue(value)
        if (onChange) onChange({name, value})
    }

    const handleOnFocus = () => {
        setIsOnFocus(true)
        inputRef.current.type = 'time'
    }

    const handleOnBlur = () => {
        setIsOnFocus(false)
        inputRef.current.type = 'text'
    }

    const handleClickIconClock = () => {
        if (isOnlyHour) return
        handleOnFocus()
        inputRef.current.focus()
        inputRef.current.showPicker()
    }

    const handleClickIconClose = () => {
        setValue('')
        if (onChange) onChange({name, value: null})
    }

    useEffect(() => {
        const initValue = regexValidValue.test(value) ? value : ""
        setValue(initValue)
    }, [value])

    return (
        <div className={clsx("avo__timepicker", className)}>
            <input
                className={clsx("input-timepicker", isOnlyHour && 'disabled-time-indicator')}
                name={name}
                step={isOnlyHour && '3600'}
                type="text"
                placeholder={placeholder}
                value={sValue}
                ref={inputRef}
                onChange={handleOnChange}
                onFocus={handleOnFocus}
                onBlur={handleOnBlur}
                disabled={disabled}
            />
            {
                ((!isOnFocus && sValue?.length === 0) || (disabled && sValue?.length > 0)) &&
                <img src={icClock} alt="ic-clock" className={clsx("icon-clock", disabled && "disabled")}
                     onClick={!disabled ? handleClickIconClock : () => {}}/>

            }
            {
                (!isOnFocus && sValue?.length > 0) && !disabled &&
                <img src={icXGrey} alt="ic-x" className="icon-close"
                     onClick={handleClickIconClose}/>
            }
        </div>
    )
}

export default Timepicker
