import {ACTION_TYPE} from "../../../constants/constant";
import iconClockForward from 'assets/images/ic-clock-forward.svg'
import Input from "../../commons/input";
import Switch from "../../commons/switch";
import Button from "../../commons/button";
import Timepicker from "../../commons/timepicker";
import ErrorMessage from "../../commons/error-message";
import clsx from "clsx";

export const WorkTimeForm = (
    {
        actionType,
        workTimeDetail,
        onChangeValue,
        isCreating,
        isUpdating,
        onSubmitForm,
        errorForm
    }) => {

    const dayDetailList = [
        { dayName: 'senin', key: 'monday' },
        { dayName: 'selasa', key: 'tuesday' },
        { dayName: 'rabu', key: 'wednesday' },
        { dayName: 'kamis', key: 'thursday' },
        { dayName: 'jumat', key: 'friday' },
        { dayName: 'sabtu', key: 'saturday' },
        { dayName: 'minggu', key: 'sunday' }
    ]

    return (
        <>
            <div className={"flex flex-col space-y-10 px-6 pt-8 pb-[100px] w-1/2"}>
                <div className={"flex flex-col space-y-6"}>
                    <div className={"flex space-x-3 items-center"}>
                        <img src={iconClockForward} alt="ic-clock-forward"/>
                        <p className={"font-bold text-md"}>{actionType === ACTION_TYPE.CREATE ? 'Tambah' : 'Ubah'} Kategori
                            Jam Kerja</p>
                    </div>
                    <div className={"flex items-center text-neutral-70"}>
                        <div className={clsx("w-1/3 required", errorForm?.category_name && "-mt-7")}>Nama Kategori Jam Kerja</div>
                        <div className="flex-1">
                            <Input name="category_name" placeholder="Isi Nama" value={workTimeDetail?.category_name}
                                   onChange={(e) => onChangeValue(e)}/>
                            <ErrorMessage className="mt-2" message={errorForm?.category_name}/>
                        </div>
                    </div>
                    <div className={"flex items-center text-neutral-70"}>
                        <div className="w-1/3">Shifting</div>
                        <Switch name={'type'} checked={workTimeDetail?.type === 'shifting'} onChange={(e) => onChangeValue(e)}/>
                    </div>
                </div>
                <div className={`flex flex-col space-y-6 ${workTimeDetail?.type === 'shifting' ? 'hidden' : 'block'}`}>
                    <p className={"font-bold text-primary-60"}>Pilih Waktu & Hari</p>
                    {
                        dayDetailList?.map((item, index) => (
                            <div className={"flex flex-grow space-x-3 text-neutral-70"} key={index}>
                                <div className={"w-1/3 capitalize mt-2"}>{item?.dayName}</div>
                                <div className="flex-1">
                                    <Timepicker
                                        name={`${item.key}-clock_in_time`}
                                        placeholder="Jam Masuk"
                                        value={workTimeDetail?.[item?.key]?.clock_in_time}
                                        onChange={(e) => onChangeValue(e)}
                                    />
                                    <ErrorMessage className="mt-2" message={errorForm?.[item?.key]?.clock_in_time}/>
                                </div>
                                <div className="flex-1">
                                    <Timepicker
                                        name={`${item.key}-clock_out_time`}
                                        placeholder="Jam Keluar"
                                        value={workTimeDetail?.[item?.key]?.clock_out_time}
                                        onChange={(e) => onChangeValue(e)}
                                    />
                                    <ErrorMessage className="mt-2" message={errorForm?.[item?.key]?.clock_out_time}/>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
            <div className={"flex items-center justify-end fixed bottom-0 w-[calc(100%-200px)] bg-white border-t px-14 py-6 z-10"}>
                <Button className="w-32" title="Submit" loading={isCreating || isUpdating} onClick={onSubmitForm} />
            </div>
        </>
    )
}
