import { Layout } from "components/layout";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import store from "./reducers";
import RoutePage from "./routes";

function App() {
	return (
		<Provider store={store}>
			<BrowserRouter>
				<Layout>
					<RoutePage />
				</Layout>
			</BrowserRouter>
		</Provider>
	);
}

export default App
