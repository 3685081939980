import Filter from "components/commons/filter"
import { useEffect, useState } from "react"
import { useFetch } from "services/useFetch"
import { deepClone, getHeadUnitText } from "utils/utils"
import {API_REQUEST_LEAVE} from "../../services/apiRequestLeave";

const FILTER = {
    LEAVE_TYPE: 'leave_type',
    DEPARTMENT: 'department',
    DIVISION: 'division',
}

const INITIAL_DATA_FILTER = [
    {
        type: 'date',
        label: 'Tanggal Pengajuan Cuti',
        start_date: {
            label: 'Dari Tanggal',
            name: 'start_date',
            placeholder: 'Pilih tanggal awal',
            isRequired: true
        },
        end_date: {
            label: 'Sampai Tanggal',
            name: 'end_date',
            placeholder: 'Pilih tanggal akhir',
            isRequired: true
        },
    },
    { label: 'Tipe Cuti', value: FILTER.LEAVE_TYPE },
    { label: 'Departement', value: FILTER.DEPARTMENT },
    { label: 'Divisi', value: FILTER.DIVISION },
]

const JOB_UNIT = [FILTER.DEPARTMENT, FILTER.DIVISION]

const INITIAL_OPTIONS = {
    [FILTER.LEAVE_TYPE]: [],
    [FILTER.DEPARTMENT]: [],
    [FILTER.DIVISION]: [],
}

const INITIAL_FILTER = {
    leave_type_ids: [],
    department_ids: [],
    division_ids: [],
    start_date: '',
    end_date: ''
}

const QUERY_PARAMS = {
    [FILTER.LEAVE_TYPE]: 'leave_type_ids',
    [FILTER.DEPARTMENT]: 'department_ids',
    [FILTER.DIVISION]: 'division_ids',
}

export const RequestLeaveFilterContainer = ({
    show,
    onShow,
    onClose,
    onSubmit,
    queryFilter,
}) => {
    const [activeFilter, setActiveFilter] = useState(INITIAL_DATA_FILTER[0])
    const [options, setOptions] = useState(INITIAL_OPTIONS)
    const [filter, setFilter] = useState(INITIAL_FILTER)
    const [filterCount, setFilterCount] = useState()
    const [errorFilter, setErrorFilter] = useState({})
    const { fetching: getJobUnit } = useFetch(API_REQUEST_LEAVE.JOB_UNIT_LIST)
    const { fetching: getLeaveType } = useFetch(API_REQUEST_LEAVE.LEAVE_TYPE_LIST)

    useEffect(() => {
        if (queryFilter) {
            const newQueryFilter = deepClone(queryFilter)
            setFilter(newQueryFilter)
            countFilterLength(newQueryFilter)
        }
    }, [queryFilter, show])

    const onCloseHandler = () => {
        onClose()
        setErrorFilter({})
    }

    const onSelectFilter = (item) => {
        if (JOB_UNIT.includes(item?.value) && options[item?.value].length === 0) {
            getJobUnit({head_unit_ids: filter?.[QUERY_PARAMS[getHeadUnitText[item?.value]]] || []}, item?.value)
                .then(res => setOptions({...options, [item?.value]: res?.data}))
        }

        if (item.value === FILTER.LEAVE_TYPE && options[item?.value].length === 0) {
            getLeaveType().then(res => setOptions({...options, [item?.value]: res?.data}))
        }
        setActiveFilter(item)
    }

    const onChangeOption = (item) => {
        const { name, value, checked } = item
        const arrChecked = filter?.[QUERY_PARAMS[name]] || []
        handleClearOptionsFilter(name)

        if (checked) {
            arrChecked.push(String(value))
        } else {
            const indexRemove = arrChecked.indexOf(String(value))
            arrChecked.splice(indexRemove, 1)
        }
        setFilter({ ...filter, [QUERY_PARAMS[name]]: arrChecked })
    }

    const handleClearOptionsFilter = (filterName) => {
        if (filterName === FILTER.DEPARTMENT) {
            options[FILTER.DIVISION] = []
            filter[[QUERY_PARAMS[FILTER.DIVISION]]] = []
        }
        setOptions({ ...options })
    }

    const onChangeDate = (e) => {
        const { name, value } = e
        setFilter({ ...filter, [name]: value })
    }

    const validateFilter = (query) => {
        return new Promise((resolve, reject) => {
            const errors = {}
            if (!query['start_date'] && query['end_date']) errors['start_date'] = 'Tanggal Awal tidak boleh kosong'
            if (!query['end_date'] && query['start_date']) errors['end_date'] = 'Tanggal Akhir tidak boleh kosong'
            setErrorFilter(errors)
            if (!Object.keys(errors).length) resolve()
        })
    }

    const onSubmitFilter = () => {
        const queryFilter = { ...filter }
        validateFilter(queryFilter).then(() => {
            Object.entries(queryFilter).forEach(([key, item]) => {
                if (Array.isArray(queryFilter[key])) queryFilter[key] = item.join(',')
            })
            onSubmit(queryFilter)
            onClose()
        })
    }

    const countFilterLength = (filter) => {
        let totalLength = 0
        for (const key in filter) {
            if (filter.hasOwnProperty(key)) {
                if (Array.isArray(filter[key])) {
                    totalLength += filter[key].length;
                } else {
                    totalLength += 1;
                }
            }
        }
        setFilterCount(totalLength)
    }

    const onClearFilter = () => {
        setFilter(deepClone(INITIAL_FILTER))
        setErrorFilter({})
    }

    const props = {
        show,
        onShow,
        onClose: onCloseHandler,
        queryParams: QUERY_PARAMS,
        filterList: INITIAL_DATA_FILTER,
        activeFilter,
        onSelectFilter,
        filter,
        filterCount,
        options,
        onChangeOption,
        onChangeDate,
        onSubmitFilter,
        onClearFilter,
        errorFilter
    }

    return (
        <Filter {...props} />
    )
}
