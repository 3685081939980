import {useState} from "react";
import icChevDown from "../../assets/images/ic-chev-down.svg";
import {useClickOutside} from "../../hooks/useClickOutside";

export const HeaderSidebar = ({companies = [], activeCompany = null, onSwitchCompany}) => {
    const [showOptions, setShowOptions] = useState(false)

    useClickOutside({
        callback: () => setShowOptions(false),
        elementID: 'header-sidebar'
    })

    if (!activeCompany) return null

    return (
        <div id="header-sidebar" className="relative">
            <div
                className="relative group flex items-center px-2 py-4 mx-1 mt-2 mb-3 gap-3 border border-transparent hover:rounded hover:border-neutral-20 hover:bg-neutral-10 cursor-pointer transition-all duration-300">
                {activeCompany?.logo && <img src={activeCompany?.logo} className='w-6 h-6' alt="logo"/>}
                <h1 className='font-semibold text-primary-60'>{activeCompany?.name}</h1>
                <img src={icChevDown}
                     className='w-6 h-6 p-1 border border-transparent rounded group-hover:border-neutral-30 transition-all duration-300'
                     alt="chev" onClick={() => setShowOptions(!showOptions)}/>
            </div>
            <div className={showOptions ? 'block' : 'hidden'}>
                <div
                    className={"col absolute left-1 top-[85px] z-10 w-[274px] bg-white shadow-md rounded-md border overflow-scroll"}>
                    {
                        companies?.map(company => (
                            <div
                                key={company?.id}
                                onClick={() => {
                                    onSwitchCompany(company)
                                    setShowOptions(!showOptions)
                                }}
                                className={"flex space-x-4 px-4 py-3 items-center hover:bg-neutral-10 hover:text-primary-50 hover:font-semibold cursor-pointer"}>
                                <img src={company?.logo} className={"w-6 h-6"} alt={'company-logo'}/>
                                <p>{company?.name}</p>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}
