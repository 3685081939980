import icArrowRightSM from "assets/images/ic-arrow-right-sm.svg"
import icCalendar from "assets/images/ic-calendar-minus.svg"
import icClockBackward from "assets/images/ic-clock-backward.svg"
import icClockForward from "assets/images/ic-clock-forward.svg"
import icUserDefault from "assets/images/ic-user-profile.svg"
import clsx from "clsx"
import { Map } from "./Map"
import moment from "moment"
import 'moment/locale/id'
moment.locale('id')

export const AttendanceDetail = ({ dataAttendanceDetail }) => {
    const { employee, attendance } = dataAttendanceDetail || {}

    return (
        <div className={"p-6 flex-col space-y-6"}>
            <div className={"p-3 bg-neutral-10 border border-neutral-20 rounded-md flex"}>
                <div className={"w-3/5 px-9 py-1 flex items-center space-x-6"}>
                    <img className={"w-16 h-16 border border-white rounded-full"} src={employee?.profile_picture || icUserDefault} alt={employee?.full_name} />
                    <div className={"flex-col justify-around space-y-3"}>
                        <p className={"text-lg font-medium text-neutral-80"}>{employee?.full_name}</p>
                        <p className={"font-medium text-neutral-80"}>{employee?.job_title}</p>
                        <p className="text-neutral-70">{employee?.nip}</p>
                    </div>
                </div>
                <div className={"w-2/5 bg-white border border-neutral-20 rounded-md px-8 py-6 space-y-5"}>
                    <div className={"flex items-center space-x-4"}>
                        <img src={icCalendar} alt={'calendar-minus'} />
                        <div>
                            <p className="font-medium">Jadwal Attendance</p>
                            <p className={"font-semibold text-md"}>{moment(attendance?.date).locale('id').format('dddd, DD MMMM YYYY')}</p>
                        </div>
                    </div>
                    <div className={"flex items-center space-x-5"}>
                        <div className={"flex items-center space-x-4"}>
                            <img className={'w-5 h-5'} src={icClockForward} alt={'calendar-minus'} />
                            <div className="w-20">
                                <p className="font-medium">Clock In</p>
                                <p className={"font-medium text-md"}>{attendance?.clock_in?.scheduled_time || '-'}</p>
                            </div>
                        </div>
                        <img src={icArrowRightSM} alt={'arrow-right'} />
                        <div className={"flex items-center space-x-4"}>
                            <img className={'w-5 h-5'} src={icClockBackward} alt={'calendar-minus'} />
                            <div className="w-20">
                                <p className="font-medium">Clock Out</p>
                                <p className={"font-medium text-md"}>{attendance?.clock_out?.scheduled_time || '-'}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <p className={"text-primary-60 font-semibold text-md"}>Detail Attendance</p>
            <div className={"flex gap-14"}>
                <div className={"flex-1 flex-col space-y-5"}>
                    <p className={"text-primary-60 font-semibold text-md"}>Clock In</p>
                    <div className={"flex items-center font-medium"}>
                        <p className={"w-1/4"}>Jam presensi</p>
                        <p className={clsx("flex-1", attendance?.clock_in?.actual_time && "text-2xl")}>{attendance?.clock_in?.actual_time || '-'}</p>
                    </div>
                    <div className={"flex items-center"}>
                        <p className={"w-1/4 font-medium"}>Notes</p>
                        <p className={"flex-1"}>{attendance?.clock_in?.note || '-'}</p>
                    </div>
                    {attendance?.clock_in?.lat && attendance?.clock_in?.lng && <div className={"flex"}>
                        <p className={"w-1/4 font-medium mt-1"}>Coordinate</p>
                        <div className="flex-1 space-y-3">
                            <div className={"flex-1 w-full h-52 rounded-lg overflow-hidden"}>
                                <Map coordinate={{ lat: attendance?.clock_in?.lat, lng: attendance?.clock_in?.lng }} />
                            </div>
                            <p>{attendance.clock_in?.address}</p>
                        </div>
                    </div>}
                    <div className={attendance?.clock_in?.attachments?.length ? "flex" : "hidden"}>
                        <p className={"w-1/4 font-medium"}>Photos</p>
                        <div className={"flex flex-wrap gap-3"}>
                            {
                                attendance?.clock_in?.attachments?.map((item, index) => (
                                    <img key={index} src={item} alt={'clock-in-image'} className={"w-[100px]"}/>
                                ))
                            }
                        </div>
                    </div>
                </div>
                <div className="border-r border-neutral-20 border-dashed" />
                <div className={"flex-1 flex-col space-y-5"}>
                    <p className={"text-primary-60 font-semibold text-md"}>Clock Out</p>
                    <div className={"flex items-center font-medium"}>
                        <p className={"w-1/4"}>Jam presensi</p>
                        <p className={clsx("flex-1", attendance?.clock_out?.actual_time && "text-2xl")}>{attendance?.clock_out?.actual_time || '-'}</p>
                    </div>
                    <div className={"flex items-center"}>
                        <p className={"w-1/4 font-medium"}>Notes</p>
                        <p className={"flex-1"}>{attendance?.clock_out?.note || '-'}</p>
                    </div>
                    {attendance?.clock_out?.lat && attendance?.clock_out?.lng && <div className={"flex"}>
                        <p className={"w-1/4 font-medium mt-1"}>Coordinate</p>
                        <div className="flex-1 space-y-3">
                            <div className={"flex-1 w-full h-52 rounded-lg overflow-hidden"}>
                                <Map coordinate={{ lat: attendance?.clock_out?.lat, lng: attendance?.clock_out?.lng }} />
                            </div>
                            <p>{attendance.clock_out?.address}</p>
                        </div>
                    </div>}
                    <div className={attendance?.clock_out?.attachments?.length ? "flex" : "hidden"}>
                        <p className={"w-1/4 font-medium"}>Photos</p>
                        <div className={"flex flex-wrap gap-3"}>
                            {
                                attendance?.clock_out?.attachments?.map((item, index) => (
                                    <img key={index} src={item} alt={'clock-out-image'} className={"w-[100px]"}/>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
