export const contractValidation = {
    job_title: {required: "Job Title Wajib Diisi"},
    grade: {required: "Grade Wajib Diisi"},
    ptkp_status: {required: "PTKP Status Wajib Diisi"},
    type: {required: "Tipe Kontrak Wajib Diisi"},
    number: {required: "No Kontrak Wajib Diisi"},
    nip: {required: "NIP Wajib Diisi"},
    start_date: {required: "Tanggal Mulai Kontrak Wajib Diisi"},
    end_date: {required: "Tanggal Berakhir Kontrak Wajib Diisi"},
    job_description: {required: "Job Description Wajib Diisi"},
    base_salary: {required: "Base Salary Wajib Diisi"}
}

export const addendumContractValidation = {
    job_title: {required: "Job Title Wajib Diisi"},
    grade: {required: "Grade Wajib Diisi"},
    ptkp_status: {required: "PTKP Status Wajib Diisi"},
    type: {required: "Tipe Kontrak Wajib Diisi"},
    number: {required: "No Kontrak Wajib Diisi"},
    addendum_number: {required: "No Addendum Kontrak Wajib Diisi"},
    nip: {required: "NIP Wajib Diisi"},
    addendum_start_date: {required: "Tanggal Mulai Addendum Wajib Diisi"},
    job_description: {required: "Job Description Wajib Diisi"},
    base_salary: {required: "Base Salary Wajib Diisi"}
}
