import iconCalendarAdd from "../../../../src/assets/images/ic-calendar-add.svg"
import MonthPicker from "../../commons/monthpicker";
import Button from "../../commons/button";
import moment from "moment";

export const SelectMonth = (
    {
        selectedMonth,
        onChangeMonth,
        onSubmit
    }) => {

    return (
        <div className={"flex flex-col space-y-10 justify-center items-center pt-28"}>
            <img src={iconCalendarAdd} alt="calendar-add" className={"mx-auto w-16"}/>
            <div className="space-y-3">
                <p className="text-neutral-70">Pilih Bulan Periode</p>
                <MonthPicker
                    className="w-[330px]"
                    format='YYYY-MM'
                    placeholder="Pilih Bulan"
                    value={selectedMonth}
                    minYear={moment().get('year')}
                    maxYear={moment().get('year') + 1}
                    onChange={(val) => onChangeMonth(val)}
                />
            </div>
            <Button className="w-[330px]" title="Buat Payroll" disabled={!selectedMonth} onClick={onSubmit}/>
        </div>
    )
}
