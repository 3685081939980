import {FORM_PREVIEW_USER, FORM_SALARY_COMPONENT} from "../../../constants/constant-management-contract";
import Button from "../../commons/button";
import icEye from "../../../assets/images/ic-eye-open.svg";
import icUserProfile from "../../../assets/images/ic-user-profile.svg";
import Drawer from "../../commons/drawer";
import {setCurrency} from "../../../utils/utils";
import {ModalDocument} from "../../commons/modal-document/ModalDocument";

export const PreviewContract = ({
                                    visibility,
                                    employeeDetail,
                                    documentPreview,
                                    setDocumentPreview,
                                    modalPreview,
                                    updateVisibility
                                }) => {
    const previewDocumentHandler = (document) => {
        updateVisibility('preview', true)
        setDocumentPreview(document)
    }

    const hasDocument = employeeDetail.contract_document_url || employeeDetail.hki_document_url || employeeDetail.sk_document_url

    return (
        <div>
            <Drawer visibility={visibility} onHide={() => updateVisibility('drawer', false)} title={'Preview User'}>
                <div className={"mt-5 px-6 space-y-5"}>
                    <div className={"flex flex-row items-center gap-4"}>
                        <img src={employeeDetail?.profile_picture ?? icUserProfile} alt={'employee-profile-image'}
                             className={"w-9 h-9 rounded-full border"}/>
                        <p className={"text-md font-bold text-neutral-80 capitalize"}>{employeeDetail?.name}</p>
                    </div>
                    {
                        FORM_PREVIEW_USER.map((item, index) => <ComponentItem key={index} label={item.label}
                                                                              value={employeeDetail[item.key]}/>)
                    }
                    <p className={"font-bold text-primary-60"}>Komponen Gaji</p>
                    {
                        FORM_SALARY_COMPONENT.map((item, index) => <ComponentItem key={index} label={item.label}
                                                                                  value={setCurrency(employeeDetail[item.key])}/>)
                    }
                    {
                        !!employeeDetail?.allowances &&
                        employeeDetail?.allowances.map((item, index) => <ComponentItem key={index} label={item.label}
                                                                                       value={setCurrency(item.value)}/>)
                    }
                    {hasDocument && <p className={"font-bold text-primary-60"}>Dokumen</p>}
                    {employeeDetail.contract_document_url && <div className={"flex flex-row items-center justify-between"}>
                        <p className={"text-neutral-70"}>Kontrak</p>
                        <Button title={'Preview'} onClick={() => previewDocumentHandler(employeeDetail.contract_document_url)} icon={icEye} type={'outline'}/>
                    </div>}
                    {employeeDetail.hki_document_url && <div className={"flex flex-row items-center justify-between"}>
                        <p className={"text-neutral-70"}>HKI</p>
                        <Button title={'Preview'} onClick={() => previewDocumentHandler(employeeDetail.hki_document_url)} icon={icEye} type={'outline'}/>
                    </div>}
                    {employeeDetail.sk_document_url && <div className={"flex flex-row items-center justify-between"}>
                        <p className={"text-neutral-70"}>Surat Keputusan</p>
                        <Button title={'Preview'} onClick={() => previewDocumentHandler(employeeDetail.sk_document_url)} icon={icEye} type={'outline'}/>
                    </div>}
                </div>
            </Drawer>
            <ModalDocument onHide={() => updateVisibility('preview', false)} show={modalPreview}
                           src={documentPreview}/>
        </div>
    )
}

const ComponentItem = ({label, value}) => {
    return (
        <div className={"flex flex-col space-y-1"}>
            <p className={"text-neutral-70"}>{label}</p>
            <p className={"text-neutral-80 font-semibold"}>{value}</p>
        </div>
    )
}
