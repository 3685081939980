export const formJobTitleValidation = {
    title: {
        required: "Job title tidak boleh kosong"
    },
    management_id: {
        required: "Management tidak boleh kosong"
    },
    grade_ids: {
        required: "Grade tidak boleh kosong"
    },
    schedule_category_id: {
        required: "Kategori jam kerja tidak boleh kosong"
    },
    quota: {
        required: "Kuota karyawan tidak boleh kosong"
    }
}
