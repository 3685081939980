import moment from "moment/moment";
import icCheckGreen from "../assets/images/ic-check-green.svg";
import icXCircleContained from "../assets/images/ic-x-circle-contained.svg";
import icStopwatchRed from "../assets/images/ic-stopwatch-red.svg";
import axios from "axios";
import {Alert} from "../components/commons/alert";

const regexEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const innerHTML = (html) => {
    return {__html: html}
}

const getFileUnit = (size) => {
    const count = size === 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024))
    return (size / Math.pow(1024, count)).toFixed(2) * 1 + ' ' + ['B', 'KB', 'MB', 'GB', 'TB'][count]
}

const setCurrency = (number = 0) => {
    return new Intl.NumberFormat('id-ID', {style: 'currency', currency: 'IDR', minimumFractionDigits: 0}).format(number)
}

const separateNumber = (x, sp = ".") => x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, sp)

const formatDateMoment = (date, format) => {
    return moment(date).format(format)
}

const capitalize = (str) => {
    if (typeof str !== 'string' || str.length === 0) return str;
    const words = str.split(' ')
    const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1))
    return capitalizedWords.join(' ')
}

const getAllQueryParams = (searchParams) => {
    const params = {}
    searchParams.forEach((value, key) => {
        params[key] = value;
    })
    return params
}

const setQueryParams = (queryParams) => {
    const params = {...queryParams}
    for (const object in params) {
        if (!params[object]) delete params[object]
    }
    return params
}

const getTextStatusColor = {
    'registered': 'text-primary-50',
    'active': 'text-green-60',
    'inactive': 'text-neutral-70',
    'suspend': 'text-orange-70',
    'blacklist': 'text-purple-60',
    'contract rejected': 'text-red-60',
    'draft': 'text-neutral-50',
    'submitted': 'text-pink-60',
    'completed': 'text-blue-60',
    'pending': 'text-orange-60',
    'valid': 'text-green-60',
    'invalid': 'text-red-50',
    'hadir': 'text-primary-50',
    'remote': 'text-green-60',
    'libur': 'text-pink-60',
    'cuti': 'text-orange-50',
}

const getBgStatusColor = {
    'registered': 'bg-primary-10 border-primary-30',
    'active': 'bg-green-10 border-green-30',
    'inactive': 'bg-neutral-20 border-neutral-50',
    'suspend': 'bg-orange-10 border-orange-30',
    'blacklist': 'bg-purple-10 border-purple-30',
    'contract rejected': 'bg-red-10 border-red-30',
    'draft': 'bg-neutral-10 border-neutral-40',
    'submitted': 'bg-pink-10 border-pink-30',
    'completed': 'bg-blue-10 border-blue-30',
    'pending': 'bg-orange-10 border-orange-30',
    'valid': 'bg-green-10 border-green-30',
    'invalid': 'bg-red-10 border-red-30',
}

const downloadFile = (url, blob, name, target) => {
    const source = url || window.URL.createObjectURL(new Blob([blob]))
    const link = document.createElement('a')
    link.href = source
    link.target = target
    link.download = name
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
}

const constructOptionsSelect = (rawOptions, labelKey, valueKey, thirdValue) => {
    return rawOptions.map((option) => {
        let object = {
            label: labelKey ? option[labelKey] : option,
            value: !!valueKey ? option[valueKey] : option,
        }
        if (!!thirdValue) {
            object[thirdValue] = option[thirdValue]
        }
        return object
    })
}

const findOptionSelected = (rawOptions, value, valueKey = 'value') => {
    return rawOptions?.find(i => i?.[valueKey] === value) || null
}

let splitString = (text) => {
    let splitText = text.split(".")
    return splitText[splitText.length - 1]
}

const convertArrayToObject = (array) => {
    let newObject = array.reduce((a, v) => ({...a, [splitString(v.parameter)]: v.message}), {})
    return newObject
}

const clearPhoneNumber = (phoneNumber) => {
    if (!phoneNumber) return null
    const checkPhone = phoneNumber.substring(0, 2)
    if (checkPhone === "08") return phoneNumber.substring(1)
    if (checkPhone === "62") return phoneNumber.substring(2)
}

const formatPhoneNumber = (phoneNumber) => {
    if (phoneNumber) return `62${phoneNumber}`
    return null
}

const getHeadUnitText = {
    'management': 'management',
    'department': 'management',
    'division': 'department',
    'sub-division': 'division'
}

const getValueFromArray = (array, key = 'id') => {
    let ids = []
    if (!!array) {
        let copiedArray = [...array]
        copiedArray?.map(data => ids.push(data[key]))
    }
    return ids
}

const formDisabled = (form, key, activity) => {
    if (!!form) {
        return true
    } else {
        switch (activity) {
            case 'addendum':
                return ['number', 'nip', 'company', 'type', 'start_date'].includes(key);
            case 'renew':
                return ['nip'].includes(key);
            default:
                return false
        }
    }
}

const defineContractStatus = (status, addendumNumber, is_renew_contract) => {
    if (!!addendumNumber) {
        return 'addendum'
    } else if (!!is_renew_contract) {
        return 'renew'
    } else {
        return 'active'
    }
}

const trimString = (string) => {
    return string.substring(0, 125);
}

const convertSpaceToDash = (text) => {
    return text.replace(/\s+/g, '-').toLowerCase()
}

const deepClone = (object) => {
    return JSON.parse(JSON.stringify(object))
}

const getDaysId = {
    'Monday': 'Senin',
    'Tuesday': 'Selasa',
    'Wednesday': 'Rabu',
    'Thursday': 'Kamis',
    'Friday': 'Jumat',
    'Saturday': 'Sabtu',
    'Sunday': 'Minggu'
}

const getMonthsId = {
    'January': 'Januari',
    'February': 'Februari',
    'March': 'Maret',
    'April': 'April',
    'May': 'Mei',
    'June': 'Juni',
    'July': 'Juli',
    'August': 'Agustus',
    'September': 'September',
    'October': 'Oktober',
    'November': 'November',
    'December': 'Desember'
}

const convertDateTextToId = (date) => {
    let converted = ''

    Object.keys(getDaysId).map(key => {
        if (date.includes(key)) converted = date.replace(key, getDaysId[key])
    })

    Object.keys(getMonthsId).map(key => {
        if (date.includes(key)) converted = (converted || date).replace(key, getMonthsId[key])
    })

    return converted || date
}

const getStatusIcon = (status) => {
    switch (status) {
        case 'approved':
            return icCheckGreen
        case 'rejected':
            return icXCircleContained
        case 'pending':
            return icStopwatchRed
        default:
            return null
    }
}

const uploadFileToS3 = async (item, file) => {
    try {
        await axios.post(`${item.url}`, {
            ...item.fields,
            file: file?.url || file
        }, {
            headers: {'Content-Type': 'multipart/form-data'}
        })
    } catch (err) {
        return Alert({
            icon: 'error',
            title: 'Error',
            message: 'Upload File Failed',
        })
    }
}

const getPathUrl = (location, separator = '/', sliceIndex = 0) => {
    const path = location.pathname.split(separator)
    return path.slice(sliceIndex)
}

export {
    regexEmail,
    innerHTML,
    formatDateMoment,
    getFileUnit,
    setCurrency,
    separateNumber,
    getTextStatusColor,
    getBgStatusColor,
    capitalize,
    getAllQueryParams,
    setQueryParams,
    downloadFile,
    constructOptionsSelect,
    getHeadUnitText,
    findOptionSelected,
    convertArrayToObject,
    clearPhoneNumber,
    formatPhoneNumber,
    getValueFromArray,
    formDisabled,
    defineContractStatus,
    trimString,
    convertSpaceToDash,
    deepClone,
    getDaysId,
    getMonthsId,
    convertDateTextToId,
    getStatusIcon,
    uploadFileToS3,
    getPathUrl
}
