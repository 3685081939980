import clsx from "clsx"

export const InlineTabs = ({className, classNameItem, tabList = [], onClickTab, activeTab}) => {
    return(
        <div className={clsx("flex px-6 justify-start items-start gap-[9px]", className)}>
            {
                tabList?.map((tab, index) => (
                    <div
                        key={index}
                        onClick={() => onClickTab(tab)}
                        className={clsx(`flex-col justify-start items-start gap-3 inline-flex cursor-pointer`, activeTab === tab?.id && 'border-b-2 border-b-primary-50', classNameItem)}>
                        <div className="w-full rounded-lg flex-col justify-start items-center gap-3 flex">
                            <div className="p-3 justify-start items-center gap-2.5 inline-flex">
                                <div
                                    className={`text-center text-[14px] ${activeTab === tab?.id ? 'text-primary-50 font-bold' : 'text-neutral-60 font-semibold'} leading-tight tracking-tight capitalize`}>{tab?.name}
                                </div>
                            </div>
                        </div>
                    </div>
                ))
            }
        </div>
    )
}
