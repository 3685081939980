import Modal from "../../commons/modal";
import Button from "../../commons/button";
import icCloseSquare from "../../../assets/images/ic-close-square.svg";
import YearPicker from "../../commons/yearpicker";

export const DayOffListFilter = (
    {
        show,
        activeFilter,
        selectedFilter,
        onCloseModal,
        onSelectFilter,
        onSubmitFilter,
        onClearFilter
    }) => {

    return (
        <Modal
            dialogClassName='w-full max-w-[380px]'
            contentClassName='rounded-xl'
            show={show}
        >
            <>
                <div className={"flex px-6 py-3 items-center justify-between border-b"}>
                    <p className={"font-semibold text-lg"}>Filter by Year</p>
                    <Button type="outline" shape="circle" size="small" icon={icCloseSquare}
                            onClick={onCloseModal}/>
                </div>
                <div className={"flex justify-center p-6"}>
                    <YearPicker className={"flex-1"} name="year" maxYear={2} value={selectedFilter?.value || activeFilter?.value}
                                onChange={(value) => onSelectFilter(value)}/>
                </div>
                <div className={"flex flex-row-reverse gap-3 px-6 py-3 border-t"}>
                    <Button className="px-8 py-4" title="Simpan" onClick={onSubmitFilter} />
                    <Button className="px-8 py-4" type="outline" title="Clear" onClick={onClearFilter}/>
                </div>
            </>
        </Modal>
    )
}
