import clsx from "clsx";
import './search.scss';
import icSearch from "./ic-search.svg";
import icXGrey from "./ic-x-grey.svg";
import {useEffect, useRef, useState} from "react";

const Search = ({className, classNameInput, placeholder = "Search...", value = '', onSearch}) => {
    const inputRef = useRef()
    const [isFocus, setIsFocus] = useState(false)
    const [sValue, setValue] = useState('')

    useEffect(() => {
        setValue(value)
    }, [value])

    const handleOnChange = (e) => {
        setValue(e.target?.value)
    }

    const handleOnKeyUp = (e) => {
        if (onSearch && e.key === 'Enter') onSearch(sValue)
    }

    const handleOnClick = () => {
        setValue('')
        inputRef.current.focus()
        if (onSearch) onSearch('')
    }

    return (
        <div className={clsx("avo__search", isFocus && 'focus', sValue?.length > 0 && 'show-close-icon', className)}>
            <img src={icSearch} alt="ic-search" className="icon-search"/>
            <input
                className={clsx("input-search", classNameInput)}
                placeholder={placeholder}
                type="text"
                value={sValue}
                onChange={handleOnChange}
                onKeyUp={handleOnKeyUp}
                onFocus={() => setIsFocus(true)}
                onBlur={() => setIsFocus(false)}
                ref={inputRef}
            />
            {
                sValue?.length > 0 &&
                <button onClick={handleOnClick} className="icon-close">
                    <img src={icXGrey} alt="ic-x"/>
                </button>
            }
        </div>
    )
}

export default Search
