import { EducationInformation } from "components/employee/employee-add/EducationInformation"
import { ACTION_TYPE } from "constants/constant"
import { DEFAULT_FORM_EDUCATION, GRADE_NO_MAJOR } from "constants/defaultFormEmployee"
import { useValidation } from "hooks/useValidation"
import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setEducationInformation } from "reducers/addEmployeeReducer"

export const EducationInformationContainer = ({ optionsGrades }) => {
    const dispatch = useDispatch()
    const dataEmployee = useSelector((state) => state.addEmployeeReducer)
    const [showModal, setShowModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [dataEducation, setDataEducation] = useState(DEFAULT_FORM_EDUCATION)
    const [selectedIndex, setSelectedIndex] = useState()
    const [actionType, setActionType] = useState(ACTION_TYPE.CREATE)
    const { isFormValid } = useValidation()
    const hasMajor = dataEducation?.grade?.value ? !GRADE_NO_MAJOR.includes(dataEducation?.grade?.value) : false

    const onChangeForm = (e) => {
        const { name, value } = e
        const noMajor = name === 'grade' && GRADE_NO_MAJOR.includes(value.value)
        setDataEducation({ ...dataEducation, [name]: value, ...(noMajor && { major: null }) })
    }

    const onCloseModal = () => {
        setShowModal(false)
        setDataEducation(DEFAULT_FORM_EDUCATION)
        setActionType(ACTION_TYPE.CREATE)
    }

    const onSubmit = () => {
        const newData = [...dataEmployee.educations]
        const newDataEducation = {
            ...dataEducation,
            grade: dataEducation?.grade?.value
        }
        switch (actionType) {
            case ACTION_TYPE.CREATE:
                newData.push(newDataEducation)
                break;
            case ACTION_TYPE.EDIT:
                newData[selectedIndex] = newDataEducation
                break;
            default:
        }
        dispatch(setEducationInformation(newData))
        onCloseModal()
    }

    const handleEdit = (item) => {
        setDataEducation({
            ...item,
            grade: {
                label: item.grade,
                value: item.grade,
            }
        })
        setActionType(ACTION_TYPE.EDIT)
        setShowModal(true)
    }

    const handleRemove = (index) => {
        const newData = [...dataEmployee.educations]
        newData.splice(index, 1)
        dispatch(setEducationInformation(newData))
        setShowDeleteModal(false)
    }

    const onActionRow = ({ index, item, actionType }) => {
        setSelectedIndex(index)
        switch (actionType) {
            case ACTION_TYPE.EDIT:
                return handleEdit(item)
            case ACTION_TYPE.DELETE:
                return setShowDeleteModal(true)
            default:
        }
    }

    const checkValidationForm = () => {
        const checkForm = { ...dataEducation }
        if (!hasMajor) delete checkForm.major
        return isFormValid(checkForm)
    }

    const props = {
        optionsGrades,
        dataEducation,
        hasMajor,
        isFormValid: checkValidationForm(),
        isEdit: actionType === ACTION_TYPE.EDIT,
        form: dataEmployee.educations,
        showModal,
        onShowModal: () => setShowModal(true),
        onCloseModal,
        onChangeForm,
        onSubmit,
        onActionRow,
        showDeleteModal,
        onCloseDeleteModal: () => setShowDeleteModal(false),
        onDelete: () => handleRemove(selectedIndex)
    }

    return (
        <EducationInformation {...props} />
    )
}