import {createSlice} from "@reduxjs/toolkit";

export const formHRISAnnouncementSlice = createSlice({
    name: 'form-hris-announcement',
    initialState: {
        toast: {
            message: '',
            isShowToast: false
        }
    },
    reducers: {
        setAnnouncementToast: (state, action) => {
            state.toast = {...state.toast, ...action.payload}
        }
    }
})

export const {
    setAnnouncementToast
} = formHRISAnnouncementSlice.actions
const formHRISAnnouncementReducer = formHRISAnnouncementSlice.reducer

export default formHRISAnnouncementReducer
