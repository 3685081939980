import {Link} from "react-router-dom";
import Badge from "components/commons/badge";
import icUserDefault from "assets/images/ic-user-profile.svg"

const tableName = {
    'prospective-employee': 'Prospective Employee',
    'active-employee': 'Active Employee',
    'former-employee': 'Former Employee'
}

export const TABLE_EMPLOYEE_LIST = (name) => (
    {
        name: tableName[name],
        column: [
            {
                name: "Name",
                key: "full_name",
                className: "font-semibold",
                sortable: true,
                render: ({item}) => {
                    return (
                        <div className={'flex flex-row gap-3 items-center'}>
                            <img className={'w-7 h-7 rounded-full border border-neutral-50'}
                                 src={item?.profile_picture ? item?.profile_picture : icUserDefault}
                                 alt={'user-img'}/>
                            <div>
                                {
                                    item?.status === 'draft' ?
                                        <div className={'cursor-pointer'}
                                             onClick={(e) => {
                                                 e.stopPropagation();
                                                 window.open(`/employee/prospective-employee/add-employee?draftId=${item.id}`, '_blank', 'noopener,noreferrer')
                                             }}>
                                            {item?.full_name}
                                        </div>
                                        :
                                        <Link to={`/employee/${name}/user-detail?userId=${item?.id}`}>
                                            <div>{item?.full_name}</div>
                                        </Link>
                                }
                            </div>
                        </div>
                    )
                }
            },
            {
                name: "NIP",
                key: "nip",
                sortable: true,
            },
            {
                name: "Position",
                key: "job_title_by_contract",
                className: "font-semibold",
                sortable: true,
            },
            {
                name: "Email",
                key: "email",
                sortable: true,
                render: ({item}) => item?.office_email ? item?.office_email : item?.personal_email
            },
            {
                name: "Join Date",
                sortable: true,
                key: "join_date",
            },
            {
                name: "Status",
                key: "status",
                render: ({value}) => <Badge title={value} type={value === 'contract-rejected' ? 'rejected' : value}/>
            }
        ]
    }
)

export const getStatus = {
    'prospective-employee': ["draft", "submitted", "registered"],
    'active-employee': ["active", "suspend"],
    'former-employee': ["inactive", "contract-rejected", "blacklist"]
}
