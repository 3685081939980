import Table from "../../commons/table";
import {TABLE_EMPLOYEE_LIST} from "configs/employee/configTableEmployeeList";
import Search from "../../commons/search";
import Button from "../../commons/button";
import icTrash from "../../../assets/images/ic-trash-01.svg";
import icFileUp from "../../../assets/images/ic-file-up-02.svg";
import icFileDown from "../../../assets/images/ic-file-down-02.svg";
import icAddSquare from "../../../assets/images/id-add-square.svg";
import DeleteModal from "../../commons/delete-modal";
import {ExportModal} from "../../commons/ExportModal";
import Toast from "../../commons/toast";
import Modal from "../../commons/modal";
import ViewList from "../../commons/view-list";
import {EmployeeFilterContainer} from "../../../containers/employee/employee-list/EmployeeFilterContainer";

export const EmployeeList = (props) => {
    const {
        modal,
        toast,
        pathName,
        onClickButtonDelete,
        onImportEmployee,
        onAddEmployee,
        onDeleteEmployee,
        onExportHandler,
        alert,
        selectedIds,
        setModalVisibility,
        employeeData,
        onSelectHandler,
        isLoading,
        onChangeQuery,
        query,
        queryFilter,
        onSubmitFilter,
        onClickSort,
        clearToast
    } = props

    return (
        <div className={'flex flex-col'}>
            <div className={'flex flex-row justify-between items-center px-6 py-4'}>
                <div className={'flex flex-row justify-between gap-3'}>
                    <Search onSearch={(search) => onChangeQuery({page: 1, search})} value={query?.search}/>
                    <div className={'flex flex-row justify-between gap-3'}>
                        <EmployeeFilterContainer
                            show={modal.filter}
                            onShow={() => setModalVisibility({filter: true})}
                            onClose={() => setModalVisibility({filter: false})}
                            queryFilter={queryFilter}
                            onSubmit={onSubmitFilter}
                            pathName={pathName}
                        />
                        <ViewList
                            show={modal?.sort}
                            onShow={() => setModalVisibility({sort: true})}
                            onClose={() => setModalVisibility({sort: false})}
                            value={query?.limit}
                            onSelectSize={(limit) => onChangeQuery({page: 1, limit})}
                        />
                        {pathName === 'prospective-employee' &&
                            <Button icon={icTrash} type="outline" onClick={onClickButtonDelete}/>}
                        <Button title="Export" icon={icFileUp} type="outline"
                                onClick={() => setModalVisibility({export: true})}/>
                    </div>
                </div>
                {
                    pathName === 'prospective-employee' &&
                    <div className={'flex flex-row justify-between gap-3'}>
                        <Button title="Import" icon={icFileDown} type="outline" onClick={onImportEmployee}/>
                        <Button title="Add Employee" icon={icAddSquare} onClick={onAddEmployee}/>
                    </div>
                }
            </div>
            <Table
                className="my-3"
                name={TABLE_EMPLOYEE_LIST(pathName).name}
                data={employeeData?.data?.result}
                columns={TABLE_EMPLOYEE_LIST(pathName).column}
                size={query?.limit}
                totalPage={employeeData?.data?.total_page}
                currentPage={query?.page}
                totalResult={employeeData?.data?.total_result}
                onChangePage={(page) => onChangeQuery({ page })}
                onSelectItem={(e) => onSelectHandler(e)}
                loading={isLoading}
                onClickSort={onClickSort}
            />
            <DeleteModal show={modal?.delete}
                         onHide={() => setModalVisibility({delete: false})}
                         onDelete={onDeleteEmployee}
                         text={`Apakah anda yakin ingin menghapus ${selectedIds?.length} data karyawan?`}/>
            <ExportModal title={'Apakah anda yakin ingin export data karyawan?'}
                         description={`Total data karyawan export: ${selectedIds?.length || employeeData?.data?.result?.length}`}
                         show={modal?.export}
                         onExportHandler={onExportHandler}
                         onCancelHandler={() => setModalVisibility({export: false})}/>
            <Toast
                show={toast?.isShowToast}
                message={toast?.message}
                onClose={() => clearToast()}
            />
            <Modal
                dialogClassName='w-full max-w-[328px]'
                contentClassName='rounded-xl'
                show={modal?.alert}
            >
                <div className={"py-8 px-6 flex flex-col justify-center items-center space-y-4"}>
                    <p className={"text-center font-semibold"}>{alert?.title}</p>
                    <p className={"text-center text-neutral-70"}>{alert?.description}</p>
                </div>
                <div className={"px-6 py-3 flex flex-row border-t space-x-3 justify-center"}>
                    <Button type={'outline'} title={'Ok'} onClick={() => setModalVisibility({alert: false})}/>
                </div>
            </Modal>
        </div>
    )
}
