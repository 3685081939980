import icMarker from "assets/images/ic-location.svg"
import { icon } from "leaflet"
import "leaflet/dist/leaflet.css"
import { MapContainer, Marker, TileLayer } from "react-leaflet"

export const Map = ({ coordinate }) => {
    const iconMarker = icon({
        iconUrl: icMarker,
        iconSize: [32, 32]
    })

    return (
        <MapContainer style={{ height: '100%' }} center={[coordinate.lat, coordinate.lng]} zoom={13} scrollWheelZoom={false} >
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Marker position={[coordinate.lat, coordinate.lng]} icon={iconMarker} />
        </MapContainer>
    )
}