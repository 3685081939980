import {WorkUnitForm} from "../../../components/organization-structure-management/work-unit-setting/WorkUnitForm";
import {useDispatch, useSelector} from "react-redux";
import {flushCreateJobUnitDetail, setModal, setToast} from "../../../reducers/organizationStructureReducer";
import {useState} from "react";
import {getHeadUnitText} from "../../../utils/utils";
import {useValidation} from "../../../hooks/useValidation";
import {useFetch} from "../../../services/useFetch";
import {API_JOB_UNIT} from "../../../services/apiJobUnit";
import {
    CreateManagementForm
} from "../../../components/organization-structure-management/work-unit-setting/CreateManagementForm";

export const WorkUnitFormContainer = ({fetchJobUnitList}) => {
    const dispatch = useDispatch()
    const formValueInitial = {
        head_unit_id: null,
        names: [],
        head_unit_selected: {},
        management: ''
    }
    const [text, setText] = useState('')
    const [formValue, setFormValue] = useState(formValueInitial)
    const {modal, selectedTab, options} = useSelector(state => state.organizationStructureReducer)
    const { activatedCompany } = useSelector(state => state.companiesReducer)
    const {fetching: createJobUnitApi} = useFetch(API_JOB_UNIT.CREATE_JOB_UNIT)
    const {isFormValid: isFormValidManagement} = useValidation({form: {management: formValue.management}})
    const {isFormValid: isFormValidJobUnit} = useValidation({
        form: {
            head_unit_id: formValue.head_unit_id,
            names: formValue.names
        }
    })

    const onChangeText = (e) => {
        setText(e)
    }

    const saveValue = (value) => {
        setFormValue({...formValue, ...value})
    }
    const addTextHandler = () => {
        saveValue({names: formValue.names.concat([text])})
        setText('')
    }

    const removeTextHandler = (index) => {
        const newSelectedText = formValue.names.filter((i, idx) => idx !== index)
        saveValue({names: newSelectedText})
    }

    const onHideForm = () => {
        setFormValue(formValueInitial)
        dispatch(setModal({form: false}))
    }

    const onChangeSelectValue = (selectedValue) => {
        saveValue({head_unit_id: selectedValue.value.value, head_unit_selected: selectedValue.value})
    }

    const onSubmitForm = () => {
        let requestBody = {
            head_unit_id: formValue.head_unit_id,
            names: selectedTab.key === 'management' ? [formValue.management] : formValue.names
        }

        createJobUnitApi(requestBody, selectedTab.key)
            .then(() => {
                dispatch(flushCreateJobUnitDetail())
                dispatch(setToast({isShowToast: true, message: 'Work Unit Created !!'}))
                fetchJobUnitList()
                onHideForm()
            })
    }

    const props = {
        selectedTab: selectedTab.name,
        headUnitLabel: getHeadUnitText[selectedTab.key],
        onHideForm,
        onSubmitForm,
        isShow: modal.form,
        onChangeSelectValue,
        options,
        formValue,
        text,
        addTextHandler,
        removeTextHandler,
        onChangeText,
        isFormValid: selectedTab.key === 'management' ? isFormValidManagement() : isFormValidJobUnit(),
        saveValue,
        activatedCompany
    }

    if (selectedTab.key === 'management') {
        return <CreateManagementForm {...props}/>
    } else {
        return (
            <WorkUnitForm {...props}/>
        )
    }
}
