import FormModal from "components/commons/form-modal"
import Input from "components/commons/input"
import Select from "components/commons/select"
import { getFileUnit } from "utils/utils"

export const CreatePersonalDocument = ({
    show,
    onHide,
    optionsPersonalDocumentType,
    dataPersonalDocument,
    onChangeForm,
    onSubmit,
    isFormValid,
    isEdit,
    isLoadingSubmit,
    onUploadFile,
    file,
}) => {
    return (
        <FormModal
            show={show}
            onHide={onHide}
            title={`${isEdit ? 'Edit' : 'Tambah'} Dokumen Pribadi`}
            onSubmitForm={onSubmit}
            isLoading={isLoadingSubmit}
            isDisabled={!isFormValid}
        >
            <div className={"px-8 py-6 mb-2 space-y-6"}>
                <div className="flex items-center gap-3">
                    <div className="font-medium w-1/3 text-neutral-70">{'Tipe Dokumen'}</div>
                    <Select
                        name="type"
                        className='flex-1'
                        placeholder="Pilih tipe dokumen"
                        options={optionsPersonalDocumentType}
                        value={dataPersonalDocument?.type}
                        onChange={onChangeForm}
                    />
                </div>
                <div className="flex items-center gap-3">
                    <div className="font-medium w-1/3 text-neutral-70">{'Deskripsi'}</div>
                    <Input
                        className='flex-1'
                        name="description"
                        placeholder={"Isi deskripsi"}
                        maxLength={100}
                        value={dataPersonalDocument?.description}
                        onChange={onChangeForm}
                    />
                </div>
                <div className="flex gap-3">
                    <div className="font-medium w-1/3 text-neutral-70 mt-2">{'File Upload'}</div>
                    <div className="flex-1 flex gap-2">
                        {file ? <span className="font-medium text-md">{file?.name} <span className="font-regular ml-2">{`(${getFileUnit(file?.size)})`}</span></span>
                            : dataPersonalDocument?.url && <span className="font-medium text-md break-all">{dataPersonalDocument?.url}</span>}
                        <input className={'hidden'} accept="application/pdf, image/*" id='personal-document' type={'file'} onChange={onUploadFile} />
                        <label className="avo__button outline cursor-pointer" type="button" htmlFor={'personal-document'}>
                            <div className="btn-content"> {`Upload ${file || dataPersonalDocument?.url ? 'Ulang' : 'File'}`} </div>
                        </label>
                    </div>
                </div>
            </div>
        </FormModal>
    )
}
