import {EmployeeImport} from "../../../components/employee/employee-import/EmployeeImport";
import {useState} from "react";
import {STEPS_IMPORT_EMPLOYEE_DATA} from "../../../constants/constant";
import {UploadFileContainer} from "./UploadFileContainer";
import {ValidationEmployeeDataContainer} from "./ValidationEmployeeDataContainer";
import {ViewDataContainer} from "./ViewDataContainer";
import {ImportDataContainer} from "./ImportDataContainer";

export const EmployeeImportContainer = () => {
    const [activeStep, setActiveStep] = useState(1)
    const [payload, setPayload] = useState({file: {}, is_update: false})
    const [validatedData, setValidatedData] = useState({})

    const setPayloadData = (payload) => {
        setPayload(payload)
    }

    const setViewData = (data) => {
        setValidatedData(data)
    }

    const onChangeStep = (step) => {
        setActiveStep(step)
    }

    const stepContent = [
        <UploadFileContainer
            payload={payload}
            onChangeStep={onChangeStep}
            setPayloadData={setPayloadData}
        />,
        <ValidationEmployeeDataContainer
            payload={payload}
            onChangeStep={onChangeStep}
            setViewData={setViewData}
        />,
        <ViewDataContainer
            validatedData={validatedData}
            onChangeStep={onChangeStep}
        />,
        <ImportDataContainer
            payload={payload}
            onChangeStep={onChangeStep}
        />
    ]

    return (
        <EmployeeImport steps={STEPS_IMPORT_EMPLOYEE_DATA} activeStep={activeStep} stepContent={stepContent} />
    )
}
