import {setPreviewEmployeeStructure} from "../../../reducers/organizationStructureReducer";
import {useDispatch} from "react-redux";

export const ParentNode = ({nodeData}) => {
    const dispatch = useDispatch()
    const onShowChildNode = () => {
        const newNodeData = JSON.stringify(nodeData)
        dispatch(setPreviewEmployeeStructure({parentNode: JSON.parse(newNodeData)}))
    };

    const onShowEmployee = () => {
        const newNodeData = JSON.stringify(nodeData)
        dispatch(setPreviewEmployeeStructure({parentNode: JSON.parse(newNodeData), visibility: true}))
    }

    if (!!nodeData.children) {
        return (
            <div className={"border-4 border-neutral-20 rounded-xl text-sm w-56"}>
                <div className={"flex flex-col rounded-lg border border-primary-50 shadow-2xl shadow-neutral-20"}>
                    <div className={"p-2 cursor-pointer h-12"} onClick={onShowEmployee}>
                        <p className={"font-semibold"}>{nodeData.name}</p>
                    </div>
                    <div className={"flex flex-row justify-center p-2 bg-neutral-20 rounded-b-lg"}>
                        <p className={"text-primary-50 font-medium "}>{nodeData.total_child} Job Title</p>
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <div className={"border-t-4 border-r border-l border-b-4 border-neutral-20 rounded-md text-sm w-40"}>
                <div className={"flex flex-col rounded-lg border border-primary-50 shadow-2xl shadow-neutral-20"}>
                    <div className={"p-2 cursor-pointer text-ellipsis overflow-hidden h-12 "} onClick={onShowChildNode}>
                        <p className={"font-semibold"}>{nodeData.name}</p>
                    </div>
                    <div className={"flex flex-row justify-center p-2 bg-neutral-20 rounded-b-lg"}>
                        <p className={"text-primary-50 font-medium "}>{nodeData.total_child} Job Title</p>
                    </div>
                </div>
            </div>
        )
    }

}
