import { createSlice } from "@reduxjs/toolkit";
import {contractDetailObj} from "../constants/constant-management-contract";

const initialState = {
    modal : {
        export: false,
        sort: false,
        filter: false,
        preview: false,
        delete: false
    },
    body : {
        search: '',
        limit: 20,
        page: 1
    },
    totalData: 0,
    selectedRows: [],
    bodyRequestListContract: {
        search: '',
        page: 1,
        limit: 20,
        status: 'draft',
        type: [],
        department_ids: [],
        division_ids: [],
        subdivision_ids: [],
        end_date: null,
        order_by: 'created_at',
        order_type: 'asc'
    },
    documentUpload: {
        contract_document_url: null,
        hki_document_url: null,
        sk_document_url: null,
        notes: ''
    },
    options : {
        company: [],
        job_title: [],
        grade: []
    },
    contractDetail: {...contractDetailObj},
    masterContractDetail: {...contractDetailObj},
    contractList: {},
    toast: {
        message: '',
        isShowToast: false
    }
}

export const contractSlice = createSlice({
    name: 'contract',
    initialState: initialState,
    reducers: {
        setModal: (state, action) => ({
            ...state,
            modal: {...state.modal, ...action.payload}
        }),
        setBodyRequest: (state, action) => ({
            ...state,
            body: {...state.body, ...action.payload}
        }),
        setBodyRequestListContract: (state, action) => ({
            ...state,
            bodyRequestListContract: {...state.bodyRequestListContract, ...action.payload}
        }),
        setSelectedRows: (state, action) => ({
            ...state, selectedRows: action.payload
        }),
        setTotalData: (state, action) => ({
            ...state, totalData: action.payload
        }),
        setDocumentUpload: (state, action) => ({
            ...state,
            documentUpload: {...state.documentUpload, ...action.payload}
        }),
        setOptions: (state, action) => ({
            ...state,
            options: {...state.options, ...action.payload}
        }),
        setContractDetail: (state, action) => ({
            ...state,
            contractDetail: {...state.contractDetail, ...action.payload}
        }),
        setMasterContractDetail: (state, action) => ({
            ...state,
            masterContractDetail: action.payload
        }),
        setContractList: (state, action) => ({
            ...state,
            contractList: action.payload
        }),
        setToast: (state, action) => {
            state.toast = {...state.toast, ...action.payload}
        }
    },
})

export const {
    setModal,
    setBodyRequest,
    setBodyRequestListContract,
    setSelectedRows,
    setTotalData,
    setDocumentUpload,
    setOptions,
    setContractDetail,
    setContractList,
    setMasterContractDetail,
    setToast
} = contractSlice.actions

const contractReducer = contractSlice.reducer
export default contractReducer
