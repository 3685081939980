import {setCurrency} from "../../utils/utils";
import Button from "../../components/commons/button";
import icTrashRed from "../../assets/images/ic-trash-01-red.svg";
import {ACTION_TYPE} from "../../constants/constant";
import icEdit from "../../assets/images/ic-edit-04.svg";
import Badge from "../../components/commons/badge";

export const TABLE_GRADE_SETTING = {
    name: 'Grade',
    column: [
        {
            name: "Grade",
            key: "name",
        },
        {
            name: "Salary",
            key: "salary",
            classNameHeader: "!text-center",
            subColumn: [
                {
                    name: "Minimal Basic Salary",
                    key: "min_basic_salary",
                    isShowTooltip: true,
                    classNameHeader: "!text-center max-w-0",
                    classNameTooltip: "font-medium truncate",
                    className: '!text-center',
                    render: ({value}) => <div>{setCurrency(value)}</div>
                },
                {
                    name: "Maximal Basic Salary",
                    key: "max_basic_salary",
                    isShowTooltip: true,
                    classNameHeader: "!text-center max-w-0",
                    classNameTooltip: "font-medium truncate",
                    className: '!text-center',
                    render: ({value}) => <div>{setCurrency(value)}</div>
                },
            ]
        },
        {
            name: "Action",
            key: "action",
            render: ({item, onClickItem}) => (
                <div className="flex gap-3 mx-auto">
                    <Button icon={icTrashRed} type="outline" onClick={() => onClickItem({item, actionType: ACTION_TYPE.DELETE})}/>
                    <Button icon={icEdit} type="outline" onClick={() => onClickItem({item, actionType: ACTION_TYPE.EDIT})}/>
                </div>
            )
        }
    ]
}

export const TABLE_SETTING_TYPE_ALLOWANCE = {
    name: 'Type Allowance',
    column: [
        {
            name: 'Nama',
            key: "name",
            className: 'font-semibold capitalize'
        },
        {
            name: "Status",
            key: "is_active",
            className: 'capitalize',
            render: ({value}) => (
                <Badge title={value ? 'active' : 'inactive'} type={value ? 'active' : 'inactive'}/>
            )
        },
        {
            name: "Action",
            key: "action",
            render: ({item, onClickItem}) => (
                <div className="flex gap-3 mx-auto">
                    <Button icon={icEdit} type="outline" onClick={() => onClickItem({actionType: ACTION_TYPE.EDIT, item})}/>
                </div>
            )
        }
    ]
}

export const INITIAL_DATA_FILTER_TYPE_ALLOWANCE = {
    page: 1,
    limit: 20
}
