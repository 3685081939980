import Button from "../../commons/button";
import icTrash from "../../../assets/images/ic-trash-01-red.svg";
import Input from "../../commons/input";
import ErrorMessage from "../../commons/error-message";

export const PercentageForm = (props) => {
    const {deleteForm, form, addForm, onChangeForm, onSubmitForm} = props
    return (
        <>
            <div className={"px-9 pt-8 overflow-scroll pb-32"}>
                <div className={"flex flex-col gap-6"}>
                    {
                        form.map((item, index) => (
                            <div className={"flex flex-col gap-6"} key={index}>
                                <div className={"flex flex-row items-center justify-between"}>
                                    <p className={"font-medium text-md"}>Percentage Ketercapaian {`${index + 1}`}</p>
                                    {form.length > 1 && <Button title={'Hapus'} type={'danger'} icon={icTrash}
                                                                onClick={() => deleteForm(index)}/>}
                                </div>
                                <div className={"grid grid-cols-2 gap-14"}>
                                    <div className={"flex flex-row items-center"}>
                                        <p className={"w-1/4 required"}>Nama Title</p>
                                        <div className={'flex-1'}>
                                            <Input name="title" placeholder={`Inputkan nama title`}
                                                   value={item?.title} onChange={(e) => onChangeForm(index, e)}/>
                                            <ErrorMessage className="mt-2" message={item?.error?.title}/>
                                        </div>
                                    </div>
                                </div>
                                <div className={"grid grid-cols-2 gap-14"}>
                                    <div className={"flex flex-row items-center"}>
                                        <p className={"w-1/4 required"}>Percentage Minimum</p>
                                        <div className={'flex-1'}>
                                            <Input name="min_percentage" type="float"
                                                   placeholder={`Inputkan percentage minimum`}
                                                   value={item?.min_percentage}
                                                   onChange={(e) => onChangeForm(index, e)}/>
                                            <ErrorMessage className="mt-2" message={item?.error?.min_percentage}/>
                                        </div>
                                    </div>
                                    <div className={"flex flex-row items-center"}>
                                        <p className={"w-1/4 required"}>Percentage Maximum</p>
                                        <div className={'flex-1'}>
                                            <Input className={'flex-1'} name="max_percentage"
                                                   placeholder={`Inputkan percentage maximum`} type="float"
                                                   value={item?.max_percentage}
                                                   onChange={(e) => onChangeForm(index, e)}/>
                                            <ErrorMessage className="mt-2" message={item?.error?.max_percentage}/>
                                        </div>
                                    </div>
                                </div>
                                <ErrorMessage className="mt-2" message={item?.errorPercentage}/>
                            </div>
                        ))
                    }
                </div>
                <Button title={'Tambah Percentage Ketercapaian'} type={'outline'} className={"mt-6"} onClick={addForm}/>
            </div>
            <div
                className={"flex items-center justify-end fixed bottom-0 w-[calc(100%-200px)] bg-white border-t px-14 py-6 z-10"}>
                <Button className="w-32" title="Submit" onClick={onSubmitForm}/>
            </div>
        </>
    )
}
