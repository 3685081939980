import icFileLock from '../../../../assets/images/ic-file-lock-02.svg'
import icFileCheck from '../../../../assets/images/ic-file-check-02.svg'
import icFileEdit from '../../../../assets/images/ic-file-edit-02.svg'
import icUpload from '../../../../assets/images/ic-upload-primary.svg'
import icDown from '../../../../assets/images/ic-chev-down.svg'
import icEditModal from '../../../../assets/images/ic-receipt-edit.svg'
import {useState} from "react";
import Button from "../../../commons/button";
import {UploadDocumentModal} from "./UploadDocumentModal";
import {STATUS_TYPE} from "../../../../constants/constant";
import {StandardModal} from "../../../commons/StandardModal";
import {ContractList} from "./ContractList";
import {ContractHistory} from "./ContractHistory";
import {EmployeeProfileDetail} from "./EmployeeProfileDetail";
import {ContractDetail} from "./ContractDetail";
import {
    AddNotesModalContainer
} from "../../../../containers/management-contract/contract-employee/add-notes-modal/AddNotesModalContainer";
import {innerHTML} from "../../../../utils/utils";
import FormModal from "../../../commons/form-modal";
import clsx from "clsx";
import Spinner from "../../../commons/spinner";
import {ModalDocument} from "../../../commons/modal-document/ModalDocument";

export const ContractEmployeeDetail = (props) => {
    const {
        contractDetail,
        detailContractInformation,
        updateModalVisibility,
        isDisabledContract,
        isDisabledContractChanged,
        editHandler,
        modal,
        modalText,
        onUploadFile,
        documentUpload,
        onSubmitFormUploadDocument,
        actionButtonHandler,
        addNotesModal,
        onSubmitNotes,
        formValid,
        jobDescriptionHandler,
        contractIdParams,
        historyIdParams,
        previewDocument,
        modalPreviewDocumentVisibility,
        loading,
        clearDocumentUpload
    } = props

    const buttonActionByStatus = () => {
        switch (contractDetail.status) {
            case STATUS_TYPE.DRAFT:
                return (
                    <div className={"flex flex-row items-center gap-3"}>
                        <Button icon={icFileEdit} title={'Edit Contract'} type={'outline'}
                                onClick={() => editHandler('edit')}/>
                        <Button icon={icUpload} title={'Upload Document'} type={'outline'}
                                onClick={() => updateModalVisibility('upload', true)}/>
                    </div>
                )
            case STATUS_TYPE.ACTIVE:
                return (
                    <div className={"flex flex-row items-center gap-3"}>
                        <div className={"flex items-center"}>
                            <Button icon={icFileEdit} title={'Edit Contract'} type={'outline'}
                                    className={"rounded-r-none"} onClick={() => editHandler('edit')}/>
                            <EditButtonOptions onClick={(e) => editHandler(e)}
                                               disabled={detailContractInformation.contracts.length > 1}/>
                        </div>
                        <Button icon={icFileCheck} title={'Completed Contract'} type={'outline'}
                                className={"border-none"} onClick={() => editHandler('complete')}/>
                    </div>
                )
            case STATUS_TYPE.PENDING:
                return (
                    <Button icon={icFileEdit} title={'Edit Contract'} type={'outline'}
                            onClick={() => editHandler('edit')}/>
                )
        }
    }

    return (
        <div className={"border-t-8 border-neutral-10 flex flex-row max-h-[calc(100vh_-_64px)] overflow-hidden"}>
            <div>
                {
                    detailContractInformation.contracts.length > 0 &&
                    <ContractList contractIdParams={contractIdParams}
                                  contractList={detailContractInformation.contracts}/>}
                <ContractHistory historyContractList={detailContractInformation.history}
                                 contractIdParams={contractIdParams}
                                 historyIdParams={historyIdParams}
                />
            </div>
            <div className={clsx('flex-1 overflow-scroll')}>
                {
                    loading ?
                        <div className={"w-full h-full flex justify-center p-10"}>
                            <Spinner className={"w-5 h-5 border-primary-50"}/>
                        </div>
                        :
                        <div className={"p-6 space-y-8"}>
                            <div className={"flex flex-row justify-between"}>
                                <div className={"flex flex-row items-center gap-3"}>
                                    <img src={icFileLock} alt={'icFileLock'}/>
                                    <p className={"font-bold text-lg text-neutral-80"}>Contract Employee</p>
                                </div>
                                {!historyIdParams && buttonActionByStatus()}
                            </div>
                            <EmployeeProfileDetail employeeDetail={detailContractInformation.profile}/>
                            <ContractDetail contractDetail={contractDetail}
                                            jobDescriptionHandler={jobDescriptionHandler}
                                            modalPreviewDocumentVisibility={modalPreviewDocumentVisibility}
                            />
                        </div>
                }
            </div>
            {modal.upload &&
                <UploadDocumentModal show={modal.upload}
                                     onHide={() => {
                                         clearDocumentUpload()
                                         updateModalVisibility('upload', false)
                                     }}
                                     onSubmitForm={onSubmitFormUploadDocument}
                                     onUploadFile={onUploadFile}
                                     contractDetail={contractDetail}
                                     employeeDetail={detailContractInformation.profile}
                                     formValid={formValid}
                                     isDisabledContract={isDisabledContract}
                                     isDisabledContractChanged={isDisabledContractChanged}
                                     documentUpload={documentUpload}
                />}
            <StandardModal
                icon={icEditModal}
                text={
                    <div>{`Apakah anda yakin ingin melakukan ${modalText} contract`}
                        <p className={"text-orange-50 font-semibold"}>{detailContractInformation.profile.name}</p>
                    </div>
                }
                show={modal.edit}
                onHide={() => updateModalVisibility('edit', false)}
                onSubmit={actionButtonHandler}
            />
            <AddNotesModalContainer
                show={addNotesModal}
                onHide={() => updateModalVisibility('notes', false)}
                onSubmitModal={(notes) => onSubmitNotes(notes)}
                employeeDetail={detailContractInformation.profile}
                contractDetail={contractDetail}
            />
            <FormModal
                title={'Uraian Pekerjaan'}
                contentClassName='rounded-xl overflow-auto pb-2.5'
                show={modal.jobDesc}
                onHide={() => updateModalVisibility('jobDesc', false)}
            >
                <div className={"px-5 pt-5"}>
                    <div className="inner-html" dangerouslySetInnerHTML={innerHTML(contractDetail['job_description'])}/>
                </div>
            </FormModal>
            <ModalDocument onHide={() => updateModalVisibility('preview', false)} show={modal.preview}
                           src={previewDocument}/>
        </div>
    )
}

const EditButtonOptions = ({onClick, disabled}) => {
    const [showOptions, setShowOptions] = useState(false)
    return (
        <div className={"relative"}>
            <Button icon={icDown} type={'outline'} className={"rounded-l-none"}
                    onClick={() => setShowOptions(!showOptions)}/>
            <div className={showOptions ? 'block' : 'hidden'}>
                <div
                    className={"col absolute right-0 top-12 z-10 w-[200px] bg-white shadow-md rounded-md overflow-scroll"}>
                    <button disabled={disabled}
                            className={"px-4 w-full py-3 cursor-pointer hover:bg-neutral-10 disabled:text-neutral-60 disabled:cursor-not-allowed"}
                            onClick={() => onClick('addendum')}>Addendum Contract
                    </button>
                    <button disabled={disabled}
                            className={"px-4 py-3 w-full cursor-pointer hover:bg-neutral-10 disabled:text-neutral-60 disabled:cursor-not-allowed"}
                            onClick={() => onClick('interim')}>Interim Contract
                    </button>
                    <button disabled={disabled}
                            className={"px-4 py-3 w-full cursor-pointer hover:bg-neutral-10 disabled:text-neutral-60 disabled:cursor-not-allowed"}
                            onClick={() => onClick('renew')}>Perubahan Contract
                    </button>
                </div>
            </div>
        </div>
    )
}
