import { BankInfoTab } from "components/employee/employee-detail/employee-detail-tab/BankInfoTab"
import { useValidation } from "hooks/useValidation"
import { useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setToast, updateBankDetail } from "reducers/employeeDetailReducer"
import { API_EMPLOYEE } from "services/apiEmployee"
import { useFetch } from "services/useFetch"

export const BankInfoTabContainer = () => {
    const dispatch = useDispatch()
    const { id, bank_detail } = useSelector(state => state.employeeDetailReducer)
    const [showModal, setShowModal] = useState(false)
    const [dataBank, setDataBank] = useState()
    const { data: dataListBank } = useFetch(API_EMPLOYEE.LIST_BANK, {}, { isFetching: true })
    const { fetching: validateBankAccount } = useFetch(API_EMPLOYEE.VALIDATE_BANK_ACCOUNT)
    const { fetching: updateBankAccount, isLoading: isLoadingSubmit } = useFetch({ ...API_EMPLOYEE.UPDATE_BANK, initPathVariable: id })
    const { isFormValid } = useValidation({ form: dataBank })

    const optionsBank = useMemo(() => {
        if (dataListBank?.data?.length === 0) return []
        return dataListBank?.data?.map((bank) => ({
            label: bank.name,
            value: bank.code
        }))
    }, [dataListBank?.data])

    const onChangeForm = (e) => {
        const { name, value } = e
        setDataBank({ ...dataBank, [name]: value, bank_account_name: null })
    }

    const onShowModal = () => {
        setDataBank({
            bank_account_name: bank_detail.bank_account_name,
            bank_account_number: bank_detail.bank_account_number,
            bank_name: { label: bank_detail.bank_name, value: bank_detail.bank_code }
        })
        setShowModal(true)
    }

    const onCloseModal = () => {
        setShowModal(false)
    }

    const onValidateAccount = () => {
        const params = {
            bank: dataBank?.bank_name.value,
            account: dataBank?.bank_account_number
        }
        validateBankAccount(params).then((response) => {
            setDataBank({
                ...dataBank,
                bank_account_name: response.data.account_name
            })
        })
            .catch(() => {})
    }

    const onSubmit = () => {
        updateBankAccount({
            bank_name: dataBank?.bank_name.label,
            bank_code: dataBank?.bank_name.value,
            bank_account_number: dataBank?.bank_account_number,
            bank_account_name: dataBank?.bank_account_name
        }).then((response) => {
            dispatch(updateBankDetail(response.data))
            dispatch(setToast({isShowToast: true, message: 'Bank Info Updated !!'}))
            onCloseModal()
        })
    }

    const props = {
        canValidate: dataBank?.bank_name?.value && dataBank?.bank_account_number,
        optionsBank,
        isFormValid: isFormValid(),
        dataBank,
        bankDetail: bank_detail,
        showModal,
        onShowModal,
        onCloseModal,
        onChangeForm,
        onValidateAccount,
        onSubmit,
        isLoadingSubmit,
    }

    return (
        <BankInfoTab {...props} />
    )
}
