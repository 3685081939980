import {METHOD} from "./method";

export const API_HRIS_ANNOUNCEMENT = {
    HRIS_ANNOUNCEMENT_LIST: {
        api: '/v1/hr-adm/announcement/list',
        method: METHOD.GET
    },
    COMPANIES_LIST: {
        api: '/v1/hr-adm/announcement/companies',
        method: METHOD.GET
    },
    EXPORT_ANNOUNCEMENT_LIST: {
        api: '/v1/hr-adm/announcement/list/export',
        method: METHOD.POST
    },
    PREVIEW_ANNOUNCEMENT: {
        api: '/v1/hr-adm/announcement/preview',
        method: METHOD.GET
    },
    URL_ATTACHMENTS: {
        api: '/v1/hr-adm/announcement/storage-url/attachment',
        method: METHOD.POST
    },
    CREATE_ANNOUNCEMENT: {
        api: '/v1/hr-adm/announcement',
        method: METHOD.POST
    },
    DETAIL_ANNOUNCEMENT: {
        api: '/v1/hr-adm/announcement',
        method: METHOD.GET
    },
    UPDATE_ANNOUNCEMENT: {
        api: '/v1/hr-adm/announcement',
        method: METHOD.PUT
    },
    DELETE_ANNOUNCEMENT: {
        api: '/v1/hr-adm/announcement',
        method: METHOD.DELETE
    },
}
