import Filter from "components/commons/filter"
import { STATUS_EMPLOYEE } from "constants/constant"
import { useEffect, useState } from "react"
import { API_EMPLOYEE } from "services/apiEmployee"
import { useFetch } from "services/useFetch"
import { capitalize, deepClone, getHeadUnitText } from "utils/utils"

const FILTER = {
    STATUS: 'status',
    DEPARTMENT: 'department',
    DIVISION: 'division',
    SUB_DIVISION: 'sub-division',
    JOIN_DATE: 'join-date',
}

const INITIAL_DATA_FILTER = [
    { label: 'Status Karyawan', value: FILTER.STATUS },
    { label: 'Departement', value: FILTER.DEPARTMENT },
    { label: 'Divisi', value: FILTER.DIVISION },
    { label: 'Sub Divisi', value: FILTER.SUB_DIVISION },
    {
        type: 'date',
        label: 'Join Date',
        start_date: {
            label: 'Dari Tanggal',
            name: 'start_date',
            placeholder: 'Pilih Tanggal Awal'
        },
        end_date: {
            label: 'Sampai Tanggal',
            name: 'end_date',
            placeholder: 'Pilih Tanggal Akhir'
        },
    }
]

const JOB_UNIT = [FILTER.DEPARTMENT, FILTER.DIVISION, FILTER.SUB_DIVISION]

const INITIAL_OPTIONS = {
    [FILTER.STATUS]: [],
    [FILTER.DEPARTMENT]: [],
    [FILTER.DIVISION]: [],
    [FILTER.SUB_DIVISION]: []
}

const OPTION_STATUS = {
    'active': [STATUS_EMPLOYEE.REGISTERED, STATUS_EMPLOYEE.ACTIVE, STATUS_EMPLOYEE.SUSPEND],
    'former': [STATUS_EMPLOYEE.INACTIVE, STATUS_EMPLOYEE.CONTRACT_REJECTED, STATUS_EMPLOYEE.BLACKLIST]
}

const INITIAL_FILTER = {
    status: [],
    department_ids: [],
    division_ids: [],
    subdivision_ids: [],
    start_date: '',
    end_date: ''
}

const QUERY_PARAMS = {
    [FILTER.DEPARTMENT]: 'department_ids',
    [FILTER.DIVISION]: 'division_ids',
    [FILTER.SUB_DIVISION]: 'subdivision_ids',
    [FILTER.STATUS]: FILTER.STATUS,
}

export const AccountActivationFilterContainer = ({
    show,
    onShow,
    onClose,
    onSubmit,
    queryFilter,
    scope,
}) => {
    const [activeFilter, setActiveFilter] = useState(INITIAL_DATA_FILTER[0])
    const [options, setOptions] = useState(INITIAL_OPTIONS)
    const [filter, setFilter] = useState(INITIAL_FILTER)
    const [filterCount, setFilterCount] = useState()
    const { fetching: getJobUnit } = useFetch(API_EMPLOYEE.LIST_JOB_UNIT)

    useEffect(() => {
        if (queryFilter) {
            const newQueryFilter = deepClone(queryFilter)
            setFilter(newQueryFilter)
            countFilterLength(newQueryFilter)
        }
    }, [queryFilter, show])

    useEffect(() => {
        setOptions({
            ...options,
            status: OPTION_STATUS[scope].map((item) => ({ name: capitalize(item.replace('-', ' ')), id: item }))
        })
    }, [scope])

    const onSelectFilter = (item) => {
        if (JOB_UNIT.includes(item.value) && options[item.value].length === 0) {
            getJobUnit({ head_unit_ids: filter?.[QUERY_PARAMS[getHeadUnitText[item.value]]] || [] }, item.value)
                .then(res => setOptions({ ...options, [item.value]: res.data }))
        }
        setActiveFilter(item)
    }

    const onChangeOption = (item) => {
        const { name, value, checked } = item
        const arrChecked = filter?.[QUERY_PARAMS[name]] || []
        handleClearOptionsFilter(name)

        if (checked) {
            arrChecked.push(String(value))
        } else {
            const indexRemove = arrChecked.indexOf(String(value))
            arrChecked.splice(indexRemove, 1)
        }
        setFilter({ ...filter, [QUERY_PARAMS[name]]: arrChecked })
    }

    const handleClearOptionsFilter = (filterName) => {
        if (filterName === FILTER.DEPARTMENT) {
            options[FILTER.DIVISION] = []
            options[FILTER.SUB_DIVISION] = []
            filter[[QUERY_PARAMS[FILTER.DIVISION]]] = []
            filter[[QUERY_PARAMS[FILTER.SUB_DIVISION]]] = []
        } else if (filterName === FILTER.DIVISION) {
            options[FILTER.SUB_DIVISION] = []
            filter[[QUERY_PARAMS[FILTER.SUB_DIVISION]]] = []
        }
        setOptions({ ...options })
    }


    const onChangeDate = (e) => {
        const { name, value } = e
        setFilter({ ...filter, [name]: value })
    }

    const onSubmitFilter = () => {
        const queryFilter = { ...filter }
        Object.entries(queryFilter).forEach(([key, item]) => {
            if (Array.isArray(queryFilter[key])) queryFilter[key] = item.join(',')
        })
        onSubmit(queryFilter)
        onClose()
    }

    const countFilterLength = (filter) => {
        let totalLength = 0
        for (const key in filter) {
            if (filter.hasOwnProperty(key)) {
                if (Array.isArray(filter[key])) {
                    totalLength += filter[key].length;
                } else {
                    totalLength += 1;
                }
            }
        }
        setFilterCount(totalLength)
    }

    const props = {
        show,
        onShow,
        onClose,
        queryParams: QUERY_PARAMS,
        filterList: INITIAL_DATA_FILTER,
        activeFilter,
        onSelectFilter,
        filter,
        filterCount,
        options,
        onChangeOption,
        onChangeDate,
        onSubmitFilter,
        onClearFilter: () => setFilter(deepClone(INITIAL_FILTER)),
    }

    return (
        <Filter {...props} />
    )
}
