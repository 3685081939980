import { FamiliesTab } from "components/employee/employee-detail/employee-detail-tab/FamiliesTab";
import { TABLE_FAMILY } from "configs/employee/configTableAddEmployee";
import { ACTION_TYPE } from "constants/constant";
import { DEFAULT_FORM_FAMILY, OPTIONS_FAMILY_RELATIONSHIP, OTHER_RELATIONSHIP } from "constants/defaultFormEmployee";
import { useValidation } from "hooks/useValidation";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setToast, updateFamilyInformation } from "reducers/employeeDetailReducer";
import { addFamilyValidation } from "services/addEmployeeValidation";
import { API_EMPLOYEE } from "services/apiEmployee";
import { METHOD } from "services/method";
import { useFetch } from "services/useFetch";
import { clearPhoneNumber, formatPhoneNumber } from "utils/utils";

export const FamiliesTabContainer = () => {
    const dispatch = useDispatch()
    const { id, families } = useSelector((state) => state.employeeDetailReducer)
    const [showModal, setShowModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [dataFamily, setDataFamily] = useState(DEFAULT_FORM_FAMILY)
    const [selectedIndex, setSelectedIndex] = useState()
    const [actionType, setActionType] = useState(ACTION_TYPE.CREATE)
    const { fetching: addFamily, isLoading: isLoadingAdd } = useFetch({ ...API_EMPLOYEE.FAMILY, initPathVariable: id })
    const { fetching: patchFamily, isLoading: isLoadingPatch } = useFetch({ ...API_EMPLOYEE.FAMILY, initPathVariable: dataFamily?.id, method: METHOD.PUT })
    const { fetching: deleteFamily, isLoading: isLoadingDelete } = useFetch({ ...API_EMPLOYEE.FAMILY, initPathVariable: dataFamily?.id, method: METHOD.DELETE })
    const { errorForm, validateForm, isFormValid } = useValidation({ form: dataFamily, schema: addFamilyValidation })
    const isOtherRelationship = dataFamily?.relationship?.value === OTHER_RELATIONSHIP

    const onChangeForm = (e) => {
        const { name, value } = e
        setDataFamily({
            ...dataFamily,
            [name]: value,
            ...(!isOtherRelationship && { other_relationship: null }),
        })
    }

    const onCloseModal = () => {
        setShowModal(false)
        setDataFamily(DEFAULT_FORM_FAMILY)
        setActionType(ACTION_TYPE.CREATE)
    }

    const onCloseDeleteModal = () => {
        setShowDeleteModal(false)
        setDataFamily(DEFAULT_FORM_FAMILY)
    }

    const onSubmit = () => {
        validateForm().then(() => {
            const newFamilies = [...families]
            const paramsDataFamily = {
                ...dataFamily,
                phone_number: formatPhoneNumber(dataFamily.phone_number),
                relationship: dataFamily.relationship.value === OTHER_RELATIONSHIP ? dataFamily.other_relationship : dataFamily.relationship.value,
            }

            const onSuccess = (message) => {
                dispatch(updateFamilyInformation(newFamilies))
                dispatch(setToast({isShowToast: true, message}))
                onCloseModal()
            }

            switch (actionType) {
                case ACTION_TYPE.CREATE:
                    addFamily(paramsDataFamily).then((response) => {
                        newFamilies.push(response.data)
                        onSuccess('Families Info Created !!')
                    })
                    break;
                case ACTION_TYPE.EDIT:
                    patchFamily(paramsDataFamily).then((response) => {
                        newFamilies[selectedIndex] = response.data
                        onSuccess('Families Info Updated !!')
                    })
                    break;
                default:
            }
        })
    }

    const handleEdit = () => {
        setActionType(ACTION_TYPE.EDIT)
        setShowModal(true)
    }

    const handleRemove = (index) => {
        deleteFamily().then(() => {
            const newFamilies = [...families]
            newFamilies.splice(index, 1)
            dispatch(updateFamilyInformation(newFamilies))
            dispatch(setToast({isShowToast: true, message: 'Families Info Deleted !!'}))
            onCloseDeleteModal()
        })
    }

    const onActionRow = ({ index, item, actionType }) => {
        const findRelationship = OPTIONS_FAMILY_RELATIONSHIP.find((find) => find.value === item.relationship)
        setSelectedIndex(index)
        setDataFamily({
            ...item,
            phone_number: clearPhoneNumber(item.phone_number),
            relationship: findRelationship || { value: OTHER_RELATIONSHIP, label: OTHER_RELATIONSHIP },
            ...(!findRelationship && { other_relationship: item.relationship }),
        })
        switch (actionType) {
            case ACTION_TYPE.EDIT:
                return handleEdit();
            case ACTION_TYPE.DELETE:
                return setShowDeleteModal(true);
            default:
        }
    }

    const checkValidationForm = () => {
        const checkForm = { ...dataFamily }
        if (!isOtherRelationship) delete checkForm.other_relationship
        return isFormValid(checkForm)
    }

    const props = {
        configTable: TABLE_FAMILY,
        optionsFamilyRelationship: OPTIONS_FAMILY_RELATIONSHIP,
        dataFamily,
        errorForm,
        isFormValid: checkValidationForm(),
        isEdit: actionType === ACTION_TYPE.EDIT,
        form: families,
        showModal,
        onShowModal: () => setShowModal(true),
        onCloseModal,
        onChangeForm,
        onSubmit,
        onActionRow,
        showDeleteModal,
        onCloseDeleteModal,
        onDelete: () => handleRemove(selectedIndex),
        isLoadingSubmit: isLoadingAdd || isLoadingPatch,
        isLoadingDelete
    }

    return (
        <FamiliesTab  {...props} />
    )
}
