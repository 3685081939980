import {
    ADDENDUM_CONTRACT_DETAIL_CONST,
    CONTRACT_DETAIL_CONST,
    CONTRACT_DOCUMENT_LIST
} from "../../../../constants/constant-management-contract";
import Button from "../../../commons/button";
import icEye from "../../../../assets/images/ic-eye-open.svg";
import {innerHTML, setCurrency, trimString} from "../../../../utils/utils";

export const ContractDetail = ({contractDetail, jobDescriptionHandler, modalPreviewDocumentVisibility}) => {
    let contractDetailConst = !!contractDetail?.addendum_number ? ADDENDUM_CONTRACT_DETAIL_CONST : CONTRACT_DETAIL_CONST
    const hasDocument = CONTRACT_DOCUMENT_LIST.some(document => contractDetail[document.key] !== undefined && contractDetail[document.key] !== null)

    const valueRender = (item) => {
        switch (true) {
            case item.isCurrency:
                return setCurrency(contractDetail[item.key])
            case ['company', 'job_title', 'grade'].includes(item.key):
                return contractDetail[item.key]?.name
            case item.key === 'job_description':
                return (
                    <div>
                        {
                            !!contractDetail[item.key] ?
                                <>
                                    <div className="inner-html"
                                         dangerouslySetInnerHTML={innerHTML(trimString(contractDetail[item.key]))}/>
                                    {contractDetail[item.key].length > 125 && <p onClick={jobDescriptionHandler}
                                                                                 className={"font-bold hover:text-primary-50 cursor-pointer"}>See
                                        Detail</p>}
                                </> : '-'
                        }
                    </div>
                )
            default:
                return contractDetail[item.key]
        }
    }
    return (
        <>
            {
                contractDetailConst.map((item, index) => (
                    <div className={"space-y-6"} key={index}>
                        <p className={"text-primary-60 font-bold capitalize"}>{item.label}</p>
                        <div className={"grid grid-cols-2 gap-y-6"}>
                            {
                                item.form.map((child, index) => <DetailItem
                                    value={valueRender(child)}
                                    label={child.labelDetail ?? child.label} key={index}/>)
                            }
                        </div>
                    </div>
                ))
            }
            <div className={"grid grid-cols-2 gap-y-6"}>
            {
                contractDetail?.allowances.map((item, idx) => (
                    <DetailItem
                        value={setCurrency(item.value)}
                        label={item.label} key={idx}/>
                ))
            }
            </div>
            {hasDocument && <div className={"space-y-6"}>
                <p className={"text-primary-60 font-bold"}>Dokumen</p>
                <div className={"space-y-6"}>
                    {
                        CONTRACT_DOCUMENT_LIST.map((item, index) => {
                            if (!!contractDetail[item.key]) {
                                return (
                                    <div className={"flex flex-row items-center font-medium space-x-3"} key={index}>
                                        <p className={"w-1/3 text-neutral-70"}>{(!!contractDetail?.is_addendum_contract && item.key === 'contract_document_url') ? 'Doc Addendum' : item.label}</p>
                                        {
                                            !!contractDetail[item.key] &&
                                            <Button title={'Preview'} icon={icEye} type={'outline'}
                                                    onClick={() => modalPreviewDocumentVisibility(contractDetail[item.key])}/>
                                        }
                                    </div>
                                )
                            }
                        })
                    }
                </div>
            </div>}
            {
                contractDetail?.notes &&
                <div className={"space-y-6"}>
                    <p className={"text-primary-60 font-bold"}>Notes</p>
                    <div className={"flex flex-row items-center font-medium"}>
                        <p className={"w-[300px] text-neutral-70"}>Isi Catatan</p>
                        <p className={"text-neutral-80 font-medium"}>{contractDetail.notes}</p>
                    </div>
                </div>
            }
        </>
    )
}

const DetailItem = ({label, value}) => {
    return (
        <div className={"flex flex-row items-center font-medium space-x-3"}>
            <p className={"w-1/3 text-neutral-70 capitalize"}>{label}</p>
            <div className={"w-2/3 text-neutral-80"}>{!!value ? value : '-'}</div>
        </div>
    )
}
