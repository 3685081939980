import Spinner from "../../../commons/spinner";
import ProgressBar from "../../../commons/progress-bar";

export const ValidationEmployeeContract = ({percentage}) => {
    return (
        <div className={"w-full flex flex-col items-center text-neutral-80 space-y-8 px-40 py-16"}>
            <Spinner className={"w-10 h-10 border-primary-50"}/>
            <div className={"flex flex-col items-center space-y-2"}>
                <p className={"font-bold text-lg"}>Validate Data</p>
                <p>Your employee contract are now being Validate</p>
            </div>
            <div className={"w-full flex flex-col items-center space-y-5 pt-8"}>
                <ProgressBar percentage={percentage}/>
                <p className={"font-bold text-lg text-primary-60"}>Progress in {percentage}%</p>
            </div>
        </div>
    )
}
