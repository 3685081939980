import {METHOD} from "./method";

export const API = {
    BITESHIP_SEARCH_AREA: {
        api: '/v1/maps/areas',
        method: METHOD.GET
    },
    GENERATE_SESSION: {
        api: '/v1/hr-adm/auth/register-session',
        method: METHOD.POST
    },
    LIST_MENU: {
        api: '/v1/hr-adm/menu',
        method: METHOD.GET
    },
    LOGOUT: {
        api: '/v1/auth/logout',
        method: METHOD.POST
    },
    SWITCH_COMPANY: {
        api: '/v1/hr-adm/menu/company',
        method: METHOD.GET
    }
}
