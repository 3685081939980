import icRight from "assets/images/ic-right-primary.svg"
import icRoleUser from "assets/images/ic-user-profile-check.svg"
import icUserDefault from "assets/images/ic-user-profile.svg"
import clsx from "clsx"
import Badge from "components/commons/badge"
import Button from "components/commons/button"
import Checkbox from "components/commons/checkbox"
import Input from "components/commons/input"
import Search from "components/commons/search"
import Select from "components/commons/select"

export const CreateRole = ({
    dataRole,
    selected,
    isFormValid,
    companyList,
    moduleList,
    menuList,
    capabilityList,
    selectedCapability,
    lengthSelectedCapability,
    optionsRole,
    selectedRole,
    selectedEmployee,
    selectedEmployeeList,
    isLoadingCreate,
    dataRoleDetail,
    isUpdateEmployeeRole,
    onSelectRole,
    onSelectModule,
    onSelectMenu,
    onChangeForm,
    onSearch,
    onSubmit,
}) => {

    return (
        <div className="flex flex-col flex-1 mb-[85px]">
            <div className="px-6 pt-6 pb-8">
                <div className="flex items-center gap-3">
                    <img className="w-4 h-4" src={icRoleUser} alt={'role user'} />
                    <div className="font-semibold text-md">{'Role User'}</div>
                </div>
                {isUpdateEmployeeRole ?
                    <>
                        <div className="bg-neutral-10 rounded-lg p-4 mt-5">
                            <div className="flex items-center justify-between">
                                {selectedEmployee || selectedEmployeeList?.length === 1 ?
                                    <>
                                        <div className="flex items-center gap-4">
                                            <img className="w-7 h-7 border border-white rounded-full -ml-1.5" src={selectedEmployee?.profile_picture || selectedEmployeeList?.[0]?.profile_picture || icUserDefault} alt={selectedEmployee?.full_name || selectedEmployeeList?.[0]?.full_name} />
                                            <div className="flex flex-col gap-1">
                                                <span className="font-semibold">{selectedEmployee?.full_name || selectedEmployeeList?.[0]?.full_name}</span>
                                                <span className="font-medium text-neutral-70">{'Role'}<span className="text-neutral-80 ml-2">{selectedEmployee?.role_name || selectedEmployeeList?.[0]?.role_name || '-'}</span></span>
                                            </div>
                                        </div>
                                        <div className="flex items-center gap-2">
                                            <span className="font-medium text-neutral-70">{'Status'}</span>
                                            <Badge type={selectedEmployee?.status || selectedEmployeeList?.[0]?.status} title={selectedEmployee?.status || selectedEmployeeList?.[0]?.status} />
                                        </div>
                                    </>
                                    :
                                    <div className="flex items-center gap-4">
                                        <div className="flex ml-1.5">
                                            {selectedEmployeeList?.map((item, index) => (
                                                <img key={index} className="w-7 h-7 border border-white rounded-full -ml-1.5" src={item?.profile_picture || icUserDefault} alt={item?.full_name} />
                                            ))}
                                        </div>
                                        <span className="font-medium"> {`Total User Selected: ${selectedEmployeeList?.length}`} </span>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="flex items-center gap-3 mt-5">
                            <div className="font-medium w-[120px]">{'Select Role'}</div>
                            <Select
                                name='status'
                                className='flex-1 max-w-[450px]'
                                optionsClassName='max-h-[300px]'
                                placeholder={'Pilih Role'}
                                options={optionsRole}
                                value={selectedRole}
                                onChange={onSelectRole}
                            />
                        </div>
                    </>
                    :
                    <div className="flex items-center gap-3 mt-5">
                        <div className="font-medium w-[120px]">{'Nama Role'}</div>
                        <Input className='flex-1 max-w-[450px]' name="name" placeholder={"Isi Nama Role"} value={dataRole?.name} onChange={onChangeForm} />
                    </div>
                }
                <div className="space-y-4 mt-5">
                    <div className="font-medium w-[120px] mt-5">{'Select PT'}</div>
                    <div className="flex flex-wrap gap-x-8 gap-y-4">
                        {companyList?.map((item, index) => (
                            <Checkbox
                                key={index}
                                label={item.name}
                                name={'company_ids'}
                                value={item.id}
                                checked={dataRole?.company_ids?.includes(item.id)}
                                disabled={isUpdateEmployeeRole && dataRoleDetail?.company_ids?.includes(item.id)}
                                onChange={onChangeForm}
                            />
                        ))}
                    </div>
                </div>
            </div>
            <div className="font-semibold px-6 pb-6">{'Select Capability'}</div>
            <div className="flex flex-1 w-full border-t border-neutral-30">
                <div className="basis-1/4">
                    <div className="font-semibold px-6 py-4 bg-neutral-20 border-r border-neutral-30">{'Modul'}</div>
                    <div className="p-6 border-t border-r border-neutral-30 h-full">
                        <div className="space-y-1">
                            {moduleList?.map((item, index) => (
                                <div
                                    key={index}
                                    className={clsx(" flex items-center p-3 rounded-md cursor-pointer transition duration-200", item.name === selected.module ? "bg-primary-10" : "hover:bg-neutral-10")}
                                    onClick={() => onSelectModule(item.name)}
                                >
                                    <div className="font-medium">{item.name}</div>
                                    {item.name === selected.module && <img className="w-4 h-4 ml-auto" src={icRight} alt='right' />}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="basis-5/12">
                    <div className="font-semibold px-6 py-4 bg-neutral-20 border-r border-neutral-30">{'Menu'}</div>
                    <div className="p-6 border-t border-r border-neutral-30 h-full">
                        <Search onSearch={(e) => onSearch('menu', e)} />
                        <div className="space-y-1 mt-6">
                            {menuList?.map((item, index) => (
                                <div
                                    key={index}
                                    className={clsx(" flex items-center p-3 rounded-md cursor-pointer transition duration-200", item.name === selected.menu ? "bg-primary-10" : "hover:bg-neutral-10")}
                                    onClick={() => onSelectMenu(item.name)}
                                >
                                    <div className="font-medium">{item.name}</div>
                                    {item.name === selected.menu && <img className="w-4 h-4 ml-auto" src={icRight} alt='right' />}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="basis-5/12">
                    <div className="font-semibold px-6 py-4 bg-neutral-20">{'Capabilities'}</div>
                    <div className="p-6 border-t border-neutral-30 h-full">
                        <Search onSearch={(e) => onSearch('capability', e)} />
                        <div className="space-y-1 mt-6">
                            {capabilityList?.length > 1 && <Checkbox
                                name={'all'}
                                className={"flex items-center p-3 rounded-md hover:bg-neutral-10 cursor-pointer transition duration-200"}
                                label='Select All'
                                indeterminate={lengthSelectedCapability > 0 && lengthSelectedCapability < capabilityList?.length}
                                checked={lengthSelectedCapability > 0 || lengthSelectedCapability === capabilityList?.length}
                                onChange={onChangeForm}
                            />}
                            {capabilityList?.map((item, index) => (
                                <Checkbox
                                    key={index}
                                    className={"flex items-center p-3 rounded-md hover:bg-neutral-10 cursor-pointer transition duration-200"}
                                    label={item.name}
                                    name={'permission_ids'}
                                    value={item.id}
                                    checked={dataRole?.permission_ids?.includes(item.id) || selectedCapability?.includes(item.id)}
                                    disabled={isUpdateEmployeeRole && dataRoleDetail?.permission_ids?.includes(item.id)}
                                    onChange={onChangeForm}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </div>

            <div className="fixed bottom-0 w-[calc(100%-200px)] border-t border-neutral-20 flex items-center justify-end bg-white py-6 px-14 gap-3">
                <Button className="w-32" title="Simpan" disabled={!isFormValid} loading={isLoadingCreate} onClick={onSubmit} />
            </div>
        </div>
    )
}
