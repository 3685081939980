import {METHOD} from "./method";

export const API_COMPONENT_SETTING = {
    COMPONENT_SETTING_LIST: {
        api: '/v1/hr-adm/payroll-setting/list',
        method: METHOD.POST
    },
    CATEGORY_LIST: {
        api: '/v1/hr-adm/payroll-setting/categories',
        method: METHOD.GET
    },
    CREATE_COMPONENT: {
        api: '/v1/hr-adm/payroll-setting',
        method: METHOD.POST
    },
    UPDATE_COMPONENT: {
        api: '/v1/hr-adm/payroll-setting',
        method: METHOD.PUT
    },
}
