import {METHOD} from "./method";

export const API_PNC_APPROVER = {
    PNC_APPROVER_LIST: {
        api: '/v1/hr-adm/pnc-approver-setting',
        method: METHOD.GET
    },
    PNC_EMPLOYEE_APPROVER_LIST: {
        api: '/v1/hr-adm/pnc-approver-setting/pnc',
        method: METHOD.GET
    },
    UPDATE_PNC_APPROVER: {
        api: '/v1/hr-adm/pnc-approver-setting',
        method: METHOD.PUT
    }
}
