import {WorkTimeForm} from "components/work-settings/work-time-form/WorkTimeForm";
import {useDispatch, useSelector} from "react-redux";
import {setWorkTimeDetail, setWorkTimeToast} from "../../../reducers/formWorkTimeReducer";
import {ACTION_TYPE} from "../../../constants/constant";
import {useFetch} from "../../../services/useFetch";
import {API_WORK_TIME} from "../../../services/apiWorkTime";
import {useNavigate} from "react-router-dom";
import {useState} from "react";
import moment from "moment";

export const WorkTimeFormContainer = () => {
    const {fetching: createWorkTime, isLoading: isCreating} = useFetch(API_WORK_TIME.CREATE_WORK_TIME)
    const {fetching: updateWorkTime, isLoading: isUpdating} = useFetch(API_WORK_TIME.UPDATE_WORK_TIME)
    const {actionType, workTimeDetail} = useSelector(state => state.formWorkTimeReducer)
    const [errorForm, setErrorForm] = useState({})
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const onChangeValue = ({name, value, checked}) => {
        const splitName = name?.split('-')
        let newData = {}
        if (splitName[1]) {
            newData = {[splitName[0]]: {...workTimeDetail?.[splitName[0]], [splitName[1]]: value}}
        } else {
            const workType = checked ? 'shifting' : 'regular'
            newData = {[name]: checked === undefined ? value : workType}
        }

        dispatch(setWorkTimeDetail({...workTimeDetail, ...newData}))
    }

    const validateForm = () => {
        return new Promise((resolve, reject) => {
            const errors = {}
            Object.keys(workTimeDetail).forEach(key => {
                const clockInTime = workTimeDetail[key]?.clock_in_time
                const clockOutTime = workTimeDetail[key]?.clock_out_time
                const isInvalidTime = moment(clockOutTime, 'hh:mm').isSameOrBefore(moment(clockInTime, 'hh:mm'))
                if (key === 'category_name' && !workTimeDetail[key]) {
                    errors[key] = 'Nama kategori jam kerja tidak boleh kosong'
                } else if (workTimeDetail?.type === 'regular' && ((!clockInTime && clockOutTime) || (clockInTime && !clockOutTime))) {
                    errors[key] = !clockInTime ? {clock_in_time: 'Jam masuk tidak boleh kosong'} : {clock_out_time: 'Jam keluar tidak boleh kosong'}
                } else if (workTimeDetail?.type === 'regular' && isInvalidTime) {
                    errors[key] = {clock_out_time: `Jam keluar tidak boleh kurang atau sama dengan jam masuk (${clockInTime})`}
                }
            })
            setErrorForm(errors)
            if (!Object.keys(errors).length) resolve()
        })
    }

    const getDataWorkTime = () => {
        const dataShifting = {...workTimeDetail}
        Object.keys(workTimeDetail)?.forEach(key => {
            if (workTimeDetail?.[key]?.hasOwnProperty('clock_in_time')) {
                dataShifting[key] = {clock_in_time: null, clock_out_time: null}
            }
        })

        return workTimeDetail?.type === 'regular' ? workTimeDetail : dataShifting
    }

    const onSubmitForm = () => {
        validateForm()
            .then(() => {
                const {id, ...payload} = getDataWorkTime()
                if (actionType === ACTION_TYPE.CREATE) {
                    createWorkTime(payload)
                        .then(() => {
                            navigate('/work-settings/work-time')
                            dispatch(setWorkTimeToast({message: 'Work Time Created', isShowToast: true}))
                        })
                } else {
                    updateWorkTime(payload, id)
                        .then(() => {
                            navigate('/work-settings/work-time')
                            dispatch(setWorkTimeToast({message: 'Work Time Updated', isShowToast: true}))
                        })
                }
            })
    }

    const props = {
        actionType,
        workTimeDetail,
        onChangeValue,
        isCreating,
        isUpdating,
        onSubmitForm,
        errorForm
    }

    return (
        <WorkTimeForm {...props}/>
    )
}
