import clsx from 'clsx'
import {useEffect} from 'react'
import './modal.scss'

const Modal = ({children, className, dialogClassName, contentClassName, show, onHide}) => {
    useEffect(() => {
        if (show) {
            document.body.style.overflow = 'hidden'
        }
        return () => {
            document.body.style.overflow = 'visible'
        }
    }, [show])

    const handleOnHide = (e) => {
        e.stopPropagation()
        if (onHide) onHide()
    }

    return (
        <>
            <div
                className={clsx("avo__modal", show ? "show" : "no-show", className)}
                onClick={handleOnHide}
            >
                {show && <div
                    className={clsx("dialogShow", show ? "scale100" : 'scale95', dialogClassName)}>

                    <div className={clsx("content", contentClassName)}
                         onClick={(e) => e.stopPropagation()}>
                        {children}
                    </div>
                </div>}
            </div>
        </>
    )
}

export default Modal
