import icFileEdit01 from "assets/images/ic-file-edit-02.svg"
import Button from "components/commons/button"
import Checkbox from "components/commons/checkbox"
import Datepicker from "components/commons/datepicker"
import { DetailItems } from "components/commons/detail-items"
import FormModal from "components/commons/form-modal"
import Input from "components/commons/input"
import Select from "components/commons/select"
import moment from "moment";

export const AdditionalInfoTab = ({
    additionalInfoConstList,
    residenceAddressConstList,
    addressConstList,
    options,
    optionsMainArea,
    optionsResidenceArea,
    optionsMainPostalCode,
    optionsResidencePostalCode,
    selected,
    dataAdditional,
    additionalInfo,
    showModal,
    onShowModal,
    onCloseModal,
    onChangeForm,
    onSubmit,
    isLoadingSubmit,
    onSearchArea,
}) => {

    return (
        <>
            <div className={"flex items-center justify-between"}>
                <p className={"text-neutral-80 font-semibold text-md"}>{'Informasi Tambahan'}</p>
                <Button title={'Edit'} type={'outline'} icon={icFileEdit01} onClick={onShowModal} />
            </div>

            <div className={"pt-6 grid grid-cols-2 gap-y-5 gap-x-16"}>
                {additionalInfoConstList.map((item, index) =>
                    <DetailItems key={index} className={item?.className} label={item.label} value={additionalInfo[item.key] ? `${additionalInfo[item.key]} ${item.addOnAfter || ''}` : ''} />
                )}
            </div>
            <div className={"pt-8 grid grid-cols-2 gap-16"}>
                <div>
                    <p className={"text-neutral-80 font-semibold text-md mb-6"}>Alamat KTP</p>
                    <div className={"space-y-5"}>
                        {addressConstList.map((item, index) =>
                            <DetailItems key={index} label={item.label} value={additionalInfo[item.key]} />
                        )}
                    </div>
                </div>
                <div>
                    <p className={"text-neutral-80 font-semibold text-md mb-6"}>Alamat Domisili</p>
                    <div className={"space-y-5"}>
                        {residenceAddressConstList.map((item, index) =>
                            <DetailItems key={index} label={item.label} value={additionalInfo[item.key]} />
                        )}
                    </div>
                </div>
            </div>

            <FormModal
                width={'max-w-[768px]'}
                show={showModal}
                onHide={onCloseModal}
                title={'Informasi Tambahan'}
                onSubmitForm={onSubmit}
                isLoading={isLoadingSubmit}
                contentClassName='overflow-auto'
            >
                <div className={"px-8 py-6 mb-2 space-y-8"}>
                    <div className={"grid grid-cols-2 gap-y-5 gap-x-9"}>
                        <div className="flex items-center gap-3">
                            <div className="w-1/3 text-neutral-70">{'Gender'}</div>
                            <Select
                                name="gender"
                                className='flex-1'
                                placeholder="Pilih gender"
                                options={options.optionsGender}
                                value={selected.gender}
                                onChange={onChangeForm}
                            />
                        </div>
                        <div className="flex items-center gap-3">
                            <div className="w-1/3 text-neutral-70">{'Golongan Darah'}</div>
                            <Select
                                name="blood_type"
                                className='flex-1'
                                placeholder="Pilih golongan darah"
                                options={options.optionsBloodType}
                                value={selected.blood_type}
                                onChange={onChangeForm}
                            />
                        </div>
                        <div className="flex items-center gap-3">
                            <div className="w-1/3 text-neutral-70">{'Tempat Lahir'}</div>
                            <Input
                                className="flex-1"
                                name="birthplace"
                                placeholder="Isi tempat lahir"
                                maxLength={100}
                                value={dataAdditional?.birthplace}
                                onChange={onChangeForm}
                            />
                        </div>
                        <div className="flex items-center gap-3">
                            <div className="w-1/3 text-neutral-70">{'Tanggal Lahir'}</div>
                            <Datepicker
                                name="birthdate"
                                className="flex-1"
                                value={dataAdditional?.birthdate}
                                format='YYYY-MM-DD'
                                maxDate={moment()}
                                onChange={(e) => onChangeForm({ name: 'birthdate', value: e })}
                            />
                        </div>
                        <div className="flex items-center gap-3">
                            <div className="w-1/3 text-neutral-70">{'Agama'}</div>
                            <Select
                                name="religion"
                                className='flex-1'
                                placeholder="Pilih agama"
                                options={options.optionsReligions}
                                value={selected.religion}
                                onChange={onChangeForm}
                            />
                        </div>
                        <div className="flex items-center gap-3">
                            <div className="w-1/3 text-neutral-70">{'Status'}</div>
                            <Select
                                name="marital_status"
                                className='flex-1'
                                placeholder="Pilih status"
                                options={options.optionsMaritalStatus}
                                value={selected.marital_status}
                                onChange={onChangeForm}
                            />
                        </div>
                        <div className="flex items-center gap-3">
                            <div className="w-1/3 text-neutral-70">{'Tinggi Badan'}</div>
                            <Input
                                className="flex-1"
                                addonAfter="cm"
                                name="height"
                                type="number"
                                placeholder="Isi tinggi badan"
                                maxLength={3}
                                value={dataAdditional?.height}
                                onChange={onChangeForm}
                            />
                        </div>
                        <div className="flex items-center gap-3">
                            <div className="w-1/3 text-neutral-70">{'Berat Badan'}</div>
                            <Input
                                className="flex-1"
                                addonAfter="Kg"
                                name="weight"
                                type="number"
                                placeholder="Isi berat badan"
                                maxLength={3}
                                value={dataAdditional?.weight}
                                onChange={onChangeForm}
                            />
                        </div>
                        <div className="flex items-center gap-3">
                            <div className="w-1/3 text-neutral-70">{'Hobi'}</div>
                            <Input
                                className="flex-1"
                                name="hobby"
                                placeholder="Isi hobi"
                                maxLength={100}
                                value={dataAdditional?.hobby}
                                onChange={onChangeForm}
                            />
                        </div>
                    </div>

                    <div className="space-y-5">
                        <div className="font-semibold text-md text-primary-80">{'Alamat KTP'}</div>
                        <div className="flex items-center gap-3">
                            <div className="w-1/3 text-neutral-70">{'Kecamatan & Kota'}</div>
                            <Select
                                name="main"
                                className='flex-1'
                                optionsClassName={optionsMainArea.length > 0 && 'max-h-[200px] min-w-full sm:!w-auto whitespace-pre-line sm:whitespace-nowrap'}
                                placeholder="Cari kecamatan dan kota"
                                options={optionsMainArea}
                                value={selected.main}
                                onChange={onChangeForm}
                                onSearch={(keyword) => onSearchArea("main", keyword)}
                            />
                        </div>
                        <div className="flex items-center gap-3">
                            <div className="w-1/3 text-neutral-70">{'Kode Pos'}</div>
                            <Select
                                name="id_card_postal_code"
                                className='flex-1'
                                placeholder="Pilih kode pos"
                                options={optionsMainPostalCode}
                                value={selected.id_card_postal_code}
                                onChange={onChangeForm}
                            />
                        </div>
                        <div className="flex gap-3">
                            <div className="w-1/3 text-neutral-70">{'Detail Alamat'}</div>
                            <Input
                                className='flex-1'
                                name="id_card_address"
                                type="text-area"
                                row={3}
                                showCount
                                maxLength={300}
                                placeholder="Isi detail alamat"
                                value={dataAdditional?.id_card_address}
                                onChange={onChangeForm}
                            />
                        </div>
                    </div>

                    <div className="space-y-5">
                        <div className="font-semibold text-md text-primary-80">{'Alamat Domisili'}</div>
                        <Checkbox label="Sama dengan di alamat KTP" name="same_as_address" value={'same_as'} onChange={onChangeForm} />
                        <div className="flex items-center gap-3">
                            <div className="w-1/3 text-neutral-70">{'Kecamatan & Kota'}</div>
                            <Select
                                name="residence"
                                className='flex-1'
                                optionsClassName={optionsResidenceArea.length > 0 && 'max-h-[200px] min-w-full sm:!w-auto whitespace-pre-line sm:whitespace-nowrap'}
                                placeholder="Cari kecamatan dan kota"
                                options={optionsResidenceArea}
                                value={selected.residence}
                                onChange={onChangeForm}
                                onSearch={(keyword) => onSearchArea("residence", keyword)}
                                disabled={selected.same_as_address}
                            />
                        </div>
                        <div className="flex items-center gap-3">
                            <div className="w-1/3 text-neutral-70">{'Kode Pos'}</div>
                            <Select
                                name="residence_postal_code"
                                className='flex-1'
                                placeholder="Pilih kode pos"
                                options={optionsResidencePostalCode}
                                value={selected.residence_postal_code}
                                onChange={onChangeForm}
                                disabled={selected.same_as_address}
                            />
                        </div>
                        <div className="flex gap-3">
                            <div className="w-1/3 text-neutral-70">{'Detail Alamat'}</div>
                            <Input
                                className='flex-1'
                                name="residence_address"
                                type="text-area"
                                row={3}
                                showCount
                                maxLength={300}
                                placeholder="Isi detail alamat"
                                disabled={selected.same_as_address}
                                value={dataAdditional?.residence_address}
                                onChange={onChangeForm}
                            />
                        </div>
                    </div>
                </div>
            </FormModal>
        </>
    )
}
