import {useEffect, useState} from "react";
import {ImportData} from "../../../../components/payroll/create-payroll/import-data-payroll/ImportData";
import {useFetch} from "../../../../services/useFetch";
import {API_INCENTIVE} from "../../../../services/apiIncentive";

export const ImportDataContainer = ({params, payload, onChangeStep, onFinish}) => {
    const [percentage, setPercentage] = useState(0)
    const [isComplete, setIsComplete] = useState(false)
    const api = `${API_INCENTIVE.IMPORT.api}/${params}/import`
    const {fetching: importData} = useFetch({...API_INCENTIVE.IMPORT, api})

    useEffect(() => {
        if (percentage === 10) {
            importData(payload, null, {'Content-Type': 'multipart/form-data'})
                .catch(() => {
                    onChangeStep(1)
                })
        }
    }, [percentage])

    useEffect(() => {
        let timeout = null
        if (percentage <= 80) {
            timeout = setTimeout(() => {
                setPercentage(percentage + 10)
            }, 1000)
        } else { //else if !isLoading && response?.data
            timeout = setTimeout(() => {
                if (percentage === 100) {
                    setIsComplete(true)
                } else {
                    setPercentage(percentage + 10)
                }
            }, 1000)
        }

        return () => {
            clearTimeout(timeout)
        }
    }, [percentage]) //isLoading

    const props = {
        params,
        percentage,
        isComplete,
        onHide: onFinish
    }

    return (
        <ImportData {...props}/>
    )
}
