import { AdditionalInfoTab } from "components/employee/employee-detail/employee-detail-tab/AdditionalInfoTab"
import { SELECTED_ADDITIONAL_INFORMATION } from "constants/defaultFormEmployee"
import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setToast, updateAdditionalInfo } from "reducers/employeeDetailReducer"
import { API } from "services/api"
import { API_EMPLOYEE } from "services/apiEmployee"
import { useBiteship } from "services/useBiteship"
import { useFetch } from "services/useFetch"
import { capitalize } from "utils/utils"

export const AdditionalInfoTabContainer = ({ options }) => {
    const dispatch = useDispatch()
    const { id, additional_info } = useSelector(state => state.employeeDetailReducer)
    const [showModal, setShowModal] = useState(false)
    const [selected, setSelected] = useState(SELECTED_ADDITIONAL_INFORMATION)
    const [dataAdditional, setDataAdditional] = useState()
    const [optionsMainArea, setOptionsMainArea] = useState([])
    const [optionsResidenceArea, setOptionsResidenceArea] = useState([])
    const [optionsMainPostalCode, setOptionsMainPostalCode] = useState([])
    const [optionsResidencePostalCode, setOptionsResidencePostalCode] = useState([])
    const { fetching: patchAdditionalInfo, isLoading: isLoadingSubmit } = useFetch({ ...API_EMPLOYEE.UPDATE_ADDITIONAL_INFORMATION, initPathVariable: id })
    const { fetching: searchArea } = useBiteship(API.BITESHIP_SEARCH_AREA)

    const additionalInfoConstList = [
        {
            label: 'Gender',
            key: 'gender',
            className: 'capitalize'
        }, {
            label: 'Golongan Darah',
            key: 'blood_type'
        }, {
            label: 'Tempat Lahir',
            key: 'birthplace'
        }, {
            label: 'Tanggal Lahir',
            key: 'birthdate'
        }, {
            label: 'Agama',
            key: 'religion',
            className: 'capitalize'
        }, {
            label: 'Status',
            key: 'marital_status',
            className: 'capitalize'
        }, {
            label: 'Tinggi Badan',
            key: 'height',
            addOnAfter: 'cm'
        }, {
            label: 'Berat Badan',
            key: 'weight',
            addOnAfter: 'Kg'
        }, {
            label: 'Hobi',
            key: 'hobby'
        },
    ]

    const residenceAddressConstList = [
        {
            label: 'Provinsi',
            key: 'residence_province'
        }, {
            label: 'Kabupaten/Kota',
            key: 'residence_city'
        }, {
            label: 'Kecamatan',
            key: 'residence_district'
        }, {
            label: 'Kode Pos',
            key: 'residence_postal_code'
        }, {
            label: 'Detail Alamat',
            key: 'residence_address'
        },
    ]

    const addressConstList = [
        {
            label: 'Provinsi',
            key: 'id_card_province'
        }, {
            label: 'Kabupaten/Kota',
            key: 'id_card_city'
        }, {
            label: 'Kecamatan',
            key: 'id_card_district'
        }, {
            label: 'Kode Pos',
            key: 'id_card_postal_code'
        }, {
            label: 'Detail Alamat',
            key: 'id_card_address'
        },
    ]

    const onChangeForm = (e) => {
        const { name, value, checked } = e
        switch (name) {
            case 'gender':
            case 'religion':
            case 'marital_status':
            case 'blood_type':
                setDataAdditional({ ...dataAdditional, [name]: String(value.value) })
                setSelected({ ...selected, [name]: value })
                break;
            case 'same_as_address':
                setDataAdditional({
                    ...dataAdditional,
                    [name]: checked,
                    residence_province: checked ? dataAdditional?.id_card_province : null,
                    residence_city: checked ? dataAdditional?.id_card_city : null,
                    residence_district: checked ? dataAdditional?.id_card_district : null,
                    residence_postal_code: checked ? dataAdditional?.id_card_postal_code : null,
                    residence_address: checked ? dataAdditional?.id_card_address : null,
                })
                setSelected({
                    ...selected,
                    [name]: checked,
                    residence: checked ? selected.main : {},
                    residence_postal_code: checked ? selected.id_card_postal_code : {},
                })
                break;
            case 'main':
            case 'residence':
                const splitArea = value.label.split(/,\s/g)
                const prefix = name === 'main' ? 'id_card' : 'residence'
                const isSameAsAddress = name === 'main' && selected.same_as_address
                setDataAdditional({
                    ...dataAdditional,
                    [`${prefix}_district`]: splitArea[0],
                    [`${prefix}_city`]: splitArea[1],
                    [`${prefix}_province`]: splitArea[2],
                    [`${prefix}_postal_code`]: null,
                    ...(isSameAsAddress && {
                        residence_district: splitArea[0],
                        residence_city: splitArea[1],
                        residence_province: splitArea[2],
                        residence_postal_code: null,
                    })
                })
                setSelected({
                    ...selected,
                    [name]: value,
                    ...(name === 'main' ? { id_card_postal_code: {} } : { residence_postal_code: {} }),
                    ...(selected.same_as_address && { residence: value, residence_postal_code: {} }),
                })
                searchPostalCode(name, value.value)
                break;
            case 'id_card_postal_code':
            case 'residence_postal_code':
                setDataAdditional({
                    ...dataAdditional,
                    [name]: String(value.value),
                    ...(selected.same_as_address && { residence_postal_code: String(value.value) }),
                })
                setSelected({
                    ...selected,
                    [name]: value,
                    ...(selected.same_as_address && { residence_postal_code: value }),
                })
                break;
            case 'id_card_address':
                setDataAdditional({
                    ...dataAdditional,
                    [name]: value,
                    ...(selected.same_as_address && { residence_address: value }),
                })
                break;
            default:
                setDataAdditional({ ...dataAdditional, [name]: value })
                break;
        }
    }

    const onSearchArea = (name, keyword, areaLabel, isSameAs) => {
        const payload = {
            countries: 'ID',
            input: keyword,
            type: 'double'
        }

        searchArea(payload).then((item) => {
            const area = item.areas.map((item) => ({
                label: item.name,
                value: item.id
            }))
            name === 'main' ? setOptionsMainArea([...area]) : setOptionsResidenceArea([...area])
            if (isSameAs) setOptionsResidenceArea([...area])
            if (areaLabel) {
                const findArea = area.find((item) => item.label === areaLabel)
                searchPostalCode(name, findArea.value)
            }
        })
    }

    const searchPostalCode = (name, areaId) => {
        searchArea({}, areaId).then((item) => {
            const postalCode = item.areas.map((item) => ({
                label: item.postal_code,
                value: item.postal_code
            }))
            if (name === 'main') {
                setOptionsMainPostalCode([...postalCode])
                if (selected?.main?.label === selected?.residence?.label) setOptionsResidencePostalCode([...postalCode])
            } else {
                setOptionsResidencePostalCode([...postalCode])
            }
        })
    }

    const onShowModal = () => {
        const selectedMain = additional_info?.id_card_district ? { label: `${additional_info.id_card_district.trim()}, ${additional_info.id_card_city.trim()}, ${additional_info.id_card_province.trim()}` } : {}
        const selectedResidence = additional_info?.residence_district ? { label: `${additional_info.residence_district.trim()}, ${additional_info.residence_city.trim()}, ${additional_info.residence_province.trim()}` } : {}

        setDataAdditional(additional_info)
        setSelected({
            gender: { label: capitalize(additional_info.gender), value: additional_info.gender },
            religion: { label: capitalize(additional_info.religion), value: additional_info.religion },
            marital_status: { label: capitalize(additional_info.marital_status), value: additional_info.marital_status },
            blood_type: { label: additional_info.blood_type, value: additional_info.blood_type },
            main: selectedMain,
            residence: selectedResidence,
            id_card_postal_code: additional_info?.id_card_postal_code ? { label: additional_info?.id_card_postal_code, value: additional_info?.id_card_postal_code } : {},
            residence_postal_code: additional_info?.residence_postal_code ? { label: additional_info?.residence_postal_code, value: additional_info?.residence_postal_code } : {},
        })

        if (selectedMain?.label) onSearchArea('main', selectedMain?.label, selectedMain?.label, selectedResidence?.label === selectedMain?.label)
        if (selectedResidence?.label && selectedResidence?.label !== selectedMain?.label) onSearchArea('residence', selectedResidence?.label, selectedResidence?.label)
        setShowModal(true)
    }

    const onCloseModal = () => {
        setShowModal(false)
    }

    const onSubmit = () => {
        patchAdditionalInfo(dataAdditional).then(() => {
            dispatch(updateAdditionalInfo(dataAdditional))
            dispatch(setToast({isShowToast: true, message: 'Additional Info Updated !!'}))
            onCloseModal()
        })
    }

    const props = {
        additionalInfoConstList,
        residenceAddressConstList,
        addressConstList,
        options,
        optionsMainArea,
        optionsResidenceArea,
        optionsMainPostalCode,
        optionsResidencePostalCode,
        selected,
        dataAdditional,
        additionalInfo: additional_info,
        showModal,
        onShowModal,
        onCloseModal,
        onChangeForm,
        onSubmit,
        isLoadingSubmit,
        onSearchArea,
    }

    return (
        <AdditionalInfoTab {...props} />
    )
}
