import FormModal from "../commons/form-modal";
import Input from "../commons/input";
import icHome from "../../assets/images/ic-home-01.svg"
import icPlus from "../../assets/images/ic-plus-01-primary.svg";
import clsx from "clsx";
import {CREATE_COMPANY_FORM} from "../../constants/constant";
import Button from "../commons/button";
import icTrash from "../../assets/images/ic-trash-04.svg";
import Datepicker from "../commons/datepicker";
import ErrorMessage from "../commons/error-message";
import moment from "moment/moment";

export const CreateCompany = ({
                                  show,
                                  onHide,
                                  logo,
                                  onSubmitForm,
                                  onUploadImage,
                                  onDeleteImage,
                                  onChangeFormValue,
                                  companyDetail,
                                  isFormValid,
                                  errorForm
                              }) => {

    return (
        <FormModal
            title={'Tambah Data Perusahaan'}
            onSubmitForm={onSubmitForm}
            show={show}
            onHide={onHide}
            isDisabled={!isFormValid}
        >
            <div className={"px-8 py-6 space-y-5"}>
                {
                    CREATE_COMPANY_FORM.map((form, index) => {
                        switch (form.type) {
                            case "upload":
                                return (
                                    <div key={index} className={"flex items-center"}>
                                        <p className={"w-1/4 text-neutral-70 required"}>{form.label}</p>
                                        <div className="flex gap-5">
                                            <div
                                                className="w-[72px] h-[72px] flex items-center justify-center overflow-hidden rounded-full border bg-neutral-20">
                                                <img
                                                    className={clsx("aspect-square", logo?.base64 || companyDetail?.logo ? 'w-full' : 'w-9 h-9')}
                                                    src={logo?.base64 || companyDetail?.logo || icHome} alt='profile'/>
                                            </div>
                                            <div
                                                className={clsx("flex flex-col", logo?.name ? "justify-between" : "justify-center")}>
                                                <span className="text-md">{logo?.name}</span>
                                                <input className={'hidden'} accept="image/*" id='profile-picture'
                                                       type={'file'} onChange={onUploadImage}/>
                                                <div className="flex gap-2">
                                                    {logo?.base64 || form?.logo ?
                                                        <label className="avo__button outline cursor-pointer"
                                                               type="button" htmlFor={'profile-picture'}>
                                                            <div className="btn-content"> {'Ubah'} </div>
                                                        </label>
                                                        :
                                                        <label className="avo__button outline cursor-pointer"
                                                               type="button" htmlFor={'profile-picture'}>
                                                            <div className="btn-content">
                                                                <img src={icPlus} alt='ubah'/>
                                                                <span>{'Upload Photo'}</span>
                                                            </div>
                                                        </label>
                                                    }
                                                    {(logo?.base64 || companyDetail?.logo) &&
                                                        <Button className="w-[100px]" type="outline" title="Hapus"
                                                                icon={icTrash} onClick={onDeleteImage}/>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            case 'date':
                                return (
                                    <div key={index} className={"flex"}>
                                        <p className={"w-1/4 required"}>{form.label}</p>
                                        <Datepicker className={"flex-1"} name={form.key}
                                                    placeholder="Pilih tanggal berdiri"
                                                    value={companyDetail[form.key]}
                                                    format='YYYY-MM-DD'
                                                    maxDate={moment()}
                                                    onChange={(e) => onChangeFormValue({[form.key]: e})}/>
                                    </div>
                                )
                            default:
                                return (
                                    <div key={index} className={"flex"}>
                                        <p className={"w-1/4 required mt-2"}>{form.label}</p>
                                        <div className={"flex-1 space-y-1"}>
                                            <Input  placeholder={form.placeholder}
                                                   value={companyDetail[form.key]} name={form.key}
                                                   onChange={(e) => onChangeFormValue({[e.name]: e.value})}/>
                                            <ErrorMessage message={errorForm[form.key]}/>
                                        </div>
                                    </div>
                                )
                        }
                    })
                }
            </div>
        </FormModal>
    )
}
