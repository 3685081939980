import icPlus01 from "assets/images/ic-plus-01.svg"
import Button from "components/commons/button"
import DeleteModal from "components/commons/delete-modal"
import Table from "components/commons/table"
import {CreatePersonalDocument} from "components/employee/create-modal/CreatePersonalDocument"
import {ModalDocument} from "../../../commons/modal-document/ModalDocument";

export const PersonalDocumentTab = ({
                                        configTable,
                                        optionsPersonalDocumentType,
                                        dataPersonalDocument,
                                        isFormValid,
                                        isEdit,
                                        form,
                                        onChangeForm,
                                        onSubmit,
                                        onActionRow,
                                        onDelete,
                                        isLoadingSubmit,
                                        isLoadingDelete,
                                        onUploadFile,
                                        modal,
                                        onChangeModal,
                                        file,
                                        selectedItem,
                                        onCloseDeleteAndPreviewModal
                                    }) => {

    return (
        <>
            <div className={"flex items-center justify-between"}>
                <p className={"text-neutral-80 font-semibold text-md"}>{'Dokumen Pribadi'}</p>
                <Button title={'Dokumen Pribadi'} icon={icPlus01} onClick={() => onChangeModal('form', true)}/>
            </div>
            <Table
                className="my-6"
                classNameHeader="!bg-primary-10 !text-md !text-primary-50"
                classNameRow="!py-6"
                name={configTable.name}
                columns={configTable.column}
                data={form}
                onClickItem={onActionRow}
            />

            <CreatePersonalDocument
                isFormValid={isFormValid}
                isEdit={isEdit}
                show={modal.form}
                onHide={() => onCloseDeleteAndPreviewModal('form')}
                optionsPersonalDocumentType={optionsPersonalDocumentType}
                dataPersonalDocument={dataPersonalDocument}
                onChangeForm={onChangeForm}
                onSubmit={onSubmit}
                isLoadingSubmit={isLoadingSubmit}
                onUploadFile={onUploadFile}
                file={file}
            />

            <DeleteModal
                text={"Apakah anda yakin ingin menghapus data dokumen pribadi?"}
                show={modal.delete}
                onHide={() => onCloseDeleteAndPreviewModal('delete')}
                onDelete={onDelete}
                isLoading={isLoadingDelete}
            />
            {modal.preview &&
                <ModalDocument onHide={() => onCloseDeleteAndPreviewModal('preview')} show={modal?.preview}
                               src={selectedItem?.url}/>}
        </>
    )
}
