import Search from "../../commons/search";
import Button from "../../commons/button";
import icFileUp from "../../../assets/images/ic-file-up-02.svg";
import icAddSquare from "../../../assets/images/id-add-square.svg";
import icEyeOpen from "../../../assets/images/ic-eye-open.svg";
import {ACTION_TYPE} from "../../../constants/constant";
import {ExportModal} from "../../commons/ExportModal";
import Table from "../../commons/table";
import {TABLE_JOB_TITLE_SETTING} from "../../../configs/organization-structure/configTableJobTitle";
import DeleteModal from "../../commons/delete-modal";
import {JobTitleSettingsFormContainer} from "../../../containers/organization-structure-management/job-title-settings/JobTitleSettingsFormContainer";
import Toast from "../../commons/toast";
import {JobTitleSettingsFilterContainer} from "../../../containers/organization-structure-management/job-title-settings/JobTitleSettingsFilterContainer";
import ViewList from "../../commons/view-list";
import Tooltip from "../../commons/tooltip";
import Modal from "../../commons/modal";

export const JobTitleSettings = (
    {
        dataList,
        modal,
        selectedIds,
        actionType,
        errorForm,
        updateId,
        jobTitleDetail,
        setJobTitleDetail,
        isLoading,
        isUpdating,
        isShowToast,
        toastMessage,
        queryFilter,
        query,
        isDeleting,
        fetchJobTitleList,
        onChangeQuery,
        onUpdateVisibility,
        setShowModal,
        setShowToast,
        onClickItemHandler,
        onClickButtonVisibility,
        validateForm,
        clearToast,
        onSelectItemHandler,
        onDelete,
        onExport,
        onSubmitFilter
    }
) => {
    return (
        <div className={"flex flex-col"}>
            <div className={"flex justify-between px-6 py-4"}>
                <div className={"flex space-x-7"}>
                    <Search value={query?.search} onSearch={(key) => onChangeQuery({search: key, page: 1})}/>
                    <div className={"flex gap-3"}>
                        <JobTitleSettingsFilterContainer
                            show={modal?.filter}
                            onShow={() => setShowModal({filter: true})}
                            onClose={() => setShowModal({filter: false})}
                            queryFilter={queryFilter}
                            onSubmit={onSubmitFilter}
                        />
                        <ViewList
                            show={modal?.limit}
                            onShow={() => setShowModal({ limit: true })}
                            onClose={() => setShowModal({ limit: false })}
                            value={query?.limit}
                            onSelectSize={(limit) => onChangeQuery({ page: 1, limit })}
                        />
                        <Tooltip
                            position="bottom"
                            content={<Button icon={icEyeOpen} type="outline" onClick={onClickButtonVisibility}/>}
                            text="Update Visibility"
                        />
                        <Button title="Export" icon={icFileUp} type="outline" disabled={!dataList?.total_data}
                                onClick={() => setShowModal({export: true})}/>
                    </div>
                </div>
                <Button title="Add Job Title" icon={icAddSquare}
                        onClick={() => onClickItemHandler({actionType: ACTION_TYPE.CREATE})}/>
            </div>
            <Table
                className="my-3"
                name={TABLE_JOB_TITLE_SETTING.name}
                columns={TABLE_JOB_TITLE_SETTING.column}
                data={dataList?.result}
                size={query?.limit}
                currentPage={query?.page}
                totalPage={dataList?.total_page}
                totalResult={dataList?.total_data}
                onSelectItem={(val) => onSelectItemHandler(val)}
                onChangePage={(val) => onChangeQuery({page: val})}
                onClickItem={(val) => onClickItemHandler(val)}
                loading={isLoading}
            />
            <DeleteModal
                show={modal?.delete}
                onHide={() => setShowModal({delete: false})}
                onDelete={onDelete}
                isLoading={isDeleting}
                text="Apakah anda yakin ingin menghapus data job title?"
            />
            {
                modal?.form &&
                <JobTitleSettingsFormContainer
                    modal={modal}
                    jobTitleDetail={jobTitleDetail}
                    actionType={actionType}
                    validateForm={validateForm}
                    errorForm={errorForm}
                    updateId={updateId}
                    setJobTitleDetail={setJobTitleDetail}
                    setShowModal={setShowModal}
                    setShowToast={setShowToast}
                    fetchJobTitleList={fetchJobTitleList}
                />
            }
            <ExportModal
                title="Apakah anda yakin ingin export data job title?"
                description={`Total data job title export: ${selectedIds?.length || dataList?.total_data}`}
                show={modal?.export}
                onExportHandler={onExport}
                onCancelHandler={() => setShowModal({export: false})}
            />
            {
                modal?.visibility &&
                <Modal
                    dialogClassName='w-full max-w-[328px]'
                    contentClassName='rounded-xl'
                    show={modal?.visibility}
                    onHide={() => setShowModal({visibility: false})}
                >
                    <div>
                        <div className={"py-8 px-4 flex flex-col justify-center items-center space-y-4"}>
                            <img src={icEyeOpen} alt="eye-open" className={"w-12 h-12 p-2 border rounded-full bg-primary-10 border-primary-30"}/>
                            <p className="text-center">Apakah anda yakin ingin melakukan update visibility ?</p>
                            <p className="text-center">{`Total data job title: ${selectedIds?.length}`}</p>
                        </div>
                        <div className={"px-6 py-3 flex flex-row border-t space-x-3 justify-center"}>
                            <Button type="outline" title="Cancel" onClick={() => setShowModal({visibility: false})}/>
                            <Button title="Submit" loading={isUpdating} onClick={onUpdateVisibility}/>
                        </div>
                    </div>
                </Modal>
            }
            <Toast
                show={isShowToast}
                message={toastMessage}
                onClose={() => clearToast()}
            />
        </div>
    )
}
