import { EducationInfoTab } from "components/employee/employee-detail/employee-detail-tab/EducationInfoTab";
import { TABLE_EDUCATION } from "configs/employee/configTableAddEmployee";
import { ACTION_TYPE } from "constants/constant";
import { DEFAULT_FORM_EDUCATION, GRADE_NO_MAJOR } from "constants/defaultFormEmployee";
import { useValidation } from "hooks/useValidation";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setToast, updateEducationInformation } from "reducers/employeeDetailReducer";
import { API_EMPLOYEE } from "services/apiEmployee";
import { METHOD } from "services/method";
import { useFetch } from "services/useFetch";

export const EducationInfoTabContainer = ({ optionsGrades }) => {
    const dispatch = useDispatch()
    const { id, educations } = useSelector(state => state.employeeDetailReducer)
    const [showModal, setShowModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [dataEducation, setDataEducation] = useState(DEFAULT_FORM_EDUCATION)
    const [selectedIndex, setSelectedIndex] = useState()
    const [actionType, setActionType] = useState(ACTION_TYPE.CREATE)
    const { fetching: addEducation, isLoading: isLoadingAdd } = useFetch({ ...API_EMPLOYEE.EDUCATION, initPathVariable: id })
    const { fetching: patchEducation, isLoading: isLoadingPatch } = useFetch({ ...API_EMPLOYEE.EDUCATION, initPathVariable: dataEducation?.id, method: METHOD.PUT })
    const { fetching: deleteEducation, isLoading: isLoadingDelete } = useFetch({ ...API_EMPLOYEE.EDUCATION, initPathVariable: dataEducation?.id, method: METHOD.DELETE })
    const { isFormValid } = useValidation({ form: dataEducation })
    const hasMajor = dataEducation?.grade?.value ? !GRADE_NO_MAJOR.includes(dataEducation?.grade?.value) : false

    const onChangeForm = (e) => {
        const { name, value } = e
        const noMajor = name === 'grade' && GRADE_NO_MAJOR.includes(value.value)
        setDataEducation({ ...dataEducation, [name]: value, ...(noMajor && { major: null }) })
    }

    const onCloseModal = () => {
        setShowModal(false)
        setDataEducation(DEFAULT_FORM_EDUCATION)
        setActionType(ACTION_TYPE.CREATE)
    }

    const onCloseDeleteModal = () => {
        setShowDeleteModal(false)
        setDataEducation(DEFAULT_FORM_EDUCATION)
    }

    const onSubmit = () => {
        const newData = [...educations]
        const paramsDataEducation = {
            ...dataEducation,
            grade: dataEducation?.grade?.value
        }

        const onSuccess = (message) => {
            dispatch(updateEducationInformation(newData))
            dispatch(setToast({isShowToast: true, message}))
            onCloseModal()
        }

        switch (actionType) {
            case ACTION_TYPE.CREATE:
                addEducation(paramsDataEducation).then((response) => {
                    newData.push(response.data)
                    onSuccess('Educational Info Created !!')
                })
                break;
            case ACTION_TYPE.EDIT:
                patchEducation(paramsDataEducation).then((response) => {
                    newData[selectedIndex] = response.data
                    onSuccess('Educational Info Updated !!')
                })
                break;
            default:
        }
    }

    const handleEdit = () => {
        setActionType(ACTION_TYPE.EDIT)
        setShowModal(true)
    }

    const handleRemove = (index) => {
        deleteEducation().then(() => {
            const newData = [...educations]
            newData.splice(index, 1)
            dispatch(updateEducationInformation(newData))
            dispatch(setToast({isShowToast: true, message: 'Educational Info Deleted !!'}))
            onCloseDeleteModal()
        })
    }

    const onActionRow = ({ index, item, actionType }) => {
        setSelectedIndex(index)
        setDataEducation({
            ...item,
            grade: { label: item.grade, value: item.grade, }
        })
        switch (actionType) {
            case ACTION_TYPE.EDIT:
                return handleEdit()
            case ACTION_TYPE.DELETE:
                return setShowDeleteModal(true)
            default:
        }
    }

    const checkValidationForm = () => {
        const checkForm = { ...dataEducation }
        if (!hasMajor) delete checkForm.major
        return isFormValid(checkForm)
    }

    const props = {
        configTable: TABLE_EDUCATION,
        optionsGrades,
        dataEducation,
        hasMajor,
        isFormValid: checkValidationForm(),
        isEdit: actionType === ACTION_TYPE.EDIT,
        form: educations,
        showModal,
        onShowModal: () => setShowModal(true),
        onCloseModal,
        onChangeForm,
        onSubmit,
        onActionRow,
        showDeleteModal,
        onCloseDeleteModal,
        onDelete: () => handleRemove(selectedIndex),
        isLoadingSubmit: isLoadingAdd || isLoadingPatch,
        isLoadingDelete
    }

    return (
        <EducationInfoTab {...props} />
    )
}
