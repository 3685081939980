import { METHOD } from "./method";

export const API_APP_VERSION = {
    DETAIL_VERSION: {
        api: '/v1/hr-adm/app-version',
        method: METHOD.GET
    },
    UPDATE_VERSION: {
        api: '/v1/hr-adm/app-version',
        method: METHOD.PUT
    },
}
