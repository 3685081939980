import { useEffect, useRef, useState } from 'react'

export const useExpandable = ({ verticalPadding, expanded = false }) => {
    const [isExpanded, setExpanded] = useState(expanded)
    const elementRef = useRef(null)
    const timerRef = useRef(null)
    const timerExpandRef = useRef(null)
    const resizeObserverRef = useRef(null)
    const DELAY = 300

    useEffect(() => {
        const setMaxHeight = () => {
            const currentElement = elementRef.current

            if (currentElement) {
                const paddingTop = isExpanded ? `${verticalPadding}px` : '0'
                const paddingBottom = isExpanded ? `${verticalPadding}px` : '0'
                const scrollHeightWithPadding = currentElement.scrollHeight + verticalPadding * 2

                currentElement.style.paddingTop = paddingTop
                currentElement.style.paddingBottom = paddingBottom
                currentElement.style.maxHeight = isExpanded ? `${scrollHeightWithPadding}px` : '0'

                if (timerRef.current) {
                    clearTimeout(timerRef.current)
                    timerRef.current = null;
                }

                if (isExpanded) {
                    timerRef.current = setTimeout(() => {
                        currentElement.style.overflow = ''
                    }, DELAY)
                } else {
                    currentElement.style.overflow = 'hidden'
                }
            }
        }

        setMaxHeight()

        if (resizeObserverRef.current) {
            const currentElement = elementRef.current
            if (currentElement) {
                resizeObserverRef.current.unobserve(currentElement)
            }
            resizeObserverRef.current.disconnect()
        }

        if (isExpanded) {
            const currentElement = elementRef.current
            if (currentElement) {
                timerExpandRef.current = setTimeout(() => {
                    const handleResize = () => {
                        setMaxHeight()
                    }

                    resizeObserverRef.current = new ResizeObserver(handleResize)
                    resizeObserverRef.current.observe(currentElement)
                }, DELAY)
            }
        }

        return () => {
            const currentElement = elementRef.current

            if (resizeObserverRef.current && currentElement) {
                resizeObserverRef.current.unobserve(currentElement)
                resizeObserverRef.current.disconnect()
            }

            if (timerExpandRef.current) clearTimeout(timerExpandRef.current)
        }
    }, [isExpanded, verticalPadding])

    const toggleExpand = () => {
        setExpanded(!isExpanded)
    }

    return { isExpanded, elementRef, toggleExpand }
}
