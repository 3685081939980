import Button from "../../components/commons/button";
import icEdit04Primary from "assets/images/ic-edit-04-primary.svg";
import icTrash01Red from "assets/images/ic-trash-01-red.svg";
import {ACTION_TYPE} from "../../constants/constant";
import moment from "moment";
import {formatDateMoment} from "../../utils/utils";

export const TABLE_DAY_OFF_LIST_SETTING = {
    name: 'Total Day Off',
    column: [
        {
            name: "Tanggal Libur",
            key: "date",
            className: "font-bold capitalize",
            render: ({value}) => <div>{formatDateMoment(value, 'D MMMM YYYY')}</div>
        },
        {
            name: "Keterangan Hari Libur",
            key: "description",
            className: "font-bold capitalize",
            sortable: true
        },
        {
            name: "Action",
            key: "action",
            classNameHeader: "!text-center",
            render: ({ item, onClickItem }) => (
                <div className="flex space-x-3 justify-center">
                    <Button
                        type="outline"
                        icon={icEdit04Primary}
                        disabled={moment(item?.date).isBefore(moment(), "day")}
                        onClick={() => onClickItem({item, actionType: ACTION_TYPE.EDIT})}
                    />
                    <Button
                        type="danger"
                        icon={icTrash01Red}
                        disabled={moment(item?.date).isBefore(moment(), "day")}
                        onClick={() => onClickItem({id: item?.id, actionType: ACTION_TYPE.DELETE})}
                    />
                </div>
            )
        }
    ]
}

export const INITIAL_DATA_FILTER = {
    search: '',
    page: 1,
    limit: 20,
    year: moment().year(),
    order_by: 'date',
    order_type: 'asc'
}
