import icFileEdit01 from "assets/images/ic-file-edit-02.svg"
import icPlus from "assets/images/ic-plus-01-primary.svg"
import icTrash from "assets/images/ic-trash-04.svg"
import icProfilePicture from "assets/images/ic-user-profile-03.svg"
import clsx from "clsx"
import Button from "components/commons/button"
import {DetailItems} from "components/commons/detail-items"
import ErrorMessage from "components/commons/error-message"
import FormModal from "components/commons/form-modal"
import Input from "components/commons/input"

export const PersonalInfoTab = ({
                                    personalInfoConstList,
                                    errorForm,
                                    profilePicture,
                                    dataPersonal,
                                    personalDetail,
                                    showModal,
                                    onShowModal,
                                    onCloseModal,
                                    onChangeForm,
                                    onSubmit,
                                    isLoadingSubmit,
                                    onUploadImage,
                                    onDeletePicture,
                                }) => {
    return (
        <>
            <div className={"flex items-center justify-between"}>
                <p className={"text-neutral-80 font-semibold text-md"}>{'Informasi Pribadi'}</p>
                <Button title={'Edit'} type={'outline'} icon={icFileEdit01} onClick={onShowModal}/>
            </div>

            <div className={"pt-6 space-y-5"}>
                {personalInfoConstList.map((item, index) =>
                    <DetailItems key={index} label={item.label} value={personalDetail[item.key]}/>
                )}
            </div>

            <FormModal
                show={showModal}
                onHide={onCloseModal}
                contentClassName="overflow-auto"
                title={'Edit Profile'}
                onSubmitForm={onSubmit}
                isLoading={isLoadingSubmit}
            >
                <div className={"px-8 py-6 mb-2 space-y-6"}>
                    <div>
                        <div className="font-medium text-md text-neutral-70 mb-5">{'Photo Profile'}</div>
                        <div className="flex gap-5">
                            <div
                                className="w-[72px] h-[72px] flex items-center justify-center overflow-hidden rounded-full border bg-neutral-20">
                                <img
                                    className={clsx("aspect-square", profilePicture?.base64 || dataPersonal?.profile_picture ? 'w-full' : 'w-9 h-9')}
                                    src={profilePicture?.base64 || dataPersonal?.profile_picture || icProfilePicture}
                                    alt='profile'/>
                            </div>
                            <div
                                className={clsx("flex flex-col", profilePicture?.name ? "justify-between" : "justify-center")}>
                                <span className="text-md">{profilePicture?.name}</span>
                                <input className={'hidden'} accept=".jpg,.jpeg,.png" id='profile-picture' type={'file'}
                                       onChange={onUploadImage}/>
                                <div className="flex gap-2">
                                    {profilePicture?.base64 || dataPersonal?.profile_picture ?
                                        <label className="avo__button outline cursor-pointer" type="button"
                                               htmlFor={'profile-picture'}>
                                            <div className="btn-content"> {'Ubah'} </div>
                                        </label>
                                        :
                                        <label className="avo__button outline cursor-pointer" type="button"
                                               htmlFor={'profile-picture'}>
                                            <div className="btn-content">
                                                <img src={icPlus} alt='ubah'/>
                                                <span>{'Upload Photo'}</span>
                                            </div>
                                        </label>
                                    }
                                    {(profilePicture?.base64 || dataPersonal?.profile_picture) &&
                                        <Button className="w-[100px]" type="outline" title="Hapus" icon={icTrash}
                                                onClick={onDeletePicture}/>}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="flex gap-3">
                        <div className="w-1/3 text-neutral-70 required mt-2">{'Nama'}</div>
                        <div className="flex-1">
                            <Input name="full_name" value={dataPersonal?.full_name} maxLength={60}
                                   pattern={/^[A-Za-z' ]+$/} onChange={onChangeForm}/>
                            <ErrorMessage className="mt-2" message={errorForm?.full_name}/>
                        </div>
                    </div>
                    <div className="flex gap-3">
                        <div className="w-1/3 text-neutral-70 required mt-2">{'Username'}</div>
                        <div className="flex-1">
                            <Input name="username" value={dataPersonal?.username} maxLength={60}
                                   onChange={onChangeForm}/>
                            <ErrorMessage className="mt-2" message={errorForm?.username}/>
                        </div>
                    </div>
                    <div className="flex gap-3">
                        <div className="w-1/3 text-neutral-70 required mt-2">{'NIK'}</div>
                        <div className="flex-1">
                            <Input name="id_card_number" type="number" value={dataPersonal?.id_card_number}
                                   maxLength={16} onChange={onChangeForm}/>
                            <ErrorMessage className="mt-2" message={errorForm?.id_card_number}/>
                        </div>
                    </div>
                    <div className="flex gap-3">
                        <div className="w-1/3 text-neutral-70 mt-2">{'No. KK'}</div>
                        <div className="flex-1">
                            <Input name="family_id_card_number" type="number"
                                   value={dataPersonal?.family_id_card_number} maxLength={16} onChange={onChangeForm}/>
                            <ErrorMessage className="mt-2" message={errorForm?.family_id_card_number}/>
                        </div>
                    </div>
                    <div className="flex gap-3">
                        <div className="w-1/3 text-neutral-70 required mt-2">{'No. Hp'}</div>
                        <div className="flex-1">
                            <Input addonBefore={'+62'} name="phone_number" type="tel" value={dataPersonal?.phone_number}
                                   maxLength={14} onChange={onChangeForm}/>
                            <ErrorMessage className="mt-2" message={errorForm?.phone_number}/>
                        </div>
                    </div>
                    <div className="flex items-center gap-3">
                        <div className="w-1/3 text-neutral-70">{'No. Paspor'}</div>
                        <div className="flex-1">
                            <Input name="passport_number" value={dataPersonal?.passport_number}
                                   maxLength={30} onChange={onChangeForm}/>
                            <ErrorMessage className="mt-2" message={errorForm?.passport_number}/>
                        </div>
                    </div>
                    <div className="flex gap-3">
                        <div className="w-1/3 text-neutral-70 required mt-2">{'Email Pribadi'}</div>
                        <div className="flex-1">
                            <Input name="personal_email" value={dataPersonal?.personal_email} onChange={onChangeForm}/>
                            <ErrorMessage className="mt-2" message={errorForm?.personal_email}/>
                        </div>
                    </div>
                    <div className="flex items-center gap-3">
                        <div className="w-1/3 text-neutral-70 mt-2">{'Email Kantor'}</div>
                        <div className="flex-1">
                            <Input className="flex-1" name="office_email" value={dataPersonal?.office_email}
                                   onChange={onChangeForm}/>
                            <ErrorMessage className="mt-2" message={errorForm?.office_email}/>
                        </div>
                    </div>
                </div>
            </FormModal>
        </>
    )
}
