import { useState } from "react";

export const useValidation = ({ form, schema } = {}) => {
    const [errorForm, setErrorForm] = useState({})
    const NOT_FOUND = 'object not found'

    function findValueByKey(obj, key) {
        if (!obj || typeof obj !== 'object') return NOT_FOUND
        if (obj.hasOwnProperty(key)) return obj[key]

        for (const prop in obj) {
            if (obj.hasOwnProperty(prop) && typeof obj[prop] === 'object') {
                const nestedValue = findValueByKey(obj[prop], key);
                if (nestedValue !== NOT_FOUND) {
                    return nestedValue;
                }
            }
        }
        return NOT_FOUND;
    }

    function validateForm(formValidate = form) {
        return new Promise((resolve, reject) => {
            const error = {};
            for (const key in schema) {
                const value = findValueByKey(formValidate, key)
                const checkRule = () => {
                    for (const rule in schema[key]) {
                        switch (rule) {
                            case 'required':
                                if (!value || value?.length === 0) {
                                    error[key] = schema[key][rule]
                                    return
                                }
                                break
                            case 'maximum':
                                if (value?.length > schema[key][rule].value) {
                                    error[key] = schema[key][rule].message
                                    return
                                }
                                break
                            case 'minimum':
                                if (value?.length > 0 && value?.length < schema[key][rule].value) {
                                    error[key] = schema[key][rule].message
                                    return
                                }
                                break
                            case 'format':
                                const regex = new RegExp(schema[key][rule].pattern)
                                if (value && !regex.test(value)) {
                                    error[key] = schema[key][rule].message || `${key} is invalid`
                                    return
                                }
                                break
                            default:
                                return
                        }
                    }
                }
                if (value !== NOT_FOUND) checkRule()
            }
            setErrorForm({ ...error })
            const hasErrors = Object.keys(error).length > 0
            if (!hasErrors) {
                resolve()
            }
        })
    }

    function isFormValid(formValidate = form) {
        for (const key in formValidate) {
            const value = formValidate[key];
            if (value === null || value === undefined || value === '') return false
            if (Array.isArray(value) && value.length === 0) return false
        }

        return true;
    }

    function clearErrorForm() {
        setErrorForm({})
    }

    return { errorForm, validateForm, isFormValid, clearErrorForm }
}
