import Button from "./button";
import Modal from "./modal";

export const StandardModal = ({show, onHide, text, icon, isLoading, onSubmit}) => {
    return (
        <Modal
            dialogClassName='w-full max-w-[328px]'
            contentClassName='rounded-xl'
            show={show}
            onHide={onHide}
        >
            <div>
                <div className={"py-8 px-4 flex flex-col justify-center items-center space-y-4"}>
                    <img src={icon} alt={'trash'}
                         className={"w-12 h-12 p-2 border rounded-full bg-primary-10 border-primary-30"}/>
                    <div className={"text-center"}>{text}</div>
                </div>
                <div className={"px-6 py-3 flex flex-row border-t space-x-3 justify-center"}>
                    <Button type={'outline'} title={'Cancel'} onClick={onHide}/>
                    <Button title={'Lanjutkan'} loading={isLoading} onClick={onSubmit}/>
                </div>
            </div>
        </Modal>
    )
}
