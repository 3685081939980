import icBulkUpdate from "assets/images/ic-bulk-update.svg"
import icFileUp from "assets/images/ic-file-up-02.svg"
import icChevDown from "assets/images/ic-chev-down.svg"
import clsx from "clsx"
import {ExportModal} from "components/commons/ExportModal"
import {InlineTabs} from "components/commons/InlineTabs"
import Button from "components/commons/button"
import Search from "components/commons/search"
import Table from "components/commons/table"
import Toast from "components/commons/toast"
import ViewList from "components/commons/view-list"
import {RequestLeaveFilterContainer} from "containers/request-leave/RequestLeaveFilterContainer"
import {useClickOutside} from "hooks/useClickOutside"
import {useState} from "react"
import {ModalDocument} from "../commons/modal-document/ModalDocument";
import {ModalApproval} from "../commons/modal-approval/ModalApproval";
import {PreviewLeave} from "./PreviewLeave";

export const RequestLeave = (
    {
        tabList,
        dataEmployee,
        dataPreview,
        status,
        optionBulk,
        configTable,
        previewTab,
        isLoading,
        query,
        activePreviewTab,
        setActivePreviewTab,
        selectedEmployeeList,
        selectedBulkOption,
        modal,
        queryFilter,
        toastMessage,
        rejectReason,
        setRejectReason,
        onChangeTab,
        onChangeQuery,
        onSelectItem,
        onExport,
        onBulkUpdate,
        onPreview,
        onSetModal,
        onCloseUpdateRequestModal,
        onUpdateStatusRequest,
        onPreviewFile,
        selectedPreviewFile
    }) => {
    const elementID = 'dropdownBulkUpdate'
    const [showSetting, setShowSetting] = useState(false)
    const showBulkStatus = [tabList[0].id, tabList[1].id]
    const {APPROVE, ...optionBulkApproved} = optionBulk
    const optionBulkFilter = status === tabList[1].id ? optionBulkApproved : optionBulk
    useClickOutside({callback: () => showSetting && setShowSetting(false), elementID})

    const onSelect = (e) => {
        setShowSetting(false)
        onBulkUpdate(e)
    }

    return (
        <>
            <InlineTabs tabList={tabList} activeTab={status} onClickTab={onChangeTab}/>
            <div className={"flex flex-row justify-between gap-3 px-6 py-4"}>
                <div className={"flex flex-row gap-3"}>
                    <Search value={query?.search} onSearch={(search) => onChangeQuery({page: 1, search})}/>
                    <RequestLeaveFilterContainer
                        show={modal.filter}
                        onShow={() => onSetModal({filter: true})}
                        onClose={() => onSetModal({filter: false})}
                        queryFilter={queryFilter}
                        onSubmit={(queryFilter) => onChangeQuery({...queryFilter, page: 1})}
                    />
                    <ViewList
                        show={modal?.limit}
                        onShow={() => onSetModal({limit: true})}
                        onClose={() => onSetModal({limit: false})}
                        value={query?.limit}
                        onSelectSize={(limit) => onChangeQuery({page: 1, limit})}
                    />
                    <Button title="Export" icon={icFileUp} type="outline" disabled={!dataEmployee?.total_result}
                            onClick={() => onSetModal({export: true})}/>
                </div>
                {
                    showBulkStatus.includes(status) &&
                    <div className="relative">
                        <Button title="Bulk Update" type="outline" disabled={!dataEmployee?.total_result} icon={icBulkUpdate} iconAfter={icChevDown}
                                onClick={() => setShowSetting(!showSetting)}/>
                        <div
                            id={elementID}
                            className={clsx(
                                "absolute bg-white right-0 mt-1 p-1.5 border border-neutral-20 rounded-lg shadow-sm whitespace-nowrap transition-all duration-200 w-[180px] z-10",
                                showSetting ? 'opacity-100 visible' : 'opacity-0 collapse'
                            )}
                        >
                            {
                                Object.entries(optionBulkFilter).map(([key, value]) => (
                                <div key={key} className="hover:bg-primary-10 capitalize px-4 py-2.5 rounded-lg cursor-pointer transition-all duration-200"
                                     onClick={() => onSelect(value)}>
                                    {value}
                                </div>
                                ))
                            }
                        </div>
                    </div>
                }
            </div>
            <Table
                className="my-3"
                name={configTable.name}
                data={dataEmployee?.result}
                columns={configTable.column}
                size={dataEmployee?.limit}
                totalPage={dataEmployee?.total_page}
                totalResult={dataEmployee?.total_result}
                currentPage={query.page}
                onChangePage={(page) => onChangeQuery({page})}
                onSelectItem={onSelectItem}
                onClickItem={(item) => onPreview(item)}
                onClickSort={(sortQuery) => onChangeQuery({...sortQuery, page: 1})}
                loading={isLoading}
            />
            {
                modal?.preview &&
                <PreviewLeave
                    visibility={modal?.preview}
                    tabList={tabList}
                    dataPreview={dataPreview}
                    previewTab={previewTab}
                    optionBulk={optionBulk}
                    activePreviewTab={activePreviewTab}
                    onChangePreviewTab={setActivePreviewTab}
                    showBulkStatus={showBulkStatus}
                    onHide={onSetModal}
                    onPreviewFile={onPreviewFile}
                    onSelect={onSelect}
                />
            }
            {
                modal.previewFile &&
                <ModalDocument
                    onHide={() => onSetModal({previewFile: false})}
                    show={modal?.previewFile}
                    src={selectedPreviewFile}
                />
            }
            {
                modal?.bulk_update &&
                <ModalApproval
                    id={dataPreview?.id}
                    title={'request cuti'}
                    modal={modal}
                    setModal={onCloseUpdateRequestModal}
                    status={selectedBulkOption}
                    optionBulk={optionBulk}
                    selectedLength={selectedEmployeeList?.length}
                    rejectReason={rejectReason}
                    setRejectReason={setRejectReason}
                    onUpdateStatusRequest={onUpdateStatusRequest}
                />
            }
            <ExportModal
                title="Apakah anda yakin ingin melakukan export data?"
                description={`Total data list cuti ${status}: ${selectedEmployeeList?.length || dataEmployee?.total_result || 0}`}
                show={modal?.export}
                onExportHandler={onExport}
                onCancelHandler={() => onSetModal({ export: false })}
            />
            <Toast
                show={modal?.toast}
                message={toastMessage}
                onClose={() => onSetModal({ toast: false })}
            />
        </>
    )
}
