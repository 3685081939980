import { createSlice } from "@reduxjs/toolkit"
import { clearPhoneNumber } from "utils/utils"

const initialState = {
    full_name: null,
    username: null,
    personal_email: null,
    office_email: null,
    phone_number: null,
    profile_picture: null,
    job_title_employees: [],
    personal_detail: {
        gender: null,
        religion: null,
        birthplace: null,
        birthdate: null,
        marital_status: null,
        height: null,
        weight: null,
        hobby: null,
        blood_type: null,
        residence_province: null,
        residence_city: null,
        residence_district: null,
        residence_postal_code: null,
        residence_address: null,
        id_card_province: null,
        id_card_city: null,
        id_card_district: null,
        id_card_postal_code: null,
        id_card_address: null,
        bank_name: null,
        bank_code: null,
        bank_account_number: null,
        bank_account_name: null,
    },
    card_detail: {
        id_card_number: null,
        family_id_card_number: null,
        passport_number: null,
        tax_id_number: null,
        bpjsk_number: null,
        bpjstk_number: null,
        mandiri_inhealth_number: null,
    },
    families: [],
    emergency_contacts: [],
    educations: [],
    work_experiences: [],
    personal_documents: []
}

export const addEmployeeSlice = createSlice({
    name: 'add-employee',
    initialState: initialState,
    reducers: {
        clearAddEmployeeReducer: () => initialState,
        setPersonalInformation: (state, action) => {
            switch (action.payload.name) {
                case "id_card_number":
                case "family_id_card_number":
                case "passport_number":
                    return {
                        ...state,
                        card_detail: {
                            ...state.card_detail,
                            [action.payload.name]: action.payload.value
                        }
                    }
                default:
                    return {
                        ...state,
                        [action.payload.name]: action.payload.value
                    }
            }
        },
        setPersonalDetail: (state, action) => {
            switch (action.payload.name) {
                case "bank_name":
                    return {
                        ...state,
                        personal_detail: {
                            ...state.personal_detail,
                            bank_account_name: null,
                            bank_code: action.payload.value.value,
                            [action.payload.name]: action.payload.value.label,
                        }
                    }
                case "bank_account_number":
                    return {
                        ...state,
                        personal_detail: {
                            ...state.personal_detail,
                            bank_account_name: null,
                            [action.payload.name]: action.payload.value,
                        }
                    }
                case "same_as_address":
                    return {
                        ...state,
                        personal_detail: {
                            ...state.personal_detail,
                            residence_province: action.payload.value ? state.personal_detail.id_card_province : null,
                            residence_city: action.payload.value ? state.personal_detail.id_card_city : null,
                            residence_district: action.payload.value ? state.personal_detail.id_card_district : null,
                            residence_postal_code: action.payload.value ? state.personal_detail.id_card_postal_code : null,
                            residence_address: action.payload.value ? state.personal_detail.id_card_address : null,
                        }
                    }
                case "main":
                case "residence":
                    const splitArea = action.payload.value.split(/,\s/g)
                    const prefix = action.payload.name === 'main' ? 'id_card' : 'residence'
                    const isSameAsAddress = action.payload.name === 'main' && action.payload.same_as_address
                    return {
                        ...state,
                        personal_detail: {
                            ...state.personal_detail,
                            [`${prefix}_district`]: splitArea[0],
                            [`${prefix}_city`]: splitArea[1],
                            [`${prefix}_province`]: splitArea[2],
                            [`${prefix}_postal_code`]: null,
                            ...(isSameAsAddress && {
                                residence_district: splitArea[0],
                                residence_city: splitArea[1],
                                residence_province: splitArea[2],
                                residence_postal_code: null,
                            })
                        }
                    }
                case "id_card_postal_code":
                case "id_card_address":
                    return {
                        ...state,
                        personal_detail: {
                            ...state.personal_detail,
                            [action.payload.name]: action.payload.value,
                            ...(action.payload.same_as_address && {
                                [action.payload.name === 'id_card_postal_code' ? 'residence_postal_code' : 'residence_address']: action.payload.value
                            }),
                        }
                    }
                default:
                    return {
                        ...state,
                        personal_detail: {
                            ...state.personal_detail,
                            [action.payload.name]: action.payload.value
                        }
                    }
            }
        },
        setNPWPInformation: (state, action) => ({
            ...state,
            card_detail: {
                ...state.card_detail,
                [action.payload.name]: action.payload.value
            }
        }),
        setJobTitle: (state, action) => ({
            ...state,
            job_title_employees: [...action.payload]
        }),
        setFamilyInformation: (state, action) => ({
            ...state,
            families: [...action.payload]
        }),
        setEmergencyContact: (state, action) => ({
            ...state,
            emergency_contacts: [...action.payload]
        }),
        setEducationInformation: (state, action) => ({
            ...state,
            educations: [...action.payload]
        }),
        setWorkExperience: (state, action) => ({
            ...state,
            work_experiences: [...action.payload]
        }),
        setPersonalDocument: (state, action) => ({
            ...state,
            personal_documents: [...action.payload]
        }),
        setDraftEmployee: (state, action) => ({
            ...state,
            full_name: action.payload.personal_detail.full_name,
            username: action.payload.personal_detail.username,
            personal_email: action.payload.personal_detail.personal_email,
            office_email: action.payload.personal_detail.office_email,
            phone_number: clearPhoneNumber(action.payload.personal_detail.phone_number),
            profile_picture: action.payload.personal_detail.profile_picture,
            job_title_employees: [...action.payload.job_title_employees],
            personal_detail: {
                gender: action.payload.additional_info.gender,
                religion: action.payload.additional_info.religion,
                birthplace: action.payload.additional_info.birthplace,
                birthdate: action.payload.additional_info.birthdate,
                marital_status: action.payload.additional_info.marital_status,
                height: action.payload.additional_info.height,
                weight: action.payload.additional_info.weight,
                hobby: action.payload.additional_info.hobby,
                blood_type: action.payload.additional_info.blood_type,
                residence_province: action.payload.additional_info.residence_province,
                residence_city: action.payload.additional_info.residence_city,
                residence_district: action.payload.additional_info.residence_district,
                residence_postal_code: action.payload.additional_info.residence_postal_code,
                residence_address: action.payload.additional_info.residence_address,
                id_card_province: action.payload.additional_info.id_card_province,
                id_card_city: action.payload.additional_info.id_card_city,
                id_card_district: action.payload.additional_info.id_card_district,
                id_card_postal_code: action.payload.additional_info.id_card_postal_code,
                id_card_address: action.payload.additional_info.id_card_address,
                bank_name: action.payload.bank_detail.bank_name,
                bank_code: action.payload.bank_detail.bank_code,
                bank_account_number: action.payload.bank_detail.bank_account_number,
                bank_account_name: action.payload.bank_detail.bank_account_name,
            },
            card_detail: {
                id_card_number: action.payload.personal_detail.id_card_number,
                family_id_card_number: action.payload.personal_detail.family_id_card_number,
                passport_number: action.payload.personal_detail.passport_number,
                tax_id_number: action.payload.card_detail.tax_id_number,
                bpjsk_number: action.payload.card_detail.bpjsk_number,
                bpjstk_number: action.payload.card_detail.bpjstk_number,
                mandiri_inhealth_number: action.payload.card_detail.mandiri_inhealth_number,
            },
            families: [...action.payload.families],
            emergency_contacts: [...action.payload.emergency_contacts],
            educations: [...action.payload.educations],
            work_experiences: [...action.payload.work_experiences],
            personal_documents: [...action.payload.personal_documents]
        })
    },
})

export const {
    clearAddEmployeeReducer,
    setPersonalInformation,
    setPersonalDetail,
    setNPWPInformation,
    setJobTitle,
    setFamilyInformation,
    setEmergencyContact,
    setEducationInformation,
    setWorkExperience,
    setPersonalDocument,
    setDraftEmployee,
} = addEmployeeSlice.actions

const addEmployeeReducer = addEmployeeSlice.reducer
export default addEmployeeReducer