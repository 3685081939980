import {ShiftingDetail} from "../../../components/request-shifting/shifting-detail/ShiftingDetail";
import {useEffect, useState} from "react";
import {useLocation, useSearchParams} from "react-router-dom";
import {downloadFile, getAllQueryParams, setQueryParams} from "../../../utils/utils";
import {INITIAL_DATA_FILTER_DETAIL_SHIFTING_LIST} from "../../../configs/request-shifting/configTableShiftingList";
import {ACTION_TYPE} from "../../../constants/constant";
import {useValidation} from "../../../hooks/useValidation";
import moment from "moment/moment";
import {useFetch} from "../../../services/useFetch";
import {API_REQUEST_SHIFTING} from "../../../services/apiRequestShifting";
import {Alert} from "../../../components/commons/alert";

const INITIAL_STATE = {
    date: null,
    clock_in_time: null,
    clock_out_time: null
}

export const ShiftingDetailContainer = () => {
    const location = useLocation()
    const [searchParams, setSearchParams] = useSearchParams()
    const userId = searchParams.get('userId')
    const {data: detailShiftingList, fetching: getDetailShiftingList, isLoading: isLoadingDetailList} = useFetch({...API_REQUEST_SHIFTING.DETAIL_SHIFTING_LIST, initPathVariable: userId}, INITIAL_DATA_FILTER_DETAIL_SHIFTING_LIST)
    const {data: detailShifting, isLoading: isLoadingDetail } = useFetch({...API_REQUEST_SHIFTING.DETAIL_SHIFTING, initPathVariable: userId}, {}, {isFetching: true})
    const {fetching: createShifting, isLoading: isCreating} = useFetch({...API_REQUEST_SHIFTING.CREATE_SHIFTING, initPathVariable: userId})
    const {fetching: updateShifting, isLoading: isUpdating} = useFetch({...API_REQUEST_SHIFTING.UPDATE_SHIFTING, initPathVariable: userId})
    const {fetching: deleteShifting, isLoading: isDeleting} = useFetch(API_REQUEST_SHIFTING.DELETE_SHIFTING)
    const {fetching: exportShifting} = useFetch({...API_REQUEST_SHIFTING.EXPORT_SHIFTING, initPathVariable: userId})
    const [shiftingDetail, setShiftingDetail] = useState(INITIAL_STATE)
    const [isShowToast, setIsShowToast] = useState(false)
    const [toastMessage, setToastMessage] = useState('')
    const [deleteId, setDeleteId] = useState(null)
    const [actionType, setActionType] = useState(ACTION_TYPE.CREATE)
    const {isFormValid} = useValidation({form: shiftingDetail})
    const [errorForm, setErrorForm] = useState(null)
    const query = searchParams.size > 0 ? getAllQueryParams(searchParams) : INITIAL_DATA_FILTER_DETAIL_SHIFTING_LIST
    const [modal, setModal] = useState({
        delete: false,
        export: false,
        form: false
    })

    useEffect(() => {
        const {userId, ...queryParams} = query
        fetchDetailShiftingList({...queryParams})
    }, [location?.search])

    const clearToast = () => {
        setIsShowToast(false)
        setToastMessage('')
    }

    const setShowModal = (item) => {
        setModal({...modal, ...item})
    }

    const setShowToast = (message, itemModal) => {
        setShowModal(itemModal)
        setIsShowToast(true)
        setToastMessage(message)
    }

    const onChangeQuery = (queryParams) => {
        const params = setQueryParams({...query, ...queryParams})
        setSearchParams(params, {replace: true})
    }

    const fetchDetailShiftingList = (item = query) => {
        getDetailShiftingList(item)
    }

    const onClickItemHandler = ({id, item, actionType}) => {
        switch (actionType) {
            case ACTION_TYPE.CREATE:
                setShiftingDetail(INITIAL_STATE)
                setActionType(ACTION_TYPE.CREATE)
                setShowModal({form: true})
                setErrorForm(null)
                break
            case ACTION_TYPE.EDIT:
                setShiftingDetail(item)
                setActionType(ACTION_TYPE.EDIT)
                setShowModal({form: true})
                setErrorForm(null)
                break
            default:
                setDeleteId(id)
                setShowModal({delete: true})
        }
    }

    const onDelete = () => {
        deleteShifting({}, deleteId)
            .then(() => {
                setShowToast('Shifting Deleted !!', {delete: false})
                if (detailShiftingList?.data?.result?.length === 1 && query?.page !== 1) {
                    onChangeQuery({page: 1})
                } else {
                    fetchDetailShiftingList()
                }
            })
    }

    const onExport = () => {
        const payload = {date: query.date || moment().format('YYYY-MM')}
        exportShifting(payload, '', {}, 'blob')
            .then(response => {
                downloadFile(null, response, `Shifting-${userId}-Export-${moment().format('DD-MM-YYYY')}.xlsx`)
                setShowToast('Shifting Exported', {export: false})
            })
    }

    const onImport = () => {
        window.open('/shifting/import-shifting', '_blank', 'noopener,noreferrer')
    }

    const onChangeValue = (item, name) => {
        const newData = name ? {[name]: item} : {[item?.name]: item?.value}
        setShiftingDetail({...shiftingDetail, ...newData})
    }

    const validateForm = () => {
        return new Promise((resolve, reject) => {
            let error = ''
            const isInvalidTime = moment(shiftingDetail?.clock_out_time, 'hh:mm').isSameOrBefore(moment(shiftingDetail?.clock_in_time, 'hh:mm'))
            if (isInvalidTime) error = `Waktu clock out tidak boleh kurang atau sama dengan waktu clock in (${shiftingDetail?.clock_in_time})`
            setErrorForm(error)
            if (!error) resolve()
        })
    }

    const errorMessage = (data = []) => {
        let message = ''
        data?.forEach(item => {
            message += `\n${item?.message}\n`
        })

        return message
    }

    const onSubmitForm = () => {
        validateForm().then(() => {
            const {id, ...payload} = shiftingDetail
            if (actionType === ACTION_TYPE.CREATE) {
                createShifting(payload)
                    .then(() => {
                        setShowToast('Shifting Created !!', {form: false})
                        fetchDetailShiftingList()
                    })
            } else {
                updateShifting({...payload, employee_shifting_id: id})
                    .then(() => {
                        setShowToast('Shifting Updated !!', {form: false})
                        fetchDetailShiftingList()
                    }).catch(err => {
                    if (err?.code === '422') {
                        Alert({
                            icon: 'error',
                            title: err?.message,
                            message: errorMessage(err?.data),
                        })
                    }
                })
            }
        })
    }

    const props = {
        dataDetail: detailShifting?.data,
        dataDetailList: detailShiftingList?.data,
        query,
        modal,
        isLoadingDetail,
        isLoadingDetailList,
        actionType,
        isShowToast,
        toastMessage,
        shiftingDetail,
        isCreating,
        isUpdating,
        isDeleting,
        errorForm,
        isFormValid,
        onChangeQuery,
        onChangeValue,
        onClickItemHandler,
        setShowModal,
        onDelete,
        onExport,
        onImport,
        clearToast,
        onSubmitForm
    }

    return (
        <ShiftingDetail {...props}/>
    )
}
