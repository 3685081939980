import icAccountInformation from "assets/images/ic-card-02.svg"
import Button from "components/commons/button"
import ErrorMessage from "components/commons/error-message"
import Input from "components/commons/input"
import Select from "components/commons/select"
import { useExpandable } from "hooks/useExpandable"
import { FormHeader } from "./FormHeader"

export const AccountInformation = ({
    canValidate,
    optionsBank,
    selectedBank,
    form,
    errorForm,
    onChangeForm,
    onValidateAccount
}) => {
    const { isExpanded, elementRef, toggleExpand } = useExpandable({ verticalPadding: 24 })

    return (
        <div className="bg-white border-y border-neutral-20">
            <FormHeader title="Informasi Rekening" icon={icAccountInformation} expanded={isExpanded} onClick={toggleExpand} errorMessage={errorForm?.bank_name} />
            <div ref={elementRef} className="expandable px-8">
                <div className="grid gap-5 lg:grid-cols-2 lg:gap-x-16">
                    <div className="space-y-5">
                        <div className="flex gap-3">
                            <div className="w-1/3 text-neutral-70 required mt-2">{'Pilih Bank'}</div>
                            <div className="flex-1">
                                <Select
                                    name="bank_name"
                                    className='flex-1'
                                    optionsClassName='max-h-[300px]'
                                    placeholder="Nama bank"
                                    options={optionsBank}
                                    value={selectedBank}
                                    onChange={onChangeForm}
                                    searchable
                                />
                                <ErrorMessage className="mt-2" message={errorForm?.bank_name} />
                            </div>
                        </div>
                        <div className="flex gap-3">
                            <div className="w-1/3 text-neutral-70 required mt-2">{'Nomor Rekening'}</div>
                            <div className="flex-1">
                                <div className="flex items-center gap-3">
                                    <Input className="flex-1" name="bank_account_number" type="number" value={form?.personal_detail?.bank_account_number} onChange={onChangeForm} />
                                    <Button className="w-[100px]" type="outline" title="Periksa" disabled={!canValidate} onClick={onValidateAccount} />
                                </div>
                                <ErrorMessage className="mt-2" message={errorForm?.bank_account_number} />
                            </div>
                        </div>
                        <div className="flex gap-3">
                            <div className="w-1/3 text-neutral-70 required mt-2">{'Nama Pemilik'}</div>
                            <div className="flex-1">
                                <Input name="bank_account_name" value={form?.personal_detail?.bank_account_name} disabled />
                                <ErrorMessage className="mt-2" message={errorForm?.bank_account_name} />
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}
