import { Home } from "components/home/Home";
import {Route, Routes} from "react-router-dom";
import {ROUTE_LIST} from "./route-list";

export default function RoutePage() {
    return (
        <Routes>
            <Route path='/' element={<Home/>}/>
            {
                ROUTE_LIST.map((item, index) => (
                    <Route
                        key={index.toString()}
                        path={item.path}
                        element={item.component}/>
                ))
            }
        </Routes>
    )
}
