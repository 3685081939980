import {ShiftingImport} from "../../../components/request-shifting/shifting-import/ShiftingImport";
import {useState} from "react";
import {STEPS_IMPORT_SHIFTING_SCHEDULE} from "../../../constants/constant";
import {UploadFileContainer} from "./UploadFileContainer";
import {ValidationShiftingScheduleContainer} from "./ValidationShiftingScheduleContainer";
import {ViewDataContainer} from "./ViewDataContainer";
import {ImportDataContainer} from "./ImportDataContainer";

export const ShiftingImportContainer = () => {
    const [activeStep, setActiveStep] = useState(1)
    const [payload, setPayload] = useState({file: {}})
    const [validatedData, setValidatedData] = useState({})

    const setPayloadData = (payload) => {
        setPayload(payload)
    }

    const setViewData = (data) => {
        setValidatedData(data)
    }

    const onChangeStep = (step) => {
        setActiveStep(step)
    }

    const stepContent = [
        <UploadFileContainer
            payload={payload}
            onChangeStep={onChangeStep}
            setPayloadData={setPayloadData}
        />,
        <ValidationShiftingScheduleContainer
            payload={payload}
            onChangeStep={onChangeStep}
            setViewData={setViewData}
        />,
        <ViewDataContainer
            validatedData={validatedData}
            onChangeStep={onChangeStep}
        />,
        <ImportDataContainer
            payload={payload}
            onChangeStep={onChangeStep}
        />
    ]

    return (
        <ShiftingImport steps={STEPS_IMPORT_SHIFTING_SCHEDULE} activeStep={activeStep} stepContent={stepContent}/>
    )
}
