import icPeople from "../../assets/images/ic-user-profile-03.svg";
import {convertSpaceToDash} from "../../utils/utils";
import {Link} from "react-router-dom";
import Button from "../../components/commons/button";
import icFileLocked from "../../assets/images/ic-file-lock-02.svg";

export const TABLE_CONTRACT_EMP_LIST = {
    name: 'List Registered Employee',
    column: [
        {
            name: "Name",
            key: "name",
            className: "font-semibold",
            render: ({item}) => {
                return (
                    <div className={'flex flex-row gap-3 items-center'}>
                        <img className={'w-7 h-7 rounded-full border border-neutral-50'}
                             src={item?.profile_picture ?? icPeople}
                             alt={'user-img'}/>
                        <div>{item?.full_name}</div>
                    </div>
                )
            }
        }, {
            name: "No. Hp",
            key: "phone_number",
            render: ({value}) => <p className={"text-neutral-60"}>{value}</p>
        }, {
            name: "Email",
            key: "personal_email",
            render: ({value}) => <p className={"text-neutral-60"}>{value}</p>
        }, {
            name: "Action",
            key: "id",
            render: ({item}) => {
                let name = convertSpaceToDash(item.full_name);
                return (
                    <Link target={"_blank"}
                          to={`/management-contract/create-contract/new-contract-${name}?employeeId=${item.id}`}>
                        <Button title={'Create Contract'} type={'outline'}
                                icon={icFileLocked}/>
                    </Link>
                )
            }
        }]
}
