import { METHOD } from "./method";

export const API_ACTIVATION = {
    LIST_EMPLOYEE_ACTIVATION: {
        api: '/v1/hr-adm/account/activation/employees',
        method: METHOD.POST
    },
    UPDATE_STATUS_EMPLOYEE: {
        api: '/v1/hr-adm/account/activation/status',
        method: METHOD.PUT
    },
    EMPLOYEE_ACTIVATION: {
        api: '/v1/hr-adm/account/activation/roles',
        method: METHOD.GET
    },
    EXPORT_EMPLOYEE_ACTIVATION: {
        api: '/v1/hr-adm/account/activation/employees/export',
        method: METHOD.POST
    },
}