import icBulkUpdate from "assets/images/ic-bulk-update.svg"
import icDown from "assets/images/ic-chev-down.svg"
import icFileUp from "assets/images/ic-file-up-02.svg"
import icFilter from "assets/images/ic-filter-new.svg"
import clsx from "clsx"
import {ExportModal} from "components/commons/ExportModal"
import {InlineTabs} from "components/commons/InlineTabs"
import Button from "components/commons/button"
import DateRangePicker from "components/commons/date-range-picker"
import Search from "components/commons/search"
import Table from "components/commons/table"
import Toast from "components/commons/toast"
import ViewList from "components/commons/view-list"
import {useClickOutside} from "hooks/useClickOutside"
import moment from "moment"
import {useState} from "react"
import {ModalApproval} from "../commons/modal-approval/ModalApproval";
import {PreviewRemoteWorking} from "./PreviewRemoteWorking";

export const RemoteWorking = (
    {
        tabList,
        dataEmployee,
        dataPreview,
        status,
        optionBulk,
        configTable,
        previewTab,
        isLoading,
        query,
        activePreviewTab,
        setActivePreviewTab,
        selectedEmployeeList,
        selectedBulkOption,
        modal,
        toastMessage,
        rejectReason,
        setRejectReason,
        onChangeTab,
        onChangeQuery,
        onSelectItem,
        onExport,
        onBulkUpdate,
        onPreview,
        onSetModal,
        onUpdateStatusRequest
    }) => {
    const elementID = 'dropdownBulkUpdate'
    const [showSetting, setShowSetting] = useState(false)
    useClickOutside({callback: () => showSetting && setShowSetting(false), elementID})

    const onSelect = (e) => {
        setShowSetting(false)
        onBulkUpdate(e)
    }

    return (
        <>
            <InlineTabs tabList={tabList} activeTab={status} onClickTab={onChangeTab}/>
            <div className={"flex flex-row justify-between gap-3 px-6 py-4"}>
                <div className={"flex flex-row gap-3"}>
                    <Search value={query?.search} onSearch={(search) => onChangeQuery({page: 1, search})}/>
                    <div className={"flex items-center gap-2"}>
                        <img src={icFilter} alt="ic-filter"/>
                        <p className="text-neutral-70">Filter by date:</p>
                        <DateRangePicker
                            value={query?.start_date ? [query?.start_date, query?.end_date] : []}
                            onChange={(value) => onChangeQuery({page: 1, start_date: value[0], end_date: value[1]})}
                            format="YYYY-MM-DD"
                            maxDate={`${moment().get('year')}-12-31`}
                        />
                    </div>
                    <ViewList
                        show={modal?.limit}
                        onShow={() => onSetModal({limit: true})}
                        onClose={() => onSetModal({limit: false})}
                        value={query?.limit}
                        onSelectSize={(limit) => onChangeQuery({page: 1, limit})}
                    />
                    <Button title="Export" icon={icFileUp} type="outline" disabled={!dataEmployee?.total_result}
                            onClick={() => onSetModal({export: true})}/>
                </div>
                {
                    status === tabList[0].id &&
                    <div className="relative">
                        <Button title="Bulk Update" type="outline" disabled={!dataEmployee?.total_result} icon={icBulkUpdate} iconAfter={icDown}
                                onClick={() => setShowSetting(!showSetting)}/>
                        <div
                            id={elementID}
                            className={clsx(
                                "absolute bg-white right-0 mt-1 p-1.5 border border-neutral-20 rounded-lg shadow-sm whitespace-nowrap transition-all duration-200 w-[180px] z-10",
                                showSetting ? "opacity-100 visible" : "opacity-0 collapse"
                            )}
                        >
                            {Object.entries(optionBulk).map(([key, value]) => (
                                <div key={key}
                                     className="hover:bg-primary-10 capitalize px-4 py-2.5 rounded-lg cursor-pointer transition-all duration-200"
                                     onClick={() => onSelect(value)}>{value}</div>
                            ))}
                        </div>
                    </div>
                }
            </div>
            <Table
                className="my-3"
                name={configTable.name}
                data={dataEmployee?.result}
                columns={configTable.column}
                size={query?.limit}
                totalPage={dataEmployee?.total_page}
                totalResult={dataEmployee?.total_result}
                currentPage={query?.page}
                onChangePage={(page) => onChangeQuery({page})}
                onSelectItem={onSelectItem}
                onClickItem={(item) => onPreview(item)}
                onClickSort={(sortQuery) => onChangeQuery({...sortQuery, page: 1})}
                loading={isLoading}
            />
            {
                modal?.preview &&
                <PreviewRemoteWorking
                    visibility={modal?.preview}
                    tabList={tabList}
                    dataPreview={dataPreview}
                    previewTab={previewTab}
                    optionBulk={optionBulk}
                    activePreviewTab={activePreviewTab}
                    onChangePreviewTab={setActivePreviewTab}
                    onHide={onSetModal}
                    onSelect={onSelect}
                />
            }
            {
                modal?.bulk_update &&
                <ModalApproval
                    id={dataPreview?.id}
                    title={'request remote working'}
                    modal={modal}
                    setModal={onSetModal}
                    status={selectedBulkOption}
                    optionBulk={optionBulk}
                    selectedLength={selectedEmployeeList?.length}
                    rejectReason={rejectReason}
                    setRejectReason={setRejectReason}
                    onUpdateStatusRequest={onUpdateStatusRequest}
                />
            }
            <ExportModal
                title="Apakah anda yakin ingin melakukan export data?"
                description={`Total data list remote working ${status}: ${selectedEmployeeList?.length || dataEmployee?.total_result || 0}`}
                show={modal?.export}
                onExportHandler={onExport}
                onCancelHandler={() => onSetModal({export: false})}
            />
            <Toast
                show={modal?.toast}
                message={toastMessage}
                onClose={() => onSetModal({toast: false})}
            />
        </>
    )
}
