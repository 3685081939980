import Search from "../../commons/search";
import Button from "../../commons/button";
import icAddSquare from "../../../assets/images/id-add-square.svg";
import Table from "../../commons/table";
import {TABLE_SETTING_COMPONENT} from "../../../configs/payroll/configTableSettingComponent";
import {SettingComponentForm} from "./SettingComponentForm";
import Toast from "../../commons/toast";

export const SettingComponent = (props) => {
    const {
        onChangeQuery,
        query,
        data,
        isLoading,
        onClickItemHandler,
        onClickSort,
        show,
        modalVisibility,
        formValue,
        onChangeFormValue,
        componentType,
        componentCategory,
        isFormValid,
        addComponentHandler,
        toast,
        clearToast,
        onSubmitForm
    } = props
    return (
        <div className={'flex flex-col'}>
            <div className={'flex flex-row justify-between px-6 py-4'}>
                <Search value={query?.search} onSearch={(search) => onChangeQuery({page: 1, search})}/>
                <Button title="Create Komponen" icon={icAddSquare} onClick={addComponentHandler}/>
            </div>
            <Table
                className="my-3"
                data={data?.result}
                columns={TABLE_SETTING_COMPONENT.column}
                totalResult={data?.total_result}
                totalPage={data?.total_page}
                currentPage={query?.page}
                name={TABLE_SETTING_COMPONENT.name}
                onClickItem={onClickItemHandler}
                onChangePage={(page) => onChangeQuery({page})}
                onClickSort={(e) => onClickSort(e)}
                loading={isLoading}
            />
            <SettingComponentForm
                onSubmitForm={onSubmitForm}
                show={show}
                onHide={() => modalVisibility(false)}
                isFormValid={isFormValid}
                componentTypeOptions={componentType}
                formValue={formValue}
                onChangeFormValue={onChangeFormValue}
                componentCategoryOptions={componentCategory}
            />
            <Toast
                show={toast?.isShowToast}
                message={toast?.message}
                onClose={() => clearToast()}
            />
        </div>
    )
}
