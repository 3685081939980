import icFileLock from "../../../assets/images/ic-file-lock-02.svg";
import {SidePartEmployee} from "./SidePartEmployee";
import Datepicker from "../../commons/datepicker";
import moment from "moment";
import {formDisabled} from "../../../utils/utils";
import TextEditor from "../../commons/text-editor";
import Select from "../../commons/select";
import Input from "../../commons/input";
import ErrorMessage from "../../commons/error-message";
import Button from "../../commons/button";

export const FormContract = (props) => {
    const {
        employeeDetail,
        contractDetail,
        isFormValid,
        onSubmitForm,
        errorForm,
        options,
        onSave,
        activity,
        form = []
    } = props
    return (
        <div>
            <div className={"px-6 py-6"}>
                <div className={"flex flex-row gap-3 mb-8"}>
                    <img src={icFileLock}/>
                    <p className={"text-neutral-80 text-md font-bold capitalize"}>{activity} Contract</p>
                </div>
                <div className={"flex flex-row space-x-6"}>
                    <SidePartEmployee employeeDetail={employeeDetail}/>
                    <div className={"flex-1 space-y-11"}>
                        {
                            form.map((item, index) => (
                                <div key={index}>
                                    <p className={"text-primary-50 font-bold capitalize"}>{item.label}</p>
                                    <div className={"grid grid-cols-2 gap-x-7 gap-y-5 mt-5"}>
                                        {
                                            item.form.map((form, i) => {
                                                switch (form.type) {
                                                    case 'date':
                                                        if ((contractDetail?.type?.value !== 'PKWT') && (form.key === 'end_date')) {
                                                            return
                                                        }
                                                        return (
                                                            <div key={i} className="flex items-start gap-3">
                                                                <div
                                                                    className={`w-1/3 text-neutral-70 font-semibold capitalize mt-2 ${form.required && 'required'}`}>{form.label}
                                                                </div>
                                                                <div className={"flex-1"}>
                                                                    <Datepicker
                                                                        name={form.key}
                                                                        maxDate={`${moment().get('year') + 5}-12-31`}
                                                                        value={contractDetail[form.key]}
                                                                        disabled={formDisabled(form.disabled, form.key, activity)}
                                                                        onChange={(e) => onSave({
                                                                            name: form.key,
                                                                            value: e
                                                                        })}/>
                                                                    <ErrorMessage message={errorForm[form.key]}
                                                                                  className={"mt-1"}/>
                                                                </div>
                                                            </div>
                                                        )
                                                        break;
                                                    case 'text-editor':
                                                        return (
                                                            <div key={i} className="flex items-start gap-4">
                                                                <div
                                                                    className={`w-1/3 text-neutral-70 font-semibold capitalize required`}>{form.label}
                                                                </div>
                                                                <div className={"flex-1"}>
                                                                    <TextEditor
                                                                        defaultValue={contractDetail[form.key]}
                                                                        onChange={(e) => onSave({
                                                                            name: form.key,
                                                                            value: e
                                                                        })}
                                                                        placeholder={form.placeholder}/>
                                                                    <ErrorMessage message={errorForm[form.key]}
                                                                                  className={"mt-12"}/>
                                                                </div>
                                                            </div>
                                                        )
                                                    case 'select':
                                                        return (
                                                            <div key={i} className="flex items-start gap-3">
                                                                <div
                                                                    className={`w-1/3 text-neutral-70 font-semibold capitalize required mt-2`}>{form.label}
                                                                </div>
                                                                <div className={"flex-1 "}>
                                                                    <Select
                                                                        name={form.key}
                                                                        className='capitalize'
                                                                        placeholder={form?.placeholder ?? form.label}
                                                                        options={options[form.key]}
                                                                        onChange={onSave}
                                                                        disabled={formDisabled(form.disabled, form.key, activity)}
                                                                        value={contractDetail[form.key]}
                                                                    />
                                                                    <ErrorMessage message={errorForm[form.key]}
                                                                                  className={"mt-1"}/>
                                                                </div>
                                                            </div>
                                                        )
                                                    case 'allowance':
                                                        return (
                                                            <div key={i} className="flex items-center gap-3">
                                                                <div
                                                                    className={`w-1/3 text-neutral-70 font-semibold capitalize`}>{form.label}
                                                                </div>
                                                                <div className="flex-1">
                                                                    <Input
                                                                        addonBefore={'Rp'}
                                                                        name={form.key}
                                                                        disabled={true}
                                                                        value={form.value}
                                                                    />
                                                                    <ErrorMessage message={errorForm[form.key]}
                                                                                  className={"mt-1"}/>
                                                                </div>
                                                            </div>
                                                        )
                                                    default:
                                                        return (
                                                            <div key={i} className="flex items-center gap-3">
                                                                <div
                                                                    className={`w-1/3 text-neutral-70 font-semibold capitalize ${form.required && 'required'}`}>{form.label}
                                                                </div>
                                                                <div className="flex-1">
                                                                    <Input
                                                                        addonBefore={form.prefix}
                                                                        name={form.key}
                                                                        type={form.inputType}
                                                                        disabled={formDisabled(form.disabled, form.key, activity)}
                                                                        placeholder={form?.placeholder ?? form.label}
                                                                        value={contractDetail[form.key]}
                                                                        onChange={onSave}
                                                                    />
                                                                    <ErrorMessage message={errorForm[form.key]}
                                                                                  className={"mt-1"}/>
                                                                </div>
                                                            </div>
                                                        )
                                                }
                                            })
                                        }
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
            <div className={"sticky bottom-0 border-t px-14 py-6 bg-white flex flex-row-reverse"}>
                <Button title={'Submit'} disabled={!isFormValid} onClick={onSubmitForm}/>
            </div>
        </div>
    )
}
