import Search from "../../commons/search";
import icFilter from "../../../assets/images/ic-filter-new.svg";
import Select from "../../commons/select";
import Button from "../../commons/button";
import icFileUp from "../../../assets/images/ic-file-up-02.svg";
import icFileDown from "../../../assets/images/ic-file-down-02.svg";
import Table from "../../commons/table";
import {TABLE_CREATE_PAYROLL_EMPLOYEE_PAYROLL_LIST} from "../../../configs/payroll/configTableCreatePayroll";
import {ExportModal} from "../../commons/ExportModal";
import Toast from "../../commons/toast";
import {ImportDataPayrollContainer} from "../../../containers/payroll/create-payroll/import-data-payroll/ImportDataPayrollContainer";
import {PayrollScheduleForm} from "./PayrollScheduleForm";
import {findOptionSelected, formatDateMoment} from "../../../utils/utils";
import Modal from "../../commons/modal";

export const EmployeePayrollList = (
    {
        dataList,
        columnTable,
        isLoading,
        isCreating,
        toast,
        query,
        optionsFilter,
        onShowModalImport,
        modal,
        errorForm,
        payrollScheduleDetail,
        onSetModal,
        onExport,
        clearToast,
        onChangeQuery,
        onSetPayrollSchedule,
        onChangeValue,
        fetchEmployeePayrollList,
        onSubmitForm,
        onSaveFrom
    }) => {

    return (
        <div>
            <div className={"flex items-center space-x-7 px-6 py-4"}>
                <Search value={query?.search} onSearch={(search) => onChangeQuery({page: 1, search})}/>
                <div className={"flex gap-3"}>
                    <div className={"flex items-center gap-2"}>
                        <img src={icFilter} alt="ic-filter"/>
                        <p className="text-neutral-70">Filter by Department:</p>
                        <Select
                            name="department_id"
                            className="w-[300px]"
                            optionsClassName="max-h-40"
                            placeholder="Pilih Department"
                            options={optionsFilter}
                            value={findOptionSelected(optionsFilter, Number(query?.department_id)) || optionsFilter[0]}
                            onChange={(item) => onChangeQuery({page: 1, [item?.name]: item?.value?.value})}
                            searchable
                        />
                    </div>
                    <Button title="Export" icon={icFileUp} type="outline" disabled={!dataList?.total_result}
                            onClick={() => onSetModal({export: true})}/>
                    <Button title="Import" icon={icFileDown} type="outline" onClick={onShowModalImport}/>
                </div>
            </div>
            <Table
                className="my-3"
                name={TABLE_CREATE_PAYROLL_EMPLOYEE_PAYROLL_LIST.name}
                columns={columnTable}
                data={dataList?.result?.data}
                size={query?.limit}
                currentPage={query?.page}
                totalPage={dataList?.total_page}
                totalResult={dataList?.total_result}
                onChangePage={(val) => onChangeQuery({page: val})}
                onClickSort={(val) => onChangeQuery(val)}
                loading={isLoading}
            />
            <div className={"flex justify-end px-6 py-4"}>
                <Button className="px-8" title="Setting Tanggal Payroll" onClick={onSetPayrollSchedule}/>
            </div>
            <ExportModal
                title="Apakah anda yakin ingin export data employee payroll list?"
                description={`Total data payroll list export: ${dataList?.total_result}`}
                show={modal?.export}
                onExportHandler={onExport}
                onCancelHandler={() => onSetModal({export: false})}
            />
            {
                modal?.form &&
                <PayrollScheduleForm
                    title="Setting Waktu Pengiriman Payroll"
                    show={modal?.form}
                    onHide={() => onSetModal({form: false})}
                    payrollScheduleDetail={payrollScheduleDetail}
                    onChangeValue={onChangeValue}
                    isCreating={isCreating}
                    errorForm={errorForm}
                    onSubmitForm={onSubmitForm}
                />
            }
            {
                modal?.confirm &&
                <Modal
                    dialogClassName='w-full max-w-[400px]'
                    contentClassName='rounded-xl'
                    show={modal?.confirm}
                >
                    <div>
                        <div className={"py-8 px-6 flex flex-col justify-center items-center space-y-4"}>
                            <p className={"text-center font-semibold"}>Confirmation</p>
                            <p className={"text-center text-neutral-70"}>
                                {`Apakah anda yakin seluruh data payroll sudah lengkap dan akan mengirimkan payroll pada tanggal ${formatDateMoment(payrollScheduleDetail?.date, 'D MMMM YYYY')} pukul ${payrollScheduleDetail?.time} ??`}
                            </p>
                        </div>
                        <div className={"px-6 py-3 flex flex-row border-t space-x-3 justify-center"}>
                            <Button type={'outline'} title={'Cancel'} onClick={() => onSetModal({confirm: false})}/>
                            <Button title={'Submit'} onClick={onSaveFrom}/>
                        </div>
                    </div>
                </Modal>
            }
            <Toast
                show={toast?.isShowToast}
                message={toast?.message}
                onClose={() => clearToast()}
            />
            <ImportDataPayrollContainer params="payroll-component" fetchList={fetchEmployeePayrollList}/>
        </div>
    )
}
