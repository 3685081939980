import Button from "../../components/commons/button";
import icEdit04Primary from "../../assets/images/ic-edit-04-primary.svg";
import {ACTION_TYPE} from "../../constants/constant";
import icTrash01Red from "../../assets/images/ic-trash-01-red.svg";
import Badge from "../../components/commons/badge";

export const TABLE_JOB_TITLE_SETTING = {
    name: 'Job Title',
    column: [
        {
            name: "Job Title",
            key: "title"
        },
        {
            name: "PT",
            key: "company_name"
        },
        {
            name: "Department",
            key: "department_name"
        },
        {
            name: "Division",
            key: "division_name"
        },
        {
            name: "Sub Division",
            key: "sub_division_name"
        },
        {
            name: "Total Employee",
            key: "count",
            classNameHeader: "!text-center",
            className: "text-center"
        },
        {
            name: "Quota Employee",
            key: "quota",
            classNameHeader: "!text-center",
            className: "text-center"
        },
        {
            name: "Visibility",
            key: "visibility",
            className: "text-center",
            render: ({value}) => <Badge title={value} type={value === 'hidden' ? 'hide' : value}/>
        },
        {
            name: "Action",
            key: "action",
            classNameHeader: "!text-center",
            render: ({ item, onClickItem }) => (
                <div className="flex space-x-3 justify-center">
                    <Button type="outline" icon={icEdit04Primary} onClick={() => onClickItem({id: item?.id, actionType: ACTION_TYPE.EDIT})}/>
                    <Button type="danger" icon={icTrash01Red} onClick={() => onClickItem({id: item?.id, actionType: ACTION_TYPE.DELETE})}/>
                </div>
            )
        }
    ]
}

export const INITIAL_DATA_FILTER = {
    search: '',
    page: 1,
    limit: 20
}
