import {METHOD} from "./method";

export const API_REQUEST_LEAVE = {
    LEAVE_LIST: {
        api: '/v1/hr-adm/leave/list',
        method: METHOD.POST
    },
    EXPORT_LEAVE_LIST: {
        api: '/v1/hr-adm/leave/list/export',
        method: METHOD.POST
    },
    JOB_UNIT_LIST: {
        api: '/v1/hr-adm/leave/job-unit',
        method: METHOD.POST
    },
    LEAVE_TYPE_LIST: {
        api: '/v1/hr-adm/leave/type',
        method: METHOD.GET
    },
    PREVIEW_LEAVE: {
        api: '/v1/hr-adm/leave/preview',
        method: METHOD.GET
    },
    APPROVE_REQUEST_LEAVE: {
        api: '/v1/hr-adm/leave/approve',
        method: METHOD.POST
    },
    REJECT_REQUEST_LEAVE: {
        api: '/v1/hr-adm/leave/reject',
        method: METHOD.POST
    },
}
