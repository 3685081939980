import {AddNotesModal} from "../../../../components/management-contract/contract-employee/add-notes-modal/AddNotesModal";
import {useState} from "react";

export const AddNotesModalContainer = ({show, onHide, onSubmitModal, employeeDetail, contractDetail}) => {
    const [notes, setNotes] = useState('')

    const props = {
        onSubmitForm: () => onSubmitModal(notes),
        title: 'Notes',
        show,
        onHide,
        notes,
        setNotes,
        employeeDetail,
        contractDetail
    }

    return (
        <AddNotesModal {...props}/>
    )
}
