import Input from "../../commons/input";
import Button from "../../commons/button";
import icCloseFill from "assets/images/ic-close-round-fill.svg";
import FormModal from "../../commons/form-modal";
import Select from "../../commons/select";

export const WorkUnitForm = (props) => {
    const {
        selectedTab,
        isShow,
        onHideForm,
        onSubmitForm,
        text,
        onChangeText,
        addTextHandler,
        removeTextHandler,
        onChangeSelectValue,
        options,
        formValue,
        headUnitLabel,
        isFormValid
    } = props

    return (
        <FormModal show={isShow}
                   title={`Tambah Data ${selectedTab}`}
                   width={'max-w-[639px]'}
                   onHide={onHideForm}
                   onSubmitForm={onSubmitForm}
                   isDisabled={!isFormValid}
        >
            <div className={"flex flex-col px-6 py-8 gap-[20px]"}>
                <div className="flex flex-row">
                    <div className="w-1/3 text-neutral-70 required capitalize">{headUnitLabel}</div>
                    <Select name="head_unit_selected"
                            className='flex-1'
                            placeholder={`Pilih ${headUnitLabel}`}
                            options={options}
                            value={formValue.head_unit_selected}
                            onChange={onChangeSelectValue}
                            optionsClassName='max-h-[350px]'
                    />
                </div>
                <div className="flex flex-row">
                    <div className="w-1/3 text-neutral-70 required">{selectedTab}</div>
                    <div className={'flex flex-row gap-3'}>
                        <Input className={'w-[270px]'} name="name" placeholder={`Isi nama ${selectedTab}`}
                               value={text}
                               onChange={(e) => onChangeText(e.value)}/>
                        <Button title="+ Tambah"
                                type={'outline'}
                                disabled={!text}
                                onClick={addTextHandler}/>
                    </div>
                </div>
                <div className="flex flex-row">
                    <div className="w-1/3 text-neutral-70"/>
                    <div
                        className="flex-1 flex-wrap h-[106px] overflow-y-auto px-3 py-2.5 bg-white rounded-lg border border-slate-300 justify-start items-start gap-1.5 inline-flex">
                        {
                            formValue.names.map((textItem, index) => (
                                <div key={index}
                                     className="px-2 py-1 bg-white rounded-lg border border-neutral-20 justify-center items-center gap-2 inline-flex">
                                    <div className="text-neutral-70 leading-tight">{textItem}</div>
                                    <div className="w-4 h-4 relative cursor-pointer"
                                         onClick={() => removeTextHandler(index)}>
                                        <img src={icCloseFill} alt={'close-icon'}/>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </FormModal>
    )
}
