import {METHOD} from "./method";

export const API_ADDITIONAL_INCENTIVE = {
    ADDITIONAL_INCENTIVE_LIST: {
        api: '/v1/hr-adm/additional-incentive-request/list',
        method: METHOD.POST
    },
    PREVIEW_ADDITIONAL_INCENTIVE: {
        api: '/v1/hr-adm/additional-incentive-request',
        method: METHOD.GET
    },
    EXPORT_ADDITIONAL_INCENTIVE_LIST: {
        api: '/v1/hr-adm/additional-incentive-request/export',
        method: METHOD.POST
    },
    APPROVE_ADDITIONAL_INCENTIVE: {
        api: '/v1/hr-adm/additional-incentive-request/approve',
        method: METHOD.POST
    },
    REJECT_ADDITIONAL_INCENTIVE: {
        api: '/v1/hr-adm/additional-incentive-request/reject',
        method: METHOD.POST
    },
}
