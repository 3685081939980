import Datepicker from "../../commons/datepicker";
import moment from "moment/moment";
import FormModal from "../../commons/form-modal";
import Timepicker from "../../commons/timepicker";
import ErrorMessage from "../../commons/error-message";

export const ShiftingDetailForm = (
    {
        title,
        show,
        onHide,
        shiftingDetail = {},
        onChangeValue,
        isFormValid,
        isCreating,
        isUpdating,
        onSubmitForm,
        errorForm
    }
) => {

    return (
        <FormModal
            title={title}
            show={show}
            width={'max-w-[639px]'}
            onHide={onHide}
            isLoading={isCreating || isUpdating}
            isDisabled={!isFormValid()}
            onSubmitForm={onSubmitForm}
        >
            <div className={"flex flex-col space-y-6 px-6 py-8"}>
                <div className={"flex items-center text-neutral-70"}>
                    <div className="w-1/3">Pilih Tanggal</div>
                    <Datepicker
                        className="flex-1"
                        value={shiftingDetail?.date}
                        placeholder="Pilih tanggal presensi"
                        format='YYYY-MM-DD'
                        minDate={moment().add(1, 'day')}
                        maxDate={`${moment().get('year') + 1}-12-31`}
                        onChange={(e) => onChangeValue(e, 'date')}
                    />
                </div>
                <div className={"flex items-center text-neutral-70"}>
                    <div className="w-1/3">Waktu Clock In</div>
                    <Timepicker
                        className="flex-1"
                        name="clock_in_time"
                        placeholder="Pilih waktu clock in"
                        value={shiftingDetail?.clock_in_time}
                        onChange={(e) => onChangeValue(e)}
                    />
                </div>
                <div className={"flex flex-grow text-neutral-70"}>
                    <div className={"w-1/3 mt-2"}>Waktu Clock Out</div>
                    <div className="flex-1">
                        <Timepicker
                            name="clock_out_time"
                            placeholder="Pilih waktu clock out"
                            value={shiftingDetail?.clock_out_time}
                            onChange={(e) => onChangeValue(e)}
                        />
                        <ErrorMessage className="mt-2" message={errorForm}/>
                    </div>
                </div>
            </div>
        </FormModal>
    )
}
