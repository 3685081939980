import {METHOD} from "./method";

export const API_GRADE = {
    LIST_GRADE: {
        api: '/v1/hr-adm/grade/list',
        method: METHOD.GET
    },
    CREATE_GRADE: {
        api: '/v1/hr-adm/grade',
        method: METHOD.POST
    },
    EXPORT_GRADE: {
        api: '/v1/hr-adm/grade/export',
        method: METHOD.POST
    },
    DELETE_GRADE: {
        api: '/v1/hr-adm/grade',
        method: METHOD.DELETE
    },
    UPDATE_GRADE: {
        api: '/v1/hr-adm/grade',
        method: METHOD.PUT
    },
    DOWNLOAD_TEMPLATE: {
        api: '/v1/hr-adm/grade/import/download-template',
        method: METHOD.GET
    },
    IMPORT_VALIDATION: {
        api: '/v1/hr-adm/grade/import/validate',
        method: METHOD.POST
    },
    IMPORT: {
        api: '/v1/hr-adm/grade/import',
        method: METHOD.POST
    },
}

export const API_TYPE_ALLOWANCE = {
    LIST_TYPE_ALLOWANCE: {
        api: '/v1/hr-adm/grade/allowances',
        method: METHOD.GET
    },
    CREATE_TYPE_ALLOWANCE: {
        api: '/v1/hr-adm/grade/allowance',
        method: METHOD.POST
    },
    UPDATE_TYPE_ALLOWANCE: {
        api: '/v1/hr-adm/grade/allowance',
        method: METHOD.PUT
    }
}
