export const CreatePayroll = ({ stepContent, lastPath }) => {
    const currentStep = stepContent.find(item => {
        let currentPath = lastPath
        if (lastPath !== stepContent[0].path) {
            const updatePath = currentPath.split('-')?.slice(0, -2)
            currentPath = updatePath?.join('-')
        }
        return item?.path === currentPath
    })

    return (
        <div className="relative">
            <div className={"absolute font-medium text-md text-primary-60 right-12 py-6"}>
                {`Step ${currentStep?.sequence} of ${stepContent?.length}`}
            </div>
            <div>
                {currentStep?.item}
            </div>
        </div>
    )
}
