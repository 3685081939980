import icEducation from "assets/images/ic-graduation-cap-02.svg"
import Button from "components/commons/button"
import DeleteModal from "components/commons/delete-modal"
import Table from "components/commons/table"
import { TABLE_EDUCATION } from "configs/employee/configTableAddEmployee"
import { useExpandable } from "hooks/useExpandable"
import { CreateEducation } from "../create-modal/CreateEducation"
import { FormHeader } from "./FormHeader"

export const EducationInformation = ({
    optionsGrades,
    dataEducation,
    hasMajor,
    isFormValid,
    isEdit,
    form,
    showModal,
    onShowModal,
    onCloseModal,
    onChangeForm,
    onSubmit,
    onActionRow,
    showDeleteModal,
    onCloseDeleteModal,
    onDelete,
}) => {
    const { isExpanded, elementRef, toggleExpand } = useExpandable({ verticalPadding: 24 })

    return (
        <div className="bg-white border-y border-neutral-20">
            <FormHeader title="Informasi Pendidikan" optional icon={icEducation} expanded={isExpanded} onClick={toggleExpand} />
            <div ref={elementRef} className="expandable px-8">
                <Table
                    className="mb-5"
                    classNameHeader="!bg-primary-10 !text-md !text-primary-50"
                    classNameRow="!py-6"
                    name={TABLE_EDUCATION.name}
                    columns={TABLE_EDUCATION.column}
                    data={form}
                    onClickItem={onActionRow}
                />
                <Button type="outline" title="+ Tambah Data Pendidikan" onClick={onShowModal} />
            </div>

            <CreateEducation
                hasMajor={hasMajor}
                isFormValid={isFormValid}
                isEdit={isEdit}
                show={showModal}
                onHide={onCloseModal}
                optionsGrades={optionsGrades}
                dataEducation={dataEducation}
                onChangeForm={onChangeForm}
                onSubmit={onSubmit}
            />

            <DeleteModal
                text={"Apakah anda yakin ingin menghapus data pendidikan?"}
                show={showDeleteModal}
                onHide={onCloseDeleteModal}
                onDelete={onDelete}
            />
        </div>
    )
}