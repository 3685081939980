import Stepper from "../../commons/stepper";

export const EmployeeImport = ({steps, stepContent, activeStep}) => {
    return (
        <div className={"flex flex-col items-center py-8 space-y-8 border-t-2"}>
            <Stepper steps={steps} activeStep={activeStep}/>
            {stepContent[activeStep - 1]}
        </div>
    )
}
