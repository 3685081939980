export const EmployeeDetailTab = ({ employeeDetailTabList, tabSelected, onChangeTab }) => {
    return (
        <div className={"bg-white flex flex-row mt-1"}>
            <div className={"flex flex-col px-6 pb-6 mt-6 space-y-2 border-r"}>
                {
                    employeeDetailTabList.map((tab, index) => (
                        <div key={index}
                            onClick={() => onChangeTab(tab)}
                            className={`w-48 px-4 py-2 flex flex-col justify-center cursor-pointer border-l-2 hover:bg-neutral-10 transition duration-200 ${tabSelected.key === tab.key ? 'font-semibold bg-primary-10 text-primary-50 border-primary-50 rounded-r' : 'font-medium text-neutral-70 border-transparent'}`}>{tab.label}</div>
                    ))
                }
            </div>
            <div className={"px-9 py-6 w-full"}>
                {tabSelected.component}
            </div>
        </div>
    )
}
