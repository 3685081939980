import { LeaveSetting } from "components/general-settings/leave-setting/LeaveSetting"
import { TABLE_LEAVE_SETTING } from "configs/general-settings/configTableLeaveSetting"
import { ACTION_TYPE } from "constants/constant"
import { useEffect, useState } from "react"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"
import { getAllQueryParams, setQueryParams } from "utils/utils"
import {useFetch} from "../../../services/useFetch";
import {API_LEAVE_SETTING} from "../../../services/apiLeaveSetting";
import {useDispatch, useSelector} from "react-redux";
import {resetInitialLeaveSetting, setLeaveSettingToast} from "../../../reducers/formLeaveSettingReducer";

export const LeaveSettingContainer = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [searchParams, setSearchParams] = useSearchParams()
    const defaultQuery = {
        page: 1,
        limit: 20
    }
    const query = searchParams.size > 0 ? getAllQueryParams(searchParams) : defaultQuery
    const [deleteId, setDeleteId] = useState(null)
    const [modal, setModal] = useState({
        delete: false,
        preview: false,
    })
    const {toast} = useSelector(state => state.formLeaveSettingReducer)
    const {data: leaveList, fetching: getLeaveList, isLoading} = useFetch(API_LEAVE_SETTING.LEAVE_LIST)
    const {data: previewLeave, fetching: getPreviewLeave} = useFetch(API_LEAVE_SETTING.PREVIEW_LEAVE)
    const {fetching: deleteLeave, isLoading: isDeleting} = useFetch(API_LEAVE_SETTING.DELETE_LEAVE)

    useEffect(() => {
        fetchLeaveList({...query})
    }, [location.search])

    const onChangeQuery = (queryParams) => {
        const params = setQueryParams({...query, ...queryParams})
        setSearchParams(params, {replace: true})
    }

    const fetchLeaveList = (item = query) => {
        getLeaveList(item)
    }

    const clearToast = () => {
        dispatch(setLeaveSettingToast({isShowToast: false}))
    }

    const onDelete = () => {
        deleteLeave({}, deleteId)
            .then(() => {
                dispatch(setLeaveSettingToast({message: 'Leave Deleted !!', isShowToast: true}))
                setModal({...modal, delete: false})
                if (getLeaveList?.data?.result?.length === 1 && Number(query?.page) !== 1) {
                    onChangeQuery({page: 1})
                } else {
                    fetchLeaveList()
                }
            })
    }

    const onActionRow = ({ id, actionType }) => {
        switch (actionType) {
            case ACTION_TYPE.CREATE:
                dispatch(resetInitialLeaveSetting())
                navigate('/general-settings/leave-setting/create-leave')
                break
            case ACTION_TYPE.EDIT:
                navigate(`/general-settings/leave-setting/update-leave?leaveId=${id}`)
                break
            case ACTION_TYPE.PREVIEW:
                getPreviewLeave({}, id)
                setModal({ ...modal, preview: true })
                break
            default:
                setDeleteId(id)
                setModal({ ...modal, delete: true })
        }
    }

    const props = {
        configTable: TABLE_LEAVE_SETTING,
        dataLeave: leaveList?.data,
        isShowToast: toast?.isShowToast,
        toastMessage: toast?.message,
        previewDetail: previewLeave?.data,
        isLoading,
        isDeleting,
        modal,
        query,
        onChangePage: (page) => onChangeQuery({ page }),
        onActionRow,
        onDelete,
        onSetModal: (e) => setModal({ ...modal, ...e }),
        clearToast
    }

    return (
        <LeaveSetting {...props} />
    )
}
