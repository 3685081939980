import Table from "../../commons/table";
import {TABLE_DAY_OFF_LIST_SETTING} from "../../../configs/work-settings/configTableDayOffList";
import DeleteModal from "../../commons/delete-modal";
import {ExportModal} from "../../commons/ExportModal";
import icFileUp from "../../../assets/images/ic-file-up-02.svg";
import Button from "../../commons/button";
import Search from "../../commons/search";
import icFilter from "../../../assets/images/ic-filter-new.svg";
import {DayOffListForm} from "./DayOffListForm";
import icAddSquare from "../../../assets/images/id-add-square.svg";
import {ACTION_TYPE} from "../../../constants/constant";
import {DayOffListFilter} from "./DayOffListFilter";
import Toast from "../../commons/toast";
import clsx from "clsx";
import ViewList from "../../commons/view-list";

export const DayOffList = (
    {
        dataList,
        modal,
        isShowToast,
        toastMessage,
        activeFilter,
        selectedFilter,
        dayOffDetail,
        isLoading,
        onClickItemHandler,
        clearToast,
        query,
        isCreating,
        isUpdating,
        isDeleting,
        actionType,
        onChangeQuery,
        setShowModal,
        onCloseFilter,
        onSelectFilter,
        onSubmitFilter,
        onClearFilter,
        onDelete,
        onExport,
        onChangeValue,
        onSubmitForm,
        isFormValid
    }) => {

    return (
        <div className={"flex flex-col"}>
            <div className={"flex justify-between px-6 py-4"}>
                <div className={"flex space-x-7"}>
                    <Search value={query?.search} onSearch={(key) => onChangeQuery({search: key, page: 1})}/>
                    <div className={"flex gap-x-3"}>
                        <Button
                            icon={icFilter}
                            type="outline"
                            className={clsx(query?.year && "!px-2.5 !bg-primary-10 !border-primary-50")}
                            title={query?.year ? <div
                                className="h-5 rounded-md bg-primary-50 font-semibold text-white px-1.5">{1}</div> : ''}
                            onClick={() => setShowModal({filter: true})}
                        />
                        <ViewList
                            show={modal?.limit}
                            onShow={() => setShowModal({limit: true})}
                            onClose={() => setShowModal({limit: false})}
                            value={query?.limit}
                            onSelectSize={(limit) => onChangeQuery({page: 1, limit})}
                        />
                        <Button title="Export" icon={icFileUp} type="outline" disabled={!dataList?.total_data}
                                onClick={() => setShowModal({export: true})}/>
                    </div>
                </div>
                <Button title="Tambah Hari Libur" icon={icAddSquare}
                        onClick={() => onClickItemHandler({actionType: ACTION_TYPE.CREATE})}/>
            </div>
            <Table
                className="my-3"
                name={TABLE_DAY_OFF_LIST_SETTING.name}
                columns={TABLE_DAY_OFF_LIST_SETTING.column}
                data={dataList?.result}
                size={query?.limit}
                currentPage={query?.page}
                totalPage={dataList?.total_page}
                totalResult={dataList?.total_data}
                onChangePage={(val) => onChangeQuery({page: val})}
                onClickItem={(val) => onClickItemHandler(val)}
                onClickSort={(val) => onChangeQuery(val)}
                loading={isLoading}
            />
            <DayOffListFilter
                show={modal?.filter}
                activeFilter={activeFilter}
                selectedFilter={selectedFilter}
                onSelectFilter={onSelectFilter}
                onCloseModal={onCloseFilter}
                onSubmitFilter={onSubmitFilter}
                onClearFilter={onClearFilter}
            />
            <DeleteModal
                show={modal?.delete}
                onHide={() => setShowModal({delete: false})}
                onDelete={onDelete}
                isLoading={isDeleting}
                text="Apakah anda yakin ingin menghapus data day off?"
            />
            <ExportModal
                title="Apakah anda yakin ingin export data day off?"
                description={`Total data day off export: ${dataList?.total_data}`}
                show={modal?.export}
                onExportHandler={onExport}
                onCancelHandler={() => setShowModal({export: false})}
            />
            {
                modal?.form &&
                <DayOffListForm
                    title={`${actionType === ACTION_TYPE.CREATE ? 'Create' : 'Update'} Day Off`}
                    show={modal?.form}
                    onHide={() => setShowModal({form: false})}
                    dayOffDetail={dayOffDetail}
                    onChangeValue={onChangeValue}
                    isFormValid={isFormValid}
                    isCreating={isCreating}
                    isUpdating={isUpdating}
                    onSubmitForm={onSubmitForm}
                />
            }
            <Toast
                show={isShowToast}
                message={toastMessage}
                onClose={() => clearToast()}
            />
        </div>
    )
}
