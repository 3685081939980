import {UploadFile} from "../../../components/request-shifting/shifting-import/UploadFile";
import {useEffect, useRef, useState} from "react";
import icDocumentUploadPrimary from "../../../assets/images/ic-document-upload-primary.svg";
import icDocumentUploadGreen from "../../../assets/images/ic-document-upload-green.svg";
import icDocumentUploadGrey from "../../../assets/images/ic-document-upload-grey.svg";
import {downloadFile} from "../../../utils/utils";
import moment from "moment";
import {useFetch} from "../../../services/useFetch";
import {API_SHIFTING_IMPORT} from "../../../services/apiShiftingImport";

export const UploadFileContainer = ({payload, onChangeStep, setPayloadData}) => {
    const uploadFileRef = useRef()
    const [isDragOver, setIsDragOver] = useState(false)
    const [isShowToast, setIsShowToast] = useState(false)
    const [toastMessage, setToastMessage] = useState('')
    const [sourceFile, setSourceFile] = useState({})
    const [percentage, setPercentage] = useState(0)
    const [isUploading, setIsUploading] = useState(false)
    const {fetching: downloadTemplate, isLoading} = useFetch(API_SHIFTING_IMPORT.DOWNLOAD_TEMPLATE)

    const onClickDownload = () => {
        downloadTemplate({}, '', {}, 'blob')
            .then(response => downloadFile(null, response, `Template-Import-Shifting-${moment().format('DD-MM-YYYY')}.xlsx`))
    }

    const cancelUploadFile = () => {
        setSourceFile({})
        setPercentage(0)
        setIsUploading(false)
    }

    const onChangeUploadFile = (e) => {
        const allowedExtension = /(\.xlsx)$/i
        const sourceUploadFile = e.dataTransfer?.files[0] || e.target?.files[0]
        if (!allowedExtension.exec(sourceUploadFile?.name)) {
            setIsShowToast(true)
            setToastMessage('Wrong Extension !!')
        } else if (sourceUploadFile?.size > 64e6) {
            setIsShowToast(true)
            setToastMessage('Invalid size !!')
        } else {
            setIsUploading(true)
            setSourceFile(sourceUploadFile)
        }
    }

    const clearToast = () => {
        setIsShowToast(false)
        setToastMessage('')
    }

    const onDragAndDrop = (e) => {
        e.preventDefault()
        const eventType = e?.type

        switch (eventType) {
            case 'drop':
                onChangeUploadFile(e)
                setIsDragOver(false)
                break
            case 'dragover':
                setIsDragOver(true)
                setPercentage(0)
                break
            default:
                setIsDragOver(false)
        }
    }

    const getIconDocumentUpload = () => {
        if (isDragOver) {
            return icDocumentUploadPrimary
        } else if (sourceFile?.name) {
            return icDocumentUploadGreen
        } else {
            return icDocumentUploadGrey
        }
    }

    const onSubmitFile = () => {
        setPayloadData({file: sourceFile})
        onChangeStep(2)
    }

    useEffect(() => {
        let timeout = null
        if (isUploading && percentage < 70) {
            timeout = setTimeout(() => {
                setPercentage(percentage + 40)
            }, 1000)
        } else if (isUploading && sourceFile?.name) {
            timeout = setTimeout(() => {
                percentage === 100 ? setIsUploading(false) : setPercentage(percentage + 20)
            }, 1000)
        }

        return () => {
            clearTimeout(timeout)
        }
    }, [isUploading, percentage])

    useEffect(() => {
        setSourceFile(payload?.file)
    }, [payload])

    const props = {
        uploadFileRef,
        percentage,
        isUploading,
        isDragOver,
        isLoading,
        isShowToast,
        toastMessage,
        sourceFile,
        clearToast,
        onClickDownload,
        cancelUploadFile,
        onChangeUploadFile,
        onDragAndDrop,
        getIconDocumentUpload,
        onSubmitFile
    }

    return (
        <UploadFile {...props}/>
    )
}
