import Table from "../../commons/table";
import {TABLE_PNC_APPROVER_SETTING} from "../../../configs/matrix-approval/configTablePncApprover";
import FormModal from "../../commons/form-modal";
import Input from "../../commons/input";
import Select from "../../commons/select";
import Toast from "../../commons/toast";

export const PncApproverSetting = (
    {
        dataList,
        pncApproverDetail,
        optionList,
        isShowForm,
        isLoading,
        isShowToast,
        toastMessage,
        query,
        isUpdating,
        clearToast,
        onHideForm,
        onClickItemHandler,
        onChangeValue,
        onChangeQuery,
        onSubmitForm
    }
) => {
    return (
        <>
            <Table
                className="my-3"
                name={TABLE_PNC_APPROVER_SETTING.name}
                columns={TABLE_PNC_APPROVER_SETTING.column}
                data={dataList?.result}
                size={query?.limit}
                currentPage={query?.page}
                totalPage={dataList?.total_page}
                totalResult={dataList?.total_data}
                onChangePage={(val) => onChangeQuery({page: val})}
                onClickItem={onClickItemHandler}
                loading={isLoading}
            />
            {
                isShowForm &&
                <FormModal
                    show={isShowForm}
                    title="Pnc Approver Setting"
                    width="max-w-[639px]"
                    onHide={onHideForm}
                    onSubmitForm={onSubmitForm}
                    isDisabled={!pncApproverDetail?.employee_id}
                    isLoading={isUpdating}
                >
                    <div className={"flex flex-col px-6 py-8 gap-[20px]"}>
                        <div className="flex items-center">
                            <div className={"w-1/3 text-neutral-70"}>Category</div>
                            <Input
                                className="flex-1"
                                classNameInput="capitalize"
                                name="category_name"
                                value={pncApproverDetail?.category_name}
                                disabled
                            />
                        </div>
                        <div className="flex items-center">
                            <div className={"w-1/3 text-neutral-70"}>Employee Name</div>
                            <Select
                                name="employee_id"
                                className="flex-1"
                                optionsClassName="max-h-40"
                                placeholder="Pilih Pegawai"
                                options={optionList}
                                value={pncApproverDetail?.employee_id}
                                onChange={onChangeValue}
                                searchable
                            />
                        </div>
                    </div>
                </FormModal>
            }
            <Toast
                show={isShowToast}
                message={toastMessage}
                onClose={() => clearToast()}
            />
        </>
    )
}
