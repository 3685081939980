import { useEffect } from "react";
import {useDispatch, useSelector} from "react-redux";
import { useSearchParams } from "react-router-dom";
import { EmployeeDetail } from "../../../components/employee/employee-detail/EmployeeDetail";
import {setEmployeeDetail, setToast} from "../../../reducers/employeeDetailReducer";
import { API_EMPLOYEE } from "../../../services/apiEmployee";
import { useFetch } from "../../../services/useFetch";
import { EmployeeDetailTabContainer } from "./employee-detail-tab/EmployeeDetailTabContainer";
import Toast from "../../../components/commons/toast";

export const EmployeeDetailContainer = () => {
    const {toast} = useSelector(state => state.employeeDetailReducer)
    const [searchParams] = useSearchParams();
    const userId = searchParams.get('userId')
    const dispatch = useDispatch()
    const { data: employeeDetail } = useFetch({ ...API_EMPLOYEE.DETAIL, initPathVariable: userId }, {}, { isFetching: true })

    const clearToast = () => {
        dispatch(setToast({isShowToast: false, message: ''}))
    }

    useEffect(() => {
        if (!!employeeDetail.data) dispatch(setEmployeeDetail(employeeDetail.data))
    }, [employeeDetail.data]);

    return (
        <>
            {
                !!employeeDetail.data &&
                <div className="bg-neutral-10">
                    <EmployeeDetail />
                    <EmployeeDetailTabContainer />
                </div>
            }
            <Toast
                show={toast?.isShowToast}
                message={toast?.message}
                onClose={() => clearToast()}
            />
        </>
    )
}
