import {Alert} from "components/commons/alert";
import moment from "moment";
import {useEffect, useState} from "react";
import {useLocation, useSearchParams} from "react-router-dom";
import {API_REQUEST_ATTENDANCE} from "services/apiRequestAttendance";
import {useFetch} from "services/useFetch";
import {capitalize, downloadFile, getAllQueryParams, setQueryParams} from "utils/utils";
import {RequestList} from "../../../components/attendance/request-list/RequestList";
import {TABLE_REQUEST_LIST} from "../../../configs/attendance/configTableRequestList";

const OPTION_BULK = {
    APPROVE: 'approve',
    REJECT: 'reject',
}

const TAB_LIST = [
    {id: 'submitted', name: 'submitted'},
    {id: 'approved', name: 'approved'},
    {id: 'rejected', name: 'rejected'},
    {id: 'cancelled', name: 'cancelled'},
]

const PREVIEW_TAB = [
    {id: 'information', name: 'informasi'},
    {id: 'history', name: 'history'},
]

const FILTER_PARAMS = ['department_ids', 'division_ids', 'subdivision_ids', 'schedule_category_ids', 'start_date', 'end_date']

export const RequestListContainer = () => {
    const location = useLocation()
    const [searchParams, setSearchParams] = useSearchParams()
    const status = searchParams.get('status')
    const defaultQuery = {
        page: 1,
        limit: 20,
        order_by: "created_at",
        order_type: "desc",
        status: TAB_LIST[0].id,
    }
    const query = searchParams.size > 0 ? getAllQueryParams(searchParams) : defaultQuery
    const [selectedEmployeeList, setSelectedEmployeeList] = useState([])
    const [selectedBulkOption, setSelectedBulkOption] = useState()
    const [selectedPreviewFile, setSelectedPreviewFile] = useState()
    const [reasonReject, setReasonReject] = useState('')
    const [toastMessage, setToastMessage] = useState('')
    const [activePreviewTab, setActivePreviewTab] = useState(PREVIEW_TAB[0].id)
    const [queryFilter, setQueryFilter] = useState()
    const [modal, setModal] = useState({
        limit: false,
        export: false,
        filter: false,
        toast: false,
        preview: false,
        previewFile: false,
    })
    const {
        data: dataRequestAttendance,
        fetching: getRequestAttendanceList,
        isLoading
    } = useFetch(API_REQUEST_ATTENDANCE.REQUEST_ATTENDANCE_LIST)
    const {fetching: exportRequestAttendance} = useFetch(API_REQUEST_ATTENDANCE.EXPORT_REQUEST_ATTENDANCE_LIST)
    const {
        fetching: getDetailRequestAttendance,
        data: dataDetail,
        isLoading: isLoadingDetail
    } = useFetch(API_REQUEST_ATTENDANCE.DETAIL_REQUEST_ATTENDANCE)
    const {
        fetching: approveRequestAttendance,
        isLoading: isLoadingApprove
    } = useFetch(API_REQUEST_ATTENDANCE.APPROVE_REQUEST_ATTENDANCE)
    const {
        fetching: rejectRequestAttendance,
        isLoading: isLoadingReject
    } = useFetch(API_REQUEST_ATTENDANCE.REJECT_REQUEST_ATTENDANCE)

    useEffect(() => {
        getList()
    }, [location.search])

    const getList = () => {
        const newQuery = {...query}
        const newQueryFilter = {}
        Object.entries(newQuery).forEach(([key, item]) => {
            if (FILTER_PARAMS.includes(key)) {
                const valueOfQuery = FILTER_PARAMS.slice(0, 4).includes(key) ? item.split(',') : item
                newQuery[key] = valueOfQuery
                newQueryFilter[key] = valueOfQuery
            }
        })
        setQueryFilter(newQueryFilter)

        getRequestAttendanceList({
            ...newQuery,
            start_date: newQuery?.start_date || moment().startOf('month').format('YYYY-MM-DD'),
            end_date: newQuery?.end_date || moment().endOf('month').format('YYYY-MM-DD'),
            department_ids: newQuery?.department_ids || [],
            division_ids: newQuery?.division_ids || [],
            subdivision_ids: newQuery?.subdivision_ids || [],
            schedule_category_ids: newQuery?.schedule_category_ids || [],
        })
    }

    const onChangeTab = (status) => {
        const params = {...defaultQuery, status: status.id}
        setSearchParams(params, {replace: true})
    }

    const onChangeQuery = (queryParams) => {
        const params = setQueryParams({...query, ...queryParams})
        setSearchParams(params, {replace: true})
    }

    const clearToast = () => {
        setModal({...modal, toast: false})
        setToastMessage('')
    }

    const setShowToast = (message, itemModal) => {
        setModal({...modal, ...itemModal, toast: true})
        setToastMessage(message)
    }

    const onExport = (e) => {
        const params = {
            ...query,
            start_date: queryFilter?.start_date || moment().format('YYYY-MM-DD'),
            end_date: queryFilter?.end_date || moment().format('YYYY-MM-DD'),
            department_ids: queryFilter?.department_ids || [],
            division_ids: queryFilter?.division_ids || [],
            subdivision_ids: queryFilter?.subdivision_ids || [],
            schedule_category_ids: queryFilter?.schedule_category_ids || [],
            attendance_request_ids: selectedEmployeeList.map((item) => item.id)
        }

        exportRequestAttendance(params, '', {}, 'blob')
            .then(res => {
                downloadFile(null, res, `Request-Attendance-List-Export-${moment().format('DD-MM-YYYY')}.xlsx`)
                setShowToast(`Request Attendance ${capitalize(status || TAB_LIST[0].id)} Exported`, {export: false})
            })
    }

    const onBulkUpdate = (option) => {
        if (selectedEmployeeList.length === 0 && !modal?.preview) {
            return Alert({
                icon: 'warning',
                title: 'Silakan pilih employee terlebih dahulu.',
                autoClose: true,
            })
        }
        setReasonReject('')
        setSelectedBulkOption(option)
        setModal({...modal, bulk_update: true})
    }

    const onApproval = (type, id = null) => {
        const params = {
            attendance_request_ids: id ? [id] : selectedEmployeeList.map((item) => item.id),
            ...(type === OPTION_BULK.REJECT && {reject_reason: reasonReject})
        }

        const fetch = type === OPTION_BULK.REJECT ? rejectRequestAttendance : approveRequestAttendance
        fetch(params).then(() => {
            setShowToast(`Request Attendance ${type === OPTION_BULK.REJECT ? 'Rejected' : 'Approved'}`, {bulk_update: false, preview: false})
            getList()
        })
    }

    const onPreview = (item) => {
        getDetailRequestAttendance({}, item.id)
        setActivePreviewTab(PREVIEW_TAB[0].id)
        setModal({...modal, preview: true})
    }

    const onPreviewFile = (item) => {
        setSelectedPreviewFile(item)
        setModal({...modal, previewFile: true})
    }

    const props = {
        tabList: TAB_LIST,
        dataEmployee: dataRequestAttendance?.data,
        isLoading,
        query,
        status: status || TAB_LIST[0].id,
        optionBulk: OPTION_BULK,
        configTable: TABLE_REQUEST_LIST,
        previewTab: PREVIEW_TAB,
        dataDetail: dataDetail?.data,
        isLoadingDetail,
        isLoadingApproval: isLoadingApprove || isLoadingReject,
        activePreviewTab,
        setActivePreviewTab,
        selectedEmployeeList,
        selectedBulkOption,
        reasonReject,
        toastMessage,
        clearToast,
        onChangeTab,
        onChangeQuery,
        onSelectItem: (employeeList) => setSelectedEmployeeList([...employeeList]),
        onExport,
        onBulkUpdate,
        onApproval,
        onPreview,
        setReasonReject,
        modal,
        onSetModal: (e) => setModal({...modal, ...e}),
        queryFilter,
        onPreviewFile,
        selectedPreviewFile
    }

    return (
        <RequestList {...props} />
    )
}
