import { METHOD } from "./method";

export const API_SHIFTING_IMPORT = {
    DOWNLOAD_TEMPLATE: {
        api: '/v1/hr-adm/shifting/import/download-template',
        method: METHOD.GET
    },
    IMPORT_VALIDATION: {
        api: '/v1/hr-adm/shifting/import/validate',
        method: METHOD.POST
    },
    IMPORT: {
        api: '/v1/hr-adm/shifting/import',
        method: METHOD.POST
    },
}
