import { useEffect } from 'react'

export const useClickOutside = ({
    callback,
    elementID,
}) => {
    useEffect(() => {
        const clickListener = (e) => {
            const targetElement = e.target
            const outsideElement = document.getElementById(elementID)

            if (outsideElement && !outsideElement.contains(targetElement)) {
                callback()
            }
        }

        document.addEventListener('click', clickListener, true)

        return () => {
            document.removeEventListener('click', clickListener, true)
        }
    }, [callback, elementID])
}
