import {IncentiveList} from "../../../components/payroll/incentive/IncentiveList";
import {useEffect, useState} from "react";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {useFetch} from "../../../services/useFetch";
import {API_INCENTIVE} from "../../../services/apiIncentive";
import {getAllQueryParams, setQueryParams} from "../../../utils/utils";
import {setPayrollModalImport, setPayrollToast} from "../../../reducers/payrollReducer";
import {useDispatch, useSelector} from "react-redux";

const defaultQuery = {
    page: 1,
    search: '',
    limit: 10,
}
export const IncentiveListContainer = ({type, lastPath}) => {
    const dispatch = useDispatch()
    const location = useLocation()
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams();
    const query = searchParams.size > 0 ? getAllQueryParams(searchParams) : defaultQuery
    const [column, setColumn] = useState([])
    const [show, setShow] = useState(false)
    const {toast} = useSelector(state => state.payrollReducer)
    const [formValue, setFormValue] = useState({})
    const api = `${API_INCENTIVE.INCENTIVE_LIST.api}/${lastPath}`
    const {data, fetching: getMonthlyIncentiveApi} = useFetch({...API_INCENTIVE.INCENTIVE_LIST, api: `${api}/list`}, defaultQuery)
    const {fetching: getDetailApi} = useFetch({...API_INCENTIVE.INCENTIVE_DETAIL, api: `${api}`})
    const {fetching: updateIncentiveApi} = useFetch({...API_INCENTIVE.UPDATE_INCENTIVE, api: `${api}` })

    useEffect(() => {
        if(lastPath !== 'incentive'){
            getIncentive()
        }
    }, [location.search, lastPath]);

    const getIncentive = () => {
        getMonthlyIncentiveApi({...query})
            .then(response => {
                if(!!response){
                    setColumn(response?.data?.result?.column_map)
                }
            })
    }

    const createPercentageBarrier = () => {
        navigate(`/payroll/incentive/percentage-ketercapaian?type=${type}`)
    }

    const onChangeQuery = (queryParams) => {
        const params = setQueryParams({...query, ...queryParams})
        setSearchParams(params, {replace: true})
    }

    const onClickItemHandler = (item) => {
        getDetailApi({}, item.id)
            .then(response => {
                setFormValue(response.data)
                setShow(true)
            })
    }

    const onHide = () => {
        setShow(false)
    }

    const onChangeForm = (e) => {
        let value = formValue.values
        value[Number(e.name)].value = Number(e.value)
        setFormValue({...formValue, values: value})
    }

    const onSubmitForm = () => {
        updateIncentiveApi(formValue, formValue?.grade_id)
            .then(() => {
                getIncentive()
                onHide()
                dispatch(setPayrollToast({isShowToast: true, message: 'Data Incentive is Updated'}))
            })
    }

    const showModalImport = () => {
        dispatch(setPayrollModalImport(true))
    }

    const clearToast = () => {
        dispatch(setPayrollToast({isShowToast: false, message: ''}))
    }

    const propsIncentive = {
        type,
        query,
        onChangeQuery,
        data: data?.data ?? {},
        isLoading: data?.isLoading,
        column,
        onClickItemHandler,
        createPercentageBarrier,
        show,
        onHide,
        onSubmitForm,
        formValue,
        onChangeForm,
        toast,
        clearToast,
        showModalImport,
        getIncentive
    }

    return (
        <IncentiveList {...propsIncentive}/>
    )
}
