import {AttendanceList} from "../../../components/attendance/attendance-list/AttendanceList";
import Search from "../../../components/commons/search";
import {downloadFile, getAllQueryParams, getValueFromArray, setQueryParams} from "../../../utils/utils";
import {useLocation, useSearchParams} from "react-router-dom";
import {AttendanceFilterContainer} from "./AttendanceFilterContainer";
import ViewList from "../../../components/commons/view-list";
import Button from "../../../components/commons/button";
import icFileUp from "../../../assets/images/ic-file-up-02.svg";
import {useDispatch, useSelector} from "react-redux";
import {ExportModal} from "../../../components/commons/ExportModal";
import {setAttendanceList, setModal, setSelectedRows, setTotalData} from "../../../reducers/attendanceReducer";
import { useEffect, useState } from "react";
import { useFetch } from "services/useFetch";
import { API_ATTENDANCE } from "services/apiAttendance";
import moment from "moment";
import Toast from "components/commons/toast";

const FILTER_PARAMS = ['department_ids', 'division_ids', 'subdivision_ids', 'schedule_category_ids', 'start_date', 'end_date', ]

export const AttendanceListContainer = () => {
    const dispatch = useDispatch()
    const location = useLocation()
    const [searchParams, setSearchParams] = useSearchParams();
    const defaultQuery = {
        page: 1,
        limit: 20,
    }
    const [queryFilter, setQueryFilter] = useState()
    const query = searchParams.size > 0 ? getAllQueryParams(searchParams) : defaultQuery
    const {modal, selectedRows, totalData, attendanceList} = useSelector(state => state.attendanceReducer)
    const {fetching: getAttendanceListApi, isLoading} = useFetch(API_ATTENDANCE.ATTENDANCE_LIST)
    const { fetching: exportAttendanceList } = useFetch(API_ATTENDANCE.EXPORT_ATTENDANCE_LIST)

    useEffect(() => {
        getAttendanceList()
    }, [location.search]);

    const getAttendanceList = async () => {
        const newQuery = {...query}
        const newQueryFilter = {}
        Object.entries(newQuery).forEach(([key, item]) => {
            if (FILTER_PARAMS.includes(key)) {
                const valueOfQuery = FILTER_PARAMS.slice(0, 4).includes(key) ? item.split(',') : item
                newQuery[key] = valueOfQuery
                newQueryFilter[key] = valueOfQuery
            }
        })
        setQueryFilter(newQueryFilter)
        const response = await getAttendanceListApi({
            ...newQuery,
            start_date: newQuery?.start_date || moment().format('YYYY-MM-DD'),
            end_date: newQuery?.end_date || moment().format('YYYY-MM-DD'),
            department_ids: newQuery?.department_ids || [],
            division_ids: newQuery?.division_ids || [],
            subdivision_ids: newQuery?.subdivision_ids || [],
            schedule_category_ids: newQuery?.schedule_category_ids || []
        })
        dispatch(setAttendanceList(response.data))
        dispatch(setTotalData(response?.data?.total_data))
    }

    const onChangeQuery = (queryParams) => {
        const params = setQueryParams({...query, ...queryParams})
        setSearchParams(params, {replace: true})
    }

    const modalVisibility = (value) => {
        dispatch(setModal({...modal, ...value}))
    }

    const onExportHandler = () => {
        const params = {
            ...query,
            start_date: queryFilter?.start_date || moment().format('YYYY-MM-DD'),
            end_date: queryFilter?.end_date || moment().format('YYYY-MM-DD'),
            department_ids: queryFilter?.department_ids || [],
            division_ids: queryFilter?.division_ids || [],
            subdivision_ids: queryFilter?.subdivision_ids || [],
            schedule_category_ids: queryFilter?.schedule_category_ids || [],
            attendance_ids: getValueFromArray(selectedRows)
        }

        exportAttendanceList(params, '', {}, 'blob')
            .then(res => {
                downloadFile(null, res, `Attendance-List-Export-${moment().format('DD-MM-YYYY')}.xlsx`)
                modalVisibility({export: false, toast: true})
            })
    }

    return (
        <>
            <div className={"flex flex-row space-x-6 px-6 py-4"}>
                <Search value={query?.search} onSearch={(search) => onChangeQuery({page: 1, search})}/>
                <div className={"flex flex-row gap-x-2"}>
                    <AttendanceFilterContainer
                        queryFilter={queryFilter}
                        onSubmit={(queryFilter) => onChangeQuery({...queryFilter, page: 1})}
                    />
                    <ViewList
                        show={modal?.sort}
                        onShow={() => modalVisibility({sort: true})}
                        onClose={() => modalVisibility({sort: false})}
                        value={query?.limit}
                        onSelectSize={(limit) => onChangeQuery({page: 1, limit})}
                    />
                    <Button title="Export" icon={icFileUp} type="outline" disabled={!attendanceList?.total_data}
                            onClick={() => modalVisibility({export: true})}/>
                </div>
            </div>
            <AttendanceList
                isLoading={isLoading}
                attendanceList={attendanceList}
                query={query}
                onChangePage={(page) => onChangeQuery({page})}
                onSelectItem={(e) => dispatch(setSelectedRows(e))}
            />
            <ExportModal title={'Apakah anda yakin ingin export data attendance?'}
                         description={`Total data export attendance: ${selectedRows.length > 0 ? selectedRows.length : totalData}`}
                         show={modal?.export} onExportHandler={onExportHandler}
                         onCancelHandler={() => modalVisibility({export: false})}/>
            <Toast
                show={modal?.toast}
                message={`Anda berhasil export attendance`}
                onClose={() => modalVisibility({ toast: false })}
            />
        </>
    )
}
