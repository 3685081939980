import axios from "libs/axios"
import {useEffect, useReducer} from "react"
import {useLocation, useNavigate} from "react-router-dom"
import {METHOD} from "./method"
import {Alert} from "components/commons/alert"
import CONFIG from "../configs/env";
import {STEPS_CREATE_PAYROLL} from "../constants/constant";
import {getPathUrl} from "../utils/utils";
import {API} from "./api";

const initialData = {
    isLoading: false,
    data: null,
    error: null,
}

const ACTION_TYPE_RESPONSE = {
    SUCCESS: 'SUCCESS',
    LOADING: 'LOADING',
    ERROR: 'ERROR'
}

const responseReducer = (state, action) => {
    switch (action.type) {
        case ACTION_TYPE_RESPONSE.SUCCESS:
            return {
                isLoading: false,
                data: action.payload,
                error: null,
            }
        case ACTION_TYPE_RESPONSE.LOADING:
            return {
                ...state,
                isLoading: true,
            }
        case ACTION_TYPE_RESPONSE.ERROR:
            return {
                isLoading: false,
                data: null,
                error: action.payload,
            }
        default:
            return state
    }
}
const defaultOptions = {
    isFetching: false,
    isLoading: false,
}

const errorAuthMsg = {
    '401-A004': 'Anda Tidak Memiliki Akses',
    '401-A005': 'Akun Anda Ditangguhkan'
}

export const useFetch = ({api, method, initPathVariable}, initPayload, options = defaultOptions) => {
    const location = useLocation()
    const navigate = useNavigate()
    const monthName = getPathUrl(location, '-', -2).join('-')
    const [data, dispatchResponse] = useReducer(responseReducer, {...initialData, isLoading: options.isLoading})
    let attempt = 0

    useEffect(() => {
        if (options.isFetching) fetching()
    }, [])

    const fetching = async (payload, pathVariable, header, responseType, additionalQueryParams = {}) => {
        dispatchResponse({type: ACTION_TYPE_RESPONSE.LOADING})

        const clearAllReducer = () => {
            localStorage.clear()
        }

        const errorMessage = (response) => {
            if (response?.code === 'ERR_NETWORK') {
                return 'Something Wrong, Please Check Your Connection'
            } else {
                return response?.data?.message || 'Terjadi Kesalahan'
            }
        }

        const handlingError = (response) => {
            dispatchResponse({type: ACTION_TYPE_RESPONSE.ERROR, payload: response?.data})
            Alert({
                icon: 'error',
                title: 'Oops!',
                message: errorMessage(response),
            })
            throw response?.data || response;
        };

        const request = async () => {
            const requestKey = (method === METHOD.GET) ? 'params' : 'data'
            try {
                const response = await axios({
                    url: `${api}${initPathVariable || pathVariable ? ('/' + (initPathVariable || pathVariable)) : ''}`,
                    method: method,
                    [requestKey]: {
                        ...initPayload,
                        ...payload
                    },
                    [Object.keys(additionalQueryParams).length > 0 ? 'params' : null]: {
                        ...additionalQueryParams
                    },
                    headers: {
                        ...header,
                    },
                    responseType
                })
                dispatchResponse({type: ACTION_TYPE_RESPONSE.SUCCESS, payload: response.data.data})
                return response.data
            } catch (error) {
                if (attempt === 2) {
                    handlingError(error?.response);
                    throw error?.response?.data;
                }
                switch (error?.response?.status) {
                    case 400:
                        switch (error.response.data.code) {
                            case '400-V002':
                                return
                            case '400-V015':
                                Alert({
                                    icon: 'error',
                                    title: error.response.data?.message,
                                    message: `current step is step ${error.response.data.data?.redirect_step}`,
                                    buttonConfirmText: 'OK',
                                    autoClose: false,
                                    backdrop: false
                                }).then(() => {
                                    navigate(`/payroll/create-payroll/${STEPS_CREATE_PAYROLL[error.response.data.data?.redirect_step - 1]}-${monthName}?payrollId=${error.response.data.data?.payroll_id}`)
                                })
                                break
                            default:
                                handlingError(error?.response)
                        }
                        break;
                    case 401:
                        switch (error.response.data.code) {
                            case '401-A002':
                            case '401-A003':
                            case '401-A004':
                            case '401-A005':
                                Alert({
                                    icon: 'error',
                                    title: 'Authentication Error !',
                                    message: errorAuthMsg[error.response.data.code],
                                    buttonConfirmText: 'OK',
                                    autoClose: false,
                                }).then((isConfirm) => {
                                    console.log("isConfirm :", isConfirm)
                                    window.location.href = `${CONFIG.API_ENDPOINT}${API.LOGOUT.api}`
                                })
                                break;
                            default:
                                handlingError(error?.response);
                        }
                        break;
                    case 403:
                        if (error.response.data.code !== "403-A001") {
                            clearAllReducer()
                            navigate('/', {replace: true})
                            throw error?.response?.data;
                        }
                        handlingError(error?.response);
                        break;
                    case 503:
                        if (error.response.data.code === "503-0000") {
                            navigate('/', {replace: true})
                            dispatchResponse({type: ACTION_TYPE_RESPONSE.ERROR, payload: error?.response?.data})
                            throw error?.response?.data;
                        }
                        handlingError(error?.response);
                        break;
                    default:
                        handlingError(error?.response || error);
                }
            }
        }
        return await request()
    }

    return {data, fetching, isLoading: data.isLoading}
}
