import icEditPrimary from "assets/images/ic-edit-04-primary.svg"
import icTrashRed from "assets/images/ic-trash-01-red.svg"
import Button from "components/commons/button"
import { ACTION_TYPE } from "constants/constant"

export const TABLE_ROLE_ACCESS = {
    name: 'Total Role',
    column: [
        {
            name: "Role",
            key: "name",
            className: "font-medium !text-neutral-80",
            classNameHeader: "lg:w-[340px]",
        },
        {
            name: "Access",
            key: "permission_count",
            className: "font-medium !text-neutral-80",
            classNameHeader: "lg:w-[240px]",
            render: ({ value }) => `${value} Access`
        },
        {
            name: "Total User",
            key: "employee_count",
            className: "font-medium !text-neutral-80",
            render: ({ value }) => `${value} Users`,
        },
        {
            name: "Action",
            key: "action",
            classNameHeader: "!text-center w-[150px]",
            render: ({ item, onClickItem }) => (
                <div className="flex justify-center gap-3 mx-auto">
                    <Button type="outline" icon={icEditPrimary} onClick={() => onClickItem({ item, actionType: ACTION_TYPE.EDIT })} />
                    <Button type="danger" icon={icTrashRed} onClick={() => onClickItem({ item, actionType: ACTION_TYPE.DELETE })} />
                </div>
            )
        }
    ]
}