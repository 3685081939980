import Search from "../../commons/search";
import Button from "../../commons/button";
import icFileUp from "assets/images/ic-file-up-02.svg";
import icAddSquare from "assets/images/id-add-square.svg";
import {ExportModal} from "../../commons/ExportModal";
import DeleteModal from "../../commons/delete-modal";
import ViewList from "../../commons/view-list";
import Table from "../../commons/table";
import {TABLE_WORK_UNIT_SETTING} from "../../../configs/organization-structure/configTableWorkUnit";
import {InlineTabs} from "../../commons/InlineTabs";

export const WorkUnitSetting = (props) => {
    const {
        onChangeTab,
        tabList,
        tab,
        selectedTab,
        modal,
        onShowModal,
        buttonAddHandler,
        onExportHandler,
        jobUnitResponse,
        actionButtonHandler,
        deleteModalHide,
        onDelete,
        query,
        onChangeQuery
    } = props

    return (
        <div>
            <InlineTabs tabList={tabList} activeTab={tab} onClickTab={onChangeTab}/>
            <div className={'flex flex-row justify-between gap-3 px-6 py-4'}>
                <div className={'flex flex-row gap-3'}>
                    <Search onSearch={(search) => onChangeQuery({page: 1, search})} value={query?.search}/>
                    <ViewList
                        show={modal?.sort}
                        onShow={() => onShowModal('sort', true)}
                        onClose={() => onShowModal('sort', false)}
                        value={query?.limit}
                        onSelectSize={(limit) => onChangeQuery({page: 1, limit})}
                    />
                    <Button title="Export" icon={icFileUp} type="outline"
                            onClick={() => onShowModal('export', true)}/>
                </div>
                <Button title={`Add ${selectedTab.name}`} icon={icAddSquare} onClick={buttonAddHandler}/>
            </div>
            <Table
                className="my-3"
                data={jobUnitResponse?.data?.result}
                columns={TABLE_WORK_UNIT_SETTING(selectedTab?.name).column}
                totalResult={jobUnitResponse?.data?.total_result}
                name={TABLE_WORK_UNIT_SETTING(selectedTab?.name).name}
                size={query?.limit}
                currentPage={query?.page}
                onChangePage={(page) => onChangeQuery({page})}
                onClickItem={actionButtonHandler}
                totalPage={jobUnitResponse?.data?.total_page}
                loading={jobUnitResponse?.isLoading}
            />
            <ExportModal title={`Apakah anda yakin ingin export data ${selectedTab.name}?`}
                         description={`Total data work unit export: ${jobUnitResponse?.data?.total_result}`}
                         show={modal?.export}
                         onExportHandler={onExportHandler}
                         onCancelHandler={() => onShowModal('export', false)}/>
            <DeleteModal show={modal.delete} onHide={deleteModalHide} onDelete={onDelete}
                         text={`Apakah anda yakin ingin menghapus data ${selectedTab.name}?`}/>
        </div>
    )
}
