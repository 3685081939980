import ErrorMessage from "components/commons/error-message";
import FormModal from "components/commons/form-modal";
import Input from "components/commons/input";
import Select from "components/commons/select";
import Switch from "components/commons/switch";
import { OTHER_RELATIONSHIP } from "constants/defaultFormEmployee";


export const CreateFamily = ({
    show,
    onHide,
    optionsFamilyRelationship,
    dataFamily,
    onChangeForm,
    onSubmit,
    errorForm,
    isFormValid,
    isEdit,
    isLoadingSubmit,
    useSameAsAddress = false,
    title = 'Data Keluarga'
}) => {
    const isOtherRelationship = dataFamily?.relationship?.value === OTHER_RELATIONSHIP

    return (
        <FormModal
            show={show}
            onHide={onHide}
            title={`${isEdit ? 'Edit' : 'Tambah'} ${title}`}
            onSubmitForm={onSubmit}
            isLoading={isLoadingSubmit}
            isDisabled={!isFormValid}
        >
            <div className={"px-8 py-6 mb-2 space-y-6"}>
                <div className="flex items-center gap-3">
                    <div className="font-medium w-1/3 text-neutral-70">{'Nama'}</div>
                    <Input
                        className='flex-1'
                        name="full_name"
                        placeholder={"Isi Nama"}
                        maxLength={60}
                        pattern={/^[A-Za-z' ]+$/}
                        value={dataFamily?.full_name}
                        onChange={onChangeForm}
                    />
                </div>
                <div className="flex items-center gap-3">
                    <div className="font-medium w-1/3 text-neutral-70">{'Posisi dalam keluarga'}</div>
                    <Select name="relationship"
                        className='flex-1'
                        placeholder="Pilih posisi keluarga"
                        options={optionsFamilyRelationship}
                        value={dataFamily?.relationship}
                        onChange={onChangeForm}
                    />
                </div>
                {isOtherRelationship && <div className="flex items-center gap-3">
                    <div className="w-1/3" />
                    <Input
                        className='flex-1'
                        name="other_relationship"
                        placeholder={"input menyesuaikan lainnya"}
                        maxLength={60}
                        pattern={/^[A-Za-z ]+$/}
                        value={dataFamily?.other_relationship}
                        onChange={onChangeForm}
                    />
                </div>}
                <div className="flex gap-3">
                    <div className="font-medium w-1/3 text-neutral-70 mt-2">{'Nomor HP'}</div>
                    <div className="flex-1">
                        <Input
                            addonBefore={'+62'}
                            name="phone_number"
                            type="tel"
                            placeholder={"Isi nomor telepon"}
                            maxLength={14}
                            value={dataFamily?.phone_number}
                            onChange={onChangeForm}
                        />
                        <ErrorMessage className="mt-2" message={errorForm?.phone_number} />
                    </div>
                </div>
                <div className="flex gap-3">
                    <div className="font-medium w-1/3 text-neutral-70 mt-2">{'Detail Alamat'}</div>
                    <Input
                        className='flex-1'
                        name="address"
                        type="text-area"
                        row={3}
                        maxLength={300}
                        showCount
                        placeholder={"Detail Alamat"}
                        value={dataFamily?.address}
                        onChange={onChangeForm}
                    />
                </div>
                {!isEdit && useSameAsAddress && <div className="flex items-center gap-5">
                    <Switch name="is_emergency_contact" checked={dataFamily?.is_emergency_contact} onChange={onChangeForm} />
                    <span className="font-medium">{'Jadikan kontak darurat'}</span>
                </div>}
            </div>
        </FormModal>
    )
}
