import {METHOD} from "./method";

export const API_COMPANY = {
    LIST: {
        api: '/v1/hr-adm/company',
        method: METHOD.GET
    },
    CREATE: {
        api: '/v1/hr-adm/company',
        method: METHOD.POST
    },
    UPDATE: {
        api: '/v1/hr-adm/company',
        method: METHOD.PUT
    },
    DELETE: {
        api: '/v1/hr-adm/company',
        method: METHOD.DELETE
    },
    UPLOAD_COMPANY_LOGO: {
        api: '/v1/hr-adm/company/storage-url/logo',
        method: METHOD.POST
    },
}
