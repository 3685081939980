import {SettingComponent} from "../../../components/payroll/setting-component/SettingComponent";
import {getAllQueryParams, setQueryParams} from "../../../utils/utils";
import {useLocation, useSearchParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {useValidation} from "../../../hooks/useValidation";
import {useFetch} from "../../../services/useFetch";
import {API_COMPONENT_SETTING} from "../../../services/apiComponentSetting";
import {Alert} from "../../../components/commons/alert";

const defaultQuery = {
    page: 1,
    search: '',
    limit: 10,
    order_by: 'id',
    order_type: 'asc'
}

const defaultFormValue = {
    title: null,
    type: null,
    is_enabled: false
}

const componentType = [
    {
        value: 'earnings',
        label: 'Earnings'
    }, {
        value: 'deduction',
        label: 'Deduction'
    }
]
export const SettingComponentContainer = () => {
    const location = useLocation()
    const [searchParams, setSearchParams] = useSearchParams();
    const [showModal, setShowModal] = useState(false)
    const [formValue, setFormValue] = useState(defaultFormValue)
    const [toast, setToast] = useState({
        isShowToast: false,
        message: null
    })
    const {isFormValid} = useValidation({form: formValue})
    const query = searchParams.size > 0 ? getAllQueryParams(searchParams) : defaultQuery
    const {
        data: componentData,
        fetching: getComponentListApi,
        isLoading
    } = useFetch(API_COMPONENT_SETTING.COMPONENT_SETTING_LIST, defaultQuery)
    const {data: categoryData, fetching: getCategoryListApi} = useFetch(API_COMPONENT_SETTING.CATEGORY_LIST)
    const { fetching: createComponentApi} = useFetch(API_COMPONENT_SETTING.CREATE_COMPONENT)
    const { fetching: updateComponentApi} = useFetch(API_COMPONENT_SETTING.UPDATE_COMPONENT)

    useEffect(() => {
        getComponentListApi({...query})
    }, [location.search]);

    const onChangeQuery = (queryParams) => {
        const params = setQueryParams({...query, ...queryParams})
        setSearchParams(params, {replace: true})
    }

    const onChangeFormValue = (value) => {
        setFormValue({...formValue, [value.name]: value.value})
    }

    const onClickItemHandler = async (item) => {
        await getCategoryListApi()
        let typeObject = componentType.filter((i) => i.value === item.type)
        setFormValue({...item, type: typeObject[0]})
        setShowModal(true)
    }

    const addComponentHandler = async () => {
        await getCategoryListApi()
        setFormValue(defaultFormValue)
        setShowModal(true)
    }

    const clearToast = () => {
      setToast({isShowToast: false, message: null})
    }

    const onSubmitForm = () => {
        let requestBody = {...formValue, type: formValue.type.value, category: formValue?.category?.value ?? null}
        if(formValue?.id){
            updateComponent(requestBody)
        } else {
            createComponent(requestBody)
        }
    }

    const createComponent = (requestBody) => {
        createComponentApi(requestBody)
            .then(response => {
                getComponentListApi({...query})
                setToast({isShowToast: true, message: 'Komponen berhasil dibuat'})
                setShowModal(false)
            })
            .catch(err => {
                Alert({
                    icon: 'error',
                    title: 'Oops!',
                    message: 'Cannot make component with same category',
                })
            })
    }

    const updateComponent = (requestBody) => {
        updateComponentApi(requestBody, requestBody?.id)
            .then(response => {
                getComponentListApi({...query})
                setToast({isShowToast: true, message: 'Komponen berhasil diubah'})
                setShowModal(false)
            })
    }


    const props = {
        onChangeQuery,
        query,
        data: componentData?.data,
        isLoading,
        onClickItemHandler,
        addComponentHandler,
        show: showModal,
        modalVisibility: (value) => setShowModal(value),
        formValue,
        onChangeFormValue,
        componentType,
        componentCategory: [{label: 'None', value: null}].concat(categoryData?.data),
        isFormValid,
        onSubmitForm,
        onClickSort: (sortQuery) => onChangeQuery({...sortQuery, page: 1}),
        toast,
        clearToast
    }
    return (
        <SettingComponent {...props}/>
    )
}
