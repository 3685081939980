import clsx from "clsx"
import './badge.scss'

const Badge = ({className, type = 'draft', title}) => {
    return (
        <div className={clsx("avo__badge", className, type)}>
            <div className={clsx('badge_content', className)}>
                {title}
            </div>
        </div>
    )
}

export default Badge
