import icAddSquare from "assets/images/id-add-square.svg"
import Button from "components/commons/button"
import DeleteModal from "components/commons/delete-modal"
import FormModal from "components/commons/form-modal"
import Input from "components/commons/input"
import Table from "components/commons/table"
import {ACTION_TYPE} from "../../../constants/constant";
import Toast from "../../commons/toast";

export const OvertimeLocation = (
    {
        configTable,
        dataOvertimeLocation,
        query,
        form,
        modal,
        actionType,
        isShowToast,
        toastMessage,
        clearToast,
        onChangePage,
        onActionRow,
        onDelete,
        onSetModal,
        onChangeForm,
        onSubmitForm,
        isLoadingTable,
        isCreating,
        isUpdating,
        isDeleting
    }) => {

    return (
        <>
            <div className="flex items-center justify-between p-6">
                <div className="font-semibold text-md text-neutral-80">Pengaturan Lokasi Lembur</div>
                <Button title="Create Lokasi Lembur" icon={icAddSquare} onClick={() => onActionRow({actionType: ACTION_TYPE.CREATE})}/>
            </div>

            <Table
                className="my-3"
                classNameHeader="first:!pl-6 last:!pr-6"
                classNameRow="first:!pl-6 last:!pr-6"
                name={configTable.name}
                data={dataOvertimeLocation?.result}
                columns={configTable.column}
                size={query?.limit}
                currentPage={query?.page}
                totalPage={dataOvertimeLocation?.total_page}
                totalResult={dataOvertimeLocation?.total_result}
                onChangePage={onChangePage}
                onClickItem={onActionRow}
                loading={isLoadingTable}
            />
            <DeleteModal
                text="Apakah anda yakin ingin menghapus data lokasi lembur?"
                show={modal.delete}
                onHide={() => onSetModal({delete: false})}
                onDelete={onDelete}
                isLoading={isDeleting}
            />
            {
                modal?.form &&
                <FormModal
                    title={`${actionType === ACTION_TYPE.CREATE ? 'Create' : 'Update'} Lokasi Lembur`}
                    show={modal?.form}
                    width="max-w-[640px]"
                    onHide={() => onSetModal({form: false})}
                    isDisabled={!form?.name}
                    isLoading={isCreating || isUpdating}
                    onSubmitForm={onSubmitForm}
                >
                    <div className="flex flex-col space-y-5 px-8 py-6">
                        <div className="flex items-center gap-3">
                            <div className="w-1/3 font-medium text-neutral-70 required">Lokasi</div>
                            <Input
                                className="flex-1"
                                name="name"
                                placeholder="Isi nama lokasi"
                                maxLength={100}
                                value={form?.name}
                                onChange={onChangeForm}
                            />
                        </div>
                    </div>
                </FormModal>
            }
            <Toast
                show={isShowToast}
                message={toastMessage}
                onClose={() => clearToast()}
            />
        </>
    )
}
