import icDocument from "assets/images/ic-folder-open-03.svg"
import Button from "components/commons/button"
import DeleteModal from "components/commons/delete-modal"
import Table from "components/commons/table"
import { TABLE_PERSONAL_DOCUMENT } from "configs/employee/configTableAddEmployee"
import { useExpandable } from "hooks/useExpandable"
import { CreatePersonalDocument } from "../create-modal/CreatePersonalDocument"
import { FormHeader } from "./FormHeader"

export const PersonalDocument = ({
    optionsPersonalDocumentType,
    dataPersonalDocument,
    isFormValid,
    isEdit,
    form,
    showModal,
    onShowModal,
    onCloseModal,
    onChangeForm,
    onSubmit,
    onActionRow,
    showDeleteModal,
    onCloseDeleteModal,
    onDelete,
    onUploadFile,
    file,
}) => {
    const { isExpanded, elementRef, toggleExpand } = useExpandable({ verticalPadding: 24 })

    return (
        <div className="bg-white border-y border-neutral-20">
            <FormHeader title="Dokumen Pribadi" optional icon={icDocument} expanded={isExpanded} onClick={toggleExpand} />
            <div ref={elementRef} className="expandable px-8">
                <Table
                    className="mb-5"
                    classNameHeader="!bg-primary-10 !text-md !text-primary-50"
                    classNameRow="!py-6"
                    name={TABLE_PERSONAL_DOCUMENT.name}
                    columns={TABLE_PERSONAL_DOCUMENT.column}
                    data={form}
                    onClickItem={onActionRow}
                />
                <Button type="outline" title="+ Tambah Dokumen Pribadi" onClick={onShowModal} />
            </div>

            <CreatePersonalDocument
                isFormValid={isFormValid}
                isEdit={isEdit}
                show={showModal}
                onHide={onCloseModal}
                optionsPersonalDocumentType={optionsPersonalDocumentType}
                dataPersonalDocument={dataPersonalDocument}
                onChangeForm={onChangeForm}
                onSubmit={onSubmit}
                onUploadFile={onUploadFile}
                file={file}
            />

            <DeleteModal
                text={"Apakah anda yakin ingin menghapus data dokumen pribadi?"}
                show={showDeleteModal}
                onHide={onCloseDeleteModal}
                onDelete={onDelete}
            />
        </div>
    )
}