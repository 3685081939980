import { Alert } from "components/commons/alert"
import { PersonalInformation } from "components/employee/employee-add/PersonalInformation"
import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setPersonalInformation } from "reducers/addEmployeeReducer"
import { getFileUnit } from "utils/utils"

export const PersonalInformationContainer = ({ errorForm }) => {
    const dispatch = useDispatch()
    const dataEmployee = useSelector((state) => state.addEmployeeReducer)
    const [profilePicture, setProfilePicture] = useState({
        file: null,
        base64: null,
        name: null,
    })

    const onUploadImage = async (e) => {
        const file = e.target.files[0]
        if (!file) return

        const allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
        if (!allowedExtensions.exec(file?.name)) {
            e.target.value = null
            return Alert({
                icon: 'error',
                title: 'Failed',
                message: 'Format file tidak sesuai',
            })
        }

        if (file?.size > 11000000) {
            e.target.value = null
            return Alert({
                icon: 'error',
                title: 'Failed',
                message: 'File Image terlalu besar (Maksimal 10 MB)',
            })
        }

        const reader = new FileReader()
        reader.onload = () => {
            e.target.value = null
            setProfilePicture({
                file: file,
                base64: reader.result,
                name: `${file.name} ${getFileUnit(file.size)}`
            })
            dispatch(setPersonalInformation({ name: 'profile_picture', value: file }))
        }
        reader.readAsDataURL(file)
    }

    const onDeletePicture = () => {
        dispatch(setPersonalInformation({ name: 'profile_picture', value: null }))
        setProfilePicture({
            file: null,
            base64: null,
            name: null,
        })
    }

    return (
        <PersonalInformation
            form={dataEmployee}
            errorForm={errorForm}
            onChangeForm={(e) => dispatch(setPersonalInformation(e))}
            profilePicture={profilePicture}
            onUploadImage={onUploadImage}
            onDeletePicture={onDeletePicture}
        />
    )
}
