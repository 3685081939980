import {useSearchParams} from "react-router-dom";
import {useFetch} from "../../../services/useFetch";
import {API_CONTRACT} from "../../../services/apiContract";
import {useEffect, useState} from "react";
import {constructOptionsSelect, convertArrayToObject, formatDateMoment} from "../../../utils/utils";
import {Alert} from "../../../components/commons/alert";
import {FormContractContainer} from "../form-contract/FormContractContainer";
import {FORM_CREATE_CONTRACT} from "../../../constants/constant-management-contract";
import {setContractDetail, setOptions} from "../../../reducers/contractReducer";
import {useDispatch, useSelector} from "react-redux";

export const CreateContractContainer = () => {
    const dispatch = useDispatch()
    const [searchParams] = useSearchParams();
    const employeeId = searchParams.get('employeeId')
    const {contractDetail} = useSelector(state => state.contractReducer)
    const [errorForm, setErrorForm] = useState({})
    const {fetching: getCompanyListApi} = useFetch({...API_CONTRACT.LIST_COMPANY, initPathVariable: employeeId})
    const {fetching: getConstantApi} = useFetch(API_CONTRACT.CONSTANT)
    const {fetching: createContractApi} = useFetch(API_CONTRACT.CREATE_CONTRACT)

    useEffect(() => {
        Promise.all([getConstantApi(), getCompanyListApi()])
            .then(result => {
                let ptkp_status = constructOptionsSelect(result[0].data.ptkp_status)
                let type = constructOptionsSelect(result[0].data.type)
                let company = constructOptionsSelect(result[1].data, 'name', 'company_id')
                dispatch(setOptions({ptkp_status, type, company}))
                dispatch(setContractDetail({ptkp_status: ptkp_status[0]}))
            })
    }, []);

    const onSubmitForm = () => {
        let requestBody = {
            ...contractDetail,
            employee_id: Number(employeeId),
            company_id: contractDetail.company.value,
            job_title_id: contractDetail.job_title.value,
            grade_id: contractDetail.grade.value,
            type: contractDetail.type.value,
            ptkp_status: contractDetail.ptkp_status.value,
            start_date: formatDateMoment(contractDetail.start_date, 'YYYY-MM-DD')
        }

        if (contractDetail?.end_date) {
            requestBody.end_date = formatDateMoment(contractDetail.end_date, 'YYYY-MM-DD')
        }

        createContractApi(requestBody)
            .then(response => {
                Alert({
                    icon: 'success',
                    title: 'Success',
                    message: 'Contract Berhasil dibuat!',
                })
                window.location.href = '/management-contract/contract-employee'
            })
            .catch(err => {
                if (err.code === '422') {
                    setErrorForm(convertArrayToObject(err.data))
                }
            })
    }

    let props = {
        onSubmitForm,
        activity: 'create',
        form: FORM_CREATE_CONTRACT,
        errorForm
    }

    return (
        <FormContractContainer {...props} />
    )
}
