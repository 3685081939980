import {Link} from "react-router-dom";
import Button from "../../components/commons/button";
import {ACTION_TYPE} from "../../constants/constant";
import icEdit04Primary from "../../assets/images/ic-edit-04-primary.svg";
import icTrash01Red from "../../assets/images/ic-trash-01-red.svg";
import moment from "moment/moment";
import {formatDateMoment} from "../../utils/utils";
import icUserDefault from "../../assets/images/ic-user-profile.svg";

export const TABLE_SHIFTING_LIST = {
    name: 'List Shifting',
    column: [
        {
            name: "Nama Karyawan",
            key: "name",
            classNameHeader: "sticky left-[70px] z-10 !min-w-[170px]",
            className: "font-semibold max-w-0 sticky left-[70px] bg-white",
            render: ({item}) =>
                <Link to={`/shifting/employee?userId=${item?.id}&date=${moment().format('YYYY-MM')}`} className="flex gap-3 items-center">
                    <img className="w-7 h-7 rounded-full border border-neutral-50" src={item?.profile_picture || icUserDefault} alt="user-profile-picture"/>
                    <p className="truncate">{item?.name}</p>
                </Link>
        },
        {
            name: "Job Title",
            key: "job_title",
            classNameHeader: "sticky left-[240px] z-10 !min-w-[170px]",
            className: "font-semibold truncate max-w-0 sticky left-[240px] bg-white"
        }
    ]
}

export const TABLE_DETAIL_SHIFTING_LIST = {
    name: 'List Shifting Schedule',
    column: [
        {
            name: "Tanggal",
            key: "date",
            className: "font-semibold",
            render: ({value}) => <div>{formatDateMoment(value, 'dddd, DD MMMM YYYY')}</div>
        },
        {
            name: "Clock In",
            key: "clock_in_time",
            className: "font-semibold"
        },
        {
            name: "Clock Out",
            key: "clock_out_time",
            className: "font-semibold"
        },
        {
            name: "Edit Jadwal",
            key: "edit_schedule",
            classNameHeader: "!text-center",
            render: ({ item, onClickItem }) => (
                <div className="flex space-x-3 justify-center">
                    <Button
                        type="outline"
                        icon={icEdit04Primary}
                        disabled={moment(item?.date).isSameOrBefore(moment(), "day")}
                        onClick={() => onClickItem({item, actionType: ACTION_TYPE.EDIT})}
                    />
                    <Button
                        type="danger"
                        icon={icTrash01Red}
                        disabled={moment(item?.date).isSameOrBefore(moment(), "day")}
                        onClick={() => onClickItem({id: item?.id, actionType: ACTION_TYPE.DELETE})}
                    />
                </div>
            )
        },
    ]
}

export const INITIAL_DATA_FILTER_SHIFTING_LIST = {
    search: '',
    page: 1,
    limit: 20,
    start_date: moment().format('YYYY-MM-DD'),
    end_date: moment().add(6, 'day').format('YYYY-MM-DD')
}

export const INITIAL_DATA_FILTER_DETAIL_SHIFTING_LIST = {
    page: 1,
    limit: 10,
    date: moment().format('YYYY-MM'),
    order_by: 'date',
    order_type: 'asc'
}
