import {ViewData} from "../../../components/request-shifting/shifting-import/ViewData";
import {useState} from "react";

export const ViewDataContainer = ({validatedData, onChangeStep}) => {
    const [detailsShifting, setDetailShifting] = useState([])
    const [showDetails, setShowDetails] = useState(false)
    const [currentPageDetails, setCurrentPageDetails] = useState(1)

    const setToPreviousStep = () => {
        onChangeStep(1)
    }

    const setModalCurrentPage = (value) => {
        setCurrentPageDetails(value)
    }

    const onHideModalDetails = () => {
        setShowDetails(false)
        setCurrentPageDetails(1)
    }

    const onClickItemHandler = (details) => {
        setDetailShifting(details)
        setShowDetails(true)
    }

    const onSubmitImport = () => {
        onChangeStep(4)
    }

    const props = {
        isValidated: !validatedData?.statistics?.total_invalid,
        dataImport: validatedData,
        detailsShifting,
        showDetails,
        currentPageDetails,
        setModalCurrentPage,
        onHideModalDetails,
        setToPreviousStep,
        onClickItemHandler,
        onSubmitImport
    }

    return (
        <ViewData {...props}/>
    )
}
