import icCalendarMinus from "../../../assets/images/ic-calendar-minus.svg"
import icClockForward from "../../../assets/images/ic-clock-forward.svg"
import icArrowRightSm from "../../../assets/images/ic-arrow-right-sm.svg"
import icCalendarRemove from "../../../assets/images/ic-calendar-remove.svg"
import {TABLE_DETAIL_SHIFTING_LIST} from "../../../configs/request-shifting/configTableShiftingList";
import Table from "../../commons/table";
import icFileUp from "../../../assets/images/ic-file-up-02.svg";
import Button from "../../commons/button";
import MonthPicker from "../../commons/monthpicker";
import moment from "moment/moment";
import icFileDown from "../../../assets/images/ic-file-down-02.svg";
import icAddSquare from "../../../assets/images/id-add-square.svg";
import {ACTION_TYPE} from "../../../constants/constant";
import {ExportModal} from "../../commons/ExportModal";
import Toast from "../../commons/toast";
import {ShiftingDetailForm} from "./ShiftingDetailForm";
import DeleteModal from "../../commons/delete-modal";
import icUserProfile from "../../../assets/images/ic-user-profile.svg";
import Spinner from "../../commons/spinner";
import {formatDateMoment} from "../../../utils/utils";

export const ShiftingDetail = (
    {
        dataDetail,
        dataDetailList,
        query,
        modal,
        isLoadingDetail,
        isLoadingDetailList,
        actionType,
        isShowToast,
        toastMessage,
        shiftingDetail,
        isFormValid,
        isCreating,
        isUpdating,
        isDeleting,
        errorForm,
        onChangeQuery,
        onChangeValue,
        onClickItemHandler,
        setShowModal,
        onDelete,
        onExport,
        onImport,
        clearToast,
        onSubmitForm
    }) => {

    return (
        <div className={"mx-6 my-4"}>
            <div className={"flex justify-between items-center p-5 bg-neutral-10 rounded-xl"}>
                {
                    isLoadingDetail ?
                        <div className={"w-full flex justify-center"}>
                            <Spinner className={"w-10 h-10 border-primary-50"}/>
                        </div>
                        :
                        <>
                            <div className={"flex items-center space-x-6 pl-8"}>
                                <img src={dataDetail?.profile?.profile_picture || icUserProfile} alt="profile"
                                     className={"w-[4.25rem] h-[4.25rem] rounded-full border"}/>
                                <div className="space-y-3">
                                    <p className={"font-semibold text-lg text-neutral-80"}>{dataDetail?.profile?.full_name}</p>
                                    <p className={"font-semibold text-neutral-80"}>{dataDetail?.profile?.job_title}</p>
                                    <p className="text-neutral-70">{dataDetail?.profile?.nip}</p>
                                </div>
                            </div>
                            <div className={"space-y-4 p-5 bg-white"}>
                                {
                                    dataDetail?.schedule_today?.clock_in ?
                                        <>
                                            <div className={"flex items-center space-x-4"}>
                                                <img src={icCalendarMinus} alt="ic-calendar-minus"/>
                                                <div className={"space-y-1 text-neutral-80"}>
                                                    <p>Jadwal Hari Ini</p>
                                                    <p className="font-bold">{formatDateMoment(dataDetail?.schedule_today?.date, 'dddd, DD MMMM YYYY')}</p>
                                                </div>
                                            </div>
                                            <div className={"flex justify-around space-x-5 rounded-xl"}>
                                                <div className={"flex items-center space-x-4 pr-10"}>
                                                    <img src={icClockForward} alt="ic-calendar-minus" className="mr-1"/>
                                                    <div className={"space-y-1 text-neutral-80"}>
                                                        <p>Clock In</p>
                                                        <p className="font-semibold">{dataDetail?.schedule_today?.clock_in}</p>
                                                    </div>
                                                </div>
                                                <img src={icArrowRightSm} alt="ic-arrow-right-sm"/>
                                                <div className={"flex items-center space-x-4 pr-10"}>
                                                    <img src={icClockForward} alt="ic-calendar-minus" className="mr-1"/>
                                                    <div className={"space-y-1 text-neutral-80"}>
                                                        <p>Clock Out</p>
                                                        <p className="font-semibold">{dataDetail?.schedule_today?.clock_out}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                        :
                                        <div className={"flex flex-col space-y-5 px-16 py-3"}>
                                            <img src={icCalendarRemove} alt="ic-calendar-remove"
                                                 className={"w-[2.25rem] h-[2.25rem] mx-auto"}/>
                                            <p className="text-neutral-80">Tidak Terdapat Jadwal Hari Ini</p>
                                        </div>
                                }
                            </div>
                        </>
                }
            </div>
            <div className={"space-y-4 my-8"}>
                <p className={"font-bold text-md text-primary-60"}>Jadwal Shifting</p>
                <div className={"flex justify-between"}>
                    <div className={"flex space-x-3"}>
                        <MonthPicker
                            format='YYYY-MM'
                            value={query?.date}
                            onChange={(val) => onChangeQuery({date: val, page: 1})}
                            maxYear={moment().get('year') + 1}
                        />
                        <Button title="Export" icon={icFileUp} type="outline" disabled={!dataDetailList?.total_result}
                                onClick={() => setShowModal({export: true})}/>
                    </div>
                    <div className={"flex space-x-3"}>
                        <Button type="outline" title="Import Shifting Schedule" icon={icFileDown} onClick={onImport}/>
                        <Button title="Add Shifting Schedule" icon={icAddSquare} onClick={() => onClickItemHandler({actionType: ACTION_TYPE.CREATE})}/>
                    </div>
                </div>
            </div>
            <Table
                className="my-3"
                name={TABLE_DETAIL_SHIFTING_LIST.name}
                columns={TABLE_DETAIL_SHIFTING_LIST.column}
                data={dataDetailList?.result}
                size={query?.limit}
                currentPage={query?.page}
                totalPage={dataDetailList?.total_page}
                totalResult={dataDetailList?.total_result}
                onChangePage={(val) => onChangeQuery({page: val})}
                onClickItem={onClickItemHandler}
                loading={isLoadingDetailList}
            />
            <DeleteModal
                show={modal?.delete}
                onHide={() => setShowModal({delete: false})}
                onDelete={onDelete}
                isLoading={isDeleting}
                text="Apakah anda yakin ingin menghapus data shifting?"
            />
            <ExportModal
                title="Apakah anda yakin ingin export data shifting ?"
                description={`Total data shifting export: ${dataDetailList?.total_result}`}
                show={modal?.export}
                onExportHandler={onExport}
                onCancelHandler={() => setShowModal({export: false})}
            />
            {
                modal?.form &&
                <ShiftingDetailForm
                    title={`${actionType === ACTION_TYPE.CREATE ? 'Create' : 'Edit'} Jadwal`}
                    show={modal?.form}
                    onHide={() => setShowModal({form: false})}
                    shiftingDetail={shiftingDetail}
                    onChangeValue={onChangeValue}
                    isFormValid={isFormValid}
                    isCreating={isCreating}
                    isUpdating={isUpdating}
                    onSubmitForm={onSubmitForm}
                    errorForm={errorForm}
                />
            }
            <Toast
                show={isShowToast}
                message={toastMessage}
                onClose={() => clearToast()}
            />
        </div>
    )
}
