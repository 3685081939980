import {DayOffList} from "../../../components/work-settings/day-off-list/DayOffList";
import {useEffect, useState} from "react";
import {ACTION_TYPE} from "../../../constants/constant";
import {useFetch} from "../../../services/useFetch";
import {INITIAL_DATA_FILTER} from "../../../configs/work-settings/configTableDayOffList";
import {API_DAY_OFF} from "../../../services/apiDayOff";
import {downloadFile, getAllQueryParams, setQueryParams} from "../../../utils/utils";
import moment from "moment";
import {useValidation} from "../../../hooks/useValidation";
import {useLocation, useSearchParams} from "react-router-dom";

const INITIAL_STATE = {
    date: null,
    description: null
}

export const DayOffListContainer = () => {
    const location = useLocation()
    const {data: dayOffList, fetching: getDayOffList, isLoading} = useFetch(API_DAY_OFF.DAY_OFF_LIST)
    const {fetching: createDayOff, isLoading: isCreating} = useFetch(API_DAY_OFF.CREATE_DAY_OFF)
    const {fetching: updateDayOff, isLoading: isUpdating} = useFetch(API_DAY_OFF.UPDATE_DAY_OFF)
    const {fetching: exportDayOff} = useFetch(API_DAY_OFF.DAY_OFF_EXPORT)
    const {fetching: deleteDayOff, isLoading: isDeleting} = useFetch(API_DAY_OFF.DELETE_DAY_OFF)
    const [dayOffDetail, setDayOffDetail] = useState(INITIAL_STATE)
    const [deleteId, setDeleteId] = useState(null)
    const [actionType, setActionType] = useState(ACTION_TYPE.CREATE)
    const [isShowToast, setIsShowToast] = useState(false)
    const [toastMessage, setToastMessage] = useState('')
    const {isFormValid} = useValidation({form: dayOffDetail})
    const [searchParams, setSearchParams] = useSearchParams()
    const query = searchParams.size > 0 ? getAllQueryParams(searchParams) : INITIAL_DATA_FILTER
    const [clearFilter, setClearFilter] = useState({})
    const [activeFilter, setActiveFilter] = useState({})
    const [selectedFilter, setSelectedFilter] = useState({
        name: "year",
        value: Number(query?.year)
    })
    const [modal, setModal] = useState({
        delete: false,
        export: false,
        filter: false,
        sort: false,
        form: false,
    })

    useEffect(() => {
        fetchDayOffList({...query})
    }, [location?.search])

    const onChangeQuery = (queryParams) => {
        const params = setQueryParams({...query, ...queryParams})
        setSearchParams(params, {replace: true})
    }

    const setShowModal = (item) => {
        setModal({...modal, ...item})
    }

    const clearToast = () => {
        setIsShowToast(false)
        setToastMessage('')
    }

    const setShowToast = (message, itemModal) => {
        setShowModal(itemModal)
        setIsShowToast(true)
        setToastMessage(message)
    }

    const onCloseFilter = () => {
        if (clearFilter?.value) setSelectedFilter(clearFilter)
        setActiveFilter({})
        setShowModal({filter: false})
    }

    const onSelectFilter = (value) => {
        setActiveFilter(value)
    }

    const onSubmitFilter = () => {
        setSelectedFilter(activeFilter)
        setClearFilter({})
        onChangeQuery({year: activeFilter?.value ?? null})
        setShowModal({filter: false})
    }

    const onClearFilter = () => {
        setClearFilter(selectedFilter)
        setActiveFilter({})
        setSelectedFilter({})
    }

    const fetchDayOffList = (item = query) => {
        getDayOffList(item)
    }

    const onClickItemHandler = ({id, item, actionType}) => {
        switch (actionType) {
            case ACTION_TYPE.CREATE:
                setDayOffDetail(INITIAL_STATE)
                setActionType(ACTION_TYPE.CREATE)
                setShowModal({form: true})
                break
            case ACTION_TYPE.EDIT:
                setDayOffDetail(item)
                setActionType(ACTION_TYPE.EDIT)
                setShowModal({form: true})
                break
            default:
                setDeleteId(id)
                setShowModal({delete: true})
        }
    }

    const onDelete = () => {
        deleteDayOff({}, deleteId)
            .then(() => {
                setShowToast('Day Off Deleted !!', {delete: false})
                if (getDayOffList?.data?.result?.length === 1 && Number(query?.page) !== 1) {
                    onChangeQuery({page: 1})
                } else {
                    fetchDayOffList()
                }
            })
    }

    const onExport = () => {
        const {page, limit, ...payload} = query
        exportDayOff(payload, '', {}, 'blob')
            .then(response => {
                downloadFile(null, response, `Day-Off-Export-${moment().format('DD-MM-YYYY')}.xlsx`)
                setShowToast('Day Off Exported', {export: false})
            })
    }

    const onChangeValue = (item, name) => {
        const newData = name ? {[name]: item} : {[item?.name]: item?.value}
        setDayOffDetail({...dayOffDetail, ...newData})
    }

    const onSubmitForm = () => {
        const {id, ...payload} = dayOffDetail
        if (actionType === ACTION_TYPE.CREATE) {
            createDayOff(payload)
                .then(() => {
                    setShowToast('Day Off Created !!', {form: false})
                    fetchDayOffList()
                })
        } else {
            updateDayOff(payload, id)
                .then(() => {
                    setShowToast('Day Off Updated !!', {form: false})
                    fetchDayOffList()
                })
        }
    }

    const props = {
        dataList: dayOffList?.data,
        modal,
        isShowToast,
        toastMessage,
        activeFilter,
        selectedFilter,
        dayOffDetail,
        query,
        isCreating,
        isUpdating,
        isDeleting,
        actionType,
        isLoading,
        onClickItemHandler,
        clearToast,
        onChangeQuery,
        setShowModal,
        onCloseFilter,
        onSelectFilter,
        onSubmitFilter,
        onClearFilter,
        onDelete,
        onExport,
        onChangeValue,
        onSubmitForm,
        isFormValid
    }

    return (
        <DayOffList {...props}/>
    )
}
