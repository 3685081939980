import {InlineTabs} from "../commons/InlineTabs";
import Search from "../commons/search";
import ViewList from "../commons/view-list";
import Button from "../commons/button";
import icFileUp from "../../assets/images/ic-file-up-02.svg";
import {ExportModal} from "../commons/ExportModal";
import Table from "../commons/table";
import {ADDITIONAL_INCENTIVE_REQUEST_TABLE} from "../../configs/additional-incentive/configTableAdditionalIncentive";
import icBulkUpdate from "../../assets/images/ic-bulk-update.svg";
import icDown from "../../assets/images/ic-chev-down.svg"
import clsx from "clsx";
import {useState} from "react";
import {useClickOutside} from "../../hooks/useClickOutside";
import icFilter from "../../assets/images/ic-filter-new.svg";
import DateRangePicker from "../commons/date-range-picker";
import moment from "moment/moment";
import Toast from "../commons/toast";
import {PreviewIncentive} from "./PreviewIncentive";
import {ModalApproval} from "../commons/modal-approval/ModalApproval";

export const AdditionalIncentive = (
    {
        tabList,
        status,
        optionBulk,
        employeeData,
        employeeDetail,
        previewTab,
        activePreviewTab,
        setActivePreviewTab,
        query,
        modal,
        selectedIds,
        selectedStatus,
        isLoading,
        toastMessage,
        rejectReason,
        setRejectReason,
        onChangeTab,
        onChangeQuery,
        onExportHandler,
        onSelectHandler,
        onClickItemHandler,
        onBulkUpdateHandler,
        onUpdateStatusRequest,
        setModalVisibility
    }) => {
    const elementID = 'dropdownSetting'
    const [showSetting, setShowSetting] = useState(false)
    useClickOutside({callback: () => showSetting && setShowSetting(false), elementID})

    const onSelect = (e) => {
        setShowSetting(false)
        onBulkUpdateHandler(e)
    }

    return (
        <div>
            <InlineTabs tabList={tabList} activeTab={status} onClickTab={onChangeTab}/>
            <div className={"flex flex-row justify-between gap-3 px-6 py-4"}>
                <div className={"flex flex-row gap-3"}>
                    <Search onSearch={(search) => onChangeQuery({page: 1, search})} value={query?.search}/>
                    <div className={"flex flex-row justify-between gap-3"}>
                        <div className={"flex flex-row gap-2 items-center"}>
                            <img src={icFilter} alt="ic-filter"/>
                            <p>Filter by date : </p>
                            <DateRangePicker
                                value={query?.start_date ? [query?.start_date, query?.end_date] : []}
                                onChange={(value) => onChangeQuery({page: 1, start_date: value[0], end_date: value[1]})}
                                format="YYYY-MM-DD"
                                maxDate={`${moment().get('year')}-12-31`}
                            />
                        </div>
                        <ViewList
                            show={modal?.limit}
                            onShow={() => setModalVisibility({limit: true})}
                            onClose={() => setModalVisibility({limit: false})}
                            value={query?.limit}
                            onSelectSize={(limit) => onChangeQuery({page: 1, limit})}
                        />
                        <Button title="Export" icon={icFileUp} type="outline" disabled={!employeeData?.total_data}
                                onClick={() => setModalVisibility({export: true})}/>
                    </div>
                </div>
                {
                    status === tabList[0].id &&
                    <div className="relative">
                        <Button title="Bulk Update" type="outline" disabled={!employeeData?.total_data}
                                icon={icBulkUpdate} iconAfter={icDown}
                                onClick={() => setShowSetting(!showSetting)}/>
                        <div
                            id={elementID}
                            className={clsx(
                                "absolute bg-white right-0 mt-1 p-1.5 border border-neutral-20 rounded-lg shadow-sm whitespace-nowrap transition-all duration-200 w-[180px] z-10",
                                showSetting ? "opacity-100 visible" : "opacity-0 collapse"
                            )}
                        >
                            {optionBulk.map((item, index) => (
                                <div key={index}
                                     className="hover:bg-primary-10 px-4 py-2.5 rounded-lg cursor-pointer capitalize transition-all duration-200"
                                     onClick={() => onSelect(item)}>{item}</div>
                            ))}
                        </div>
                    </div>
                }
            </div>
            <Table
                className="my-3"
                name={ADDITIONAL_INCENTIVE_REQUEST_TABLE.name}
                data={employeeData?.result}
                columns={ADDITIONAL_INCENTIVE_REQUEST_TABLE.column}
                size={query?.limit}
                totalPage={employeeData?.total_page}
                currentPage={query?.page}
                totalResult={employeeData?.total_data}
                onChangePage={(page) => onChangeQuery({page})}
                onSelectItem={onSelectHandler}
                onClickItem={(item) => onClickItemHandler(item)}
                loading={isLoading}
            />
            <ExportModal
                title="Apakah anda yakin ingin export data additional incentive request ?"
                description={`Total data additional incentive request export: ${selectedIds?.length || employeeData?.total_data}`}
                show={modal?.export}
                onExportHandler={onExportHandler}
                onCancelHandler={() => setModalVisibility({export: false})}
            />
            <PreviewIncentive
                visibility={modal?.preview}
                employeeDetail={employeeDetail}
                tabList={tabList}
                previewTab={previewTab}
                optionBulk={optionBulk}
                activePreviewTab={activePreviewTab}
                onHide={() => setModalVisibility({preview: false})}
                onChangePreviewTab={(item) => setActivePreviewTab(item?.id)}
                onSelect={onSelect}
            />
            {
                modal?.bulk_update &&
                <ModalApproval
                    id={employeeDetail?.request_info?.id}
                    title={'request additional incentive'}
                    modal={modal}
                    setModal={setModalVisibility}
                    status={selectedStatus}
                    optionBulk={optionBulk}
                    selectedLength={selectedIds?.length}
                    rejectReason={rejectReason}
                    setRejectReason={setRejectReason}
                    onUpdateStatusRequest={onUpdateStatusRequest}
                />
            }
            <Toast
                show={modal?.toast}
                message={toastMessage}
                onClose={() => setModalVisibility({toast: false})}
            />
        </div>
    )
}
