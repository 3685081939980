import {METHOD} from "./method";

export const API_PAYROLL_LIST = {
    PAYROLL_LIST: {
        api: '/v1/hr-adm/payroll/list',
        method: METHOD.POST
    },
    PAYROLL_DETAIL: {
        api: '/v1/hr-adm/payroll',
        method: METHOD.POST
    },
    DELETE_PAYROLL: {
        api: '/v1/hr-adm/payroll',
        method: METHOD.DELETE
    },
    EXPORT_PAYROLL_DETAIL: {
        api: '/v1/hr-adm/payroll/export',
        method: METHOD.POST
    },
    DEPARTMENT_LIST: {
        api: '/v1/hr-adm/payroll/department',
        method: METHOD.GET
    }
}
