import Search from "../../commons/search";
import Table from "../../commons/table";
import {TABLE_APPROVAL_SETTING} from "configs/matrix-approval/configTableApproval";
import Input from "../../commons/input";
import Select from "../../commons/select";
import FormModal from "../../commons/form-modal";
import Toast from "../../commons/toast";

export const ApprovalSetting = (
    {
        dataList,
        isShowForm,
        isShowToast,
        toastMessage,
        query,
        clearToast,
        onChangeQuery,
        onHideForm,
        onClickItemHandler,
        onSubmitForm,
        onChangeValue,
        approvalDetail,
        optionList,
        isLoading,
        isUpdating
    }
) => {

    return (
        <div>
            <div className={"flex px-6 py-4"}>
                <Search value={query?.search} onSearch={(key) => onChangeQuery({search: key, page: 1})}/>
            </div>
            <Table
                className="my-3"
                name={TABLE_APPROVAL_SETTING.name}
                columns={TABLE_APPROVAL_SETTING.column}
                data={dataList?.result}
                size={query?.limit}
                currentPage={query?.page}
                totalPage={dataList?.total_page}
                totalResult={dataList?.total_data}
                onChangePage={(val) => onChangeQuery({page: val})}
                onClickItem={onClickItemHandler}
                loading={isLoading}
            />
            {
                isShowForm &&
                <FormModal
                    show={isShowForm}
                    title="Approval Setting"
                    width="max-w-[639px]"
                    onHide={onHideForm}
                    isLoading={isUpdating}
                    onSubmitForm={onSubmitForm}
                >
                    <div className={"flex flex-col px-6 py-8 gap-[20px]"}>
                        <div className={"flex items-center"}>
                            <div className={"w-1/3 text-neutral-70"}>Job Title</div>
                            <Input className="flex-1" name="title" value={approvalDetail?.title} disabled />
                        </div>
                        <div className={"flex items-center"}>
                            <div className={"w-1/3 text-neutral-70"}>Approver 1</div>
                            <Select
                                name="approver_1"
                                className="flex-1"
                                optionsClassName="max-h-40"
                                placeholder="Pilih approver 1"
                                options={optionList}
                                value={approvalDetail?.approver_1}
                                onChange={onChangeValue}
                            />
                        </div>
                        <div className="flex items-center">
                            <div className="w-1/3 text-neutral-70">Approver 2</div>
                            <Select
                                name="approver_2"
                                className="flex-1"
                                optionsClassName="max-h-40"
                                placeholder="Pilih approver 2"
                                options={optionList}
                                value={approvalDetail?.approver_2}
                                onChange={onChangeValue}
                            />
                        </div>
                    </div>
                </FormModal>
            }
            <Toast
                show={isShowToast}
                message={toastMessage}
                onClose={() => clearToast()}
            />
        </div>
    )
}
