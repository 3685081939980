import {useEffect, useState} from "react";
import {ACTION_TYPE} from "../../../constants/constant";
import {useFetch} from "../../../services/useFetch";
import {API_JOB_TITLE} from "../../../services/apiJobTitle";
import {useValidation} from "../../../hooks/useValidation";
import {formJobTitleValidation} from "../../../services/formJobTitleValidation";
import {JobTitleSettings} from "../../../components/organization-structure-management/job-title-settings/JobTitleSettings";
import {INITIAL_DATA_FILTER} from "../../../configs/organization-structure/configTableJobTitle";
import {downloadFile, getAllQueryParams, setQueryParams} from "../../../utils/utils";
import moment from "moment";
import {useLocation, useSearchParams} from "react-router-dom";
import {Alert} from "../../../components/commons/alert";

const FILTER_PARAMS = ['department_ids', 'division_ids', 'sub_division_ids', 'visibility']

export const INITIAL_STATE = {
    title: null,
    management_id: null,
    department_id: null,
    division_id: null,
    sub_division_id: null,
    description: null,
    grade_ids: [],
    schedule_category_id: null,
    direct_report_id: null,
    quota: null
}

export const JobTitleSettingsContainer = () => {
    const location = useLocation()
    const {
        data: jobTitleList,
        fetching: getJobTitleList,
        isLoading
    } = useFetch(API_JOB_TITLE.JOB_TITLE_LIST)
    const {fetching: exportJobTitle} = useFetch(API_JOB_TITLE.JOB_TITLE_EXPORT)
    const {fetching: deleteJobTitle, isLoading: isDeleting} = useFetch(API_JOB_TITLE.JOB_TITLE_DELETE)
    const {fetching: updateVisibility, isLoading: isUpdating} = useFetch(API_JOB_TITLE.UPDATE_VISIBILITY)
    const [isShowToast, setIsShowToast] = useState(false)
    const [toastMessage, setToastMessage] = useState('')
    const [selectedIds, setSelectedIds] = useState([])
    const [updateId, setUpdateId] = useState(null)
    const [deleteId, setDeleteId] = useState(null)
    const [actionType, setActionType] = useState(ACTION_TYPE.CREATE)
    const [jobTitleDetail, setJobTitleDetail] = useState(INITIAL_STATE)
    const {errorForm, clearErrorForm, validateForm} = useValidation({schema: formJobTitleValidation})
    const [searchParams, setSearchParams] = useSearchParams()
    const [queryFilter, setQueryFilter] = useState({})
    const query = searchParams.size > 0 ? getAllQueryParams(searchParams) : INITIAL_DATA_FILTER
    const [modal, setModal] = useState({
        delete: false,
        export: false,
        filter: false,
        limit: false,
        form: false,
        visibility: false,
    })

    useEffect(() => {
        const newQuery = {...query}
        const newQueryFilter = {}
        Object.entries(newQuery).forEach(([key, item]) => {
            if (FILTER_PARAMS.includes(key)) {
                const valueOfQuery = item?.split(',')
                newQuery[key] = valueOfQuery
                newQueryFilter[key] = valueOfQuery
            }
        })
        setQueryFilter(newQueryFilter)
        fetchJobTitleList(newQuery)
    }, [location?.search])

    const onChangeQuery = (queryParams) => {
        const params = setQueryParams({...query, ...queryParams})
        setSearchParams(params, {replace: true})
    }

    const clearToast = () => {
        setIsShowToast(false)
        setToastMessage('')
    }

    const setShowToast = (message, itemModal) => {
        setShowModal(itemModal)
        setIsShowToast(true)
        setToastMessage(message)
    }

    const setShowModal = (item) => {
        setModal({...modal, ...item})
    }

    const fetchJobTitleList = (item = {...query, ...queryFilter}) => {
        getJobTitleList(item)
    }

    const onClickItemHandler = ({id, actionType}) => {
        switch (actionType) {
            case ACTION_TYPE.CREATE:
                setActionType(ACTION_TYPE.CREATE)
                setJobTitleDetail(INITIAL_STATE)
                setUpdateId(null)
                setShowModal({form: true})
                clearErrorForm()
                break
            case ACTION_TYPE.EDIT:
                setUpdateId(id)
                setActionType(ACTION_TYPE.EDIT)
                setShowModal({form: true})
                clearErrorForm()
                break
            default:
                setDeleteId(id)
                setShowModal({delete: true})
        }
    }

    const onExport = () => {
        const combineObj = {...query, ...queryFilter}
        const {page, limit, ...dataFilterRest} = combineObj
        const payload = {...dataFilterRest, job_title_ids: selectedIds}
        exportJobTitle(payload, '', {}, 'blob')
            .then(response => {
                downloadFile(null, response, `Job-Title-Export-${moment().format('DD-MM-YYYY')}.xlsx`)
                setShowToast('Job Title Exported', {export: false})
            })
    }

    const onSelectItemHandler = (item) => {
        const ids = item?.map(i => i?.id)
        setSelectedIds(ids)
    }

    const onDelete = () => {
        deleteJobTitle({}, deleteId)
            .then(() => {
                setShowToast('Job Title Deleted !!', {delete: false})
                if (getJobTitleList?.data?.result?.length === 1 && Number(query?.page) !== 1) {
                    onChangeQuery({page: 1})
                } else {
                    fetchJobTitleList()
                }
            })
    }

    const onClickButtonVisibility = () => {
        if (selectedIds.length === 0) {
            return Alert({
                icon: 'warning',
                title: 'Silakan pilih job title terlebih dahulu.',
                autoClose: true
            })
        }
        setShowModal({visibility: true})
    }

    const onUpdateVisibility = () => {
        updateVisibility({job_title_ids: selectedIds})
            .then(() => {
                setShowToast('Job Title Visibility Updated', {visibility: false})
                fetchJobTitleList()
            })
    }

    const props = {
        dataList: jobTitleList?.data,
        modal,
        selectedIds,
        actionType,
        errorForm,
        updateId,
        jobTitleDetail,
        setJobTitleDetail,
        isLoading,
        isUpdating,
        isShowToast,
        toastMessage,
        queryFilter,
        query,
        isDeleting,
        fetchJobTitleList,
        onChangeQuery,
        onUpdateVisibility,
        setShowModal,
        setShowToast,
        onClickItemHandler,
        onClickButtonVisibility,
        validateForm,
        clearToast,
        onSelectItemHandler,
        onDelete,
        onExport,
        onSubmitFilter: (queryFilter) => onChangeQuery({...queryFilter, ...{page: 1}})
    }

    return (
        <JobTitleSettings {...props} />
    )
}
