import { METHOD } from "./method";

export const API_ROLE = {
    LIST_ROLE: {
        api: '/v1/hr-adm/account/roles/list',
        method: METHOD.POST
    },
    LIST_COMPANY: {
        api: '/v1/hr-adm/account/roles/companies',
        method: METHOD.GET
    },
    LIST_PERMISSION: {
        api: '/v1/hr-adm/account/roles/permissions/list',
        method: METHOD.POST
    },
    ROLE: {
        api: '/v1/hr-adm/account/roles',
        method: METHOD.POST
    },
}