import clsx from "clsx"
import { Fragment } from "react"
import "./breadcrumb.scss"

const Breadcrumb = ({ className, basePathTitle, path, separator, onClick }) => {
    const splitPath = (path) => {
        if (path === '/') return [{
            title: basePathTitle || 'Home',
            path: path
        }]

        let parentPath = ''
        return path.split('/').reduce((filtered, currentItem) => {
            if (!!currentItem) {
                parentPath = parentPath + `/${currentItem}`
                filtered.push({
                    title: currentItem.replace(/-/g, ' '),
                    path: parentPath
                })
            }
            return filtered
        }, [])
    }

    const handleOnClick = (item) => {
        if (onClick) onClick(item.path)
    }

    return (
        <div className={clsx("avo__breadcrumb", className)}>
            {splitPath(path).map((item, index) => (
                <Fragment key={index}>
                    {index > 0 && separator}
                    <div key={item.title} className="breadcrumb-title" onClick={() => handleOnClick(item)}>{item.title}</div>
                </Fragment>
            ))}
        </div>
    )
}

export default Breadcrumb
