import {METHOD} from "./method";

export const API_INCENTIVE = {
    CREATE_UPDATE_PERCENTAGE_INCENTIVE: {
        api: '/v1/hr-adm/incentive',
        method: METHOD.PUT
    },
    INCENTIVE_LIST: {
        api: '/v1/hr-adm/incentive',
        method: METHOD.POST
    },
    INCENTIVE_DETAIL: {
        api: '/v1/hr-adm/incentive',
        method: METHOD.GET
    },
    UPDATE_INCENTIVE: {
        api: '/v1/hr-adm/incentive',
        method: METHOD.PUT
    },
    DOWNLOAD_TEMPLATE: {
        api: '/v1/hr-adm/incentive',
        method: METHOD.GET
    },
    IMPORT: {
        api: '/v1/hr-adm/incentive',
        method: METHOD.POST
    },
    IMPORT_DATA: {
        api: '/v1/hr-adm/incentive/personal-incentive/import',
        method: METHOD.POST
    },
}
