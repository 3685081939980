import icUserDefault from "../../assets/images/ic-user-profile.svg";
import {InlineTabs} from "../commons/InlineTabs";
import {formatDateMoment, getStatusIcon, setCurrency} from "../../utils/utils";
import Badge from "../commons/badge";
import Button from "../commons/button";
import icChecked from "../../assets/images/ic-check-02.svg";
import icCross from "../../assets/images/ic-x-02.svg";
import Drawer from "../commons/drawer";

export const PreviewOvertime = (
    {
        visibility,
        dataPreview,
        tabList,
        previewTab,
        optionBulk,
        activePreviewTab,
        onHide,
        onChangePreviewTab,
        onPreviewFile,
        onSelect
    }) => {

    return (
        <Drawer visibility={visibility} onHide={() => onHide({preview: false})} title="Detail Overtime Request">
            <div className="px-6">
                <div className={"flex items-center gap-4 mt-2 mb-4"}>
                    <img className={"w-12 h-12 border border-white rounded-full"} src={dataPreview?.profile_picture ?? icUserDefault} alt={dataPreview?.profile_picture}/>
                    <div className={"flex flex-col space-y-2"}>
                        <span className={"font-semibold text-md text-neutral-80"}>{dataPreview?.full_name}</span>
                        <span className={"font-medium text-neutral-80"}>{dataPreview?.title}</span>
                        <span className="text-neutral-70">{dataPreview?.nip}</span>
                    </div>
                </div>
                <InlineTabs
                    className="!px-0"
                    classNameItem="w-full"
                    tabList={previewTab}
                    activeTab={activePreviewTab}
                    onClickTab={(tab) => onChangePreviewTab(tab.id)}
                />
                {activePreviewTab === previewTab[0].id ?
                    <div className={"space-y-8 py-6"}>
                        <div className="space-y-4">
                            <div className="flex">
                                <div className={"w-44 text-neutral-70 pr-2"}>Department</div>
                                <div className={"flex-1 font-medium text-neutral-80"}>{dataPreview?.department || '-'}</div>
                            </div>
                            <div className="flex">
                                <div className={"w-44 text-neutral-70 pr-2"}>Grade</div>
                                <div className={"flex-1 font-medium text-neutral-80"}>{dataPreview?.grade_allowance || '-'}</div>
                            </div>
                            <div className="flex">
                                <div className={"w-44 text-neutral-70 pr-2"}>Kategori Jam Kerja</div>
                                <div className={"flex-1 font-medium text-neutral-80"}>{dataPreview?.schedule_category || '-'}</div>
                            </div>
                            <div className="flex">
                                <div className={"w-44 text-neutral-70 pr-2"}>Lokasi Lembur</div>
                                <div className={"flex-1 font-medium text-neutral-80"}>{dataPreview?.location_name || '-'}</div>
                            </div>
                        </div>
                        <div className="space-y-4">
                            <div className={"font-semibold text-neutral-80"}>Waktu Lembur Sebelum Shift</div>
                            <div className="flex">
                                <div className={"w-44 text-neutral-70 pr-2"}>Jam Mulai Lembur</div>
                                <div className={"flex-1 font-medium text-neutral-80"}>{dataPreview?.start_time_before_shift || '-'}</div>
                            </div>
                            <div className="flex">
                                <div className={"w-44 text-neutral-70 pr-2"}>Jam Berakhir Lembur</div>
                                <div className={"flex-1 font-medium text-neutral-80"}>{dataPreview?.end_time_before_shift || '-'}</div>
                            </div>
                        </div>
                        <div className="space-y-4">
                            <div className={"font-semibold text-neutral-80"}>Waktu Lembur Setelah Shift</div>
                            <div className="flex">
                                <div className={"w-44 text-neutral-70 pr-2"}>Jam Mulai Lembur</div>
                                <div className={"flex-1 font-medium text-neutral-80"}>{dataPreview?.start_time_after_shift || '-'}</div>
                            </div>
                            <div className="flex">
                                <div className={"w-44 text-neutral-70 pr-2"}>Jam Berakhir Lembur</div>
                                <div className={"flex-1 font-medium text-neutral-80"}>{dataPreview?.end_time_after_shift || '-'}</div>
                            </div>
                        </div>
                        <div className="space-y-4">
                            <div className="flex">
                                <div className={"w-44 text-neutral-70 pr-2"}>Total Durasi Lembur</div>
                                <div className={"flex-1 font-medium text-neutral-80"}>{Number(dataPreview?.duration).toFixed(2).replace(/\.00$/, "")}</div>
                            </div>
                            <div className="flex">
                                <div className={"w-44 text-neutral-70 pr-2"}>Upah Lembur</div>
                                <div className={"flex-1 font-medium text-neutral-80"}>{setCurrency(dataPreview?.nominal_benefit)}</div>
                            </div>
                            <div className="flex">
                                <div className={"w-44 text-neutral-70 pr-2"}>Deskripsi Lembur</div>
                                <div className={"flex-1 font-medium text-neutral-80"}>{dataPreview?.submit_reason || '-'}</div>
                            </div>
                            <div className="flex">
                                <div className={"w-44 text-neutral-70 pr-2"}>File Pendukung</div>
                                <div className={"flex flex-col"}>
                                    {
                                        dataPreview?.attachment_urls?.length ?
                                            dataPreview?.attachment_urls?.map((item, index) => (
                                                <div key={index} onClick={() => onPreviewFile(item)}
                                                     className={"hover:cursor-pointer hover:text-primary-50 hover:font-semibold"}>
                                                    {dataPreview?.attachment_urls?.length > 1 ? `File Attachment ${index + 1}` : 'File Attachment'}
                                                </div>
                                            ))
                                            :
                                            '-'
                                    }
                                </div>
                            </div>
                            <div className={"flex items-center"}>
                                <div className={"w-44 text-neutral-70 pr-2"}>Status Pengajuan</div>
                                <Badge title={
                                    <div className={'flex items-center space-x-1'}>
                                        {
                                            getStatusIcon(dataPreview?.status) &&
                                            <img src={getStatusIcon(dataPreview?.status)} alt="ic-status"/>
                                        }
                                        <p>{dataPreview?.status}</p>
                                    </div>
                                } type={dataPreview?.status}/>
                            </div>
                            {
                                dataPreview?.status === tabList[2].id &&
                                <div className="flex">
                                    <div className={"w-44 text-neutral-70 pr-2"}>Note</div>
                                    <div className={"flex-1 font-medium text-neutral-80"}>{dataPreview?.reject_reason || '-'}</div>
                                </div>
                            }
                        </div>
                        {
                            dataPreview?.status === tabList[0].id &&
                            <div className="space-y-5">
                                <div className={"font-medium text-neutral-80"}>Update Status Pengajuan</div>
                                <div className={"flex gap-2.5 px-2.5"}>
                                    <Button className="w-full" icon={icChecked} title="Approved" type="outline"
                                            onClick={() => onSelect(optionBulk?.APPROVE)}/>
                                    <Button className="w-full" icon={icCross} title="Rejected" type="danger"
                                            onClick={() => onSelect(optionBulk?.REJECT)}/>
                                </div>
                            </div>
                        }
                    </div>
                    :
                    <div className={"py-6 overflow-scroll"}>
                        {
                            dataPreview?.logs?.map((item, index) => (
                                <div className="pb-2" key={index}>
                                    <div className={"flex flex-row items-center gap-5"}>
                                        <div
                                            className={"bg-primary-50 w-5 h-5 rounded-full flex flex-col justify-center items-center"}>
                                            <div className={"bg-white w-3 h-3 rounded-full"}/>
                                        </div>
                                        <p className={"text-neutral-80 font-bold capitalize"}>{item?.type}</p>
                                    </div>
                                    <div
                                        className={`text-neutral-70 pb-5 ${index !== (dataPreview?.logs?.length - 1) ? 'border-l-2 border-neutral-50 ml-2 mt-1 pl-8' : 'pl-10'}`}>
                                        <p className={"font-semibold pt-3"}>Modified by</p>
                                        <p className="font-semibold">{item?.actor}</p>
                                        <p className="pt-3">{formatDateMoment(item?.created_at, 'DD MMM YYYY HH:mm:ss')}</p>
                                    </div>
                                </div>
                            ))
                        }
                    </div>}
            </div>
        </Drawer>
    )
}
