import { WorkExperience } from "components/employee/employee-add/WorkExperience"
import { ACTION_TYPE } from "constants/constant"
import { DEFAULT_FORM_WORK_EXPERIENCE } from "constants/defaultFormEmployee"
import { useValidation } from "hooks/useValidation"
import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setWorkExperience } from "reducers/addEmployeeReducer"

export const WorkExperienceContainer = () => {
    const dispatch = useDispatch()
    const dataEmployee = useSelector((state) => state.addEmployeeReducer)
    const [showModal, setShowModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [dataWorkExperience, setDataWorkExperience] = useState(DEFAULT_FORM_WORK_EXPERIENCE)
    const [selectedIndex, setSelectedIndex] = useState()
    const [actionType, setActionType] = useState(ACTION_TYPE.CREATE)
    const { isFormValid } = useValidation({ form: dataWorkExperience })

    const onChangeForm = (e) => {
        const { name, value } = e
        setDataWorkExperience({ ...dataWorkExperience, [name]: value })
    }

    const onCloseModal = () => {
        setShowModal(false)
        setDataWorkExperience(DEFAULT_FORM_WORK_EXPERIENCE)
        setActionType(ACTION_TYPE.CREATE)
    }

    const onSubmit = () => {
        const newData = [...dataEmployee.work_experiences]
        switch (actionType) {
            case ACTION_TYPE.CREATE:
                newData.push(dataWorkExperience)
                break;
            case ACTION_TYPE.EDIT:
                newData[selectedIndex] = dataWorkExperience
                break;
            default:
        }
        dispatch(setWorkExperience(newData))
        onCloseModal()
    }

    const handleEdit = (item) => {
        setDataWorkExperience(item)
        setActionType(ACTION_TYPE.EDIT)
        setShowModal(true)
    }

    const handleRemove = (index) => {
        const newData = [...dataEmployee.work_experiences]
        newData.splice(index, 1)
        dispatch(setWorkExperience(newData))
        setShowDeleteModal(false)
    }

    const onActionRow = ({ index, item, actionType }) => {
        setSelectedIndex(index)
        switch (actionType) {
            case ACTION_TYPE.EDIT:
                return handleEdit(item);
            case ACTION_TYPE.DELETE:
                return setShowDeleteModal(true)
            default:
        }
    }

    const props = {
        dataWorkExperience,
        isEdit: actionType === ACTION_TYPE.EDIT,
        isFormValid: isFormValid(),
        form: dataEmployee.work_experiences,
        showModal,
        onShowModal: () => setShowModal(true),
        onCloseModal,
        onChangeForm,
        onSubmit,
        onActionRow,
        showDeleteModal,
        onCloseDeleteModal: () => setShowDeleteModal(false),
        onDelete: () => handleRemove(selectedIndex)
    }

    return (
        <WorkExperience {...props} />
    )
}