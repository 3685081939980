import {useFetch} from "../../../services/useFetch";
import {API_JOB_TITLE} from "../../../services/apiJobTitle";
import {useEffect, useState} from "react";
import {constructOptionsSelect, findOptionSelected} from "../../../utils/utils";
import {ACTION_TYPE} from "../../../constants/constant";
import {JobTitleSettingsForm} from "../../../components/organization-structure-management/job-title-settings/JobTitleSettingsForm";
import {useSelector} from "react-redux";
import { INITIAL_STATE } from "./JobTitleSettingsContainer";

export const JobTitleSettingsFormContainer = (
    {
        modal,
        jobTitleDetail,
        actionType,
        validateForm,
        errorForm,
        updateId,
        setJobTitleDetail,
        setShowModal,
        setShowToast,
        fetchJobTitleList
    }
) => {
    const {activatedCompany} = useSelector(state => state.companiesReducer)
    const {fetching: getJobUnitList} = useFetch(API_JOB_TITLE.JOB_UNIT_LIST)
    const {fetching: getGradeList} = useFetch(API_JOB_TITLE.GRADE_LIST)
    const {fetching: getWorkingTimeCategoryList} = useFetch(API_JOB_TITLE.WORKING_TIME_CATEGORY_LIST)
    const {fetching: getN1List} = useFetch(API_JOB_TITLE.N1_LIST)
    const {fetching: detailJobTitle} = useFetch(API_JOB_TITLE.DETAIL_JOB_TITLE)
    const {fetching: createJobTitle, isLoading: isCreating} = useFetch(API_JOB_TITLE.CREATE_JOB_TITLE)
    const {fetching: updateJobTitle, isLoading: isUpdating} = useFetch(API_JOB_TITLE.UPDATE_JOB_TITLE)
    const [optionList, setOptionList] = useState({
        grade: [],
        job_unit: {
            management: [],
            department: [],
            division: [],
            ['sub-division']: []
        },
        working_time_category: [],
        n1: []
    })
    const getUnitText = {
        department_id: ['management_id', 'department'],
        division_id: ['department_id', 'division'],
        sub_division_id: ['division_id', 'sub-division']
    }

    const getDataForm = () => {
        if (updateId) {
            detailJobTitle({}, updateId).then(response => {
                const details = response?.data
                setDataOptions(details, getApiJobUnit(details))
            })
        } else {
            setDataOptions()
        }
    }

    const getApiJobUnit = (item) => {
        const apiList = [null, null, null]
        Object.keys(item)?.forEach(key => {
            if (key === 'department_id' && item[key]) {
                apiList[0] = getJobUnitList({head_unit_ids: [item?.management_id]}, 'department')
            } else if (key === 'division_id' && item[key]) {
                apiList[1] = getJobUnitList({head_unit_ids: [item?.department_id]}, 'division')
            } else if (key === 'sub_division_id' && item[key]) {
                apiList[2] = getJobUnitList({head_unit_ids: [item?.division_id]}, 'sub-division')
            }
        })

        return apiList?.filter(item => item)
    }

    const setDataOptions = (details, additionalApiList = []) => {
        Promise.all([
            getGradeList(),
            getWorkingTimeCategoryList(),
            getN1List(),
            getJobUnitList({}, 'management'),
            ...additionalApiList,
        ]).then(response => {
            const options = {
                grade: constructOptionsSelect(response[0]?.data, 'name', 'id'),
                job_unit: {
                    management: constructOptionsSelect(response[3]?.data, 'name', 'id'),
                    department: response[4]?.data ? constructOptionsSelect(response[4]?.data, 'name', 'id') : [],
                    division: response[5]?.data ? constructOptionsSelect(response[5]?.data, 'name', 'id') : [],
                    ['sub-division']: response[6]?.data ? constructOptionsSelect(response[6]?.data, 'name', 'id') : []
                },
                working_time_category: constructOptionsSelect(response[1]?.data, 'name', 'id'),
                n1: constructOptionsSelect(response[2]?.data, 'title', 'id')
            }
            setOptionList(options)
            if (updateId) setDetailJobTitle(options, details)
        })
    }

    const setDetailJobTitle = (options, data) => {
        setJobTitleDetail({
            title: data?.title,
            management_id: findOptionSelected(options?.job_unit?.management, data?.management_id),
            department_id: findOptionSelected(options?.job_unit?.department, data?.department_id),
            division_id: findOptionSelected(options?.job_unit?.division, data?.division_id),
            sub_division_id: findOptionSelected(options?.job_unit?.['sub-division'], data?.sub_division_id),
            description: data?.description,
            grade_ids: constructOptionsSelect(data?.grades, 'name', 'id'),
            schedule_category_id: findOptionSelected(options?.working_time_category, data?.schedule_category_id),
            direct_report_id: findOptionSelected(options?.n1, data?.direct_report_id),
            quota: data?.quota
        })
    }

    const onSelectJobUnit = (jobUnit, data) => {
        const newSelected = {...jobTitleDetail}
        const newOptions = {...optionList}
        switch (jobUnit) {
            case 'management_id':
                newSelected.department_id = null
                newOptions.job_unit.department = []
                newSelected.division_id = null
                newOptions.job_unit.division = []
                newSelected.sub_division_id = null
                newOptions.job_unit['sub-division'] = []
                break
            case 'department_id':
                newSelected.division_id = null
                newOptions.job_unit.division = []
                newSelected.sub_division_id = null
                newOptions.job_unit['sub-division'] = []
                break
            case 'division_id':
                newSelected.sub_division_id = null
                newOptions.job_unit['sub-division'] = []
                break
            default:
                return 0
        }
        setJobTitleDetail({...newSelected, ...data})
        setOptionList({...optionList, ...newOptions})
    }

    const getJobUnit = (jobUnit, head_unit_ids = []) => {
        const prevJobUnitOptions = {...optionList?.job_unit}
        getJobUnitList({head_unit_ids}, getUnitText[jobUnit]?.[1]).then(response => {
            const newOptions = {
                job_unit: {...prevJobUnitOptions, [getUnitText[jobUnit]?.[1]]: constructOptionsSelect(response?.data, 'name', 'id')}
            }
            setOptionList({...optionList, ...newOptions})
        })
    }

    const onChangeValue = (item, key) => {
        const newData = {[item?.name]: item?.value}
        if (key === 'job_unit') {
            onSelectJobUnit(item?.name, newData)
        } else {
            setJobTitleDetail({...jobTitleDetail, ...newData})
        }
    }

    const onClickFieldJobUnit = (item) => {
        const headUnitId = jobTitleDetail[getUnitText[item?.name]?.[0]]?.value
        if (item?.is_show && headUnitId && !optionList?.job_unit?.[getUnitText[item?.name]?.[1]]?.length) getJobUnit(item?.name, [headUnitId])
    }

    const constructJobTitleDetail = () => {
        const jobTitleDetailCopy = {...jobTitleDetail}
        Object.keys(jobTitleDetailCopy)?.forEach(key => {
            switch (key) {
                case 'management_id':
                case 'department_id':
                case 'division_id':
                case 'sub_division_id':
                case 'schedule_category_id':
                case 'direct_report_id':
                    jobTitleDetailCopy[key] = jobTitleDetailCopy[key]?.value ?? null
                    break
                case 'grade_ids':
                    jobTitleDetailCopy[key] = jobTitleDetailCopy[key]?.map(i => i?.value)
                    break
                case 'quota':
                    jobTitleDetailCopy[key] = jobTitleDetailCopy[key] ? Number(jobTitleDetailCopy[key]) : null
                    break
                default:
                    return 0
            }
        })

        return jobTitleDetailCopy
    }

    const onSubmitForm = () => {
        const payload = constructJobTitleDetail()
        validateForm(payload).then(() => {
            if (actionType === ACTION_TYPE.CREATE) {
                createJobTitle(payload)
                    .then(() => {
                        setShowToast('Job Title Created !!', {form: false})
                        fetchJobTitleList()
                    })
            } else {
                updateJobTitle(payload, updateId)
                    .then(() => {
                        setShowToast('Job Title Updated !!', {form: false})
                        fetchJobTitleList()
                    })
            }
        })
    }

    useEffect(() => {
        getDataForm()
    }, [])

    const props = {
        title: `${actionType === ACTION_TYPE.CREATE ? 'Create' : 'Update'} Job Title`,
        show: modal?.form,
        activatedCompany,
        jobTitleDetail,
        optionList,
        errorForm,
        isCreating,
        isUpdating,
        onHide: () => {
            setShowModal({form: false})
            setJobTitleDetail(INITIAL_STATE)
        },
        onClickField: onClickFieldJobUnit,
        onChangeValue,
        onSubmitForm
    }

    return (
        <JobTitleSettingsForm {...props}/>
    )
}
