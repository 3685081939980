import {RemoteWorkingSetting} from "components/general-settings/remote-working-setting/RemoteWorkingSetting"
import {useEffect, useState} from "react"
import {useFetch} from "../../../services/useFetch";
import {API_REMOTE_WORKING_SETTING} from "../../../services/apiRemoteWorkingSetting";
import {useValidation} from "../../../hooks/useValidation";

const INITIAL_STATE = {
    quota: null,
    month_to_start: null
}

export const RemoteWorkingSettingContainer = () => {
    const [form, setForm] = useState(INITIAL_STATE)
    const [isShowToast, setIsShowToast] = useState(false)
    const {errorForm, validateForm} = useValidation({
        schema: {
            quota: {
                required: "Kuota remote working tidak boleh kosong"
            }
        }
    })
    const {fetching: detailRemoteWorking} = useFetch(API_REMOTE_WORKING_SETTING.DETAIL_REMOTE_WORKING)
    const {fetching: updateRemoteWorking, isLoading: isUpdating} = useFetch(API_REMOTE_WORKING_SETTING.UPDATE_REMOTE_WORKING)

    const clearToast = () => {
        setIsShowToast(false)
    }

    const onChangeForm = (e) => {
        const {name, value} = e
        setForm({...form, [name]: value})
    }

    const onSubmit = () => {
        validateForm(form)
            .then(() => {
                updateRemoteWorking(form)
                    .then(() => setIsShowToast(true))
            })
    }

    useEffect(() => {
        detailRemoteWorking().then(response => setForm({...form, ...response?.data}))
    }, [])

    const props = {
        form,
        isShowToast,
        clearToast,
        onChangeForm,
        onSubmit,
        isUpdating,
        errorForm
    }

    return (
        <RemoteWorkingSetting {...props} />
    )
}
