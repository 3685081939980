import clsx from "clsx";
import Input from "../../commons/input";
import ErrorMessage from "../../commons/error-message";
import Button from "../../commons/button";
import TextEditor from "../../commons/text-editor";
import Datepicker from "../../commons/datepicker";
import moment from "moment";
import Timepicker from "../../commons/timepicker";
import Select from "../../commons/select";
import icTrashRed from "../../../assets/images/ic-trash-01-red.svg";

export const HRISAnnouncementForm = (
    {
        announcementId,
        announcementDetail,
        files,
        companiesList,
        onChangeValue,
        onUploadFile,
        onDeleteFile,
        isLoadingUpload,
        isCreating,
        isUpdating,
        onSubmitForm,
        errorForm
    }) => {

    return (
        <>
            <div className={"flex flex-col space-y-10 px-6 pt-8 pb-[100px] w-1/2"}>
                <p className={"font-bold text-md text-primary-60"}>Form {!announcementId ? 'Tambah' : 'Ubah'} Pengumuman</p>
                <div className={"flex text-neutral-70"}>
                    <div className={"w-1/3 required mt-2"}>Judul Pengumuman</div>
                    <div className="flex-1">
                        <Input name="title" placeholder="Tulis Judul Announcement" value={announcementDetail?.title}
                               onChange={(e) => onChangeValue(e)}/>
                        <ErrorMessage className="mt-2" message={errorForm?.title}/>
                    </div>
                </div>
                <div className={"flex text-neutral-70"}>
                    <div className={"w-1/3 mt-2 required"}>Uraian Pengumuman</div>
                    <div className={`flex-1 w-2/3 ${errorForm?.description ? 'space-y-12' : 'mb-10'}`}>
                        <TextEditor
                            defaultValue={announcementDetail?.description}
                            onChange={(val) => onChangeValue({name: "description", value: val})}
                            placeholder="Isi Uraian Pengumuman"
                        />
                        <ErrorMessage message={errorForm?.description}/>
                    </div>
                </div>
                <div className={"flex text-neutral-70"}>
                    <div className={"w-1/3 mt-2 required"}>Tanggal Publish</div>
                    <div className="flex-1">
                        <Datepicker
                            className={"flex-1 z-20"}
                            value={announcementDetail?.published_date}
                            placeholder="Pilih Tanggal"
                            format='YYYY-MM-DD'
                            minDate={moment()}
                            maxDate={`${moment().get('year') + 1}-12-31`}
                            onChange={(e) => onChangeValue(e, 'published_date')}
                        />
                        <ErrorMessage className="mt-2" message={errorForm?.published_date}/>
                    </div>
                </div>
                <div className={"flex text-neutral-70"}>
                    <div className={"w-1/3 mt-2 required"}>Waktu Publish</div>
                    <div className="flex-1">
                        <Timepicker
                            className="flex-1"
                            name="published_time"
                            placeholder="Pilih Waktu"
                            value={announcementDetail?.published_time}
                            onChange={(e) => onChangeValue(e)}
                        />
                        <ErrorMessage className="mt-2" message={errorForm?.published_time}/>
                    </div>
                </div>
                <div className={"flex text-neutral-70"}>
                    <div className={"w-1/3 required mt-2"}>Pilih Perusahaan</div>
                    <div className="flex-1">
                        <Select
                            name="company_ids"
                            className="flex-1"
                            optionsClassName="max-h-40"
                            placeholder="Pilih Perusahaan"
                            options={companiesList}
                            value={announcementDetail?.company_ids}
                            onChange={(val) => onChangeValue(val)}
                            isMultiple
                        />
                        <ErrorMessage className="mt-2" message={errorForm?.company_ids}/>
                    </div>
                </div>
                <div className={"flex text-neutral-70"}>
                    <div className={"w-1/3 mt-2"}>File Announcement</div>
                    <div className={clsx('flex-1 w-2/3', files?.length && 'space-y-4')}>
                        {
                            files?.map((item, index) => (
                                <div key={index} className={"flex flex-wrap gap-y-2 items-center justify-between"}>
                                    <span className={"font-medium text-md"}>{item?.name || item}</span>
                                    <div className={"flex space-x-2"}>
                                        <Button type="danger" icon={icTrashRed} onClick={() => onDeleteFile(index)} />
                                        <input className="hidden" accept="application/pdf, image/*" id={`reupload-attachments-${index}`} type="file" onChange={(e) => onUploadFile(e, index)} />
                                        <label className="avo__button outline cursor-pointer" type="button" htmlFor={`reupload-attachments-${index}`}>
                                            <div className="btn-content">Upload Ulang</div>
                                        </label>
                                    </div>
                                </div>
                            ))
                        }
                        <div className="w-fit">
                            <input className="hidden" accept="application/pdf, image/*" id="attachments" type="file" onChange={onUploadFile} />
                            <label className="avo__button outline cursor-pointer" type="button" htmlFor="attachments">
                                <div className="btn-content">{`Upload ${files?.length ? 'Dokumen Lain' : 'Dokumen'}`}</div>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"flex items-center justify-end fixed bottom-0 w-[calc(100%-200px)] bg-white border-t px-14 py-6 z-10"}>
                <Button className="w-32" title="Submit" loading={isLoadingUpload || isCreating || isUpdating} onClick={onSubmitForm} />
            </div>
        </>
    )
}
