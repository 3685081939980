import Button from "../../components/commons/button";
import icEdit from "../../assets/images/ic-edit-04.svg";

export const TABLE_ACTIVITY_SETTING = {
    name: 'Activity',
    column: [
        {
            name: 'Activity Name',
            key: "name",
            className: 'font-semibold capitalize',
            render: ({value}) => <div>{value?.replace('-', ' ')}</div>
        },
        {
            name: "Approval 1",
            key: "approval_1",
            className: "capitalize"
        },
        {
            name: "Approval 2",
            key: "approval_2",
            className: "capitalize"
        },
        {
            name: "Approval 3",
            key: "approval_3",
            className: "capitalize"
        },
        {
            name: "Action",
            key: "action",
            render: ({item, onClickItem}) => (
                <div className="flex gap-3 mx-auto">
                    <Button icon={icEdit} type="outline" onClick={() => onClickItem(item)}/>
                </div>
            )
        }
    ]
}

export const INITIAL_DATA_FILTER_ACTIVITY = {
    page: 1,
    limit: 20
}
