import {useEffect, useState} from "react";
import {ImportData} from "../../../../components/organization-structure-management/grade-setting/import-grade/ImportData";
import {useFetch} from "../../../../services/useFetch";
import {API_GRADE} from "../../../../services/apiGrade";

export const ImportDataContainer = ({payload, onChangeStep, setProgressing, onHide}) => {
    const [percentage, setPercentage] = useState(0)
    const [isComplete, setIsComplete] = useState(false)
    const {fetching: importData, isLoading} = useFetch(API_GRADE.IMPORT)

    useEffect(() => {
        if (percentage === 10) {
            importData(payload, null, {'Content-Type': 'multipart/form-data'})
                .then().catch(() => {
                setProgressing(false)
                onChangeStep(1)
            })
        }
    }, [percentage])

    useEffect(() => {
        let timeout = null
        if (percentage <= 80) {
            timeout = setTimeout(() => {
                setPercentage(percentage + 10)
            }, 1000)
        } else if (!isLoading) {
            timeout = setTimeout(() => {
                if (percentage === 100) {
                    setIsComplete(true)
                    setProgressing(false)
                } else {
                    setPercentage(percentage + 10)
                }
            }, 1000)
        }

        return () => {
            clearTimeout(timeout)
        }
    }, [percentage, isLoading])

    const props = {
        percentage,
        isComplete,
        onHide
    }

    return (
        <ImportData {...props}/>
    )
}
