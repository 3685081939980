import {ApprovalSetting} from "components/matrix-approval/approval-setting/ApprovalSetting";
import {useEffect, useState} from "react";
import {useFetch} from "../../../services/useFetch";
import {INITIAL_DATA_FILTER_APPROVAL} from "../../../configs/matrix-approval/configTableApproval";
import {API_APPROVAL} from "../../../services/apiApproval";
import {constructOptionsSelect, findOptionSelected, getAllQueryParams, setQueryParams} from "../../../utils/utils";
import {useLocation, useSearchParams} from "react-router-dom";

const INITIAL_STATE = {
    id: null,
    title: null,
    approver_1: null,
    approver_2: null
}

export const ApprovalSettingContainer = () => {
    const location = useLocation()
    const {data: approvalList, fetching: getApprovalList, isLoading} = useFetch(API_APPROVAL.APPROVAL_LIST)
    const {fetching: getApproverList} = useFetch(API_APPROVAL.APPROVER_LIST)
    const {fetching: updateApproval, isLoading: isUpdating} = useFetch(API_APPROVAL.UPDATE_APPROVAL)
    const [approvalDetail, setApprovalDetail] = useState(INITIAL_STATE)
    const [optionList, setOptionList] = useState([])
    const [isShowToast, setIsShowToast] = useState(false)
    const [toastMessage, setToastMessage] = useState('')
    const [isShowForm, setShowForm] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams()
    const query = searchParams.size > 0 ? getAllQueryParams(searchParams) : INITIAL_DATA_FILTER_APPROVAL

    useEffect(() => {
        fetchApprovalList({...query})
    }, [location?.search])

    const onChangeQuery = (queryParams) => {
        const params = setQueryParams({...query, ...queryParams})
        setSearchParams(params, {replace: true})
    }

    const clearToast = () => {
        setIsShowToast(false)
        setToastMessage('')
    }

    const onHideForm = () => {
        setShowForm(false)
    }

    const onClickItemHandler = (item) => {
        setShowForm(true)
        getApproverList({}, item?.id)
            .then(response => {
                const options = constructOptionsSelect([{
                    title: 'Tidak Ada',
                    id: null
                }, ...response?.data], 'title', 'id')
                setOptionList(options)
                setApprovalDetail({
                    ...item,
                    approver_1: findOptionSelected(options, item?.approver_1 || 'Tidak Ada', 'label'),
                    approver_2: findOptionSelected(options, item?.approver_2 || 'Tidak Ada', 'label'),
                })
            })
    }

    const fetchApprovalList = (item = query) => {
        getApprovalList(item)
    }

    const onChangeValue = (item) => {
        const newData = {[item?.name]: item?.value}
        setApprovalDetail({...approvalDetail, ...newData})
    }

    const onSubmitForm = () => {
        const payload = {
            approver_1: approvalDetail?.approver_1?.value ?? null,
            approver_2: approvalDetail?.approver_2?.value ?? null
        }
        updateApproval(payload, approvalDetail?.id)
            .then(() => {
                onHideForm()
                setIsShowToast(true)
                setToastMessage('Approval Updated !!')
                fetchApprovalList()
            })
    }

    const props = {
        dataList: approvalList?.data,
        isShowForm,
        isShowToast,
        toastMessage,
        query,
        clearToast,
        onChangeQuery,
        onHideForm,
        onClickItemHandler,
        onSubmitForm,
        onChangeValue,
        approvalDetail,
        optionList,
        isLoading,
        isUpdating
    }

    return (
        <ApprovalSetting {...props}/>
    )
}
