import Table from "../../commons/table";
import {useState} from "react";
import icUploadWhite from "assets/images/ic-upload-white.svg"
import icChevronLeftWhite from "assets/images/ic-chevron-left-white.svg"
import Button from "../../commons/button";
import {TABLE_IMPORT_SHIFTING_SCHEDULE, TABLE_IMPORT_SHIFTING_SCHEDULE_DETAIL} from "../../../configs/request-shifting/configTableImportShifting";
import icUploadGrey from "assets/images/ic-upload-grey.svg";
import Modal from "../../commons/modal";
import icCloseSquare from "../../../assets/images/ic-close-square.svg";

export const ViewData = (
    {
        isValidated,
        dataImport,
        detailsShifting,
        showDetails,
        currentPageDetails,
        setModalCurrentPage,
        onHideModalDetails,
        setToPreviousStep,
        onClickItemHandler,
        onSubmitImport
    }) => {
    const [currentPage, setCurrentPage] = useState(1)

    return (
        <div>
            <div className={"flex flex-col space-y-6 px-40"}>
                <div>
                    <Button className={"mb-6"} title="Back" icon={icChevronLeftWhite} onClick={() => setToPreviousStep()}/>
                    <p className={"font-medium text-md text-neutral-80"}> Total Data
                        <span className={"font-bold text-base text-primary-50 ml-2"}>{dataImport?.statistics?.total}</span>
                    </p>
                    <Table
                        name=""
                        data={dataImport?.shiftings?.slice((5 * (currentPage - 1)), currentPage * 5)}
                        columns={TABLE_IMPORT_SHIFTING_SCHEDULE.column}
                        size={5}
                        totalPage={Math.ceil(dataImport?.statistics?.total / 5)}
                        totalResult=" "
                        onChangePage={(e) => setCurrentPage(e)}
                        onClickItem={(val) => onClickItemHandler(val)}
                    />
                </div>
                <div className={"flex justify-end"}>
                    <Button
                        title="Import Data"
                        icon={isValidated ? icUploadWhite : icUploadGrey}
                        onClick={onSubmitImport}
                        disabled={!isValidated}
                    />
                </div>
            </div>
            {
                showDetails &&
                <Modal
                    show={showDetails}
                    dialogClassName="max-w-[590px]"
                    contentClassName="rounded-xl"
                    children={
                        <>
                            <div className={"px-6 py-3 flex flex-row items-center justify-between border-b"}>
                                <p className={"font-medium text-md text-neutral-80 text-center"}>
                                    Details Shifting Schedule
                                </p>
                                <Button
                                    type="outline"
                                    shape="circle"
                                    size="small"
                                    icon={icCloseSquare}
                                    onClick={onHideModalDetails}
                                />
                            </div>
                            <Table
                                className="p-5"
                                name={TABLE_IMPORT_SHIFTING_SCHEDULE_DETAIL.name}
                                data={detailsShifting?.slice((10 * (currentPageDetails - 1)), currentPageDetails * 10)}
                                columns={TABLE_IMPORT_SHIFTING_SCHEDULE_DETAIL.column}
                                size={10}
                                totalPage={Math.ceil(detailsShifting?.length / 10)}
                                totalResult={detailsShifting?.length}
                                onChangePage={(e) => setModalCurrentPage(e)}
                            />
                        </>
                    }
                />
            }
        </div>
    )
}
