import {METHOD} from "./method";

export const API_LEAVE_SETTING = {
    LEAVE_LIST: {
        api: '/v1/hr-adm/general-setting/leave/list',
        method: METHOD.GET
    },
    PREVIEW_LEAVE: {
        api: '/v1/hr-adm/general-setting/leave/preview',
        method: METHOD.GET
    },
    EMPLOYEE_LIST: {
        api: '/v1/hr-adm/general-setting/leave/employees',
        method: METHOD.GET
    },
    CREATE_LEAVE: {
        api: '/v1/hr-adm/general-setting/leave',
        method: METHOD.POST
    },
    DETAIL_LEAVE: {
        api: '/v1/hr-adm/general-setting/leave',
        method: METHOD.GET
    },
    UPDATE_LEAVE: {
        api: '/v1/hr-adm/general-setting/leave',
        method: METHOD.PUT
    },
    DELETE_LEAVE: {
        api: '/v1/hr-adm/general-setting/leave',
        method: METHOD.DELETE
    },
}
