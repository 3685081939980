import {METHOD} from "./method";

export const API_OVERTIME = {
    OVERTIME_LIST: {
        api: '/v1/hr-adm/overtime/list',
        method: METHOD.POST
    },
    PREVIEW_OVERTIME: {
        api: '/v1/hr-adm/overtime/preview',
        method: METHOD.GET
    },
    EXPORT_OVERTIME_LIST: {
        api: '/v1/hr-adm/overtime/list/export',
        method: METHOD.POST
    },
    APPROVE_OVERTIME: {
        api: '/v1/hr-adm/overtime/approve',
        method: METHOD.POST
    },
    REJECT_OVERTIME: {
        api: '/v1/hr-adm/overtime/reject',
        method: METHOD.POST
    }
}
