import icEmail from "../../../../assets/images/ic-email.svg";
import icUserProfile from "../../../../assets/images/ic-user-profile.svg";

export const EmployeeProfileDetail = ({employeeDetail}) => {
  return (
      <div className={"px-9 py-6 bg-neutral-10 flex flex-row justify-between items-center rounded"}>
          <div className={"flex flex-row space-x-6"}>
              <img src={employeeDetail?.profile_picture ?? icUserProfile} alt={'profile-image'}
                   className={"w-[68px] h-[68px] rounded-full border"}/>
              <div className={"space-y-3"}>
                  <p className={"font-semibold text-md text-neutral-80"}>{employeeDetail.name}</p>
                  <div className={"flex flex-row gap-2"}>
                      <p className={"text-neutral-70 font-medium"}>No. NIK</p>
                      <p className={"text-neutral-80 font-semibold"}>{employeeDetail.nik}</p>
                  </div>
                  <div className={"flex flex-row gap-2"}>
                      <p className={"text-neutral-70 font-medium"}>No. HP</p>
                      <p className={"text-neutral-80 font-semibold"}>{employeeDetail.phone_number}</p>
                  </div>
                  <div className={"flex flex-row gap-2"}>
                      <img src={icEmail}/>
                      <p className={"text-primary-50"}>{employeeDetail?.email}</p>
                  </div>
              </div>
          </div>
          <div className={"space-y-3 flex flex-col items-end"}>
              <p className={"font-semibold"}>Nomor Rekening</p>
              <div className={"flex flex-row space-x-3"}>
                  <img src={employeeDetail.bank.image} className={"bg-cover h-5"}/>
                  <p>{employeeDetail.bank.name}</p>
              </div>
              <div className={"flex flex-row items-center space-x-4"}>
                  <div className={"flex flex-row items-center font-medium space-x-2"}>
                      <p className={"text-neutral-50"}>No. Rek</p>
                      <p className={"text-neutral-80"}>{employeeDetail.bank.account_number}</p>
                  </div>
                  <div className={"flex flex-row items-center font-medium space-x-2"}>
                      <p className={"text-neutral-50"}>a.n</p>
                      <p className={"text-neutral-80"}>{employeeDetail.bank.account_name}</p>
                  </div>
              </div>
          </div>
      </div>
  )
}
