import { METHOD } from "./method";

export const API_EMPLOYEE = {
    DELETE_EMPLOYEE: {
        api: '/v1/hr-adm/employee/delete',
        method: METHOD.POST
    },
    CREATE_EMPLOYEE: {
        api: '/v1/hr-adm/employee',
        method: METHOD.POST
    },
    CREATE_DRAFT_EMPLOYEE: {
        api: '/v1/hr-adm/employee/draft',
        method: METHOD.POST
    },
    LIST_EMPLOYEE: {
        api: '/v1/hr-adm/employee/list',
        method: METHOD.POST
    },
    EXPORT_EMPLOYEE: {
        api: '/v1/hr-adm/employee/export',
        method: METHOD.POST
    },
    LIST_CONSTANTS_EMPLOYEE: {
        api: '/v1/hr-adm/employee/constants',
        method: METHOD.GET
    },
    LIST_BANK: {
        api: '/v1/hr-adm/employee/banks',
        method: METHOD.GET
    },
    VALIDATE_BANK_ACCOUNT: {
        api: '/v1/hr-adm/employee/bank/validate',
        method: METHOD.POST
    },
    LIST_COMPANY: {
        api: '/v1/hr-adm/employee/companies',
        method: METHOD.GET
    },
    LIST_JOB_TITLE: {
        api: '/v1/hr-adm/employee/job-titles',
        method: METHOD.GET
    },
    DETAIL: {
        api: '/v1/hr-adm/employee',
        method: METHOD.GET
    },
    UPDATE_PERSONAL_DETAIL: {
        api: '/v1/hr-adm/employee/personal-detail',
        method: METHOD.PUT
    },
    UPDATE_ADDITIONAL_INFORMATION: {
        api: '/v1/hr-adm/employee/additional-info',
        method: METHOD.PUT
    },
    JOB_TITLE: {
        api: '/v1/hr-adm/employee/job-title',
        method: METHOD.POST
    },
    FAMILY: {
        api: '/v1/hr-adm/employee/family',
        method: METHOD.POST
    },
    EMERGENCY_CONTACT: {
        api: '/v1/hr-adm/employee/emergency-contact',
        method: METHOD.POST
    },
    EDUCATION: {
        api: '/v1/hr-adm/employee/education',
        method: METHOD.POST
    },
    WORK_EXPERIENCE: {
        api: '/v1/hr-adm/employee/work-experience',
        method: METHOD.POST
    },
    PERSONAL_DOCUMENT: {
        api: '/v1/hr-adm/employee/personal-document',
        method: METHOD.POST
    },
    URL_PERSONAL_DOCUMENT: {
        api: '/v1/hr-adm/employee/storage-url/personal-document',
        method: METHOD.POST
    },
    URL_PROFILE_PICTURE: {
        api: '/v1/hr-adm/employee/storage-url/profile-picture',
        method: METHOD.POST
    },
    UPDATE_BANK: {
        api: '/v1/hr-adm/employee/bank',
        method: METHOD.PUT
    },
    UPDATE_CARD: {
        api: '/v1/hr-adm/employee/card',
        method: METHOD.PUT
    },
    LIST_JOB_UNIT: {
        api: '/v1/hr-adm/employee/job-unit',
        method: METHOD.POST
    },
    CHANGE_PASSWORD: {
        api: '/v1/hr-adm/employee/change-password',
        method: METHOD.POST
    }
}
