import {useSearchParams} from "react-router-dom";
import {STATUS_TYPE} from "../../../../../constants/constant";
import {RenewContractContainer} from "./renew-contract/RenewContractContainer";
import {AddendumContractContainer} from "./addendum-contract/AddendumContractContainer";
import {EditContractContainer} from "./edit-contract/EditContractContainer";

export const ModifyContractContainer = () => {
    const [searchParams] = useSearchParams();
    const action = searchParams.get('action')

    switch (action) {
        case STATUS_TYPE.RENEW:
            return <RenewContractContainer />
        case STATUS_TYPE.ADDENDUM:
            return <AddendumContractContainer type={'addendum'} />
        case STATUS_TYPE.INTERIM:
            return <AddendumContractContainer type={'interim'} />
        default:
            return <EditContractContainer />
    }
}
