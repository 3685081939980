import icFileLock from "../../../../assets/images/ic-file-lock-02.svg";
import {getBgStatusColor, getTextStatusColor} from "../../../../utils/utils";
import {Link} from "react-router-dom";

export const ContractList = ({contractList, contractIdParams}) => {
    return (
        <div className={"border-r-8 border-b-8 border-neutral-10 "}>
            <div className={"p-6 flex flex-row items-center gap-3"}>
                <img src={icFileLock} alt={'ic-file-lock'}/>
                <p className={"text-neutral-80 font-bold text-md"}>List Contract</p>
            </div>
            <div className={"px-6 py-3 space-y-3"}>
                {
                    contractList.map((item, index) => (
                        <Link to={`?contractId=${item.id}`} key={index}>
                            <div
                                className={`px-4 py-2 cursor-pointer space-y-3 ${item?.id === Number(contractIdParams) ? 'rounded-r bg-primary-10 border-l-2 border-primary-50' : ''}`}>
                                <p className={"text-neutral-70 font-bold"}>No. {item.number}</p>
                                <p className={`border w-fit rounded-md shrink px-[10px] font-semibold flex-wrap capitalize ${getTextStatusColor[item.status]} ${getBgStatusColor[item.status]}`}>{item.status}</p>
                            </div>
                        </Link>
                    ))
                }
            </div>
        </div>
    )
}
