import {SettingTypeAllowance} from "../../../components/organization-structure-management/setting-type-allowance/SettingTypeAllowance";
import {useLocation, useSearchParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {getAllQueryParams, setQueryParams} from "../../../utils/utils";
import {INITIAL_DATA_FILTER_TYPE_ALLOWANCE} from "../../../configs/organization-structure/configTableGrade";
import {ACTION_TYPE} from "../../../constants/constant";
import {useFetch} from "../../../services/useFetch";
import {API_TYPE_ALLOWANCE} from "../../../services/apiGrade";

const INITIAL_STATE = {
    name: null,
    is_active: false
}

export const SettingTypeAllowanceContainer = () => {
    const location = useLocation()
    const {data: typeAllowanceList, fetching: getTypeAllowanceList, isLoading} = useFetch(API_TYPE_ALLOWANCE.LIST_TYPE_ALLOWANCE)
    const {fetching: createTypeAllowance, isLoading: isCreating} = useFetch(API_TYPE_ALLOWANCE.CREATE_TYPE_ALLOWANCE)
    const {fetching: updateTypeAllowance, isLoading: isUpdating} = useFetch(API_TYPE_ALLOWANCE.UPDATE_TYPE_ALLOWANCE)
    const [typeAllowanceDetail, setTypeAllowanceDetail] = useState(INITIAL_STATE)
    const [isShowForm, setShowForm] = useState(false)
    const [isShowToast, setIsShowToast] = useState(false)
    const [toastMessage, setToastMessage] = useState('')
    const [actionType, setActionType] = useState(ACTION_TYPE.CREATE)
    const [searchParams, setSearchParams] = useSearchParams()
    const query = searchParams.size > 0 ? getAllQueryParams(searchParams) : INITIAL_DATA_FILTER_TYPE_ALLOWANCE

    useEffect(() => {
        fetchTypeAllowanceList({...query})
    }, [location?.search])

    const onChangeQuery = (queryParams) => {
        const params = setQueryParams({...query, ...queryParams})
        setSearchParams(params, {replace: true})
    }

    const clearToast = () => {
        setIsShowToast(false)
        setToastMessage('')
    }

    const setShowToast = (message) => {
        setShowForm(false)
        setIsShowToast(true)
        setToastMessage(message)
    }

    const onHideForm = () => {
        setShowForm(false)
    }

    const onClickItemHandler = ({actionType, item}) => {
        setShowForm(true)
        setActionType(actionType)

        if (actionType === ACTION_TYPE.CREATE) {
            setTypeAllowanceDetail(INITIAL_STATE)
        } else {
            setTypeAllowanceDetail(item)
        }
    }

    const onChangeValue = ({name, value, checked}) => {
        const newItem = checked === undefined ? {[name]: value} : {[name]: checked}
        setTypeAllowanceDetail({...typeAllowanceDetail, ...newItem})
    }

    const fetchTypeAllowanceList = (item = query) => {
        getTypeAllowanceList(item)
    }

    const onSubmitForm = () => {
        const {id, ...payload} = typeAllowanceDetail

        if (actionType === ACTION_TYPE.CREATE) {
            createTypeAllowance(payload)
                .then(() => {
                    setShowToast('Type Allowance Created !!')
                    fetchTypeAllowanceList()
                })
        } else {
            updateTypeAllowance(payload, id)
                .then(() => {
                    setShowToast('Type Allowance Updated !!')
                    fetchTypeAllowanceList()
                })
        }
    }

    const props = {
        dataList: typeAllowanceList?.data,
        typeAllowanceDetail,
        isShowForm,
        isLoading,
        isShowToast,
        toastMessage,
        query,
        isCreating,
        isUpdating,
        title: `${actionType === ACTION_TYPE.CREATE ? 'Create' : 'Update'} Type Allowance`,
        clearToast,
        onHideForm,
        onClickItemHandler,
        onChangeValue,
        onChangeQuery,
        onSubmitForm
    }

    return (
        <SettingTypeAllowance {...props}/>
    )
}
