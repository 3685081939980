import Drawer from "../commons/drawer";
import icUserProfile from "../../assets/images/ic-user-profile.svg";
import Badge from "../commons/badge";
import Button from "../commons/button";
import icChecked from "../../assets/images/ic-check-02.svg";
import icCross from "../../assets/images/ic-x-02.svg";
import {formatDateMoment, getStatusIcon, setCurrency} from "../../utils/utils";

export const PreviewIncentive = (
    {
        visibility,
        employeeDetail,
        tabList,
        previewTab,
        optionBulk,
        activePreviewTab,
        onHide,
        onChangePreviewTab,
        onSelect
    }) => {
    const {employee, request_info, history} = {...employeeDetail}

    return (
        <Drawer visibility={visibility} onHide={onHide} title="Detail Additional Incentive">
            <div className={"mt-5 px-6 space-y-5"}>
                <div className={"flex flex-row items-center gap-4"}>
                    <img src={employee?.profile_picture ?? icUserProfile} alt="employee-profile-image"
                         className={"w-9 h-9 rounded-full border"}/>
                    <div className={"space-y-1 text-neutral-80 capitalize"}>
                        <p className={"text-md font-bold"}>{employee?.full_name}</p>
                        <p className={"font-semibold"}>{employee?.job_title}</p>
                    </div>
                </div>
                <div className="flex justify-between items-start gap-[9px]">
                    {
                        previewTab?.map((tab, index) => (
                            <div
                                key={index}
                                onClick={() => onChangePreviewTab(tab)}
                                className={`w-full inline-flex ${activePreviewTab === tab?.id && 'border-b-2 border-b-primary-50'} cursor-pointer`}>
                                <div className={"rounded-lg w-full flex justify-center items-center"}>
                                    <div className={"p-3 justify-start items-center gap-2.5 inline-flex"}>
                                        <div className={`text-center text-[14px] ${activePreviewTab === tab?.id ? 'text-primary-50 font-bold' : 'text-neutral-60 font-semibold'} leading-tight tracking-tight capitalize`}>
                                            {tab?.name}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
                {
                    activePreviewTab === previewTab[0]?.id ?
                        <div>
                            <div className={"flex flex-col space-y-3"}>
                                <div className={"flex flex-row p-1"}>
                                    <div className="w-1/2">Nama</div>
                                    <div className={"w-1/2 font-semibold"}>{request_info?.full_name}</div>
                                </div>
                                <div className={"flex flex-row p-1"}>
                                    <div className="w-1/2">NIP</div>
                                    <div className={"w-1/2 font-semibold"}>{request_info?.nip}</div>
                                </div>
                                <div className={"flex flex-row p-1"}>
                                    <div className="w-1/2">Job Title</div>
                                    <div className={"w-1/2 font-semibold"}>{request_info?.job_title}</div>
                                </div>
                                <div className={"flex flex-row p-1"}>
                                    <div className="w-1/2">Grade</div>
                                    <div className={"w-1/2 font-semibold"}>{request_info?.grade}</div>
                                </div>
                                <div className={"flex flex-row p-1"}>
                                    <div className="w-1/2">Department</div>
                                    <div className={"w-1/2 font-semibold"}>{request_info?.department}</div>
                                </div>
                                <div className={"flex flex-row p-1"}>
                                    <div className="w-1/2 whitespace-pre-line">{'Jenis Additional\n Incentive'}</div>
                                    <div className={"w-1/2 font-semibold"}>{request_info?.type}</div>
                                </div>
                                {
                                    request_info?.status === tabList[1].id &&
                                    <div className={"flex flex-row p-1"}>
                                        <div className="w-1/2">Nominal</div>
                                        <div className={"w-1/2 font-medium"}>
                                            {request_info?.nominal_benefit ? setCurrency(request_info?.nominal_benefit) : '-'}
                                        </div>
                                    </div>
                                }
                                <div className={"flex flex-row p-1"}>
                                    <div className="w-1/2">Deskripsi</div>
                                    <div className={"w-1/2 font-semibold"}>{request_info?.submit_reason}</div>
                                </div>
                                <div className={"flex flex-row p-1"}>
                                    <div className="w-1/2">Status</div>
                                    <div className="w-1/2">
                                        <Badge title={
                                            <div className={"flex items-center space-x-1"}>
                                                {getStatusIcon(request_info?.status) &&
                                                    <img src={getStatusIcon(request_info?.status)} alt="ic-status"/>}
                                                <p>{request_info?.status}</p>
                                            </div>
                                        } type={request_info?.status}/>
                                    </div>
                                </div>
                                {
                                    request_info?.status === tabList[2].id &&
                                    <div className={"flex flex-row p-1"}>
                                        <div className="w-1/2">Note</div>
                                        <div className={"w-1/2 font-medium"}>{request_info?.reject_reason || '-'}</div>
                                    </div>
                                }
                            </div>
                            {
                                request_info?.status === tabList[0].id &&
                                <div className="mt-3">
                                    <p className="font-semibold">Update Status Pengajuan</p>
                                    <div className={"flex justify-center items-start gap-[9px] mt-3"}>
                                        <Button className="w-1/2" icon={icChecked} title="Approved" type="outline"
                                                onClick={() => onSelect(optionBulk[0])}/>
                                        <Button className="w-1/2" icon={icCross} title="Rejected" type="danger"
                                                onClick={() => onSelect(optionBulk[1])}/>
                                    </div>
                                </div>
                            }
                        </div>
                        :
                        <div className="overflow-scroll">
                            {
                                history.map((item, index) => (
                                    <div className="pb-2" key={index}>
                                        <div className={"flex flex-row items-center gap-5"}>
                                            <div className={"bg-primary-50 w-5 h-5 rounded-full flex flex-col justify-center items-center"}>
                                                <div className={"bg-white w-3 h-3 rounded-full"}/>
                                            </div>
                                            <p className={"text-neutral-80 font-bold capitalize"}>{item?.action}</p>
                                        </div>
                                        <div
                                            className={`text-neutral-70 pb-5 ${index !== (history.length - 1) ? 'border-l-2 border-neutral-50 ml-2 mt-1 pl-8' : 'pl-10'}`}>
                                            <p className={"font-semibold pt-3"}>{item?.actor}</p>
                                            <p className="pt-3">{formatDateMoment(item.date, 'DD MMM YYYY HH:mm:ss')}</p>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                }
            </div>
        </Drawer>
    )
}
