import {useLocation} from "react-router-dom";
import {CreatePayroll} from "../../../components/payroll/create-payroll/CreatePayroll";
import {SelectMonthContainer} from "./SelectMonthContainer";
import {EmployeeListContainer} from "./EmployeeListContainer";
import {GenerateComponentContainer} from "./GenerateComponentContainer";
import {EmployeePayrollListContainer} from "./EmployeePayrollListContainer";
import {getPathUrl} from "../../../utils/utils";

export const CreatePayrollContainer = () => {
    const location = useLocation()
    const lastPath = getPathUrl(location, '/', -1).toString()
    const stepContent = [
        {
            path: 'pilih-bulan-payroll',
            item: <SelectMonthContainer/>,
            sequence: 1
        },
        {
            path: 'list-employee',
            item: <EmployeeListContainer/>,
            sequence: 2
        },
        {
            path: 'generate-komponen',
            item: <GenerateComponentContainer/>,
            sequence: 3
        },
        {
            path: 'list-employee-payroll',
            item: <EmployeePayrollListContainer/>,
            sequence: 4
        }
    ]

    return (
        <CreatePayroll stepContent={stepContent} lastPath={lastPath}/>
    )
}
