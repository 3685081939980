import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    modal : {
        export: false,
        sort: false,
        filter: false,
        toast: false,
    },
    selectedRows: [],
    totalData: 0,
    attendanceList: {}
}

export const attendanceSlice = createSlice({
    name: 'attendance',
    initialState: initialState,
    reducers: {
        setModal: (state, action) => ({
            ...state,
            modal: {...state.modal, ...action.payload}
        }),
        setSelectedRows: (state, action) => ({
            ...state, selectedRows: action.payload
        }),
        setTotalData: (state, action) => ({
            ...state, totalData: action.payload
        }),
        setAttendanceList: (state, action) => ({
            ...state,
            attendanceList: action.payload
        })
    },
})

export const {
    setModal,
    setSelectedRows,
    setTotalData,
    setAttendanceList,
} = attendanceSlice.actions

const attendanceReducer = attendanceSlice.reducer
export default attendanceReducer
