import {ImportData} from "components/management-contract/create-contract/create-contract-import/ImportData";
import {useEffect, useState} from "react";
import {useFetch} from "../../../../services/useFetch";
import {API_CONTRACT_IMPORT} from "../../../../services/apiContractImport";

export const ImportDataContainer = ({payload, onChangeStep}) => {
    const [percentage, setPercentage] = useState(0)
    const [isComplete, setIsComplete] = useState(false)
    const {fetching: importData, isLoading} = useFetch(API_CONTRACT_IMPORT.IMPORT)

    useEffect(() => {
        if (percentage === 10) {
            importData(payload, null, {'Content-Type': 'multipart/form-data'})
                .then().catch(() => {
                onChangeStep(1)
            })
        }
    }, [percentage])

    useEffect(() => {
        let timeout = null
        if (percentage <= 80) {
            timeout = setTimeout(() => {
                setPercentage(percentage + 10)
            }, 1000)
        } else if (!isLoading) {
            timeout = setTimeout(() => {
                percentage === 100 ? setIsComplete(true) : setPercentage(percentage + 10)
            }, 1000)
        }

        return () => {
            clearTimeout(timeout)
        }
    }, [percentage, isLoading])

    return (
        <ImportData percentage={percentage} isComplete={isComplete}/>
    )
}
