import Button from "../button";
import icCloseSquare from "../../../assets/images/ic-close-square.svg";
import Input from "../input";
import Modal from "../modal";

export const ModalApproval = (
    {
        id,
        title,
        modal,
        setModal,
        status,
        optionBulk,
        selectedLength,
        rejectReason,
        setRejectReason,
        onUpdateStatusRequest,
    }) => {

    return (
        <Modal
            dialogClassName="w-full max-w-[350px]"
            contentClassName="rounded-xl"
            show={modal?.bulk_update}
        >
            <div>
                <div className={"p-3 flex flex-row items-center justify-between border-b"}>
                    <p className={"font-semibold text-md capitalize"}>{`${status} Request`}</p>
                    <Button type="outline" shape="circle" size="small" icon={icCloseSquare}
                            onClick={() => setModal({bulk_update: false})}/>
                </div>
                <div className={"py-4 px-3 flex flex-col space-y-2"}>
                    <p className="font-semibold">{`Apakah anda yakin ingin melakukan ${status === (optionBulk[1] || optionBulk.REJECT) ? 'Reject' : 'approve'}
                                ${title}?`}</p>
                    <p className={`text-neutral-70 mt-3 ${!modal?.preview ? 'block' : 'hidden'}`}>{`${selectedLength} Data terpilih`}</p>
                </div>
                {
                    status === (optionBulk[1] || optionBulk.REJECT) &&
                    <div className={"px-3 mb-2 flex flex-col space-y-2"}>
                        <p className="font-medium">Berikan alasan penolakan</p>
                        <Input type="text-area" row={4} maxLength={300} value={rejectReason}
                               showCount onChange={(item) => setRejectReason(item?.value)}/>
                    </div>
                }
                <div className={"px-6 py-3 flex flex-row border-t space-x-3 justify-center"}>
                    <Button type="outline" title="Cancel"
                            onClick={() => setModal({bulk_update: false})}/>
                    {
                        status === (optionBulk[1] || optionBulk.REJECT) ?
                            <Button title="Reject" disabled={!rejectReason}
                                    onClick={() => onUpdateStatusRequest(optionBulk[1] || optionBulk.REJECT, modal?.preview && id)}/>
                            :
                            <Button title="Approve"
                                    onClick={() => onUpdateStatusRequest(optionBulk[0] || optionBulk.APPROVE, modal?.preview && id)}/>
                    }
                </div>
            </div>
        </Modal>
    )
}
