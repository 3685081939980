import { EmergencyContactInformation } from "components/employee/employee-add/EmergencyContactInformation"
import { ACTION_TYPE } from "constants/constant"
import { DEFAULT_FORM_EMERGENCY_CONTACT, OPTIONS_FAMILY_RELATIONSHIP, OTHER_RELATIONSHIP } from "constants/defaultFormEmployee"
import { useValidation } from "hooks/useValidation"
import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setEmergencyContact } from "reducers/addEmployeeReducer"
import { addFamilyValidation } from "services/addEmployeeValidation"
import { clearPhoneNumber, formatPhoneNumber } from "utils/utils"

export const EmergencyContactInformationContainer = () => {
    const dispatch = useDispatch()
    const dataEmployee = useSelector((state) => state.addEmployeeReducer)
    const [showModal, setShowModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [dataEmergency, setDataEmergency] = useState(DEFAULT_FORM_EMERGENCY_CONTACT)
    const [selectedIndex, setSelectedIndex] = useState()
    const [actionType, setActionType] = useState(ACTION_TYPE.CREATE)
    const { errorForm, validateForm, isFormValid } = useValidation({ form: dataEmergency, schema: addFamilyValidation })
    const isOtherRelationship = dataEmergency?.relationship?.value === OTHER_RELATIONSHIP

    const onChangeForm = (e) => {
        const { name, value } = e
        setDataEmergency({
            ...dataEmergency,
            [name]: value,
            ...(!isOtherRelationship && { other_relationship: null }),
        })
    }

    const onCloseModal = () => {
        setShowModal(false)
        setDataEmergency(DEFAULT_FORM_EMERGENCY_CONTACT)
        setActionType(ACTION_TYPE.CREATE)
    }

    const onSubmit = () => {
        validateForm().then(() => {
            const newEmergencyContacts = [...dataEmployee.emergency_contacts]
            const newDataEmergencyContact = {
                ...dataEmergency,
                phone_number: formatPhoneNumber(dataEmergency.phone_number),
                relationship: dataEmergency.relationship.value === OTHER_RELATIONSHIP ? dataEmergency.other_relationship : dataEmergency.relationship.value,
            }
            switch (actionType) {
                case ACTION_TYPE.CREATE:
                    newEmergencyContacts.push(newDataEmergencyContact)
                    break;
                case ACTION_TYPE.EDIT:
                    newEmergencyContacts[selectedIndex] = newDataEmergencyContact
                    break;
                default:
            }
            dispatch(setEmergencyContact(newEmergencyContacts))
            onCloseModal()
        })
    }

    const handleEdit = (item) => {
        const findRelationship = OPTIONS_FAMILY_RELATIONSHIP.find((find) => find.value === item.relationship)
        setDataEmergency({
            ...item,
            phone_number: clearPhoneNumber(item.phone_number),
            relationship: findRelationship || { value: OTHER_RELATIONSHIP, label: OTHER_RELATIONSHIP },
            ...(!findRelationship && { other_relationship: item.relationship }),
        })
        setActionType(ACTION_TYPE.EDIT)
        setShowModal(true)
    }

    const handleRemove = (index) => {
        const newEmergencyContacts = [...dataEmployee.emergency_contacts]
        newEmergencyContacts.splice(index, 1)
        dispatch(setEmergencyContact(newEmergencyContacts))
        setShowDeleteModal(false)
    }

    const onActionRow = ({ index, item, actionType }) => {
        setSelectedIndex(index)
        switch (actionType) {
            case ACTION_TYPE.EDIT:
                return handleEdit(item);
            case ACTION_TYPE.DELETE:
                return setShowDeleteModal(true);
            default:
        }
    }

    const checkValidationForm = () => {
        const checkForm = { ...dataEmergency }
        if (!isOtherRelationship) delete checkForm.other_relationship
        return isFormValid(checkForm)
    }

    const props = {
        optionsFamilyRelationship: OPTIONS_FAMILY_RELATIONSHIP,
        dataEmergency,
        errorForm,
        isFormValid: checkValidationForm(),
        isEdit: actionType === ACTION_TYPE.EDIT,
        form: dataEmployee.emergency_contacts,
        showModal,
        onShowModal: () => setShowModal(true),
        onCloseModal,
        onChangeForm,
        onSubmit,
        onActionRow,
        showDeleteModal,
        onCloseDeleteModal: () => setShowDeleteModal(false),
        onDelete: () => handleRemove(selectedIndex)
    }

    return (
        <EmergencyContactInformation {...props} />
    )
}