import {AccountInformation} from "components/employee/employee-add/AccountInformation"
import {useEffect, useMemo, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {setPersonalDetail} from "reducers/addEmployeeReducer"
import {API_EMPLOYEE} from "services/apiEmployee"
import {useFetch} from "services/useFetch"

export const AccountInformationContainer = ({errorForm}) => {
    const dispatch = useDispatch()
    const dataEmployee = useSelector((state) => state.addEmployeeReducer)
    const [selectedBank, setSelectedBank] = useState()
    const {data: dataListBank} = useFetch(API_EMPLOYEE.LIST_BANK, {}, {isFetching: true})
    const {fetching: validateBankAccount} = useFetch(API_EMPLOYEE.VALIDATE_BANK_ACCOUNT)

    const optionsBank = useMemo(() => {
        if (dataListBank?.data?.length === 0) return []
        return dataListBank?.data?.map((bank) => ({
            label: bank.name,
            value: bank.code
        }))
    }, [dataListBank?.data])

    useEffect(() => {
        setSelectedBank({
            label: dataEmployee?.personal_detail?.bank_name,
            value: dataEmployee?.personal_detail?.bank_code,
        })
    }, [dataEmployee?.personal_detail?.bank_code])

    const onChangeForm = (e) => {
        const {name, value} = e
        if (name === 'bank_name') {
            setSelectedBank(value)
            dispatch(setPersonalDetail({
                name: name,
                value: value
            }))
            return
        }
        dispatch(setPersonalDetail(e))
    }

    const onValidateAccount = () => {
        const params = {
            bank: selectedBank.value,
            account: dataEmployee?.personal_detail?.bank_account_number
        }
        validateBankAccount(params).then((response) => {
            dispatch(setPersonalDetail({name: 'bank_account_name', value: response.data.account_name}))
        })
            .catch(err => {
                if(err.code === '503-A001'){
                    dispatch(setPersonalDetail({name: 'bank_account_number', value: ''}))
                }
            })
    }

    const props = {
        canValidate: selectedBank?.value && dataEmployee?.personal_detail?.bank_account_number,
        optionsBank,
        selectedBank,
        form: dataEmployee,
        errorForm,
        onChangeForm,
        onValidateAccount,
    }

    return (
        <AccountInformation {...props} />
    )
}
