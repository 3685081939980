import {Incentive} from "../../../components/payroll/incentive/incentive";
import {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {IncentiveListContainer} from "./IncentiveListContainer";

const tabList = [
  {
    label: 'Personal Incentive',
    key: 'personal-incentive',
  }, {
    label: 'Monthly Group Incentive',
    key: 'monthly-group-incentive',
  }, {
    label: 'Quarterly Group Incentive',
    key: 'quarterly-group-incentive',
  }, {
    label: 'Company Wide Performance',
    key: 'company-wide-performance',
  },
]
export const IncentiveContainer = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [selectedTab, setSelectedTab] = useState(tabList[0])
  const path = location.pathname.split('/')
  const lastPath = path.pop()

  useEffect(() => {
    if(lastPath === 'incentive'){
      navigate(`personal-incentive`)
    } else {
      if(lastPath !== selectedTab.key){
        let newTab = tabList.find(i => i.key === lastPath)
        setSelectedTab(newTab)
      }
    }
  }, []);

  const onChangeTab = (tab) => {
    navigate(`/payroll/incentive/${tab.key}`)
    setSelectedTab(tab)
  }

  return (
      <div className="flex flex-col h-full">
        <div className="h-1 bg-neutral-10" />

        <div className={"bg-white flex flex-1"}>
          <Incentive list={tabList} selectedTab={selectedTab} onChangeTab={onChangeTab}/>
          <div className={"w-full h-full"}>
            <IncentiveListContainer type={selectedTab.key} lastPath={lastPath}/>
          </div>
        </div>

      </div>
  )
}
