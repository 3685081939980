import Badge from "../../components/commons/badge";

export const TABLE_IMPORT_DATA_EMPLOYEE = {
    column: [
        {
            name: "Username",
            key: "username",
            className: "font-semibold",
            width: 250
        },
        {
            name: "Name",
            key: "full_name",
            className: "font-semibold",
            width: 250
        },
        {
            name: "Email",
            key: "personal_email",
            width: 250
        },
        {
            name: "Status",
            key: "status",
            width: 250,
            render: ({value}) => <Badge title={value} type={value}/>
        }
    ]
}
