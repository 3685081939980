import Datepicker from "components/commons/datepicker";
import FormModal from "components/commons/form-modal";
import Input from "components/commons/input";
import Select from "components/commons/select";
import moment from "moment/moment";


export const CreateEducation = ({
    show,
    onHide,
    optionsGrades,
    dataEducation,
    onChangeForm,
    onSubmit,
    hasMajor,
    isFormValid,
    isEdit,
    isLoadingSubmit,
}) => {
    return (
        <FormModal
            show={show}
            onHide={onHide}
            title={`${isEdit ? 'Edit' : 'Tambah'} Data Pendidikan`}
            onSubmitForm={onSubmit}
            isLoading={isLoadingSubmit}
            isDisabled={!isFormValid}
        >
            <div className={"px-8 py-6 mb-2 space-y-6"}>
                <div className="flex items-center gap-3">
                    <div className="font-medium w-1/3 text-neutral-70">{'Jenjang Pendidikan'}</div>
                    <Select
                        name="grade"
                        className='flex-1'
                        placeholder="Pilih pendidikan"
                        options={optionsGrades}
                        value={dataEducation?.grade}
                        onChange={onChangeForm}
                    />
                </div>
                <div className="flex items-center gap-3">
                    <div className="font-medium w-1/3 text-neutral-70">{'Nama Instansi'}</div>
                    <Input
                        className='flex-1'
                        name="institution"
                        placeholder={"Isi nama instansi"}
                        maxLength={100}
                        value={dataEducation?.institution}
                        onChange={onChangeForm}
                    />
                </div>
                {hasMajor && <div className="flex items-center gap-3">
                    <div className="font-medium w-1/3 text-neutral-70">{'Jurusan'}</div>
                    <Input
                        className='flex-1'
                        name="major"
                        placeholder={"Isi jurusan"}
                        maxLength={100}
                        value={dataEducation?.major}
                        onChange={onChangeForm}
                    />
                </div>}
                <div className="flex items-center gap-3">
                    <div className="font-medium w-1/3 text-neutral-70">{'IPK / Nilai Akhir'}</div>
                    <Input
                        className='flex-1'
                        name="final_score"
                        placeholder={"Isi nilai IPK / Nilai akhir"}
                        maxLength={4}
                        pattern={/^[0-9.]+$/}
                        value={dataEducation?.final_score}
                        onChange={onChangeForm}
                    />
                </div>
                <div className="flex items-center gap-3">
                    <div className="font-medium w-1/3 text-neutral-70">{'Tgl Masuk'}</div>
                    <Datepicker
                        name='education_start_date'
                        className="flex-1"
                        placeholder="Pilih tanggal masuk"
                        value={dataEducation?.start_date}
                        format='YYYY-MM-DD'
                        maxDate={moment()}
                        onChange={(e) => onChangeForm({ name: 'start_date', value: e })}
                    />
                </div>
                <div className="flex items-center gap-3">
                    <div className="font-medium w-1/3 text-neutral-70">{'Tgl Keluar'}</div>
                    <Datepicker
                        name='education_end_date'
                        className="flex-1"
                        placeholder="Pilih tanggal keluar"
                        value={dataEducation?.end_date}
                        format='YYYY-MM-DD'
                        maxDate={moment()}
                        onChange={(e) => onChangeForm({ name: 'end_date', value: e })}
                    />
                </div>
            </div>
        </FormModal>
    )
}
