import {useSearchParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {useFetch} from "../../../../../../services/useFetch";
import {API_CONTRACT} from "../../../../../../services/apiContract";
import {setContractDetail, setOptions} from "../../../../../../reducers/contractReducer";
import {FORM_CREATE_CONTRACT} from "../../../../../../constants/constant-management-contract";
import {FormContractContainer} from "../../../../form-contract/FormContractContainer";
import {constructOptionsSelect, convertArrayToObject, formatDateMoment} from "../../../../../../utils/utils";
import {Alert} from "../../../../../../components/commons/alert";

export const RenewContractContainer = () => {
    const [searchParams] = useSearchParams();
    const contractId = searchParams.get('contractId')
    const employeeId = searchParams.get('employeeId')
    const dispatch = useDispatch()
    const [errorForm, setErrorForm] = useState({})
    const {contractDetail} = useSelector(state => state.contractReducer)
    const {fetching: getContractDetailApi} = useFetch(API_CONTRACT.DETAIL_CONTRACT)
    const {fetching: getCompanyListApi} = useFetch({...API_CONTRACT.LIST_COMPANY, initPathVariable: employeeId})
    const {fetching: getConstantApi} = useFetch(API_CONTRACT.CONSTANT)
    const {fetching: renewContractApi} = useFetch(API_CONTRACT.RENEW_CONTRACT)

    useEffect(() => {
        Promise.all([getContractDetailApi({}, contractId), getConstantApi(), getCompanyListApi()])
            .then(res => {
                let ptkp_status = constructOptionsSelect(res[1].data.ptkp_status)
                let type = constructOptionsSelect(res[1].data.type)
                let company = constructOptionsSelect(res[2].data, 'name', 'company_id')
                dispatch(setContractDetail({nip: res[0].data.nip, ptkp_status: ptkp_status[0]}))
                dispatch(setOptions({ptkp_status, type, company}))
            })
    }, []);

    const onSubmitForm = () => {
        let requestBody = {
            ...contractDetail,
            company_id: contractDetail.company.value,
            job_title_id: contractDetail.job_title.value,
            grade_id: contractDetail.grade.value,
            type: contractDetail.type.value,
            ptkp_status: contractDetail.ptkp_status.value,
            start_date: formatDateMoment(contractDetail.start_date, 'YYYY-MM-DD'),
            end_date: contractDetail.type.value === 'PKWT' ? formatDateMoment(contractDetail.end_date, 'YYYY-MM-DD') : null,
        }

        renewContractApi(requestBody, contractId)
            .then(res => {
                Alert({
                    icon: 'success',
                    title: 'Success',
                    message: 'Perubahan Contract berhasil!',
                })
                window.location.href = `/management-contract/contract-employee/employee-detail?contractId=${contractId}`
            })
            .catch(err => {
                console.log(err)
                if (err.code === '422') {
                    setErrorForm(convertArrayToObject(err.data))
                }
            })
    }

    let props = {
        errorForm,
        onSubmitForm,
        form: FORM_CREATE_CONTRACT,
        activity: 'renew'
    }

    return (
        <FormContractContainer {...props}/>
    )
}
