import { NPWPInformation } from "components/employee/employee-add/NPWPInformation"
import { useDispatch, useSelector } from "react-redux"
import { setNPWPInformation } from "reducers/addEmployeeReducer"

export const NPWPInformationContainer = ({errorForm}) => {
    const dispatch = useDispatch()
    const dataEmployee = useSelector((state) => state.addEmployeeReducer)

    return (
        <NPWPInformation
            form={dataEmployee}
            onChangeForm={(e) => dispatch(setNPWPInformation(e))}
            errorForm={errorForm}
        />
    )
}
