import {createSlice} from "@reduxjs/toolkit";
import {ACTION_TYPE} from "../constants/constant";

const INITIAL_STATE = {
    actionType: ACTION_TYPE.CREATE,
    workTimeDetail: {
        category_name: null,
        type: 'regular',
        monday: { clock_in_time: null, clock_out_time: null },
        tuesday: { clock_in_time: null, clock_out_time: null },
        wednesday: { clock_in_time: null, clock_out_time: null },
        thursday: { clock_in_time: null, clock_out_time: null },
        friday: { clock_in_time: null, clock_out_time: null },
        saturday: { clock_in_time: null, clock_out_time: null },
        sunday: { clock_in_time: null, clock_out_time: null }
    },
    toast: {
        message: '',
        isShowToast: false
    }
}

export const formWorkTimeSlice = createSlice({
    name: 'form-work-time',
    initialState: INITIAL_STATE,
    reducers: {
        resetInitialWorkTime: () => INITIAL_STATE,
        setActionType: (state, action) => {
            state.actionType = action.payload
        },
        setWorkTimeDetail: (state, action) => {
            state.workTimeDetail = {...INITIAL_STATE?.workTimeDetail, ...action.payload}
        },
        setWorkTimeToast: (state, action) => {
            state.toast = {...state.toast, ...action.payload}
        }
    }
})

export const {
    resetInitialWorkTime,
    setActionType,
    setWorkTimeDetail,
    setWorkTimeToast
} = formWorkTimeSlice.actions
const formWorkTimeReducer = formWorkTimeSlice.reducer

export default formWorkTimeReducer
