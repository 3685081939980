import {useState} from "react";
import {UploadFileContainer} from "./UploadFileContainer";
import {ImportDataPayroll} from "../../../../components/payroll/create-payroll/import-data-payroll/ImportDataPayroll";
import {STEPS_IMPORT_DATA_PAYROLL} from "../../../../constants/constant";
import Modal from "../../../../components/commons/modal";
import {useDispatch, useSelector} from "react-redux";
import {setPayrollModalImport, setPayrollToast} from "../../../../reducers/payrollReducer";
import Button from "../../../../components/commons/button";
import icCloseSquare from "../../../../assets/images/ic-close-square.svg";
import {ValidationDataContainer} from "./ValidationDataContainer";
import {ImportDataContainer} from "./ImportDataContainer";

export const ImportDataPayrollContainer = ({params, updateList}) => {
    const dispatch = useDispatch()
    const {isShowModalImport} = useSelector(state => state.payrollReducer)
    const [activeStep, setActiveStep] = useState(1)
    const [payload, setPayload] = useState({file: {}})
    const [isProgressing, setIsProgressing] = useState(false)

    const setPayloadData = (payload) => {
        setPayload(payload)
    }

    const onChangeStep = (step) => {
        setActiveStep(step)
    }

    const onHide = () => {
        if (activeStep === 3) {
            dispatch(setPayrollToast({isShowToast: true, message: 'Berhasil Import Data'}))
        }
        dispatch(setPayrollModalImport(false))
        setActiveStep(1)
        setPayload({file: {}})
    }

    const onFinish = () => {
        onHide()
        updateList()
    }

    const stepContent = [
        <UploadFileContainer
            params={params}
            payload={payload}
            onChangeStep={onChangeStep}
            setPayloadData={setPayloadData}
            // setProgressing={setIsProgressing}
        />,
        <ValidationDataContainer
            params={params}
            payload={payload}
            onChangeStep={onChangeStep}
            // setProgressing={setIsProgressing}
        />,
        <ImportDataContainer
            params={params}
            payload={payload}
            onFinish={onFinish}
            onChangeStep={onChangeStep}
            // setProgressing={setIsProgressing}
        />
    ]

    return (
        <Modal
            dialogClassName="w-[70%]"
            contentClassName="rounded-xl"
            show={isShowModalImport}
            onHide={onHide}
        >
            <div className={"px-6 py-3 flex flex-row items-center justify-between border-b"}>
                <p className={"font-semibold text-md"}>Import Data</p>
                <Button type="outline" shape="circle" size="small" icon={icCloseSquare} onClick={onHide}/>
            </div>
            <ImportDataPayroll steps={STEPS_IMPORT_DATA_PAYROLL} activeStep={activeStep} stepContent={stepContent}/>
        </Modal>
    )
}
