import Stepper from "../../../commons/stepper";
import icChevronLeftWhite from "../../../../assets/images/ic-chevron-left-white.svg";
import Button from "../../../commons/button";

export const ImportDataPayroll = ({steps, stepContent, activeStep, setToFirstStep, isErrorValidation}) => {
    return (
        <div className={"flex flex-col items-center py-8 space-y-8"}>
            <div className={"flex items-center justify-between"}>
                {isErrorValidation && <Button className="mr-16 mb-1" title="Back" icon={icChevronLeftWhite} onClick={setToFirstStep}/>}
                <Stepper steps={steps} activeStep={activeStep}/>
                <div/>
            </div>
            {stepContent[activeStep - 1]}
        </div>
    )
}
