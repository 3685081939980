import Table from "../../commons/table";
import {TABLE_SHIFTING_LIST} from "../../../configs/request-shifting/configTableShiftingList";
import Search from "../../commons/search";
import icFilter from "../../../assets/images/ic-filter-new-02.svg";
import DateRangePicker from "../../commons/date-range-picker";
import moment from "moment/moment";
import ViewList from "../../commons/view-list";
import icFileUp from "../../../assets/images/ic-file-up-02.svg";
import Button from "../../commons/button";
import {ExportModal} from "../../commons/ExportModal";
import icFileDown from "../../../assets/images/ic-file-down-02.svg";
import Toast from "../../commons/toast";

export const ShiftingList = (
    {
        dataList,
        isLoading,
        columnTable,
        query,
        modal,
        selectedIds,
        isShowToast,
        toastMessage,
        clearToast,
        onChangeQuery,
        setShowModal,
        onSelectHandler,
        onExport,
        onImport
    }
) => {

    return (
        <div>
            <div className={"flex justify-between px-6 py-4"}>
                <div className={"flex space-x-7"}>
                    <Search value={query?.search} onSearch={(key) => onChangeQuery({search: key, page: 1})}/>
                    <div className={"flex gap-3"}>
                        <div className="flex items-center space-x-2">
                            <img src={icFilter} alt="icon-filter"/>
                            <p className="title">Filter by Date:</p>
                            <DateRangePicker
                                value={query?.start_date ? [query?.start_date, query?.end_date] : []}
                                onChange={(value) => onChangeQuery({page: 1, start_date: value[0], end_date: value[1]})}
                                format='YYYY-MM-DD'
                                maxDate={`${moment().get('year') + 1}-12-31`}
                                minRange={7}
                                maxRange={30}
                            />
                        </div>
                        <ViewList
                            show={modal?.limit}
                            onShow={() => setShowModal({ limit: true })}
                            onClose={() => setShowModal({ limit: false })}
                            value={query?.limit}
                            onSelectSize={(limit) => onChangeQuery({ page: 1, limit })}
                        />
                        <Button title="Export" icon={icFileUp} type="outline" disabled={!dataList?.total_data}
                                onClick={() => setShowModal({export: true})}/>
                    </div>
                </div>
                <Button type="outline" title="Import Shifting Schedule" icon={icFileDown} onClick={onImport}/>
            </div>
            <Table
                className="my-3"
                name={TABLE_SHIFTING_LIST.name}
                columns={columnTable}
                data={dataList?.result?.employees}
                size={query?.limit}
                currentPage={query?.page}
                totalPage={dataList?.total_page}
                totalResult={dataList?.total_data}
                onChangePage={(val) => onChangeQuery({page: val})}
                onSelectItem={(val) => onSelectHandler(val)}
                loading={isLoading}
            />
            <ExportModal
                title="Apakah anda yakin ingin export data shifting?"
                description={`Total data shifting export: ${selectedIds?.length || dataList?.total_data}`}
                show={modal?.export}
                onExportHandler={onExport}
                onCancelHandler={() => setShowModal({export: false})}
            />
            <Toast
                show={isShowToast}
                message={toastMessage}
                onClose={() => clearToast()}
            />
        </div>
    )
}
