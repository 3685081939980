import icAddEmpty from "../../../assets/images/ic-personal-incentive.svg";
import Button from "../../commons/button";
import icAddSquare from "../../../assets/images/ic-plus-01.svg";
import Search from "../../commons/search";
import icFileDown from "../../../assets/images/ic-file-down-02.svg";
import icAdd from "../../../assets/images/ic-add-personal-incentive.svg";
import Table from "../../commons/table";
import {EditIncentiveForm} from "./EditIncentiveForm";
import icEdit from "../../../assets/images/ic-edit-04.svg";
import Toast from "../../commons/toast";
import {ImportDataPayrollContainer} from "../../../containers/payroll/incentive/import/ImportDataPayrollContainer";

const ACTION_BUTTON = [{
    name: "Action",
    key: "action",
    render: ({item, onClickItem}) => (
        <div className="flex gap-3 mx-auto">
            <Button icon={icEdit} type="outline" onClick={() => onClickItem(item)}/>
        </div>
    )
}]

export const IncentiveList = (props) => {
    const {
        type,
        query,
        onChangeQuery,
        data,
        column,
        onClickItemHandler,
        isLoading,
        createPercentageBarrier,
        show,
        onHide,
        onSubmitForm,
        formValue,
        onChangeForm,
        toast,
        clearToast,
        showModalImport,
        getIncentive
    } = props

    return (
        <div className={"flex-1 w-full h-full"}>
            {
                column?.length === 0 ?
                    <div className={"w-full h-full flex flex-row items-center justify-center"}>
                        <div className={"flex flex-col items-center gap-8"}>
                            <div className={"flex flex-col items-center gap-3"}>
                                <img src={icAddEmpty} alt={'add-empty'} className={"w-12 h-12"}/>
                                <p className={"font-medium w-60 text-center"}>Create Percentage Ketercapaian untuk dapat
                                    menggunakan fitur ini</p>
                            </div>
                            <Button title={'Create Percentage Ketercapaian'} icon={icAddSquare}
                                    onClick={createPercentageBarrier}/>
                        </div>
                    </div>
                    :
                    <div className={'flex flex-col'}>
                        <div className={'flex flex-row justify-between px-6 py-4'}>
                            <div className={'flex flex-row gap-3'}>
                                <Search value={query?.search} onSearch={(search) => onChangeQuery({page: 1, search})}/>
                                <Button title="Import" icon={icFileDown} type="outline"
                                        onClick={showModalImport}/>
                            </div>
                            <Button title="Setting Percentage Ketercapaian" type={'outline'} icon={icAdd}
                                    onClick={createPercentageBarrier}/>
                        </div>
                        <Table
                            className="my-3"
                            data={data?.result?.data}
                            columns={column.concat(ACTION_BUTTON)}
                            totalResult={data?.total_result}
                            totalPage={data?.total_page}
                            currentPage={query?.page}
                            name={'Data Incentive'}
                            onClickItem={onClickItemHandler}
                            onChangePage={(page) => onChangeQuery({page})}
                            loading={isLoading}
                        />
                        {!!show &&
                            <EditIncentiveForm
                                show={show}
                                onHide={onHide}
                                formValue={formValue}
                                onSubmitForm={onSubmitForm}
                                onChangeForm={onChangeForm}
                            />}
                        <Toast
                            show={toast?.isShowToast}
                            message={toast?.message}
                            onClose={() => clearToast()}
                        />
                        <ImportDataPayrollContainer params={type} updateList={getIncentive}/>
                    </div>
            }

        </div>
    )
}
