import { Alert } from "components/commons/alert";
import { PersonalDocumentTab } from "components/employee/employee-detail/employee-detail-tab/PersonalDocumentTab";
import { TABLE_PERSONAL_DOCUMENT } from "configs/employee/configTableAddEmployee";
import { ACTION_TYPE } from "constants/constant";
import { DEFAULT_FORM_PERSONAL_DOCUMENT } from "constants/defaultFormEmployee";
import { useValidation } from "hooks/useValidation";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {setToast, updatePersonalDocument} from "reducers/employeeDetailReducer";
import { API_EMPLOYEE } from "services/apiEmployee";
import { METHOD } from "services/method";
import { useFetch } from "services/useFetch";
import {capitalize, uploadFileToS3} from "utils/utils";


export const PersonalDocumentTabContainer = ({ optionsPersonalDocumentType }) => {
    const dispatch = useDispatch()
    const { id, personal_documents } = useSelector(state => state.employeeDetailReducer)
    const [modal, setModal] = useState({
        form: false,
        delete: false,
        preview: false
    })
    const [dataPersonalDocument, setDataPersonalDocument] = useState(DEFAULT_FORM_PERSONAL_DOCUMENT)
    const [file, setFile] = useState(null)
    const [selectedIndex, setSelectedIndex] = useState()
    const [selectedItem, setSelectedItem] = useState({})
    const [actionType, setActionType] = useState(ACTION_TYPE.CREATE)
    const { fetching: addPersonalDocument, isLoading: isLoadingAdd } = useFetch({ ...API_EMPLOYEE.PERSONAL_DOCUMENT, initPathVariable: id })
    const { fetching: patchPersonalDocument, isLoading: isLoadingPatch } = useFetch({ ...API_EMPLOYEE.PERSONAL_DOCUMENT, initPathVariable: dataPersonalDocument?.id, method: METHOD.PUT })
    const { fetching: deletePersonalDocument, isLoading: isLoadingDelete } = useFetch({ ...API_EMPLOYEE.PERSONAL_DOCUMENT, initPathVariable: dataPersonalDocument?.id, method: METHOD.DELETE })
    const { fetching: getUrlUploadPersonalDocument } = useFetch(API_EMPLOYEE.URL_PERSONAL_DOCUMENT)
    const { isFormValid } = useValidation({ form: { ...dataPersonalDocument, currentFile: file || dataPersonalDocument?.url } })

    const onChangeForm = (e) => {
        const { name, value } = e
        setDataPersonalDocument({ ...dataPersonalDocument, [name]: value })
    }

    const onCloseModal = () => {
        onChangeModal('form', false)
        setDataPersonalDocument(DEFAULT_FORM_PERSONAL_DOCUMENT)
        setActionType(ACTION_TYPE.CREATE)
        setFile(null)
    }

    const onCloseDeleteAndPreviewModal = (key) => {
        onChangeModal(key, false)
        setDataPersonalDocument(DEFAULT_FORM_PERSONAL_DOCUMENT)
    }

    const onSubmit = () => {
        if (!file) return onSave()
        setUploadPersonalDocument()
    }

    const setUploadPersonalDocument = async () => {
        try {
            const s3Urls = await getUrlUploadPersonalDocument({files: [file?.name?.replace(/\s/g, '')]})
            await uploadFileToS3(s3Urls?.data[0], file)
            const urlDocument = `${s3Urls?.data[0]?.url}${s3Urls?.data[0]?.fields?.key}`
            onSave(urlDocument)
        } catch(err) {
            console.log('upload failed')
        }
    }

    const onSave = (url) => {
        const newData = [...personal_documents]
        const paramsDataPersonalDocument = {
            ...dataPersonalDocument,
            type: dataPersonalDocument?.type?.value,
            ...(url && {url: url})
        }

        const onSuccess = (message) => {
            dispatch(updatePersonalDocument(newData))
            dispatch(setToast({isShowToast: true, message}))
            onCloseModal()
        }

        switch (actionType) {
            case ACTION_TYPE.CREATE:
                addPersonalDocument(paramsDataPersonalDocument).then((response) => {
                    newData.push(response.data)
                    onSuccess('Personal Document Created !!')
                })
                break;
            case ACTION_TYPE.EDIT:
                patchPersonalDocument(paramsDataPersonalDocument).then((response) => {
                    newData[selectedIndex] = response.data
                    onSuccess('Personal Document Updated !!')
                })
                break;
            default:
        }
    }

    const handleEdit = () => {
        setActionType(ACTION_TYPE.EDIT)
        onChangeModal('form', true)
    }

    const handleRemove = (index) => {
        deletePersonalDocument().then(() => {
            const newData = [...personal_documents]
            newData.splice(index, 1)
            dispatch(updatePersonalDocument(newData))
            dispatch(setToast({isShowToast: true, message: 'Personal Document Deleted !!'}))
            onCloseDeleteAndPreviewModal('delete')
        })
    }

    const onActionRow = ({ actionType, index, item }) => {
        setSelectedIndex(index)
        setDataPersonalDocument({
            ...item,
            type: { label: capitalize(item.type), value: item.type }
        })
        switch (actionType) {
            case ACTION_TYPE.EDIT:
                return handleEdit();
            case ACTION_TYPE.DELETE:
                return onChangeModal('delete', true)
            case ACTION_TYPE.PREVIEW:
                setSelectedItem(item)
                return onChangeModal('preview', true)
            default:
        }
    }

    const onChangeModal = (key, value) => {
      setModal({...modal, [key]: value})
    }

    const onUploadFile = (e) => {
        const file = e.target.files[0]
        if (!file) return

        const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.pdf)$/i;
        if (!allowedExtensions.exec(file?.name)) {
            e.target.value = null
            return Alert({
                icon: 'error',
                title: 'Failed',
                message: 'Format file tidak sesuai',
            })
        }

        if (file?.size > 21000000) {
            e.target.value = null
            return Alert({
                icon: 'error',
                title: 'Failed',
                message: 'File terlalu besar (Maksimal 20 MB)',
            })
        }

        const reader = new FileReader()
        reader.onload = () => {
            e.target.value = null
            setFile(file)
        }
        reader.readAsDataURL(file)
    }

    const props = {
        configTable: TABLE_PERSONAL_DOCUMENT,
        optionsPersonalDocumentType,
        dataPersonalDocument,
        isFormValid: isFormValid(),
        isEdit: actionType === ACTION_TYPE.EDIT,
        form: personal_documents,
        modal,
        onChangeModal,
        onChangeForm,
        onSubmit,
        onActionRow,
        onCloseDeleteAndPreviewModal,
        onDelete: () => handleRemove(selectedIndex),
        isLoadingSubmit: isLoadingAdd || isLoadingPatch,
        isLoadingDelete,
        onUploadFile,
        file,
        selectedItem
    }

    return (
        <PersonalDocumentTab {...props} />
    )
}
