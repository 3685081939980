import { WorkingExperienceTab } from "components/employee/employee-detail/employee-detail-tab/WorkingExperienceTab";
import { TABLE_WORK_EXPERIENCE } from "configs/employee/configTableAddEmployee";
import { ACTION_TYPE } from "constants/constant";
import { DEFAULT_FORM_WORK_EXPERIENCE } from "constants/defaultFormEmployee";
import { useValidation } from "hooks/useValidation";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setToast, updateWorkExperience } from "reducers/employeeDetailReducer";
import { API_EMPLOYEE } from "services/apiEmployee";
import { METHOD } from "services/method";
import { useFetch } from "services/useFetch";

export const WorkingExperienceTabContainer = () => {
    const dispatch = useDispatch()
    const { id, work_experiences } = useSelector(state => state.employeeDetailReducer)
    const [showModal, setShowModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [dataWorkExperience, setDataWorkExperience] = useState(DEFAULT_FORM_WORK_EXPERIENCE)
    const [selectedIndex, setSelectedIndex] = useState()
    const [actionType, setActionType] = useState(ACTION_TYPE.CREATE)
    const { fetching: addWorkExperience, isLoading: isLoadingAdd } = useFetch({ ...API_EMPLOYEE.WORK_EXPERIENCE, initPathVariable: id })
    const { fetching: patchWorkExperience, isLoading: isLoadingPatch } = useFetch({ ...API_EMPLOYEE.WORK_EXPERIENCE, initPathVariable: dataWorkExperience?.id, method: METHOD.PUT })
    const { fetching: deleteWorkExperience, isLoading: isLoadingDelete } = useFetch({ ...API_EMPLOYEE.WORK_EXPERIENCE, initPathVariable: dataWorkExperience?.id, method: METHOD.DELETE })
    const { isFormValid } = useValidation({ form: dataWorkExperience })

    const onChangeForm = (e) => {
        const { name, value } = e
        setDataWorkExperience({ ...dataWorkExperience, [name]: value })
    }

    const onCloseModal = () => {
        setShowModal(false)
        setDataWorkExperience(DEFAULT_FORM_WORK_EXPERIENCE)
        setActionType(ACTION_TYPE.CREATE)
    }

    const onCloseDeleteModal = () => {
        setShowDeleteModal(false)
        setDataWorkExperience(DEFAULT_FORM_WORK_EXPERIENCE)
    }

    const onSubmit = () => {
        const newData = [...work_experiences]

        const onSuccess = (message) => {
            dispatch(updateWorkExperience(newData))
            dispatch(setToast({isShowToast: true, message}))
            onCloseModal()
        }

        switch (actionType) {
            case ACTION_TYPE.CREATE:
                addWorkExperience(dataWorkExperience).then((response) => {
                    newData.push(response.data)
                    onSuccess('Working Experience Created !!')
                })
                break;
            case ACTION_TYPE.EDIT:
                newData[selectedIndex] = dataWorkExperience
                patchWorkExperience(dataWorkExperience).then((response) => {
                    newData[selectedIndex] = response.data
                    onSuccess('Working Experience Updated !!')
                })
                break;
            default:
        }
    }

    const handleEdit = () => {
        setActionType(ACTION_TYPE.EDIT)
        setShowModal(true)
    }

    const handleRemove = (index) => {
        deleteWorkExperience().then(() => {
            const newData = [...work_experiences]
            newData.splice(index, 1)
            dispatch(updateWorkExperience(newData))
            dispatch(setToast({isShowToast: true, message: 'Working Experience Deleted !!'}))
            onCloseDeleteModal()
        })
    }

    const onActionRow = ({ index, item, actionType }) => {
        setSelectedIndex(index)
        setDataWorkExperience(item)
        switch (actionType) {
            case ACTION_TYPE.EDIT:
                return handleEdit();
            case ACTION_TYPE.DELETE:
                return setShowDeleteModal(true)
            default:
        }
    }

    const props = {
        configTable: TABLE_WORK_EXPERIENCE,
        dataWorkExperience,
        isEdit: actionType === ACTION_TYPE.EDIT,
        isFormValid: isFormValid(),
        form: work_experiences,
        showModal,
        onShowModal: () => setShowModal(true),
        onCloseModal,
        onChangeForm,
        onSubmit,
        onActionRow,
        showDeleteModal,
        onCloseDeleteModal,
        onDelete: () => handleRemove(selectedIndex),
        isLoadingSubmit: isLoadingAdd || isLoadingPatch,
        isLoadingDelete
    }

    return (
        <WorkingExperienceTab {...props} />
    )
}
