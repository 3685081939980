import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    toast: {
        message: '',
        isShowToast: false
    }
}

export const employeeListSlice = createSlice({
    name: 'employeeList',
    initialState: initialState,
    reducers: {
        setFilterModal: (state, action) => action.payload,
        setToast: (state, action) => {
            state.toast = {...state.toast, ...action.payload}
        }
    }
})

export const {setFilterModal, setToast} = employeeListSlice.actions

const employeeListReducer = employeeListSlice.reducer
export default employeeListReducer
