import clsx from "clsx";
import './checkbox.scss';

const Checkbox = ({ className, classNameInput, label, name = "CheckboxItem", value, checked, indeterminate = false, disabled, onChange }) => {
    const handleOnChange = (e) => {
        const { name, value, checked } = e.target
        if (onChange) onChange({ name, value, checked })
    }

    return (
        <label className={clsx("avo__checkbox", className)} htmlFor={`${name}-${label}`}>
            <input
                className={clsx("input-checkbox", indeterminate && "indeterminate", classNameInput)}
                id={`${name}-${label}`}
                name={name}
                type="checkbox"
                checked={checked}
                disabled={disabled}
                value={value}
                onChange={handleOnChange}
            />
            <label className={clsx(disabled && "disabled")} htmlFor={`${name}-${label}`}>{label}</label>
        </label>
    )
}

export default Checkbox
