import {CreateLeave} from "components/general-settings/leave-setting/CreateLeave"
import {useEffect, useState} from "react"
import {useFetch} from "../../../services/useFetch";
import {API_LEAVE_SETTING} from "../../../services/apiLeaveSetting";
import {constructOptionsSelect, findOptionSelected} from "../../../utils/utils";
import {useDispatch, useSelector} from "react-redux";
import {setLeaveSettingDetail, setLeaveSettingToast} from "../../../reducers/formLeaveSettingReducer";
import {useNavigate, useSearchParams} from "react-router-dom";

export const CreateLeaveContainer = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const leaveId = searchParams.get('leaveId')
    const {leaveSettingDetail} = useSelector(state => state.formLeaveSettingReducer)
    const [errorForm, setErrorForm] = useState({})
    const [options, setOptions] = useState({
        employees: [],
        gender: [
            {
                label: 'General',
                value: null
            },
            {
                label: 'Laki - Laki',
                value: 'male'
            },
            {
                label: 'Perempuan',
                value: 'female'
            },
        ]
    })
    const {fetching: getEmployeeList} = useFetch(API_LEAVE_SETTING.EMPLOYEE_LIST)
    const {fetching: createLeave, isLoading: isCreating} = useFetch(API_LEAVE_SETTING.CREATE_LEAVE)
    const {fetching: detailLeave} = useFetch(API_LEAVE_SETTING.DETAIL_LEAVE)
    const {fetching: updateLeave, isLoading: isUpdating} = useFetch(API_LEAVE_SETTING.UPDATE_LEAVE)

    const onChangeForm = (e) => {
        const {name, value, checked} = e
        let newData = {[name]: checked === undefined ? value : checked}
        dispatch(setLeaveSettingDetail({...leaveSettingDetail, ...newData}))
    }

    const setShowToast = (message) => {
        navigate('/general-settings/leave-setting')
        dispatch(setLeaveSettingToast({message, isShowToast: true}))
    }

    const onSearchEmployee = (value) => {
        getEmployeeList({search: value})
            .then(response => setOptions({
                ...options,
                employees: constructOptionsSelect(response?.data, 'full_name', 'id')
            }))
    }

    const constructLeaveSettingDetail = () => {
        const leaveSettingCopy = {...leaveSettingDetail}
        Object.keys(leaveSettingCopy)?.forEach(key => {
            switch (key) {
                case 'is_full_day':
                    leaveSettingCopy[key] = !leaveSettingCopy[key]
                    break
                case 'employee_ids':
                    leaveSettingCopy[key] = leaveSettingCopy[key]?.map(i => i?.value)
                    break
                case 'gender':
                    leaveSettingCopy[key] = leaveSettingCopy[key]?.value ?? null
                    break
                default:
                    return 0
            }
        })

        return leaveSettingCopy
    }

    const validateForm = () => {
        return new Promise((resolve, reject) => {
            const errors = {}
            Object.keys(leaveSettingDetail).forEach(key => {
                if (key === 'name' && !leaveSettingDetail[key]) {
                    errors[key] = 'Nama cuti tidak boleh kosong'
                } else if ((key === 'max_day_per_request' && leaveSettingDetail[key]) && (leaveSettingDetail[key] < leaveSettingDetail['min_day_per_request'])) {
                    errors[key] = 'Maksimal hari per request kurang dari minimal hari per request'
                } else if ((key === 'max_day_per_request' && leaveSettingDetail['quota']) && (leaveSettingDetail[key] > leaveSettingDetail['quota'])) {
                    errors[key] = 'Maksimal hari per request lebih dari kuota cuti'
                }
            })
            setErrorForm(errors)
            if (!Object.keys(errors).length) resolve()
        })
    }

    const onSubmit = () => {
        validateForm()
            .then(() => {
                const payload = constructLeaveSettingDetail()
                if (!leaveId) {
                    createLeave(payload)
                        .then(() => {
                            setShowToast('Leave Created !!')
                        })
                } else {
                    updateLeave(payload, leaveId)
                        .then(() => {
                            setShowToast('Leave Updated !!')
                        })
                }
            })
    }

    useEffect(() => {
        getEmployeeList()
            .then(response => setOptions({
                ...options,
                employees: constructOptionsSelect(response?.data, 'full_name', 'id')
            }))

        if (leaveId) {
            detailLeave({}, leaveId)
                .then(resDetail => {
                    const combineObj = {
                        ...resDetail?.data,
                        gender: findOptionSelected(options?.gender, resDetail?.data?.gender),
                        employee_ids: constructOptionsSelect(resDetail?.data?.employees, 'full_name', 'id'),
                        is_full_day: !resDetail?.data?.is_full_day
                    }
                    const {id, employees, ...dataRest} = combineObj
                    dispatch(setLeaveSettingDetail(dataRest))
                })
        }
    }, [])

    const props = {
        options,
        form: leaveSettingDetail,
        onChangeForm,
        onSearchEmployee,
        onSubmit,
        isCreating,
        isUpdating,
        errorForm
    }

    return (
        <CreateLeave {...props} />
    )
}
