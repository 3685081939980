import Table from "../../commons/table";
import {TABLE_WORK_TIME_SETTING} from "../../../configs/work-settings/configTableWorkTime";
import icAddSquare from "../../../assets/images/id-add-square.svg";
import Button from "../../commons/button";
import {ACTION_TYPE} from "../../../constants/constant";
import DeleteModal from "../../commons/delete-modal";
import Toast from "../../commons/toast";

export const WorkTime = (
    {
        dataList,
        isShowToast,
        toastMessage,
        deleteModal,
        isLoading,
        query,
        isDeleting,
        clearToast,
        onChangeQuery,
        setHideDeleteModal,
        onClickItemHandler,
        onDelete
    }) => {

    return (
        <div className={"flex flex-col"}>
            <div className={"flex justify-end px-6 py-4"}>
                <Button title="Tambah Jam Kerja" icon={icAddSquare} onClick={() => onClickItemHandler({actionType: ACTION_TYPE.CREATE})}/>
            </div>
            <Table
                className="my-3"
                name={TABLE_WORK_TIME_SETTING.name}
                columns={TABLE_WORK_TIME_SETTING.column}
                data={dataList?.result}
                size={query?.limit}
                currentPage={query?.page}
                totalPage={dataList?.total_page}
                totalResult={dataList?.total_data}
                onChangePage={(val) => onChangeQuery({page: val})}
                onClickItem={(val) => onClickItemHandler(val)}
                loading={isLoading}
            />
            <DeleteModal
                show={deleteModal}
                onHide={() => setHideDeleteModal()}
                onDelete={onDelete}
                isLoading={isDeleting}
                text="Apakah anda yakin ingin menghapus data work time?"
            />
            <Toast
                show={isShowToast}
                message={toastMessage}
                onClose={() => clearToast()}
            />
        </div>
    )
}
