import clsx from "clsx"

export const DetailItems = ({className, label, value}) => {
  return (
      <div className={"flex flex-row"}>
          <p className={"w-60 text-neutral-70"}>{label}</p>
          <p className={clsx("w-52 text-neutral-80 font-medium", className)}>{value || '-'}</p>
      </div>
  )
}
