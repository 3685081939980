import icCloseSquare from "assets/images/ic-close-square.svg"
import icFilter from "assets/images/ic-filter-new.svg"
import icRightPrimary from "assets/images/ic-right-primary.svg"
import clsx from "clsx"
import Button from "components/commons/button"
import Checkbox from "components/commons/checkbox"
import Datepicker from "components/commons/datepicker"
import Modal from "components/commons/modal"
import ErrorMessage from "../error-message";

const Filter = (
    {
        show,
        onShow,
        onClose,
        queryParams,
        filterList,
        onSelectFilter,
        activeFilter,
        filter,
        filterCount,
        options,
        onChangeOption,
        onChangeDate,
        onSubmitFilter,
        onClearFilter,
        errorFilter = {}
    }) => {

    const selectedFilterCount = activeFilter?.type !== 'date' && filter?.[queryParams?.[activeFilter?.value]]?.length

    return (
        <>
            <Button
                icon={icFilter}
                type="outline"
                className={clsx(filterCount > 0 && "!px-2.5 !bg-primary-10 !border-primary-50")}
                title={filterCount > 0 ? <div className="h-5 rounded-md bg-primary-50 font-semibold text-white px-1.5">{filterCount}</div> : ''}
                onClick={onShow}
            />
            <Modal
                dialogClassName='w-full max-w-[590px]'
                contentClassName='rounded-xl'
                show={show}
            >
                <div>
                    <div className={"px-6 py-3 flex items-center justify-between border-b"}>
                        <p className={"font-semibold text-lg"}>Filter</p>
                        <Button type="outline" shape="circle" size="small" icon={icCloseSquare} onClick={onClose} />
                    </div>
                    <div className={"grid grid-cols-3 divide-x h-[300px]"}>
                        <div className={'col-span-1 px-1.5 py-3'}>
                            {filterList.map((item, index) => (
                                <div
                                    key={index}
                                    className={`group flex items-center justify-between leading-5 rounded-md px-3 py-2 hover:bg-neutral-10 cursor-pointer transition duration-200 ${item?.value === activeFilter?.value && 'bg-primary-10 text-primary-50'}`}
                                    onClick={() => onSelectFilter(item)}
                                >
                                    <p className={'font-semibold'}>{item.label}</p>
                                    {item?.value === activeFilter?.value && <div className="flex items-center gap-2">
                                        {!!selectedFilterCount && <div className="bg-primary-40 text-white px-1.5 rounded">{selectedFilterCount}</div>}
                                        <img className="w-4 h-4" src={icRightPrimary} alt={'ic-right-primary'} />
                                    </div>}
                                </div>
                            ))}
                        </div>
                        <div className={clsx('col-span-2 px-1.5 py-3', activeFilter?.type !== 'date' && 'overflow-auto')}>
                            <div className={'col px-4 space-y-2'}>
                                {activeFilter?.type === 'date' ?
                                    <>
                                        <div className="flex flex-col items-start gap-3">
                                            <div className={clsx('font-medium w-1/3 text-neutral-70', activeFilter.start_date?.isRequired && 'required')}>{activeFilter.start_date.label}</div>
                                            <Datepicker
                                                name={activeFilter.start_date.name}
                                                className="w-full"
                                                placeholder={activeFilter.start_date.placeholder}
                                                value={filter?.[activeFilter.start_date.name]}
                                                format='YYYY-MM-DD'
                                                maxDate={filter?.[activeFilter.end_date.name]}
                                                onChange={(e) => onChangeDate({ name: activeFilter.start_date.name, value: e })}
                                            />
                                            <ErrorMessage message={errorFilter?.start_date}/>
                                        </div>
                                        <div className="flex flex-col items-start gap-3">
                                            <div className={clsx('font-medium w-1/3 text-neutral-70', activeFilter.end_date?.isRequired && 'required')}>{activeFilter.end_date.label}</div>
                                            <Datepicker
                                                name={activeFilter.end_date.name}
                                                className="w-full"
                                                placeholder={activeFilter.end_date.placeholder}
                                                value={filter?.[activeFilter.end_date.name]}
                                                format='YYYY-MM-DD'
                                                minDate={filter?.[activeFilter.start_date.name]}
                                                onChange={(e) => onChangeDate({ name: activeFilter.end_date.name, value: e })}
                                            />
                                            <ErrorMessage message={errorFilter?.end_date}/>
                                        </div>
                                    </> :
                                    options?.[activeFilter.value]?.map((option, index) => (
                                        <div key={`${option.name}-${option.id}`} className={'py-0.5'}>
                                            <Checkbox
                                                label={option.name}
                                                name={activeFilter.value}
                                                onChange={(e) => onChangeOption(e, index)}
                                                checked={Object.keys(filter).length && filter?.[queryParams[activeFilter.value]]?.includes(String(option.id))}
                                                value={option.id}
                                            />
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </div>
                    <div className={"px-6 py-3 flex flex-row-reverse gap-3 border-t"}>
                        <Button title={'Simpan'} onClick={onSubmitFilter} className={'px-8 py-4'} />
                        <Button type={'outline'} title={'Clear'} className={'px-8 py-4'} onClick={onClearFilter} />
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default Filter
