import { FamilyInformation } from "components/employee/employee-add/FamilyInformation"
import { ACTION_TYPE } from "constants/constant"
import { DEFAULT_FORM_FAMILY, OPTIONS_FAMILY_RELATIONSHIP, OTHER_RELATIONSHIP } from "constants/defaultFormEmployee"
import { useValidation } from "hooks/useValidation"
import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setEmergencyContact, setFamilyInformation } from "reducers/addEmployeeReducer"
import { addFamilyValidation } from "services/addEmployeeValidation"
import { clearPhoneNumber, formatPhoneNumber } from "utils/utils"

export const FamilyInformationContainer = () => {
    const dispatch = useDispatch()
    const dataEmployee = useSelector((state) => state.addEmployeeReducer)
    const [showModal, setShowModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [dataFamily, setDataFamily] = useState(DEFAULT_FORM_FAMILY)
    const [selectedIndex, setSelectedIndex] = useState()
    const [actionType, setActionType] = useState(ACTION_TYPE.CREATE)
    const { errorForm, validateForm, isFormValid } = useValidation({ form: dataFamily, schema: addFamilyValidation })
    const isOtherRelationship = dataFamily?.relationship?.value === OTHER_RELATIONSHIP

    const onChangeForm = (e) => {
        const { name, value, checked } = e
        setDataFamily({
            ...dataFamily,
            [name]: name === 'is_emergency_contact' ? checked : value,
            ...(!isOtherRelationship && { other_relationship: null }),
        })
    }

    const onCloseModal = () => {
        setShowModal(false)
        setDataFamily(DEFAULT_FORM_FAMILY)
        setActionType(ACTION_TYPE.CREATE)
    }

    const addEmergencyContact = (dataNewFamilies) => {
        const emergencyContact = [...dataEmployee.emergency_contacts]
        emergencyContact.push(dataNewFamilies)
        dispatch(setEmergencyContact(emergencyContact))
    }

    const onSubmit = () => {
        validateForm().then(() => {
            const newFamilies = [...dataEmployee.families]
            const dataNewFamilies = {
                ...dataFamily,
                phone_number: formatPhoneNumber(dataFamily.phone_number),
                relationship: dataFamily.relationship.value === OTHER_RELATIONSHIP ? dataFamily.other_relationship : dataFamily.relationship.value,
            }

            switch (actionType) {
                case ACTION_TYPE.CREATE:
                    newFamilies.push(dataNewFamilies)
                    if (dataFamily.is_emergency_contact) addEmergencyContact(dataNewFamilies)
                    break;
                case ACTION_TYPE.EDIT:
                    newFamilies[selectedIndex] = dataNewFamilies
                    break;
                default:
            }
            dispatch(setFamilyInformation(newFamilies))
            onCloseModal()
        })
    }

    const handleEditFamily = (item) => {
        const findRelationship = OPTIONS_FAMILY_RELATIONSHIP.find((find) => find.value === item.relationship)
        setDataFamily({
            ...item,
            phone_number: clearPhoneNumber(item.phone_number),
            relationship: findRelationship || { value: OTHER_RELATIONSHIP, label: OTHER_RELATIONSHIP },
            ...(!findRelationship && { other_relationship: item.relationship }),
        })
        setActionType(ACTION_TYPE.EDIT)
        setShowModal(true)
    }

    const handleRemoveFamily = (index) => {
        const newFamilies = [...dataEmployee.families]
        newFamilies.splice(index, 1)
        dispatch(setFamilyInformation(newFamilies))
        setShowDeleteModal(false)
    }

    const onActionRow = ({ index, item, actionType }) => {
        setSelectedIndex(index)
        switch (actionType) {
            case ACTION_TYPE.EDIT:
                return handleEditFamily(item);
            case ACTION_TYPE.DELETE:
                return setShowDeleteModal(true);
            default:
        }
    }

    const checkValidationForm = () => {
        const checkForm = { ...dataFamily }
        if (!isOtherRelationship) delete checkForm.other_relationship
        return isFormValid(checkForm)
    }

    const props = {
        optionsFamilyRelationship: OPTIONS_FAMILY_RELATIONSHIP,
        dataFamily,
        errorForm,
        isFormValid: checkValidationForm(),
        isEdit: actionType === ACTION_TYPE.EDIT,
        form: dataEmployee.families,
        showModal,
        onShowModal: () => setShowModal(true),
        onCloseModal,
        onChangeForm,
        onSubmit,
        onActionRow,
        showDeleteModal,
        onCloseDeleteModal: () => setShowDeleteModal(false),
        onDelete: () => handleRemoveFamily(selectedIndex)
    }

    return (
        <FamilyInformation {...props} />
    )
}