import icEditPrimary from "assets/images/ic-edit-04-primary.svg"
import icEyeOpen from "assets/images/ic-eye-open.svg"
import icTrashRed from "assets/images/ic-trash-01-red.svg"
import Button from "components/commons/button"
import { ACTION_TYPE } from "constants/constant"

export const TABLE_LEAVE_SETTING = {
    name: 'Total Leave',
    column: [
        {
            name: "Nama Cuti",
            key: "name",
            className: "font-medium !text-neutral-80",
            classNameHeader: "lg:w-[340px]",
        },
        {
            name: "Kuota",
            key: "quota",
            className: "font-medium !text-neutral-80",
        },
        {
            name: "Berlaku 1/2 Hari",
            key: "is_full_day",
            className: "font-medium !text-neutral-80",
            render: ({ value }) => !value ? 'Ya' : 'Tidak',
        },
        {
            name: "Maximum Request",
            key: "max_request",
            className: "font-medium !text-neutral-80",
            render: ({ value }) => value ? `${value} Kali` : '-',
        },
        {
            name: "Request Pada Tahun Berbeda",
            key: "is_different_year_allowed",
            className: "font-medium !text-neutral-80",
            render: ({ value }) => value ? 'Ya' : 'Tidak',
        },
        {
            name: "Action",
            key: "action",
            classNameHeader: "!text-center w-[150px]",
            render: ({ item, onClickItem }) => (
                <div className="flex justify-center gap-3 mx-auto">
                    <Button className="w-[80px]" type="outline" icon={icEyeOpen} title="Preview" onClick={() => onClickItem({ id: item?.id, actionType: ACTION_TYPE.PREVIEW })} />
                    <Button type="outline" icon={icEditPrimary} onClick={() => onClickItem({ id: item?.id, actionType: ACTION_TYPE.EDIT })} />
                    <Button type="danger" icon={icTrashRed} onClick={() => onClickItem({ id: item?.id, actionType: ACTION_TYPE.DELETE })} />
                </div>
            )
        }
    ]
}
