import {createSlice} from "@reduxjs/toolkit";

const INITIAL_STATE = {
    activatedCompany: null
}

export const companiesSlice = createSlice({
    name: 'companies',
    initialState: INITIAL_STATE,
    reducers: {
        setActivatedCompany: (state, action) => {
            state.activatedCompany = action.payload
        }
    }
})

export const {setActivatedCompany} = companiesSlice.actions
const companiesReducer = companiesSlice.reducer

export default companiesReducer
