import clsx from "clsx";
import './tooltip.scss';

const Tooltip = ({className, position= "top", content, text = "Tooltip Example", isShow = true}) => {
    return (
        <div className={clsx("avo__tooltip", !isShow && 'hide', position, className)} data-title={text}>
            {content}
        </div>
    )
}

export default Tooltip
