import {useEffect, useState} from "react";
import {ValidationShiftingSchedule} from "../../../components/request-shifting/shifting-import/ValidationShiftingSchedule";
import {useFetch} from "../../../services/useFetch";
import {API_SHIFTING_IMPORT} from "../../../services/apiShiftingImport";

export const ValidationShiftingScheduleContainer = ({payload, onChangeStep, setViewData}) => {
    const [percentage, setPercentage] = useState(0)
    const {fetching: importValidation, data: response, isLoading} = useFetch(API_SHIFTING_IMPORT.IMPORT_VALIDATION)

    useEffect(() => {
        if (percentage === 10) {
            importValidation(payload, null, {'Content-Type': 'multipart/form-data'})
                .then().catch(() => {
                onChangeStep(1)
            })
        }
    }, [percentage])

    useEffect(() => {
        let timeout = null
        if (percentage <= 80) {
            timeout = setTimeout(() => {
                setPercentage(percentage + 10)
            }, 1000)
        } else if (!isLoading && response?.data) {
            timeout = setTimeout(() => {
                if (percentage === 100) {
                    setViewData(response?.data)
                    onChangeStep(3)
                } else {
                    setPercentage(percentage + 10)
                }
            }, 1000)
        }

        return () => {
            clearTimeout(timeout)
        }
    }, [percentage, isLoading])

    return (
        <ValidationShiftingSchedule percentage={percentage}/>
    )
}
