import Badge from "../../components/commons/badge";
import {formatDateMoment} from "../../utils/utils";

export const TABLE_IMPORT_CONTRACT_EMPLOYEE = {
    column: [
        {
            name: "Name",
            key: "full_name",
            className: "font-semibold",
            width: 250
        },
        {
            name: "NIP",
            key: "nip",
            width: 250
        },
        {
            name: "No Kontrak",
            key: "number",
            width: 250
        },
        {
            name: "Tanggal Awal",
            key: "start_date",
            width: 250,
            render: ({value}) => <div>{value ? formatDateMoment(value, 'D MMMM YYYY') : '-'}</div>
        },
        {
            name: "Tanggal Berakhir",
            key: "end_date",
            width: 250,
            render: ({value}) => <div>{value ? formatDateMoment(value, 'D MMMM YYYY') : '-'}</div>
        },
        {
            name: "Status",
            key: "status",
            width: 250,
            render: ({value}) => <Badge title={value} type={value}/>
        }
    ]
}
