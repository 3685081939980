import {PayrollList} from "../../../components/payroll/payroll-list/PayrollList";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {convertSpaceToDash, getAllQueryParams, getMonthsId, setQueryParams} from "../../../utils/utils";
import {INITIAL_DATA_QUERY} from "../../../configs/payroll/configTablePayrollList";
import {useEffect, useState} from "react";
import {useFetch} from "../../../services/useFetch";
import {API_PAYROLL_LIST} from "../../../services/apiPayrollList";
import moment from "moment/moment";
import {ACTION_TYPE, STEPS_CREATE_PAYROLL} from "../../../constants/constant";
import {useDispatch, useSelector} from "react-redux";
import {setPayrollToast} from "../../../reducers/payrollReducer";

const getMonths = () => {
    return Object.values(getMonthsId).map((value, index) => {
        return {label: value, value: index + 1}
    })
}

const getYears = () => {
    const START_YEAR = moment().get('year') + 1
    const END_YEAR = 2024
    const years = [];
    for (let year = START_YEAR; year >= END_YEAR; year--) {
        years.push({
            label: year,
            value: year,
        })
    }
    return years;
}

const OPTIONS_FILTER = {
    month: [{label: 'Semua Bulan', value: null}, ...getMonths()],
    year: [{label: 'Semua Tahun', value: null}, ...getYears()]
}

export const PayrollListContainer = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const {toast} = useSelector(state => state.payrollReducer)
    const [searchParams, setSearchParams] = useSearchParams()
    const [deleteId, setDeleteId] = useState(null)
    const [showDeleteModal, setDeleteModal] = useState(false)
    const optionsFilter = OPTIONS_FILTER
    const query = searchParams.size > 0 ? getAllQueryParams(searchParams) : INITIAL_DATA_QUERY
    const {data: payrollList, fetching: getPayrollList, isLoading} = useFetch(API_PAYROLL_LIST.PAYROLL_LIST)
    const {fetching: deletePayroll, isDeleting} = useFetch(API_PAYROLL_LIST.DELETE_PAYROLL)

    useEffect(() => {
        fetchPayrollList()
    }, [location?.search])

    const onChangeQuery = (queryParams) => {
        const params = setQueryParams({...query, ...queryParams})
        setSearchParams(params, {replace: true})
    }

    const clearToast = () => {
        dispatch(setPayrollToast({isShowToast: false, message: ''}))
    }

    const onHideDeleteModal = () => {
        setDeleteModal(false)
        setDeleteId(null)
    }

    const fetchPayrollList = () => {
        getPayrollList({...query})
    }

    const onClickItemHandler = ({item, id, actionType}) => {
        if (actionType === ACTION_TYPE.DETAIL) {
            const currentStep = item?.last_step ? item?.last_step - 1 : 0
            const monthName = convertSpaceToDash(item?.month_year)
            if (item?.status === 'drafted') return navigate(`/payroll/create-payroll/${STEPS_CREATE_PAYROLL[currentStep]}-${monthName}?payrollId=${item?.id}`)
            return navigate(`/payroll/payroll-list/payroll-detail-${monthName}?payrollId=${item?.id}`)
        } else {
            setDeleteId(id)
            setDeleteModal(true)
        }
    }

    const onDelete = () => {
        deletePayroll({}, deleteId)
            .then(() => {
                dispatch(setPayrollToast({isShowToast: true, message: 'Payroll Deleted !!'}))
                setDeleteModal(false)
                if (payrollList?.data?.result?.length === 1 && Number(query?.page) !== 1) {
                    onChangeQuery({page: 1})
                } else {
                    fetchPayrollList()
                }
            })
    }

    const props = {
        dataList: payrollList?.data,
        isLoading,
        query,
        navigate,
        optionsFilter,
        toast,
        showDeleteModal,
        isDeleting,
        onDelete,
        onHideDeleteModal,
        clearToast,
        onChangeQuery,
        onClickItemHandler
    }

    return (
        <PayrollList {...props}/>
    )
}
