import {METHOD} from "./method";

export const API_JOB_UNIT = {
    LIST_JOB_UNIT: {
        api: '/v1/hr-adm/job-unit/list',
        method: METHOD.GET
    },
    LIST_COMPANY: {
        api: '/v1/hr-adm/job-unit/companies',
        method: METHOD.GET
    },
    LIST_OPTION_JOB_UNIT: {
        api: '/v1/hr-adm/job-unit',
        method: METHOD.GET
    },
    CREATE_JOB_UNIT: {
        api: '/v1/hr-adm/job-unit',
        method: METHOD.POST
    },
    UPDATE_JOB_UNIT: {
        api: '/v1/hr-adm/job-unit',
        method: METHOD.PUT
    },
    DELETE_JOB_UNIT: {
        api: '/v1/hr-adm/job-unit',
        method: METHOD.DELETE
    },
    EXPORT_JOB_UNIT: {
        api: '/v1/hr-adm/job-unit/export',
        method: METHOD.GET
    }
}
