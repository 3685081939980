import {METHOD} from "./method";

export const API_ACTIVITY = {
    ACTIVITY_LIST: {
        api: '/v1/hr-adm/activity-setting',
        method: METHOD.GET
    },
    ACTIVITY_APPROVER_LIST: {
        api: '/v1/hr-adm/activity-setting/approver',
        method: METHOD.GET
    },
    UPDATE_ACTIVITY_APPROVAL: {
        api: '/v1/hr-adm/activity-setting',
        method: METHOD.PUT
    }
}
