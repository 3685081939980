import {EmployeeList} from "../../../components/payroll/create-payroll/EmployeeList";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {constructOptionsSelect, downloadFile, getAllQueryParams, getPathUrl, setQueryParams} from "../../../utils/utils";
import {useEffect, useState} from "react";
import {setPayrollModalImport, setPayrollToast} from "../../../reducers/payrollReducer";
import {INITIAL_DATA_QUERY_EMPLOYEE_LIST, TABLE_CREATE_PAYROLL_EMPLOYEE_LIST} from "../../../configs/payroll/configTableCreatePayroll";
import {useFetch} from "../../../services/useFetch";
import {API_CREATE_PAYROLL} from "../../../services/apiCreatePayroll";
import moment from "moment/moment";
import {STEPS_CREATE_PAYROLL} from "../../../constants/constant";

const INIT_COLUMN_INCENTIVE = {
    name: null,
    key: null,
    classNameHeader: "!min-w-[200px]",
    render: ({value}) => <div>{`${value}%`}</div>
}

export const EmployeeListContainer = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const monthName = getPathUrl(location, '-', -2).join('-')
    const {toast} = useSelector(state => state.payrollReducer)
    const [searchParams, setSearchParams] = useSearchParams()
    const payrollId = searchParams.get('payrollId')
    const query = searchParams.size > 0 ? getAllQueryParams(searchParams) : INITIAL_DATA_QUERY_EMPLOYEE_LIST
    const [modalExport, setModalExport] = useState(false)
    const [optionsFilter, setOptionsFilter] = useState([])
    const {data: employeeList, fetching: getEmployeeList, isLoading} = useFetch({...API_CREATE_PAYROLL.EMPLOYEE_LIST, initPathVariable: payrollId})
    const {fetching: departmentList} = useFetch(API_CREATE_PAYROLL.DEPARTMENT_LIST)
    const {fetching: exportEmployeeList} = useFetch({...API_CREATE_PAYROLL.EXPORT_EMPLOYEE_LIST, initPathVariable: payrollId})
    const {fetching: updateLastStep, isUpdatingStep} = useFetch({...API_CREATE_PAYROLL.UPDATE_LAST_STEP, initPathVariable: payrollId})

    useEffect(() => {
        fetchEmployeeList()
    }, [location?.search])

    useEffect(() => {
        departmentList()
            .then(response => {
                const list = constructOptionsSelect([{
                    name: 'Semua Department',
                    id: null
                }, ...response?.data], 'name', 'id')
                setOptionsFilter(list)
            })
    }, [])

    const onChangeQuery = (queryParams) => {
        const params = setQueryParams({...query, ...queryParams})
        setSearchParams(params, {replace: true})
    }

    const clearToast = () => {
        dispatch(setPayrollToast({isShowToast: false, message: ''}))
    }

    const fetchEmployeeList = () => {
        const {payrollId, ...queryParams} = query
        getEmployeeList(queryParams)
    }

    const onExport = () => {
        const {payrollId, page, limit, ...payload} = {...query}
        exportEmployeeList(payload, null, {}, 'blob')
            .then(response => {
                downloadFile(null, response, `employee-list-${payrollId}-Export-${moment().format('DD-MM-YYYY')}.xlsx`)
                dispatch(setPayrollToast({isShowToast: true, message: 'Employee List Exported'}))
                setModalExport(false)
            })
    }

    const onSubmit = () => {
        updateLastStep({last_step: 3})
            .then(() => navigate(`/payroll/create-payroll/${STEPS_CREATE_PAYROLL[2]}-${monthName}?payrollId=${payrollId}`))
    }

    const onShowModalImport = () => {
        dispatch(setPayrollModalImport(true))
    }

    const getColumnTable = () => {
        const column = [...TABLE_CREATE_PAYROLL_EMPLOYEE_LIST.column]
        employeeList?.data?.result?.custom_column_incentives?.forEach(item => column.push({
            ...INIT_COLUMN_INCENTIVE,
            ...item
        }))
        return column
    }

    const props = {
        dataList: employeeList?.data,
        columnTable: getColumnTable(),
        isLoading,
        isUpdatingStep,
        toast,
        query,
        optionsFilter,
        modalExport,
        setModalExport,
        onShowModalImport,
        onExport,
        clearToast,
        onChangeQuery,
        fetchEmployeeList,
        onSubmit
    }

    return (
        <EmployeeList {...props}/>
    )
}
