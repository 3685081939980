import {METHOD} from "./method";

export const API_REMOTE_WORKING = {
    REMOTE_WORKING_LIST: {
        api: '/v1/hr-adm/remote-working/list',
        method: METHOD.POST
    },
    PREVIEW_REMOTE_WORKING: {
        api: '/v1/hr-adm/remote-working/preview',
        method: METHOD.GET
    },
    EXPORT_REMOTE_WORKING_LIST: {
        api: '/v1/hr-adm/remote-working/export',
        method: METHOD.POST
    },
    APPROVE_REMOTE_WORKING: {
        api: '/v1/hr-adm/remote-working/approve',
        method: METHOD.POST
    },
    REJECT_REMOTE_WORKING: {
        api: '/v1/hr-adm/remote-working/reject',
        method: METHOD.POST
    },
}
