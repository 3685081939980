import { OvertimeLocation } from "components/general-settings/overtime-setting/OvertimeLocation"
import { TABLE_OVERTIME_LOCATION } from "configs/general-settings/configTableOvertimeSetting"
import { ACTION_TYPE } from "constants/constant"
import {useEffect, useState} from "react"
import {useLocation, useSearchParams} from "react-router-dom"
import { getAllQueryParams, setQueryParams } from "utils/utils"
import {useFetch} from "../../../services/useFetch";
import {API_OVERTIME_SETTING} from "../../../services/apiOvertimeSetting";

const INITIAL_STATE = {
    name: null
}

export const OvertimeLocationContainer = () => {
    const location = useLocation()
    const [searchParams, setSearchParams] = useSearchParams()
    const defaultQuery = {
        page: 1,
        limit: 20
    }
    const query = searchParams.size > 0 ? getAllQueryParams(searchParams) : defaultQuery
    const [form, setForm] = useState(INITIAL_STATE)
    const [actionType, setActionType] = useState(ACTION_TYPE.CREATE)
    const [isShowToast, setIsShowToast] = useState(false)
    const [toastMessage, setToastMessage] = useState('')
    const [deleteId, setDeleteId] = useState(null)
    const [modal, setModal] = useState({
        form: false,
        delete: false,
    })
    const {data: overtimeLocationList, fetching: getOvertimeLocationList, isLoading} = useFetch(API_OVERTIME_SETTING.OVERTIME_LOCATION_LIST)
    const {fetching: createOvertimeLocation, isLoading: isCreating} = useFetch(API_OVERTIME_SETTING.CREATE_OVERTIME_LOCATION)
    const {fetching: updateOvertimeLocation, isLoading: isUpdating} = useFetch(API_OVERTIME_SETTING.UPDATE_OVERTIME_LOCATION)
    const {fetching: deleteOvertimeLocation, isLoading: isDeleting} = useFetch(API_OVERTIME_SETTING.DELETE_OVERTIME_LOCATION)

    useEffect(() => {
        fetchOvertimeLocationList({...query})
    }, [location.search])

    const onChangeQuery = (queryParams) => {
        const params = setQueryParams({ ...query, ...queryParams })
        setSearchParams(params, { replace: true })
    }

    const clearToast = () => {
        setIsShowToast(false)
        setToastMessage('')
    }

    const setShowToast = (message, itemModal) => {
        setModal({ ...modal, ...itemModal })
        setIsShowToast(true)
        setToastMessage(message)
    }

    const fetchOvertimeLocationList = (item = query) => {
        getOvertimeLocationList(item)
    }

    const onChangeForm = (e) => {
        const { name, value } = e
        setForm({ ...form, [name]: value })
    }

    const onDelete = () => {
        deleteOvertimeLocation({}, deleteId)
            .then(() => {
                setShowToast('Overtime Location Deleted !!', {delete: false})
                if (getOvertimeLocationList?.data?.result?.length === 1 && Number(query?.page) !== 1) {
                    onChangeQuery({page: 1})
                } else {
                    fetchOvertimeLocationList()
                }
            })
    }

    const onActionRow = ({ item, actionType }) => {
        switch (actionType) {
            case ACTION_TYPE.CREATE:
                setActionType(ACTION_TYPE.CREATE)
                setForm({...INITIAL_STATE})
                setModal({...modal, form: true})
                break
            case ACTION_TYPE.EDIT:
                setActionType(ACTION_TYPE.EDIT)
                setForm({...item})
                setModal({...modal, form: true})
                break
            default:
                setDeleteId(item?.id)
                setModal({ ...modal, delete: true })
        }
    }

    const onSubmitForm = () => {
        const {id, ...payload} = form
        if (actionType === ACTION_TYPE.CREATE) {
            createOvertimeLocation(payload)
                .then(() => {
                    setShowToast('Overtime Location Created !!', {form: false})
                    fetchOvertimeLocationList()
                })
        } else {
            updateOvertimeLocation(payload, id)
                .then(() => {
                    setShowToast('Overtime Location Updated !!', {form: false})
                    fetchOvertimeLocationList()
                })
        }
    }

    const props = {
        configTable: TABLE_OVERTIME_LOCATION,
        dataOvertimeLocation: overtimeLocationList?.data,
        query,
        form,
        modal,
        actionType,
        isShowToast,
        toastMessage,
        clearToast,
        onChangePage: (page) => onChangeQuery({ page }),
        onActionRow,
        onDelete,
        onSetModal: (e) => setModal({ ...modal, ...e }),
        onChangeForm,
        onSubmitForm,
        isLoadingTable: isLoading,
        isCreating,
        isUpdating,
        isDeleting,
    }

    return (
        <OvertimeLocation {...props} />
    )
}
