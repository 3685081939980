import {AdditionalIncentive} from "../../components/additional-incentive/AdditionalIncentive";
import {useEffect, useState} from "react";
import {useLocation, useSearchParams} from "react-router-dom";
import {downloadFile, getAllQueryParams, setQueryParams} from "../../utils/utils";
import {useFetch} from "../../services/useFetch";
import {API_ADDITIONAL_INCENTIVE} from "../../services/apiAdditionalIncentive";
import moment from "moment";
import {Alert} from "../../components/commons/alert";

const tabList = [
    {id: 'submitted', name: 'Submitted'},
    {id: 'approved', name: 'Approved'},
    {id: 'rejected', name: 'Rejected'},
    {id: 'cancelled', name: 'Cancelled'}
]

const previewTab = [
    {id: 'information', name: 'Informasi'},
    {id: 'history', name: 'History'},
]

const OPTION_BULK = ['approved', 'rejected']

export const AdditionalIncentiveContainer = () => {
    const location = useLocation()
    const [searchParams, setSearchParams] = useSearchParams();
    const status = searchParams.get('status')
    const [toastMessage, setToastMessage] = useState('')
    const [selectedIds, setSelectedIds] = useState()
    const [rejectReason, setRejectReason] = useState(null)
    const [modal, setModal] = useState({
        limit: false,
        export: false,
        toast: false,
        preview: false
    })
    const defaultQuery = {
        page: 1,
        search: '',
        limit: 20,
        status: tabList[0].id,
        start_date: moment().startOf('month').format('YYYY-MM-DD'),
        end_date: moment().endOf('month').format('YYYY-MM-DD'),
        order_by: "created_at",
        order_type: "desc"
    }
    const [activePreviewTab, setActivePreviewTab] = useState(previewTab[0]?.id)
    const [selectedStatus, setSelectedStatus] = useState()
    const query = searchParams.size > 0 ? getAllQueryParams(searchParams) : defaultQuery
    const { data: additionalIncentiveList, fetching: getAdditionalIncentiveList, isLoading } = useFetch(API_ADDITIONAL_INCENTIVE.ADDITIONAL_INCENTIVE_LIST, defaultQuery)
    const { data: previewAdditionalIncentive, fetching: getPreviewAdditionalIncentive} = useFetch(API_ADDITIONAL_INCENTIVE.PREVIEW_ADDITIONAL_INCENTIVE)
    const {fetching: exportAdditionalIncentive} = useFetch(API_ADDITIONAL_INCENTIVE.EXPORT_ADDITIONAL_INCENTIVE_LIST)
    const {fetching: approveAdditionalIncentive} = useFetch(API_ADDITIONAL_INCENTIVE.APPROVE_ADDITIONAL_INCENTIVE)
    const {fetching: rejectAdditionalIncentive} = useFetch(API_ADDITIONAL_INCENTIVE.REJECT_ADDITIONAL_INCENTIVE)

    useEffect(() => {
        fetchAdditionalIncentiveList({...query})
    }, [location.search])

    const onChangeTab = (status) => {
        const params = { ...defaultQuery, status: status.id }
        setSearchParams(params, { replace: true })
    }

    const onChangeQuery = (queryParams) => {
        const params = setQueryParams({...query, ...queryParams})
        setSearchParams(params, {replace: true})
    }

    const fetchAdditionalIncentiveList = (item = query) => {
        getAdditionalIncentiveList(item)
    }

    const onExportHandler = () => {
        const combineObj = query?.start_date ? query : {...defaultQuery, ...query}
        const {page, limit, ...dataFilterRest} = combineObj
        const payload = {...dataFilterRest, additional_incentive_request_ids: selectedIds}
        exportAdditionalIncentive(payload, '', {}, 'blob')
            .then(response => {
                downloadFile(null, response, `Additional-Incentive-Export-${moment().format('DD-MM-YYYY')}.xlsx`)
                setToastMessage("Anda berhasil export data additional incentive")
                setModal({...modal, export: false, toast: true})
            })
    }

    const onSelectHandler = (item) => {
        let ids = item?.map(i => i?.id)
        setSelectedIds(ids)
    }

    const onClickItemHandler = (item) => {
        getPreviewAdditionalIncentive({}, item?.id)
        setActivePreviewTab(previewTab[0].id)
        setModal({ ...modal, preview: true })
    }

    const onBulkUpdateHandler = (option) => {
        if (selectedIds.length === 0 && !modal?.preview) {
            return Alert({
                icon: 'warning',
                title: 'Silakan pilih employee terlebih dahulu.',
                autoClose: true,
            })
        }
        setSelectedStatus(option)
        setModal({ ...modal, bulk_update: true })
    }

    const getPromiseAPI = (type, bodyRequest) => {
        if (type === OPTION_BULK[0]) return approveAdditionalIncentive(bodyRequest)
        return rejectAdditionalIncentive(bodyRequest)
    }

    const errorMessage = (data = []) => {
        let message = ''
        data?.forEach(item => {
            message += `\nSomething happen in ID ${item?.additional_incentive_request_id}:\nStatus: ${item?.success ? 'Success' : 'Failed'}\nMessage: ${item?.message}\n`
        })

        return message
    }

    const onUpdateStatusRequest = (status, id = null) => {
        const bodyRequest = {
            additional_incentive_request_ids: id ? [id] : selectedIds
        }
        if (status === OPTION_BULK[1]) bodyRequest['reject_reason'] = rejectReason

        getPromiseAPI(status, bodyRequest)
            .then(() => {
                setToastMessage(`Additional Incentive ${status === OPTION_BULK?.APPROVE ? 'Approved !!' : 'Rejected !!'}`)
                setModal({...modal, bulk_update: false, preview: false, toast: true})
                fetchAdditionalIncentiveList()
            }).catch(err => {
            if (err?.code === '400-T002') {
                Alert({
                    icon: 'error',
                    title: err?.message,
                    message: errorMessage(err?.data),
                })
            }
            setModal({ ...modal, bulk_update: false, preview: false})
        })
    }

    const props = {
        tabList,
        status: status || tabList[0].id,
        optionBulk: OPTION_BULK,
        employeeData: additionalIncentiveList?.data,
        employeeDetail: previewAdditionalIncentive?.data,
        previewTab,
        activePreviewTab,
        setActivePreviewTab,
        query,
        modal,
        selectedIds,
        selectedStatus,
        isLoading,
        toastMessage,
        rejectReason,
        setRejectReason,
        onChangeTab,
        onChangeQuery,
        onExportHandler,
        onSelectHandler,
        onClickItemHandler,
        onBulkUpdateHandler,
        onUpdateStatusRequest,
        setModalVisibility: (value) => setModal({...modal, ...value}),
    }

    return (
        <AdditionalIncentive {...props}/>
    )
}
