import {useEffect, useState} from "react";
import {useFetch} from "../../../../services/useFetch";
import {API_INCENTIVE} from "../../../../services/apiIncentive";
import {ValidationData} from "../../../../components/payroll/incentive/import/ValidationData";

export const ValidationDataContainer = ({params, payload, onChangeStep}) => {
    const [percentage, setPercentage] = useState(0)
    const [isComplete, setIsComplete] = useState(false)
    const [isValid, setIsValid] = useState(false)
    const api = `${API_INCENTIVE.IMPORT.api}/${params}/import/validate`
    const {data: validationResult, fetching: importValidation} = useFetch({...API_INCENTIVE.IMPORT, api})

    useEffect(() => {
        if (percentage === 10) {
            importValidation(payload, null, {'Content-Type': 'multipart/form-data'})
                .then((res) => {
                    setIsValid(res?.data?.stats?.total_invalid === 0)
                })
                .catch(() => {
                    onChangeStep(1)
                })
        }
    }, [percentage])

    useEffect(() => {
        let timeout = null
        if (percentage <= 80) {
            timeout = setTimeout(() => {
                setPercentage(percentage + 10)
            }, 1000)
        } else { //else if !isLoading && response?.data
            timeout = setTimeout(() => {
                if (percentage === 100) {
                    setIsComplete(true)
                } else {
                    setPercentage(percentage + 10)
                }
            }, 1000)
        }

        return () => {
            clearTimeout(timeout)
        }
    }, [percentage]) //isLoading

    const onImportData = () => {
        onChangeStep(3)
    }

    const props = {
        params,
        percentage,
        isComplete,
        onChangeStep,
        isValid,
        validationResult: validationResult?.data?.stats,
        onImportData
    }

    return (
        <ValidationData {...props}/>
    )
}
