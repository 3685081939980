import { AdditionalInformation } from "components/employee/employee-add/AdditionalInformation"
import { SELECTED_ADDITIONAL_INFORMATION } from "constants/defaultFormEmployee"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setPersonalDetail } from "reducers/addEmployeeReducer"
import { API } from "services/api"
import { useBiteship } from "services/useBiteship"

export const AdditionalInformationContainer = ({ options, selectedAdditional }) => {
    const dispatch = useDispatch()
    const [selected, setSelected] = useState(SELECTED_ADDITIONAL_INFORMATION)
    const dataEmployee = useSelector((state) => state.addEmployeeReducer)
    const [optionsMainArea, setOptionsMainArea] = useState([])
    const [optionsResidenceArea, setOptionsResidenceArea] = useState([])
    const [optionsMainPostalCode, setOptionsMainPostalCode] = useState([])
    const [optionsResidencePostalCode, setOptionsResidencePostalCode] = useState([])
    const { fetching: searchArea } = useBiteship(API.BITESHIP_SEARCH_AREA)

    useEffect(() => {
        setSelected({ ...selected, ...selectedAdditional })
        const { main, residence } = selectedAdditional
        if (main?.label) onSearchArea('main', main?.label, main?.label, residence?.label === main?.label)
        if (residence?.label && residence?.label !== main?.label) onSearchArea('residence', residence?.label, residence?.label)
    }, [selectedAdditional])

    const onChangeForm = (e) => {
        const { name, value, checked } = e
        switch (name) {
            case 'gender':
            case 'religion':
            case 'marital_status':
            case 'blood_type':
                dispatch(setPersonalDetail({ ...e, value: String(value.value) }))
                setSelected({ ...selected, [name]: value })
                break;
            case 'same_as_address':
                dispatch(setPersonalDetail({ ...e, value: checked }))
                setSelected({
                    ...selected,
                    [name]: checked,
                    residence: checked ? selected.main : {},
                    residence_postal_code: checked ? selected.id_card_postal_code : {},
                })
                break;
            case 'main':
            case 'residence':
                dispatch(setPersonalDetail({ ...e, value: value.label, same_as_address: selected.same_as_address }))
                setSelected({
                    ...selected,
                    [name]: value,
                    ...(name === 'main' ? { id_card_postal_code: {} } : { residence_postal_code: {} }),
                    ...(selected.same_as_address && { residence: value, residence_postal_code: {} }),
                })
                searchPostalCode(name, value.value)
                break;
            case 'id_card_postal_code':
            case 'residence_postal_code':
                dispatch(setPersonalDetail({ ...e, value: String(value.value), same_as_address: selected.same_as_address }))
                setSelected({
                    ...selected,
                    [name]: value,
                    ...(selected.same_as_address && { residence_postal_code: value }),
                })
                break;
            case 'id_card_address':
                dispatch(setPersonalDetail({ ...e, same_as_address: selected.same_as_address }))
                break;
            default:
                dispatch(setPersonalDetail(e))
                break;
        }
    }

    const onSearchArea = (name, keyword, areaLabel, isSameAs) => {
        const payload = {
            countries: 'ID',
            input: keyword,
            type: 'double'
        }

        searchArea(payload).then((item) => {
            const area = item.areas.map((item) => ({
                label: item.name,
                value: item.id
            }))
            name === 'main' ? setOptionsMainArea([...area]) : setOptionsResidenceArea([...area])
            if (isSameAs) setOptionsResidenceArea([...area])
            if (areaLabel) {
                const findArea = area.find((item) => item.label === areaLabel)
                searchPostalCode(name, findArea.value)
            }
        })
    }

    const searchPostalCode = (name, areaId) => {
        searchArea({}, areaId).then((item) => {
            const postalCode = item.areas.map((item) => ({
                label: item.postal_code,
                value: item.postal_code
            }))
            if (name === 'main') {
                setOptionsMainPostalCode([...postalCode])
                if (selectedAdditional?.main?.label === selectedAdditional?.residence?.label) setOptionsResidencePostalCode([...postalCode])
            } else {
                setOptionsResidencePostalCode([...postalCode])
            }
        })
    }

    const props = {
        optionsMainArea,
        optionsResidenceArea,
        optionsMainPostalCode,
        optionsResidencePostalCode,
        options,
        selected,
        form: dataEmployee,
        onChangeForm,
        onSearchArea,
    }

    return (
        <AdditionalInformation {...props} />
    )
}