import { METHOD } from "./method";

export const API_EMPLOYEE_IMPORT = {
    DOWNLOAD_TEMPLATE: {
        api: '/v1/hr-adm/employee/import/download-template',
        method: METHOD.GET
    },
    IMPORT_VALIDATION: {
        api: '/v1/hr-adm/employee/import/validate',
        method: METHOD.POST
    },
    IMPORT: {
        api: '/v1/hr-adm/employee/import',
        method: METHOD.POST
    },
}
