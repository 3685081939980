import {ImportGrade} from "../../../../components/organization-structure-management/grade-setting/import-grade/ImportGrade";
import Button from "../../../../components/commons/button";
import icCloseSquare from "../../../../assets/images/ic-close-square.svg";
import Modal from "../../../../components/commons/modal";
import {useState} from "react";
import {STEPS_IMPORT_GRADE} from "../../../../constants/constant";
import {UploadFileContainer} from "./UploadFileContainer";
import {ValidationGradeContainer} from "./ValidationGradeContainer";
import {ImportDataContainer} from "./ImportDataContainer";

export const ImportGradeContainer = (
    {
        show,
        onHide,
        fetchList,
        setShowToast
    }) => {

    const [activeStep, setActiveStep] = useState(1)
    const [payload, setPayload] = useState({file: {}})
    const [isProgressing, setIsProgressing] = useState(false)
    const [isErrorValidation, setIsErrorValidation] = useState(false)

    const setPayloadData = (payload) => {
        setPayload(payload)
    }

    const setProgressing = (value) => {
        setIsProgressing(value)
    }

    const onChangeStep = (step) => {
        setActiveStep(step)
    }

    const setErrorValidation = (value) => {
        setIsErrorValidation(value)
    }

    const setToFirstStep = () => {
        setActiveStep(1)
        setIsErrorValidation(false)
    }

    const onHideHandler = () => {
        if (activeStep === 3) {
            setShowToast('Import Data Successfully !!', {import: false})
            fetchList()
        }
        setActiveStep(1)
        setPayload({file: {}})
        onHide()
    }

    const stepContent = [
        <UploadFileContainer
            onChangeStep={onChangeStep}
            setPayloadData={setPayloadData}
            setProgressing={setProgressing}
        />,
        <ValidationGradeContainer
            payload={payload}
            onChangeStep={onChangeStep}
            setProgressing={setProgressing}
            setErrorValidation={setErrorValidation}
        />,
        <ImportDataContainer
            payload={payload}
            onChangeStep={onChangeStep}
            setProgressing={setProgressing}
            onHide={onHideHandler}
        />
    ]

    return (
        <Modal
            dialogClassName="w-[70%]"
            contentClassName="rounded-xl"
            show={show}
            onHide={() => {}}
        >
            <div className={"px-6 py-3 flex flex-row items-center justify-between border-b"}>
                <p className={"font-semibold text-md"}>Import Data</p>
                <Button type="outline" shape="circle" size="small" icon={icCloseSquare} disabled={isProgressing} onClick={!isProgressing ? onHideHandler : () => {}}/>
            </div>
            <ImportGrade
                steps={STEPS_IMPORT_GRADE}
                activeStep={activeStep}
                stepContent={stepContent}
                isErrorValidation={isErrorValidation}
                setToFirstStep={setToFirstStep}
            />
        </Modal>
    )
}
