import Table from "../../commons/table";
import { TABLE_ATTENDANCE_LIST } from "../../../configs/attendance/configTableAttendanceList";

export const AttendanceList = ({ attendanceList, query, onSelectItem, onChangePage, isLoading }) => {

	return (
		<Table
			className="my-3"
			name={TABLE_ATTENDANCE_LIST.name}
			data={attendanceList.result}
			columns={TABLE_ATTENDANCE_LIST.column}
			size={query?.limit}
			totalPage={attendanceList.total_page}
			currentPage={query?.page}
			totalResult={attendanceList.total_data}
			onChangePage={onChangePage}
			onSelectItem={onSelectItem}
			loading={isLoading}
		/>
	)
}
