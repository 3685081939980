import clsx from "clsx";
import icChevDownGrey from "./ic-chev-down-grey.svg";
import icCalendar02 from "./ic-calendar-02.svg";
import Button from "../button";
import './date-range-picker.scss'
import {formatDateMoment} from "../../../utils/utils";
import {useEffect, useState} from "react";
import moment from "moment";
import icLeft from "assets/images/ic-left.svg";
import icRight from "assets/images/ic-right.svg";
import icCloseRoundFill from "assets/images/ic-close-round-fill.svg";
import icDoubleLeft from "./ic-double-left.svg";
import icDoubleRight from "./ic-double-right.svg";
import {useClickOutside} from "../../../hooks/useClickOutside";

const DAYS = [
    'Min',
    'Sen',
    'Sel',
    'Rab',
    'Kam',
    'Jum',
    'Sab',
]

const MONTHS = [
    'Januari',
    'Februari',
    'Maret',
    'April',
    'Mei',
    'Juni',
    'Juli',
    'Agustus',
    'September',
    'Oktober',
    'November',
    'Desember'
]

const DateRangePicker = (
    {
        className,
        name = 'avo-date-range-picker',
        format,
        onChange,
        value = [],
        placeholder = "Select Date Range",
        minDate = null,
        maxDate = null,
        minRange = null,
        maxRange = null,
        autoSelectRange = 0
    }) => {
    const defaultDateLeft = moment(value[0]).subtract(1, 'months')
    const defaultDateRight = moment(value[0])
    const END_YEAR = 1970
    const [modal, setModal] = useState(false)
    const [currentDate, setCurrentDate] = useState([defaultDateLeft, defaultDateRight])
    const [dates, setDates] = useState([])
    const [selectedDate, setSelectedDate] = useState([])
    const [dateHover, setDateHover] = useState()
    const minDateRange = moment(selectedDate[0]).add(minRange, 'day')

    const onClickButtonModal = (value) => {
        setModal(value)
    }

    const isToday = (item) => {
        return moment(item).isSame(moment(), 'day')
    }

    const onSelected = (item) => {
        if (moment(item).isSame(selectedDate[0]) && moment(item).isSame(selectedDate[1])) {
            return 'selected-start-end'
        } else if ((selectedDate[1] || minRange) && moment(item).isSame(selectedDate[0])) {
            return 'selected-start'
        } else if (selectedDate[1] && moment(item).isSame(selectedDate[1])) {
            return 'selected-end'
        } else if (moment(item).isBetween(selectedDate[0], selectedDate[1] || minDateRange)) {
            return 'selected-between'
        } else if (moment(item).isSame(selectedDate[0])) {
            return moment(item).isBefore(dateHover) ? 'selected-start' : 'selected-end'
        }
    }

    const onHoverDate = (item) => {
        if (selectedDate[0] && !selectedDate[1])
            return moment(item).isBetween(selectedDate[0], moment(dateHover).add(1, 'day')) ||
                (!minRange && moment(item).isBetween(moment(dateHover).subtract(1, 'day'), selectedDate[0]))
    }

    const onMouseOver = (item) => {
        if (selectedDate[0]) setDateHover(item)
    }

    const isInactiveDate = (date, datePosition) => {
        if (datePosition === 'left') return MONTHS[moment(date).get('month')] !== getMonth()[0]
        return MONTHS[moment(date).get('month')] !== getMonth()[1]
    }

    const isDisableAutoRange = (date) => {
        return moment(maxDate).diff(date, 'days') + 1 < autoSelectRange
    }

    const isDisabledMaxRange = (date) => {
        const maxDateRange = moment(selectedDate[0]).add(maxRange, 'day')
        if (selectedDate[1]) {
            return false
        } else if (minRange) {
            return moment(date).isSameOrAfter(maxDateRange)
        } else {
            return moment(date).isSameOrAfter(maxDateRange) || moment(date).isSameOrBefore(moment(selectedDate[0]).subtract(maxRange, 'day'))
        }
    }

    const isDisabledMinRange = (date) => {
        if (selectedDate[0] && !selectedDate[1]) return moment(date).isBetween(moment(selectedDate[0]).subtract(1, 'day'), moment(minDateRange).subtract(1, 'day'))
        return moment(maxDate).diff(date, 'days') + 1 < minRange
    }

    const isDisabledDateButton = (item) => {
        return moment(item).isBefore(minDate || `${END_YEAR}-01-01`) ||
            moment(item).isAfter(maxDate || `${moment().get('year')}-12-31`) ||
            autoSelectRange && isDisableAutoRange(item) ||
            minRange && isDisabledMinRange(item) || maxRange && isDisabledMaxRange(item)
    }

    const isDisabledArrowMonthButton = (direction) => {
        if (direction === 'left') return getMonth()[0] === MONTHS[0] && getYear()[0] === END_YEAR
        return getMonth()[1] === MONTHS[11] && getYear()[1] === moment(maxDate || defaultDateRight).get('year')
    }

    const isDisabledArrowYearButton = (direction) => {
        if (direction === 'left') return getYear()[0] === END_YEAR
        return getYear()[1] === moment(maxDate || defaultDateRight).get('year')
    }

    const onClickArrow = (type) => {
        const typeSplit = type?.split('-')
        const newDates = []
        currentDate.map(item => {
            if (typeSplit[0] === 'next') newDates.push(moment(item).add(1, typeSplit[1]))
            if (typeSplit[0] === 'previous') newDates.push(moment(item).subtract(1, typeSplit[1]))
        })

        setCurrentDate(newDates)
    }

    const onChangeSelected = (value) => {
        if (onChange) onChange(value || [selectedDate[0].format(format), selectedDate[1].format(format)])
        onClickButtonModal(false)
    }

    const onClickDate = (item) => {
        if (autoSelectRange) {
            selectedDate[0] = item
            selectedDate[1] = moment(item).add(autoSelectRange - 1, 'day')
            onChangeSelected()
        } else if ((selectedDate[0] && selectedDate[1]) || (minRange && moment(item).isBefore(selectedDate[0], 'day'))) {
            selectedDate[0] = item
            selectedDate[1] = null
        } else if (!selectedDate[0]) {
            selectedDate[0] = item
        } else if (selectedDate[0] && moment(item).isSameOrAfter(selectedDate[0], 'day')) {
            selectedDate[1] = item
            onChangeSelected()
        } else if (selectedDate[0] && moment(item).isBefore(selectedDate[0], 'day')) {
            selectedDate[1] = selectedDate[0]
            selectedDate[0] = item
            onChangeSelected()
        } else {
            selectedDate[0] = item
        }

        setSelectedDate([...selectedDate])

        if (!selectedDate[1] && moment(item).isBefore(currentDate[0])) {
            setCurrentDate([moment(item), moment(item).add(1, 'months')])
        } else if (!selectedDate[1] && moment(item).isAfter(currentDate[1])) {
            setCurrentDate([moment(item).subtract(1, 'month'), moment(item)])
        }
    }

    const onClearValue = (e) => {
        if (e.stopPropagation) e.stopPropagation()
        setCurrentDate([moment().subtract(1, 'months'), moment()])
        onChangeSelected([])
    }

    const getMonth = () => {
        const currentMonth = []
        currentDate.map(item => {
            currentMonth.push(MONTHS[moment(item).get('month')])
        })

        return currentMonth
    }

    const getYear = () => {
        const currentYear = []
        currentDate.map(item => {
            currentYear.push(moment(item).get('year'))
        })

        return currentYear
    }

    useEffect(() => {
        const initialListDate = []

        currentDate.map(item => {
            const dates = []
            const startDay = moment(item).startOf("month").startOf("week")
            const endDay = moment(item).endOf("month").endOf("week")
            const firstDay = startDay.clone().subtract(1, "day")

            while (firstDay.isBefore(endDay, "day")) {
                dates.push(firstDay.add(1, "day").clone())
            }

            initialListDate.push(dates)
        })

        setDates(initialListDate)
    }, [currentDate])

    useEffect(() => {
        const countDateRange = moment(value[1]).diff(moment(value[0]), 'days') + 1
        if ((minRange || maxRange) && (countDateRange < minRange || countDateRange > maxRange)) return setSelectedDate([null, null])
        if (value) return setSelectedDate([value[0] ? moment(value[0]) : null, value[1] ? moment(value[1]) : null])
    }, [value])

    useClickOutside({
        callback: () => onClickButtonModal(false),
        elementID: `date-range-picker-${name}`
    })

    return (
        <div id={`date-range-picker-${name}`} className={clsx('avo__date__range__picker', className)}>
            <div className="button-modal">
                <Button
                    className="button-value"
                    type="outline"
                    title={
                        value[0] && value[1] ?
                            <>
                                {`${formatDateMoment(value[0], 'DD MMM YYYY')} - ${formatDateMoment(value[1], 'DD MMM YYYY')}`}
                                <img src={icCloseRoundFill} alt="button-clear" onClick={onClearValue}/>
                            </>
                            :
                            placeholder
                    }
                    icon={icCalendar02}
                    iconAfter={icChevDownGrey}
                    onClick={() => onClickButtonModal(!modal)}
                />
                <div className={clsx('modal', modal ? 'show' : 'hide')}>
                    <div className="wrapper-dates left">
                        <div className="wrapper-top-content">
                            <div className="arrow-button">
                                <Button
                                    type="text"
                                    icon={icDoubleLeft}
                                    disabled={isDisabledArrowYearButton('left')}
                                    onClick={() => onClickArrow('previous-year')}
                                />
                                <Button
                                    className="first-arrow-left"
                                    type="text"
                                    icon={icLeft}
                                    disabled={isDisabledArrowMonthButton('left')}
                                    onClick={() => onClickArrow('previous-month')}
                                />
                            </div>
                            <div>{`${getMonth()[0]} ${getYear()[0]}`}</div>
                            <div/>
                        </div>
                        <div className="dates">
                            {
                                DAYS.map((item, index) => (
                                    <div key={index} className="item days">{item}</div>
                                ))
                            }
                            {
                                dates[0]?.map((item, index) => (
                                    <button
                                        className={clsx('item',
                                            isToday(item) && 'today',
                                            !isInactiveDate(item, 'left') && onSelected(item),
                                            !isInactiveDate(item, 'left') && onHoverDate(item) && 'date-hover',
                                            isInactiveDate(item, 'left') && 'inactive',
                                            isDisabledDateButton(item) && 'disabled')
                                        }
                                        key={index}
                                        disabled={isDisabledDateButton(item)}
                                        onClick={() => onClickDate(item)}
                                        onMouseOver={() => !isDisabledDateButton(item) && onMouseOver(item)}
                                    >
                                        {formatDateMoment(item, "D")}
                                    </button>
                                ))
                            }
                        </div>
                    </div>
                    <div className="wrapper-dates right">
                        <div className="wrapper-top-content">
                            <div/>
                            <div>{`${getMonth()[1]} ${getYear()[1]}`}</div>
                            <div className="arrow-button">
                                <Button
                                    className="first-arrow-right"
                                    type="text"
                                    icon={icRight}
                                    disabled={isDisabledArrowMonthButton('right')}
                                    onClick={() => onClickArrow('next-month')}
                                />
                                <Button
                                    type="text"
                                    icon={icDoubleRight}
                                    disabled={isDisabledArrowYearButton('right')}
                                    onClick={() => onClickArrow('next-year')}
                                />
                            </div>
                        </div>
                        <div className="dates">
                            {
                                DAYS.map((item, index) => (
                                    <div key={index} className="item">{item}</div>
                                ))
                            }
                            {
                                dates[1]?.map((item, index) => (
                                    <button
                                        className={clsx('item',
                                            isToday(item) && 'today',
                                            !isInactiveDate(item, 'right') && onSelected(item),
                                            !isInactiveDate(item, 'right') && onHoverDate(item) && 'date-hover',
                                            isInactiveDate(item, 'right') && 'inactive',
                                            isDisabledDateButton(item) && 'disabled')}
                                        key={index}
                                        disabled={isDisabledDateButton(item)}
                                        onClick={() => onClickDate(item)}
                                        onMouseOver={() => !isDisabledDateButton(item) && onMouseOver(item)}
                                    >
                                        {formatDateMoment(item, "D")}
                                    </button>
                                ))
                            }
                        </div>
                    </div>
                    <div className="caption-min-max-range">
                        {minRange && <p>Minimum Range: {minRange} Days</p>}
                        {minRange && maxRange && <span className="caption-boundary">||</span>}
                        {maxRange && <p>Maximum Range: {maxRange} Days</p>}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DateRangePicker
