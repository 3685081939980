import {useState} from "react";
import Button from "../../../commons/button";
import Table from "../../../commons/table";
import {TABLE_IMPORT_CONTRACT_EMPLOYEE} from "../../../../configs/contract/configTableImportContract";
import icUploadWhite from "../../../../assets/images/ic-upload-white.svg";
import icChevronLeftWhite from "../../../../assets/images/ic-chevron-left-white.svg";
import icError from "../../../../assets/images/ic-error.svg";
import icUploadGrey from "../../../../assets/images/ic-upload-grey.svg";

export const ViewData = (
    {
        isValidated,
        dataImport,
        setToPreviousStep,
        showErrorDetail,
        onSubmitImport
    }) => {
    const [currentPage, setCurrentPage] = useState(1)

    return (
        <div className={"flex flex-col space-y-6 px-40"}>
            <div>
                <Button className={"mb-6"} title="Back" icon={icChevronLeftWhite} onClick={() => setToPreviousStep()}/>
                <div className={"flex justify-between items-center"}>
                    <p className={"font-medium text-md text-neutral-80"}> Total Data
                        <span className={"font-bold text-base text-primary-50 ml-2"}>
                            {dataImport?.statistics?.total}
                        </span>
                    </p>
                    <Button type="outline" title="Error Detail" icon={icError} disabled={isValidated}
                            onClick={showErrorDetail}/>
                </div>
                <Table
                    name=""
                    data={dataImport?.contracts?.slice((5 * (currentPage - 1)), currentPage * 5)}
                    columns={TABLE_IMPORT_CONTRACT_EMPLOYEE.column}
                    size={5}
                    totalPage={Math.ceil(dataImport?.statistics?.total / 5)}
                    totalResult=" "
                    onChangePage={(e) => setCurrentPage(e)}
                />
            </div>
            <div className={"flex justify-end"}>
                <Button
                    title="Import Data"
                    icon={isValidated ? icUploadWhite : icUploadGrey}
                    onClick={onSubmitImport}
                    disabled={!isValidated}
                />
            </div>
        </div>
    )
}
