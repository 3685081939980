import Button from "../../components/commons/button";
import icEdit from "../../assets/images/ic-edit-04.svg";
import {ACTION_TYPE} from "../../constants/constant";
import icTrashRed from "../../assets/images/ic-trash-01-red.svg";

export const TABLE_WORK_UNIT_SETTING = (name) => {
    return ({
        name,
        column: [
            {
                name: `Nama ${name}`,
                key: "name",
                className: 'w-1/5',
            }, {
                name: `Nama Head Unit`,
                key: `${name === 'management' ? 'company_name' : 'head_unit_name'}`,
                className: 'w-2/5',
            }, {
                name: "Kode",
                key: "code",
            }, {
                name: "Edit",
                className: 'w-1/6',
                key: 'action',
                render: ({onClickItem, item}) => (
                    <div className="flex space-x-3">
                        <Button icon={icEdit} type="outline" title={'Edit'} onClick={() => onClickItem(ACTION_TYPE.EDIT, item)}/>
                        <Button icon={icTrashRed} type="outline" onClick={() => onClickItem(ACTION_TYPE.DELETE, item)}/>
                    </div>
                )
            }
        ]
    })
}
