import {METHOD} from "./method";

export const API_APPROVAL = {
    APPROVAL_LIST: {
        api: '/v1/hr-adm/approval-setting/list',
        method: METHOD.POST
    },
    APPROVER_LIST: {
        api: '/v1/hr-adm/approval-setting/approver',
        method: METHOD.GET
    },
    UPDATE_APPROVAL: {
        api: '/v1/hr-adm/approval-setting',
        method: METHOD.POST
    }
}
