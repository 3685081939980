import Button from "../../commons/button";
import icAddSquare from "../../../assets/images/id-add-square.svg";
import Table from "../../commons/table";
import {TABLE_PAYROLL_LIST} from "../../../configs/payroll/configTablePayrollList";
import icFilter from "../../../assets/images/ic-filter-new.svg";
import Select from "../../commons/select";
import {findOptionSelected} from "../../../utils/utils";
import Toast from "../../commons/toast";
import DeleteModal from "../../commons/delete-modal";

export const PayrollList = (
    {
        dataList,
        isLoading,
        query,
        navigate,
        optionsFilter,
        toast,
        showDeleteModal,
        isDeleting,
        onDelete,
        onHideDeleteModal,
        clearToast,
        onChangeQuery,
        onClickItemHandler
    }) => {

    return (
        <div className={"flex flex-col"}>
            <div className={"flex justify-between px-6 py-4"}>
                <div className={"flex gap-3"}>
                    <div className={"flex items-center gap-2"}>
                        <img src={icFilter} alt="ic-filter"/>
                        <p className="text-neutral-70">Filter by Month:</p>
                        <Select
                            name="month"
                            className="w-[200px]"
                            optionsClassName="max-h-40"
                            placeholder="Pilih Bulan"
                            options={optionsFilter?.month}
                            value={findOptionSelected(optionsFilter?.month, Number(query?.month)) || optionsFilter?.month[0]}
                            onChange={(item) => onChangeQuery({page: 1, [item?.name]: item?.value?.value})}
                        />
                    </div>
                    <div className={"flex items-center gap-2"}>
                        <img src={icFilter} alt="ic-filter"/>
                        <p className="text-neutral-70">Filter by Year:</p>
                        <Select
                            name="year"
                            className="w-[200px]"
                            optionsClassName="max-h-40"
                            placeholder="Pilih Tahun"
                            options={optionsFilter?.year}
                            value={findOptionSelected(optionsFilter?.year, Number(query?.year)) || optionsFilter?.year[0]}
                            onChange={(item) => onChangeQuery({page: 1, [item?.name]: item?.value?.value})}
                        />
                    </div>
                </div>
                <Button title="Create Payroll" icon={icAddSquare} onClick={() => navigate('/payroll/create-payroll')}/>
            </div>
            <Table
                className="my-3"
                name={TABLE_PAYROLL_LIST.name}
                columns={TABLE_PAYROLL_LIST.column}
                data={dataList?.result}
                size={query?.limit}
                currentPage={query?.page}
                totalPage={dataList?.total_page}
                totalResult={dataList?.total_result}
                onChangePage={(val) => onChangeQuery({page: val})}
                onClickItem={(val) => onClickItemHandler(val)}
                loading={isLoading}
            />
            <DeleteModal
                show={showDeleteModal}
                onHide={onHideDeleteModal}
                onDelete={onDelete}
                isLoading={isDeleting}
                text="Apakah anda yakin ingin menghapus data payroll?"
            />
            <Toast
                show={toast?.isShowToast}
                message={toast?.message}
                onClose={() => clearToast()}
            />
        </div>
    )
}
