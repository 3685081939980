import {useState} from "react";
import {UploadFileContainer} from "./UploadFileContainer";
import {ImportDataPayroll} from "../../../../components/payroll/create-payroll/import-data-payroll/ImportDataPayroll";
import {STEPS_IMPORT_DATA_PAYROLL} from "../../../../constants/constant";
import Modal from "../../../../components/commons/modal";
import {useDispatch, useSelector} from "react-redux";
import {setPayrollModalImport, setPayrollToast} from "../../../../reducers/payrollReducer";
import Button from "../../../../components/commons/button";
import icCloseSquare from "../../../../assets/images/ic-close-square.svg";
import {ValidationDataContainer} from "./ValidationDataContainer";
import {ImportDataContainer} from "./ImportDataContainer";
import {useSearchParams} from "react-router-dom";

export const ImportDataPayrollContainer = ({params, fetchList}) => {
    const dispatch = useDispatch()
    const {isShowModalImport} = useSelector(state => state.payrollReducer)
    const [activeStep, setActiveStep] = useState(1)
    const [payload, setPayload] = useState({file: {}})
    const [isProgressing, setIsProgressing] = useState(false)
    const [isErrorValidation, setIsErrorValidation] = useState(false)
    const [searchParams] = useSearchParams()
    const payrollId = searchParams.get('payrollId')

    const setPayloadData = (payload) => {
        setPayload(payload)
    }

    const setProgressing = (value) => {
        setIsProgressing(value)
    }

    const onChangeStep = (step) => {
        setActiveStep(step)
    }

    const setErrorValidation = (value) => {
        setIsErrorValidation(value)
    }

    const setToFirstStep = () => {
        setActiveStep(1)
        setIsErrorValidation(false)
    }

    const onHide = () => {
        if (activeStep === 3) {
            dispatch(setPayrollToast({isShowToast: true, message: 'Berhasil Import Data'}))
            fetchList()
        }
        dispatch(setPayrollModalImport(false))
        setActiveStep(1)
        setPayload({file: {}})
    }

    const stepContent = [
        <UploadFileContainer
            params={params}
            payrollId={payrollId}
            onChangeStep={onChangeStep}
            setPayloadData={setPayloadData}
            setProgressing={setProgressing}
        />,
        <ValidationDataContainer
            params={params}
            payrollId={payrollId}
            payload={payload}
            onChangeStep={onChangeStep}
            setProgressing={setProgressing}
            setErrorValidation={setErrorValidation}
        />,
        <ImportDataContainer
            params={params}
            payrollId={payrollId}
            payload={payload}
            onChangeStep={onChangeStep}
            setProgressing={setProgressing}
            onHide={onHide}
        />
    ]

    return (
        <Modal
            dialogClassName="w-[70%]"
            contentClassName="rounded-xl"
            show={isShowModalImport}
            onHide={() => {}}
        >
            <div className={"px-6 py-3 flex flex-row items-center justify-between border-b"}>
                <p className={"font-semibold text-md"}>Import Data</p>
                <Button type="outline" shape="circle" size="small" icon={icCloseSquare} disabled={isProgressing} onClick={!isProgressing ? onHide : () => {}}/>
            </div>
            <ImportDataPayroll
                steps={STEPS_IMPORT_DATA_PAYROLL}
                activeStep={activeStep}
                stepContent={stepContent}
                isErrorValidation={isErrorValidation}
                setToFirstStep={setToFirstStep}
            />
        </Modal>
    )
}
