import icEyeOpen from "assets/images/ic-eye-open.svg"
import icUserDefault from "assets/images/ic-user-profile.svg"
import Badge from "components/commons/badge"
import Button from "components/commons/button"
import {formatDateMoment, getStatusIcon} from "../../utils/utils";

export const TABLE_REQUEST_OVERTIME = {
    name: 'Overtime Request List',
    column: [
        {
            name: "Name",
            key: "full_name",
            className: "font-medium",
            render: ({ item }) => {
                return (
                    <div className={'flex flex-row gap-3 items-center'}>
                        <img className={'w-7 h-7 rounded-full border border-neutral-50'} src={item?.profile_picture || icUserDefault} alt={item?.full_name} />
                        <div>{item?.full_name}</div>
                    </div>
                )
            }
        },
        {
            name: "Tanggal Lembur",
            key: "overtime_date",
            render: ({value}) => <div>{formatDateMoment(value, 'D MMMM YYYY')}</div>
        },
        {
            name: "Durasi Lembur",
            key: "duration",
            render: ({value}) => <div>{Number(value).toFixed(2).replace(/\.00$/, "")}</div>
        },
        {
            name: "Tipe Benefit",
            key: "benefit",
        },
        {
            name: "Approval 1",
            key: "approver_1",
            sortable: true,
            render: ({ value }) => value ? <Badge title={
                <div className={'flex items-center space-x-1'}>
                    <img src={getStatusIcon(value?.toLowerCase())} alt="ic-status"/>
                    <p>{value}</p>
                </div>
            } type={value?.toLowerCase()} /> : ''
        },
        {
            name: "Approval 2",
            key: "approver_2",
            sortable: true,
            render: ({ value }) => value ? <Badge title={
                <div className={'flex items-center space-x-1'}>
                    <img src={getStatusIcon(value?.toLowerCase())} alt="ic-status"/>
                    <p>{value}</p>
                </div>
            } type={value?.toLowerCase()} /> : ''
        },
        {
            name: "Approval 3",
            key: "approver_3",
            sortable: true,
            render: ({ value }) => value ? <Badge title={
                <div className={'flex items-center space-x-1'}>
                    <img src={getStatusIcon(value?.toLowerCase())} alt="ic-status"/>
                    <p>{value}</p>
                </div>
            } type={value?.toLowerCase()} /> : ''
        },
        {
            name: "Action",
            key: "action",
            classNameHeader: "!text-center w-[150px]",
            render: ({ item, onClickItem }) => (
                <div className="flex justify-center">
                    <Button className="w-[80px]" type="outline" icon={icEyeOpen} title="Preview" onClick={() => onClickItem(item)} />
                </div>
            )
        }
    ]
}
