import Menu from "components/commons/menu"
import {Header} from "components/header"
import {MENU} from "constants/constant"
import {useLocation, useNavigate} from "react-router-dom"
import {useEffect, useState} from "react";
import {useFetch} from "../../services/useFetch";
import {API} from "../../services/api";
import {FooterSidebar} from "./FooterSidebar";
import {HeaderSidebar} from "./HeaderSidebar";
import {useDispatch, useSelector} from "react-redux";
import {setActivatedCompany} from "../../reducers/companiesReducer";
import CONFIG from "configs/env";

export const Layout = ({children}) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const [sidebarList, setSidebarList] = useState([])
    const [companies, setCompanies] = useState([])
    const [profile, setProfile] = useState()
    const [modules, setModules] = useState([])
    const {activatedCompany} = useSelector(state => state.companiesReducer)
    const {fetching: switchCompany} = useFetch(API.SWITCH_COMPANY, {})
    const {fetching: getMenuApi} = useFetch(API.LIST_MENU)

    useEffect(() => {
        handleGetMenu()
    }, [])

    const handleGetMenu = () => {
        getMenuApi()
            .then(resp => {
                let menu = resp?.data?.menus
                let displayMenu = []
                MENU.map(item => {
                    Object.keys(menu).map(i => {
                        if (i === item?.key) displayMenu.push(item)
                    })
                })

                if (!activatedCompany) {
                    dispatch(setActivatedCompany(resp?.data?.companies.find(company => company.selected === true)))
                }
                setSidebarList(displayMenu)
                setModules(resp?.data?.modules)
                setCompanies(resp?.data?.companies)
                setProfile(resp?.data?.profile)
            })
            .catch(err => console.log("err"))
    }

    const onNavigate = (e) => {
        navigate(e)
    }

    const onClickLogout = () => {
        window.location.href = `${CONFIG.API_ENDPOINT}${API.LOGOUT.api}`
    }

    const onSwitchCompany = (company) => {
        switchCompany({}, company?.id)
            .then(() => {
                dispatch(setActivatedCompany(company))
                handleGetMenu()
                navigate('/', {replace: true})
            })
            .catch(err => console.log("err"))
    }

    return (
        <div className="flex">
            <div className={'min-w-0 w-[200px]'}>
                <Menu
                    className="min-h-screen sticky top-0 z-20"
                    items={sidebarList}
                    selectedKey={location.pathname.substring(1)}
                    onClick={onNavigate}
                    contentHeader={<HeaderSidebar companies={companies} activeCompany={activatedCompany}
                                                  onSwitchCompany={onSwitchCompany}/>}
                    contentFooter={<FooterSidebar modules={modules}/>}
                />
            </div>
            <div className={'flex-1 overflow-y-auto'}>
                <Header
                    userProfile={profile}
                    breadcrumbPath={location.pathname}
                    onNavigate={onNavigate}
                    onClickLogout={onClickLogout}
                />
                {children}
            </div>
        </div>
    )
}
