import FormModal from "../../commons/form-modal";
import Input from "../../commons/input";
import Datepicker from "../../commons/datepicker";
import moment from "moment";

export const DayOffListForm = (
    {
        title,
        show,
        onHide,
        dayOffDetail = {},
        onChangeValue,
        isFormValid,
        isCreating,
        isUpdating,
        onSubmitForm
    }) => {

    return (
        <FormModal
            title={title}
            show={show}
            width={'max-w-[639px]'}
            onHide={onHide}
            isLoading={isCreating || isUpdating}
            isDisabled={!isFormValid()}
            onSubmitForm={onSubmitForm}
        >
            <div className={"flex flex-col space-y-6 px-6 py-8"}>
                <div className={"flex items-center text-neutral-70"}>
                    <div className={"w-1/3 required"}>Pilih Tanggal</div>
                    <Datepicker
                        className="flex-1"
                        value={dayOffDetail?.date}
                        format='YYYY-MM-DD'
                        minDate={moment()}
                        maxDate={`${moment().get('year') + 2}-12-31`}
                        onChange={(e) => onChangeValue(e, 'date')}
                    />
                </div>
                <div className={"flex items-center text-neutral-70"}>
                    <div className={"w-1/3 required"}>Keterangan Hari Libur</div>
                    <Input className="flex-1" name="description" placeholder="Isi Keterangan" value={dayOffDetail?.description}
                           onChange={(e) => onChangeValue(e)}/>
                </div>
            </div>
        </FormModal>
    )
}
