import clsx from "clsx";
import './progress-bar.scss'

const ProgressBar = ({className, classNameBar, percentage = 0}) => {
    return (
        <div className={clsx('avo__progress__bar', className)}>
            <div className={clsx('bar-percentage', classNameBar)} style={{width: `${percentage}%`}}/>
        </div>
    )
}

export default ProgressBar
