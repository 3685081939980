import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    id: null,
    status: null,
    employee_id_number: null,
    contract_activated_at: null,
    contract_job_title: null,
    personal_detail: {
        profile_picture: null,
        full_name: null,
        phone_number: null,
        id_card_number: null,
        username: null,
        family_id_card_number: null,
        passport_number: null,
        personal_email: null,
        office_email: null,
    },
    bank_detail: {
        bank_code: null,
        bank_name: null,
        bank_account_number: null,
        bank_account_name: null,
        bank_image: null,
        is_bank_verified: null,
    },
    job_title_employees: [],
    additional_info: {
        gender: null,
        birthplace: null,
        birthdate: null,
        height: null,
        weight: null,
        religion: null,
        marital_status: null,
        hobby: null,
        blood_type: null,
        residence_province: null,
        residence_city: null,
        residence_district: null,
        residence_postal_code: null,
        residence_address: null,
        id_card_province: null,
        id_card_city: null,
        id_card_district: null,
        id_card_postal_code: null,
        id_card_address: null,
    },
    card_detail: {
        tax_id_number: null,
        bpjsk_number: null,
        bpjstk_number: null,
        mandiri_inhealth_number: null,
    },
    families: [],
    emergency_contacts: [],
    educations: [],
    work_experiences: [],
    personal_documents: [],
    toast: {
        message: '',
        isShowToast: false
    }
}

export const employeeDetailSlice = createSlice({
    name: 'employee-detail',
    initialState: initialState,
    reducers: {
        setEmployeeDetail: (state, action) => action.payload,
        updatePersonalDetail: (state, action) => ({
            ...state,
            personal_detail: action.payload
        }),
        updateAdditionalInfo: (state, action) => ({
            ...state,
            additional_info: action.payload
        }),
        updateBankDetail: (state, action) => ({
            ...state,
            bank_detail: action.payload
        }),
        updateNPWPInformation: (state, action) => ({
            ...state,
            card_detail: action.payload
        }),
        updateJobTitle: (state, action) => ({
            ...state,
            job_title_employees: [...action.payload]
        }),
        updateFamilyInformation: (state, action) => ({
            ...state,
            families: [...action.payload]
        }),
        updateEmergencyContact: (state, action) => ({
            ...state,
            emergency_contacts: [...action.payload]
        }),
        updateEducationInformation: (state, action) => ({
            ...state,
            educations: [...action.payload]
        }),
        updateWorkExperience: (state, action) => ({
            ...state,
            work_experiences: [...action.payload]
        }),
        updatePersonalDocument: (state, action) => ({
            ...state,
            personal_documents: [...action.payload]
        }),
        setToast: (state, action) => {
            state.toast = {...state.toast, ...action.payload}
        }
    },
})

export const {
    setEmployeeDetail,
    updatePersonalDetail,
    updateAdditionalInfo,
    updateBankDetail,
    updateNPWPInformation,
    updateJobTitle,
    updateFamilyInformation,
    updateEmergencyContact,
    updateEducationInformation,
    updateWorkExperience,
    updatePersonalDocument,
    setToast
} = employeeDetailSlice.actions

const employeeDetailReducer = employeeDetailSlice.reducer
export default employeeDetailReducer
