export const GeneralOvertimeSetting = ({ overtimeSettingList, selectedTab, onChangeTab }) => {
    return (
        <div className="flex flex-col h-full">
            <div className="h-1 bg-neutral-10" />

            <div className={"bg-white flex flex-1"}>
                <div className={"h-full flex flex-col px-4 py-6 space-y-2 border-r"}>
                    {
                        overtimeSettingList.map((tab, index) => (
                            <div key={index}
                                onClick={() => onChangeTab(tab)}
                                className={`w-48 px-4 py-2 flex flex-col justify-center cursor-pointer border-l-2 hover:bg-neutral-10 transition duration-200 ${selectedTab.key === tab.key ? 'font-semibold bg-primary-10 text-primary-50 border-primary-50 rounded-r' : 'font-medium text-neutral-70 border-transparent'}`}>{tab.label}</div>
                        ))
                    }
                </div>
                <div className={"w-full"}>
                    {selectedTab.component}
                </div>
            </div>

        </div>
    )
}
