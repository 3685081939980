import {useNavigate} from "react-router-dom";
import Spinner from "../../../commons/spinner";
import ProgressBar from "../../../commons/progress-bar";
import icDocumentImportSuccess from "../../../../assets/images/ic-document-import-succes.svg";
import Button from "../../../commons/button";

export const ImportData = ({percentage, isComplete}) => {
    const navigate = useNavigate()

    return (
        <div className={`w-full flex flex-col items-center text-neutral-80 space-y-8 px-40 ${!isComplete ? 'py-16' : 'py-36'}`}>
            {
                !isComplete ?
                    <>
                        <Spinner className={"w-10 h-10 border-primary-50"}/>
                        <div className={"flex flex-col items-center space-y-2"}>
                            <p className={"font-bold text-lg"}>Importing</p>
                            <p>Your employee contract are now being Imported</p>
                        </div>
                        <div className={"w-full flex flex-col items-center space-y-5 pt-8"}>
                            <ProgressBar percentage={percentage}/>
                            <p className={"font-bold text-lg text-primary-60"}>Progress in {percentage}%</p>
                        </div>
                    </>
                    :
                    <>
                        <p className={"font-bold"}>Berhasil Import Data Contract Employee</p>
                        <img src={icDocumentImportSuccess} alt="ic-document-import-success" />
                        <Button title="View Contract Employee List" onClick={() => navigate('/management-contract/contract-employee')}/>
                    </>
            }
        </div>
    )
}
