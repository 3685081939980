import { METHOD } from "./method";

export const API_STRUCTURE_ORGANIZATION = {
    GENERATE_STRUCTURE_ORGANIZATION: {
        api: '/v1/hr-adm/job-title/structure',
        method: METHOD.GET
    },
    JOB_TITLE_EMPLOYEES: {
        api: '/v1/hr-adm/job-title/employee',
        method: METHOD.GET
    }
}
