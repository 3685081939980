import FormModal from "../../../commons/form-modal";
import Input from "../../../commons/input";
import icProfile from "../../../../assets/images/ic-user-profile.svg"

export const AddNotesModal = (props) => {
    const {
        onSubmitForm,
        title,
        show,
        onHide,
        notes,
        setNotes,
        employeeDetail,
        contractDetail
    } = props

  return (
      <FormModal
          onSubmitForm={onSubmitForm}
          title={title}
          show={show}
          onHide={onHide}
          isDisabled={!notes || (!!notes && notes.length < 5)}>
          <div className={"p-8 space-y-8"}>
              <div className={"flex p-4 space-x-4 bg-neutral-10 rounded-md"}>
                  <img src={employeeDetail.profile_picture ?? icProfile} alt={'profile-picture'}
                       className={"rounded-full w-12 h-12 border"}/>
                  <div className={"flex flex-col space-y-2"}>
                      <p className={"font-bold text-neutral-80"}>{employeeDetail.name || employeeDetail.full_name}</p>
                      <div className={"flex items-center space-x-2 font-medium"}>
                          <p className={"text-neutral-70"}>No. Kontrak</p>
                          <p className={"text-neutral-80"}>{contractDetail.number}</p>
                      </div>
                      {
                          contractDetail?.addendum_number &&
                          <div className={"flex items-center space-x-2 font-medium"}>
                              <p className={"text-neutral-70"}>No. Addendum</p>
                              <p className={"text-neutral-80"}>{contractDetail.addendum_number}</p>
                          </div>
                      }
                  </div>
              </div>
              <div className={"flex"}>
                  <p className={"w-1/5 required"}>Notes</p>
                  <Input type="text-area" value={notes} onChange={(e) => {
                      setNotes(e.value)
                  }} row={5} showCount maxLength={300} className={"flex-1"}
                         placeholder={'Notes'}/>
              </div>
          </div>
      </FormModal>
  )
}
