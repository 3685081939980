import {HRISAnnouncement} from "../../../components/general-settings/hris-announcement/HRISAnnouncement";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import moment from "moment/moment";
import {downloadFile, getAllQueryParams, setQueryParams} from "../../../utils/utils";
import {useFetch} from "../../../services/useFetch";
import {API_HRIS_ANNOUNCEMENT} from "../../../services/apiHrisAnnouncement";
import {useEffect, useState} from "react";
import {TABLE_HRIS_ANNOUNCEMENT} from "../../../configs/general-settings/configTableHrisAnnouncement";
import {ACTION_TYPE} from "../../../constants/constant";
import {useDispatch, useSelector} from "react-redux";
import {setAnnouncementToast} from "../../../reducers/formHRISAnnouncementReducer";

const TAB_LIST = [
    {id: 'submitted', name: 'submitted'},
    {id: 'published', name: 'published'}
]

export const HRISAnnouncementContainer = () => {
    const location = useLocation()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams()
    const [selectedAnnouncementList, setSelectedAnnouncementList] = useState([])
    const [deleteId, setDeleteId] = useState(null)
    const [documentPreview, setDocumentPreview] = useState('')
    const {toast} = useSelector(state => state.formHRISAnnouncementReducer)
    const status = searchParams.get('status')
    const defaultQuery = {
        page: 1,
        limit: 20,
        search: '',
        status: TAB_LIST[0].id,
        startDate: moment().startOf('month').format('YYYY-MM-DD'),
        endDate: moment().endOf('month').format('YYYY-MM-DD')
    }
    const query = searchParams.size > 0 ? getAllQueryParams(searchParams) : defaultQuery
    const [modal, setModal] = useState({
        limit: false,
        export: false,
        preview: false,
        preview_document: false,
        delete: false,
    })
    const {
        data: announcementList,
        fetching: getAnnouncementList,
        isLoading
    } = useFetch(API_HRIS_ANNOUNCEMENT.HRIS_ANNOUNCEMENT_LIST, defaultQuery)
    const {
        data: previewAnnouncement,
        fetching: getPreviewAnnouncement
    } = useFetch(API_HRIS_ANNOUNCEMENT.PREVIEW_ANNOUNCEMENT)
    const {fetching: exportAnnouncement} = useFetch(API_HRIS_ANNOUNCEMENT.EXPORT_ANNOUNCEMENT_LIST)
    const {fetching: deleteAnnouncement, isLoading: isDeleting} = useFetch(API_HRIS_ANNOUNCEMENT.DELETE_ANNOUNCEMENT)

    useEffect(() => {
        fetchAnnouncementList({...query})
    }, [location.search])

    const fetchAnnouncementList = (item = query) => {
        getAnnouncementList(item)
    }

    const onChangeTab = (status) => {
        const params = {...defaultQuery, status: status.id}
        setSearchParams(params, {replace: true})
    }

    const onChangeQuery = (queryParams) => {
        const params = setQueryParams({...query, ...queryParams})
        setSearchParams(params, {replace: true})
    }

    const clearToast = () => {
        dispatch(setAnnouncementToast({message: '', isShowToast: false}))
    }

    const onClickItemHandler = ({id, actionType}) => {
        switch (actionType) {
            case ACTION_TYPE.CREATE:
                navigate('/general-settings/hris-announcement/create')
                break
            case ACTION_TYPE.PREVIEW:
                getPreviewAnnouncement({}, id)
                setModal({...modal, preview: true})
                break
            case ACTION_TYPE.EDIT:
                navigate(`/general-settings/hris-announcement/update?announcementId=${id}`)
                break
            default:
                setDeleteId(id)
                setModal({...modal, delete: true})
        }
    }

    const onExport = () => {
        const {page, limit, ...dataFilterRest} = query
        const payload = {...dataFilterRest, announcement_ids: selectedAnnouncementList.map(item => item.id)}
        exportAnnouncement(payload, '', {}, 'blob')
            .then(response => {
                downloadFile(null, response, `Remote-Working-Export-${moment().format('DD-MM-YYYY')}.xlsx`)
                dispatch(setAnnouncementToast({message: 'Anda berhasil export data announcement', isShowToast: true}))
                setModal({...modal, export: false})
            })
    }

    const onDelete = () => {
        deleteAnnouncement({}, deleteId)
            .then(() => {
                dispatch(setAnnouncementToast({message: 'Announcement Deleted', isShowToast: true}))
                setModal({...modal, preview: false, delete: false})
                if (announcementList?.data?.result?.length === 1 && Number(query?.page) !== 1) {
                    onChangeQuery({page: 1})
                } else {
                    fetchAnnouncementList()
                }
            })
    }

    const onPreviewDocument = (url) => {
        setModal({...modal, preview_document: true})
        setDocumentPreview(url)
    }

    console.log(query)

    const props = {
        tabList: TAB_LIST,
        configTable: TABLE_HRIS_ANNOUNCEMENT,
        dataList: announcementList?.data,
        dataPreview: previewAnnouncement?.data,
        status: status || TAB_LIST[0].id,
        isLoading,
        isDeleting,
        modal,
        query,
        toast,
        selectedAnnouncementList,
        navigate,
        documentPreview,
        onPreviewDocument,
        clearToast,
        onExport,
        onDelete,
        onChangeTab,
        onChangeQuery,
        onClickItemHandler,
        onSelectItem: (item) => setSelectedAnnouncementList([...item]),
        onSetModal: (e) => setModal({...modal, ...e})
    }

    return (
        <HRISAnnouncement {...props}/>
    )
}
