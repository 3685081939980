import Table from "../../commons/table";
import {TABLE_CONTRACT_LIST} from "../../../configs/contract/configTableContractEmployee";
import {PreviewContract} from "./PreviewContract";
import Search from "../../commons/search";
import {
    ContractFilterContainer
} from "../../../containers/management-contract/contract-employee/ContractFilterContainer";
import ViewList from "../../commons/view-list";
import Button from "../../commons/button";
import icFileUp from "../../../assets/images/ic-file-up-02.svg";
import {ExportModal} from "../../commons/ExportModal";
import {ContractEmployee} from "./ContractEmployee";
import icTrash from "../../../assets/images/ic-trash-01.svg";
import DeleteModal from "../../commons/delete-modal";
import Toast from "../../commons/toast";

export const ContractList = (props) => {
    const {
        isLoading,
        documentPreview,
        setDocumentPreview,
        contractListResponse,
        onPreviewHandler,
        modalPreview,
        updateVisibility,
        isOpen,
        employeeDetail,
        onChangePage,
        onSelectItem,
        onClickSort,
        tabList,
        status,
        onChangeTab,
        query,
        onChangeQuery,
        queryFilter,
        modal,
        toast,
        modalVisibility,
        selectedRows,
        totalData,
        onExportHandler,
        onClickButtonDelete,
        onDeleteContract,
        clearToast
    } = props

    return (
        <ContractEmployee tabList={tabList} activeTab={status} onClickTab={onChangeTab}>
            <div className={"flex flex-row space-x-6 px-6 py-4"}>
                <Search value={query?.search} onSearch={(search) => onChangeQuery({page: 1, search})}/>
                <div className={"flex flex-row"}>
                    <ContractFilterContainer onSubmit={(queryFilter) => onChangeQuery({...queryFilter, page: 1})}
                                             queryFilter={queryFilter}/>
                    <div className={"flex flex-row space-x-2 ml-2"}>
                        <ViewList
                            show={modal?.sort}
                            onShow={() => modalVisibility({sort: true})}
                            onClose={() => modalVisibility({sort: false})}
                            value={query?.limit}
                            onSelectSize={(limit) => onChangeQuery({page: 1, limit})}
                        />
                        {
                            status === tabList[1]?.name &&
                            <Button icon={icTrash} type="outline" onClick={onClickButtonDelete}/>
                        }
                        <Button title="Export" icon={icFileUp} type="outline"
                                onClick={() => modalVisibility({export: true})}/>
                    </div>
                </div>
            </div>
            <div>
                <Table
                    className="my-3"
                    name={TABLE_CONTRACT_LIST.name}
                    data={contractListResponse?.data?.result}
                    columns={TABLE_CONTRACT_LIST.column}
                    size={contractListResponse?.data?.limit}
                    totalPage={contractListResponse?.data?.total_page}
                    currentPage={query?.page}
                    totalResult={contractListResponse?.data?.total_result}
                    onChangePage={(e) => onChangePage(e)}
                    onClickItem={(e) => onPreviewHandler(e)}
                    onSelectItem={(e) => onSelectItem(e)}
                    onClickSort={(e) => onClickSort(e)}
                    loading={isLoading}
                />
                {
                    isOpen &&
                    <PreviewContract
                        visibility={isOpen}
                        employeeDetail={employeeDetail}
                        documentPreview={documentPreview}
                        setDocumentPreview={setDocumentPreview}
                        modalPreview={modalPreview}
                        updateVisibility={updateVisibility}
                    />}
            </div>
            <DeleteModal
                show={modal?.delete}
                onHide={() => modalVisibility({delete: false})}
                onDelete={onDeleteContract}
                text={`Apakah anda yakin ingin menghapus ${selectedRows?.length} data kontrak?`}
            />
            <ExportModal
                title={'Apakah anda yakin ingin export data kontrak karyawan?'}
                description={`Total data export kontrak karyawan : ${selectedRows.length > 0 ? selectedRows.length : totalData}`}
                show={modal?.export}
                onExportHandler={onExportHandler}
                onCancelHandler={() => modalVisibility({export: false})}
            />
            <Toast
                show={toast?.isShowToast}
                message={toast?.message}
                onClose={() => clearToast()}
            />
        </ContractEmployee>
    )
}
