import OrganizationChart from "@dabeng/react-orgchart";
import {ParentNode} from "./ParentNode";
import {ChildNode} from "./ChildNode";
import Spinner from "components/commons/spinner";

export const OrganizationalStructure = ({structureOrganization, childStructureData = null, isLoading}) => {
    return (
        <div className={'flex flex-wrap'}>
            {/* <p className={"flex flex-row justify-center font-semibold"}>Structure Organization PT AVO Innovation Technology</p> */}
            <div className={'w-full flex justify-center'}>
                {isLoading?.parent 
                    ? <Spinner className="w-8 h-8 border-primary-50 mt-12" />
                    : <OrganizationChart datasource={structureOrganization || []} NodeTemplate={ParentNode} />
                }
            </div>
            <div className={'w-full flex justify-center'}>
                {isLoading?.child 
                    ? <Spinner className="w-8 h-8 border-primary-50 mt-12" />
                    : !!childStructureData && <OrganizationChart datasource={childStructureData || []} NodeTemplate={ChildNode} />
                }
            </div>
        </div>
    )
}
