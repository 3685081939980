import icFamily from "assets/images/ic-activity.svg"
import Button from "components/commons/button"
import DeleteModal from "components/commons/delete-modal"
import Table from "components/commons/table"
import { TABLE_FAMILY } from "configs/employee/configTableAddEmployee"
import { useExpandable } from "hooks/useExpandable"
import { CreateFamily } from "../create-modal/CreateFamily"
import { FormHeader } from "./FormHeader"

export const FamilyInformation = ({
    optionsFamilyRelationship,
    dataFamily,
    errorForm,
    isFormValid,
    isEdit,
    form,
    showModal,
    onShowModal,
    onCloseModal,
    onChangeForm,
    onSubmit,
    onActionRow,
    showDeleteModal,
    onCloseDeleteModal,
    onDelete,
}) => {
    const { isExpanded, elementRef, toggleExpand } = useExpandable({ verticalPadding: 24 })

    return (
        <div className="bg-white border-y border-neutral-20">
            <FormHeader title="Informasi Keluarga" optional icon={icFamily} expanded={isExpanded} onClick={toggleExpand} />
            <div ref={elementRef} className="expandable px-8">
                <Table
                    className="mb-5"
                    classNameHeader="!bg-primary-10 !text-md !text-primary-50"
                    classNameRow="!py-6"
                    name={TABLE_FAMILY.name}
                    columns={TABLE_FAMILY.column}
                    data={form}
                    onClickItem={onActionRow}
                />
                <Button type="outline" title="+ Tambah Data Keluarga" onClick={onShowModal} />
            </div>

            <CreateFamily
                errorForm={errorForm}
                isFormValid={isFormValid}
                isEdit={isEdit}
                show={showModal}
                onHide={onCloseModal}
                optionsFamilyRelationship={optionsFamilyRelationship}
                dataFamily={dataFamily}
                onChangeForm={onChangeForm}
                onSubmit={onSubmit}
                useSameAsAddress
            />

            <DeleteModal
                text={"Apakah anda yakin ingin menghapus data keluarga?"}
                show={showDeleteModal}
                onHide={onCloseDeleteModal}
                onDelete={onDelete}
            />
        </div>
    )
}