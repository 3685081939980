import clsx from "clsx"
import "./error-message.scss"
import icError from "./ic-error.svg"

const ErrorMessage = ({ className, message }) => {
    if (!message) return null

    return (
        <div className={clsx("avo__error-message", className)}>
            <img src={icError} alt={'error'} />
            <span className="error-label">{message}</span>
        </div>
    )
}

export default ErrorMessage
