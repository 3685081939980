import icAdditionalInformation from "assets/images/ic-users-profiles-plus.svg"
import Checkbox from "components/commons/checkbox"
import Datepicker from "components/commons/datepicker"
import Input from "components/commons/input"
import Select from "components/commons/select"
import { useExpandable } from "hooks/useExpandable"
import { FormHeader } from "./FormHeader"
import moment from "moment";

export const AdditionalInformation = ({
    optionsMainArea,
    optionsResidenceArea,
    optionsMainPostalCode,
    optionsResidencePostalCode,
    options,
    selected,
    form,
    onChangeForm,
    onSearchArea,
}) => {
    const { isExpanded, elementRef, toggleExpand } = useExpandable({ verticalPadding: 24 })

    return (
        <div className="bg-white border-y border-neutral-20">
            <FormHeader title="Informasi Tambahan" optional icon={icAdditionalInformation} expanded={isExpanded} onClick={toggleExpand} />
            <div ref={elementRef} className="expandable px-8">
                <div className="grid gap-5 lg:grid-cols-2 lg:gap-x-16">
                    <div className="flex items-center gap-3">
                        <div className="w-1/3 text-neutral-70">{'Gender'}</div>
                        <Select
                            name="gender"
                            className='flex-1'
                            placeholder="Pilih gender"
                            options={options.optionsGender}
                            value={selected.gender}
                            onChange={onChangeForm}
                        />
                    </div>
                    <div className="flex items-center gap-3">
                        <div className="w-1/3 text-neutral-70">{'Golongan Darah'}</div>
                        <Select
                            name="blood_type"
                            className='flex-1'
                            placeholder="Pilih golongan darah"
                            options={options.optionsBloodType}
                            value={selected.blood_type}
                            onChange={onChangeForm}
                        />
                    </div>
                    <div className="flex items-center gap-3">
                        <div className="w-1/3 text-neutral-70">{'Tempat Lahir'}</div>
                        <Input
                            className="flex-1"
                            name="birthplace"
                            placeholder="Isi tempat lahir"
                            maxLength={100}
                            value={form?.personal_detail?.birthplace}
                            onChange={onChangeForm}
                        />
                    </div>
                    <div className="flex items-center gap-3">
                        <div className="w-1/3 text-neutral-70">{'Tanggal Lahir'}</div>
                        <Datepicker
                            name='birthdate'
                            className="flex-1"
                            value={form?.personal_detail?.birthdate}
                            format='YYYY-MM-DD'
                            maxDate={moment()}
                            onChange={(e) => onChangeForm({ name: 'birthdate', value: e })}
                        />
                    </div>
                    <div className="flex items-center gap-3">
                        <div className="w-1/3 text-neutral-70">{'Agama'}</div>
                        <Select
                            name="religion"
                            className='flex-1'
                            placeholder="Pilih agama"
                            options={options.optionsReligions}
                            value={selected.religion}
                            onChange={onChangeForm}
                        />
                    </div>
                    <div className="flex items-center gap-3">
                        <div className="w-1/3 text-neutral-70">{'Status'}</div>
                        <Select
                            name="marital_status"
                            className='flex-1'
                            placeholder="Pilih status"
                            options={options.optionsMaritalStatus}
                            value={selected.marital_status}
                            onChange={onChangeForm}
                        />
                    </div>
                    <div className="flex items-center gap-3">
                        <div className="w-1/3 text-neutral-70">{'Tinggi Badan'}</div>
                        <Input
                            className="flex-1"
                            addonAfter="cm"
                            name="height"
                            type="number"
                            placeholder="Isi tinggi badan"
                            maxLength={3}
                            value={form?.personal_detail?.height}
                            onChange={onChangeForm}
                        />
                    </div>
                    <div className="flex items-center gap-3">
                        <div className="w-1/3 text-neutral-70">{'Berat Badan'}</div>
                        <Input
                            className="flex-1"
                            addonAfter="Kg"
                            name="weight"
                            type="number"
                            placeholder="Isi berat badan"
                            maxLength={3}
                            value={form?.personal_detail?.weight}
                            onChange={onChangeForm}
                        />
                    </div>
                    <div className="flex items-center gap-3">
                        <div className="w-1/3 text-neutral-70">{'Hobi'}</div>
                        <Input
                            className="flex-1"
                            name="hobby"
                            placeholder="Isi hobi"
                            maxLength={100}
                            value={form?.personal_detail?.hobby}
                            onChange={onChangeForm}
                        />
                    </div>
                    <div className="hidden lg:block" />

                    <div className="space-y-5 lg:mt-3">
                        <div className="font-semibold text-md text-primary-50">{'Alamat KTP'}</div>
                        <div className="flex items-center gap-3">
                            <div className="w-1/3 text-neutral-70">{'Kecamatan & Kota'}</div>
                            <Select
                                name="main"
                                className='flex-1'
                                optionsClassName={optionsMainArea.length > 0 && 'max-h-[200px] min-w-full sm:!w-auto whitespace-pre-line sm:whitespace-nowrap'}
                                placeholder="Cari kecamatan dan kota"
                                options={optionsMainArea}
                                value={selected.main}
                                onChange={onChangeForm}
                                onSearch={(keyword) => onSearchArea("main", keyword)}
                            />
                        </div>
                        <div className="flex items-center gap-3">
                            <div className="w-1/3 text-neutral-70">{'Kode Pos'}</div>
                            <Select
                                name="id_card_postal_code"
                                className='flex-1'
                                placeholder="Pilih kode pos"
                                options={optionsMainPostalCode}
                                value={selected.id_card_postal_code}
                                onChange={onChangeForm}
                            />
                        </div>
                        <div className="flex gap-3">
                            <div className="w-1/3 text-neutral-70">{'Detail Alamat'}</div>
                            <Input
                                className='flex-1'
                                name="id_card_address"
                                type="text-area"
                                row={3}
                                showCount
                                maxLength={300}
                                placeholder="Isi detail alamat"
                                value={form?.personal_detail?.id_card_address}
                                onChange={onChangeForm}
                            />
                        </div>
                    </div>

                    <div className="space-y-5 lg:mt-3">
                        <div className="font-semibold text-md text-primary-50">{'Alamat Domisili'}</div>
                        <Checkbox label="Sama dengan di alamat KTP" name="same_as_address" value={'same_as'} onChange={onChangeForm} />
                        <div className="flex items-center gap-3">
                            <div className="w-1/3 text-neutral-70">{'Kecamatan & Kota'}</div>
                            <Select
                                name="residence"
                                className='flex-1'
                                optionsClassName={optionsResidenceArea.length > 0 && 'max-h-[200px] min-w-full sm:!w-auto whitespace-pre-line sm:whitespace-nowrap'}
                                placeholder="Cari kecamatan dan kota"
                                options={optionsResidenceArea}
                                value={selected.residence}
                                onChange={onChangeForm}
                                onSearch={(keyword) => onSearchArea("residence", keyword)}
                                disabled={selected.same_as_address}
                            />
                        </div>
                        <div className="flex items-center gap-3">
                            <div className="w-1/3 text-neutral-70">{'Kode Pos'}</div>
                            <Select
                                name="residence_postal_code"
                                className='flex-1'
                                placeholder="Pilih kode pos"
                                options={optionsResidencePostalCode}
                                value={selected.residence_postal_code}
                                onChange={onChangeForm}
                                disabled={selected.same_as_address}
                            />
                        </div>
                        <div className="flex gap-3">
                            <div className="w-1/3 text-neutral-70">{'Detail Alamat'}</div>
                            <Input
                                className='flex-1'
                                name="residence_address"
                                type="text-area"
                                row={3}
                                showCount
                                maxLength={300}
                                placeholder="Isi detail alamat"
                                disabled={selected.same_as_address}
                                value={form?.personal_detail?.residence_address}
                                onChange={onChangeForm}
                            />
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}
