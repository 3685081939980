import {useEffect, useState} from "react";
import icPeople from "../../assets/images/ic-people.svg";
import icMenu from "../../assets/images/ic-menu-01.svg";
import {Link} from "react-router-dom";
import icSetting from "../../assets/images/ic-setting-2.svg";
import { useClickOutside } from "hooks/useClickOutside";

export const FooterSidebar = ({modules = []}) => {
    const [showOptions, setShowOptions] = useState(false)
    const [activeModule, setActiveModule] = useState(null)

    useEffect(() => {
        if (modules?.length > 0 && !activeModule) {
            setActiveModule(modules[0])
        }
    }, [modules]);

    const onSwitchModule = (company) => {
        setActiveModule(company)
        setShowOptions(!showOptions)
    }

    const elementID = 'footerSidebar'
    useClickOutside({ callback: () => showOptions && setShowOptions(false), elementID })

    return (
        <div id={elementID} className={"relative"}>
            <div className={"flex justify-between items-center p-3 bg-primary-10 rounded mx-1 cursor-pointer"}
                 onClick={() => setShowOptions(!showOptions)}>
                <div className={"flex items-center space-x-3"}>
                    <img src={icPeople} alt={'module-icon'}/>
                    <p className={"font-bold text-primary-60"}>{activeModule?.name}</p>
                </div>
                <img src={icMenu} alt={'ic-menu'}/>
            </div>
            <div className={showOptions ? 'block' : 'hidden'}>
                <div
                    className={"col absolute left-[150px] bottom-14 z-10 w-[274px] bg-white shadow-md rounded-md border overflow-scroll"}>
                    <p className={"px-3 py-2 font-semibold text-primary-50"}>PILIH MODUL</p>
                    {
                        modules.map((module, index) => (
                            <div
                                onClick={() => onSwitchModule(module)}
                                key={index}
                                className={"flex space-x-4 px-4 py-3 items-center hover:bg-neutral-10 hover:text-primary-50 hover:font-semibold cursor-pointer"}>
                                <img src={icPeople} className={"w-6 h-6 p-1 rounded bg-primary-10"}
                                     alt={'module-icon'}/>
                                <p>{module?.name}</p>
                            </div>
                        ))
                    }
                    <Link to={'/settings-perusahaan'} onClick={() => setShowOptions(false)}>
                        <div className={"border-t flex items-center space-x-4 px-4 py-3 cursor-pointer"}>
                            <img src={icSetting} className={"w-4 h-4 m-1"}/>
                            <p>Settings PT</p>
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    )
}
