import {METHOD} from "./method";

export const API_REMOTE_WORKING_SETTING = {
    DETAIL_REMOTE_WORKING: {
        api: '/v1/hr-adm/general-setting/remote-working',
        method: METHOD.GET
    },
    UPDATE_REMOTE_WORKING: {
        api: '/v1/hr-adm/general-setting/remote-working',
        method: METHOD.PUT
    },
}
