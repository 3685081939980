import {METHOD} from "./method";

export const API_REQUEST_SHIFTING = {
    SHIFTING_LIST: {
        api: '/v1/hr-adm/shifting/list',
        method: METHOD.POST
    },
    EXPORT_SHIFTING_LIST: {
        api: '/v1/hr-adm/shifting/list/export',
        method: METHOD.POST
    },
    DETAIL_SHIFTING: {
        api: '/v1/hr-adm/shifting',
        method: METHOD.GET
    },
    DETAIL_SHIFTING_LIST: {
        api: '/v1/hr-adm/shifting/list',
        method: METHOD.GET
    },
    CREATE_SHIFTING: {
        api: '/v1/hr-adm/shifting',
        method: METHOD.POST
    },
    UPDATE_SHIFTING: {
        api: '/v1/hr-adm/shifting',
        method: METHOD.PUT
    },
    DELETE_SHIFTING: {
        api: '/v1/hr-adm/shifting',
        method: METHOD.DELETE
    },
    EXPORT_SHIFTING: {
        api: '/v1/hr-adm/shifting/export',
        method: METHOD.GET
    },
}
