import { RoleAccess } from "components/management-account/role-access/RoleAccess"
import { TABLE_ROLE_ACCESS } from "configs/management-account/configTableRoleAccess"
import { ACTION_TYPE } from "constants/constant"
import { useEffect, useState } from "react"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"
import { API_ROLE } from "services/apiRole"
import { METHOD } from "services/method"
import { useFetch } from "services/useFetch"
import { getAllQueryParams, setQueryParams } from "utils/utils"
import {useDispatch, useSelector} from "react-redux";
import {setToast} from "../../../reducers/accountReducer";

export const RoleAccessContainer = () => {
    const dispatch = useDispatch()
    const location = useLocation()
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams()
    const defaultQuery = {
        page: 1,
        limit: 20,
        search: ''
    }
    const query = searchParams.size > 0 ? getAllQueryParams(searchParams) : defaultQuery
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [selectedItem, setSelectedItem] = useState()
    const {toast} = useSelector(state => state.accountReducer)
    const { data: dataRoleAccess, fetching: getRoleList, isLoading: isLoadingRoleList } = useFetch(API_ROLE.LIST_ROLE)
    const { fetching: deleteRole, isLoading: isLoadingDelete } = useFetch({ ...API_ROLE.ROLE, initPathVariable: selectedItem?.id, method: METHOD.DELETE })

    useEffect(() => {
        getRoleList(query)
    }, [location.search])

    const handleGetRoleList = (queryParams) => {
        const params = setQueryParams({ ...query, ...queryParams })
        setSearchParams(params, { replace: true })
    }

    const onDelete = () => {
        deleteRole().then(() => {
            getRoleList(query)
            setShowDeleteModal(false)
            dispatch(setToast({isShowToast: true, message: 'Role Access Deleted !!'}))
        }).catch(() => {
            setShowDeleteModal(false)
        })
    }

    const onActionRow = ({ item, actionType }) => {
        setSelectedItem(item)
        switch (actionType) {
            case ACTION_TYPE.EDIT:
                return navigate(`/management-account/role-access/update-role?roleId=${item.id}`)
            case ACTION_TYPE.DELETE:
                return setShowDeleteModal(true)
            default:
        }
    }

    const clearToast = () => {
        dispatch(setToast({isShowToast: false, message: ''}))
    }

    const props = {
        configTable: TABLE_ROLE_ACCESS,
        dataRoleAccess: dataRoleAccess?.data,
        isLoadingRoleList,
        query,
        toast,
        clearToast,
        onSearch: (search) => handleGetRoleList({ page: 1, search }),
        onChangePage: (page) => handleGetRoleList({ page }),
        onCreateRole: () => navigate('/management-account/role-access/create-role'),
        onActionRow,
        showDeleteModal,
        onCloseDeleteModal: () => setShowDeleteModal(false),
        onDelete,
        isLoadingDelete,
    }

    return (
        <RoleAccess {...props} />
    )
}
