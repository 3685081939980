import clsx from "clsx"
import {useEffect, useState} from "react"
import icEyeOff from './ic-eye-off.svg'
import icEye from './ic-eye.svg'
import './input.scss'
import {separateNumber} from "utils/utils"
import icCloseRoundFill from "../../../assets/images/ic-close-round-fill.svg";

export const Input = (
    {
        className,
        classNameInput,
        type = 'text',
        name,
        value,
        placeholder,
        disabled,
        onChange,
        readOnly,
        maxLength,
        row,
        addonBefore,
        addonAfter,
        iconAfter,
        style,
        showCount,
        error,
        onClick,
        onClickClear,
        pattern,
    }) => {

    const [isPasswordVisible, setPasswordVisible] = useState(false)
    const [sValue, setValue] = useState("")
    const [currency, setCurrency] = useState({
        value_int: '',
        value_string: ''
    })

    useEffect(() => {
        if (type === 'currency') {
            if (value === "" || value === null) {
                setCurrency({
                    value_int: '',
                    value_string: ''
                })
            } else {
                const valueString = String(value)
                const valueInt = valueString.replace(/[.]/g, '')
                setCurrency({
                    value_int: valueInt,
                    value_string: separateNumber(valueInt)
                })
            }
        } else if (value || value === "" || value === null) {
            setValue(value || "")
        }
    }, [value])

    const handleOnChange = (e) => {
        const {name, value} = e.target

        if (type === 'tel' && ((!(value.match(/^[0-9]+$/)) && value[0]) || value[0] === '0')) return
        if (type === "number" && value !== "" && !(value.match(/^[0-9]+$/))) return
        if (type === "float" && value !== "" && !(value.match(/^[0-9]*\.?[0-9]*$/))) return
        if (pattern && value !== "" && !(value.match(pattern))) return

        setValue(value)
        if (!onChange) return
        onChange({ name: name, value: value === '' ? null : value })
    }

    const handleOnKeyDown = (e) => {
		if (!/[0-9]/.test(e.key) && (e.key !== 'Backspace') && type === 'currency') e.preventDefault()
	}

    const handleCurrency = (e) => {
        let {name, value} = e.target
        if (value.charAt(0) === '0' && value?.length > 1) {
            value = value.substring(1)
        }
        const valueInt = value.replace(/[.]/g, '')
        setCurrency({
            value_int: valueInt,
            value_string: separateNumber(valueInt)
        })
        onChange({name: name, value: value === '' ? null : valueInt})
    }

    const typeInput = () => {
        if (type === 'number' || type === 'float') return 'text'
        if (type === 'password') return isPasswordVisible ? 'text' : 'password'
        return type
    }

    const onClickClearHandler = (e) => {
        e.stopPropagation()
        onClickClear()
    }

    if (type === "text-area") return (
        <div className={clsx("avo__input input-area", className)}>
            <div className="input-wrapper input-wrapper-area">
                <textarea
                    className={classNameInput}
                    style={style}
                    rows={row}
                    placeholder={placeholder}
                    name={name}
                    maxLength={maxLength}
                    value={value || sValue}
                    readOnly={readOnly}
                    disabled={disabled}
                    onChange={handleOnChange}
                />
                {showCount &&
                    <div className="count-char">{`${sValue?.length}${maxLength ? ` / ${maxLength}` : ''}`}</div>}

            </div>
        </div>
    )

    return (
        <div className={clsx("avo__input", className)} onClick={onClick}>
            {addonBefore && <div className={clsx("addon addon-before", disabled && "disabled")}>{addonBefore}</div>}

            <div className="input-wrapper">
                {type === 'password' &&
                    <button className="btn-password-visible" onClick={() => setPasswordVisible(!isPasswordVisible)}>
                        <img src={isPasswordVisible ? icEyeOff : icEye} alt="password visible"/>
                    </button>}
                <input
                    className={clsx(classNameInput, addonBefore && 'with-addon-before', addonAfter && 'with-addon-after', error && 'invalid',)}
                    style={style}
                    placeholder={placeholder}
                    name={name}
                    value={type === 'currency' ? currency.value_string : value ?? sValue}
                    type={typeInput()}
                    maxLength={maxLength}
                    readOnly={readOnly}
                    disabled={disabled}
                    onKeyDown={handleOnKeyDown}
                    onChange={type === 'currency' ? handleCurrency : handleOnChange}
                />
                {(onClickClear && value) && <img className={clsx("btn-clear", iconAfter && "include-icon-after")} src={icCloseRoundFill} alt="button-clear" onClick={onClickClearHandler}/>}
                {iconAfter && <button className="btn-password-visible" disabled={disabled} onClick={() => setPasswordVisible(!isPasswordVisible)}>
                    <img src={iconAfter} alt="password visible"/>
                </button>}
            </div>
            {addonAfter && <div className={clsx("addon addon-after", disabled && "disabled")}>{addonAfter}</div>}

        </div>
    )
}

export default Input
