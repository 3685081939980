import {ChangePasswordTab} from "../../../../components/employee/employee-detail/employee-detail-tab/ChangePasswordTab";
import {useState} from "react";
import {useFetch} from "../../../../services/useFetch";
import {API_EMPLOYEE} from "../../../../services/apiEmployee";
import {useDispatch, useSelector} from "react-redux";
import {setToast} from "../../../../reducers/employeeDetailReducer";

const INITIAL_STATE = {
    new_password: null,
    new_password_confirmation: null
}

export const ChangePasswordTabContainer = () => {
    const dispatch = useDispatch()
    const { id } = useSelector(state => state.employeeDetailReducer)
    const [form, setForm] = useState(INITIAL_STATE)
    const [errorForm, setErrorForm] = useState({})
    const {fetching: updatePassword, isLoading: isUpdating} = useFetch({ ...API_EMPLOYEE.CHANGE_PASSWORD, initPathVariable: id })

    const onChangeForm = (e) => {
        const {name, value} = e
        setForm({...form, [name]: value})
    }

    const onGeneratePassword = () => {
        let result = ''
        let indexChar = 0
        const characters = [
            'ABCDEFGHIJKLMNOPQRSTUVWXYZ', //uppercase
            'abcdefghijklmnopqrstuvwxyz', //lowercase
            '0123456789', //number
            '!@#$%^&*(),.?":{}|<>_+=-[]/~`' //special character
        ]
        for (let i = 0; i < 8; i++) {
            const randomIndex = Math.floor(Math.random() * characters[indexChar].length)
            result += characters[indexChar].charAt(randomIndex)
            if (indexChar < characters.length - 1) {
                indexChar += 1
            } else {
                indexChar = 0
            }
        }
        setForm({...form, new_password: result})
    }

    const validateForm = () => {
        return new Promise((resolve, reject) => {
            const errors = {}
            const regexPasswordValidate = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>_+=\-[\]\\/'~`])[A-Za-z\d!@#$%^&*(),.?":{}|<>_+=\-[\]\\/'~`]{8,}$/
            Object.keys(form).forEach(key => {
                if (!form[key]) {
                    errors[key] = 'Password tidak boleh kosong'
                } else if (!regexPasswordValidate.test(form[key])) {
                    errors[key] = 'Password harus ada huruf besar, huruf kecil, angka, symbol dan minimal 8 karakter'
                } else if (key === 'new_password_confirmation' && form[key] !== form['new_password']) {
                    errors[key] = 'Konfirmasi password tidak sama dengan password baru'
                }
            })
            setErrorForm(errors)
            if (!Object.keys(errors).length) resolve()
        })
    }

    const onSubmit = () => {
        validateForm(form)
            .then(() => {
                updatePassword(form)
                dispatch(setToast({isShowToast: true, message: 'Password has been Changed !!'}))
            })
    }

    const props = {
        form,
        onGeneratePassword,
        onChangeForm,
        onSubmit,
        isUpdating,
        errorForm
    }

    return (
        <ChangePasswordTab {...props}/>
    )
}
