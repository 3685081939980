import iconRemoteWorking from "assets/images/ic-home-05-blue.svg"
import Button from "components/commons/button"
import Input from "components/commons/input"
import ErrorMessage from "../../commons/error-message";
import Toast from "../../commons/toast";

export const RemoteWorkingSetting = (
    {
        form,
        isShowToast,
        clearToast,
        onChangeForm,
        onSubmit,
        isUpdating,
        errorForm
    }) => {

    return (
        <div className="p-9">
            <div className="flex items-center gap-3 mb-5">
                <img className="w-4 h-4" src={iconRemoteWorking} alt="remote working setting"/>
                <div className="font-semibold text-md">Remote Working Settings</div>
            </div>
            <div className="w-1/2 flex flex-col gap-5">
                <div className="flex gap-3">
                    <div className="w-1/3 font-medium text-neutral-70 mt-2 required">Kuota Per Bulan</div>
                    <div className="flex-1">
                        <Input
                            name="quota"
                            type="number"
                            placeholder="Isi kuota"
                            addonAfter="Hari"
                            maxLength={2}
                            value={form?.quota}
                            onChange={onChangeForm}
                        />
                        <ErrorMessage className="mt-2" message={errorForm?.quota}/>
                    </div>
                </div>
                <div className="flex items-center gap-3">
                    <div className="w-1/3 font-medium text-neutral-70">Mulai Berlaku Setelah x Bulan Kontrak</div>
                    <Input
                        className="flex-1"
                        name="month_to_start"
                        type="number"
                        placeholder="Mulai berlaku"
                        addonAfter="Bulan"
                        maxLength={2}
                        value={form?.month_to_start}
                        onChange={onChangeForm}
                    />
                </div>
                <Button className="ml-auto w-32" title="Simpan" loading={isUpdating} onClick={onSubmit}/>
            </div>
            <Toast
                show={isShowToast}
                message="Remote Working Updated !!"
                onClose={() => clearToast()}
            />
        </div>
    )
}
