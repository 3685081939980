import { AddEmployee } from "components/employee/employee-add/AddEmployee"
import { SELECTED_ADDITIONAL_INFORMATION } from "constants/defaultFormEmployee"
import { useValidation } from "hooks/useValidation"
import { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useSearchParams } from "react-router-dom"
import { clearAddEmployeeReducer, setDraftEmployee } from "reducers/addEmployeeReducer"
import { addEmployeeValidation } from "services/addEmployeeValidation"
import { API_EMPLOYEE } from "services/apiEmployee"
import { METHOD } from "services/method"
import { useFetch } from "services/useFetch"
import {capitalize, convertArrayToObject, formatPhoneNumber} from "utils/utils"
import { AccountInformationContainer } from "./AccountInformationContainer"
import { AdditionalInformationContainer } from "./AdditionalInformationContainer"
import { EducationInformationContainer } from "./EducationInformationContainer"
import { EmergencyContactInformationContainer } from "./EmergencyContactInformationContainer"
import { FamilyInformationContainer } from "./FamilyInformationContainer"
import { JobTitleContainer } from "./JobTitleContainer"
import { NPWPInformationContainer } from "./NPWPInformationContainer"
import { PersonalDocumentContainer } from "./PersonalDocumentContainer"
import { PersonalInformationContainer } from "./PersonalInformationContainer"
import { WorkExperienceContainer } from "./WorkExperienceContainer"
import {uploadFileToS3} from "../../../utils/utils";
import {setToast} from "../../../reducers/employeeListReducer";

export const AddEmployeeContainer = () => {
    const [searchParams] = useSearchParams();
    const draftId = searchParams.get('draftId')
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const dataEmployee = useSelector((state) => state.addEmployeeReducer)
    const [errorFormApi, setErrorForm] = useState({})
    const [selectedAdditional, setSelectedAdditional] = useState(SELECTED_ADDITIONAL_INFORMATION)
    const [showConfirmSubmit, setShowConfirmSubmit] = useState(false)
    const { data: dataConstantsEmployee } = useFetch(API_EMPLOYEE.LIST_CONSTANTS_EMPLOYEE, {}, { isFetching: true })
    const { fetching: createEmployee } = useFetch(API_EMPLOYEE.CREATE_EMPLOYEE)
    const { fetching: updateEmployee } = useFetch({ ...API_EMPLOYEE.CREATE_EMPLOYEE, initPathVariable: draftId, method: METHOD.PUT })
    const { fetching: createDraftEmployee } = useFetch(API_EMPLOYEE.CREATE_DRAFT_EMPLOYEE)
    const { fetching: updateDraftEmployee } = useFetch({ ...API_EMPLOYEE.CREATE_DRAFT_EMPLOYEE, initPathVariable: draftId, method: METHOD.PUT })
    const { fetching: getEmployeeDetail } = useFetch({ ...API_EMPLOYEE.DETAIL, initPathVariable: draftId })
    const { fetching: getUrlProfilePicture } = useFetch(API_EMPLOYEE.URL_PROFILE_PICTURE)
    const { fetching: getUrlUploadPersonalDocument } = useFetch(API_EMPLOYEE.URL_PERSONAL_DOCUMENT)
    const { errorForm: errorFormValidation, validateForm } = useValidation({ schema: addEmployeeValidation })
    let errorForm = {...errorFormValidation, ...errorFormApi}

    useEffect(() => {
        if (draftId) {
            getEmployeeDetail().then((response) => {
                dispatch(setDraftEmployee(response.data))
                const { additional_info } = response.data
                setSelectedAdditional({
                    gender: additional_info?.gender ? { label: capitalize(additional_info?.gender), value: additional_info?.gender } : {},
                    religion: additional_info?.religion ? { label: capitalize(additional_info?.religion), value: additional_info?.religion } : {},
                    marital_status: additional_info?.marital_status ? { label: capitalize(additional_info?.marital_status), value: additional_info?.marital_status } : {},
                    blood_type: additional_info?.blood_type ? { label: capitalize(additional_info?.blood_type), value: additional_info?.blood_type } : {},
                    main: additional_info?.id_card_district ? { label: `${additional_info.id_card_district.trim()}, ${additional_info.id_card_city.trim()}, ${additional_info.id_card_province.trim()}` } : {},
                    residence: additional_info?.residence_district ? { label: `${additional_info.residence_district.trim()}, ${additional_info.residence_city.trim()}, ${additional_info.residence_province.trim()}` } : {},
                    id_card_postal_code: additional_info?.id_card_postal_code ? { label: additional_info?.id_card_postal_code, value: additional_info?.id_card_postal_code } : {},
                    residence_postal_code: additional_info?.residence_postal_code ? { label: additional_info?.residence_postal_code, value: additional_info?.residence_postal_code } : {},
                })
            })
        }
    }, [draftId])

    const { optionsGrades, optionsPersonalDocumentType, optionsGender, optionsReligions, optionsMaritalStatus, optionsBloodType } = useMemo(() => {
        const mapOptions = (dataList) => {
            if (!dataList || dataList?.length === 0) return []
            return dataList?.map((item) => ({
                label: capitalize(item),
                value: item,
            }))
        }

        const optionsGrades = mapOptions(dataConstantsEmployee?.data?.grades)
        const optionsPersonalDocumentType = mapOptions(dataConstantsEmployee?.data?.personalDocumentType)
        const optionsGender = mapOptions(dataConstantsEmployee?.data?.gender)
        const optionsReligions = mapOptions(dataConstantsEmployee?.data?.religions)
        const optionsMaritalStatus = mapOptions(dataConstantsEmployee?.data?.maritalStatus)
        const optionsBloodType = mapOptions(dataConstantsEmployee?.data?.bloodType)
        return {
            optionsGrades,
            optionsPersonalDocumentType,
            optionsGender,
            optionsReligions,
            optionsMaritalStatus,
            optionsBloodType,
        }

    }, [dataConstantsEmployee?.data])

    const onSavedAsDraft = () => {
        generateParams().then(params => {
            const onSave = draftId ? updateDraftEmployee : createDraftEmployee
            onSave(params).then(() => {
                dispatch(clearAddEmployeeReducer())
                dispatch(setToast({isShowToast: true, message: 'Employee Saved as Draft !!'}))
                navigate('/employee/prospective-employee')
            }).catch(err => {
                if (err.code === '422') {
                    setShowConfirmSubmit(false)
                    setErrorForm(convertArrayToObject(err.data))
                }
            })
        })
    }

    const params = () => {
        return ({
            ...dataEmployee,
            phone_number: formatPhoneNumber(dataEmployee.phone_number),
            job_title_employees: dataEmployee.job_title_employees.map((item) => item.job_title_id)
        })
    }

    const setUploadProfilePicture = async (newParams) => {
        try {
            const s3Urls = await getUrlProfilePicture({file: dataEmployee?.profile_picture?.name?.replace(/\s/g, '')})
            await uploadFileToS3(s3Urls?.data, dataEmployee?.profile_picture)
            newParams['profile_picture'] = `${s3Urls?.data?.url}${s3Urls?.data?.fields?.key}`
        } catch (err) {
            console.log('upload failed')
        }
    }

    const setUploadPersonalDocument = async (filesName, uploadfiles, uploadedItems, newParams) => {
        try {
            const s3Urls = await getUrlUploadPersonalDocument({files: filesName})
            const apiS3list = s3Urls?.data?.map(async (item, index) => {
                await uploadFileToS3(item, uploadfiles[index])
                uploadedItems.push({...uploadfiles[index], url: `${item?.url}${item?.fields?.key}`})
            })
            await Promise.all(apiS3list)
            newParams['personal_documents'] = uploadedItems
        } catch (err) {
            console.log('upload failed')
        }
    }

    const generateParams = () => {
        return new Promise(async (resolve, reject) => {
            let newParams = {...params()}
            let copyPersonalDocuments = [...dataEmployee?.personal_documents]
            let uploadedItems = []
            let uploadItems = []
            let filesName = []

            copyPersonalDocuments.map(item => {
                if (item?.url?.name) {
                    filesName.push(item?.url?.name?.replace(/\s/g, ''))
                    uploadItems.push(item)
                } else {
                    uploadedItems.push(item)
                }
            })

            if (dataEmployee?.profile_picture?.name) await setUploadProfilePicture(newParams)
            if (filesName?.length) await setUploadPersonalDocument(filesName, uploadItems, uploadedItems, newParams)

            resolve(newParams)
        })
    }

    const onSubmit = () => {
        generateParams().then(params => {
            const onCreateEmployee = draftId ? updateEmployee : createEmployee
            onCreateEmployee(params).then(() => {
                dispatch(clearAddEmployeeReducer())
                dispatch(setToast({isShowToast: true, message: 'Employee Created/Updated !!'}))
                navigate('/employee/prospective-employee')
            })
                .catch(err => {
                    if (err.code === '422') {
                        setShowConfirmSubmit(false)
                        setErrorForm(convertArrayToObject(err.data))
                    }
                })
        })
    }

    const companyNameList = () => {
        let companyName = dataEmployee.job_title_employees.map((item) => item.company_name)
        companyName = [...new Set(companyName)]
        return companyName.join(', ')
    }

    const props = {
        onSavedAsDraft,
        onSubmit,
        onValidateForm: () => validateForm(params()).then(() => setShowConfirmSubmit(true)),
        errorForm,
        canSavedDraft: !!dataEmployee.full_name,
        setShowConfirmSubmit,
        showConfirmSubmit,
        companyNameList: companyNameList()
    }

    return (
        <AddEmployee {...props}>
            <PersonalInformationContainer errorForm={errorForm} />
            <AccountInformationContainer errorForm={errorForm} />
            <JobTitleContainer errorForm={errorForm} />
            <AdditionalInformationContainer
                options={{
                    optionsGrades,
                    optionsPersonalDocumentType,
                    optionsGender,
                    optionsReligions,
                    optionsMaritalStatus,
                    optionsBloodType,
                }}
                selectedAdditional={selectedAdditional}
            />
            <NPWPInformationContainer errorForm={errorForm}/>
            <FamilyInformationContainer />
            <EmergencyContactInformationContainer />
            <EducationInformationContainer optionsGrades={optionsGrades} />
            <WorkExperienceContainer />
            <PersonalDocumentContainer optionsPersonalDocumentType={optionsPersonalDocumentType} />
        </AddEmployee>
    )
}
