import Drawer from "../../commons/drawer";
import icProfilePicture from "../../../assets/images/ic-user-profile-03.svg";

export const PreviewEmployeeStructure = (props) => {
    const {
        visibility,
        onHide,
        jobTitleDetail,
        employeeList = [],
        onClosePreview
    } = props

    return (
        <Drawer visibility={visibility} onHide={onHide} title={'Struktur'}>
            <div className={'flex flex-col gap-3 px-6'}>
                <div className={'font-semibold'}>{jobTitleDetail?.name}</div>
                <p className={'capitalize text-sm text-neutral-60'}>{`${employeeList?.length} total employee`}</p>
                {
                    !!employeeList?.length &&
                    employeeList.map(employee =>
                        <div className="flex px-3 py-4 gap-3 items-center rounded border" key={employee?.id}>
                            <div className="w-9 h-9 overflow-hidden rounded-full border">
                                <img className="w-full aspect-square"
                                     src={employee?.profile_picture || icProfilePicture}
                                     alt='profile'/>
                            </div>
                            <div>
                                <div className="font-semibold mb-px">{employee?.full_name}</div>
                                <div className="text-neutral-70">{employee?.nip}</div>
                            </div>
                        </div>
                    )
                }
            </div>
        </Drawer>
    )
}
