import {createSlice} from "@reduxjs/toolkit";

export const payrollSlice = createSlice({
    name: 'payroll',
    initialState: {
        isShowModalImport: false,
        toast: {
            message: '',
            isShowToast: false
        }
    },
    reducers: {
        setPayrollModalImport: (state, action) => {
            state.isShowModalImport = action.payload
        },
        setPayrollToast: (state, action) => {
            state.toast = {...state.toast, ...action.payload}
        }
    }
})

export const {
    setPayrollModalImport,
    setPayrollToast
} = payrollSlice.actions
const payrollReducer = payrollSlice.reducer

export default payrollReducer
