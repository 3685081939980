import icArrowUp from "assets/images/ic-arrow-up-contained.svg"
import icUserStar from "assets/images/ic-user-profile-star.svg"
import icUserDefault from "assets/images/ic-user-profile.svg"
import Badge from "components/commons/badge"
import Button from "components/commons/button"
import Tooltip from "components/commons/tooltip"
import { ACTION_TYPE } from "constants/constant"
import moment from "moment"

export const TABLE_ACCOUNT_ACTIVATION = {
    name: 'Total Employee',
    column: [
        {
            name: "Name",
            key: "full_name",
            className: "font-medium",
            sortable: true,
            render: ({ item }) => {
                return (
                    <div className={'flex flex-row gap-3 items-center'}>
                        <img className={'w-7 h-7 rounded-full border border-neutral-50'} src={item?.profile_picture || icUserDefault} alt={item?.full_name} />
                        <div>{item?.full_name}</div>
                    </div>
                )
            }
        },
        {
            name: "NIP",
            key: "nip",
            sortable: true,
        },
        {
            name: "Job Title",
            key: "title",
        },
        {
            name: "Role",
            key: "roles"
        },
        {
            name: "Status Kontrak",
            key: "contract_status",
            sortable: true,
            render: ({ value }) => <Badge type={value === 'contract-rejected' ? 'rejected' : value} title={value} />
        },
        {
            name: "Status Karyawan",
            key: "employee_status",
            sortable: true,
            render: ({ value }) => <Badge type={value === 'contract-rejected' ? 'rejected' : value} title={value} />
        },
        {
            name: "Action",
            key: "action",
            classNameHeader: "!text-center w-[150px]",
            render: ({ item, onClickItem }) => (
                <div className="flex justify-center gap-3 mx-auto">
                    <Tooltip
                        position="bottom"
                        content={<Button type="outline" icon={icArrowUp} onClick={() => onClickItem({ item, actionType: ACTION_TYPE.UPDATE_STATUS })} />}
                        text="Update Status"
                    />
                    <Tooltip
                        position="bottom"
                        content={<Button type="outline" icon={icUserStar} onClick={() => onClickItem({ item, actionType: ACTION_TYPE.UPDATE_ROLE })} />}
                        text="Update Role"
                    />
                </div>
            )
        }
    ]
}
