import Button from "../button";
import icArrowRight from '../../../assets/images/ic-align-right-02.svg'

const Drawer = ({visibility, onHide, children, title}) => {
    return (
        <div
            className={
                "fixed overflow-hidden z-20 bg-gray-900 bg-opacity-25 inset-0 transform ease-in-out " +
                (visibility
                    ? " transition-opacity opacity-100 duration-500 translate-x-0"
                    : " opacity-0 translate-x-full  ")
            }
        >
            <div
                className={
                    "min-w-[350px] w-1/4 max-w-lg right-0 absolute bg-white h-full shadow-xl delay-400 duration-500 ease-in-out transition-all transform  " +
                    (visibility ? " translate-x-0 " : " translate-x-full ")
                }
            >
                <div className="relative pb-10 flex flex-col space-y-6 overflow-y-scroll h-full">
                    <div className={"px-6 py-3 flex flex-row justify-between items-center border-b sticky top-0 bg-white"}>
                        <p className={"text-neutral-80 font-bold"}>{title}</p>
                        <Button title={'Tutup'} iconAfter={icArrowRight} type={'outline'} onClick={onHide}/>
                    </div>
                    {children}
                </div>
            </div>
            <section
                className=" w-screen h-full cursor-pointer "
                onClick={onHide}
            ></section>
        </div>
    )
}

export default Drawer
