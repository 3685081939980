import {Alert} from "components/commons/alert"
import {PersonalInfoTab} from "components/employee/employee-detail/employee-detail-tab/PersonalInfoTab"
import {useValidation} from "hooks/useValidation"
import {useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {setToast, updatePersonalDetail} from "reducers/employeeDetailReducer"
import {addEmployeeValidation} from "services/addEmployeeValidation"
import {API_EMPLOYEE} from "services/apiEmployee"
import {useFetch} from "services/useFetch"
import {clearPhoneNumber, convertArrayToObject, formatPhoneNumber, getFileUnit, uploadFileToS3} from "utils/utils"

export const PersonalInfoTabContainer = () => {
    const dispatch = useDispatch()
    const {id, personal_detail} = useSelector(state => state.employeeDetailReducer)
    const [showModal, setShowModal] = useState(false)
    const [dataPersonal, setDataPersonal] = useState()
    const [errorFormApi, setErrorFormApi] = useState({})
    const [profilePicture, setProfilePicture] = useState({
        file: null,
        base64: null,
        name: null,
    })
    const {
        fetching: patchPersonalDetail,
        isLoading: isLoadingSubmit
    } = useFetch({...API_EMPLOYEE.UPDATE_PERSONAL_DETAIL, initPathVariable: id})
    const {fetching: getUrlProfilePicture} = useFetch(API_EMPLOYEE.URL_PROFILE_PICTURE)
    const {errorForm: errorFormValidation, validateForm} = useValidation({
        form: dataPersonal,
        schema: addEmployeeValidation
    })

    const personalInfoConstList = [
        {
            label: 'Nama',
            key: 'full_name',
            required: true
        }, {
            label: 'NIK',
            key: 'id_card_number',
            required: true
        }, {
            label: 'Username',
            key: 'username',
            required: true
        }, {
            label: 'No. KK',
            key: 'family_id_card_number'
        }, {
            label: 'Paspor',
            key: 'passport_number'
        }, {
            label: 'No. HP',
            key: 'phone_number'
        }, {
            label: 'Email Pribadi',
            key: 'personal_email'
        }, {
            label: 'Email Kantor',
            key: 'office_email'
        },
    ]

    const onChangeForm = (e) => {
        const {name, value} = e
        setDataPersonal({...dataPersonal, [name]: value})
    }

    const onShowModal = () => {
        setDataPersonal({
            ...personal_detail,
            phone_number: clearPhoneNumber(personal_detail.phone_number)
        })
        setShowModal(true)
    }

    const onCloseModal = () => {
        setShowModal(false)
    }

    const onSubmit = () => {
        validateForm().then(() => {
            if (!profilePicture?.file) return onSave()
            setUploadProfilePicture()
        })
    }

    const setUploadProfilePicture = async () => {
        try {
            const s3Urls = await getUrlProfilePicture({file: profilePicture?.file?.name?.replace(/\s/g, '')})
            await uploadFileToS3(s3Urls.data, profilePicture?.file)
            const urlProfilePicture = `${s3Urls.data.url}${s3Urls.data.fields.key}`
            onSave(urlProfilePicture)
        } catch (err) {
            console.log('upload failed')
        }
    }

    const onSave = (url = dataPersonal?.profile_picture) => {
        const param = {
            ...dataPersonal,
            profile_picture: url,
            phone_number: formatPhoneNumber(dataPersonal.phone_number)
        }
        patchPersonalDetail(param).then(() => {
            dispatch(updatePersonalDetail(param))
            dispatch(setToast({isShowToast: true, message: 'Personal Info Updated !!'}))
            onCloseModal()
        })
            .catch(err => {
                if (err.code === '422') {
                    setErrorFormApi(convertArrayToObject(err.data))
                }
            })
    }

    const onUploadImage = async (e) => {
        const file = e.target.files[0]
        if (!file) return

        const allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
        if (!allowedExtensions.exec(file?.name)) {
            e.target.value = null
            return Alert({
                icon: 'error',
                title: 'Failed',
                message: 'Format file tidak sesuai',
            })
        }

        if (file?.size > 11000000) {
            e.target.value = null
            return Alert({
                icon: 'error',
                title: 'Failed',
                message: 'File Image terlalu besar (Maksimal 10 MB)',
            })
        }

        const reader = new FileReader()
        reader.onload = () => {
            e.target.value = null
            setProfilePicture({
                file: file,
                base64: reader.result,
                name: `${file.name} ${getFileUnit(file.size)}`
            })
        }
        reader.readAsDataURL(file)
    }

    const onDeletePicture = () => {
        setDataPersonal({...dataPersonal, profile_picture: null})
        setProfilePicture({
            file: null,
            base64: null,
            name: null,
        })
    }

    const props = {
        personalInfoConstList,
        errorForm: {...errorFormValidation, ...errorFormApi},
        profilePicture,
        dataPersonal,
        personalDetail: personal_detail,
        showModal,
        onShowModal,
        onCloseModal,
        onChangeForm,
        onSubmit,
        isLoadingSubmit,
        onUploadImage,
        onDeletePicture,
    }

    return (
        <PersonalInfoTab {...props} />
    )
}
