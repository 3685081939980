import FormModal from "../../commons/form-modal";
import Input from "../../commons/input";
import {getHeadUnitText} from "../../../utils/utils";
import Select from "../../commons/select";

export const CreateManagementForm = (props) => {
    const {
        isShow,
        onHideForm,
        onSubmitForm,
        isFormValid,
        formValue,
        saveValue,
        options,
        onChangeSelectValue,
        activatedCompany
    } = props
    return (
        <FormModal show={isShow}
                   title={`Tambah Data Management`}
                   width={'max-w-[639px]'}
                   onHide={onHideForm}
                   onSubmitForm={onSubmitForm}
                   isDisabled={!isFormValid}
        >
            <div className={"flex flex-col px-6 py-8 gap-[20px]"}>
                <div className="flex flex-row">
                    <div className="w-1/3 text-neutral-70 required">PT</div>
                    <Input className={'flex-1'} name="company" disabled={true} value={activatedCompany?.name}/>
                </div>
                <div className="flex flex-row">
                    <div className="w-1/3 text-neutral-70 capitalize">Management</div>
                    <Select name="head_unit_selected"
                            className='flex-1 capitalize'
                            options={options}
                            placeholder={`Pilih Management`}
                            value={formValue.head_unit_selected}
                            onChange={onChangeSelectValue}
                            optionsClassName={'max-h-[350px]'}
                    />
                </div>
                <div className="flex flex-row">
                    <div className="w-1/3 text-neutral-70 required">Management</div>
                    <Input className={'flex-1'} name="management" placeholder={`Isi nama management`}
                           value={formValue.management} onChange={(e) => saveValue({[e.name]: e.value})}/>
                </div>
            </div>
        </FormModal>
    )
}
