import {METHOD} from "./method";

export const API_WORK_TIME = {
    WORK_TIME_LIST: {
        api: '/v1/hr-adm/work-time/list',
        method: METHOD.GET
    },
    CREATE_WORK_TIME: {
        api: '/v1/hr-adm/work-time',
        method: METHOD.POST
    },
    UPDATE_WORK_TIME: {
        api: '/v1/hr-adm/work-time',
        method: METHOD.PUT
    },
    DELETE_WORK_TIME: {
        api: '/v1/hr-adm/work-time',
        method: METHOD.DELETE
    }
}
