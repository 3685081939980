import clsx from "clsx"
import './pagination.scss'

const Pagination = ({ className, currentPage, totalPage, onChange }) => {
    const DOT_SPACE = '...'

    const getPaginationGenerator = (currentPageNumber, totalPageNumber, offset = 3) => {
        if (currentPage === 0) return []
        const offsetNumber = currentPageNumber <= offset || currentPageNumber > totalPageNumber - offset ? offset : offset - 1
        const numbersList = []
        const numbersListWithDots = []
        if (totalPageNumber <= 1 || totalPageNumber === undefined) return [1]
        numbersList.push(1)
        for (let i = currentPageNumber - offsetNumber; i <= currentPageNumber + offsetNumber; i++) {
            if (i < totalPageNumber && i > 1) {
                numbersList.push(i)
            }
        }
        numbersList.push(totalPageNumber);
        numbersList.reduce((accumulator, currentValue) => {
            if (accumulator === 1) numbersListWithDots.push(accumulator)
            if (currentValue - accumulator !== 1) numbersListWithDots.push(DOT_SPACE)
            numbersListWithDots.push(currentValue)
            return currentValue
        })
        return numbersListWithDots
    }

    return (
        <div className={clsx("avo__pagination", className)}>
            <div className={clsx("page-item", currentPage <= 1 && "disabled")} onClick={() => currentPage > 1 ? onChange(currentPage - 1) : {}}>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M10.8047 2.86128C11.0651 3.12163 11.0651 3.54374 10.8047 3.80409L6.60948 7.99935L10.8047 12.1946C11.0651 12.455 11.0651 12.8771 10.8047 13.1374C10.5444 13.3978 10.1223 13.3978 9.86193 13.1374L5.19526 8.47075C4.93491 8.2104 4.93491 7.78829 5.19526 7.52794L9.86193 2.86128C10.1223 2.60093 10.5444 2.60093 10.8047 2.86128Z" />
                </svg>
            </div>
            <div className="page-wrapper">
                {getPaginationGenerator(currentPage, isNaN(totalPage) ? 0 : totalPage).map((item, index) =>
                    item === DOT_SPACE
                        ? <div key={index} className="out-space">{item}</div>
                        : <div
                            key={index}
                            className={clsx("page-item", currentPage === item && "active")}
                            onClick={() => currentPage !== item ? onChange(item) : {}}>{item}</div>
                )}
            </div>
            <div className={clsx("page-item", currentPage >= totalPage && "disabled")} onClick={() => currentPage < totalPage ? onChange(currentPage + 1) : {}}>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M5.19526 13.1387C4.93491 12.8784 4.93491 12.4563 5.19526 12.1959L9.39052 8.00065L5.19526 3.80539C4.93491 3.54504 4.93491 3.12293 5.19526 2.86258C5.45561 2.60223 5.87772 2.60223 6.13807 2.86258L10.8047 7.52925C11.0651 7.7896 11.0651 8.21171 10.8047 8.47206L6.13807 13.1387C5.87772 13.3991 5.45561 13.3991 5.19526 13.1387Z" />
                </svg>
            </div>
        </div>
    )
}

export default Pagination