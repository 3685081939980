import { Alert } from "components/commons/alert"
import { RemoteWorking } from "components/remote-working/RemoteWorking"
import { TABLE_REQUEST_REMOTE_WORKING } from "configs/remote-working/configTableRemoteWorking"
import { useEffect, useState } from "react"
import { useLocation, useSearchParams } from "react-router-dom"
import {downloadFile, getAllQueryParams, setQueryParams} from "utils/utils"
import {useFetch} from "../../services/useFetch";
import {API_REMOTE_WORKING} from "../../services/apiRemoteWorking";
import moment from "moment/moment";

const OPTION_BULK = {
    APPROVE: 'approve',
    REJECT: 'reject',
}

const TAB_LIST = [
    { id: 'submitted', name: 'submitted' },
    { id: 'approved', name: 'approved' },
    { id: 'rejected', name: 'rejected' },
    { id: 'cancelled', name: 'cancelled' },
]

const PREVIEW_TAB = [
    { id: 'information', name: 'informasi' },
    { id: 'history', name: 'history' },
]

export const RemoteWorkingContainer = () => {
    const location = useLocation()
    const [searchParams, setSearchParams] = useSearchParams()
    const status = searchParams.get('status')
    const defaultQuery = {
        page: 1,
        limit: 20,
        search: '',
        order_by: "created_at",
        order_type: "desc",
        status: TAB_LIST[0].id,
        start_date: moment().startOf('month').format('YYYY-MM-DD'),
        end_date: moment().endOf('month').format('YYYY-MM-DD')
    }
    const query = searchParams.size > 0 ? getAllQueryParams(searchParams) : defaultQuery
    const [selectedEmployeeList, setSelectedEmployeeList] = useState([])
    const [selectedBulkOption, setSelectedBulkOption] = useState()
    const [activePreviewTab, setActivePreviewTab] = useState(PREVIEW_TAB[0].id)
    const [rejectReason, setRejectReason] = useState(null)
    const [toastMessage, setToastMessage] = useState('')
    const [modal, setModal] = useState({
        limit: false,
        export: false,
        toast: false,
        preview: false,
    })
    const { data: remoteWorkingList, fetching: getRemoteWorkingList, isLoading } = useFetch(API_REMOTE_WORKING.REMOTE_WORKING_LIST, defaultQuery)
    const { data: previewRemoteWorking, fetching: getPreviewRemoteWorking} = useFetch(API_REMOTE_WORKING.PREVIEW_REMOTE_WORKING)
    const {fetching: exportRemoteWorking} = useFetch(API_REMOTE_WORKING.EXPORT_REMOTE_WORKING_LIST)
    const {fetching: approveRemoteWorking} = useFetch(API_REMOTE_WORKING.APPROVE_REMOTE_WORKING)
    const {fetching: rejectRemoteWorking} = useFetch(API_REMOTE_WORKING.REJECT_REMOTE_WORKING)

    useEffect(() => {
        fetchRemoteWorkingList({...query})
    }, [location.search])

    const onChangeTab = (status) => {
        const params = { ...defaultQuery, status: status.id }
        setSearchParams(params, { replace: true })
    }

    const onChangeQuery = (queryParams) => {
        const params = setQueryParams({ ...query, ...queryParams })
        setSearchParams(params, { replace: true })
    }

    const fetchRemoteWorkingList = (item = query) => {
        getRemoteWorkingList(item)
    }

    const onExport = () => {
        const combineObj = query?.start_date ? query : {...defaultQuery, ...query}
        const {page, limit, ...dataFilterRest} = combineObj
        const payload = {...dataFilterRest, remote_working_request_ids: selectedEmployeeList.map(item => item.id)}
        exportRemoteWorking(payload, '', {}, 'blob')
            .then(response => {
                downloadFile(null, response, `Remote-Working-Export-${moment().format('DD-MM-YYYY')}.xlsx`)
                setToastMessage("Anda berhasil export data remote working")
                setModal({...modal, export: false, toast: true})
            })
    }

    const onBulkUpdate = (option) => {
        if (selectedEmployeeList.length === 0 && !modal?.preview) {
            return Alert({
                icon: 'warning',
                title: 'Silakan pilih employee terlebih dahulu.',
                autoClose: true,
            })
        }
        setSelectedBulkOption(option)
        setModal({ ...modal, bulk_update: true })
    }

    const onPreview = (item) => {
        getPreviewRemoteWorking({}, item?.id)
        setActivePreviewTab(PREVIEW_TAB[0].id)
        setModal({ ...modal, preview: true })
    }

    const getPromiseAPI = (type, bodyRequest) => {
        if (type === OPTION_BULK?.APPROVE) return approveRemoteWorking(bodyRequest)
        return rejectRemoteWorking(bodyRequest)
    }

    const errorMessage = (data = []) => {
        let message = ''
        data?.forEach(item => {
            message += `\nSomething happen in ID ${item?.remote_working_request_id}:\nStatus: ${item?.success ? 'Success' : 'Failed'}\nMessage: ${item?.message}\n`
        })

        return message
    }

    const onUpdateStatusRequest = (status, id = null) => {
        const bodyRequest = {
            remote_working_request_ids: id ? [id] : selectedEmployeeList.map(item => item.id)
        }
        if (status === OPTION_BULK?.REJECT) bodyRequest['reject_reason'] = rejectReason

        getPromiseAPI(status, bodyRequest)
            .then(() => {
                setToastMessage(`Remote Working ${status === OPTION_BULK?.APPROVE ? 'Approved !!' : 'Rejected !!'}`)
                setModal({...modal, bulk_update: false, preview: false, toast: true})
                fetchRemoteWorkingList()
            }).catch(err => {
            if (err?.code === '400-U001') {
                Alert({
                    icon: 'error',
                    title: err?.message,
                    message: errorMessage(err?.data),
                })
            }
            setModal({ ...modal, bulk_update: false, preview: false})
        })
    }

    const props = {
        tabList: TAB_LIST,
        dataEmployee: remoteWorkingList?.data,
        dataPreview: previewRemoteWorking?.data,
        status: status || TAB_LIST[0].id,
        optionBulk: OPTION_BULK,
        configTable: TABLE_REQUEST_REMOTE_WORKING,
        previewTab: PREVIEW_TAB,
        isLoading,
        query,
        activePreviewTab,
        setActivePreviewTab,
        selectedEmployeeList,
        selectedBulkOption,
        modal,
        toastMessage,
        rejectReason,
        setRejectReason,
        onChangeTab,
        onChangeQuery,
        onSelectItem: (employeeList) => setSelectedEmployeeList([...employeeList]),
        onExport,
        onBulkUpdate,
        onPreview,
        onSetModal: (e) => setModal({ ...modal, ...e }),
        onUpdateStatusRequest
    }

    return (
        <RemoteWorking {...props} />
    )
}
