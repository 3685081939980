import clsx from "clsx";
import './yearpicker.scss';
import {useEffect, useState} from "react";
import icLeft from "../../../assets/images/ic-left.svg";
import icRight from "../../../assets/images/ic-right.svg";
import moment from "moment/moment";

const YearPicker = ({className, name, value, maxYear, onChange}) => {
    const END_YEAR = 1970
    const LIMIT = 12
    const [currentDisplayedIndex, setCurrentDisplayedIndex] = useState(0)
    const [displayedYears, setDisplayedYears] = useState([])
    const [selectedYear, setSelectedYear] = useState('')

    const getYearsList = (endYear) => {
        const years = []
        const startYear = maxYear ? (moment().year() + maxYear) : moment().year()
        for (let year = startYear; year >= endYear; year--) {
            years.push(year)
        }

        return years?.reverse()
    }

    const constructYears = () => {
        const years = getYearsList(END_YEAR)
        const items = []
        for (let i = 0; i < years?.length; i += LIMIT) {
            items.push(years?.slice(i, i + LIMIT))
        }

        return items
    }

    const getDisplayYears = (index = 0) => {
        const displayedList = constructYears()
        return displayedList?.[index]
    }

    const setDisplayYears = (index) => {
        setDisplayedYears(getDisplayYears(index))
        setCurrentDisplayedIndex(index)
    }

    const onClickArrow = (type, index) => {
        let newIndex = index
        if (!getDisplayYears(index)) newIndex = type === "previous" ? constructYears()?.length - 1 : 0
        setDisplayYears(newIndex)
    }

    const onClickItem = (year) => {
        if (onChange) onChange({name, value: year})
        setSelectedYear(year)
    }

    useEffect(() => {
        let newIndex = constructYears()?.length - 1
        if (value) newIndex = constructYears()?.findIndex(item => item?.includes(value))
        setDisplayYears(newIndex)
        setSelectedYear(value || '')
    }, [value])

    return (
        <div className={clsx("avo__yearpicker", className)}>
            <div className="header-wrapper">
                <img src={icLeft} className="cursor-pointer"
                     onClick={() => onClickArrow("previous", currentDisplayedIndex - 1)}/>
                <p className="header-title">{`${displayedYears?.at(0)} - ${displayedYears?.at(-1)}`}</p>
                <img src={icRight} className="cursor-pointer"
                     onClick={() => onClickArrow("next", currentDisplayedIndex + 1)}/>
            </div>
            <div className="body-wrapper">
                {
                    displayedYears?.map((item, index) =>
                        <div key={index} className={clsx("item-wrapper", selectedYear === item && "selected")}
                             onClick={() => onClickItem(item)}>
                            {item}
                        </div>
                    )
                }
            </div>
        </div>
    )
}

export default YearPicker
