import IcArrowDown from "../../../assets/images/ic-chev-down.svg"
import {setPreviewEmployeeStructure} from "../../../reducers/organizationStructureReducer";
import {useDispatch} from "react-redux";

export const ChildNode = ({nodeData}) => {
    const dispatch = useDispatch()
    const selectNode = () => {
        const newNodeData = JSON.stringify(nodeData)
        dispatch(setPreviewEmployeeStructure({visibility: true, childNode: JSON.parse(newNodeData)}))
    };

    if (!!nodeData.children) {
        return (
            <div className={"border-4 border-neutral-20 rounded-xl text-sm w-40"}>
                <div className={"flex flex-col rounded-lg border border-primary-50 shadow-2xl shadow-neutral-20"}
                     onClick={selectNode}>
                    <div className={"p-2"}>
                        <p className={"font-semibold"}>{nodeData.name}</p>
                    </div>
                    {
                        nodeData.children.length > 0 &&
                        <div className={"flex flex-row justify-between p-2 bg-neutral-20 rounded-b-lg"}>
                            <div/>
                            <p className={"text-primary-50 font-medium "}>{nodeData.children.length} Job Title</p>
                            <img src={IcArrowDown}
                                 alt={'arrow-icon'}
                                 className={" border rounded-full bg-white flex col items-center justify-center"}/>

                        </div>
                    }

                </div>
            </div>
        )
    } else {
        return (
            <div className={"border-t-4 border-r border-l border-b-4 border-neutral-20 rounded-md text-sm w-40"}>
                <div className={"flex flex-col rounded-md py-2"}
                     onClick={selectNode}>
                    <p>{nodeData.name}</p>
                </div>
            </div>
        )
    }

}
