import icPlus from "assets/images/ic-plus-01-primary.svg"
import icTrash from "assets/images/ic-trash-04.svg"
import icProfilePicture from "assets/images/ic-user-profile-03.svg"
import icPersonalInformation from "assets/images/ic-users-profiles-up.svg"
import clsx from "clsx"
import Button from "components/commons/button"
import ErrorMessage from "components/commons/error-message"
import Input from "components/commons/input"
import {useExpandable} from "hooks/useExpandable"
import {FormHeader} from "./FormHeader"

export const PersonalInformation = ({
                                        form,
                                        errorForm,
                                        onChangeForm,
                                        profilePicture,
                                        onUploadImage,
                                        onDeletePicture
                                    }) => {
    const {isExpanded, elementRef, toggleExpand} = useExpandable({verticalPadding: 24, expanded: true})

    return (
        <div className="bg-white border-y border-neutral-20">
            <FormHeader title="Informasi Pribadi" icon={icPersonalInformation} expanded={isExpanded}
                        onClick={toggleExpand}/>
            <div ref={elementRef} className="expandable px-8">
                <div className="font-semibold text-md text-primary-50 mb-5">{'Photo Profile'}</div>
                <div className="flex gap-5">
                    <div
                        className="w-[72px] h-[72px] flex items-center justify-center overflow-hidden rounded-full border bg-neutral-20">
                        <img
                            className={clsx("aspect-square", profilePicture?.base64 || form?.profile_picture ? 'w-full' : 'w-9 h-9')}
                            src={profilePicture?.base64 || form?.profile_picture || icProfilePicture} alt='profile'/>
                    </div>
                    <div className={clsx("flex flex-col", profilePicture?.name ? "justify-between" : "justify-center")}>
                        <span className="text-md">{profilePicture?.name}</span>
                        <input className={'hidden'} accept=".jpg,.jpeg,.png" id='profile-picture' type={'file'}
                               onChange={onUploadImage}/>
                        <div className="flex gap-2">
                            {profilePicture?.base64 || form?.profile_picture ?
                                <label className="avo__button outline cursor-pointer" type="button"
                                       htmlFor={'profile-picture'}>
                                    <div className="btn-content"> {'Ubah'} </div>
                                </label>
                                :
                                <label className="avo__button outline cursor-pointer" type="button"
                                       htmlFor={'profile-picture'}>
                                    <div className="btn-content">
                                        <img src={icPlus} alt='ubah'/>
                                        <span>{'Upload Photo'}</span>
                                    </div>
                                </label>
                            }
                            {(profilePicture?.base64 || form?.profile_picture) &&
                                <Button className="w-[100px]" type="outline" title="Hapus" icon={icTrash}
                                        onClick={onDeletePicture}/>}
                        </div>
                    </div>
                </div>
                <div className="font-semibold text-md text-primary-50 mb-5 mt-6">{'Informasi Pribadi'}</div>
                <div className="grid gap-5 lg:grid-cols-2 lg:gap-x-16">
                    <div className="flex gap-3">
                        <div className="w-1/3 text-neutral-70 required mt-2">{'Nama'}</div>
                        <div className="flex-1">
                            <Input name="full_name" value={form?.full_name} maxLength={60} pattern={/^[A-Za-z' ]+$/}
                                   onChange={onChangeForm}/>
                            <ErrorMessage className="mt-2" message={errorForm?.full_name}/>
                        </div>
                    </div>
                    <div className="flex gap-3">
                        <div className="w-1/3 text-neutral-70 required mt-2">{'Username'}</div>
                        <div className="flex-1">
                            <Input name="username" value={form?.username} maxLength={60} onChange={onChangeForm}/>
                            <ErrorMessage className="mt-2" message={errorForm?.username}/>
                        </div>
                    </div>
                    <div className="flex gap-3">
                        <div className="w-1/3 text-neutral-70 required mt-2">{'NIK'}</div>
                        <div className="flex-1">
                            <Input name="id_card_number" type="number" value={form?.card_detail?.id_card_number}
                                   maxLength={16} onChange={onChangeForm}/>
                            <ErrorMessage className="mt-2" message={errorForm?.id_card_number}/>
                        </div>
                    </div>
                    <div className="flex gap-3">
                        <div className="w-1/3 text-neutral-70 mt-2">{'No. KK'}</div>
                        <div className="flex-1">
                            <Input name="family_id_card_number" type="number"
                                   value={form?.card_detail?.family_id_card_number} maxLength={16}
                                   onChange={onChangeForm}/>
                            <ErrorMessage className="mt-2" message={errorForm?.family_id_card_number}/>
                        </div>
                    </div>
                    <div className="flex gap-3">
                        <div className="w-1/3 text-neutral-70 required mt-2">{'No. Hp'}</div>
                        <div className="flex-1">
                            <Input addonBefore={'+62'} name="phone_number" type="tel" value={form?.phone_number}
                                   maxLength={14} onChange={onChangeForm}/>
                            <ErrorMessage className="mt-2" message={errorForm?.phone_number}/>
                        </div>
                    </div>
                    <div className="flex items-center gap-3">
                        <div className="w-1/3 text-neutral-70">{'No. Paspor'}</div>
                        <div className="flex-1">
                            <Input className="flex-1" name="passport_number" value={form?.card_detail?.passport_number}
                                   maxLength={30} onChange={onChangeForm}/>
                            <ErrorMessage className="mt-2" message={errorForm?.passport_number}/>
                        </div>
                    </div>
                    <div className="flex gap-3">
                        <div className="w-1/3 text-neutral-70 required mt-2">{'Email Pribadi'}</div>
                        <div className="flex-1">
                            <Input name="personal_email" value={form?.personal_email} onChange={onChangeForm}/>
                            <ErrorMessage className="mt-2" message={errorForm?.personal_email}/>
                        </div>
                    </div>
                    <div className="flex items-center gap-3">
                        <div className="w-1/3 text-neutral-70 mt-2">{'Email Kantor'}</div>
                        <div className="flex-1">
                            <Input className="flex-1" name="office_email" value={form?.office_email}
                                   onChange={onChangeForm}/>
                            <ErrorMessage className="mt-2" message={errorForm?.office_email}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
