import Spinner from "../../../commons/spinner";
import ProgressBar from "../../../commons/progress-bar";
import icDocumentImportSuccess from "../../../../assets/images/ic-document-import-succes.svg";
import Button from "../../../commons/button";
import icUploadWhite from "../../../../assets/images/ic-upload-white.svg"

export const ValidationGrade = ({percentage, isComplete, errorDetail, onImportData}) => {
    return (
        <>
            {
                !isComplete ?
                    <div className={"w-full flex flex-col items-center text-neutral-80 space-y-8 px-40 py-16"}>
                        <Spinner className={"w-10 h-10 border-primary-50"}/>
                        <div className={"flex flex-col items-center space-y-2"}>
                            <p className={"font-bold text-lg"}>Validate Data</p>
                            <p>Your Grade data are now being Validate</p>
                        </div>
                        <div className={"w-full flex flex-col items-center space-y-5 pt-8"}>
                            <ProgressBar percentage={percentage}/>
                            <p className={"font-bold text-lg text-primary-60"}>Progress in {percentage}%</p>
                        </div>
                    </div> :
                    errorDetail.length ?
                        <div className="w-fit">
                            <div className={"flex flex-col justify-center items-center space-y-6"}>
                                <p className={"font-bold text-lg text-red-60"}>Error Detail</p>
                                <div className={"h-[250px] space-y-6 overflow-scroll"}>
                                    {
                                        errorDetail.map((item, index) => (
                                            <div key={index}>
                                                <span>Something error in row {item?.row}, messages:</span>
                                                {
                                                    Object.entries(item?.errors).map(([key, value]) =>
                                                        <li key={key}
                                                            className="capitalize">{key.replaceAll('_', ' ')}: {value}</li>
                                                    )
                                                }
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                        :
                        <div className="w-full">
                            <div className={"flex flex-col items-center justify-center px-40 py-16"}>
                                <p className="font-bold">Berhasil Validasi Data</p>
                                <img src={icDocumentImportSuccess} alt="ic-document-import-success"
                                     className="mx-auto"/>
                            </div>
                            <div className={"flex justify-end px-6 pt-6 border-t"}>
                                <Button className="w-[132px]" type="primary" title="Import Data" icon={icUploadWhite}
                                        onClick={onImportData}/>
                            </div>
                        </div>
            }
        </>
    )
}
