export const formBenefitsRepresentativeValidation = {
    schedule_category_id: {
        required: "Tipe jam kerja tidak boleh kosong"
    },
    grade_id: {
        required: "Grade tidak boleh kosong"
    },
    non_jakarta_based: {
        required: "Tunjangan non DKI Jakarta tidak boleh kosong"
    },
    jakarta_based: {
        required: "Tunjangan DKI Jakarta tidak boleh kosong"
    }
}

export const formAdditionalIncentiveValidation = {
    grade_id: {
        required: "Grade tidak boleh kosong"
    },
    min_incentive: {
        required: "Additional Incentive Minimum tidak boleh kosong"
    }
}

export const formGovernmentOvertimeValidation = {
    schedule_category_id: {
        required: "Tipe jam kerja tidak boleh kosong"
    },
    duration: {
        required: "Durasi lembur tidak boleh kosong"
    },
    benefit_on_working_day: {
        required: "Benefit hari kerja tidak boleh kosong"
    },
    benefit_on_holiday: {
        required: "Benefit hari libur tidak boleh kosong"
    }
}
