import Input from "../../../commons/input";
import ErrorMessage from "../../../commons/error-message";
import Button from "../../../commons/button";

export const ChangePasswordTab = (
    {
        form,
        onGeneratePassword,
        onChangeForm,
        onSubmit,
        isUpdating,
        errorForm
    }) => {

    return (
        <>
            <p className={"text-neutral-80 font-semibold text-md mt-2 mb-8"}>Ubah Password</p>
            <div className={"w-5/6 flex flex-col gap-5"}>
                <div className="flex">
                    <div className={"w-1/4 font-medium text-neutral-70 mt-2 required"}>New Password</div>
                    <div className="w-2/4">
                        <Input
                            name="new_password"
                            placeholder="Isi password"
                            type="password"
                            value={form?.new_password}
                            onChange={onChangeForm}
                        />
                        <ErrorMessage className="mt-2" message={errorForm?.new_password}/>
                    </div>
                    <div className={"flex-1 ml-3"}>
                        <Button type="outline" title="Generate Password" onClick={onGeneratePassword}/>
                    </div>
                </div>
                <div className="flex">
                    <div className={"w-1/4 font-medium text-neutral-70 mt-2 required"}>New Password Confirmation</div>
                    <div className="w-2/4">
                        <Input
                            name="new_password_confirmation"
                            placeholder="Isi konfirmasi password"
                            type="password"
                            value={form?.new_password_confirmation}
                            onChange={onChangeForm}
                        />
                        <ErrorMessage className="mt-2" message={errorForm?.new_password_confirmation}/>
                    </div>
                </div>
                <div className="flex">
                    <div className="w-1/4"/>
                    <div className={"w-2/4 flex justify-end"}>
                        <Button className="w-32" title="Simpan" loading={isUpdating} onClick={onSubmit}/>
                    </div>
                </div>
            </div>
        </>
    )
}
