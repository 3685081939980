import icAddSquare from "assets/images/id-add-square.svg"
import Button from "components/commons/button"
import DeleteModal from "components/commons/delete-modal"
import FormModal from "components/commons/form-modal"
import Input from "components/commons/input"
import Select from "components/commons/select"
import Table from "components/commons/table"
import {ACTION_TYPE} from "../../../constants/constant";
import ErrorMessage from "../../commons/error-message";
import Toast from "../../commons/toast";

export const AdditionalIncentiveSetting = (
    {
        configTable,
        dataAdditionalIncentive,
        query,
        optionsGrade,
        form,
        modal,
        actionType,
        errorForm,
        isShowToast,
        toastMessage,
        clearToast,
        onChangePage,
        onActionRow,
        onDelete,
        onSetModal,
        onChangeForm,
        onSubmitForm,
        isLoadingTable,
        isCreating,
        isUpdating,
        isDeleting
    }) => {

    return (
        <>
            <div className="flex items-center justify-between p-6">
                <div className="font-semibold text-md text-neutral-80">Pengaturan Additional Incentive</div>
                <Button title="Create Additional Incentive" icon={icAddSquare} onClick={() => onActionRow({actionType: ACTION_TYPE.CREATE})}/>
            </div>
            <Table
                className="my-3"
                classNameHeader="first:!pl-6 last:!pr-6"
                classNameRow="first:!pl-6 last:!pr-6"
                name={configTable.name}
                data={dataAdditionalIncentive?.result}
                columns={configTable.column}
                size={query?.limit}
                currentPage={query?.page}
                totalPage={dataAdditionalIncentive?.total_page}
                totalResult={dataAdditionalIncentive?.total_result}
                onChangePage={onChangePage}
                onClickItem={onActionRow}
                loading={isLoadingTable}
            />
            <DeleteModal
                text="Apakah anda yakin ingin menghapus data additional incentive?"
                show={modal.delete}
                onHide={() => onSetModal({delete: false})}
                onDelete={onDelete}
                isLoading={isDeleting}
            />
            {
                modal?.form &&
                <FormModal
                    title={`${actionType === ACTION_TYPE.CREATE ? 'Create' : 'Update'} Additional Incentive`}
                    show={modal?.form}
                    width="max-w-[640px]"
                    onHide={() => onSetModal({form: false})}
                    isLoading={isCreating || isUpdating}
                    onSubmitForm={onSubmitForm}
                >
                    <div className="flex flex-col space-y-5 px-8 py-6">
                        <div className="flex gap-3">
                            <div className="w-1/3 font-medium text-neutral-70 mt-2 required">Grade</div>
                            <div className="flex-1">
                                <Select
                                    name="grade_id"
                                    className="flex-1"
                                    optionsClassName="max-h-40"
                                    placeholder="Pilih grade"
                                    options={optionsGrade}
                                    value={form?.grade_id}
                                    onChange={onChangeForm}
                                />
                                <ErrorMessage className="mt-2" message={errorForm?.grade_id}/>
                            </div>
                        </div>
                        <div className="flex gap-3">
                            <div className="w-1/3 font-medium text-neutral-70 required">Additional Incentive Minimum</div>
                            <div className="flex-1">
                                <Input
                                    name="min_incentive"
                                    className="flex-1"
                                    addonBefore="Rp"
                                    type="currency"
                                    value={form?.min_incentive}
                                    onChange={onChangeForm}
                                />
                                <ErrorMessage className="mt-2" message={errorForm?.min_incentive}/>
                            </div>
                        </div>
                        <div className="flex items-center gap-3">
                            <div className="w-1/3 font-medium text-neutral-70">Additional Incentive Average</div>
                            <Input
                                name="avg_incentive"
                                className="flex-1"
                                addonBefore="Rp"
                                type="currency"
                                value={form?.avg_incentive}
                                onChange={onChangeForm}
                            />
                        </div>
                        <div className="flex items-center gap-3">
                            <div className="w-1/3 font-medium text-neutral-70">Additional Incentive Maximum</div>
                            <Input
                                name="max_incentive"
                                className="flex-1"
                                addonBefore="Rp"
                                type="currency"
                                value={form?.max_incentive}
                                onChange={onChangeForm}
                            />
                        </div>
                    </div>
                </FormModal>
            }
            <Toast
                show={isShowToast}
                message={toastMessage}
                onClose={() => clearToast()}
            />
        </>
    )
}
