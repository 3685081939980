import {WorkUnitSetting} from "components/organization-structure-management/work-unit-setting/WorkUnitSetting";
import {useDispatch, useSelector} from "react-redux";
import {
    setJobUnitDetail,
    setModal,
    setOptions,
    setSelectedTab,
    setToast,
    setTotalData
} from "reducers/organizationStructureReducer";
import {useFetch} from "services/useFetch";
import {API_JOB_UNIT} from "services/apiJobUnit";
import {
    constructOptionsSelect,
    downloadFile,
    getAllQueryParams,
    getHeadUnitText,
    setQueryParams
} from "utils/utils";
import moment from "moment/moment";
import {useEffect} from "react";
import {ACTION_TYPE} from "constants/constant";
import {Alert} from "components/commons/alert";
import icAlert from "assets/images/ic-alert-triangle.svg";
import {useLocation, useSearchParams} from "react-router-dom";
import {WorkUnitFormContainer} from "./WorkUnitFormContainer";
import {EditWorkUnitFormContainer} from "./EditWorkUnitFormContainer";
import Toast from "../../../components/commons/toast";

const INITIAL_PARAMS = {
    page: 1,
    search: '',
    limit: 20,
}

const tabList = [
    {id: 'management', name: 'Management', key: 'management'},
    {id: 'department', name: 'Department', key: 'department'},
    {id: 'division', name: 'Divisi', key: 'division'},
    {id: 'sub-division', name: 'Sub Divisi', key: 'sub-division'}
]

export const WorkUnitSettingContainer = () => {
    const dispatch = useDispatch()
    const location = useLocation()
    const [searchParams, setSearchParams] = useSearchParams();
    const query = searchParams.size > 0 ? getAllQueryParams(searchParams) : INITIAL_PARAMS
    const {toast, selectedTab, modal, jobUnitDetail} = useSelector(state => state.organizationStructureReducer)
    const tab = searchParams.get('tab') || 'management'
    const {data: jobUnitResponse, fetching: getJobUnitListApi} = useFetch(API_JOB_UNIT.LIST_JOB_UNIT)
    const {fetching: getOptionsJobUnitListApi} = useFetch(API_JOB_UNIT.LIST_OPTION_JOB_UNIT)
    const {fetching: deleteJobUnitApi} = useFetch(API_JOB_UNIT.DELETE_JOB_UNIT)
    const {fetching: exportJobUnitApi} = useFetch(API_JOB_UNIT.EXPORT_JOB_UNIT)

    useEffect(() => {
        fetchJobUnitList()
    }, [location.search])

    const fetchJobUnitList = () => {
        const findTab = tabList.find(item => item.key === tab)
        dispatch(setSelectedTab(findTab))
        getJobUnitListApi(query, tab)
            .then(res => {
                dispatch(setTotalData(res?.data?.total_result))
            })
    }

    const clearToast = () => {
        dispatch(setToast({isShowToast: false, message: ''}))
    }

    const actionButtonHandler = (type, item) => {
        dispatch(setJobUnitDetail(item))
        if (type === ACTION_TYPE.DELETE) {
            dispatch(setModal({[type.toLowerCase()]: true}))
        } else {
            editButtonHandler(type.toLowerCase())
        }
    }

    const editButtonHandler = (typeModal) => {
        getOptionsJobUnitListApi({}, getHeadUnitText[selectedTab.key])
            .then(response => {
                let opt = constructOptionsSelect(response.data, 'name', 'id')
                dispatch(setOptions(opt))
                dispatch(setModal({[typeModal]: true}))
            })
    }

    const deleteModalHide = () => dispatch(setModal({delete: false}))

    const onDelete = () => {
        deleteJobUnitApi({}, `${selectedTab.key}/${jobUnitDetail.id}`)
            .then(() => {
                deleteModalHide()
                fetchJobUnitList()
                dispatch(setToast({isShowToast: true, message: 'Work Unit Deleted !!'}))
            })
            .catch(err => {
                if (err.code === '400-D006') {
                    deleteModalHide()
                    Alert({
                        icon: icAlert,
                        title: 'Failed',
                        message: err.message,
                        autoClose: true,
                        interval: 3000
                    })
                }
            })
    }

    const onShowModal = (key, value) => dispatch(setModal({[key]: value}))

    const buttonAddHandler = () => {
        getOptionsJobUnitListApi({}, getHeadUnitText[selectedTab.key])
            .then(response => {
                let opt = constructOptionsSelect(response.data, 'name', 'id')
                dispatch(setOptions(opt))
                onShowModal('form', true)
            })
    }

    const onExportHandler = () => {
        exportJobUnitApi({search: query.search}, selectedTab.key, {}, 'blob')
            .then(res => {
                downloadFile(null, res, `${selectedTab.name}-List-Export-${moment().format('DD-MM-YYYY')}.xlsx`)
                onShowModal('export', false)
                dispatch(setToast({isShowToast: true, message: 'Work Unit Exported !!'}))
            })
    }

    const onChangeTab = (tab) => {
        const params = setQueryParams({ ...INITIAL_PARAMS, tab: tab.key })
        setSearchParams(params, { replace: true })
    }

    const onChangeQuery = (queryParams) => {
        const params = setQueryParams({...query, ...queryParams})
        setSearchParams(params, {replace: true})
    }

    const props = {
        onChangeTab,
        tabList,
        tab,
        selectedTab,
        modal,
        onShowModal,
        buttonAddHandler,
        onExportHandler,
        jobUnitResponse,
        actionButtonHandler,
        deleteModalHide,
        onDelete,
        onChangeQuery,
        query
    }

    return (
        <>
            <WorkUnitSetting {...props}/>
            <WorkUnitFormContainer fetchJobUnitList={fetchJobUnitList}/>
            <EditWorkUnitFormContainer fetchJobUnitList={fetchJobUnitList}/>
            <Toast
                show={toast?.isShowToast}
                message={toast?.message}
                onClose={() => clearToast()}
            />
        </>
    )

}
