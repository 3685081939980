import { METHOD } from "./method";

export const API_DAY_OFF = {
    DAY_OFF_LIST: {
        api: '/v1/hr-adm/day-off/list',
        method: METHOD.POST
    },
    DAY_OFF_EXPORT: {
        api: '/v1/hr-adm/day-off/export',
        method: METHOD.POST
    },
    DELETE_DAY_OFF: {
        api: '/v1/hr-adm/day-off',
        method: METHOD.DELETE
    },
    CREATE_DAY_OFF: {
        api: '/v1/hr-adm/day-off',
        method: METHOD.POST
    },
    UPDATE_DAY_OFF: {
        api: '/v1/hr-adm/day-off',
        method: METHOD.PUT
    }
}
