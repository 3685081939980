import {useEffect} from "react";
import {useNavigate} from "react-router-dom";

export const GeneralCreatePayrollContainer = () => {
    const navigate = useNavigate()

    useEffect(() => {
        navigate('/payroll/create-payroll/pilih-bulan-payroll')
    }, [])

    return (
        <></>
    )
}
