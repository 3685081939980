import Button from "../../components/commons/button";
import icEdit04Primary from "../../assets/images/ic-edit-04-primary.svg";
import {ACTION_TYPE} from "../../constants/constant";
import icTrash01Red from "../../assets/images/ic-trash-01-red.svg";

export const TABLE_WORK_TIME_SETTING = {
    name: 'Total Kategori Jam Kerja',
    column: [
        {
            name: "Nama Kategori Jam kerja",
            key: "category_name",
            className: "font-bold capitalize"
        },
        {
            name: "Type Jam Kerja",
            key: "type",
            className: "capitalize"
        },
        {
            name: "Senin",
            key: "monday",
            render: ({value}) => <div>{value?.clock_in_time} - {value?.clock_out_time}</div>
        },
        {
            name: "Selasa",
            key: "tuesday",
            render: ({value}) => <div>{value?.clock_in_time} - {value?.clock_out_time}</div>
        },
        {
            name: "Rabu",
            key: "wednesday",
            render: ({value}) => <div>{value?.clock_in_time} - {value?.clock_out_time}</div>
        },
        {
            name: "Kamis",
            key: "thursday",
            render: ({value}) => <div>{value?.clock_in_time} - {value?.clock_out_time}</div>
        },
        {
            name: "Jumat",
            key: "friday",
            render: ({value}) => <div>{value?.clock_in_time} - {value?.clock_out_time}</div>
        },
        {
            name: "Sabtu",
            key: "saturday",
            render: ({value}) => <div>{value?.clock_in_time} - {value?.clock_out_time}</div>
        },
        {
            name: "Minggu",
            key: "sunday",
            render: ({value}) => <div>{value?.clock_in_time} - {value?.clock_out_time}</div>
        },
        {
            name: "Action",
            key: "action",
            classNameHeader: "!text-center",
            render: ({ item, onClickItem }) => (
                <div className="flex space-x-3 justify-center">
                    <Button type="outline" icon={icEdit04Primary} onClick={() => onClickItem({item, actionType: ACTION_TYPE.EDIT})}/>
                    <Button type="danger" icon={icTrash01Red} onClick={() => onClickItem({id: item?.id, actionType: ACTION_TYPE.DELETE})}/>
                </div>
            )
        }
    ]
}

export const INITIAL_DATA_FILTER = {
    page: 1,
    limit: 20
}
