import Button from "../../commons/button";
import Modal from "../../commons/modal";
import icCloseSquare from "../../../assets/images/ic-close-square.svg";
import {Document, Page, pdfjs} from 'react-pdf'
import {useEffect, useState} from "react";
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import Spinner from "../../commons/spinner";
import {downloadFile} from "../../../utils/utils";
import icDownloadPrimary from "../../../assets/images/ic-download-primary.svg";

export const ModalDocument = ({show, onHide, src}) => {
    const [numPages, setNumPages] = useState([])
    const checkImageExtensions = /(\.jpg|\.jpeg|\.png)$/i;

    useEffect(() => {
        pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`
    })

    const onDocumentLoadSuccess = (e) => {
        const arrayPages = []
        for (let i = 1; i < e._pdfInfo.numPages + 1; i++) {
            arrayPages.push(i)
        }
        setNumPages(arrayPages)
    }

    return (
        <Modal
            dialogClassName="w-1/2 max-w-[80%]"
            contentClassName="rounded-xl"
            show={show}
            onHide={onHide}
        >
            <div className={"px-6 py-3 flex flex-row items-center justify-between border-b"}>
                <p className={"font-semibold text-md"}>Preview Document</p>
                <Button type="outline" shape="circle" size="small" icon={icCloseSquare}
                        onClick={onHide}/>
            </div>
            <div className={"overflow-scroll flex justify-center"}>
                {
                    checkImageExtensions.exec(src) ?
                        <img src={src} alt="file-image"/>
                        :
                        <Document file={src} onLoadSuccess={onDocumentLoadSuccess}
                                  loading={<Spinner className={"w-5 h-5 m-10 border-primary-50"}/>}>
                            {
                                numPages.map((item, index) => (
                                    <Page width={700} pageNumber={item} key={index}/>
                                ))
                            }
                        </Document>
                }
            </div>
            <div className={"px-6 py-3 flex-1 space-x-2 flex border-t justify-between"}>
                <Button className="w-[132px]" type="outline" title="Download" icon={icDownloadPrimary} onClick={() => downloadFile(src, null, null, '_blank')}/>
                <Button className="w-[132px]" title="Tutup" onClick={onHide}/>
            </div>
        </Modal>
    )
}
