import {RegisteredEmployee} from "../../../components/management-contract/create-contract/RegisteredEmployee";
import {useFetch} from "../../../services/useFetch";
import {API_CONTRACT} from "../../../services/apiContract";
import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {setModal} from "../../../reducers/contractReducer";
import {useLocation, useSearchParams} from "react-router-dom";
import {getAllQueryParams, setQueryParams} from "../../../utils/utils";

export const RegisteredEmployeeContainer = () => {
  const location = useLocation()
  const dispatch = useDispatch()
  const {body: params, modal} = useSelector(state => state.contractReducer)
  const {data: employeeListResponse, fetching: getEmployeeListApi} = useFetch(API_CONTRACT.EMPLOYEE_LIST)
  const [searchParams, setSearchParams] = useSearchParams()
  const query = searchParams.size > 0 ? getAllQueryParams(searchParams) : {...params}

  useEffect(() => {
    const getRegisteredEmployee = async () => {
      await getEmployeeListApi({...query})
    }

    getRegisteredEmployee()
  }, [location?.search])

  const onChangeQuery = (queryParams) => {
    const params = setQueryParams({...query, ...queryParams})
    setSearchParams(params, {replace: true})
  }

  const modalVisibility = (value) => {
    dispatch(setModal(value))
  }

  const props = {
    onChangePage: (e) => onChangeQuery({page: e}),
    onSearch: (e) => onChangeQuery({page: 1, search: e}),
    onChangeSize: (e) => onChangeQuery({page: 1, limit: e}),
    modal,
    query,
    employeeListResponse,
    modalVisibility
  }
  
    return (
        <RegisteredEmployee {...props}/>
    )

}
