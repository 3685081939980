import Spinner from "../../../commons/spinner";
import ProgressBar from "../../../commons/progress-bar";
import icDocumentImportSuccess from "../../../../assets/images/ic-document-import-succes.svg";
import Button from "../../../commons/button";
import icUploadWhite from "../../../../assets/images/ic-upload-white.svg";

export const ImportData = ({percentage, isComplete, onHide}) => {
    return (
        <>
            {
                !isComplete ?
                    <div className={"w-full flex flex-col items-center text-neutral-80 space-y-8 px-40 py-16"}>
                        <Spinner className={"w-10 h-10 border-primary-50"}/>
                        <div className={"flex flex-col items-center space-y-2"}>
                            <p className={"font-bold text-lg"}>Importing</p>
                            <p>Your Grade data are now being Imported</p>
                        </div>
                        <div className={"w-full flex flex-col items-center space-y-5 pt-8"}>
                            <ProgressBar percentage={percentage}/>
                            <p className={"font-bold text-lg text-primary-60"}>Progress in {percentage}%</p>
                        </div>
                    </div> :
                    <div className="w-full">
                        <div className={"flex flex-col items-center justify-center px-40 py-16"}>
                            <p className="font-bold">Berhasil Import Grade Data</p>
                            <img src={icDocumentImportSuccess} alt="ic-document-import-success" className="mx-auto"/>
                        </div>
                        <div className={"flex justify-end px-6 pt-6 border-t"}>
                            <Button className="w-[132px]" type="primary" title="Lanjutkan" icon={icUploadWhite} onClick={onHide}/>
                        </div>
                    </div>
            }
        </>
    )
}
