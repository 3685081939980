import {EmployeeList} from "components/employee/employee-list/EmployeeList";
import {useFetch} from "services/useFetch";
import {API_EMPLOYEE} from "services/apiEmployee";
import {useEffect, useState} from "react";
import {useLocation, useSearchParams} from "react-router-dom";
import {getStatus} from "configs/employee/configTableEmployeeList";
import {downloadFile, getAllQueryParams, setQueryParams} from "../../../utils/utils";
import moment from "moment";
import {useDispatch, useSelector} from "react-redux";
import {setToast} from "../../../reducers/employeeListReducer";


const FILTER_PARAMS = ['department_ids', 'division_ids', 'subdivision_ids', 'status', 'start_date', 'end_date']

export const EmployeeListContainer = () => {
    const location = useLocation()
    const pathName = location.pathname.split('/').pop()
    const defaultQuery = {
        page: 1,
        search: '',
        limit: 20,
    }
    const dispatch = useDispatch()
    const {toast} = useSelector((state) => state.employeeListReducer)
    const [searchParams, setSearchParams] = useSearchParams();
    const query = searchParams.size > 0 ? getAllQueryParams(searchParams) : defaultQuery
    const {data: employeeData, fetching: getEmployeeListApi} = useFetch(API_EMPLOYEE.LIST_EMPLOYEE, {})
    const [selectedIds, setSelectedIds] = useState([])
    const {fetching: exportEmployeeApi} = useFetch(API_EMPLOYEE.EXPORT_EMPLOYEE, {})
    const {fetching: deleteEmployeeApi} = useFetch(API_EMPLOYEE.DELETE_EMPLOYEE, {})
    const [modal, setModal] = useState({
        sort: false,
        delete: false,
        export: false,
        filter: false,
        alert: false
    })
    const [alert, setAlert] = useState({title: '', description: ''})
    const [queryFilter, setQueryFilter] = useState()
    const [bodyRequest, setBodyRequest] = useState(defaultQuery)

    useEffect(() => {
        const newQuery = {...query}
        const newQueryFilter = {}
        Object.entries(newQuery).forEach(([key, item]) => {
            if (FILTER_PARAMS.includes(key)) {
                const valueOfQuery = FILTER_PARAMS.slice(0, 4).includes(key) ? item.split(',') : item
                newQuery[key] = valueOfQuery
                newQueryFilter[key] = valueOfQuery
            }
        })

        const body = {
            ...bodyRequest,
            status: newQuery?.status ? newQuery.status : getStatus[pathName],
            department_ids: newQuery.department_ids || [],
            division_ids: newQuery.division_ids || [],
            subdivision_ids: newQuery.subdivision_ids || [],
            start_join_date: newQuery.start_date || null,
            end_join_date: newQuery.end_date || null,
            order_by: newQuery?.order_by || 'created_at',
            order_type: newQuery?.order_type || 'desc',
            limit: newQuery.limit,
            search: newQuery.search || '',
            page: newQuery.page
        }

        setQueryFilter(newQueryFilter)
        setBodyRequest(body)
        getEmployee(body)
    }, [location.search, pathName])

    const getEmployee = (body) => {
        getEmployeeListApi(body)
            .catch(err => console.log("err :", err))
    }

    const onSelectHandler = (item) => {
        let ids = item?.map(i => i?.id)
        setSelectedIds(ids)
    }

    const onAddEmployeeHandler = () => {
        window.open('/employee/prospective-employee/add-employee', '_blank', 'noopener,noreferrer')
    }

    const importEmployeeHandler = () => {
        window.open('/employee/prospective-employee/import-employee', '_blank', 'noopener,noreferrer')
    }

    const onExportHandler = () => {
        let body = {...bodyRequest, employee_ids: selectedIds}

        exportEmployeeApi(body, '', {}, 'blob')
            .then(res => {
                downloadFile(null, res, `Employee-List-Export-${moment().format('DD-MM-YYYY')}.xlsx`)
                setModal({...modal, export: false})
                dispatch(setToast({isShowToast: true, message: 'Export Data Berhasil !!'}))
            })
    }

    const onDeleteEmployee = () => {
        deleteEmployeeApi({employee_ids: selectedIds})
            .then(resp => {
                let failedData = []
                resp?.data?.map(data => !data?.isSuccess && failedData.push(data))

                if (!failedData?.length) {
                    if (query?.page === '1') {
                        getEmployee({...bodyRequest})
                    } else {
                        onChangeQuery({page: 1, search: '', limit: 20})
                    }
                    setAlert({title: 'Success', description: 'Data berhasil dihapus !'})
                    setModal({...modal, delete: false, alert: true})
                } else {
                    setAlert({title: 'Delete Failed', description: 'Data tidak dapat dihapus !'})
                    setModal({...modal, delete: false, alert: true})
                }
            })
    }

    const onClickButtonDelete = () => {
        if (!selectedIds?.length) {
            setAlert({title: 'Delete Failed', description: 'Silahkan pilih nama karyawan untuk dihapus!'})
            setModal({...modal, alert: true})
        } else {
            setModal({...modal, delete: true})
        }
    }

    const setModalVisibility = (modalVisibility) => {
        setModal({...modal, ...modalVisibility})
    }

    const onChangeQuery = (queryParams) => {
        const params = setQueryParams({...query, ...queryParams})
        setSearchParams(params, {replace: true})
    }

    const clearToast = () => {
        dispatch(setToast({isShowToast: false, message: ''}))
    }

    const props = {
        alert,
        modal,
        toast,
        pathName,
        selectedIds,
        employeeData,
        onClickButtonDelete,
        onImportEmployee: importEmployeeHandler,
        onAddEmployee: onAddEmployeeHandler,
        onDeleteEmployee,
        onExportHandler,
        setModalVisibility,
        onSelectHandler,
        isLoading: employeeData?.isLoading,
        onChangeQuery,
        query,
        queryFilter,
        onSubmitFilter: (queryFilter) => onChangeQuery(queryFilter),
        onClickSort: (queryFilter) => onChangeQuery(queryFilter),
        clearToast
    }

    return <EmployeeList {...props}/>
}
