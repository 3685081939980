import {useEffect, useState} from "react";
import {useFetch} from "../../../services/useFetch";
import {deepClone, getHeadUnitText} from "../../../utils/utils";
import Filter from "../../../components/commons/filter";
import {API_JOB_TITLE} from "../../../services/apiJobTitle";

const FILTER = {
    DEPARTMENT: 'department',
    DIVISION: 'division',
    SUB_DIVISION: 'sub-division',
    VISIBILITY: 'visibility'
}

const INITIAL_DATA_FILTER = [
    { label: 'Departement', value: FILTER.DEPARTMENT },
    { label: 'Divisi', value: FILTER.DIVISION },
    { label: 'Sub Divisi', value: FILTER.SUB_DIVISION },
    { label: 'Visibility', value: FILTER.VISIBILITY }
]

const INITIAL_OPTIONS = {
    [FILTER.DEPARTMENT]: [],
    [FILTER.DIVISION]: [],
    [FILTER.SUB_DIVISION]: [],
    [FILTER.VISIBILITY]: [
        {
            name: 'Shown',
            id: 'shown',
        },
        {
            name: 'Hidden',
            id: 'hidden'
        }
    ],
}

const INITIAL_FILTER = {
    department_ids: [],
    division_ids: [],
    sub_division_ids: [],
    visibility: []
}

const QUERY_PARAMS = {
    [FILTER.DEPARTMENT]: 'department_ids',
    [FILTER.DIVISION]: 'division_ids',
    [FILTER.SUB_DIVISION]: 'sub_division_ids',
    [FILTER.VISIBILITY]: 'visibility'
}

const JOB_UNIT = [FILTER.DEPARTMENT, FILTER.DIVISION, FILTER.SUB_DIVISION]

export const JobTitleSettingsFilterContainer = (
    {
        show,
        onShow,
        onClose,
        onSubmit,
        queryFilter
    }
) => {
    const [activeFilter, setActiveFilter] = useState({})
    const [options, setOptions] = useState(INITIAL_OPTIONS)
    const [filter, setFilter] = useState(INITIAL_FILTER)
    const [filterCount, setFilterCount] = useState(0)
    const {fetching: getJobUnit} = useFetch(API_JOB_TITLE.JOB_UNIT_LIST)

    useEffect(() => {
        if (queryFilter) {
            const newQueryFilter = deepClone(queryFilter)
            setFilter(newQueryFilter)
            countFilterLength(newQueryFilter)
        }
        if (show) onSelectFilter(INITIAL_DATA_FILTER[0])
    }, [queryFilter, show])


    const onSelectFilter = (item) => {
        if (JOB_UNIT.includes(item.value) && options[item.value].length === 0) {
            getJobUnit({head_unit_ids: filter?.[QUERY_PARAMS[getHeadUnitText[item.value]]] || []}, item.value)
                .then(res => setOptions({...options, [item.value]: res.data}))
        }
        setActiveFilter(item)
    }

    const onChangeOption = (item) => {
        const { name, value, checked } = item
        const arrChecked = filter?.[QUERY_PARAMS[name]] || []
        handleClearOptionsFilter(name)

        if (checked) {
            arrChecked.push(String(value))
        } else {
            const indexRemove = arrChecked.indexOf(String(value))
            arrChecked.splice(indexRemove, 1)
        }
        setFilter({ ...filter, [QUERY_PARAMS[name]]: arrChecked })
    }

    const handleClearOptionsFilter = (filterName) => {
        if (filterName === FILTER.DEPARTMENT) {
            options[FILTER.DIVISION] = []
            options[FILTER.SUB_DIVISION] = []
            filter[[QUERY_PARAMS[FILTER.DIVISION]]] = []
            filter[[QUERY_PARAMS[FILTER.SUB_DIVISION]]] = []
        } else if (filterName === FILTER.DIVISION) {
            options[FILTER.SUB_DIVISION] = []
            filter[[QUERY_PARAMS[FILTER.SUB_DIVISION]]] = []
        }
        setOptions({ ...options })
    }

    const onSubmitFilter = () => {
        const queryFilter = {...filter}
        Object.entries(queryFilter).forEach(([key, item]) => {
            queryFilter[key] = item.join(',')
        })
        onSubmit(queryFilter)
        onClose()
    }

    const countFilterLength = (filter) => {
        let totalLength = 0
        for (const key in filter) {
            totalLength += filter[key].length;
        }
        setFilterCount(totalLength)
    }

    const props = {
        show,
        onShow,
        onClose,
        queryParams: QUERY_PARAMS,
        filterList: INITIAL_DATA_FILTER,
        activeFilter,
        onSelectFilter,
        filter,
        filterCount,
        options,
        onChangeOption,
        onSubmitFilter,
        onClearFilter: () => setFilter(deepClone(INITIAL_FILTER)),
    }

    return (
        <Filter {...props} />
    )
}
