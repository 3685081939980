import iconUserProfile from 'assets/images/ic-users-profiles-02.svg'
import iconOrgStructure from 'assets/images/ic-data-04.svg'
import iconMatrixApproval from 'assets/images/ic-check-square-broken.svg'
import iconWorkSettings from 'assets/images/ic-clock-forward.svg'
import iconManagementContract from 'assets/images/ic-file-attach-01.svg'
import iconManagementAccount from 'assets/images/ic-key-01.svg'
import iconAttendance from 'assets/images/ic-calendar-check-primary.svg'
import iconRequestShifting from 'assets/images/ic-arrow-switch-horizontal.svg'
import iconRequestLeave from "assets/images/ic-luggage.svg"
import iconOvertime from "assets/images/ic-clock-plus.svg"
import iconAdditionalIncentive from "assets/images/ic-coin-hand.svg"
import iconRemoteWorking from "assets/images/ic-home-05.svg"
import iconPayroll from "assets/images/ic-payroll.svg"
import iconAppVersion from "assets/images/ic-gear.svg"

export const PAGE_TITLE = 'AVO Innovation & Technology'

export const STEPS_IMPORT_EMPLOYEE_DATA = ['Upload File', 'Validation Employee Data', 'View Data', 'Import Data']

export const STEPS_IMPORT_EMPLOYEE_CONTRACT = ['Upload File', 'Validation Employee Contract', 'View Data', 'Import Data']

export const STEPS_IMPORT_SHIFTING_SCHEDULE = ['Upload File', 'Validation Import Shifting Schedule', 'View Data', 'Import Data']

export const STEPS_IMPORT_DATA_PAYROLL = ['Upload File', 'Validation Data', 'Import Data']

export const STEPS_CREATE_PAYROLL = ['pilih-bulan-payroll', 'list-employee', 'generate-komponen', 'list-employee-payroll']

export const STEPS_IMPORT_GRADE = ['Upload File', 'Validation Data', 'Import Data']

export const MENU = [
    {
        label: 'Employee',
        key: 'employee',
        icon: iconUserProfile,
        children: [
            {
                label: 'Prospective Employee',
                key: 'prospective-employee',
                id: 5
            },
            {
                label: 'Active Employee',
                key: 'active-employee',
                id: 5
            },
            {
                label: 'Former Employee',
                key: 'former-employee',
                id: 5
            }
        ]
    },
    {
        label: 'Management Structure Organization',
        key: 'management-structure-organization',
        icon: iconOrgStructure,
        children: [
            {
                label: 'Works Unit Settings',
                key: 'works-unit-settings',
                id: 46
            },
            {
                label: 'Grade Settings',
                key: 'grade-settings',
                id: 42
            },
            {
                label: 'Job Title Settings',
                key: 'job-title-settings',
                id: 82
            },
            {
                label: 'Structure Organization',
                key: 'structure-organization',
                id: 85
            }
        ]
    },
    {
        label: 'Management Contract',
        key: 'management-contract',
        icon: iconManagementContract,
        children: [
            {
                label: 'Create Contract',
                key: 'create-contract',
                id: 96
            }, {
                label: 'Contract Employee',
                key: 'contract-employee',
                id: 90
            }
        ]
    },
    {
        label: 'Management Account',
        key: 'management-account',
        icon: iconManagementAccount,
        children: [
            {
                label: 'Account Activation',
                key: 'account-activation',
            }, {
                label: 'Role Access',
                key: 'role-access',
            }
        ]
    },
    {
        label: 'Work Settings',
        key: 'work-settings',
        icon: iconWorkSettings,
        children: [
            {
                label: 'Work Time',
                key: 'work-time',
            },
            {
                label: 'Day Off List',
                key: 'day-off-list',
            }
        ]
    },
    {
        label: 'Matrix Approval',
        key: 'matrix-approval',
        icon: iconMatrixApproval,
        children: [
            {
                label: 'Approval Setting',
                key: 'approval-setting',
            },
            {
                label: 'Activity Setting',
                key: 'activity-setting',
            },
            {
                label: 'PnC Approver Setting',
                key: 'pnc-approver-setting',
            }
        ]
    },  {
        label: 'Attendance',
        key: 'attendance',
        icon: iconAttendance,
        children: [
            {
                label: 'Attendance List',
                key: 'attendance-list',
            },
            {
                label: 'Request List',
                key: 'request-list',
            }
        ]
    },
    {
        label: 'Payroll',
        key: 'payroll',
        icon: iconPayroll,
        children: [
            {
                label: 'Payroll List',
                key: 'payroll-list',
            },
            {
                label: 'Create Payroll',
                key: 'create-payroll',
            },
            {
                label: 'Setting Komponen Payroll',
                key: 'settings-komponen-payroll',
            },
            {
                label: 'Incentive',
                key: 'incentive',
            }
        ]
    },
    {
        label: 'Shifting',
        key: 'shifting',
        icon: iconRequestShifting
    },
    {
        label: 'Request Leave / Cuti',
        key: 'leave-request',
        icon: iconRequestLeave
    },
    {
        label: 'Request Overtime',
        key: 'overtime-request',
        icon: iconOvertime
    },
    {
        label: 'Remote Working Request',
        key: 'remote-working-request',
        icon: iconRemoteWorking
    },
    {
        label: 'Request Additional Incentive',
        key: 'additional-incentive-request',
        icon: iconAdditionalIncentive
    },
    {
        label: 'General Settings',
        key: 'general-settings',
        icon: iconAttendance,
        children: [
            {
                label: 'Leave Setting',
                key: 'leave-setting',
            },
            {
                label: 'Remote Working Setting',
                key: 'remote-working-setting',
            },
            {
                label: 'Overtime Setting',
                key: 'overtime-setting',
            },
            {
                label: 'HRIS Announcement',
                key: 'hris-announcement',
            }
        ]
    },
    {
        label: 'App Version',
        key: 'versioning',
        icon: iconAppVersion
    },
]

export const CREATE_COMPANY_FORM = [
    {
        label: 'Logo Perusahaan',
        key: 'logo',
        type: 'upload'
    }, {
        label: 'Nama PT',
        key: 'name',
        placeholder: 'Isi nama PT'
    }, {
        label: 'Kode PT',
        key: 'code',
        placeholder: 'Isi kode PT'
    }, {
        label: 'Tgl Berdiri PT',
        type: 'date',
        key: 'establishment_date',
        placeholder: 'Pilih tanggal berdiri'
    }, {
        label: 'No. Surat Keterangan Usaha',
        key: 'business_certificate_number',
        placeholder: 'Isi nomor surat keterangan usaha'
    }, {
        label: 'No. Akta Perusahaan',
        key: 'company_deed_number',
        placeholder: 'Isi nomor akta perusahaan'
    },
]

export const ACTION_TYPE = {
    CREATE: 'CREATE',
    EDIT: 'EDIT',
    DELETE: 'DELETE',
    UPDATE_STATUS: 'UPDATE_STATUS',
    UPDATE_ROLE: 'UPDATE_ROLE',
    PREVIEW: 'PREVIEW',
    DETAIL: 'DETAIL',
}

export const STATUS_TYPE = {
    DRAFT: 'draft',
    ACTIVE: 'active',
    PENDING: 'pending',
    ADDENDUM: 'addendum',
    INTERIM: 'interim',
    RENEW: 'renew'
}

export const STATUS_EMPLOYEE = {
    DRAFT: 'draft',
    SUBMITTED: 'submitted',
    REGISTERED: 'registered',
    ACTIVE: 'active',
    SUSPEND: 'suspend',
    INACTIVE: 'inactive',
    BLACKLIST: 'blacklist',
    CONTRACT_REJECTED: 'contract-rejected'
}
