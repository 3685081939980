import {ContractEmployeeDetail} from "../../../../components/management-contract/contract-employee/contract-employee-detail/ContractEmployeeDetail";
import {useSearchParams} from "react-router-dom";
import {useFetch} from "../../../../services/useFetch";
import {API_CONTRACT} from "../../../../services/apiContract";
import {useEffect, useState} from "react";
import {convertSpaceToDash, getFileUnit, uploadFileToS3} from "../../../../utils/utils";
import {useDispatch, useSelector} from "react-redux";
import {setDocumentUpload, setMasterContractDetail} from "../../../../reducers/contractReducer";
import {useValidation} from "../../../../hooks/useValidation";
import {STATUS_TYPE} from "../../../../constants/constant";
import {Alert} from "../../../../components/commons/alert";
import icAlert from "../../../../assets/images/ic-alert-triangle.svg";

export const ContractEmployeeDetailContainer = () => {
    const dispatch = useDispatch()
    const [searchParams] = useSearchParams();
    const contractId = searchParams.get('contractId')
    const contractHistoryId = searchParams.get('contractHistoryId')
    const [modalText, setModalText] = useState('')
    const [previewDocument, setPreviewDocument] = useState('')
    const [modal, setModal] = useState({
        upload: false,
        edit: false,
        notes: false,
        jobDesc: false,
        loading: false,
        preview: false
    })
    const {documentUpload, masterContractDetail} = useSelector(state => state.contractReducer)
    const [filesDocumentUpload, setFilesDocumentUpload] = useState({})
    const {isFormValid: formValidContract} = useValidation({
        form: {
            contract_document_url: documentUpload.contract_document_url
        }
    })
    const {isFormValid: formValidModifyContract} = useValidation({
        form: {
            ...{
                contract_document_url: documentUpload.contract_document_url,
                notes: documentUpload.notes
            }
        }
    })
    const {data: contractDetailResponse, fetching: getContractDetailByContractIdApi} = useFetch({
        ...API_CONTRACT.DETAIL_CONTRACT,
        initPathVariable: contractId
    })
    const {
        data: contractHistoryDetailResponse,
        fetching: getContractHistoryByContractIdApi
    } = useFetch(API_CONTRACT.DETAIL_CONTRACT_HISTORY)
    const {data: detailContractInformation, fetching: getContractInformationApi} = useFetch({
        ...API_CONTRACT.DETAIL_CONTRACT_INFORMATION,
        initPathVariable: contractId
    })
    const {fetching: getUrlDocumentApi} = useFetch(API_CONTRACT.GET_URL_CONTRACT_DOCUMENT)
    const {fetching: uploadContractDocumentApi} = useFetch(API_CONTRACT.UPLOAD_CONTRACT_DOCUMENT)
    const {fetching: uploadAddendumContractDocumentApi} = useFetch(API_CONTRACT.UPLOAD_ADDENDUM_CONTRACT_DOCUMENT)
    const {fetching: uploadRenewContractDocumentApi} = useFetch(API_CONTRACT.UPLOAD_RENEW_CONTRACT_DOCUMENT)
    const {fetching: completeContractApi} = useFetch(API_CONTRACT.COMPLETE_CONTRACT)

    useEffect(() => {
        if (!!contractHistoryId) {
            getContractHistory()
        } else {
            getContractDetail()
        }
    }, [contractId, contractHistoryId]);

    const getContractHistory = () => {
        Promise.all([getContractHistoryByContractIdApi({}, contractHistoryId), getContractInformationApi({}, contractId)])
            .then((res) => {
                dispatch(setMasterContractDetail(res[0].data))
            })
    }

    const getContractDetail = () => {
        Promise.all([getContractDetailByContractIdApi({}, contractId), getContractInformationApi({}, contractId)])
            .then((res) => {
                dispatch(setMasterContractDetail(res[0].data))
            })
    }

    const updateModalVisibility = (key, value) => {
        setModal({...modal, [key]: value})
    }

    const editHandler = (text) => {
        setModalText(text)
        updateModalVisibility('edit', true)
    }

    const onUploadFile = async (e, key) => {
        if (e.name === 'notes') {
            dispatch(setDocumentUpload({[e.name]: e.value}))
        } else {
            const file = e.target.files[0]
            if (!file) return
            if(file.size > 2000000){
                return Alert({
                    icon: icAlert,
                    title: 'Failed',
                    message: 'File melebihi 2MB',
                    autoClose: true,
                    interval: 3000
                })
            }

            const allowedExtensions = /^[a-z0-9_()\-\[\]]+\.pdf$/i;
            if (!allowedExtensions.exec(convertSpaceToDash(file?.name))) {
                e.target.value = null
                return console.log('Format file tidak sesuai')
            }

            const reader = new FileReader()
            reader.onload = () => {
                e.target.value = null
                dispatch(setDocumentUpload({[key]: {name: `${file.name} ${getFileUnit(file.size)}`}}))
                setFilesDocumentUpload({...filesDocumentUpload, ...{[key]: file}})
            }
            reader.readAsDataURL(file)
        }
    }

    const onSubmitFormUploadDocument = (contractStatus) => {
        switch (contractStatus) {
            case STATUS_TYPE.ADDENDUM:
                return uploadDocumentAddendumContract()
            case STATUS_TYPE.RENEW:
                return uploadDocumentRenewContract()
            default:
                return uploadDocumentContract()
        }

    }

    const uploadDocumentContract = () => {
        const filesUpload = [filesDocumentUpload?.contract_document_url]
        if (filesDocumentUpload?.hki_document_url) filesUpload.push(filesDocumentUpload?.hki_document_url)
        const fileNames = filesUpload?.map(item => item?.name?.replace(/\s/g, ''))

        uploadingFiles(filesUpload, fileNames).then(response => {
            let requestBody = {
                contract_document_url: response[0],
                hki_document_url: response[1] || null
            }
            uploadContractDocumentApi(requestBody, contractId)
                .then(res => {
                    updateModalVisibility('upload', false)
                    window.location.reload()
                })
        }).catch(() => console.log('upload failed'))
    }

    const uploadDocumentAddendumContract = () => {
        const filesUpload = [filesDocumentUpload?.contract_document_url]
        if (filesDocumentUpload?.sk_document_url) filesUpload.push(filesDocumentUpload?.sk_document_url)
        const fileNames = filesUpload?.map(item => item?.name?.replace(/\s/g, ''))

        uploadingFiles(filesUpload, fileNames).then(response => {
            let requestBody = {
                contract_document_url: response[0],
                sk_document_url: response[1] || null,
                notes: documentUpload.notes
            }
            uploadAddendumContractDocumentApi(requestBody, contractId)
                .then(res => {
                    updateModalVisibility('upload', false)
                    window.location.reload()
                })
        }).catch(() => console.log('upload failed'))
    }

    const uploadDocumentRenewContract = () => {
        const filesUpload = [filesDocumentUpload?.contract_document_url]
        if (filesDocumentUpload?.sk_document_url) filesUpload.push(filesDocumentUpload?.sk_document_url)
        const fileNames = filesUpload?.map(item => item?.name?.replace(/\s/g, ''))

        uploadingFiles(filesUpload, fileNames).then(response => {
            let requestBody = {
                contract_document_url: response[0],
                sk_document_url: response[1] || null,
                notes: documentUpload.notes
            }
            uploadRenewContractDocumentApi(requestBody, contractId)
                .then(res => {
                    updateModalVisibility('upload', false)
                    window.location.reload()
                })
        }).catch(() => console.log('upload failed'))
    }

    const uploadingFiles = (filesUpload, filesName) => {
        return new Promise(async (resolve, reject) => {
            const s3Urls = await getUrlDocumentApi({files: filesName})
            const filesUrls = []
            const apiS3list = s3Urls?.data?.map(async (item,index) => {
                await uploadFileToS3(item, filesUpload[index])
                filesUrls[index] = `${item.url}${item.fields.key}`
            })
            await Promise.all(apiS3list)
            resolve(filesUrls)
        })
    }

    const actionButtonHandler = async () => {
        let name = convertSpaceToDash(detailContractInformation?.data?.profile?.name)
        let id = detailContractInformation?.data?.profile?.id
        switch (modalText) {
            case 'edit':
                return window.location.href = `/management-contract/contract-employee/edit-contract-${name}?contractId=${contractId}&employeeId=${id}&action=edit`
            case 'addendum':
                return window.location.href = `/management-contract/contract-employee/addendum-contract-${name}?contractId=${contractId}&employeeId=${id}&action=addendum`
            case 'interim':
                return window.location.href = `/management-contract/contract-employee/addendum-contract-(interim)-${name}?contractId=${contractId}&employeeId=${id}&action=interim`
            case 'renew':
                return window.location.href = `/management-contract/contract-employee/perubahan-contract-${name}?contractId=${contractId}&employeeId=${id}&action=renew`
            case 'complete':
                updateModalVisibility('edit', false)
                setTimeout(() => {
                    updateModalVisibility('notes', true)
                }, 200)
                break
            default:
                return
        }
    }

    const onSubmitNotes = (notes) => {
        completeContractApi({notes}, contractId)
            .then(() => {
                Alert({
                    icon: 'success',
                    title: 'Success',
                    message: 'Complete Contract berhasil!',
                })
                window.location.href = `/management-contract/contract-employee/employee-detail?contractId=${contractId}`
            })
            .catch(err => {
                updateModalVisibility('notes', false)
                console.log(err)
            })
    }

    let formValid = (status) => {
        switch (status) {
            case 'addendum':
            case 'renew':
                return !formValidModifyContract()
            default:
                return !formValidContract()
        }
    }

    const jobDescriptionHandler = () => {
        updateModalVisibility('jobDesc', true)
    }

    const modalPreviewDocumentVisibility = (doc) => {
        setPreviewDocument(doc)
        updateModalVisibility('preview', true)
    }

    const clearDocumentUpload = () => {
        dispatch(setDocumentUpload({
            contract_document_url: null,
            hki_document_url: null,
            sk_document_url: null,
            notes: ''
        }))
        setFilesDocumentUpload({})
    }

    if (masterContractDetail?.id && detailContractInformation?.data) {
        const props = {
            contractDetail: masterContractDetail,
            detailContractInformation: detailContractInformation.data,
            contractIdParams: contractId,
            historyIdParams: contractHistoryId,
            updateModalVisibility,
            editHandler,
            modal,
            modalText,
            onUploadFile,
            documentUpload,
            formValid,
            isDisabledContract: !formValidContract(),
            isDisabledAddendumContract: !formValidModifyContract(),
            isDisabledRenewContract: !formValidModifyContract(),
            onSubmitFormUploadDocument,
            actionButtonHandler,
            addNotesModal: modal.notes,
            onSubmitNotes,
            jobDescriptionHandler,
            previewDocument,
            modalPreviewDocumentVisibility,
            clearDocumentUpload,
            loading: contractDetailResponse.isLoading || contractHistoryDetailResponse.isLoading
        }

        return (
            <ContractEmployeeDetail {...props}/>
        )
    }

}
