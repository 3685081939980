import {
    OrganizationalStructure
} from "components/organization-structure-management/organizational-structure/OrganizationalStructure";
import {useFetch} from "services/useFetch";
import {API_STRUCTURE_ORGANIZATION} from "services/apiStructureOrganization";
import {
    PreviewEmployeeStructure
} from "components/organization-structure-management/organizational-structure/PreviewEmployeeStructure";
import {useDispatch, useSelector} from "react-redux";
import {setPreviewEmployeeStructure} from "../../../reducers/organizationStructureReducer";
import {useEffect, useState} from "react";

export const OrganizationalStructureContainer = () => {
    const dispatch = useDispatch()
    const {previewEmployee} = useSelector(state => state.organizationStructureReducer)
    const {
        data: structureOrganizationResponse,
        fetching: getParentStructureApi,
        isLoading: isLoadingParent
    } = useFetch(API_STRUCTURE_ORGANIZATION.GENERATE_STRUCTURE_ORGANIZATION, {})
    const {fetching: getChildStructureApi, isLoading: isLoadingChild} = useFetch(API_STRUCTURE_ORGANIZATION.GENERATE_STRUCTURE_ORGANIZATION)
    const {
        data: jobTitleEmployees,
        fetching: getJobTitleEmployee,
    } = useFetch(API_STRUCTURE_ORGANIZATION.JOB_TITLE_EMPLOYEES, {})
    const [childStructure, setChildStructure] = useState(null)

    useEffect(() => {
        getParentStructureApi()
            .catch(err => console.log("err :", err))
    }, [])

    useEffect(() => {
        if (!!previewEmployee?.parentNode?.id && !previewEmployee?.visibility) {
            getChildStructureApi('', '', null, '', {
                rootId: previewEmployee?.parentNode?.id
            }).then(res => {
                setChildStructure(res?.data)
            })
        }
    }, [previewEmployee]);

    useEffect(() => {
        if (!!previewEmployee?.visibility) {
            const id = previewEmployee?.childNode?.id ? previewEmployee?.childNode?.id : previewEmployee?.parentNode?.id
            getJobTitleEmployee('', id)
                .catch(err => console.log("err :", err))
        }
    }, [previewEmployee?.visibility])

    const onHidePreview = () => dispatch(setPreviewEmployeeStructure({visibility: false}))

    return (
        <>
            <OrganizationalStructure structureOrganization={structureOrganizationResponse?.data}
                                     childStructureData={childStructure} isLoading={{parent: isLoadingParent, child: isLoadingChild}}/>
            <PreviewEmployeeStructure visibility={previewEmployee?.visibility}
                                      jobTitleDetail={previewEmployee?.childNode}
                                      onHide={onHidePreview}
                                      employeeList={jobTitleEmployees?.data}
            />
        </>
    )
}
