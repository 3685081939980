import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    modal: {
        export: false,
        sort: false,
        form: false,
        delete: false,
        edit: false
    },
    selectedTab: {id: 1, name: 'Management', key: 'management'},
    params: {
        page: 1,
        limit: 10,
        search: ''
    },
    options: [],
    reload: false,
    jobUnitDetail: {},
    total_data: 0,
    previewEmployee: {
        visibility: false,
        childNode: null,
        parentNode: null
    },
    toast: {
        message: '',
        isShowToast: false
    }
}

export const organizationStructureSlice = createSlice({
    name: 'organization-structure',
    initialState: initialState,
    reducers: {
        setModal: (state, action) => ({
            ...state,
            modal: {...state.modal, ...action.payload}
        }),
        setSelectedTab: (state, action) => ({
            ...state, selectedTab: action.payload
        }),
        setParams: (state, action) => ({
            ...state,
            params: {...state.params, ...action.payload}
        }),
        setOptions: (state, action) => ({
            ...state, options: action.payload
        }),
        setReload: (state, action) => ({
            ...state, reload: action.payload
        }),
        setJobUnitDetail: (state, action) => ({
            ...state, jobUnitDetail: action.payload
        }),
        setTotalData: (state, action) => ({
            ...state, total_data: action.payload
        }),
        flushCreateJobUnitDetail: (state) => ({
            ...state,
            createJobUnitDetail: {
                names: [],
                head_unit_id: 0,
                head_unit_selected: {}
            }
        }),
        setPreviewEmployeeStructure: (state, action) => {
            return ({
                ...state,
                previewEmployee: {
                    visibility: action?.payload?.visibility,
                    childNode: action?.payload?.childNode,
                    parentNode: action?.payload?.parentNode
                }
            })
        },
        setToast: (state, action) => {
            state.toast = {...state.toast, ...action.payload}
        }
    },
})

export const {
    setModal,
    setSelectedTab,
    setParams,
    setOptions,
    setReload,
    flushCreateJobUnitDetail,
    setJobUnitDetail,
    setTotalData,
    setPreviewEmployeeStructure,
    setToast
} = organizationStructureSlice.actions

const organizationStructureReducer = organizationStructureSlice.reducer
export default organizationStructureReducer
