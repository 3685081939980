import icProfileAdd from "assets/images/ic-profile-add.svg"
import Button from "components/commons/button"
import Modal from "components/commons/modal"

export const AddEmployee = ({
    children,
    onSavedAsDraft,
    onSubmit,
    onValidateForm,
    canSavedDraft,
    setShowConfirmSubmit,
    showConfirmSubmit,
    companyNameList,
}) => {
    return (
        <div className="bg-neutral-10 space-y-1 pt-1 pb-[100px]">
            {children}
            <div className="fixed bottom-0 w-[calc(100%-200px)] flex items-center justify-end bg-white py-6 px-14 gap-3 z-10">
                <Button className="w-32" type="outline" title="Saved as Draft" disabled={!canSavedDraft} onClick={onSavedAsDraft} />
                <Button className="w-32" title="Submit" onClick={onValidateForm} />
            </div>

            <Modal
                dialogClassName='w-full max-w-[360px]'
                contentClassName='rounded-xl'
                show={showConfirmSubmit}
            >
                <div>
                    <div className="flex flex-col justify-center items-center py-8 px-4">
                        <img src={icProfileAdd} alt={'profile add'} className="w-12 h-12 p-2 border rounded-full bg-primary-10 border-primary-30 mb-4" />
                        <p className="text-center font-medium text-neutral-80">{'Apakah anda yakin ingin menambah karyawan'}</p>
                        <p className="text-center font-medium text-orange-50 mt-1">{`${companyNameList}?`}</p>
                    </div>
                    <div className="px-6 py-3 flex flex-row border-t space-x-3 justify-center">
                        <Button className="w-full" type="outline" title="Cancel" onClick={() => setShowConfirmSubmit(false)} />
                        <Button className="w-full" title="Submit" onClick={onSubmit} />
                    </div>
                </div>
            </Modal>
        </div>
    )
}