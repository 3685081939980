import Table from "../commons/table";
import {TABLE_COMPANY_SETTING} from "../../configs/company-setting/configTableCompanySetting";
import Button from "../commons/button";
import icPlus from '../../assets/images/ic-plus-01.svg'
import {CreateCompany} from "./CreateCompany";
import DeleteModal from "../commons/delete-modal";

export const CompanySettings = (props) => {
    const {
        companyResponse,
        modalVisibility,
        companyLogo,
        onUploadImage,
        onDeleteImage,
        onChangeFormValue,
        onSubmitForm,
        companyDetail,
        actionButtonListHandler,
        onDelete,
        modal,
        isFormValid,
        errorForm,
        onAddCompany
    } = props

    return (
        <div>
            <div className={"flex justify-end"}>
                <Button title={'Add Perusahaan'} icon={icPlus} className={"mr-9 mt-4"}
                        onClick={onAddCompany}/>
            </div>
            <Table
                className="my-3"
                name={TABLE_COMPANY_SETTING.name}
                data={companyResponse}
                columns={TABLE_COMPANY_SETTING.column}
                totalResult={companyResponse?.length}
                onClickItem={actionButtonListHandler}
            />
            <CreateCompany
                show={modal.form}
                onHide={() => modalVisibility({form: false})}
                logo={companyLogo}
                onUploadImage={onUploadImage}
                onDeleteImage={onDeleteImage}
                onChangeFormValue={onChangeFormValue}
                onSubmitForm={onSubmitForm}
                companyDetail={companyDetail}
                isFormValid={isFormValid}
                errorForm={errorForm}
            />
            <DeleteModal show={modal.delete} onHide={() => modalVisibility({delete: false})} onDelete={onDelete}
                         text={'Apakah anda ingin menghapus data perusahaan ini?'}/>
        </div>
    )
}
