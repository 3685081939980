export const SELECTED_ADDITIONAL_INFORMATION = {
    gender: {},
    religion: {},
    marital_status: {},
    blood_type: {},
    main: {},
    residence: {},
    id_card_postal_code: {},
    residence_postal_code: {},
    same_as_address: false,
}

export const DEFAULT_FORM_JOB_TITLE = {
    company: null,
    job_title: null
}

export const DEFAULT_FORM_FAMILY = {
    full_name: null,
    relationship: null,
    other_relationship: null,
    address: null,
    phone_number: null,
    is_emergency_contact: false
}

export const DEFAULT_FORM_EMERGENCY_CONTACT = {
    full_name: null,
    relationship: null,
    address: null,
    phone_number: null,
}

export const DEFAULT_FORM_EDUCATION = {
    grade: null,
    institution: null,
    start_date: null,
    end_date: null,
    major: null,
    final_score: null,
}

export const DEFAULT_FORM_WORK_EXPERIENCE = {
    company_name: null,
    position: null,
    start_date: null,
    end_date: null,
    job_description: null,
}

export const DEFAULT_FORM_PERSONAL_DOCUMENT = {
    type: null,
    description: null,
}

export const OPTIONS_FAMILY_RELATIONSHIP = [
    {
        label: 'Ayah',
        value: 'Ayah',
    },
    {
        label: 'Ibu',
        value: 'Ibu',
    },
    {
        label: 'Nenek',
        value: 'Nenek',
    },
    {
        label: 'Kakek',
        value: 'Kakek',
    },
    {
        label: 'Adik',
        value: 'Adik',
    },
    {
        label: 'Kakak',
        value: 'Kakak',
    },
    {
        label: 'Paman',
        value: 'Paman',
    },
    {
        label: 'Bibi',
        value: 'Bibi',
    },
    {
        label: 'Lainnya',
        value: 'Lainnya',
    },
]

export const OTHER_RELATIONSHIP = 'Lainnya'
export const GRADE_NO_MAJOR = ['SD', 'SMP']