import icUserDefault from "../../assets/images/ic-user-profile.svg";
import Button from "../../components/commons/button";
import icEyeOpen from "../../assets/images/ic-eye-open.svg";

export const ADDITIONAL_INCENTIVE_REQUEST_TABLE = {
    name: 'Additional Incentive Request',
    column: [
        {
            name: "Nama Atasan",
            key: "supervisor_full_name",
            className: "font-semibold",
            render: ({item}) => {
                return (
                    <div className={'flex flex-row gap-3 items-center'}>
                        <img className={'w-7 h-7 rounded-full border border-neutral-50'}
                             src={item?.supervisor_profile_picture ? item?.supervisor_profile_picture : icUserDefault}
                             alt={'user-img'}/>
                        <div>{item?.supervisor_full_name}</div>
                    </div>
                )
            }
        },
        {
            name: "Nama Penerima Incentive",
            key: "subordinate_full_name",
            className: "font-semibold",
            render: ({item}) => (
                <div className="space-y-2">
                    <div>{item?.subordinate_full_name}</div>
                    <div className="font-regular">{item?.subordinate_job_title}</div>
                </div>
            )
        },
        {
            name: "Grade",
            key: "subordinate_grade_name",
        },
        {
            name: "Tanggal Pengajuan",
            key: "request_date_formatted",
        },
        {
            name: "Jenis Additional Incentive",
            key: "type_formatted",
        },
        {
            name: "Action",
            key: "action",
            classNameHeader: "!rounded-r-md w-[150px]",
            render: ({item, onClickItem}) => (
                <div className="flex justify-end gap-3 mx-auto">
                    <Button className="w-[80px]" type="outline" icon={icEyeOpen} title="Preview"
                            onClick={() => onClickItem(item)}/>
                </div>
            )
        }
    ]
}
