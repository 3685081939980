// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.avo__modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.avo__modal.show {
  z-index: 20;
  background-color: rgba(235, 240, 246, 0.8);
}
.avo__modal.no-show {
  opacity: 0;
  z-index: -1;
  pointer-events: none;
}
.avo__modal .dialogShow {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 16px;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.avo__modal.scale100 {
  transform: scale(1);
}
.avo__modal.scale95 {
  transform: scale(0.95);
}
.avo__modal .content {
  position: relative;
  display: flex;
  flex-direction: column;
  max-height: 90%;
  background-color: white;
  width: 100%;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -4px rgba(0, 0, 0, 0.1);
}`, "",{"version":3,"sources":["webpack://./src/components/commons/modal/modal.scss"],"names":[],"mappings":"AAEA;EACE,eAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EACA,wBAAA;EACA,wDAAA;EACA,0BAAA;AADF;AAGE;EACE,WAAA;EACA,0CAAA;AADJ;AAIE;EACE,UAAA;EACA,WAAA;EACA,oBAAA;AAFJ;AAKE;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,YAAA;EACA,iBAAA;EACA,kBAAA;EACA,aAAA;EACA,wBAAA;EACA,wDAAA;EACA,0BAAA;AAHJ;AAME;EACE,mBAAA;AAJJ;AAOE;EACE,sBAAA;AALJ;AAQE;EACE,kBAAA;EACA,aAAA;EACA,sBAAA;EACA,eAAA;EACA,uBAAA;EACA,WAAA;EACA,kFAAA;AANJ","sourcesContent":["@import '../../../styles/color';\n\n.avo__modal {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  transition-property: all;\n  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);\n  transition-duration: 150ms;\n\n  &.show {\n    z-index: 20;\n    background-color: rgb(235, 240, 246, 0.8);\n  }\n\n  &.no-show {\n    opacity: 0;\n    z-index: -1;\n    pointer-events: none;\n  }\n\n  .dialogShow {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    height: 100%;\n    margin-left: auto;\n    margin-right: auto;\n    padding: 16px;\n    transition-property: all;\n    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);\n    transition-duration: 150ms;\n  }\n\n  &.scale100 {\n    transform: scale(1);\n  }\n\n  &.scale95 {\n    transform: scale(.95);\n  }\n\n  .content {\n    position: relative;\n    display: flex;\n    flex-direction: column;\n    max-height: 90%;\n    background-color: white;\n    width: 100%;\n    box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
