import {
    EditWorkUnitForm
} from "../../../components/organization-structure-management/work-unit-setting/EditWorkUnitForm";
import {useDispatch, useSelector} from "react-redux";
import {setModal, setToast} from "../../../reducers/organizationStructureReducer";
import {useValidation} from "../../../hooks/useValidation";
import {useEffect, useState} from "react";
import {useFetch} from "../../../services/useFetch";
import {API_JOB_UNIT} from "../../../services/apiJobUnit";

export const EditWorkUnitFormContainer = ({fetchJobUnitList}) => {
    const dispatch = useDispatch()
    const [formValue, setFormValue] = useState({})
    const {modal, jobUnitDetail, options} = useSelector(state => state.organizationStructureReducer)
    const {isFormValid} = useValidation({form: {}})
    const {fetching: updateJobUnit} = useFetch(API_JOB_UNIT.UPDATE_JOB_UNIT)

    useEffect(() => {
        let head_unit_selected = {value: jobUnitDetail.head_unit_id, label: jobUnitDetail.head_unit_name}
        setFormValue({...jobUnitDetail, head_unit_selected})
    }, [jobUnitDetail]);

    const onHideForm = () => {
      dispatch(setModal({edit: false}))
    }

    const onSubmitForm = () => {
        let requestBodyManagement = {name: formValue.name}
        let requestBodyOther = {...requestBodyManagement, head_unit_id: formValue.head_unit_selected.value}
        let requestBody = formValue.category === 'management' ? requestBodyManagement : requestBodyOther
        updateJobUnit(requestBody, `${formValue.category}/${formValue.id}`)
            .then(() => {
                onHideForm()
                dispatch(setToast({isShowToast: true, message: 'Work Unit Updated !!'}))
                fetchJobUnitList()
            })

    }

    const saveValue = (value) => {
        setFormValue({...formValue, ...value})
    }

    const props = {
        isShow: modal.edit,
        onHideForm,
        onSubmitForm,
        isFormValid,
        formValue,
        saveValue,
        options
    }

    return (
        <EditWorkUnitForm {...props} />
    )
}
