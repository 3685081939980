import moment from "moment";
import icEyeOpen from "../../assets/images/ic-eye-open.svg";
import icPeople from "../../assets/images/ic-user-profile-03.svg";
import Button from "../../components/commons/button";

export const TABLE_REQUEST_LIST = {
    name: 'Request',
    column: [
        {
            name: "Name",
            key: "employee",
            className: "font-semibold",
            render: ({ value }) => {
                return (
                    <div className={'flex flex-row gap-3 items-center'}>
                        <img className={'w-7 h-7 rounded-full border border-neutral-50'}
                            src={value?.profile_picture ?? icPeople}
                            alt={'user-img'} />
                        <div className={"capitalize"}>{value?.full_name}</div>
                    </div>
                )
            }
        }, {
            name: "Job Title",
            key: "job_title",
            className: "font-semibold",
            render: ({ value }) => value.title
        }, {
            name: "Tanggal Presensi",
            key: "attendance_date",
            render: ({ value }) => moment(value).format('DD MMMM YYYY')
        }, {
            name: "Clock In",
            key: "clock_in_time",
        }, {
            name: "Clock Out",
            key: "clock_out_time",
        }, {
            name: "Tanggal Pengajuan",
            key: "created_at",
            sortable: true,
            render: ({ value }) => moment(value).format('DD MMMM YYYY')
        }, {
            name: "Action",
            key: "action",
            classNameHeader: "!text-center w-[150px]",
            render: ({ item, onClickItem }) => (
                <div className="flex justify-center">
                    <Button className="w-[80px]" type="outline" icon={icEyeOpen} title="Preview" onClick={() => onClickItem(item)} />
                </div>
            )
        }
    ]
}
