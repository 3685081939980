import clsx from "clsx";
import icCloseSquare from "../../../assets/images/ic-close-square.svg";
import Button from "../button";
import Modal from "../modal/Modal";

const FormModal = ({
                       children,
                       show,
                       onHide,
                       title,
                       width,
                       isDisabled = false,
                       onSubmitForm,
                       isLoading,
                       contentClassName
                   }) => {
    const maxWidth = !!width ? width : 'max-w-[590px]'
    return (
        <Modal
            dialogClassName={clsx('w-full', maxWidth)}
            contentClassName={clsx('rounded-xl', contentClassName, isLoading && 'pointer-events-none',)}
            show={show}
        >
            <div className={"px-6 py-3 flex flex-row items-center justify-between border-b"}>
                <p className={"font-semibold text-md"}>{title}</p>
                <Button type="outline" shape="circle" size="small" icon={icCloseSquare}
                        onClick={onHide}/>
            </div>
            <div className={clsx(contentClassName)}>
                {children}
            </div>
            {onSubmitForm && <div className={"px-6 py-3 flex-1 flex flex-row-reverse border-t"}>
                <Button className="w-[132px]" title={'Simpan'} disabled={isDisabled} loading={isLoading}
                        onClick={onSubmitForm}/>
            </div>}

        </Modal>
    )
}

export default FormModal
