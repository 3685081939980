
import axios from "axios"
import CONFIG from "configs/env"
import { useEffect, useState } from "react"
import { METHOD } from "./method"

const BASE_URL = CONFIG.BITESHIP_API_ENDPOINT
let instance = null

const axiosBiteship = makeInstance()
const initialData = {
    isLoading: false,
    data: null,
    error: null,
}

const defaultOptions = {
    isFetching: false,
    isLoading: false,
}

function makeInstance() {
    if (instance) return instance

    instance = axios.create({
        baseURL: BASE_URL,
        timeout: +CONFIG.REQUEST_TIMEOUT || 60000,
        headers: {
            Authorization: process.env.REACT_APP_BITESHIP_KEY
        }
    })

    return instance
}

export const useBiteship = ({ api, method }, initPayload, options = defaultOptions) => {
    const [data, setData] = useState(initialData)

    useEffect(() => {
        if (options.isFetching) fetching()
    }, [])

    const fetching = async (payload, pathVariable) => {
        const requestKey = (method === METHOD.GET) ? 'params' : 'data'

        setData({ ...data, isLoading: true })

        try {
            const response = await axiosBiteship({
                url: `${api}${pathVariable ? ('/' + (pathVariable)) : ''}`,
                method: method,
                [requestKey]: {
                    ...initPayload,
                    ...payload
                }
            })
            setData({ ...data, isLoading: false, data: response.data })
            return response.data
        } catch (error) {
            setData({ ...data, isLoading: false, error: error })
            throw error
        }
    }

    return { data, fetching }
}