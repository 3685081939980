import { METHOD } from "./method";

export const API_JOB_TITLE = {
    JOB_TITLE_LIST: {
        api: '/v1/hr-adm/job-title/list',
        method: METHOD.POST
    },
    JOB_TITLE_DELETE: {
        api: '/v1/hr-adm/job-title',
        method: METHOD.DELETE
    },
    JOB_TITLE_EXPORT: {
        api: '/v1/hr-adm/job-title/export',
        method: METHOD.POST
    },
    JOB_UNIT_LIST: {
        api: '/v1/hr-adm/job-title/job-unit',
        method: METHOD.POST
    },
    GRADE_LIST: {
        api: '/v1/hr-adm/job-title/grade',
        method: METHOD.GET
    },
    WORKING_TIME_CATEGORY_LIST: {
        api: '/v1/hr-adm/job-title/schedule-category',
        method: METHOD.GET
    },
    N1_LIST: {
        api: '/v1/hr-adm/job-title/n1',
        method: METHOD.GET
    },
    CREATE_JOB_TITLE: {
        api: '/v1/hr-adm/job-title',
        method: METHOD.POST
    },
    DETAIL_JOB_TITLE: {
        api: '/v1/hr-adm/job-title',
        method: METHOD.GET
    },
    UPDATE_JOB_TITLE: {
        api: '/v1/hr-adm/job-title',
        method: METHOD.PUT
    },
    UPDATE_VISIBILITY: {
        api: '/v1/hr-adm/job-title/hide',
        method: METHOD.POST
    },
}
