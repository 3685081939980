
import axios from "axios";
import CONFIG from "configs/env";

const BASE_URL = CONFIG.API_ENDPOINT
let instance = null;

export function createInstance(config) {
    const headers = config?.headers

    return axios.create({
        baseURL: BASE_URL,
        timeout: +CONFIG.REQUEST_TIMEOUT || 60000,
        withCredentials: true,
        headers
    })
}

function makeInstance() {

    if (instance) return instance;

    instance = axios.create({
        baseURL: BASE_URL,
        timeout: +CONFIG.REQUEST_TIMEOUT || 60000,
        withCredentials: true,
    })

    instance.interceptors.request.use((config) => {
        // Do something before request is sent
        // config.headers['Accept-Language'] = 'en'
        return config
    }, (error) => {
        // Do something with request error
        return Promise.reject(error);
    })

    return instance
}

export const axiosInstance = makeInstance()

export default axiosInstance
