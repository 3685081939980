import Search from "../../commons/search";
import Button from "../../commons/button";
import Table from "../../commons/table";
import {TABLE_PAYROLL_DETAIL} from "../../../configs/payroll/configTablePayrollList";
import Toast from "../../commons/toast";
import icFileUp from "../../../assets/images/ic-file-up-02.svg";
import {ExportModal} from "../../commons/ExportModal";
import icFilter from "../../../assets/images/ic-filter-new.svg";
import Select from "../../commons/select";
import {findOptionSelected} from "../../../utils/utils";

export const PayrollDetail = (
    {
        dataList,
        columnTable,
        isLoading,
        query,
        optionsFilter,
        isShowToast,
        toastMessage,
        modalExport,
        setModalExport,
        onExport,
        clearToast,
        onChangeQuery,
    }) => {

    return (
        <div className={"flex flex-col"}>
            <div className={"flex space-x-7 px-6 py-4"}>
                <Search value={query?.search} onSearch={(search) => onChangeQuery({page: 1, search})}/>
                <div className={"flex gap-3"}>
                    <div className={"flex items-center gap-2"}>
                        <img src={icFilter} alt="ic-filter"/>
                        <p className="text-neutral-70">Filter by Department:</p>
                        <Select
                            name="department_id"
                            className="w-[300px]"
                            optionsClassName="max-h-40"
                            placeholder="Pilih Department"
                            options={optionsFilter}
                            value={findOptionSelected(optionsFilter, Number(query?.department_id)) || optionsFilter[0]}
                            onChange={(item) => onChangeQuery({page: 1, [item?.name]: item?.value?.value})}
                            searchable
                        />
                    </div>
                    <Button title="Export" icon={icFileUp} type="outline" disabled={!dataList?.total_result}
                            onClick={() => setModalExport(true)}/>
                </div>
            </div>
            <Table
                className="my-3"
                name={TABLE_PAYROLL_DETAIL.name}
                columns={columnTable}
                data={dataList?.result?.data}
                size={query?.limit}
                currentPage={query?.page}
                totalPage={dataList?.total_page}
                totalResult={dataList?.total_result}
                onChangePage={(val) => onChangeQuery({page: val})}
                onClickSort={(val) => onChangeQuery(val)}
                loading={isLoading}
            />
            <ExportModal
                title="Apakah anda yakin ingin export data payroll detail?"
                description={`Total data payroll detail export: ${dataList?.total_result}`}
                show={modalExport}
                onExportHandler={onExport}
                onCancelHandler={() => setModalExport(false)}
            />
            <Toast
                show={isShowToast}
                message={toastMessage}
                onClose={() => clearToast()}
            />
        </div>
    )
}
