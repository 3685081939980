import Button from "../../components/commons/button";
import icEdit from "../../assets/images/ic-edit-04.svg";
import Badge from "../../components/commons/badge";

export const TABLE_SETTING_COMPONENT = {
    name: 'Data Komponen Payroll',
    column: [
        {
            name: "Nama Komponen",
            key: "title",
            className: "font-medium",
        },
        {
            name: "Tipe Komponen",
            key: "type",
            sortable: true,
            render: ({value}) => <div className={`font-medium capitalize`}>{value}</div>
        },
        {
            name: "Kategori",
            key: "category",
            render: ({value}) => <div className={`${!value ? 'text-neutral-60' : 'text-gray-800'} font-medium capitalize`}>{!!value ? value.label : 'None'}</div>
        },
        {
            name: "Status",
            key: "is_enabled",
            width: 250,
            render: ({value}) => <Badge title={!!value ? 'Active' : 'Inactive'} type={!!value ? 'active' : 'inactive'}/>
        },
        {
            name: "Action",
            key: "action",
            render: ({item, onClickItem}) => (
                <div className="flex gap-3 mx-auto">
                    <Button icon={icEdit} type="outline" title={'Edit'} onClick={() => onClickItem(item)}/>
                </div>
            )
        }
    ]
}
