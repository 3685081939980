import {ValidationData} from "../../../../components/payroll/create-payroll/import-data-payroll/ValidationData";
import {useEffect, useState} from "react";
import {useFetch} from "../../../../services/useFetch";
import {API_CREATE_PAYROLL} from "../../../../services/apiCreatePayroll";

export const ValidationDataContainer = ({params, payrollId, payload, onChangeStep, setProgressing, setErrorValidation}) => {
    const [percentage, setPercentage] = useState(0)
    const [isComplete, setIsComplete] = useState(false)
    const [errorDetail, setErrorDetail] = useState([])
    const API = params === 'percentage-incentive' ? API_CREATE_PAYROLL.IMPORT_VALIDATION_INCENTIVE : API_CREATE_PAYROLL.IMPORT_VALIDATION_COMPONENT
    const {data: response, fetching: importValidation, isLoading} = useFetch({...API, initPathVariable: payrollId})

    useEffect(() => {
        if (percentage === 10) {
            importValidation(payload, null, {'Content-Type': 'multipart/form-data'})
                .then().catch(() => {
                setProgressing(false)
                onChangeStep(1)
            })
        }
    }, [percentage])

    useEffect(() => {
        let timeout = null
        if (percentage <= 80) {
            timeout = setTimeout(() => {
                setPercentage(percentage + 10)
            }, 1000)
        } else if (!isLoading && response?.data) {
            timeout = setTimeout(() => {
                if (percentage === 100) {
                    setIsComplete(true)
                    setProgressing(false)
                    setErrorDetail(response?.data?.error_detail)
                    setErrorValidation(!!response?.data?.statistics?.total_invalid)
                } else {
                    setPercentage(percentage + 10)
                }
            }, 1000)
        }

        return () => {
            clearTimeout(timeout)
        }
    }, [percentage, isLoading])

    const onImportData = () => {
        setProgressing(true)
        onChangeStep(3)
    }

    const props = {
        params,
        percentage,
        isComplete,
        errorDetail,
        onImportData
    }

    return (
        <ValidationData {...props}/>
    )
}
