import icChevRightWhite from "assets/images/ic-chev-right-white.svg"
import clsx from "clsx"
import ErrorMessage from "../../commons/error-message";

export const FormHeader = ({ icon, title, optional, expanded, onClick, errorMessage }) => {
    return (
        <div className="px-6">
            <div className="flex items-center justify-between px-3 py-6">
                <div className="flex items-center gap-3">
                    <img className="w-4 h-4" src={icon} alt={title} />
                    <div className="font-semibold text-md text-neutral-80">{title}</div>
                    {optional && <span className="font-medium text-md text-neutral-60">{'(Optional)'}</span>}
                </div>
                <div className={"flex flex-row items-center space-x-3"}>
                    {
                        errorMessage &&
                        <ErrorMessage message={errorMessage} />
                    }
                    <div className="w-6 h-6 rounded-full p-1 bg-primary-50 cursor-pointer" onClick={onClick}>
                        <img className={clsx("w-4 h-4 transition duration-200", expanded && "rotate-90")} src={icChevRightWhite} alt='chev' />
                    </div>
                </div>
            </div>
            {expanded && <div className="h-px w-full bg-divider" />}
        </div>
    )
}
