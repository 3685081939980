import Modal from "../modal/Modal";
import Button from "../button";
import icTrash from "../../../assets/images/ic-trash.svg"

const DeleteModal = ({show, onHide, onDelete, text, isLoading}) => {
    return (
        <Modal
            dialogClassName='w-full max-w-[328px]'
            contentClassName='rounded-xl'
            show={show}
            onHide={onHide}
        >
            <div>
                <div className={"py-8 px-4 flex flex-col justify-center items-center space-y-4"}>
                    <img src={icTrash} alt={'trash'} className={"w-12 h-12 p-2 border rounded-full bg-primary-10 border-primary-30"}/>
                    <p className={"text-center"}>{text}</p>
                </div>
                <div className={"px-6 py-3 flex flex-row border-t space-x-3 justify-center"}>
                    <Button type={'outline'} title={'Cancel'} onClick={onHide}/>
                    <Button title={'Delete'} loading={isLoading} onClick={onDelete}/>
                </div>
            </div>
        </Modal>
    )
}

export default DeleteModal
