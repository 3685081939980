import { EmergencyContactTab } from "components/employee/employee-detail/employee-detail-tab/EmergencyContactTab";
import { TABLE_EMERGENCY_CONTACT } from "configs/employee/configTableAddEmployee";
import { ACTION_TYPE } from "constants/constant";
import { DEFAULT_FORM_EMERGENCY_CONTACT, OPTIONS_FAMILY_RELATIONSHIP, OTHER_RELATIONSHIP } from "constants/defaultFormEmployee";
import { useValidation } from "hooks/useValidation";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setToast, updateEmergencyContact } from "reducers/employeeDetailReducer";
import { addFamilyValidation } from "services/addEmployeeValidation";
import { API_EMPLOYEE } from "services/apiEmployee";
import { METHOD } from "services/method";
import { useFetch } from "services/useFetch";
import { clearPhoneNumber, formatPhoneNumber } from "utils/utils";

export const EmergencyContactTabContainer = () => {
    const dispatch = useDispatch()
    const { id, emergency_contacts } = useSelector((state) => state.employeeDetailReducer)
    const [showModal, setShowModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [dataEmergency, setDataEmergency] = useState(DEFAULT_FORM_EMERGENCY_CONTACT)
    const [selectedIndex, setSelectedIndex] = useState()
    const [actionType, setActionType] = useState(ACTION_TYPE.CREATE)
    const { fetching: addEmergencyContact, isLoading: isLoadingAdd } = useFetch({ ...API_EMPLOYEE.EMERGENCY_CONTACT, initPathVariable: id })
    const { fetching: patchEmergencyContact, isLoading: isLoadingPatch } = useFetch({ ...API_EMPLOYEE.EMERGENCY_CONTACT, initPathVariable: dataEmergency?.id, method: METHOD.PUT })
    const { fetching: deleteEmergencyContact, isLoading: isLoadingDelete } = useFetch({ ...API_EMPLOYEE.EMERGENCY_CONTACT, initPathVariable: dataEmergency?.id, method: METHOD.DELETE })
    const { errorForm, validateForm, isFormValid } = useValidation({ form: dataEmergency, schema: addFamilyValidation })
    const isOtherRelationship = dataEmergency?.relationship?.value === OTHER_RELATIONSHIP

    const onChangeForm = (e) => {
        const { name, value } = e
        setDataEmergency({
            ...dataEmergency,
            [name]: value,
            ...(!isOtherRelationship && { other_relationship: null }),
        })
    }

    const onCloseModal = () => {
        setShowModal(false)
        setDataEmergency(DEFAULT_FORM_EMERGENCY_CONTACT)
        setActionType(ACTION_TYPE.CREATE)
    }

    const onCloseDeleteModal = () => {
        setShowDeleteModal(false)
        setDataEmergency(DEFAULT_FORM_EMERGENCY_CONTACT)
    }

    const onSubmit = () => {
        validateForm().then(() => {
            const newEmergencyContacts = [...emergency_contacts]
            const paramsEmergencyContact = {
                ...dataEmergency,
                phone_number: formatPhoneNumber(dataEmergency.phone_number),
                relationship: dataEmergency.relationship.value === OTHER_RELATIONSHIP ? dataEmergency.other_relationship : dataEmergency.relationship.value,
            }

            const onSuccess = (message) => {
                dispatch(updateEmergencyContact(newEmergencyContacts))
                dispatch(setToast({isShowToast: true, message}))
                onCloseModal()
            }

            switch (actionType) {
                case ACTION_TYPE.CREATE:
                    addEmergencyContact(paramsEmergencyContact).then((response) => {
                        newEmergencyContacts.push(response.data)
                        onSuccess('Emergency Contact Created !!')
                    })
                    break;
                case ACTION_TYPE.EDIT:
                    patchEmergencyContact(paramsEmergencyContact).then((response) => {
                        newEmergencyContacts[selectedIndex] = response.data
                        onSuccess('Emergency Contact Updated !!')
                    })
                    break;
                default:
            }
        })
    }

    const handleEdit = () => {
        setActionType(ACTION_TYPE.EDIT)
        setShowModal(true)
    }

    const handleRemove = (index) => {
        deleteEmergencyContact().then(() => {
            const newEmergencyContacts = [...emergency_contacts]
            newEmergencyContacts.splice(index, 1)
            dispatch(updateEmergencyContact(newEmergencyContacts))
            dispatch(setToast({isShowToast: true, message: 'Emergency Contact Deleted !!'}))
            onCloseDeleteModal()
        })
    }

    const onActionRow = ({ index, item, actionType }) => {
        const findRelationship = OPTIONS_FAMILY_RELATIONSHIP.find((find) => find.value === item.relationship)
        setSelectedIndex(index)
        setDataEmergency({
            ...item,
            phone_number: clearPhoneNumber(item.phone_number),
            relationship: findRelationship || { value: OTHER_RELATIONSHIP, label: OTHER_RELATIONSHIP },
            ...(!findRelationship && { other_relationship: item.relationship }),
        })
        switch (actionType) {
            case ACTION_TYPE.EDIT:
                return handleEdit();
            case ACTION_TYPE.DELETE:
                return setShowDeleteModal(true);
            default:
        }
    }

    const checkValidationForm = () => {
        const checkForm = { ...dataEmergency }
        if (!isOtherRelationship) delete checkForm.other_relationship
        return isFormValid(checkForm)
    }

    const props = {
        configTable: TABLE_EMERGENCY_CONTACT,
        optionsFamilyRelationship: OPTIONS_FAMILY_RELATIONSHIP,
        dataEmergency,
        errorForm,
        isFormValid: checkValidationForm(),
        isEdit: actionType === ACTION_TYPE.EDIT,
        form: emergency_contacts,
        showModal,
        onShowModal: () => setShowModal(true),
        onCloseModal,
        onChangeForm,
        onSubmit,
        onActionRow,
        showDeleteModal,
        onCloseDeleteModal,
        onDelete: () => handleRemove(selectedIndex),
        isLoadingSubmit: isLoadingAdd || isLoadingPatch,
        isLoadingDelete
    }

    return (
        <EmergencyContactTab  {...props} />
    )
}
