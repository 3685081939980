import clsx from "clsx";
import './stepper.scss'

const Stepper = ({className, typeLine = "solid", isVertical, steps = [], activeStep = 1}) => {
    return (
        <div className={clsx('avo__stepper', isVertical && 'vertical', className)}>
            {
                steps.map((item, index) => (
                    <div key={index}
                         className={clsx('step-wrapper', isVertical && 'vertical', (activeStep === index + 1 || activeStep > index) && 'active')}>
                        {
                            isVertical ?
                                <div className="vertical-content">
                                    <div>
                                        <div className="circle-icon">{index + 1}</div>
                                        {
                                            steps.length - 1 !== index && (
                                                <div className={clsx('line vertical', typeLine)}/>
                                            )
                                        }
                                    </div>
                                    <div className="step-item">{item}</div>
                                </div>
                            :
                                <>
                                    <div className="circle-icon">{index + 1}</div>
                                    <div className="step-item">{item}</div>
                                    {
                                        steps.length - 1 !== index && (
                                            <div className={clsx('line', typeLine)}/>
                                        )
                                    }
                                </>
                        }
                    </div>
                ))
            }
        </div>
    )
}

export default Stepper
