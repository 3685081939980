import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    toast: {
        message: '',
        isShowToast: false
    }
}

export const accountSlice = createSlice({
    name: 'account',
    initialState: initialState,
    reducers: {
        setToast: (state, action) => {
            state.toast = {...state.toast, ...action.payload}
        }
    }
})

export const {setToast} = accountSlice.actions

const accountReducer = accountSlice.reducer
export default accountReducer
