import {SelectMonth} from "../../../components/payroll/create-payroll/SelectMonth";
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import {useFetch} from "../../../services/useFetch";
import {API_CREATE_PAYROLL} from "../../../services/apiCreatePayroll";
import {STEPS_CREATE_PAYROLL} from "../../../constants/constant";
import {getMonthsId} from "../../../utils/utils";

export const SelectMonthContainer = () => {
    const navigate = useNavigate()
    const [selectedMonth, setSelectedMonth] = useState(null)
    const {fetching: createPayroll} = useFetch(API_CREATE_PAYROLL.CREATE_PAYROLL)

    const onChangeMonth = (value) => {
        setSelectedMonth(value)
    }

    const onSubmit = () => {
        createPayroll({month: selectedMonth})
            .then(response => {
                const monthName = `${Object.values(getMonthsId)[response?.data?.month - 1]?.toLowerCase()}-${response?.data?.year}`
                navigate(`/payroll/create-payroll/${STEPS_CREATE_PAYROLL[1]}-${monthName}?payrollId=${response?.data?.id}`)
            })
    }

    const props = {
        selectedMonth,
        onChangeMonth,
        onSubmit
    }

    return (
        <SelectMonth {...props}/>
    )
}
