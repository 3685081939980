import Filter from "../../../components/commons/filter";
import {deepClone, getHeadUnitText} from "../../../utils/utils";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {setModal} from "../../../reducers/attendanceReducer";
import {useFetch} from "services/useFetch";
import {API_ATTENDANCE} from "services/apiAttendance";

const FILTER = {
    ATTENDANCE_DATE: 'attendance_date',
    SCHEDULE_CATEGORY: 'schedule_category_ids',
    DEPARTMENT: 'department',
    DIVISION: 'division',
    SUB_DIVISION: 'sub-division',
}

const QUERY_PARAMS = {
    [FILTER.DEPARTMENT]: 'department_ids',
    [FILTER.DIVISION]: 'division_ids',
    [FILTER.SUB_DIVISION]: 'subdivision_ids',
    [FILTER.ATTENDANCE_DATE]: FILTER.ATTENDANCE_DATE,
    [FILTER.SCHEDULE_CATEGORY]: FILTER.SCHEDULE_CATEGORY,
}

const INITIAL_DATA_FILTER = [
    {label: 'Tanggal Presensi', type: 'date',
        start_date: {
            label: 'Dari Tanggal',
            name: 'start_date',
            placeholder: 'Pilih Tanggal Awal',
            isRequired: true
        },
        end_date: {
            label: 'Sampai Tanggal',
            name: 'end_date',
            placeholder: 'Pilih Tanggal Akhir',
            isRequired: true
        },},
    {label: 'Kategori Jam Kerja', value: FILTER.SCHEDULE_CATEGORY},
    {label: 'Department', value: FILTER.DEPARTMENT},
    {label: 'Divisi', value: FILTER.DIVISION},
    {label: 'Sub Divisi', value: FILTER.SUB_DIVISION},
]

const JOB_UNIT = [FILTER.DEPARTMENT, FILTER.DIVISION, FILTER.SUB_DIVISION]

const INITIAL_OPTIONS = {
    [FILTER.SCHEDULE_CATEGORY]: [],
    [FILTER.DEPARTMENT]: [],
    [FILTER.DIVISION]: [],
    [FILTER.SUB_DIVISION]: []
}

const INITIAL_FILTER = {
    schedule_category_ids: [],
    department_ids: [],
    division_ids: [],
    subdivision_ids: [],
    start_date: '',
    end_date: ''
}

export const AttendanceFilterContainer = ({onSubmit, queryFilter}) => {
    const dispatch = useDispatch()
    const {modal} = useSelector(state => state.attendanceReducer)
    const {fetching: getJobUnitApi} = useFetch(API_ATTENDANCE.LIST_JOB_UNIT)
    const {fetching: getScheduleCategory} = useFetch(API_ATTENDANCE.SCHEDULE_CATEGORY_LIST)
    const [activeFilter, setActiveFilter] = useState(INITIAL_DATA_FILTER[0])
    const [filter, setFilter] = useState(INITIAL_FILTER)
    const [filterCount, setFilterCount] = useState()
    const [errorFilter, setErrorFilter] = useState({})
    const [options, setOptions] = useState(INITIAL_OPTIONS)

    useEffect(() => {
        if (queryFilter) {
            const newQueryFilter = deepClone(queryFilter)
            setFilter(newQueryFilter)
            countFilterLength(newQueryFilter)
        }
    }, [queryFilter, modal.filter])

    const modalVisibility = (value) => {
        dispatch(setModal({...modal, ...value}))
    }

    const onCloseHandler = () => {
        modalVisibility({'filter': false})
        setErrorFilter({})
    }

    const onSelectFilter = (item) => {
        if (JOB_UNIT.includes(item.value) && options[item.value].length === 0) {
            getJobUnitApi({head_unit_ids: filter?.[QUERY_PARAMS[getHeadUnitText[item.value]]] || []}, item.value)
                .then(res => setOptions({...options, [item.value]: res.data}))
        }

        if (item.value === FILTER.SCHEDULE_CATEGORY && options[item?.value].length === 0) {
            getScheduleCategory()
                .then(res => setOptions({...options, [FILTER.SCHEDULE_CATEGORY]: res?.data}))
        }
        setActiveFilter(item)
    }

    const handleClearOptionsFilter = (filterName) => {
        if (filterName === FILTER.DEPARTMENT) {
            options[FILTER.DIVISION] = []
            options[FILTER.SUB_DIVISION] = []
            filter[[QUERY_PARAMS[FILTER.DIVISION]]] = []
            filter[[QUERY_PARAMS[FILTER.SUB_DIVISION]]] = []
        } else if (filterName === FILTER.DIVISION) {
            options[FILTER.SUB_DIVISION] = []
            filter[[QUERY_PARAMS[FILTER.SUB_DIVISION]]] = []
        }
        setOptions({ ...options })
    }

    const onChangeOption = (item) => {
        const { name, value, checked } = item
        const arrChecked = filter?.[QUERY_PARAMS[name]] || []
        handleClearOptionsFilter(name)

        if (checked) {
            arrChecked.push(String(value))
        } else {
            const indexRemove = arrChecked.indexOf(String(value))
            arrChecked.splice(indexRemove, 1)
        }
        setFilter({ ...filter, [QUERY_PARAMS[name]]: arrChecked })
    }

    const onChangeDate = (e) => {
        const { name, value } = e
        setFilter({ ...filter, [name]: value })
    }

    const validateFilter = (query) => {
        return new Promise((resolve, reject) => {
            const errors = {}
            if (!query['start_date'] && query['end_date']) errors['start_date'] = 'Tanggal Awal tidak boleh kosong'
            if (!query['end_date'] && query['start_date']) errors['end_date'] = 'Tanggal Akhir tidak boleh kosong'
            setErrorFilter(errors)
            if (!Object.keys(errors).length) resolve()
        })
    }

    const onSubmitFilter = () => {
        const queryFilter = {...filter}
        validateFilter(queryFilter)
            .then(() => {
                Object.entries(queryFilter).forEach(([key, item]) => {
                    if (Array.isArray(queryFilter[key])) queryFilter[key] = item.join(',')
                })
                onSubmit(queryFilter)
                modalVisibility({'filter': false})
            })
    }

    const countFilterLength = (filter) => {
        let totalLength = 0
        for (const key in filter) {
            if (filter.hasOwnProperty(key)) {
                if (Array.isArray(filter[key])) {
                    totalLength += filter[key].length;
                } else {
                    totalLength += 1;
                }
            }
        }
        setFilterCount(totalLength)
    }

    const onClearFilter = () => {
        setFilter(deepClone(INITIAL_FILTER))
        setErrorFilter({})
    }

    const props = {
        show: modal.filter,
        onShow: () => modalVisibility({'filter': true}),
        onClose: onCloseHandler,
        queryParams: QUERY_PARAMS,
        filterList: INITIAL_DATA_FILTER,
        activeFilter,
        onSelectFilter,
        filter,
        filterCount,
        options,
        onChangeOption,
        onChangeDate,
        onSubmitFilter,
        onClearFilter,
        errorFilter
    }

    return (
        <Filter {...props}/>
    )
}
