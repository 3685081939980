import icUserDefault from "../../assets/images/ic-user-profile.svg";
import {InlineTabs} from "../commons/InlineTabs";
import Badge from "../commons/badge";
import {formatDateMoment, getStatusIcon} from "../../utils/utils";
import Button from "../commons/button";
import icChecked from "../../assets/images/ic-check-02.svg";
import icCross from "../../assets/images/ic-x-02.svg";
import Drawer from "../commons/drawer";

export const PreviewRemoteWorking = (
    {
        visibility,
        tabList,
        dataPreview,
        previewTab,
        optionBulk,
        activePreviewTab,
        onChangePreviewTab,
        onHide,
        onSelect
    }) => {

    return (
        <Drawer visibility={visibility} onHide={() => onHide({preview: false})} title="Detail Remote Working">
            <div className="px-6">
                <div className={"flex items-center gap-4 mt-2 mb-4"}>
                    <img className={"w-12 h-12 border border-white rounded-full"}
                         src={dataPreview?.profile_picture ?? icUserDefault} alt={dataPreview?.profile_picture}/>
                    <div className={"flex flex-col space-y-2"}>
                        <span className={"font-semibold text-md text-neutral-80"}>{dataPreview?.full_name}</span>
                        <span className={"font-medium text-neutral-80"}>{dataPreview?.title}</span>
                        <span className={"text-neutral-70"}>{dataPreview?.nip}</span>
                    </div>
                </div>
                <InlineTabs
                    className="!px-0"
                    classNameItem="w-full"
                    tabList={previewTab}
                    activeTab={activePreviewTab}
                    onClickTab={(tab) => onChangePreviewTab(tab.id)}
                />
                {activePreviewTab === previewTab[0].id ?
                    <div className={"space-y-5 py-6"}>
                        <div className="flex">
                            <div className={"w-44 text-neutral-70 pr-2"}>Department</div>
                            <div className={"flex-1 font-medium text-neutral-80"}>{dataPreview?.department || '-'}</div>
                        </div>
                        <div className="flex">
                            <div className={"w-44 text-neutral-70 pr-2"}>Alasan Remote Working</div>
                            <div className={"flex-1 font-medium text-neutral-80"}>{dataPreview?.submit_reason || '-'}</div>
                        </div>
                        <div className={"flex items-center"}>
                            <div className={"w-44 text-neutral-70 pr-2"}>Status</div>
                            <Badge title={
                                <div className={"flex items-center space-x-1"}>
                                    {
                                        getStatusIcon(dataPreview?.status) &&
                                        <img src={getStatusIcon(dataPreview?.status)} alt="ic-status"/>
                                    }
                                    <p>{dataPreview?.status}</p>
                                </div>
                            } type={dataPreview?.status}/>
                        </div>
                        {
                            dataPreview?.status === tabList[2].id &&
                            <div className="flex">
                                <div className={"w-44 text-neutral-70 pr-2"}>Note</div>
                                <div className={"flex-1 font-medium text-neutral-80"}>{dataPreview?.reject_reason || '-'}</div>
                            </div>
                        }
                        {
                            dataPreview?.status === tabList[0].id &&
                            <div className="space-y-5">
                                <div className={"font-medium text-neutral-80"}>Update Status Pengajuan</div>
                                <div className={"flex gap-2.5 px-2.5"}>
                                    <Button className="w-full" icon={icChecked} title="Approved" type="outline"
                                            onClick={() => onSelect(optionBulk?.APPROVE)}/>
                                    <Button className="w-full" icon={icCross} title="Rejected" type="danger"
                                            onClick={() => onSelect(optionBulk?.REJECT)}/>
                                </div>
                            </div>
                        }
                    </div>
                    :
                    <div className={"py-6 overflow-scroll"}>
                        {
                            dataPreview?.logs?.map((item, index) => (
                                <div className="pb-2" key={index}>
                                    <div className={"flex flex-row items-center gap-5"}>
                                        <div className={"bg-primary-50 w-5 h-5 rounded-full flex flex-col justify-center items-center"}>
                                            <div className={"bg-white w-3 h-3 rounded-full"}/>
                                        </div>
                                        <p className={"text-neutral-80 font-bold capitalize"}>{item?.type}</p>
                                    </div>
                                    <div
                                        className={`text-neutral-70 pb-5 ${index !== (dataPreview?.logs?.length - 1) ? 'border-l-2 border-neutral-50 ml-2 mt-1 pl-8' : 'pl-10'}`}>
                                        <p className={"font-semibold pt-3"}>{`Modified by ${item?.actor}`}</p>
                                        <p className="pt-3">{formatDateMoment(item?.created_at, 'DD MMM YYYY HH:mm:ss')}</p>
                                    </div>
                                </div>
                            ))
                        }
                    </div>}
            </div>
        </Drawer>
    )
}
