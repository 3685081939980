import {createSlice} from "@reduxjs/toolkit";

const INITIAL_STATE = {}

export const matrixApprovalSlice = createSlice({
    name: 'matrix-approval',
    initialState: INITIAL_STATE,
    reducers: {}
})

export const {} = matrixApprovalSlice.actions
const matrixApprovalReducer = matrixApprovalSlice.reducer

export default matrixApprovalReducer
