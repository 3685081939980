import { Quill } from "react-quill"

export const registerSmartBreak = () => {
    class SmartBreak extends Quill.import("blots/break") {
        length() {
            return 1
        }
        value() {
            return "\n"
        }
        insertInto(parent, ref) {
            Quill.import("blots/embed").prototype.insertInto.call(this, parent, ref)
        }
    }
    SmartBreak.blotName = "break"
    SmartBreak.tagName = "BR"
    Quill.register(SmartBreak, true)
}
