import { CreateRole } from "components/management-account/create-role/CreateRole"
import { useValidation } from "hooks/useValidation"
import { useEffect, useMemo, useState } from "react"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"
import { API_ACTIVATION } from "services/apiActivation"
import { API_ROLE } from "services/apiRole"
import { METHOD } from "services/method"
import { useFetch } from "services/useFetch"
import { constructOptionsSelect } from "utils/utils"
import {setToast} from "../../../reducers/accountReducer";
import {useDispatch} from "react-redux";

export const CreateRoleContainer = () => {
    const dispatch = useDispatch()
    const location = useLocation()
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const roleId = searchParams.get('roleId')
    const employeeId = searchParams.get('employeeId')
    const [selectedCapability, setSelectedCapability] = useState([])
    const [dataRole, setDataRole] = useState({
        name: null,
        company_ids: [],
        permission_ids: [],
    })
    const [selected, setSelected] = useState({
        module: '',
        menu: '',
    })
    const [keyword, setKeyword] = useState({
        menu: '',
        capability: '',
    })
    const [search, setSearch] = useState({
        menu: [],
        capability: [],
    })
    const [selectedUpdate, setSelectedUpdate] = useState({
        employee: null,
        role: null
    })
    const { data: dataListCompany } = useFetch(API_ROLE.LIST_COMPANY, {}, { isFetching: true })
    const { data: dataListModule, fetching: getModuleList } = useFetch(API_ROLE.LIST_PERMISSION, { scope: "modules", parent: "" })
    const { data: dataListMenu, fetching: getMenuList } = useFetch(API_ROLE.LIST_PERMISSION)
    const { data: dataListCapability, fetching: getCapabilityList } = useFetch(API_ROLE.LIST_PERMISSION)
    const { fetching: createRole, isLoading: isLoadingCreate } = useFetch(API_ROLE.ROLE, { ...dataRole, permission_ids: [...dataRole.permission_ids, ...selectedCapability] })
    const { fetching: updateRole } = useFetch({ ...API_ROLE.ROLE, initPathVariable: roleId, method: METHOD.PUT }, { ...dataRole, permission_ids: [...dataRole.permission_ids, ...selectedCapability] })
    const { data: dataRoleList, fetching: getRoleList } = useFetch({ ...API_ROLE.ROLE, method: METHOD.GET })
    const { data: dataRoleDetail, fetching: getRoleDetail } = useFetch({ ...API_ROLE.ROLE, initPathVariable: roleId, method: METHOD.GET })
    const { fetching: getEmployeeDetail } = useFetch({ ...API_ACTIVATION.EMPLOYEE_ACTIVATION, initPathVariable: employeeId })
    const { fetching: updateEmployeeRole } = useFetch({ ...API_ACTIVATION.EMPLOYEE_ACTIVATION, method: METHOD.PUT }, { ...dataRole, permission_ids: [...dataRole.permission_ids, ...selectedCapability], employee_ids: employeeId?.split(','), role_id: selectedUpdate?.role?.value })
    const { isFormValid } = useValidation({ form: { ...dataRole, permission_ids: [...dataRole.permission_ids, ...selectedCapability] } })
    const lengthSelectedCapability = dataListCapability?.data?.filter(item => selectedCapability.includes(item.id))?.length + dataListCapability?.data?.filter(item => dataRole?.permission_ids.includes(item.id))?.length
    const isUpdateEmployeeRole = !!selectedUpdate?.employee || !!location?.state?.selectedEmployeeList

    useEffect(() => {
        getModuleList().then((response) => {
            onSelectModule(response?.data?.[0]?.name)
        })
    }, [])

    useEffect(() => {
        if (roleId) {
            getRoleDetail().then((response) => {
                setDataRole(response.data)
            })
        }
        if (employeeId) {
            getRoleList().then((responseRole) => {
                if (employeeId?.split(',').length === 1) {
                    getEmployeeDetail().then((response) => {
                        getRoleDetail(null, response.data.role_id)
                        setDataRole({
                            name: response.data.role_name,
                            company_ids: [...response.data.company_ids],
                            permission_ids: [...response.data.permission_ids]
                        })
                        const findRole = responseRole?.data?.find((role) => role.id === response.data.role_id)
                        setSelectedUpdate({
                            employee: response.data,
                            role: findRole ? { label: findRole?.name, value: findRole?.id } : null
                        })
                    })
                }
            })
        }
    }, [roleId, employeeId])

    const optionsRole = useMemo(() => {
        if (!dataRoleList?.data || dataRoleList?.data?.length === 0) return []
        return constructOptionsSelect(dataRoleList?.data, 'name', 'id')
    }, [dataRoleList?.data])

    const onChangeForm = (e) => {
        const { name, value } = e

        const handleCheckedItem = ({ value, checked }, arrChecked) => {
            if (checked) {
                arrChecked.push(Number(value))
            } else {
                const indexRemove = arrChecked.indexOf(Number(value))
                arrChecked.splice(indexRemove, 1)
            }
            return arrChecked
        }

        switch (name) {
            case 'company_ids':
                setDataRole({ ...dataRole, [name]: handleCheckedItem(e, dataRole[name]) })
                break;
            case 'permission_ids':
                if (isUpdateEmployeeRole) {
                    const findId = dataRole.permission_ids.find((id) => id === Number(e.value))
                    if (findId) {
                        setDataRole({ ...dataRole, [name]: handleCheckedItem(e, dataRole[name]) })
                    } else {
                        setSelectedCapability([...handleCheckedItem(e, selectedCapability)])
                    }
                } else {
                    setDataRole({ ...dataRole, [name]: handleCheckedItem(e, dataRole[name]) })
                }
                break;
            case 'all':
                const allCapabilityId = dataListCapability?.data?.map(item => item.id)
                if (isUpdateEmployeeRole) {
                    setSelectedCapability(e.checked || (lengthSelectedCapability > 0 && lengthSelectedCapability < dataListCapability?.data?.length)
                        ? [...selectedCapability, ...allCapabilityId.filter(id => !dataRole.permission_ids.includes(id))]
                        : [...selectedCapability.filter(id => !allCapabilityId.includes(id))])

                    if (!(e.checked || (lengthSelectedCapability > 0 && lengthSelectedCapability < dataListCapability?.data?.length))) {
                        setDataRole({
                            ...dataRole,
                            permission_ids: [...dataRole.permission_ids.filter(id => !allCapabilityId.filter(secondId => !dataRoleDetail?.data?.permission_ids?.includes(secondId)).includes(id))]
                        })
                    }
                } else {
                    setDataRole({
                        ...dataRole,
                        permission_ids: e.checked || (lengthSelectedCapability > 0 && lengthSelectedCapability < dataListCapability?.data?.length)
                            ? [...dataRole.permission_ids, ...allCapabilityId]
                            : [...dataRole.permission_ids.filter(id => !allCapabilityId.includes(id))]
                    })
                }
                break;
            default:
                setDataRole({ ...dataRole, [name]: value })
                break
        }
    }

    const onSelectRole = (role) => {
        setSelectedUpdate({ ...selectedUpdate, role: role.value })
        getRoleDetail(null, role.value.value).then((response) => {
            setDataRole({
                name: response.data.name,
                company_ids: [...response.data.company_ids],
                permission_ids: [...response.data.permission_ids]
            })
            setSelectedCapability([])
        })
    }

    const onSelectModule = (module) => {
        if (selected.module === module) return
        setSelected({ ...selected, module })
        getMenuList({ scope: "menus", parent: module })
    }

    const onSelectMenu = (menu) => {
        if (selected.menu === menu) return
        setSelected({ ...selected, menu })
        getCapabilityList({ scope: "capabilities", parent: menu })
    }

    const onSearch = (name, value) => {
        setKeyword({ ...keyword, [name]: value })
        const searchData = name === 'menu' ? dataListMenu?.data : dataListCapability?.data
        if (!searchData || searchData?.length === 0) return
        const searchResult = searchData?.filter((item) => item.name.toLowerCase().includes(value.toLowerCase()))
        setSearch({ ...search, [name]: [...searchResult] })
    }

    const onSubmit = () => {
        const submit = employeeId ? updateEmployeeRole : roleId ? updateRole : createRole
        submit().then(() => {
            dispatch(setToast({isShowToast: true, message: `${employeeId ? 'Employee' : 'Role'} Access ${employeeId || roleId ? 'Updated' : 'Created'} !!`}))
            navigate(employeeId ? '/management-account/account-activation' : '/management-account/role-access', { replace: true })
        })
    }

    const props = {
        dataRole,
        selected,
        isFormValid: isFormValid(),
        companyList: dataListCompany?.data,
        moduleList: dataListModule?.data,
        menuList: keyword.menu ? search.menu : dataListMenu?.data,
        capabilityList: keyword.capability ? search.capability : dataListCapability?.data,
        selectedCapability,
        lengthSelectedCapability,
        optionsRole,
        selectedRole: selectedUpdate?.role,
        selectedEmployee: selectedUpdate?.employee,
        selectedEmployeeList: location?.state?.selectedEmployeeList,
        isLoadingCreate,
        dataRoleDetail: dataRoleDetail?.data,
        isUpdateEmployeeRole,
        onSelectRole,
        onSelectModule,
        onSelectMenu,
        onChangeForm,
        onSearch,
        onSubmit,
    }

    return (
        <CreateRole {...props} />
    )
}
