import Search from "../../commons/search";
import icImport from "../../../assets/images/ic-file-down-02.svg";
import Button from "../../commons/button";
import Table from "../../commons/table";
import {TABLE_CONTRACT_EMP_LIST} from "../../../configs/contract/configTableCreateContract";
import ViewList from "../../commons/view-list";

export const RegisteredEmployee = (
    {
        employeeListResponse,
        onSearch,
        onChangePage,
        modal,
        query,
        onChangeSize,
        modalVisibility
    }) => {

    return (
        <div>
            <div className={"flex flex-row justify-between px-6 pt-6 pb-4"}>
                <div className={"flex flex-row"}>
                    <Search className="mr-4" value={query.search} onSearch={onSearch}/>
                    <ViewList
                        show={modal?.sort}
                        onShow={() => modalVisibility({ sort: true })}
                        onClose={() => modalVisibility({ sort: false })}
                        value={query?.limit}
                        onSelectSize={onChangeSize}
                    />
                </div>
                <Button icon={icImport} type="outline" title={"Import"}
                        onClick={() => window.open('/management-contract/create-contract/import-contract', '_blank', 'noopener,noreferrer')}/>
            </div>
            <Table
                className="my-3"
                name={TABLE_CONTRACT_EMP_LIST.name}
                data={employeeListResponse?.data?.result}
                columns={TABLE_CONTRACT_EMP_LIST.column}
                size={query?.limit}
                currentPage={query?.page}
                totalPage={employeeListResponse?.data?.total_page}
                totalResult={employeeListResponse?.data?.total_result}
                onChangePage={onChangePage}
                loading={employeeListResponse?.isLoading}
            />
        </div>
    )
}
