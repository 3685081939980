import moment from "moment";
import { Link } from "react-router-dom";
import icPeople from "../../assets/images/ic-user-profile-03.svg";
import { getTextStatusColor } from "../../utils/utils";

export const TABLE_ATTENDANCE_LIST = {
    name: 'Attendance',
    column: [
        {
            name: "Name",
            key: "employee",
            className: "font-semibold",
            render: ({item, value}) => {
                return (
                    <Link to={`/attendance/attendance-list/detail-attendance?id=${item.id}`}>
                        <div className={'flex flex-row gap-3 items-center'}>
                            <img className={'w-7 h-7 rounded-full border border-neutral-50'}
                                 src={value?.profile_picture ?? icPeople}
                                 alt={'user-img'}/>
                            <div className={"capitalize"}>{value?.full_name}</div>
                        </div>
                    </Link>
                )
            }
        }, {
            name: "Job Title",
            key: "job_title",
            className: "font-semibold",
            render: ({value}) => value.title
        }, {
            name: "Tanggal Presensi",
            key: "date",
            render: ({ value }) => moment(value).format('DD MMMM YYYY')
        }, {
            name: "Clock In",
            key: "clock_in_time",
        }, {
            name: "Clock Out",
            key: "clock_out_time",
        }, {
            name: "Keterangan",
            key: "annotation",
            className: "font-semibold",
            render: ({value}) => <p className={`capitalize ${getTextStatusColor[value]}`}>{value}</p>
        }
    ]
}
