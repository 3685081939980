import {STATUS_TYPE} from "./constant";

export const SALARY_ALLOWANCE = {
    label: 'komponen gaji',
    form: [
        {
            label: 'status PTKP',
            type: 'select',
            key: 'ptkp_status',
            placeholder: 'Pilih status PTKP',
            required: true,
        }, {
            label: 'gaji pokok',
            key: 'base_salary',
            inputType: 'currency',
            type: 'input',
            prefix: 'Rp',
            placeholder: '0',
            isCurrency: true,
            required: true
        }
    ]
}

export const CONTRACT_NUMBER = {
    label: 'informasi kontrak',
    form: [
        {
            label: 'input nomor kontrak',
            labelDetail: 'Nomor Kontrak',
            type: 'input',
            key: 'number',
            required: true
        },
        {
            label: 'input NIP',
            labelDetail: 'NIP',
            type: 'input',
            key: 'nip',
            required: true
        }
    ]
}

export const ADDENDUM_CONTRACT_NUMBER = {
    label: 'informasi kontrak',
    form: [
        {
            label: 'input nomor kontrak',
            labelDetail: 'Nomor Kontrak',
            type: 'input',
            key: 'number',
            disabled: true,
            required: true
        },
        {
            label: 'input NIP',
            labelDetail: 'NIP',
            type: 'input',
            key: 'nip',
            required: true
        }
    ]
}

export const ADDENDUM_NUMBER = {
    label: 'Addendum',
    form: [
        {
            label: 'nomor addendum',
            type: 'input',
            key: 'addendum_number',
            required: true
        }, {
            label: 'tanggal berlaku addendum',
            type: 'date',
            key: 'addendum_start_date',
            required: true
        }
    ]
}

export const POSITION = {
    label: 'penempatan posisi',
    form: [
        {
            label: 'pilih PT',
            labelDetail: 'Perusahaan',
            type: 'select',
            key: 'company',
            required: true,
        }, {
            label: 'tipe kontrak',
            type: 'select',
            key: 'type',
            required: true,
        }, {
            label: 'pilih job title',
            labelDetail: 'job title',
            type: 'select',
            key: 'job_title',
            required: true,
        }, {
            label: 'tanggal mulai kontrak',
            type: 'date',
            key: 'start_date',
            required: true,
        }, {
            label: 'pilih grade',
            labelDetail: 'grade',
            type: 'select',
            key: 'grade',
            required: true,
        }, {
            label: 'tanggal berakhir kontrak',
            type: 'date',
            key: 'end_date',
            hide: true,
            required: true,
        }, {
            label: 'uraian pekerjaan',
            type: 'text-editor',
            key: 'job_description',
            placeholder: 'Isi Uraian Pekerjaan',
            required: true,
        },
    ]
}

export const FORM_PREVIEW_USER = [
    {
        label: 'Perusahaan',
        key: 'company'
    }, {
        label: 'Job Title',
        key: 'title'
    }, {
        label: 'Grade',
        key: 'grade_name'
    }, {
        label: 'Tipe Kontrak',
        key: 'type'
    }
]

export const FORM_SALARY_COMPONENT = [
    {
        label: 'Gaji Pokok',
        key: 'base_salary'
    }
]

export const DOCUMENT_REQUIREMENT_CONST = {
    [STATUS_TYPE.ACTIVE]: [
        {
            label: 'Upload Doc Kontrak',
            type: 'upload',
            key: 'contract_document_url',
            required: true
        }, {
            label: 'Upload Doc HKI',
            type: 'upload',
            key: 'hki_document_url',
            required: false
        }
    ],
    [STATUS_TYPE.ADDENDUM]: [
        {
            label: 'Upload Doc Addendum',
            type: 'upload',
            key: 'contract_document_url',
            required: true
        }, {
            label: 'Upload Surat Keputusan',
            type: 'upload',
            key: 'sk_document_url',
            required: false
        }, {
            label: 'Notes Addendum',
            type: 'description',
            key: 'notes',
            required: true
        }
    ],
    [STATUS_TYPE.RENEW]: [
        {
            label: 'Upload Contract',
            type: 'upload',
            key: 'contract_document_url',
            required: true
        }, {
            label: 'Upload Surat Keputusan',
            type: 'upload',
            key: 'sk_document_url',
            required: false
        }, {
            label: 'Notes Addendum',
            type: 'description',
            key: 'notes',
            required: true
        }
    ],
}

export const CONTRACT_DOCUMENT_LIST = [
    {
        label: 'Doc Kontrak',
        key: 'contract_document_url'
    }, {
        label: 'Doc HKI',
        key: 'hki_document_url'
    }, {
        label: 'Doc SK',
        key: 'sk_document_url'
    }
]

export const contractDetailObj = {
    job_title: null,
    grade: null,
    ptkp_status: null,
    type: null,
    number: null,
    nip: null,
    start_date: null,
    job_description: null,
    base_salary: null,
}

export const CONTRACT_DETAIL_CONST = [
    CONTRACT_NUMBER,
    POSITION,
    SALARY_ALLOWANCE
]

export const ADDENDUM_CONTRACT_DETAIL_CONST = [
    CONTRACT_NUMBER,
    ADDENDUM_NUMBER,
    POSITION,
    SALARY_ALLOWANCE
]

export const FORM_CREATE_CONTRACT = [
    CONTRACT_NUMBER,
    POSITION,
    SALARY_ALLOWANCE
]

export const FORM_ADDENDUM_CONTRACT = [
    ADDENDUM_CONTRACT_NUMBER,
    ADDENDUM_NUMBER,
    POSITION,
    SALARY_ALLOWANCE
]
