import FormModal from "../../commons/form-modal";
import Input from "../../commons/input";
import Select from "../../commons/select";
import ErrorMessage from "../../commons/error-message";
import TextEditor from "../../commons/text-editor";

export const JobTitleSettingsForm = (
    {
        title,
        show,
        onHide,
        activatedCompany,
        jobTitleDetail = {},
        optionList = {},
        errorForm = {},
        onChangeValue,
        onClickField,
        isCreating,
        isUpdating,
        onSubmitForm
    }) => {

    return (
        <FormModal
            title={title}
            show={show}
            width="max-w-[985px]"
            contentClassName="overflow-auto"
            onHide={onHide}
            isLoading={isCreating || isUpdating}
            onSubmitForm={onSubmitForm}
        >
            <div className={"grid grid-cols-2 gap-x-8 gap-y-6 px-6 py-8"}>
                <div className={"flex text-neutral-70"}>
                    <div className={"w-1/3 mt-2"}>PT</div>
                    <Input className="flex-1" name="company_name" placeholder="Isi PT" value={activatedCompany?.name}
                           disabled/>
                </div>
                <div className={"flex text-neutral-70"}>
                    <div className={"w-1/3 required mt-2"}>Job Title</div>
                    <div className="flex-1">
                        <Input name="title" placeholder="Isi Job Title" value={jobTitleDetail?.title}
                               onChange={(val) => onChangeValue(val)}/>
                        <ErrorMessage className="mt-2" message={errorForm?.title}/>
                    </div>
                </div>
                <div className={"flex text-neutral-70"}>
                    <div className={"w-1/3 required mt-2"}>Management</div>
                    <div className="flex-1">
                        <Select
                            name="management_id"
                            optionsClassName="max-h-40"
                            placeholder="Pilih Management"
                            options={optionList?.job_unit?.management}
                            value={jobTitleDetail?.management_id}
                            onChange={(val) => onChangeValue(val, 'job_unit')}
                            searchable
                        />
                        <ErrorMessage className="mt-2" message={errorForm?.management_id}/>
                    </div>
                </div>
                <div className={"flex text-neutral-70"}>
                    <div className={"w-1/3 mt-2"}>Department</div>
                    <Select
                        name="department_id"
                        className="flex-1"
                        optionsClassName="max-h-40"
                        placeholder="Pilih Department"
                        options={optionList?.job_unit?.department}
                        value={jobTitleDetail?.department_id}
                        onChange={(val) => onChangeValue(val, 'job_unit')}
                        onClickField={(val) => onClickField(val)}
                        searchable
                    />
                </div>
                <div className={"flex text-neutral-70"}>
                    <div className={"w-1/3 mt-2"}>Divisi</div>
                    <Select
                        name="division_id"
                        className="flex-1"
                        optionsClassName="max-h-40"
                        placeholder="Pilih Divisi"
                        options={optionList?.job_unit?.division}
                        value={jobTitleDetail?.division_id}
                        onChange={(val) => onChangeValue(val, 'job_unit')}
                        onClickField={(val) => onClickField(val)}
                        searchable
                    />
                </div>
                <div className={"flex text-neutral-70"}>
                    <div className={"w-1/3 mt-2"}>Sub Divisi</div>
                    <Select
                        name="sub_division_id"
                        className="flex-1"
                        optionsClassName="max-h-40"
                        placeholder="Pilih Sub Divisi"
                        options={optionList?.job_unit?.['sub-division']}
                        value={jobTitleDetail?.sub_division_id}
                        onChange={(val) => onChangeValue(val)}
                        onClickField={(val) => onClickField(val)}
                        searchable
                    />
                </div>
                <div className={"flex text-neutral-70 mb-10"}>
                    <div className={"w-1/3 mt-2"}>Uraian Pekerjaan</div>
                    <div className="flex-1">
                        <TextEditor
                            defaultValue={jobTitleDetail?.description}
                            onChange={(val) => onChangeValue({name: "description", value: val})}
                            placeholder="Isi Uraian Pekerjaan"
                        />
                    </div>
                </div>
                <div className={"flex text-neutral-70"}>
                    <div className={"w-1/3 required mt-2"}>Grade</div>
                    <div className="flex-1">
                        <Select
                            name="grade_ids"
                            className="flex-1"
                            optionsClassName="max-h-40"
                            placeholder="Pilih Grade"
                            options={optionList?.grade}
                            value={jobTitleDetail?.grade_ids}
                            onChange={(val) => onChangeValue(val)}
                            isMultiple
                        />
                        <ErrorMessage className="mt-2" message={errorForm?.grade_ids}/>
                    </div>
                </div>
                <div className={"flex text-neutral-70"}>
                    <div className={"w-1/3 required mt-2"}>Kategori Jam Kerja</div>
                    <div className="flex-1">
                        <Select
                            name="schedule_category_id"
                            className="flex-1"
                            optionsClassName="max-h-40"
                            placeholder="Pilih Kategori Jam Kerja"
                            options={optionList?.working_time_category}
                            value={jobTitleDetail?.schedule_category_id}
                            onChange={(val) => onChangeValue(val)}
                        />
                        <ErrorMessage className="mt-2" message={errorForm?.schedule_category_id}/>
                    </div>
                </div>
                <div className={"flex text-neutral-70"}>
                    <div className={"w-1/3 mt-2"}>N+1 Job Title</div>
                    <Select
                        name="direct_report_id"
                        className="flex-1"
                        optionsClassName="max-h-40"
                        placeholder="Pilih N+1 Job Title"
                        options={optionList?.n1}
                        value={jobTitleDetail?.direct_report_id}
                        onChange={(val) => onChangeValue(val)}
                        searchable
                    />
                </div>
                <div className={"flex text-neutral-70"}>
                    <div className={"w-1/3 required mt-2"}>Kuota Karyawan</div>
                    <div className="flex-1">
                        <Input name="quota" type="number" placeholder="Isi Total Karyawan" value={jobTitleDetail?.quota}
                               onChange={(val) => onChangeValue(val)}/>
                        <ErrorMessage className="mt-2" message={errorForm?.quota}/>
                    </div>
                </div>
            </div>
        </FormModal>
    )
}
