import {InlineTabs} from "../../commons/InlineTabs";

export const ContractEmployee = ({tabList, activeTab, onClickTab, children}) => {
    return (
        <div>
            <InlineTabs tabList={tabList} activeTab={activeTab} onClickTab={(e) => onClickTab(e)}/>
            {children}
        </div>
    )
}
