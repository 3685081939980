import icPeople from "../../assets/images/ic-user-profile-03.svg";
import Badge from "../../components/commons/badge";
import Button from "../../components/commons/button";
import icEyeOpen from "../../assets/images/ic-eye-open.svg";
import {Link} from "react-router-dom";
import icFileEye from "../../assets/images/ic-file-eye-02.svg";

export const TABLE_CONTRACT_LIST = {
    name: 'List Contract Employee',
    column: [
        {
            name: "Name",
            key: "full_name",
            className: "font-semibold",
            sortable: true,
            render: ({item}) => {
                return (
                    <div className={'flex flex-row gap-3 items-center'}>
                        <img className={'w-7 h-7 rounded-full border border-neutral-50'}
                             src={item?.profile_picture ?? icPeople}
                             alt={'user-img'}/>
                        <div className={"capitalize"}>{item?.full_name}</div>
                    </div>
                )
            }
        }, {
            name: "NIP",
            key: "nip",
            sortable: true
        }, {
            name: "Posisi",
            key: "title",
            className: "font-semibold",
        }, {
            name: "No. Kontrak",
            key: "number",
        }, {
            name: "Tgl Mulai",
            key: "start_date",
            sortable: true
        }, {
            name: "Tgl Berakhir",
            key: "end_date",
        }, {
            name: "Status",
            key: "status",
            className: "text-center",
            render: ({value}) => <Badge title={value} type={value === 'contract-rejected' ? 'rejected' : value}/>
        }, {
            name: "Action",
            key: "id",
            className: "text-center",
            render: ({value, item, onClickItem}) => <div className={"flex flex-row items-center gap-[10px]"}>
                <Button icon={icEyeOpen} type={'outline'} onClick={() => onClickItem(item)}/>
                <Link to={`/management-contract/contract-employee/employee-detail?contractId=${value}`}>
                    <Button icon={icFileEye} title={'Detail'} type={'outline'}/>
                </Link>
            </div>
        }
    ]
}
