import icEmail from "../../../assets/images/ic-email.svg";
import icUser from "../../../assets/images/ic-user-profile.svg";
import icCheck from "../../../assets/images/ic-check-broken-primary.svg";

export const SidePartEmployee = ({employeeDetail}) => {
    return (
        <div className={"p-6 space-y-8 bg-neutral-10 border rounded shadow-sm"}>
            <p className={"text-primary-60 font-bold"}>Detail user</p>
            <img src={employeeDetail?.profile_picture ?? icUser} alt={'profile picture'}
                 className={"w-16 h-16 rounded-full border"}/>
            <div>
                <p className={"text-lg font-semibold text-neutral-80 mb-5"}>{employeeDetail?.full_name}</p>
                <div className={"flex flex-col space-y-4"}>
                    <div className={"flex flex-row gap-2"}>
                        <img src={icEmail} alt={'email'}/>
                        <p className={"text-primary-50"}>{employeeDetail?.personal_email}</p>
                    </div>
                    <div className={"flex flex-row gap-2"}>
                        <p className={"text-neutral-70 font-medium"}>No. NIK</p>
                        <p className={"text-neutral-80 font-semibold"}>{employeeDetail?.id_card_number}</p>
                    </div>
                    <div className={"flex flex-row gap-2"}>
                        <p className={"text-neutral-70 font-medium"}>No. HP</p>
                        <p className={"text-neutral-80 font-semibold"}>+{employeeDetail.phone_number}</p>
                    </div>
                </div>
            </div>
            <div className={"flex flex-col space-y-5"}>
                <div className={"flex flex-row space-x-5 items-center"}>
                    <p className={"font-semibold"}>Nomor Rekening</p>
                    {!!employeeDetail?.is_bank_verified &&
                        <img src={icCheck} alt={'icCheck'} className={"w-4"}/>}
                </div>
                <img src={employeeDetail.bank_image} alt={'bank image'} className={"w-[100px]"}/>
                <p>{employeeDetail?.bank_name}</p>
                <div className={"flex flex-row gap-2"}>
                    <p className={"text-neutral-50 font-medium"}>No. Rek</p>
                    <p className={"text-neutral-80 font-semibold"}>{employeeDetail?.bank_account_number}</p>
                </div>
                <div className={"flex flex-row gap-2"}>
                    <p className={"text-neutral-50 font-medium"}>a.n</p>
                    <p className={"text-neutral-80 font-semibold"}>{employeeDetail?.bank_account_name}</p>
                </div>
            </div>
        </div>
    )
}
