import Button from "../../commons/button";
import ProgressBar from "../../commons/progress-bar";

const VARIABLE_COUNT = [
    {
        name: 'Total Employee',
        key: 'total_count',
        color: 'text-blue-60'
    },
    {
        name: 'Ongoing',
        key: 'ongoing_count',
        color: 'text-orange-30'
    },
    {
        name: 'Completed',
        key: 'completed_count',
        color: 'text-green-60'
    },
    {
        name: 'Failed',
        key: 'failed_count',
        color: 'text-red-60'
    }
]

export const GenerateComponent = (
    {
        progressDetail,
        percentage,
        isCalculate,
        isUpdatingStep,
        isLoading,
        isRefresh,
        getWordingCalculateButton,
        onCalculate,
        onSubmit
    }) => {

    return (
        <div className={"flex flex-col space-y-10 justify-center items-center pt-28"}>
            <p className={"font-medium text-lg text-neutral-80"}>Generate Component</p>
            {
                !isCalculate && !progressDetail?.ongoing_count ?
                    <div className={"flex flex-col items-center space-y-3"}>
                        <p>Klik button generate component untuk mulai menghitung komponen payroll untuk karyawan.</p>
                        <p>Total: {progressDetail?.total_count} karyawan</p>
                        {
                            progressDetail?.is_process_done &&
                            <p className={`pt-5 font-medium ${progressDetail?.failed_count ? 'text-red-60' : 'text-green-60'}`}>
                                Hitung Komponen Selesai, Jumlah Gagal {progressDetail?.failed_count}
                            </p>
                        }
                    </div>
                    :
                    <>
                        <div className={"flex space-x-6"}>
                            {
                                VARIABLE_COUNT.map((item, index) =>
                                    <p key={index} className={`font-medium capitalize ${item?.color}`}>
                                        {item?.name}: {progressDetail[item?.key]}
                                    </p>
                                )
                            }
                        </div>
                        <ProgressBar className={"w-2/3"} percentage={percentage}/>
                        <p className={"font-bold text-lg text-primary-60"}>Progress in {percentage}%</p>
                    </>
            }
            <div className={"flex flex-col justify-center space-y-4"}>
                <Button
                    className="px-8"
                    title={getWordingCalculateButton()}
                    disabled={progressDetail?.completed_count === progressDetail?.total_count}
                    loading={isLoading || isCalculate}
                    onClick={onCalculate}
                />
                {isRefresh && <p className={'text-sm text-red-60'}>Klik button untuk melanjutkan perhitungan komponen</p>}
            </div>
            <div className={"flex items-center justify-end fixed bottom-0 w-[calc(100%-200px)] bg-white border-t px-14 py-6 z-10"}>
                <Button
                    className="px-8"
                    title="List Employee Payroll"
                    disabled={!progressDetail?.is_process_done}
                    loading={isUpdatingStep}
                    onClick={onSubmit}
                />
            </div>
        </div>
    )
}
