import icChevRight from "assets/images/ic-chev-right.svg"
import icLogout from "assets/images/ic-logout.svg"
import icProfilePicture from "assets/images/ic-user-profile-03.svg"
import Breadcrumb from "components/commons/breadcrumb"
import Button from "components/commons/button"


export const Header = ({breadcrumbPath, onNavigate, userProfile, onClickLogout}) => {
    return (
        <div className="w-full flex items-center px-6 py-4 sticky top-0 bg-white z-20">
            <Breadcrumb
                className='hris-breadcrumb'
                path={breadcrumbPath}
                separator={<img className="w-5 h-5" src={icChevRight} alt='chev right'/>}
                onClick={onNavigate}
            />
            <div className="flex items-center gap-8 ml-auto">
                <div className="flex gap-3 items-center">
                    <div className="w-9 h-9 overflow-hidden rounded-full border">
                        <img className="w-full aspect-square" src={userProfile?.profile_picture || icProfilePicture} alt='profile'/>
                    </div>
                    <div>
                        <div className="font-medium mb-px">{userProfile?.full_name}</div>
                        <div className="text-neutral-70">{userProfile?.title}</div>
                    </div>
                </div>
                <Button type="text"
                        className={'rounded-lg shadow border border-neutral-10 justify-center items-center'}
                        icon={icLogout} onClick={onClickLogout}/>
            </div>
        </div>
    )
}
