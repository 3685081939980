import {createSlice} from "@reduxjs/toolkit";
import {ACTION_TYPE} from "../constants/constant";

const INITIAL_STATE = {
    leaveSettingDetail: {
        name: null,
        quota: null,
        min_month_to_start: null,
        is_full_day: false,
        is_different_year_allowed: false,
        max_request: null,
        max_day_per_request: null,
        min_day_per_request: null,
        gender: {label: 'General', value: null},
        employee_ids: [],
        max_allowed_date: null,
        is_shown_on_application: false
    },
    toast: {
        message: '',
        isShowToast: false
    }
}

export const formLeaveSettingSlice = createSlice({
    name: 'form-leave-setting',
    initialState: INITIAL_STATE,
    reducers: {
        resetInitialLeaveSetting: () => INITIAL_STATE,
        setLeaveSettingDetail: (state, action) => {
            state.leaveSettingDetail = {...INITIAL_STATE?.leaveSettingDetail, ...action.payload}
        },
        setLeaveSettingToast: (state, action) => {
            state.toast = {...state.toast, ...action.payload}
        }
    }
})

export const {
    resetInitialLeaveSetting,
    setLeaveSettingDetail,
    setLeaveSettingToast
} = formLeaveSettingSlice.actions
const formLeaveSettingReducer = formLeaveSettingSlice.reducer

export default formLeaveSettingReducer
