import clsx from "clsx"
import { useEffect, useRef, useState } from "react"
import icChevDown from "./ic-chev-down.svg"
import './select.scss'
import icCloseRoundFill from "../../../assets/images/ic-close-round-fill.svg";
import icSearch02 from "../../../assets/images/ic-search-02.svg";

export const Select = ({
    className,
    optionsClassName,
    name,
    value,
    placeholder,
    options = [],
    onChange,
    onClickField,
    searchable,
    disabled,
    onSearch,
    isMultiple
}) => {
    const firstRender = useRef(true)
    const inputRef = useRef()
    const selectRef = useRef(null)
    const optionsRef = useRef(null)
    const [isShow, setShow] = useState(false)
    const [isInputFocus, setInputFocus] = useState(false)
    const [keyword, setKeyword] = useState("")
    const [optionsSearch, setOptionsSearch] = useState([])
    const [isFirstInput, setFirstInput] = useState(true)
    const [isSelectOption, setSelectOption] = useState(false)
    const [position, setPosition] = useState('below')
    const optionsList = searchable ? optionsSearch : options

    useEffect(() => {
        if (isShow) setOptionsSearch([...options])
    }, [options])

    useEffect(() => {
        const clickListener = (e) => {
            const flyoutElement = document.getElementById(`select-${name}`)
            let targetElement = e.target

            do {
                if (targetElement === flyoutElement) return  // This is a click inside. Do nothing, just return.
                targetElement = targetElement.parentNode
            } while (targetElement);
            if (searchable) setKeyword('')
            setShow(false)
        }

        if (isShow) {
            document.addEventListener("click", clickListener, true)
            setOptionsSearch([...options])
            calculatePosition()
        }

        return () => document.removeEventListener("click", clickListener, true)
    }, [isShow])

    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false
            return
        }
        let delayDebounceFn
        if (onSearch && !isSelectOption) {
            delayDebounceFn = setTimeout(() => {
                if (!isFirstInput) {
                    onSearch(keyword)
                    setShow(true)
                }
                setFirstInput(false)
            }, 1000)
        }

        return () => clearTimeout(delayDebounceFn)
    }, [keyword, isSelectOption])

    const calculatePosition = () => {
        const { bottom } = selectRef.current.getBoundingClientRect()
        const windowHeight = window.innerHeight
        const optionsHeight = optionsRef.current.offsetHeight
        const spaceBelow = windowHeight - bottom

        if (spaceBelow >= optionsHeight) {
            setPosition('below')
        } else {
            setPosition('above')
        }
    }

    const onDeleteOption = (item) => {
        const newData = value?.filter(i => i?.label !== item?.label)
        if (onChange) onChange({value: newData, name})
        if (isShow) inputRef.current?.focus()
    }

    const isNotExistSelectedItem = (item) => {
        return isMultiple ? !value?.find(i => i?.label === item?.label) : item !== value
    }

    const handleOnChange = (selectedItem) => {
        setShow(false)
        if (searchable || onSearch) {
            setKeyword('')
            setOptionsSearch([...options])
        }
        if (isNotExistSelectedItem(selectedItem)) {
            onChange({ value: isMultiple ? [...value, selectedItem] : selectedItem, name: name })
            setSelectOption(true)
        }
    }

    const handleSearch = (e) => {
        setSelectOption(false)
        setKeyword(e.target.value)
        if (onSearch) return
        const searchResult = options.filter((item) => item.label.toLowerCase().includes(e.target.value.toLowerCase()))
        setOptionsSearch([...searchResult])
    }

    const onClick = (e) => {
        e.stopPropagation()
        if (disabled) return
        setShow(!isShow)
        if ((searchable || onSearch) && !isInputFocus) inputRef.current?.focus()
        if (onClickField) onClickField({name, is_show: !isShow})
    }

    const getInputWidth = () => {
        if (!keyword) return '2px'
        if (isMultiple) return 'auto'
        return 'calc(100% - 52px)'
    }

    return (
        <div ref={selectRef} id={`select-${name}`} className={clsx("avo__select", className)}>
            <div className={clsx("select-wrapper", value?.length && "select-wrapper-items", disabled && "disabled")} onClick={onClick}>
                <div className={clsx(value?.length ? "wrapper-items" : "wrapper-item")}>
                    {
                        value?.length ?
                            value?.map((item, index) =>
                                    <div key={index}
                                         className="selected-multiple-item"
                                         onClick={(e) => e.stopPropagation()}>
                                        <p className="text-item">{item?.label}</p>
                                        <img src={icCloseRoundFill} alt="ic-close-round-fill" className="ic-close"
                                             onClick={() => onDeleteOption(item)}/>
                                    </div>
                            )
                            : <></>
                    }
                    {(searchable || onSearch) && <input
                        className={clsx(isMultiple && "input-multiple-select")}
                        style={{ width: getInputWidth() }}
                        ref={inputRef}
                        name={name}
                        value={keyword}
                        disabled={disabled}
                        onChange={handleSearch}
                        onFocus={() => setInputFocus(true)}
                        onBlur={() => setInputFocus(false)}
                    />}
                </div>
                {(!keyword && !value?.length) && <div className={clsx("selected-item", !value?.label && "placeholder")}>
                    {value?.label || placeholder}
                </div>}
                <img src={isInputFocus ? icSearch02 : icChevDown} alt='chev-down' />
            </div>
            <div ref={optionsRef} className={clsx("options-wrapper", isShow ? "show" : "hide", position, optionsClassName)}>
                {optionsList.map((item, index) => (
                    <div key={index} className="option-item" onClick={() => handleOnChange(item)}>
                        {item.label}
                    </div>
                ))}
                {optionsList?.length === 0 && <div className="no-option">{'No options'}</div>}
            </div>
        </div>
    )
}

export default Select
