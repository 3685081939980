import icPlus01 from "assets/images/ic-plus-01.svg"
import Button from "components/commons/button"
import DeleteModal from "components/commons/delete-modal"
import Table from "components/commons/table"
import { CreateWorkExperience } from "components/employee/create-modal/CreateWorkExperience"

export const WorkingExperienceTab = ({
    configTable,
    dataWorkExperience,
    isFormValid,
    isEdit,
    form,
    showModal,
    onShowModal,
    onCloseModal,
    onChangeForm,
    onSubmit,
    onActionRow,
    showDeleteModal,
    onCloseDeleteModal,
    onDelete,
    isLoadingSubmit,
    isLoadingDelete,
}) => {

    return (
        <>
            <div className={"flex items-center justify-between"}>
                <p className={"text-neutral-80 font-semibold text-md"}>{'Pengalaman Kerja'}</p>
                <Button title={'Pengalaman Kerja'} icon={icPlus01} onClick={onShowModal} />
            </div>
            <Table
                className="my-6"
                classNameHeader="!bg-primary-10 !text-md !text-primary-50"
                classNameRow="!py-6"
                name={configTable.name}
                columns={configTable.column}
                data={form}
                onClickItem={onActionRow}
            />

            <CreateWorkExperience
                isFormValid={isFormValid}
                isEdit={isEdit}
                show={showModal}
                onHide={onCloseModal}
                dataWorkExperience={dataWorkExperience}
                onChangeForm={onChangeForm}
                onSubmit={onSubmit}
                isLoadingSubmit={isLoadingSubmit}
            />

            <DeleteModal
                text={"Apakah anda yakin ingin menghapus data pengalaman kerja?"}
                show={showDeleteModal}
                onHide={onCloseDeleteModal}
                onDelete={onDelete}
                isLoading={isLoadingDelete}
            />
        </>
    )
}
