import {PercentageForm} from "../../../components/payroll/incentive/PercentageForm";
import {useEffect, useState} from "react";
import {deepClone} from "../../../utils/utils";
import {useNavigate, useSearchParams} from "react-router-dom";
import {useFetch} from "../../../services/useFetch";
import {API_INCENTIVE} from "../../../services/apiIncentive";
import {setPayrollToast} from "../../../reducers/payrollReducer";
import {useDispatch} from "react-redux";

const defaultForm = {
    title: null,
    min_percentage: null,
    max_percentage: null,
}

const errorMessage = {
    title : "Title tidak boleh kosong",
    min_percentage: "Percentage minimum tidak boleh kosong",
    max_percentage: "Percentage maximum tidak boleh kosong"
}
export const PercentageFormContainer = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const type = searchParams.get('type')
    const api = `/v1/hr-adm/incentive/${type}/percentage`
    const [form, setForm] = useState([])
    const {fetching: createUpdatePercentageApi} = useFetch({...API_INCENTIVE.CREATE_UPDATE_PERCENTAGE_INCENTIVE, api})
    const {fetching: getPercentageListApi} = useFetch({ api, method: 'get'})

    useEffect(() => {
        getPercentageList()
    }, []);

    const getPercentageList = () => {
        getPercentageListApi()
            .then(result => {
                let formData = result?.data.length === 0 ? [defaultForm] : result?.data
                setForm(formData)
            })
    }

    const onChangeForm = (index, e) => {
        let copyForm = deepClone(form)
        copyForm[index][e.name] = e.value
        setForm(copyForm)
    }

    const addForm = () => {
        setForm(form.concat(defaultForm))
    }

    const deleteForm = (index) => {
        let copyForm = deepClone(form)
        let newForm = copyForm.filter((i, idx) => idx !== index)
        setForm(newForm)
    }

    const onSubmitForm = () => {
        let isError = false
        let copyForm = deepClone(form)
        copyForm.forEach((item) => {
            let error = {}
            for (const key in defaultForm) {
                if(!item[key]){
                    error[key] = errorMessage[key]
                }
            }
            item.error = error
            if(!!error?.title || !!error?.min_percentage || !!error?.max_percentage) isError = true
        })

        if(!isError){
            createUpdatePercentage(copyForm)
        } else {
            setForm(copyForm)
        }
    }

    const createUpdatePercentage = (copyForm) => {
        createUpdatePercentageApi({percentage_settings: copyForm})
            .then(() => {
                dispatch(setPayrollToast({isShowToast: true, message: 'Data Percentage Ketercapaian is Updated'}))
                navigate(`/payroll/incentive/${type}`)
            })
            .catch(err => {
                if(err.code === '422'){
                    err.data.forEach(i => {
                        let index = i.parameter.slice(20, 21)
                        copyForm[Number(index)].errorPercentage = i.message
                    })
                    setForm(copyForm)
                }
            })
    }

    const props = {
        form,
        addForm,
        deleteForm,
        onChangeForm,
        onSubmitForm
    }
    return (
        <PercentageForm {...props}/>
    )
}
