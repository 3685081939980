import {ActivitySetting} from "components/matrix-approval/activity-setting/ActivitySetting";
import {useEffect, useState} from "react";
import {useFetch} from "../../../services/useFetch";
import {API_ACTIVITY} from "../../../services/apiActivity";
import {INITIAL_DATA_FILTER_ACTIVITY} from "../../../configs/matrix-approval/configTableActivity";
import {constructOptionsSelect, findOptionSelected, getAllQueryParams, setQueryParams} from "../../../utils/utils";
import {useLocation, useSearchParams} from "react-router-dom";

const INITIAL_STATE = {
    id: null,
    name: null,
    approval_1: null,
    approval_2: null,
    approval_3: null
}

export const ActivitySettingContainer = () => {
    const location = useLocation()
    const {data: activityList, fetching: getActivityList, isLoading} = useFetch(API_ACTIVITY.ACTIVITY_LIST)
    const {fetching: getActivityApproverList} = useFetch(API_ACTIVITY.ACTIVITY_APPROVER_LIST)
    const {fetching: updateActivityApproval, isLoading: isUpdating} = useFetch(API_ACTIVITY.UPDATE_ACTIVITY_APPROVAL)
    const [activityApproverDetail, setActivityApproverDetail] = useState(INITIAL_STATE)
    const [optionList, setOptionList] = useState([])
    const [isShowToast, setIsShowToast] = useState(false)
    const [toastMessage, setToastMessage] = useState('')
    const [isShowForm, setShowForm] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams()
    const query = searchParams.size > 0 ? getAllQueryParams(searchParams) : INITIAL_DATA_FILTER_ACTIVITY

    useEffect(() => {
        fetchActivityList({...query})
    }, [location?.search])

    const onChangeQuery = (queryParams) => {
        const params = setQueryParams({...query, ...queryParams})
        setSearchParams(params, {replace: true})
    }

    const clearToast = () => {
        setIsShowToast(false)
        setToastMessage('')
    }

    const onHideForm = () => {
        setShowForm(false)
    }

    const isDisabledSubmitButton = () => {
        return !(activityApproverDetail?.approval_1 || activityApproverDetail?.approval_2 ||
            activityApproverDetail?.approval_3)
    }

    const onClickItemHandler = (item) => {
        setShowForm(true)
        getActivityApproverList()
            .then(response => {
                const options = constructOptionsSelect([{
                    value: 'Tidak Ada',
                    key: null
                }, ...response?.data], 'value', 'key')
                setOptionList(options)
                setActivityApproverDetail({
                    ...item,
                    approval_1: findOptionSelected(options, item?.approval_1 || 'Tidak Ada', 'label'),
                    approval_2: findOptionSelected(options, item?.approval_2 || 'Tidak Ada', 'label'),
                    approval_3: findOptionSelected(options, item?.approval_3 || 'Tidak Ada', 'label')
                })
            })
    }

    const onChangeValue = (item) => {
        const newData = {[item?.name]: item?.value}
        setActivityApproverDetail({...activityApproverDetail, ...newData})
    }

    const fetchActivityList = (item = query) => {
        getActivityList(item)
    }

    const onSubmitForm = () => {
        const combineObj = {
            ...activityApproverDetail,
            approval_1: activityApproverDetail?.approval_1?.value ?? null,
            approval_2: activityApproverDetail?.approval_2?.value ?? null,
            approval_3: activityApproverDetail?.approval_3?.value ?? null,
        }
        const {id, ...payload} = combineObj
        updateActivityApproval(payload, activityApproverDetail?.id)
            .then(() => {
                onHideForm()
                setIsShowToast(true)
                setToastMessage('Activity Updated !!')
                fetchActivityList()
            })
    }

    const props = {
        dataList: activityList?.data,
        isShowForm,
        isShowToast,
        toastMessage,
        isLoading,
        activityApproverDetail,
        optionList,
        query,
        isUpdating,
        clearToast,
        onHideForm,
        isDisabledSubmitButton,
        onSubmitForm,
        onChangeValue,
        onChangeQuery,
        onClickItemHandler
    }

    return (
        <ActivitySetting {...props}/>
    )
}
