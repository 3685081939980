import Button from "../../../commons/button";
import icDownloadPrimary from "../../../../assets/images/ic-download-primary.svg";
import ProgressBar from "../../../commons/progress-bar";
import icXPrimary from "../../../../assets/images/ic-x-primary.svg";
import {getFileUnit} from "../../../../utils/utils";
import icUploadPrimary from "../../../../assets/images/ic-upload-primary.svg";
import icArrowUpRightWhite from "../../../../assets/images/ic-arrow-up-right-white.svg";
import icArrowUpRightGrey from "../../../../assets/images/ic-arrow-up-right-grey.svg";
import Toast from "../../../commons/toast";

export const UploadFile = (
    {
        uploadFileRef,
        percentage,
        isUploading,
        isDragOver,
        isLoading,
        isShowToast,
        toastMessage,
        sourceFile,
        clearToast,
        onClickDownload,
        onChangeUploadFile,
        cancelUploadFile,
        onDragAndDrop,
        getIconDocumentUpload,
        onSubmitFile
    }) => {

    return (
        <div className={"w-full flex flex-col space-y-4 px-40 text-neutral-80"}>
            <div className={"flex flex-row justify-between items-center"}>
                <div className="space-y-2">
                    <p className="font-medium">Import File XLSS File</p>
                    <p>This tools allows you to import Employee Contract from a XLSX file.</p>
                </div>
                <Button type="outline" title="Download Template" icon={icDownloadPrimary}
                        onClick={onClickDownload} loading={isLoading}/>
            </div>
            <div
                onDrop={(e) => onDragAndDrop(e)}
                onDragOver={(e) => onDragAndDrop(e)}
                onDragLeave={(e) => onDragAndDrop(e)}
                className={`flex flex-col space-y-4 px-5 ${isUploading ? 'py-[66px]' : ''} ${sourceFile?.name ? 'pt-16' : 'py-16'} ${isDragOver ? 'bg-primary-10 border-primary-40' : 'bg-neutral-10 border-neutral-40'} border-2 border-dashed rounded-lg`}>
                {
                    isUploading ?
                        <div className={"flex flex-col items-center space-y-10 text-md"}>
                            <p>
                                <span className={"font-bold"}>{percentage}%</span> <span className={"font-medium"}>Mengunggah File</span>
                            </p>
                            <ProgressBar className="w-1/4" percentage={percentage}/>
                            <Button type="outline" title="Cancel" icon={icXPrimary} onClick={() => cancelUploadFile()}/>
                        </div> :
                        <>
                            <img src={getIconDocumentUpload()}
                                 alt="ic-document-upload" className={"mx-auto"}/>
                            {
                                sourceFile?.name ?
                                    <>
                                        <div className={"flex flex-col items-center space-y-4"}>
                                            <p className="font-medium">{sourceFile?.name}</p>
                                            <p className="text-neutral-70">{getFileUnit(sourceFile?.size)}</p>
                                        </div>
                                        <div className={"flex flex-row justify-end pb-7"}>
                                            <Button type="outline" title="Cancel" icon={icXPrimary}
                                                    onClick={() => cancelUploadFile()}/>
                                        </div>
                                    </>
                                    :
                                    <div className={"flex flex-col items-center space-y-4"}>
                                        <div className={"flex flex-row items-center space-x-4"}>
                                            <p className={`font-medium ${isDragOver ? 'text-primary-50' : 'text-inherit'}`}>Drag
                                                File Here or</p>
                                            <div className="relative">
                                                <Button type="outline" title="Upload File" icon={icUploadPrimary}
                                                        onClick={() => uploadFileRef.current.click()}/>
                                                <input type="file" accept=".xlsx" className={"absolute top-0 hidden"}
                                                       ref={uploadFileRef} onChange={onChangeUploadFile}/>
                                            </div>
                                        </div>
                                        <p className="text-neutral-60">XLSX File format, and maximum size 64 mb</p>
                                    </div>
                            }
                        </>
                }
            </div>
            <div className={"flex flex-row justify-end"}>
                <Button className="mt-10 px-12" type="primary" title="Validate Data"
                        disabled={!sourceFile?.name || isUploading}
                        icon={sourceFile?.name && !isUploading ? icArrowUpRightWhite : icArrowUpRightGrey}
                        onClick={onSubmitFile}/>
            </div>
            <Toast
                show={isShowToast}
                message={toastMessage}
                onClose={() => clearToast()}
            />
        </div>
    )
}
