import icTrashRed from "assets/images/ic-trash-01-red.svg";
import Button from "components/commons/button";
import { JobTitleTab } from "components/employee/employee-detail/employee-detail-tab/JobTitleTab";
import { ACTION_TYPE } from "constants/constant";
import { DEFAULT_FORM_JOB_TITLE } from "constants/defaultFormEmployee";
import { useValidation } from "hooks/useValidation";
import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setEmployeeDetail, setToast, updateJobTitle } from "reducers/employeeDetailReducer";
import { API_EMPLOYEE } from "services/apiEmployee";
import { METHOD } from "services/method";
import { useFetch } from "services/useFetch";

export const JobTitleTabContainer = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const { id, job_title_employees } = useSelector(state => state.employeeDetailReducer)
    const [showModal, setShowModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [dataJobTitle, setDataJobTitle] = useState(DEFAULT_FORM_JOB_TITLE)
    const [selectedIndex, setSelectedIndex] = useState()
    const [selectedJobTitleId, setSelectedJobTitleId] = useState()
    const [actionType, setActionType] = useState(ACTION_TYPE.CREATE)
    const { data: dataListCompany } = useFetch(API_EMPLOYEE.LIST_COMPANY, {}, { isFetching: true })
    const { data: dataListJobTitle, fetching: getListJobTitle } = useFetch(API_EMPLOYEE.LIST_JOB_TITLE)
    const { fetching: addJobTitle, isLoading: isLoadingAdd } = useFetch({ ...API_EMPLOYEE.JOB_TITLE, initPathVariable: id })
    const { fetching: patchJobTitle, isLoading: isLoadingPatch } = useFetch({ ...API_EMPLOYEE.JOB_TITLE, initPathVariable: id, method: METHOD.PUT })
    const { fetching: deleteJobTitle, isLoading: isLoadingDelete } = useFetch({ ...API_EMPLOYEE.JOB_TITLE, initPathVariable: id, method: METHOD.DELETE })
    const { fetching: getEmployeeDetail } = useFetch({ ...API_EMPLOYEE.DETAIL, initPathVariable: id })
    const { isFormValid } = useValidation({ form: dataJobTitle })

    const optionsCompany = useMemo(() => {
        if (dataListCompany?.data?.length === 0) return []
        return dataListCompany?.data?.map((company) => ({
            label: company.name,
            value: company.id
        }))
    }, [dataListCompany?.data])

    const optionsJobTitle = useMemo(() => {
        if (dataListJobTitle?.data?.length === 0) return []
        return dataListJobTitle?.data?.map((job) => ({
            label: job.title,
            value: job.id
        }))
    }, [dataListJobTitle?.data])

    const onChangeForm = (e) => {
        const { name, value } = e
        if (name === "company") {
            getListJobTitle({ companyId: value.value })
            setDataJobTitle({
                job_title: null,
                [name]: value
            })
            return
        }
        setDataJobTitle({ ...dataJobTitle, [name]: value })
    }

    const onCloseModal = () => {
        setShowModal(false)
        setDataJobTitle(DEFAULT_FORM_JOB_TITLE)
        setActionType(ACTION_TYPE.CREATE)
    }

    const onSubmit = () => {
        const newJobTitle = [...job_title_employees]
        const newDataJobTitle = {
            company_id: dataJobTitle.company.value,
            company_name: dataJobTitle.company.label,
            job_title_id: dataJobTitle.job_title.value,
            job_title_name: dataJobTitle.job_title.label,
        }

        const onSuccess = () => {
            dispatch(updateJobTitle(newJobTitle))
            onCloseModal()
        }

        switch (actionType) {
            case ACTION_TYPE.CREATE:
                addJobTitle({ job_title_id: newDataJobTitle?.job_title_id }).then(() => {
                    newJobTitle.push(newDataJobTitle)
                    dispatch(setToast({isShowToast: true, message: 'Job Title Created !!'}))
                    onSuccess()
                })
                break;
            case ACTION_TYPE.EDIT:
                patchJobTitle({ old_job_title_id: selectedJobTitleId, new_job_title_id: newDataJobTitle?.job_title_id }).then((response) => {
                    if (response.data.is_redirect) return handleRedirect(response.data.is_redirect)
                    getEmployeeDetail().then((response) => dispatch(setEmployeeDetail({...response.data, toast: {isShowToast: true, message: 'Job Title Updated !!'}})))
                    newJobTitle[selectedIndex] = newDataJobTitle
                    onSuccess()
                })
                break;
            default:
        }
    }

    const handleEdit = (item) => {
        getListJobTitle({ companyId: item.company_id })
        setDataJobTitle({
            company: {
                label: item.company_name,
                value: item.company_id,
            },
            job_title: {
                label: item.job_title_name,
                value: item.job_title_id,
            }
        })
        setActionType(ACTION_TYPE.EDIT)
        setShowModal(true)
    }

    const handleRemove = (index) => {
        deleteJobTitle({ job_title_id: selectedJobTitleId }).then((response) => {
            const newJobTitle = [...job_title_employees]
            newJobTitle.splice(index, 1)
            dispatch(updateJobTitle(newJobTitle))
            dispatch(setToast({isShowToast: true, message: 'Job Title Deleted !!'}))
            setShowDeleteModal(false)
            handleRedirect(response.data.is_redirect)
        })
    }

    const handleRedirect = (isRedirect) => {
        if (!isRedirect) return
        const path = location.pathname.split('/')
        path.splice(-1)
        navigate(path.join('/'))
    }

    const onActionRow = ({ index, item, actionType }) => {
        setSelectedIndex(index)
        setSelectedJobTitleId(item.job_title_id)
        switch (actionType) {
            case ACTION_TYPE.EDIT:
                return handleEdit(item);
            case ACTION_TYPE.DELETE:
                return setShowDeleteModal(true);
            default:
        }
    }

    const TABLE_JOB_TITLE_TAB = {
        name: 'Job Title Table',
        column: [
            {
                name: "Perusahaan",
                key: "company_name",
                classNameHeader: "!rounded-l-md",
                className: "font-medium !text-neutral-80",
            },
            {
                name: "Job Title",
                key: "job_title_name",
            },
            {
                key: "action",
                classNameHeader: "!rounded-r-md w-[150px]",
                render: ({ index, item, onClickItem }) => (
                    <div className="flex justify-end gap-3 mx-auto">
                        <Button className="w-[80px]" type="outline" title="Edit"
                            onClick={() => onClickItem({ index, item, actionType: ACTION_TYPE.EDIT })} />
                        {job_title_employees.length > 1 && <Button type="danger" icon={icTrashRed}
                            onClick={() => onClickItem({ index, item, actionType: ACTION_TYPE.DELETE })} />}
                    </div>
                )
            }
        ]
    }

    const props = {
        configTable: TABLE_JOB_TITLE_TAB,
        optionsCompany,
        optionsJobTitle,
        dataJobTitle,
        isFormValid: isFormValid(),
        isEdit: actionType === ACTION_TYPE.EDIT,
        job_title_employees,
        showModal,
        onShowModal: () => setShowModal(true),
        onCloseModal,
        onChangeForm,
        onSubmit,
        onActionRow,
        showDeleteModal,
        onCloseDeleteModal: () => setShowDeleteModal(false),
        onDelete: () => handleRemove(selectedIndex),
        isLoadingSubmit: isLoadingAdd || isLoadingPatch,
        isLoadingDelete
    }

    return (
        <JobTitleTab  {...props} />
    )
}
