import icEmail from "assets/images/ic-email.svg";
import icProfilePicture from "assets/images/ic-user-profile-03.svg";
import Badge from "components/commons/badge";
import { useSelector } from "react-redux";

export const EmployeeDetail = () => {
    const employeeDetail = useSelector(state => state.employeeDetailReducer)
    const {
        status,
        employee_id_number,
        contract_activated_at,
        contract_job_title,
        personal_detail
    } = employeeDetail

    return (
        <div className="bg-white flex items-center border-y border-neutral-20 px-9 py-6 mt-1 gap-6">
            <img src={personal_detail?.profile_picture || icProfilePicture} className={"w-[4.25rem] h-[4.25rem] rounded-full border"} alt={personal_detail?.full_name} />
            <div className="w-full space-y-3">
                <div className="flex items-center justify-between gap-1">
                    <div className="font-medium text-lg text-neutral-80">{personal_detail?.full_name}</div>
                    <Badge type={status} title={status} />
                </div>
                <div className="flex items-center justify-between gap-1">
                    <div className="flex flex-col gap-2">
                        <span className="text-neutral-70">NIP<span className="font-medium text-neutral-80 pl-2">{employee_id_number || '-'}</span></span>
                        <div className="text-primary-40">{contract_job_title}</div>
                    </div>
                    <div className="flex flex-col items-end gap-2">
                        <span className="text-neutral-70">Date Join<span className="font-medium text-neutral-80 pl-2">{contract_activated_at || '-'}</span></span>
                        <div className="flex items-center gap-2">
                            <img className="w-4 h-4" src={icEmail} alt="email" />
                            <span className="text-primary-50">{personal_detail?.personal_email}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
