import { Alert } from "components/commons/alert"
import { RequestLeave } from "components/request-leave/RequestLeave"
import { TABLE_REQUEST_LEAVE } from "configs/request-leave/configTableRequestLeave"
import { useEffect, useState } from "react"
import { useLocation, useSearchParams } from "react-router-dom"
import {downloadFile, getAllQueryParams, setQueryParams} from "utils/utils"
import {useFetch} from "../../services/useFetch";
import {API_REQUEST_LEAVE} from "../../services/apiRequestLeave";
import moment from "moment/moment";

const OPTION_BULK = {
    APPROVE: 'approve',
    REJECT: 'reject',
}

const TAB_LIST = [
    { id: 'submitted', name: 'submitted' },
    { id: 'approved', name: 'approved' },
    { id: 'rejected', name: 'rejected' },
    { id: 'cancelled', name: 'cancelled' },
]

const FILTER_PARAMS = ['department_ids', 'division_ids', 'leave_type_ids', 'start_date', 'end_date']

const PREVIEW_TAB = [
    { id: 'information', name: 'informasi' },
    { id: 'history', name: 'history' },
]

export const RequestLeaveContainer = () => {
    const location = useLocation()
    const [searchParams, setSearchParams] = useSearchParams()
    const status = searchParams.get('status')
    const defaultQuery = {
        page: 1,
        limit: 20,
        search: '',
        status: TAB_LIST[0].id,
        order_by: "requested_at",
        order_type: "desc",
    }
    const query = searchParams.size > 0 ? getAllQueryParams(searchParams) : defaultQuery
    const [selectedEmployeeList, setSelectedEmployeeList] = useState([])
    const [selectedBulkOption, setSelectedBulkOption] = useState(null)
    const [activePreviewTab, setActivePreviewTab] = useState(PREVIEW_TAB[0].id)
    const [queryFilter, setQueryFilter] = useState(null)
    const [rejectReason, setRejectReason] = useState(null)
    const [toastMessage, setToastMessage] = useState('')
    const [selectedPreviewFile, setSelectedPreviewFile] = useState()
    const [modal, setModal] = useState({
        limit: false,
        export: false,
        filter: false,
        toast: false,
        preview: false,
        previewFile: false,
    })
    const {data: requestLeaveList, fetching: getRequestLeaveList, isLoading} = useFetch(API_REQUEST_LEAVE.LEAVE_LIST)
    const {data: previewLeave, fetching: getPreviewLeave} = useFetch(API_REQUEST_LEAVE.PREVIEW_LEAVE)
    const {fetching: exportRequestLeave} = useFetch(API_REQUEST_LEAVE.EXPORT_LEAVE_LIST)
    const {fetching: approveRequestLeave} = useFetch(API_REQUEST_LEAVE.APPROVE_REQUEST_LEAVE)
    const {fetching: rejectRequestLeave} = useFetch(API_REQUEST_LEAVE.REJECT_REQUEST_LEAVE)

    useEffect(() => {
        const newQuery = {...query}
        const newQueryFilter = {}
        Object.entries(newQuery).forEach(([key, item]) => {
            if (FILTER_PARAMS.includes(key)) {
                const valueOfQuery = FILTER_PARAMS.slice(0, 3).includes(key) ? item.split(',') : item
                newQuery[key] = valueOfQuery
                newQueryFilter[key] = valueOfQuery
            }
        })
        setQueryFilter(newQueryFilter)
        fetchRequestLeaveList(newQuery)
    }, [location.search])

    const onChangeTab = (status) => {
        const params = { ...defaultQuery, status: status.id }
        setSearchParams(params, { replace: true })
    }

    const onChangeQuery = (queryParams) => {
        const params = setQueryParams({ ...query, ...queryParams })
        setSearchParams(params, { replace: true })
    }

    const fetchRequestLeaveList = (item = {...query, ...queryFilter}) => {
        getRequestLeaveList(item)
    }

    const onExport = () => {
        const combineObj = {...query, ...queryFilter}
        const {page, limit, ...dataFilterRest} = combineObj
        const payload = {...dataFilterRest, leave_request_ids: selectedEmployeeList.map(item => item.id)}
        exportRequestLeave(payload, '', {}, 'blob')
            .then(response => {
                downloadFile(null, response, `Request-Leave-Export-${moment().format('DD-MM-YYYY')}.xlsx`)
                setToastMessage(`Anda berhasil export data cuti`)
                setModal({...modal, export: false, toast: true})
            })
    }

    const onBulkUpdate = (option) => {
        if (selectedEmployeeList.length === 0 && !modal?.preview) {
            return Alert({
                icon: 'warning',
                title: 'Silakan pilih employee terlebih dahulu.',
                autoClose: true,
            })
        }
        setSelectedBulkOption(option)
        setModal({ ...modal, bulk_update: true })
    }

    const onPreview = (item) => {
        getPreviewLeave({}, item?.id)
        setActivePreviewTab(PREVIEW_TAB[0].id)
        setModal({ ...modal, preview: true })
    }

    const onCloseUpdateRequestModal = () => {
        setModal({...modal, bulk_update: false})
        setRejectReason(null)
    }

    const onPreviewFile = (item) => {
        setSelectedPreviewFile(item)
        setModal({...modal, previewFile: true})
    }

    const getPromiseAPI = (type, bodyRequest) => {
        if (type === OPTION_BULK?.APPROVE) return approveRequestLeave(bodyRequest)
        return rejectRequestLeave(bodyRequest)
    }

    const errorMessage = (data = []) => {
        let message = ''
        data?.forEach(item => {
            message += `\nSomething happen in ID ${item?.leave_request_id}:\nStatus: ${item?.success ? 'Success' : 'Failed'}\nMessage: ${item?.message}\n`
        })

        return message
    }

    const onUpdateStatusRequest = (status, id = null) => {
        const bodyRequest = {
            leave_request_ids: id ? [id] : selectedEmployeeList.map(item => item.id)
        }
        if (status === OPTION_BULK?.REJECT) bodyRequest['reject_reason'] = rejectReason

        getPromiseAPI(status, bodyRequest)
            .then(() => {
                setToastMessage(`Request Leave ${status === OPTION_BULK?.APPROVE ? 'Approved !!' : 'Rejected !!'}`)
                setModal({...modal, bulk_update: false, preview: false, toast: true})
                fetchRequestLeaveList()
            }).catch(err => {
            if (err?.code === '400-L003') {
                Alert({
                    icon: 'error',
                    title: err?.message,
                    message: errorMessage(err?.data),
                })
            }
            setModal({ ...modal, bulk_update: false, preview: false})
        })
    }

    const props = {
        tabList: TAB_LIST,
        dataEmployee: requestLeaveList?.data,
        dataPreview: previewLeave?.data,
        status: status || TAB_LIST[0].id,
        optionBulk: OPTION_BULK,
        configTable: TABLE_REQUEST_LEAVE,
        previewTab: PREVIEW_TAB,
        isLoading,
        query,
        activePreviewTab,
        setActivePreviewTab,
        selectedEmployeeList,
        selectedBulkOption,
        modal,
        queryFilter,
        toastMessage,
        rejectReason,
        setRejectReason,
        onChangeTab,
        onChangeQuery,
        onSelectItem: (employeeList) => setSelectedEmployeeList([...employeeList]),
        onExport,
        onBulkUpdate,
        onPreview,
        onSetModal: (e) => setModal({ ...modal, ...e }),
        onCloseUpdateRequestModal,
        onUpdateStatusRequest,
        onPreviewFile,
        selectedPreviewFile
    }

    return (
        <RequestLeave {...props} />
    )
}
