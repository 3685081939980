import icCheckedBroken from "assets/images/ic-check-broken.svg"
import icFileEdit01 from "assets/images/ic-file-edit-02.svg"
import Button from "components/commons/button"
import FormModal from "components/commons/form-modal"
import Input from "components/commons/input"
import Select from "components/commons/select"

export const BankInfoTab = ({
    canValidate,
    optionsBank,
    isFormValid,
    dataBank,
    bankDetail,
    showModal,
    onShowModal,
    onCloseModal,
    onChangeForm,
    onValidateAccount,
    onSubmit,
    isLoadingSubmit,
}) => {
    return (
        <>
            <div className={"flex items-center justify-between"}>
                <p className={"text-neutral-80 font-semibold text-md"}>{'Informasi Rekening'}</p>
                <Button title={'Edit'} type={'outline'} icon={icFileEdit01} onClick={onShowModal} />
            </div>
            <div className={"flex flex-row items-center mt-6 space-x-6 bg-primary-10 px-6 py-4 rounded-lg"}>
                <div>
                    <img className='max-w-[150px]' src={bankDetail?.bank_image} alt='bank' />
                </div>
                <div className={"flex flex-col justify-between flex-1 text-neutral-80"}>
                    <p>{bankDetail?.bank_name}</p>
                    <p className={"font-medium mt-3"}>{bankDetail?.bank_account_number}</p>
                    <div className={"flex flex-row items-center space-x-1 mt-2"}>
                        <p className={"text-neutral-60"}>a.n</p>
                        <p className={"font-medium"}>{bankDetail?.bank_account_name}</p>
                    </div>
                </div>
                {!!bankDetail?.is_bank_verified && <img className='w-8 h-8' src={icCheckedBroken} alt='verified' />}
            </div>

            <FormModal
                show={showModal}
                onHide={onCloseModal}
                title={'Edit Daftar Rekening'}
                onSubmitForm={onSubmit}
                isLoading={isLoadingSubmit}
                isDisabled={!isFormValid}
            >
                <div className={"px-8 py-6 mb-2 space-y-6"}>
                    <div className="flex items-center gap-3">
                        <div className="w-1/3 text-neutral-70">{'Pilih Bank'}</div>
                        <Select
                            name="bank_name"
                            className='flex-1'
                            optionsClassName='max-h-[300px]'
                            placeholder="Nama bank"
                            options={optionsBank}
                            value={dataBank?.bank_name}
                            onChange={onChangeForm}
                            searchable
                        />
                    </div>
                    <div className="flex items-center gap-3">
                        <div className="w-1/3 text-neutral-70">{'Nomor Rekening'}</div>
                        <Input className="flex-1" name="bank_account_number" type="number" value={dataBank?.bank_account_number} onChange={onChangeForm} />
                        <Button className="w-[100px]" type="outline" title="Periksa" disabled={!canValidate} onClick={onValidateAccount} />
                    </div>
                    <div className="flex items-center gap-3">
                        <div className="w-1/3 text-neutral-70">{'Nama Pemilik'}</div>
                        <Input className="flex-1" name="bank_account_name" value={dataBank?.bank_account_name} disabled />
                    </div>
                </div>
            </FormModal>
        </>
    )
}
