import icExport from "assets/images/ic-export.svg";
import Button from "../commons/button";
import Modal from "../commons/modal";

export const ExportModal = ({show = false, onCancelHandler, onExportHandler, title, description}) => {
    return (
        <Modal
            dialogClassName='w-full max-w-[328px]'
            contentClassName='rounded-xl'
            show={show}
        >
            <div>
                <div className={"py-8 px-6 flex flex-col justify-center items-center space-y-4"}>
                    <img src={icExport} alt={'export'}
                         className={"w-12 h-12 p-2 border rounded-full bg-primary-10 border-primary-30"}/>
                    <p className={"text-center font-semibold"}>{title}</p>
                    <p className={"text-center text-neutral-70"}>{description}</p>
                </div>
                <div className={"px-6 py-3 flex flex-row border-t space-x-3 justify-center"}>
                    <Button type={'outline'} title={'Cancel'} onClick={onCancelHandler}/>
                    <Button title={'Export'} onClick={onExportHandler}/>
                </div>
            </div>
        </Modal>
    )
}
