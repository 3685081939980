import { EmployeeDetailTab } from "components/employee/employee-detail/employee-detail-tab/EmployeeDetailTab";
import { useMemo, useState } from "react";
import { API_EMPLOYEE } from "services/apiEmployee";
import { useFetch } from "services/useFetch";
import { capitalize } from "utils/utils";
import { AdditionalInfoTabContainer } from "./AdditionalInfoTabContainer";
import { BankInfoTabContainer } from "./BankInfoTabContainer";
import { EducationInfoTabContainer } from "./EducationInfoTabContainer";
import { EmergencyContactTabContainer } from "./EmergencyContactTabContainer";
import { FamiliesTabContainer } from "./FamiliesTabContainer";
import { InsuranceAndTaxTabContainer } from "./InsuranceAndTaxTabContainer";
import { JobTitleTabContainer } from "./JobTitleTabContainer";
import { PersonalDocumentTabContainer } from "./PersonalDocumentTabContainer";
import { PersonalInfoTabContainer } from "./PersonalInfoTabContainer";
import { WorkingExperienceTabContainer } from "./WorkingExperienceTabContainer";
import {ChangePasswordTabContainer} from "./ChangePasswordTabContainer";

export const EmployeeDetailTabContainer = () => {
    const { data: dataConstantsEmployee } = useFetch(API_EMPLOYEE.LIST_CONSTANTS_EMPLOYEE, {}, { isFetching: true })

    const { optionsGrades, optionsPersonalDocumentType, optionsGender, optionsReligions, optionsMaritalStatus, optionsBloodType } = useMemo(() => {
        const mapOptions = (dataList) => {
            if (!dataList || dataList?.length === 0) return []
            return dataList?.map((item) => ({
                label: capitalize(item),
                value: item,
            }))
        }

        const optionsGrades = mapOptions(dataConstantsEmployee?.data?.grades)
        const optionsPersonalDocumentType = mapOptions(dataConstantsEmployee?.data?.personalDocumentType)
        const optionsGender = mapOptions(dataConstantsEmployee?.data?.gender)
        const optionsReligions = mapOptions(dataConstantsEmployee?.data?.religions)
        const optionsMaritalStatus = mapOptions(dataConstantsEmployee?.data?.maritalStatus)
        const optionsBloodType = mapOptions(dataConstantsEmployee?.data?.bloodType)
        return {
            optionsGrades,
            optionsPersonalDocumentType,
            optionsGender,
            optionsReligions,
            optionsMaritalStatus,
            optionsBloodType,
        }

    }, [dataConstantsEmployee?.data])

    const employeeDetailTabList = [
        {
            label: 'Informasi Pribadi',
            key: 'personal_detail',
            component: <PersonalInfoTabContainer />
        }, {
            label: 'Informasi Rekening',
            key: 'bank_info',
            component: <BankInfoTabContainer />
        }, {
            label: 'Job Title',
            key: 'job_title_employees',
            component: <JobTitleTabContainer />
        }, {
            label: 'Informasi Tambahan',
            key: 'additional_info',
            component: <AdditionalInfoTabContainer options={{
                optionsGrades,
                optionsPersonalDocumentType,
                optionsGender,
                optionsReligions,
                optionsMaritalStatus,
                optionsBloodType,
            }} />
        }, {
            label: 'Informasi BPJS & NPWP',
            key: 'card_detail',
            component: <InsuranceAndTaxTabContainer />
        }, {
            label: 'Informasi Keluarga',
            key: 'family_info',
            component: <FamiliesTabContainer />
        }, {
            label: 'Kontak Darurat',
            key: 'emergency_contact',
            component: <EmergencyContactTabContainer />
        }, {
            label: 'Informasi Pendidikan',
            key: 'education_info',
            component: <EducationInfoTabContainer optionsGrades={optionsGrades} />
        }, {
            label: 'Pengalaman Kerja',
            key: 'work_experiences',
            component: <WorkingExperienceTabContainer />
        }, {
            label: 'Dokumen Pribadi',
            key: 'personal_documents',
            component: <PersonalDocumentTabContainer optionsPersonalDocumentType={optionsPersonalDocumentType} />
        }, {
            label: 'Ubah Password',
            key: 'change_password',
            component: <ChangePasswordTabContainer />,
        }
    ]
    const [tabSelected, setTableSelected] = useState(employeeDetailTabList[0])

    const onChangeTab = (tab) => {
        setTableSelected(tab)
    }

    return (
        <EmployeeDetailTab tabSelected={tabSelected} employeeDetailTabList={employeeDetailTabList} onChangeTab={onChangeTab} />
    )
}
