import icAddSquare from "assets/images/id-add-square.svg"
import Button from "components/commons/button"
import DeleteModal from "components/commons/delete-modal"
import Search from "components/commons/search"
import Table from "components/commons/table"
import Toast from "../../commons/toast";


export const RoleAccess = (
    {
        configTable,
        dataRoleAccess,
        isLoadingRoleList,
        query,
        toast,
        clearToast,
        onSearch,
        onChangePage,
        onCreateRole,
        onActionRow,
        showDeleteModal,
        onCloseDeleteModal,
        onDelete,
        isLoadingDelete,
    }) => {

    return (
        <div className={'flex flex-col'}>
            <div className={'flex flex-row justify-between items-center px-6 py-4'}>
                <Search value={query?.search} onSearch={onSearch}/>
                <Button title="Create User Role" icon={icAddSquare} onClick={onCreateRole}/>
            </div>
            <Table
                className="my-3"
                classNameHeader="first:!pl-6 last:!pr-6"
                classNameRow="first:!pl-6 last:!pr-6"
                name={configTable.name}
                data={dataRoleAccess?.result}
                columns={configTable.column}
                size={dataRoleAccess?.limit}
                currentPage={query.page}
                totalPage={dataRoleAccess?.total_page}
                totalResult={dataRoleAccess?.total_result}
                onChangePage={onChangePage}
                onClickItem={onActionRow}
                loading={isLoadingRoleList}
            />
            <DeleteModal
                text={"Apakah anda yakin ingin menghapus data role?"}
                show={showDeleteModal}
                onHide={onCloseDeleteModal}
                onDelete={onDelete}
                isLoading={isLoadingDelete}
            />
            <Toast
                show={toast?.isShowToast}
                message={toast?.message}
                onClose={() => clearToast()}
            />
        </div>
    )
}
