import {TABLE_ACTIVITY_SETTING} from "configs/matrix-approval/configTableActivity";
import Table from "../../commons/table";
import Input from "../../commons/input";
import FormModal from "../../commons/form-modal";
import Select from "../../commons/select";
import Toast from "../../commons/toast";

export const ActivitySetting = (
    {
        dataList,
        isShowForm,
        isShowToast,
        toastMessage,
        isLoading,
        activityApproverDetail,
        optionList,
        query,
        isUpdating,
        clearToast,
        onHideForm,
        isDisabledSubmitButton,
        onSubmitForm,
        onChangeValue,
        onChangeQuery,
        onClickItemHandler
    }
) => {

    return (
        <>
            <Table
                className="my-3"
                name={TABLE_ACTIVITY_SETTING.name}
                columns={TABLE_ACTIVITY_SETTING.column}
                data={dataList?.result}
                size={query?.limit}
                currentPage={query?.page}
                totalPage={dataList?.total_page}
                totalResult={dataList?.total_data}
                onChangePage={(val) => onChangeQuery({page: val})}
                onClickItem={onClickItemHandler}
                loading={isLoading}
            />
            {
                isShowForm &&
                <FormModal
                    show={isShowForm}
                    title="Activity Setting"
                    width="max-w-[639px]"
                    onHide={onHideForm}
                    onSubmitForm={onSubmitForm}
                    isDisabled={isDisabledSubmitButton()}
                    isLoading={isUpdating}
                >
                    <div className={"flex flex-col px-6 py-8 gap-[20px]"}>
                        <div className="flex items-center">
                            <div className={"w-1/3 text-neutral-70"}>Activity Name</div>
                            <Input
                                className="flex-1"
                                classNameInput="capitalize"
                                name="name"
                                value={activityApproverDetail?.name?.replace('-', ' ')}
                                disabled
                            />
                        </div>
                        <div className="flex items-center">
                            <div className={"w-1/3 text-neutral-70"}>Approver 1</div>
                            <Select
                                name="approval_1"
                                className="flex-1"
                                placeholder="Pilih approver 1"
                                options={optionList}
                                value={activityApproverDetail?.approval_1}
                                onChange={onChangeValue}
                            />
                        </div>
                        <div className="flex items-center">
                            <div className={"w-1/3 text-neutral-70"}>Approver 2</div>
                            <Select
                                name="approval_2"
                                className="flex-1"
                                placeholder="Pilih approver 2"
                                options={optionList}
                                value={activityApproverDetail?.approval_2}
                                onChange={onChangeValue}
                            />
                        </div>
                        <div className="flex items-center">
                            <div className={"w-1/3 text-neutral-70"}>Approver 3</div>
                            <Select
                                name="approval_3"
                                className="flex-1"
                                placeholder="Pilih approver 3"
                                options={optionList}
                                value={activityApproverDetail?.approval_3}
                                onChange={onChangeValue}
                            />
                        </div>
                    </div>
                </FormModal>
            }
            <Toast
                show={isShowToast}
                message={toastMessage}
                onClose={() => clearToast()}
            />
        </>
    )
}
