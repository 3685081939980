import icTrashRed from "assets/images/ic-trash-01-red.svg";
import Button from "components/commons/button";
import {ACTION_TYPE} from "constants/constant";
import moment from "moment";
import { Link } from "react-router-dom";

export const TABLE_JOB_TITLE = {
    name: 'Job Title Table',
    column: [
        {
            name: "Perusahaan",
            key: "company_name",
            classNameHeader: "!rounded-l-md",
            className: 'font-medium !text-neutral-80',
        },
        {
            name: "Job Title",
            key: "job_title_name",
        },
        {
            key: "action",
            classNameHeader: "!rounded-r-md w-[150px]",
            render: ({index, item, onClickItem}) => (
                <div className="flex justify-end gap-3 mx-auto">
                    <Button className="w-[80px]" type="outline" title="Edit"
                            onClick={() => onClickItem({index, item, actionType: ACTION_TYPE.EDIT})}/>
                    <Button type="danger" icon={icTrashRed}
                            onClick={() => onClickItem({index, item, actionType: ACTION_TYPE.DELETE})}/>
                </div>
            )
        }
    ]
}

export const TABLE_FAMILY = {
    name: 'Family Table',
    column: [
        {
            name: "Nama",
            key: "full_name",
            classNameHeader: "!rounded-l-md",
            className: 'font-medium !text-neutral-80',
        },
        {
            name: "Posisi",
            key: "relationship",
            className: 'font-medium !text-neutral-80',
        },
        {
            name: "No. Hp",
            key: "phone_number",
        },
        {
            name: "Alamat",
            key: "address",
        },
        {
            key: "action",
            classNameHeader: "!rounded-r-md w-[150px]",
            render: ({index, item, onClickItem}) => (
                <div className="flex justify-end gap-3 mx-auto">
                    <Button className="w-[80px]" type="outline" title="Edit"
                            onClick={() => onClickItem({index, item, actionType: ACTION_TYPE.EDIT})}/>
                    <Button type="danger" icon={icTrashRed}
                            onClick={() => onClickItem({index, item, actionType: ACTION_TYPE.DELETE})}/>
                </div>
            )
        }
    ]
}

export const TABLE_EMERGENCY_CONTACT = {
    name: 'Emergency Contact Table',
    column: [
        {
            name: "Nama",
            key: "full_name",
            classNameHeader: "!rounded-l-md",
            className: 'font-medium !text-neutral-80',
        },
        {
            name: "Posisi",
            key: "relationship",
            className: 'font-medium !text-neutral-80',
        },
        {
            name: "No. Hp",
            key: "phone_number",
        },
        {
            name: "Alamat",
            key: "address",
        },
        {
            key: "id",
            classNameHeader: "!rounded-r-md w-[150px]",
            render: ({index, item, onClickItem}) => (
                <div className="flex justify-end gap-3 mx-auto">
                    <Button className="w-[80px]" type="outline" title="Edit"
                            onClick={() => onClickItem({index, item, actionType: ACTION_TYPE.EDIT})}/>
                    <Button type="danger" icon={icTrashRed}
                            onClick={() => onClickItem({index, item, actionType: ACTION_TYPE.DELETE})}/>
                </div>
            )
        }
    ]
}

export const TABLE_EDUCATION = {
    name: 'Education Table',
    column: [
        {
            name: "Nama Instansi",
            key: "institution",
            classNameHeader: "!rounded-l-md",
            className: 'font-medium !text-neutral-80',
        },
        {
            name: "Jurusan",
            key: "major",
            className: 'font-medium !text-neutral-80',
        },
        {
            name: "Jenjang Pendidikan",
            key: "grade",
        },
        {
            name: "IPK / Nilai Akhir",
            key: "final_score",
        },
        {
            name: "Tgl Masuk",
            key: "start_date",
            render: ({value}) => moment(value).format('YYYY-MMM-DD')
        },
        {
            name: "Tgl Keluar",
            key: "end_date",
            render: ({value}) => moment(value).format('YYYY-MMM-DD')
        },
        {
            key: "action",
            classNameHeader: "!rounded-r-md w-[150px]",
            render: ({index, item, onClickItem}) => (
                <div className="flex justify-end gap-3 mx-auto">
                    <Button className="w-[80px]" type="outline" title="Edit"
                            onClick={() => onClickItem({index, item, actionType: ACTION_TYPE.EDIT})}/>
                    <Button type="danger" icon={icTrashRed}
                            onClick={() => onClickItem({index, item, actionType: ACTION_TYPE.DELETE})}/>
                </div>
            )
        }
    ]
}

export const TABLE_WORK_EXPERIENCE = {
    name: 'Work Experience Table',
    column: [
        {
            name: "Nama Perusahaan",
            key: "company_name",
            classNameHeader: "!rounded-l-md",
            className: 'font-medium !text-neutral-80',
        },
        {
            name: "Posisi",
            key: "position",
            className: 'font-medium !text-neutral-80',
        },
        {
            name: "Tgl Masuk",
            key: "start_date",
            render: ({value}) => moment(value).format('YYYY-MMM-DD')
        },
        {
            name: "Tgl Keluar",
            key: "end_date",
            render: ({value}) => moment(value).format('YYYY-MMM-DD')
        },
        {
            name: "Job Desc",
            key: "job_description",
        },
        {
            key: "actions",
            classNameHeader: "!rounded-r-md w-[150px]",
            render: ({index, item, onClickItem}) => (
                <div className="flex justify-end gap-3 mx-auto">
                    <Button className="w-[80px]" type="outline" title="Edit"
                            onClick={() => onClickItem({index, item, actionType: ACTION_TYPE.EDIT})}/>
                    <Button type="danger" icon={icTrashRed}
                            onClick={() => onClickItem({index, item, actionType: ACTION_TYPE.DELETE})}/>
                </div>
            )
        }
    ]
}

export const TABLE_PERSONAL_DOCUMENT = {
    name: 'Personal Document Table',
    column: [
        {
            name: "Tipe Dokumen",
            key: "type",
            classNameHeader: "!rounded-l-md",
            className: "font-medium !text-neutral-80 capitalize"
        },
        {
            name: "Deskripsi",
            key: "description",
        },
        {
            name: "File",
            key: "url",
            render: ({item,index, onClickItem}) => <div className="text-blue-60 hover:text-blue-50 transition duration-100 cursor-pointer" onClick={() => onClickItem({actionType: ACTION_TYPE.PREVIEW, item, index})}>{item?.url?.name || item.url}</div>
        },
        {
            key: "action",
            classNameHeader: "!rounded-r-md w-[150px]",
            render: ({index, item, onClickItem}) => (
                <div className="flex justify-end gap-3 mx-auto">
                    <Button className="w-[80px]" type="outline" title="Edit"
                            onClick={() => onClickItem({actionType: ACTION_TYPE.EDIT, index, item})}/>
                    <Button type="danger" icon={icTrashRed}
                            onClick={() => onClickItem({actionType: ACTION_TYPE.DELETE,index, item})}/>
                </div>
            )
        }
    ]
}
