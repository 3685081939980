import {ShiftingList} from "../../../components/request-shifting/shifting-list/ShiftingList";
import {useLocation, useSearchParams} from "react-router-dom";
import {
    convertDateTextToId,
    downloadFile,
    getAllQueryParams,
    setQueryParams
} from "../../../utils/utils";
import {useEffect, useState} from "react";
import {INITIAL_DATA_FILTER_SHIFTING_LIST, TABLE_SHIFTING_LIST} from "../../../configs/request-shifting/configTableShiftingList";
import {useFetch} from "../../../services/useFetch";
import {API_REQUEST_SHIFTING} from "../../../services/apiRequestShifting";
import moment from "moment";

const INIT_COLUMN = {
    name: null,
    key: null,
    classNameHeader: "!text-center !min-w-[115px]",
    subColumn: [
        {
            name: "In",
            key: "clock_in_time",
            classNameHeader: "!text-center",
            className: "!text-center"
        },
        {
            name: "Out",
            key: "clock_out_time",
            classNameHeader: "!text-center",
            className: "!text-center"
        }
    ]
}

export const ShiftingListContainer = () => {
    const location = useLocation()
    const {
        data: shiftingList,
        fetching: getShiftingList,
        isLoading
    } = useFetch(API_REQUEST_SHIFTING.SHIFTING_LIST, INITIAL_DATA_FILTER_SHIFTING_LIST)
    const {fetching: exportShiftingList} = useFetch(API_REQUEST_SHIFTING.EXPORT_SHIFTING_LIST)
    const [selectedIds, setSelectedIds] = useState([])
    const [isShowToast, setIsShowToast] = useState(false)
    const [toastMessage, setToastMessage] = useState('')
    const [searchParams, setSearchParams] = useSearchParams()
    const query = searchParams.size > 0 ? getAllQueryParams(searchParams) : INITIAL_DATA_FILTER_SHIFTING_LIST
    const [modal, setModal] = useState({
        export: false,
        limit: false
    })

    useEffect(() => {
        fetchShiftingList({...query})
    }, [location?.search])

    const setShowModal = (item) => {
        setModal({...modal, ...item})
    }

    const clearToast = () => {
        setIsShowToast(false)
        setToastMessage('')
    }

    const onChangeQuery = (queryParams) => {
        const params = setQueryParams({...query, ...queryParams})
        setSearchParams(params, {replace: true})
    }

    const fetchShiftingList = (item = query) => {
        getShiftingList(item)
    }

    const onSelectHandler = (item) => {
        const ids = item?.map(i => i?.id)
        setSelectedIds(ids)
    }

    const onExport = () => {
        const combineObj = query?.start_date ? query : {...INITIAL_DATA_FILTER_SHIFTING_LIST, ...query}
        const {page, limit, ...dataFilterRest} = combineObj
        const payload = {...dataFilterRest, employee_ids: selectedIds}
        exportShiftingList(payload, '', {}, 'blob')
            .then(response => {
                downloadFile(null, response, `Shifting-List-Export-${moment().format('DD-MM-YYYY')}.xlsx`)
                setShowModal({export: false})
                setIsShowToast(true)
                setToastMessage('Shifting List Exported')
            })
    }

    const onImport = () => {
        window.open('/shifting/import-shifting', '_blank', 'noopener,noreferrer')
    }

    const getColumnTable = () => {
        const column = [...TABLE_SHIFTING_LIST?.column]
        shiftingList?.data?.result?.shifting_header.forEach(item => column.push({
            ...INIT_COLUMN,
            name: convertDateTextToId(item?.replaceAll("_", " ")),
            key: item
        }))
        return column
    }

    const props = {
        dataList: shiftingList?.data,
        columnTable: getColumnTable(),
        isLoading,
        query,
        modal,
        selectedIds,
        isShowToast,
        toastMessage,
        clearToast,
        onChangeQuery,
        setShowModal,
        onSelectHandler,
        onExport,
        onImport
    }

    return (
        <ShiftingList {...props}/>
    )
}
