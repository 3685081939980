import {useDispatch, useSelector} from "react-redux";
import {setModal} from "../../../reducers/contractReducer";
import {useEffect, useState} from "react";
import {useFetch} from "../../../services/useFetch";
import {API_EMPLOYEE} from "../../../services/apiEmployee";
import {deepClone, getHeadUnitText} from "../../../utils/utils";
import Filter from "../../../components/commons/filter";

const FILTER = {
    TYPE: 'type',
    DEPARTMENT: 'department',
    DIVISION: 'division',
    SUB_DIVISION: 'sub-division',
    JOIN_DATE: 'join-date',
}

const INITIAL_OPTIONS = {
    type: [{name: 'PKWTT', id: 1, value: 'pkwtt'}, {name: 'PKWT', id: 2, value: 'pkwt'}],
    management: [],
    department: [],
    division: [],
    'sub-division': []
}

const INITIAL_DATA_FILTER = [
    {label: 'Tanggal Kontrak', type: 'date', start_date: {
            label: 'Dari Tanggal',
            name: 'start_date',
            placeholder: 'Pilih Tanggal Awal'
        },
        end_date: {
            label: 'Sampai Tanggal',
            name: 'end_date',
            placeholder: 'Pilih Tanggal Akhir'
        },},
    {label: 'Tipe Kontrak', value: 'type'},
    {label: 'Department', value: 'department'},
    {label: 'Divisi', value: 'division'},
    {label: 'Sub Divisi', value: 'sub-division'},
]

const INITIAL_FILTER = {
    type: [],
    department_ids: [],
    division_ids: [],
    subdivision_ids: [],
    start_date: '',
    end_date: ''
}

const QUERY_PARAMS = {
    [FILTER.DEPARTMENT]: 'department_ids',
    [FILTER.DIVISION]: 'division_ids',
    [FILTER.SUB_DIVISION]: 'subdivision_ids',
    [FILTER.TYPE]: FILTER.TYPE,
}

const JOB_UNIT = [FILTER.DEPARTMENT, FILTER.DIVISION, FILTER.SUB_DIVISION]

export const ContractFilterContainer = ({onSubmit, queryFilter}) => {
    const dispatch = useDispatch()
    const {modal} = useSelector(state => state.contractReducer)
    const {fetching: getJobUnitApi} = useFetch(API_EMPLOYEE.LIST_JOB_UNIT)
    const [options, setOptions] = useState(INITIAL_OPTIONS)
    const [filter, setFilter] = useState(INITIAL_FILTER)
    const [filterCount, setFilterCount] = useState()
    const [activeFilter, setActiveFilter] = useState(INITIAL_DATA_FILTER[0])

    useEffect(() => {
        if (queryFilter) {
            const newQueryFilter = deepClone(queryFilter)
            setFilter(newQueryFilter)
            countFilterLength(newQueryFilter)
        }
    }, [queryFilter, modal.filter])

    const modalVisibility = (value) => {
        dispatch(setModal({...modal, ...value}))
    }

    const onChangeDate = (e) => {
        const { name, value } = e
        setFilter({ ...filter, [name]: value })
    }

    const onChangeOption = (item) => {
        const { name, value, checked } = item
        const arrChecked = filter?.[QUERY_PARAMS[name]] || []
        handleClearOptionsFilter(name)

        if (checked) {
            arrChecked.push(String(value))
        } else {
            const indexRemove = arrChecked.indexOf(String(value))
            arrChecked.splice(indexRemove, 1)
        }
        setFilter({ ...filter, [QUERY_PARAMS[name]]: arrChecked })
    }

    const handleClearOptionsFilter = (filterName) => {
        if (filterName === FILTER.DEPARTMENT) {
            options[FILTER.DIVISION] = []
            options[FILTER.SUB_DIVISION] = []
            filter[[QUERY_PARAMS[FILTER.DIVISION]]] = []
            filter[[QUERY_PARAMS[FILTER.SUB_DIVISION]]] = []
        } else if (filterName === FILTER.DIVISION) {
            options[FILTER.SUB_DIVISION] = []
            filter[[QUERY_PARAMS[FILTER.SUB_DIVISION]]] = []
        }
        setOptions({ ...options })
    }

    const onSubmitFilter = () => {
        const queryFilter = { ...filter }
        Object.entries(queryFilter).forEach(([key, item]) => {
            if (Array.isArray(queryFilter[key])) queryFilter[key] = item.join(',')
        })
        onSubmit(queryFilter)
        modalVisibility({'filter': false})
    }

    const onSelectFilter = (item) => {
        if (JOB_UNIT.includes(item.value) && options[item.value].length === 0) {
            getJobUnitApi({ head_unit_ids: filter?.[QUERY_PARAMS[getHeadUnitText[item.value]]] || [] }, item.value)
                .then(res => setOptions({ ...options, [item.value]: res.data }))
        }
        setActiveFilter(item)
    }

    const countFilterLength = (filter) => {
        let totalLength = 0
        for (const key in filter) {
            if (filter.hasOwnProperty(key)) {
                if (Array.isArray(filter[key])) {
                    totalLength += filter[key].length;
                } else {
                    totalLength += 1;
                }
            }
        }
        setFilterCount(totalLength)
    }

    const props = {
        show: modal.filter,
        onShow: () => modalVisibility({'filter': true}),
        onClose: () => modalVisibility({'filter': false}),
        queryParams: QUERY_PARAMS,
        filterList: INITIAL_DATA_FILTER,
        activeFilter,
        onSelectFilter,
        filter,
        filterCount,
        options,
        onChangeOption,
        onChangeDate,
        onSubmitFilter,
        onClearFilter: () => setFilter(deepClone(INITIAL_FILTER)),
    }

    return (
        <Filter {...props} />
    )
}
