import icEditPrimary from "assets/images/ic-edit-04-primary.svg"
import icEyeOpen from "assets/images/ic-eye-open.svg"
import {formatDateMoment} from "../../utils/utils";
import Badge from "../../components/commons/badge";
import Button from "../../components/commons/button";
import {ACTION_TYPE} from "../../constants/constant";

export const TABLE_HRIS_ANNOUNCEMENT = {
    name: 'Total Announcement',
    column: [
        {
            name: "Judul Pengumuman",
            key: "title",
            className: "font-medium !text-neutral-80 max-w-[400px]"
        },
        {
            name: "Sent to",
            key: "company_name",
            className: "max-w-[250px] font-medium !text-neutral-80",
        },
        {
            name: "Tanggal Dibuat",
            key: "created_at",
            className: "font-medium !text-neutral-80",
            render: ({value}) => <div>{formatDateMoment(value, 'D MMMM YYYY')}</div>
        },
        {
            name: "Waktu Publish",
            key: "published_at",
            className: "font-medium !text-neutral-80",
            render: ({value}) => <div>{formatDateMoment(value, 'D MMMM YYYY HH:mm')}</div>
        },
        {
            name: "Status",
            key: "status",
            render: ({ value }) => value ? <Badge title={value} type={value?.toLowerCase()} /> : ''
        },
        {
            name: "Action",
            key: "action",
            classNameHeader: "!text-center",
            render: ({ item, onClickItem }) => (
                <div className="flex justify-center gap-3 mx-auto">
                    <Button className="w-[80px]" type="outline" icon={icEyeOpen} title="Preview" onClick={() => onClickItem({ id: item?.id, actionType: ACTION_TYPE.PREVIEW })} />
                    <Button type="outline" icon={icEditPrimary} disabled={item?.status?.toLowerCase() !== 'submitted'} onClick={() => onClickItem({ id: item?.id, actionType: ACTION_TYPE.EDIT })} />
                </div>
            )
        }
    ]
}
