import Badge from "../../components/commons/badge";
import Button from "../../components/commons/button";
import icEyeOpen from "../../assets/images/ic-eye-open.svg";
import moment from "moment";
import {formatDateMoment} from "../../utils/utils";
import clsx from "clsx";

export const TABLE_IMPORT_SHIFTING_SCHEDULE = {
    column: [
        {
            name: "Name",
            key: "full_name",
            className: "font-semibold",
            width: 250
        },
        {
            name: "NIP",
            key: "nip",
            className: "font-semibold",
            width: 250
        },
        {
            name: "Total Shifting Schedule",
            key: "total_days",
            width: 250,
            render: ({value}) => <div>{value} Hari</div>
        },
        {
            name: "Status",
            key: "status",
            width: 250,
            render: ({value}) => <Badge title={value} type={value}/>
        },
        {
            name: "Action",
            key: "action",
            width: 250,
            render: ({ item, onClickItem }) => (
                <Button type="outline" icon={icEyeOpen} onClick={() => onClickItem(item?.details)}/>
            )
        },
    ]
}

export const TABLE_IMPORT_SHIFTING_SCHEDULE_DETAIL = {
    name: 'Shifting Schedule',
    column: [
        {
            name: "Tanggal",
            key: "date",
            className: "font-semibold",
            width: 250,
            render: ({value}) => <div className={clsx(!moment(value).isValid() && "text-red-80")}>
                {moment(value).isValid() ? formatDateMoment(value, "D MMMM YYYY") : value}
            </div>
        },
        {
            name: "Schedule In",
            key: "clock_in_time",
            classNameHeader: "!text-center",
            className: "font-semibold text-center",
            width: 250,
            render: ({value}) => <div className={clsx(!moment(value, 'hh:mm').isValid() && "text-red-80")}>
                {value}
            </div>
        },
        {
            name: "Schedule Out",
            key: "clock_out_time",
            classNameHeader: "!text-center",
            className: "font-semibold text-center",
            width: 250,
            render: ({value}) => <div className={clsx(!moment(value, 'hh:mm').isValid() && "text-red-80")}>
                {value}
            </div>
        }
    ]
}
