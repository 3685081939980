import {PncApproverSetting} from "../../../components/matrix-approval/pnc-approver-setting/PncApproverSetting";
import {useEffect, useState} from "react";
import {useFetch} from "../../../services/useFetch";
import {API_PNC_APPROVER} from "../../../services/apiPncApprover";
import {INITIAL_DATA_FILTER_PNC_APPROVER} from "../../../configs/matrix-approval/configTablePncApprover";
import {useLocation, useSearchParams} from "react-router-dom";
import {constructOptionsSelect, findOptionSelected, getAllQueryParams, setQueryParams} from "../../../utils/utils";

const INITIAL_STATE = {
    category_id: null,
    category_name: null,
    employee_id: null
}

export const PncApproverSettingContainer = () => {
    const location = useLocation()
    const {data: pncApproverList, fetching: getPncApproverList, isLoading} = useFetch(API_PNC_APPROVER.PNC_APPROVER_LIST)
    const {fetching: getEmployeePncApproverList} = useFetch(API_PNC_APPROVER.PNC_EMPLOYEE_APPROVER_LIST)
    const {fetching: updatePncApprover, isLoading: isUpdating} = useFetch(API_PNC_APPROVER.UPDATE_PNC_APPROVER)
    const [pncApproverDetail, setPncApproverDetail] = useState(INITIAL_STATE)
    const [isShowForm, setShowForm] = useState(false)
    const [optionList, setOptionList] = useState([])
    const [isShowToast, setIsShowToast] = useState(false)
    const [toastMessage, setToastMessage] = useState('')
    const [searchParams, setSearchParams] = useSearchParams()
    const query = searchParams.size > 0 ? getAllQueryParams(searchParams) : INITIAL_DATA_FILTER_PNC_APPROVER

    useEffect(() => {
        fetchPncApproverList({...query})
    }, [location?.search])

    const onChangeQuery = (queryParams) => {
        const params = setQueryParams({...query, ...queryParams})
        setSearchParams(params, {replace: true})
    }

    const clearToast = () => {
        setIsShowToast(false)
        setToastMessage('')
    }

    const onHideForm = () => {
        setShowForm(false)
    }

    const onClickItemHandler = (item) => {
        setShowForm(true)
        getEmployeePncApproverList()
            .then(response => {
            const options = constructOptionsSelect([{full_name: 'Tidak Ada', id: null}, ...response?.data], 'full_name', 'id')
            setOptionList(options)
            setPncApproverDetail({...item, employee_id: findOptionSelected(options, item?.employee_id)})
        })
    }

    const onChangeValue = (item) => {
        setPncApproverDetail({...pncApproverDetail, [item?.name]: item?.value})
    }

    const fetchPncApproverList = (item = query) => {
        getPncApproverList(item)
    }

    const onSubmitForm = () => {
        updatePncApprover({
            employee_id: pncApproverDetail?.employee_id?.value
        }, pncApproverDetail?.category_id)
            .then(() => {
                onHideForm()
                setIsShowToast(true)
                setToastMessage('PnC Approver Updated !!')
                fetchPncApproverList()
        })
    }

    const props = {
        dataList: pncApproverList?.data,
        pncApproverDetail,
        optionList,
        isShowForm,
        isLoading,
        isShowToast,
        toastMessage,
        query,
        isUpdating,
        clearToast,
        onHideForm,
        onClickItemHandler,
        onChangeValue,
        onChangeQuery,
        onSubmitForm
    }

    return (
        <PncApproverSetting {...props}/>
    )
}
