import Spinner from "../../commons/spinner";
import icUserDefault from "../../../assets/images/ic-user-profile.svg";
import {InlineTabs} from "../../commons/InlineTabs";
import {formatDateMoment, getStatusIcon} from "../../../utils/utils";
import Badge from "../../commons/badge";
import Button from "../../commons/button";
import icChecked from "../../../assets/images/ic-check-02.svg";
import icCross from "../../../assets/images/ic-x-02.svg";
import Drawer from "../../commons/drawer";

export const PreviewAttendance = (
    {
        visibility,
        isLoading,
        isLoadingApproval,
        setModal,
        dataPreview,
        previewTab,
        activePreviewTab,
        onChangePreviewTab,
        onPreviewFile,
        optionBulk,
        onSelect,
    }) => {

    return (
        <Drawer visibility={visibility} onHide={() => setModal({preview: false})}
                title="Detail Request Attendance">
            {isLoading
                ? <div className="flex justify-center">
                    <Spinner className={"w-8 h-8 border-primary-50"}/>
                </div>
                : <div className="px-6">
                    <div className="flex items-center gap-4 mt-2 mb-4">
                        <img className="w-12 h-12 border border-white rounded-full"
                             src={dataPreview?.employee?.profile_picture || icUserDefault}
                             alt={dataPreview?.employee?.full_name}/>
                        <div className="flex flex-col space-y-2">
                                <span
                                    className="font-semibold text-md text-neutral-80">{dataPreview?.employee?.full_name}</span>
                            <span className="font-medium text-neutral-80">{dataPreview?.employee?.job_title}</span>
                            <span className="text-neutral-70">{dataPreview?.employee?.nip}</span>
                        </div>
                    </div>
                    <InlineTabs
                        className="!px-0"
                        classNameItem="w-full"
                        tabList={previewTab}
                        activeTab={activePreviewTab}
                        onClickTab={(tab) => onChangePreviewTab(tab.id)}
                    />
                    {activePreviewTab === previewTab[0].id ?
                        <div className="space-y-5 py-6">
                            <div className="flex">
                                <div className="w-32 text-neutral-70 pr-2">{'Department'}</div>
                                <div
                                    className="flex-1 font-medium text-neutral-80">{dataPreview?.request_info?.department || '-'}</div>
                            </div>
                            <div className="flex">
                                <div className="w-32 text-neutral-70 pr-2">{'Tanggal Presensi'}</div>
                                <div
                                    className="flex-1 font-medium text-neutral-80">{formatDateMoment(dataPreview?.request_info?.attendance_date, 'DD MMMM YYYY') || '-'}</div>
                            </div>
                            <div className="flex">
                                <div className="w-32 text-neutral-70 pr-2">{'Jam Clock In'}</div>
                                <div
                                    className="flex-1 font-medium text-neutral-80">{dataPreview?.request_info?.clock_in_time || '-'}</div>
                            </div>
                            <div className="flex">
                                <div className="w-32 text-neutral-70 pr-2">{'Jam Clock Out'}</div>
                                <div
                                    className="flex-1 font-medium text-neutral-80">{dataPreview?.request_info?.clock_out_time || '-'}</div>
                            </div>
                            <div className="flex items-center">
                                <div className="w-32 text-neutral-70 pr-2">{'Status'}</div>
                                <Badge title={
                                    <div className={'flex items-center space-x-1'}>
                                        {getStatusIcon(dataPreview?.request_info?.status) &&
                                            <img src={getStatusIcon(dataPreview?.request_info?.status)}
                                                 alt="ic-status"/>}
                                        <p>{dataPreview?.request_info?.status}</p>
                                    </div>
                                } type={dataPreview?.request_info?.status}/>
                            </div>
                            <div className="flex">
                                <div className="w-32 text-neutral-70 pr-2">{'Alasan Pengajuan'}</div>
                                <div
                                    className="flex-1 font-medium text-neutral-80">{dataPreview?.request_info?.submit_reason || '-'}</div>
                            </div>
                            {dataPreview?.request_info?.status === 'rejected' && <div className="flex">
                                <div className="w-32 text-neutral-70 pr-2">{'Alasan Penolakan'}</div>
                                <div
                                    className="flex-1 font-medium text-neutral-80">{dataPreview?.request_info?.reject_reason || '-'}</div>
                            </div>}
                            <div className="flex">
                                <div className={"w-32 text-neutral-70 pr-2"}>File Pendukung</div>
                                <div className={"flex flex-col"}>
                                    {
                                        dataPreview?.request_info?.attachments?.length ?
                                            dataPreview?.request_info?.attachments?.map((item, index) => (
                                                <div key={index} onClick={() => onPreviewFile(item)}
                                                     className={"hover:cursor-pointer hover:text-primary-50 hover:font-semibold"}>
                                                    {dataPreview?.request_info?.attachments?.length > 1 ? `File Attachment ${index + 1}` : 'File Attachment'}
                                                </div>
                                            ))
                                            :
                                            '-'
                                    }
                                </div>
                            </div>
                            {dataPreview?.request_info?.status === 'submitted' && <>
                                <div className="font-medium text-neutral-80">{'Update Status Pengajuan'}</div>
                                <div className="flex gap-2.5 px-2.5">
                                    <Button className="w-full" icon={icChecked} title={'Approved'} type={'outline'}
                                            loading={isLoadingApproval}
                                            onClick={() => onSelect(optionBulk.APPROVE)}/>
                                    <Button className="w-full" icon={icCross} title={'Rejected'} type={'danger'}
                                            onClick={() => onSelect(optionBulk.REJECT)}
                                    />
                                </div>
                            </>}
                        </div>
                        :
                        <div className={"py-6 overflow-scroll"}>
                            {dataPreview?.history?.map((item, index) => (
                                <div className={"pb-2"} key={index}>
                                    <div className={"flex flex-row items-center gap-5"}>
                                        <div
                                            className={`bg-primary-50 w-5 h-5 rounded-full flex flex-col justify-center items-center`}>
                                            <div className={"bg-white w-3 h-3 rounded-full"}/>
                                        </div>
                                        <p className={"text-neutral-80 font-bold capitalize"}>{item.action}</p>
                                    </div>
                                    <div
                                        className={`text-neutral-70 pb-5 ${index !== (dataPreview?.history?.length - 1) ? 'border-l-2 border-neutral-50 ml-2 mt-1 pl-8' : 'pl-10'}`}>
                                        <p className={"font-semibold pt-3"}>{item.actor}</p>
                                        <p className={"pt-3"}>{formatDateMoment(item.date, 'DD MMM YYYY HH:mm:ss')}</p>
                                    </div>
                                </div>
                            ))}
                        </div>}
                </div>
            }
        </Drawer>
    )
}
