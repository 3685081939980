import {GovernmentOvertime} from "components/general-settings/overtime-setting/GovernmentOvertime"
import {TABLE_GOVERNMENT_OVERTIME} from "configs/general-settings/configTableOvertimeSetting"
import {ACTION_TYPE} from "constants/constant"
import {useEffect, useState} from "react"
import {useLocation, useSearchParams} from "react-router-dom"
import {constructOptionsSelect, getAllQueryParams, setQueryParams} from "utils/utils"
import {useFetch} from "../../../services/useFetch";
import {API_OVERTIME_SETTING} from "../../../services/apiOvertimeSetting";
import {useValidation} from "../../../hooks/useValidation";
import {formGovernmentOvertimeValidation} from "../../../services/formGeneralSettingValidation";

const INITIAL_STATE = {
    schedule_category_id: null,
    duration: null,
    benefit_on_working_day: null,
    benefit_on_holiday: null,
}

export const GovernmentOvertimeContainer = () => {
    const location = useLocation()
    const [searchParams, setSearchParams] = useSearchParams()
    const defaultQuery = {
        page: 1,
        limit: 20
    }
    const query = searchParams.size > 0 ? getAllQueryParams(searchParams) : defaultQuery
    const [form, setForm] = useState(INITIAL_STATE)
    const [actionType, setActionType] = useState(ACTION_TYPE.CREATE)
    const [isShowToast, setIsShowToast] = useState(false)
    const [toastMessage, setToastMessage] = useState('')
    const [deleteId, setDeleteId] = useState(null)
    const [modal, setModal] = useState({
        form: false,
        delete: false,
    })
    const [optionsScheduleCategory, setOptionsScheduleCategory] = useState([])
    const {errorForm, clearErrorForm, validateForm} = useValidation({schema: formGovernmentOvertimeValidation})
    const {data: governmentOvertimeList, fetching: getGovernmentOvertimeList, isLoading} = useFetch(API_OVERTIME_SETTING.GOVERNMENT_OVERTIME_LIST)
    const {fetching: getScheduleCategoryList} = useFetch(API_OVERTIME_SETTING.SCHEDULE_CATEGORY_LIST)
    const {fetching: createGovernmentOvertime, isLoading: isCreating} = useFetch(API_OVERTIME_SETTING.CREATE_GOVERNMENT_OVERTIME)
    const {fetching: updatedGovernmentOvertime, isLoading: isUpdating} = useFetch(API_OVERTIME_SETTING.UPDATE_GOVERNMENT_OVERTIME)
    const {fetching: deleteGovernmentOvertime, isLoading: isDeleting} = useFetch(API_OVERTIME_SETTING.DELETE_GOVERNMENT_OVERTIME)

    useEffect(() => {
        fetchGovernmentOvertimeList({...query})
    }, [location.search])

    const onChangeQuery = (queryParams) => {
        const params = setQueryParams({...query, ...queryParams})
        setSearchParams(params, {replace: true})
    }

    const clearToast = () => {
        setIsShowToast(false)
        setToastMessage('')
    }

    const setShowToast = (message, itemModal) => {
        setModal({...modal, ...itemModal})
        setIsShowToast(true)
        setToastMessage(message)
    }

    const fetchGovernmentOvertimeList = (item = query) => {
        getGovernmentOvertimeList(item)
    }

    const onChangeForm = (e) => {
        const {name, value} = e
        setForm({...form, [name]: value})
    }

    const onDelete = () => {
        deleteGovernmentOvertime({}, deleteId)
            .then(() => {
                setShowToast('Government Overtime Deleted !!', {delete: false})
                if (getGovernmentOvertimeList?.data?.result?.length === 1 && Number(query?.page) !== 1) {
                    onChangeQuery({page: 1})
                } else {
                    fetchGovernmentOvertimeList()
                }
            })
    }

    const onActionRow = ({item, actionType}) => {
        const combineObj = {
            ...item,
            schedule_category_id: {label: item?.schedule?.name, value: item?.schedule_category_id}
        }
        const {schedule, ...newDataItem} = combineObj

        switch (actionType) {
            case ACTION_TYPE.CREATE:
                setActionType(ACTION_TYPE.CREATE)
                setForm({...INITIAL_STATE})
                setModal({...modal, form: true})
                clearErrorForm()
                break
            case ACTION_TYPE.EDIT:
                setActionType(ACTION_TYPE.EDIT)
                setForm({...newDataItem})
                setModal({...modal, form: true})
                clearErrorForm()
                break
            default:
                setDeleteId(item?.id)
                setModal({...modal, delete: true})
        }
    }

    const onSubmitForm = () => {
        const {id, ...payload} = {...form, schedule_category_id: form?.schedule_category_id?.value ?? null}
        validateForm(payload)
            .then(() => {
                if (actionType === ACTION_TYPE.CREATE) {
                    createGovernmentOvertime(payload)
                        .then(() => {
                            setShowToast('Government Overtime Created !!', {form: false})
                            fetchGovernmentOvertimeList()
                        })
                } else {
                    updatedGovernmentOvertime(payload, id)
                        .then(() => {
                            setShowToast('Government Overtime Updated !!', {form: false})
                            fetchGovernmentOvertimeList()
                        })
                }
            })
    }

    useEffect(() => {
        if (modal?.form) {
            getScheduleCategoryList()
                .then(response => {
                    const options = response?.data ? constructOptionsSelect(response?.data, 'name', 'id') : []
                    setOptionsScheduleCategory(options)
                })
        }
    }, [modal?.form])

    const props = {
        configTable: TABLE_GOVERNMENT_OVERTIME,
        dataGovernmentOvertime: governmentOvertimeList?.data,
        query,
        optionsScheduleCategory,
        form,
        modal,
        actionType,
        errorForm,
        isShowToast,
        toastMessage,
        clearToast,
        onChangePage: (page) => onChangeQuery({page}),
        onActionRow,
        onDelete,
        onSetModal: (e) => setModal({...modal, ...e}),
        onChangeForm,
        onSubmitForm,
        isLoadingTable: isLoading,
        isCreating,
        isUpdating,
        isDeleting,
    }

    return (
        <GovernmentOvertime {...props} />
    )
}
