import clsx from "clsx";
import './toast.scss';
import icXWhite from "./ic-x-white.svg"
import {useRef} from "react";

const Toast = ({className, classNameMessage, show, message, closeAutomatic = true, onClose, type = "default"}) => {
    const wrapperRef = useRef()
    const closeToast = () => {
        if (onClose) {
            wrapperRef.current?.classList?.add("close-toast")
            setTimeout(() => {
                onClose()
            }, 400)
        }
    }
    const handleOnClick = () => {
        closeToast()
    }

    if (show && closeAutomatic) {
        setTimeout(() => {
            closeToast()
        }, 2500)
    }

    return (
        <div className={clsx("avo__toast", show && "show-toast", className)} ref={wrapperRef}>
            <div className={clsx("message-toast", type, classNameMessage)}>
                <p>{message}</p>
                <button onClick={handleOnClick}>
                    <img src={icXWhite} alt="ic-x-white" className="close-image"/>
                </button>
            </div>
        </div>
    )
}

export default Toast
