import {useSearchParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {useFetch} from "../../../../../../services/useFetch";
import {API_CONTRACT} from "../../../../../../services/apiContract";
import {constructOptionsSelect, convertArrayToObject, deepClone, formatDateMoment} from "../../../../../../utils/utils";
import {setContractDetail, setOptions} from "../../../../../../reducers/contractReducer";
import {FORM_ADDENDUM_CONTRACT, FORM_CREATE_CONTRACT} from "../../../../../../constants/constant-management-contract";
import {FormContractContainer} from "../../../../form-contract/FormContractContainer";
import {Alert} from "../../../../../../components/commons/alert";

export const EditContractContainer = () => {
    const [searchParams] = useSearchParams();
    const contractId = searchParams.get('contractId')
    const employeeId = searchParams.get('employeeId')
    const dispatch = useDispatch()
    const {contractDetail} = useSelector(state => state.contractReducer)
    const [form, setForm] = useState([])
    const [errorForm, setErrorForm] = useState({})
    const {fetching: getContractDetailApi} = useFetch(API_CONTRACT.DETAIL_CONTRACT)
    const {fetching: getJobTitleByCompanyApi} = useFetch(API_CONTRACT.LIST_JOB_TITLE)
    const {fetching: getCompanyListApi} = useFetch({...API_CONTRACT.LIST_COMPANY, initPathVariable: employeeId})
    const {fetching: getConstantApi} = useFetch(API_CONTRACT.CONSTANT)
    const {fetching: editContractApi} = useFetch(API_CONTRACT.EDIT_CONTRACT)
    const {fetching: getGradeByJobTitleApi} = useFetch(API_CONTRACT.LIST_GRADE)

    useEffect(() => {
        Promise.all([getConstantApi(), getCompanyListApi()])
            .then(result => {
                let ptkp_status = constructOptionsSelect(result[0].data.ptkp_status)
                let type = constructOptionsSelect(result[0].data.type)
                let company = constructOptionsSelect(result[1].data, 'name', 'company_id')
                dispatch(setOptions({ptkp_status, type, company}))
            })
    }, []);

    useEffect(() => {
        getContractDetailApi({}, contractId)
            .then(res => {
                let company = constructOptionsSelect([res.data.company], 'name', 'id')
                let job_title = constructOptionsSelect([res.data.job_title], 'name', 'id')
                let grade = constructOptionsSelect([res.data.grade], 'name', 'id')
                let ptkp_status = constructOptionsSelect([res.data.ptkp_status])
                let type = constructOptionsSelect([res.data.type])
                let defineForm = res?.data?.addendum_start_date ? FORM_ADDENDUM_CONTRACT : FORM_CREATE_CONTRACT
                constructAllowance(res.data.allowances, defineForm)
                dispatch(setContractDetail({
                    ...res.data,
                    company: company[0],
                    job_title: job_title[0],
                    grade: grade[0],
                    ptkp_status: ptkp_status[0],
                    type: type[0]
                }))
                FORM_ADDENDUM_CONTRACT[2].form[5].hide = (type[0].value === 'PKWTT')
                FORM_CREATE_CONTRACT[1].form[5].hide = (type[0].value === 'PKWTT')
                getJobTitle(company[0].value)
                getGrade(job_title[0].value)
            })
    }, []);

    const constructAllowance = (allowances, defineForm) => {
        let copyForm = deepClone(defineForm)
        let copyFormSalary = deepClone(defineForm[defineForm.length - 1].form)
        copyForm[defineForm.length - 1].form = copyFormSalary.concat(allowances)
        setForm(copyForm)
    }

    const getJobTitle = (company_id) => {
        getJobTitleByCompanyApi({company_id}, employeeId)
            .then(result => {
                dispatch(setOptions({job_title: constructOptionsSelect(result.data, 'title', 'id')}))
            })
    }

    const getGrade = (job_title_id) => {
        getGradeByJobTitleApi({job_title_id})
            .then(result => {
                dispatch(setOptions({grade: constructOptionsSelect(result.data, 'name', 'id')}))
            })
    }

    const onSubmitForm = (notes) => {
        let requestBody = {
            is_interim: contractDetail?.is_interim,
            nip: contractDetail.nip,
            addendum_number: contractDetail.addendum_number,
            company_id: contractDetail.company.value,
            job_title_id: contractDetail.job_title.value,
            grade_id: contractDetail.grade.value,
            type: contractDetail.type.value,
            ptkp_status: contractDetail.ptkp_status.value,
            end_date: contractDetail.type.value === 'PKWT' ? formatDateMoment(contractDetail.end_date, 'YYYY-MM-DD') : null,
            base_salary: contractDetail.base_salary,
            job_description: contractDetail.job_description,
            notes
        }

        if (!contractDetail.addendum_number) {
            requestBody.number = contractDetail.number
            requestBody.start_date = formatDateMoment(contractDetail.start_date, 'YYYY-MM-DD')
        } else {
            requestBody.addendum_start_date = formatDateMoment(contractDetail.addendum_start_date, 'YYYY-MM-DD')
            requestBody.addendum_end_date = formatDateMoment(contractDetail.addendum_end_date, 'YYYY-MM-DD')
        }

        editContractApi(requestBody, contractId)
            .then(res => {
                Alert({
                    icon: 'success',
                    title: 'Success',
                    message: 'Edit Kontrak Berhasil!',
                })
                window.location.href = `/management-contract/contract-employee/employee-detail?contractId=${contractId}`
            })
            .catch(err => {
                if (err.code === '422') {
                    setErrorForm(convertArrayToObject(err.data))
                }
            })
    }

    let props = {
        onSubmitForm,
        activity: 'edit',
        form,
        errorForm
    }

    return (
        <FormContractContainer {...props} />
    )
}
