import Table from "../../commons/table";
import {TABLE_SETTING_TYPE_ALLOWANCE} from "../../../configs/organization-structure/configTableGrade";
import icAddSquare from "../../../assets/images/id-add-square.svg";
import Button from "../../commons/button";
import FormModal from "../../commons/form-modal";
import Toast from "../../commons/toast";
import Input from "../../commons/input";
import Switch from "../../commons/switch";
import {ACTION_TYPE} from "../../../constants/constant";

export const SettingTypeAllowance = (
    {
        dataList,
        typeAllowanceDetail,
        isShowForm,
        isLoading,
        isShowToast,
        toastMessage,
        query,
        isCreating,
        isUpdating,
        title,
        clearToast,
        onHideForm,
        onClickItemHandler,
        onChangeValue,
        onChangeQuery,
        onSubmitForm
    }) => {

    return (
        <>
            <div className={"flex justify-end gap-3 px-6 py-4"}>
                <Button title="Add Type Allowance" icon={icAddSquare}
                        onClick={() => onClickItemHandler({actionType: ACTION_TYPE.CREATE})}/>
            </div>
            <Table
                className="my-3"
                name={TABLE_SETTING_TYPE_ALLOWANCE.name}
                columns={TABLE_SETTING_TYPE_ALLOWANCE.column}
                data={dataList?.result}
                size={query?.limit}
                currentPage={query?.page}
                totalPage={dataList?.total_page}
                totalResult={dataList?.total_result}
                onChangePage={(val) => onChangeQuery({page: val})}
                onClickItem={onClickItemHandler}
                loading={isLoading}
            />
            {
                isShowForm &&
                <FormModal
                    show={isShowForm}
                    title={title}
                    width="max-w-[639px]"
                    onHide={onHideForm}
                    onSubmitForm={onSubmitForm}
                    isDisabled={!typeAllowanceDetail?.name}
                    isLoading={isCreating || isUpdating}
                >
                    <div className={"flex flex-col px-6 py-8 gap-[20px]"}>
                        <div className="flex items-center">
                            <div className={"w-1/3 text-neutral-70"}>Nama</div>
                            <Input
                                className="flex-1"
                                name="name"
                                placeholder="Masukkan Nama Allowance"
                                value={typeAllowanceDetail?.name}
                                onChange={(e) => onChangeValue(e)}
                            />
                        </div>
                        <div className="flex items-center">
                            <div className={"w-1/3 text-neutral-70"}>Status</div>
                            <Switch
                                name="is_active"
                                checked={typeAllowanceDetail?.is_active}
                                onChange={(e) => onChangeValue(e)}
                            />
                            <p className={"text-neutral-70 pl-3"}>{typeAllowanceDetail?.is_active ? 'Active' : 'Inactive'}</p>
                        </div>
                    </div>
                </FormModal>
            }
            <Toast
                show={isShowToast}
                message={toastMessage}
                onClose={() => clearToast()}
            />
        </>
    )
}
