import Input from "../../commons/input";
import FormModal from "../../commons/form-modal";
import Select from "../../commons/select";
import {getHeadUnitText} from "../../../utils/utils";

export const EditWorkUnitForm = (props) => {
    const {
        isShow,
        onHideForm,
        onSubmitForm,
        isFormValid,
        formValue,
        saveValue,
        options
    } = props

  return (
      <FormModal show={isShow}
                 title={`Update Data ${formValue.category}`}
                 width={'max-w-[639px]'}
                 onHide={onHideForm}
                 onSubmitForm={onSubmitForm}
                 isDisabled={!isFormValid}
      >
          <div className={"flex flex-col px-6 py-8 gap-[20px]"}>
              {
                  formValue.category === 'management' ?
                      <div className="flex flex-row">
                          <div className="w-1/3 text-neutral-70 required capitalize">PT</div>
                          <Input className={'flex-1'} name="company_name" disabled={true} value={formValue.company_name}/>
                      </div> :
                      <div className="flex flex-row">
                          <div className="w-1/3 text-neutral-70 required capitalize">{getHeadUnitText[formValue.category]}</div>
                          <Select name="head_unit_selected"
                                  className='flex-1 capitalize'
                                  options={options}
                                  value={formValue.head_unit_selected}
                                  onChange={(e) => saveValue({head_unit_selected: e.value})}
                          />
                      </div>
              }

              <div className="flex flex-row">
                  <div className="w-1/3 text-neutral-70 required capitalize">{formValue.category}</div>
                  <Input className={'flex-1'} name="name"
                         value={formValue.name} onChange={(e) => saveValue({[e.name]: e.value})}/>
              </div>
          </div>
      </FormModal>
  )
}
