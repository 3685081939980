import FormModal from "../../commons/form-modal";
import Input from "../../commons/input";

export const EditIncentiveForm = (props) => {
    const {show, onHide, onSubmitForm, formValue, onChangeForm} = props
    return (
        <FormModal
            title={`Edit Nilai Incentive`}
            onSubmitForm={onSubmitForm}
            show={show}
            onHide={onHide}
        >
            <div className={"flex flex-col px-6 py-8 gap-[20px]"}>
                <div className="flex flex-row items-center">
                    <div className="w-1/3 text-neutral-70 required capitalize font-medium">nama grade</div>
                    <p className={"text-lg"}>{formValue?.grade_name}</p>
                </div>
                {
                    formValue.values.map((item, index) => (
                        <div className="flex flex-row items-center" key={index}>
                            <div className="w-1/3 text-neutral-70 required capitalize font-medium">{item.label}</div>
                            <Input className={'flex-1'} name={index} addonBefore={'Rp'}
                                   value={item.value} onChange={(e) => onChangeForm(e)}/>
                        </div>
                    ))
                }
            </div>
        </FormModal>
    )
}
